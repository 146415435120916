import { Flex, FlexProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

export default function StatCard({ children }: CardProps) {
    return (
        <Flex
            border="1px"
            borderRadius="md"
            p="4"
            bg="white"
            borderColor="gray.300"
            w="full"
        >
            {children}
        </Flex>
    )
}

interface CardProps extends FlexProps {
    children: ReactNode
}
