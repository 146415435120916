import { Box, Icon, Input, useOutsideClick } from '@chakra-ui/react'
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'
import Item from '../../../../../models/item'
import CategoryItem from '../../../../../models/category_item'
import { get } from '../../../../../lib/http'
import { FieldSearchProps } from './types'
import ItemSearch from './ItemSearch'
import Close from '../../../../../components/Icon/Close'

export default function FieldSearch({ item, onSelect }: FieldSearchProps) {
    const ref = useRef<HTMLInputElement>(null)
    const [search, setSearch] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [index, setIndex] = useState(0)
    const [items, setItems] = useState<
        Array<Item & { category_item: CategoryItem }>
    >([])

    useOutsideClick({
        ref,
        handler: () => {
            setTimeout(() => setIsOpen(false), 100)
        }
    })

    useEffect(() => {
        if (!item) {
            setSearch('')
        }
    }, [item])

    async function searchHandler(ev: ChangeEvent<HTMLInputElement>) {
        if (item) {
            onSelect(null)
            return
        }
        setSearch(ev.target.value)
        const value = ev.target.value.trim().toLowerCase()
        if (!value) {
            setIsOpen(false)
            setItems([])
            setIndex(0)

            return
        }

        setIsOpen(true)
        const { data } = await get<
            Array<Item & { category_item: CategoryItem }>
        >(`/api/items?search=${value}&limit=20`)

        if (data) {
            setItems(data.result)
            setIndex(_index =>
                data.result.length - 1 > _index
                    ? _index
                    : data.result.length - 1
            )
        }
    }

    function keyHandler(ev: KeyboardEvent<HTMLInputElement>) {
        if (ev.code === 'ArrowUp') {
            setIndex(_index => (_index === 0 ? 0 : _index - 1))
        }
        if (ev.code === 'ArrowDown') {
            setIndex(_index => (_index === items.length ? _index : _index + 1))
        }
        if (ev.code === 'Enter' || ev.code === 'NumpadEnter') {
            setIsOpen(false)
            onSelect(items.find((_, i) => i === index) || null)
            setIndex(0)
            setItems([])
        }
        if (ev.code === 'Escape') {
            setIsOpen(false)
            onSelect(null)
            setIndex(0)
            setItems([])
            setSearch('')
        }
    }

    function clearHandler() {
        setSearch('')
        onSelect(null)
        if (ref.current) {
            ref.current.focus()
        }
    }

    return (
        <Box position="relative" w="full">
            <Input
                placeholder="Búsqueda"
                borderLeftWidth={{
                    base: '1px',
                    lg: '0px'
                }}
                borderLeftRadius={{
                    base: 'lg',
                    lg: 'none'
                }}
                mr={2}
                ref={ref}
                bg="white"
                onChange={searchHandler}
                onKeyUp={ev => keyHandler(ev)}
                value={item ? item.name : search}
            />
            {(item || search) && (
                <Icon
                    as={Close}
                    fill="gray-3"
                    position="absolute"
                    top="calc(50% - 7px)"
                    right="4"
                    fontSize="sm"
                    cursor="pointer"
                    zIndex="1"
                    onClick={clearHandler}
                />
            )}
            <Box
                position="absolute"
                top={12}
                zIndex={10}
                py={2}
                bg="white"
                borderRadius="md"
                boxShadow="md"
                w="full"
                minW="72"
                borderWidth="1px"
                d={isOpen && items.length > 0 ? 'block' : 'none'}
            >
                {items.map((_item, i) => (
                    <ItemSearch
                        key={i}
                        item={_item}
                        isActive={i === index}
                        onSelect={onSelect}
                    />
                ))}
            </Box>
        </Box>
    )
}
