export default function Check(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 32 25"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M11.5295 25C11.428 24.9159 11.3332 24.8491 11.253 24.769C7.58255 21.1047 3.91295 17.4391 0.244245 13.7722C0.0492815 13.5772 0.0305866 13.4544 0.236234 13.2514C1.64816 11.8546 3.05163 10.4503 4.44665 9.03832C4.72574 8.75389 4.83658 8.97423 4.99282 9.1318C7.0346 11.1722 9.08573 13.2033 11.1075 15.2625C11.4573 15.619 11.6296 15.579 11.9594 15.2478C16.9386 10.2455 21.924 5.24988 26.9156 0.260938C27.2534 -0.0782455 27.427 -0.0956053 27.7676 0.260938C29.0669 1.60432 30.3956 2.91833 31.7283 4.22832C31.9686 4.46469 31.9953 4.59421 31.7429 4.85194C25.1035 11.4736 18.4712 18.1015 11.8459 24.7356C11.7471 24.8224 11.643 24.9039 11.5295 25Z" />
        </svg>
    )
}
