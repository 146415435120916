import { Icon } from '@chakra-ui/react'
import Trash from '../../../components/Icon/Trash'
import colors from '../../../config/theme/colors'
import Delete from '../../../lib/delete'
import Booking from '../../../models/booking'
import { listBooking, PATH_ENDPOINT_BOOKING } from '../storage'

export default function DeleteBooking({ booking }: { booking: Booking }) {
    return (
        <Delete
            title="Eliminar Reserva"
            path={`${PATH_ENDPOINT_BOOKING}/${booking.id}`}
            element={click => (
                <Icon
                    onClick={click}
                    fill={colors.error}
                    as={Trash}
                    cursor="pointer"
                />
            )}
            done={() => {
                listBooking.setQuery('page', 1)
                listBooking.load()
            }}
        >
            ¿Está seguro de eliminar la reserva de <b>{booking.client_name}</b>?
            <p>Todos los pre-predidos serán eliminados</p>
        </Delete>
    )
}
