import { Icon } from '@chakra-ui/react'
import Trash from '../../components/Icon/Trash'
import colors from '../../config/theme/colors'
import useSession from '../../hooks/useSession'
import Delete from '../../lib/delete'
import User from '../../models/user'
import Logged from '../../types/logged'
import { controlList } from './storage'

export default function DeleteUser({ user }: { user: User }) {
    const session = useSession().session as Logged

    return (
        <Delete
            title="Eliminar usuario"
            path={`/api/users/${user.id}`}
            element={click => (
                <Icon
                    onClick={click}
                    fill={colors.error}
                    as={Trash}
                    cursor="pointer"
                    opacity={session.user.id === user.id ? '0.5' : '1'}
                    pointerEvents={
                        session.user.id === user.id ? 'none' : 'auto'
                    }
                />
            )}
            done={() => {
                controlList.setQuery('page', 1)
                controlList.load()
            }}
        >
            ¿Está seguro eliminar el usuario <b>{user.firstname}</b>?
        </Delete>
    )
}
