import { Tr, Td } from '@chakra-ui/react'
import List from '../../../lib/list'
import { listDriver } from '../storage'
import DeleteDriver from './DeleteDriver'
import Edition from './Edition'

export default function Driver() {
    return (
        <List
            control={listDriver}
            tbody={(driver, e) => (
                <Tr key={e}>
                    <Td textAlign="center" width="28">
                        {e}
                    </Td>
                    <Td textAlign="center">{driver.name}</Td>
                    <Td textAlign="center">
                        <Edition driver={driver} />
                        <DeleteDriver driver={driver} />
                    </Td>
                </Tr>
            )}
        />
    )
}
