import ControlList from '../../lib/list/ControlList'

export interface CashboxUserRowQuery {
    fecha_apertura: string
    cajero: string
    linea_negocio: string
    venta: number
}

export const controlList = new ControlList<CashboxUserRowQuery>({
    path: '/api/cashbox_users/sales_by_line',
    theads: ['Fecha de apertura', 'Cajer@', 'Linea de negocio', 'Venta (S/)']
})
