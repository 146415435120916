import ControlList from '../../lib/list/ControlList'
import Warehouse from '../../models/warehouse'
import { GetInventoryItemWarehouse, DailySummaryOperation } from './types'

export const listWarehouses = new ControlList<Warehouse>({
    path: '/api/warehouses',
    theads: ['Nombre', 'Acciones']
})

export const listProducts = new ControlList<GetInventoryItemWarehouse>({
    path: '/api/inventory_item_warehouses',
    theads: ['Nombre de producto', 'Unidad', 'Almacén', 'Catidad', 'Acciones']
})

export const listKardex = new ControlList<DailySummaryOperation>({
    path: '/api/inventory_item_warehouses/daily_summary_operations',
    theads: [
        'Fecha',
        'Producto',
        'Almacén',
        'Unidad',
        'Stock Inicial',
        'Ingresos',
        'Salidas',
        'Stock Final',
        'Acciones'
    ]
})
