import {
    Modal,
    ModalOverlay,
    ModalBody,
    ModalContent,
    Text,
    Box,
    Grid,
    Input,
    Button
} from '@chakra-ui/react'
import BackSpace from '../../../components/Icon/BackSpace'

import useModalPay from '../useModalPay'

export default function ModalPin({
    isOpen,
    typePay,
    onClose
}: {
    typePay: string
    isOpen: boolean
    onClose: () => void
}) {
    const {
        numbers,
        handleClear,
        handleSubmit,
        handleClickButton,
        handleRemoveLastDigit,
        amount,
        getTextsForModal,
        changeHandler
    } = useModalPay(isOpen)

    const { text, subText } = getTextsForModal(typePay)

    return (
        <Modal isCentered={true} size="xl" onClose={onClose} isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent p="10" borderRadius="3xl">
                <ModalBody>
                    <Text
                        color="dark"
                        fontSize="2xl"
                        fontWeight="bold"
                        textAlign="center"
                        mb="5"
                    >
                        {text}
                    </Text>
                    <Text mb="8" textAlign="center" color="dark">
                        {subText}
                    </Text>
                    <Box
                        mb="10"
                        display="flex"
                        justifyContent="center"
                        gridGap="10px"
                    >
                        <Input
                            w="350px"
                            value={amount}
                            onChange={changeHandler}
                            autoFocus
                            fontSize="24px"
                            fontWeight="bold"
                            borderRadius="xl"
                            h="50px"
                        />
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Box mr={10}>
                            <Grid
                                gap="20px"
                                templateColumns={{
                                    base: 'repeat(2,1fr)',
                                    md: 'repeat(3,1fr)'
                                }}
                            >
                                {numbers.map((number: number) => (
                                    <Button
                                        key={number}
                                        w="90px"
                                        variant="dark:2xl-lg"
                                        bg="transparent"
                                        color="dark"
                                        onClick={() =>
                                            handleClickButton(number)
                                        }
                                    >
                                        {number}
                                    </Button>
                                ))}
                            </Grid>
                            <Box
                                mt="20px"
                                display="flex"
                                justifyContent="flex-end"
                                gridGap="20px"
                            >
                                <Button
                                    w="90px"
                                    variant="dark:2xl-lg"
                                    bg="transparent"
                                    color="dark"
                                    onClick={() => handleClickButton(0)}
                                >
                                    0
                                </Button>
                                <Button
                                    w="90px"
                                    variant="dark:2xl-lg"
                                    bg="transparent"
                                    color="dark"
                                    onClick={() => handleClickButton('.')}
                                >
                                    .
                                </Button>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridGap="20px"
                        >
                            <Button
                                w="90px"
                                variant="orange:2xl-lg"
                                onClick={handleRemoveLastDigit}
                            >
                                <BackSpace fill="white" />
                            </Button>
                            <Button
                                variant="orange:2xl-lg"
                                onClick={handleClear}
                            >
                                Clear
                            </Button>
                            <Button
                                variant="alo-green:2xl-lg"
                                onClick={handleSubmit}
                            >
                                Ok
                            </Button>
                        </Box>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
