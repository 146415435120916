import {
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    InputProps,
    Switch,
    Text
} from '@chakra-ui/react'
import ErrorHelper from '../../../../components/ErrorHelper'
import colors from '../../../../config/theme/colors'
import { formItem } from '../../storage'

export default function Commission() {
    const {
        props: inputProps,
        helper,
        error
    } = formItem.useField<InputProps>('commission')
    const { value, setValue } = formItem.useValue('has_commission')
    return (
        <>
            <Text mb={3} fontWeight="bold" fontSize="20px">
                Comisión por venta
            </Text>
            <FormControl mb={2}>
                <Flex align="center">
                    <FormLabel m={0} mr={3}>
                        ¿Tiene comisión por venta?
                    </FormLabel>
                    <Switch
                        isChecked={value}
                        onChange={e => setValue(e.target.checked)}
                    />
                </Flex>
                <FormHelperText>
                    Esta es para habilitar la comisión para agencias o guía
                    local.
                </FormHelperText>
            </FormControl>
            {value && (
                <FormControl>
                    <InputGroup>
                        <InputLeftElement
                            pointerEvents="none"
                            color={colors['alo-gray']}
                            fontSize="1.2em"
                        >
                            S/.
                        </InputLeftElement>
                        <Input
                            {...inputProps}
                            placeholder="0.00"
                            type="number"
                            name="comission"
                            autoFocus
                        />
                    </InputGroup>
                    <ErrorHelper helper={helper || error} />
                </FormControl>
            )}
        </>
    )
}
