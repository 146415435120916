import Client from '../models/client'
import TypeIdentificationDocument from './type_identification_document'

export default function getFullNameClient(client: Client) {
    const fullNumber = `${
        TypeIdentificationDocument[client.type_identification_document_id]
    }: ${client.number}`
    if (client.type_identification_document_id === 2) {
        return {
            fullName: client.business_name,
            fullNumber
        }
    }
    return {
        fullName: `${client.firstname} ${client.lastname}`,
        fullNumber
    }
}
