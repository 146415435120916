import { Flex } from '@chakra-ui/react'
import { TabButtonProps } from '../types'

export default function TabButton({
    children,
    isActive = false,
    value,
    onTab
}: TabButtonProps) {
    return (
        <Flex
            borderRadius="3xl"
            px="8"
            py="1"
            alignItems="center"
            cursor="pointer"
            fontSize="sm"
            border="1px solid"
            borderColor={isActive ? 'gray-2' : 'gray.300'}
            color={isActive ? 'white' : 'gray.700'}
            bg={isActive ? 'gray-2' : 'white'}
            transition=".1s ease"
            onClick={() => onTab(value)}
            whiteSpace="nowrap"
        >
            {children}
        </Flex>
    )
}
