import { storagePage } from '../storage'

export default function useSelectedItems() {
    const subtotal = storagePage.selectedItems.reduce(
        (acc, curr) => acc + curr.sub_total,
        0
    )

    const discount =
        storagePage.typeDiscount === 'percentage'
            ? (subtotal / 100) * storagePage.discount
            : storagePage.discount
    const total = Number((subtotal - discount).toFixed(2))

    const valuePercentDiscount = storagePage.discount
    const totalCredit = storagePage.creditFees.reduce(
        (acc, curr) => acc + Number(curr.amount),
        0
    )
    const cash = storagePage.cash.reduce((acc, curr) => acc + curr.amount, 0)
    const card = storagePage.card.reduce((acc, curr) => acc + curr.amount, 0)
    const transfer = storagePage.transfer.reduce(
        (acc, curr) => acc + curr.amount,
        0
    )

    const accumulatedPayment = cash + card + transfer

    return {
        subtotal,
        discount,
        total,
        totalCredit,
        valuePercentDiscount,
        typeDiscount: storagePage.typeDiscount,
        accumulatedPayment
    }
}
