export default function OrderIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 18 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 16c-.27-4.07-3.25-7.4-7.16-8.21A2.006 2.006 0 0 0 12 5a2.006 2.006 0 0 0-1.84 2.79C6.25 8.6 3.27 11.93 3 16h18Zm-2.02 1H2v2h20v-2h-3.02Zm-.48-3.01A7.002 7.002 0 0 0 12 9.58c-2.95 0-5.47 1.83-6.5 4.41h13Z"
            />
        </svg>
    )
}
