import { Box, Flex } from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import Initial from '../../components/Initial'
import PageState from '../../types/pageState'
import MenuUser from '../../components/MenuUser'
import NTabs from '../../components/NTabs'
import Tables from './Tables'
import TabZones from './TabZones'
import SelectZones from './SelectZones'
import { useState, useEffect } from 'react'
import { useGet } from '../../hooks/useHttp'
import Zone from '../../models/zone'
import LabelStates from './LabelStates'
import JoinTablesOptions from './JoinTablesOptions'
import { TableData } from './types'
import { get } from '../../lib/http'

export default function ByTables() {
    const [tables, setTables] = useState<TableData[]>([])
    const { data } = useGet<Zone[]>(
        '2>/api/zones/?sort=name&is_enabled=true',
        []
    )
    const [zoneId, setZoneId] = useState(
        () => Number(localStorage.getItem('zone_id')) || 0
    )

    useEffect(() => {
        if (data.result.length === 0) return
        const currentZoneId = Number(localStorage.getItem('zone_id'))
        newSetZoneId(currentZoneId || data.result[0].id)
        loadTables(currentZoneId)
    }, [data.result.length])

    useEffect(() => {
        loadTables(zoneId)
    }, [zoneId])

    async function loadTables(currentZoneId: number) {
        const result = await get<TableData[]>(
            `/api/tables?sort=name&is_enabled=true&zone_id=${currentZoneId}`
        )
        if (result.data) {
            setTables(result.data.result)
        }
    }

    function newSetZoneId(_zoneId: number) {
        setZoneId(_zoneId)
        localStorage.setItem('zone_id', _zoneId.toString())
    }

    return (
        <Initial pageState={PageState.SUCCESS} position="relative" pb={16}>
            <Navbar component={<NTabs />}>
                <Box px="5" py="1">
                    <MenuUser />
                </Box>
            </Navbar>
            <Box
                w={{
                    base: '100vw',
                    xl: 'calc(100vw - 250px)'
                }}
                px={{
                    xl: 12,
                    lg: 6
                }}
                py={6}
                mx="auto"
            >
                <Box mb={5} px="16px">
                    <Flex justifyContent="end" alignItems="center">
                        {zoneId !== 0 && (
                            <SelectZones
                                zones={data.result}
                                currentZone={zoneId}
                                onClick={newSetZoneId}
                            />
                        )}
                        <LabelStates />
                    </Flex>
                    <TabZones
                        zones={data.result}
                        currentZone={zoneId}
                        onClick={newSetZoneId}
                    />
                </Box>
                {zoneId !== 0 && <Tables tables={tables} />}
                {data.result.length > 0 && (
                    <JoinTablesOptions
                        zones={data.result}
                        onLoad={_zoneId => {
                            loadTables(_zoneId)
                            newSetZoneId(_zoneId)
                        }}
                    />
                )}
            </Box>
        </Initial>
    )
}
