import { Box, Heading, SimpleGrid } from '@chakra-ui/react'
import { GetItem, Section1Props } from '../types'
import BoxItem from './BoxItem'

export default function Section1({
    itemFeatures,
    setFeature,
    featuresSelected
}: Section1Props) {
    return (
        <Box
            w={{
                base: '100%',
                md: '50%'
            }}
            bg="#F6F6F6"
            p="8"
        >
            {itemFeatures.map((itemF, i) => (
                <Box key={i} mb={4}>
                    <Heading fontSize="xl" mb={3}>
                        {itemF.feature.name}
                    </Heading>
                    <SimpleGrid columns={4} spacing={2}>
                        {itemF.feature.sub_features.map(
                            (
                                subFeature: GetItem['item_features'][number]['feature']['sub_features'][number],
                                _i
                            ) => (
                                <BoxItem
                                    price={subFeature.price}
                                    key={_i}
                                    quantity={itemF.quantity}
                                    onClick={() =>
                                        setFeature(
                                            itemF.feature,
                                            subFeature,
                                            itemF.quantity
                                        )
                                    }
                                    isActive={
                                        !!(
                                            featuresSelected.find(
                                                fs =>
                                                    fs.feature_id ===
                                                    itemF.feature.id
                                            )?.subs || []
                                        ).find(
                                            sub =>
                                                sub.sub_feature_id ===
                                                subFeature.id
                                        )
                                    }
                                    hasStockControl={
                                        subFeature.supply_sub_features[0]
                                            ? subFeature.supply_sub_features[0]
                                                  .supply.has_inventory_control
                                            : false
                                    }
                                    currentStock={
                                        subFeature.supply_sub_features[0]
                                            ? subFeature.supply_sub_features[0]
                                                  .supply.stock
                                            : 0
                                    }
                                >
                                    {subFeature.name}
                                </BoxItem>
                            )
                        )}
                    </SimpleGrid>
                </Box>
            ))}
        </Box>
    )
}
