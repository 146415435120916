import { Flex, FlexProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

export default function Card({
    children,
    direction,
    justifyContent,
    gridGap,
    maxH,
    w,
    maxW
}: CardProps) {
    return (
        <Flex
            border="1px"
            borderRadius="md"
            p="4"
            bg="white"
            borderColor="gray.300"
            h="fit-content"
            w={w}
            direction={direction}
            justifyContent={justifyContent}
            gridGap={gridGap}
            maxH={maxH}
            maxW={maxW}
        >
            {children}
        </Flex>
    )
}

interface CardProps extends FlexProps {
    children: ReactNode
}
