import React from 'react'
import { get } from '../../../../lib/http'
import PreOrder from '../../../../models/pre_order'

export function usePreOrder(dataPreOrder: PreOrder, onClose: () => void) {
    const [preOrder, setPreOrder] = React.useState<PreOrder | null>(null)
    const [loading, setLoading] = React.useState(false)

    const getPreOrder = async () => {
        setLoading(true)

        const result = await get<PreOrder>(`/api/pre_orders/${dataPreOrder.id}`)
        if (result.error) onClose()
        setPreOrder(result?.data?.result ?? null)
        setLoading(false)
    }

    React.useEffect(() => {
        getPreOrder()
    }, [])

    return {
        loading,
        preOrder
    }
}
