import { Box, Flex, Input, Text } from '@chakra-ui/react'
import usePreOrder from '../../../../hooks/usePreOrder'
import ModalBookings from '../../../../modals/ModalBookings'
import { useState } from 'react'
import useParamsQuickSaleBy from '../../../../hooks/useParamsQuickSaleBy'
import ItemSelected from './ItemSelected'
import ModalPreOrder from '../../../../modals/ModalPreOrders'
import useOrderByTable from '../../../../hooks/useOrderByTable'
import useStorage from '../../../../../../hooks/useStorage'
import LocalGuide from './LocalGuide'
import Booking from './Booking'
import useMenu from '../../../../../../hooks/useMenu'

export default function PreOrderOptions() {
    const { booking, alias, setAlias, preOrders, setPreOrders } = usePreOrder()
    const { by } = useParamsQuickSaleBy()
    const { init } = useMenu()
    const { orderId } = useOrderByTable()
    const [isBookingOpen, setIsBookingOpen] = useState(false)
    const [isPreOrderOpen, setIsPreOrderOpen] = useState(false)
    const { business_variables } = useStorage()

    const isEnabledPreOrders =
        business_variables.find(bv => bv.code === '017')?.value === '1'

    function closePreOrders() {
        setPreOrders([])
        init([])
    }
    /**
     * Si existe una orden (edición) no será posible cambiar la reserva
     */
    const isOrderAlready = !!orderId

    return (
        <Box w="full">
            {!isOrderAlready && (
                <ModalBookings
                    isOpen={isBookingOpen}
                    onClose={() => setIsBookingOpen(false)}
                />
            )}
            {booking && (
                <ModalPreOrder
                    bookingId={booking.id}
                    isOpen={isPreOrderOpen}
                    onClose={() => setIsPreOrderOpen(false)}
                />
            )}
            {by === 'tables' &&
                (booking?.direct_booking === false || !booking) && (
                    <LocalGuide />
                )}
            {((booking && booking.direct_booking) ||
                by === 'pre_orders' ||
                (!isOrderAlready && !booking)) && <Booking />}
            {isEnabledPreOrders &&
                by === 'tables' &&
                booking?.type === 1 &&
                !isOrderAlready && (
                    <Flex gap={2} align="center">
                        <Text whiteSpace="nowrap">Pre-pedido</Text>
                        {preOrders.length === 0 && (
                            <Text
                                as="b"
                                color="dark-2"
                                cursor="pointer"
                                decoration="underline"
                                onClick={() => setIsPreOrderOpen(true)}
                                whiteSpace="nowrap"
                            >
                                Sin Pre-pedidos
                            </Text>
                        )}
                        {preOrders.length > 0 && (
                            <ItemSelected
                                onClick={() => setIsPreOrderOpen(true)}
                                onRemove={closePreOrders}
                            >
                                {preOrders
                                    .map(preOrder => preOrder.alias)
                                    .join(', ')}
                            </ItemSelected>
                        )}
                    </Flex>
                )}
            {booking?.type === 1 && by === 'pre_orders' && (
                <Input
                    bg="white"
                    mt={2}
                    placeholder="Nombre del cliente"
                    onChange={ev => setAlias(ev.target.value)}
                    value={alias}
                />
            )}
        </Box>
    )
}
