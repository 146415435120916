import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react'
import BodyContent from './BodyContent'
import { ModalFormClientProps } from './types'
import useSubmit from './useSubmit'

export default function ModalFormClient({
    element,
    onDone = () => null,
    edition
}: ModalFormClientProps) {
    const {
        submitHandler,
        isLoading,
        errorHelper,
        isOpen,
        setOpen,
        handleChange,
        openHandler,
        form,
        handleChangeForm,
        handleSearchSunat
    } = useSubmit(onDone, edition)

    return (
        <>
            {element(openHandler)}
            <Modal
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                size="lg"
                closeOnOverlayClick
            >
                <ModalOverlay />
                <ModalContent px={{ base: 3, md: 6 }} py={4}>
                    <ModalHeader>
                        {edition ? 'Editar cliente' : 'Nuevo cliente'}{' '}
                    </ModalHeader>
                    <form onSubmit={submitHandler} noValidate>
                        <ModalBody pb={6}>
                            <BodyContent
                                errorHelper={errorHelper}
                                onChanges={handleChange}
                                edition={edition}
                                form={form}
                                handleChangeForm={handleChangeForm}
                                handleSearchSunat={handleSearchSunat}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                variant="alo-gray:lg-md"
                                bg="transparent"
                                color="alo-gray"
                                onClick={() => setOpen(false)}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="alo-green:lg-md"
                                ml={3}
                                type="submit"
                                isLoading={isLoading}
                            >
                                Confirmar
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}
