import { Box } from '@chakra-ui/react'
import { render } from 'redity'
import { get } from '../../../../lib/http'
import Keys from '../../keys'
import { ItemData, storagePage } from '../../storage'

export default function BoxCategory({
    id,
    name
}: {
    id: number
    name: string
}) {
    async function clickHandler() {
        storagePage.selectedCategoryId = id
        render(Keys.LIST_CATEGORIES)
        const { data } = await get<ItemData[]>(
            `/api/items?category_item_id=${id}`
        )
        storagePage.items = data.result
        render(Keys.LIST_ITEM)
    }

    const { selectedCategoryId } = storagePage

    return (
        <Box
            key={id}
            minH={{ base: '30px', lg: '60px' }}
            borderRadius="10px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontSize={{ base: 'sm', xl: 'md' }}
            cursor="pointer"
            whiteSpace={{ base: 'nowrap', lg: 'normal' }}
            w="100%"
            textAlign="center"
            p="0 10px"
            bg={{
                base: selectedCategoryId === id ? 'dark' : 'transparent',
                lg: selectedCategoryId === id ? 'white' : 'transparent'
            }}
            color={{
                base: selectedCategoryId === id ? 'white' : 'dark',
                lg: selectedCategoryId === id ? 'dark' : 'white'
            }}
            border="2px solid"
            borderColor={{ base: 'dark', lg: 'white' }}
            onClick={clickHandler}
        >
            {name}
        </Box>
    )
}
