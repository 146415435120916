import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Spinner,
    Text
} from '@chakra-ui/react'
import { ModalSendProps } from '../types'
import useSendValidate from '../hooks/useSendValidate'

export default function ModalSend({
    element,
    filename,
    typeVoucherId,
    sunatVoucherId
}: ModalSendProps) {
    const { isOpen, setOpen, isLoading, openHandler, error } = useSendValidate(
        filename,
        typeVoucherId,
        sunatVoucherId
    )

    return (
        <>
            {element(openHandler)}
            <Modal
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                size="md"
                isCentered
                closeOnOverlayClick={!isLoading}
            >
                <ModalOverlay />
                <ModalContent px={6} py={4}>
                    <ModalBody>
                        <Text
                            fontSize="2xl"
                            fontWeight="bold"
                            textAlign="center"
                            mb="5"
                        >
                            {error
                                ? 'Error al enviar a sunat'
                                : 'Validando comprobante en sunat'}
                        </Text>
                        {isLoading && (
                            <Flex justifyContent="center" py="16">
                                <Spinner size="md" />
                            </Flex>
                        )}
                        {error && <Text>{error}</Text>}
                        {error && (
                            <Flex mt="8" justifyContent="center">
                                <Button
                                    variant="outline"
                                    w="36"
                                    onClick={() => {
                                        setOpen(false)
                                    }}
                                >
                                    Aceptar
                                </Button>
                            </Flex>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
