import useSubmit from '../../../hooks/useSubmit'
import modal from '../../../lib/modal'
import PreparationDestiny from '../../../models/preparation_destiny'
import { formDestiny, listDestiny } from '../storage'
import FormDestiny from './FormDestiny'

export default function useDestiny(destiny?: PreparationDestiny) {
    const { validation, submit, observer } = useSubmit(formDestiny, {
        path: destiny
            ? `/api/preparation_destinations/${destiny.id}`
            : '/api/preparation_destinations',
        method: destiny ? 'put' : 'post',
        done: () => listDestiny.load()
    })

    validation((v, f) => {
        v.name.isEmpty('Es requerido el nombre')
        v.connectionType.isEmpty('Seleccione un tipo de conexión')
        if (f.connectionType === '1') {
            v.ip.isIP('La IP no es válida').isEmpty('Es requerido la IP')
        }
    })

    observer(f => ({
        name: f.name,
        connection_type: Number(f.connectionType),
        ip: f.connectionType === '1' ? f.ip : null
    }))

    function openModal() {
        if (destiny) {
            formDestiny.store({
                name: destiny.name,
                ip: destiny.ip,
                connectionType: destiny.connection_type.toString()
            })
            formDestiny.init(formDestiny.store)
        } else {
            formDestiny.init()
        }
        modal(FormDestiny, {
            title: destiny
                ? 'Editar destino de preparación'
                : 'Nuevo destino de preparación',
            onSubmit: submit
        })
    }

    return openModal
}
