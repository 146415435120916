import { Flex, Text } from '@chakra-ui/react'
import { ItemSearchProps } from '../../types'

export default function ItemSearch({
    onSelect,
    client,
    isActive
}: ItemSearchProps) {
    return (
        <Flex
            py="2"
            px="4"
            bg={isActive ? 'gray.100' : 'white'}
            cursor="pointer"
            _hover={{
                bg: isActive ? 'gray.100' : 'gray.50'
            }}
            justify="space-between"
            align="center"
            onClick={() => onSelect(client)}
        >
            <Text>
                {client.type_identification_document_id === 2
                    ? client.business_name
                    : `${client.firstname} ${client.lastname}`}
                <Text as="span" color="blackAlpha.700" fontSize="xs" ml={2}>
                    {client.number}
                </Text>
            </Text>
        </Flex>
    )
}
