import { useState } from 'react'
import { useGet } from '../../../hooks/useHttp'
import modal, { close } from '../../../lib/modal'
import AditionalInformationCategory from '../../../models/aditional_information_category'
import Confirm from '../ContentModals/Confirm'
import { formItem } from '../storage'
import useSubmitModalConfirm from './useSubmitModalConfirm'

export default function usePreferences() {
    const submitHandler = useSubmitModalConfirm()
    const [valuesPreferences, setValuesPreferences] = useState<
        Record<string, string>
    >({})

    const { data, isLoading } = useGet<AditionalInformationCategory[]>(
        `/api/aditional_informations?category_item_id=${
            formItem.store().category_item_id
        }&sort=description`,
        []
    )

    function openModalConfirm() {
        close()
        setTimeout(() => {
            modal(Confirm, {
                size: 'lg',
                cleaned: true,
                onSubmit: submitHandler
            })
        }, 150)
    }

    function clickAcceptHandler() {
        const { description } = formItem.store()
        const values = [description, ...Object.values(valuesPreferences)]
        if (description.trim() === '') {
            values.shift()
        }
        formItem.store.set('description', values.join(', '))
        openModalConfirm()
    }

    function clickCancelHandler() {
        openModalConfirm()
    }

    function checkedHandler(isChecked: boolean, id: number) {
        const values: Record<string, string> = {
            ...valuesPreferences,
            [id]: data.result.find(preference => preference.id === id)
                ?.description
        }

        if (!isChecked) {
            delete values[id]
        }

        setValuesPreferences(values)
    }

    return {
        preferences: data.result,
        isLoading,
        clickAcceptHandler,
        clickCancelHandler,
        valuesPreferences,
        checkedHandler
    }
}
