import { FeatureSetting } from '../../../../types/runway_state'
import { ADDITIONAL_INFORMATION_DIVIDER } from '../../config'
import { FeatureSelected } from '../../ContentModals/IceCream/types'

export function getDataForEdition(featureSettings: FeatureSetting[]) {
    function getFSSFs(
        featureSettingSubFeatures: FeatureSetting['feature_setting_sub_features']
    ) {
        const newFeatureSettingSubFeatures: FeatureSelected['subs'] = []

        for (const featureSettingSubFeature of featureSettingSubFeatures) {
            for (let i = 0; i < featureSettingSubFeature.quantity; i++) {
                newFeatureSettingSubFeatures.push({
                    sub_feature_id: featureSettingSubFeature.sub_feature_id,
                    name: featureSettingSubFeature.name,
                    price: featureSettingSubFeature.price,
                    feature_setting_sub_feature_id:
                        featureSettingSubFeature.feature_setting_sub_feature_id,
                    aditional_information:
                        featureSettingSubFeature.aditional_information.split(
                            ADDITIONAL_INFORMATION_DIVIDER
                        )[i] || ''
                })
            }
        }

        return newFeatureSettingSubFeatures
    }

    return featureSettings.map(featureSetting => ({
        feature_id: featureSetting.feature_id,
        quantity: featureSetting.quantity,
        name: featureSetting.name,
        feature_setting_id: featureSetting.feature_setting_id,
        subs: getFSSFs(featureSetting.feature_setting_sub_features)
    }))
}
