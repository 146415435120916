import { Box, Flex } from '@chakra-ui/react'
import { useRender } from 'redity'
import Keys from '../keys'
import { storagePage } from '../storage'
import SidebarCategories from './SidebarCategories'
import ListItems from './ListItems'
import Searcher from './Searcher'

export default function ContentLeft() {
    useRender(Keys.CONTENT_LEFT)
    const { hasOrderTable, editOrderTable } = storagePage

    return (
        <Box w="full" h="full" position="relative">
            <Searcher />
            <Flex
                w="full"
                h={{ base: 'calc(100vh - 196px)', lg: 'calc(100vh - 123px)' }}
                position="relative"
                flexDirection={{ base: 'column', lg: 'row' }}
            >
                <SidebarCategories />
                <ListItems />
            </Flex>
            {hasOrderTable && !editOrderTable && (
                <Box
                    position="absolute"
                    top="0"
                    left={0}
                    width="100%"
                    h="100%"
                    bg="dark-2"
                    opacity="0.5"
                    zIndex={2}
                />
            )}
        </Box>
    )
}
