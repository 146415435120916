import { Icon, Td, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import Printer from '../../../components/Icon/Printer'
import { post } from '../../../lib/http'
import getPrinterConfig from '../../../utilities/getPrinterConfig'

export default function TdPrinter({
    cashboxUserId
}: {
    cashboxUserId: number
}) {
    const toast = useToast()
    const [isLoading, setLoading] = useState(false)

    async function clickPrintHandler() {
        // const ips = getIPsConfig()
        const printerConfig = getPrinterConfig()
        if (printerConfig) {
            setLoading(true)
            await post('/api/printer/close_cashbox', {
                printer_id: Number(printerConfig.printer_id),
                cashbox_user_id: cashboxUserId
            })
            setLoading(false)
        } else {
            toast({
                title: 'Lo sentimos, se requiere configurar la impresora para el cierre de caja',
                position: 'top',
                status: 'error'
            })
        }
    }

    return (
        <Td>
            <Icon
                as={Printer}
                fill={'gray-2'}
                opacity={isLoading ? '.6' : '1'}
                pointerEvents={isLoading ? 'none' : 'auto'}
                cursor="pointer"
                fontSize="xl"
                onClick={clickPrintHandler}
            />
        </Td>
    )
}
