import { Box, Button } from '@chakra-ui/react'
import BackSpace from '../../../../components/Icon/BackSpace'
import { ButtonsActionsProps } from './types'

const buttonWidth = { base: '65px', sm: '80px', md: '90px' }

export default function ButtonsAction({
    onClear,
    onOk,
    onRemove,
    isLoading
}: ButtonsActionsProps) {
    return (
        <Box display="flex" flexDirection="column" gridGap="3">
            <Button
                w={buttonWidth}
                h="20"
                colorScheme="orange"
                rounded="xl"
                onClick={onRemove}
                fill="white"
                leftIcon={<BackSpace />}
            />
            <Button
                colorScheme="orange"
                rounded="xl"
                h="20"
                w={buttonWidth}
                onClick={onClear}
                fontSize="xl"
            >
                Clear
            </Button>
            <Button
                colorScheme="green"
                rounded="xl"
                h="20"
                fontSize="xl"
                onClick={onOk}
                isLoading={isLoading}
                w={buttonWidth}
            >
                Ok
            </Button>
        </Box>
    )
}
