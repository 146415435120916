import { Icon } from '@chakra-ui/react'
import Pencil from '../../../components/Icon/Pencil'
import Colors from '../../../config/colors'
import Driver from '../../../models/driver'
import useDriver from './useDriver'

export default function Edition({ driver }: { driver: Driver }) {
    const openModal = useDriver(driver)

    return (
        <Icon
            fill={Colors.INFO}
            as={Pencil}
            marginRight={2}
            cursor="pointer"
            onClick={openModal}
        />
    )
}
