import { useToast } from '@chakra-ui/react'
//import dayjs from 'dayjs'
import { ChangeEvent, FormEvent, useState } from 'react'
import { post } from '../../lib/http'
import val from '../../lib/val'
import useSession from '../../hooks/useSession'

export default function useAnull(
    order_payment_id: number,
    sunat_voucher_id: number,
    type_voucher: number,
    onDone: () => void
) {
    const [isOpen, setOpen] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [helpers, setHelpers] = useState<{ comment?: string }>({})
    const toast = useToast()
    const { isLogged, session } = useSession()

    async function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const formElem = ev.target as typeof ev.target & {
            comment: { value: string }
        }

        const v = val({
            comment: formElem.comment.value
        })

        v.comment.isEmpty('Es requerido ingresar el motivo')

        const result = v.runtest()
        if (result) {
            setHelpers(result)
            return
        }

        setLoading(true)

        //Nota de Venta
        if (type_voucher === 4) {
            const { error } = await post<any>(
                `/api/v2/order_payments/${order_payment_id}/annul`,
                {
                    order_payment_id,
                    comment: formElem.comment.value,
                    user_id_cancel:
                        isLogged && session !== null ? session.user.id : null
                }
            )

            if (error) {
                toast({
                    title: error.message,
                    status: 'error',
                    position: 'top'
                })
                setOpen(false)
            } else {
                toast({
                    title: 'Venta anulada',
                    status: 'success',
                    position: 'top',
                    isClosable: true
                })
                setOpen(false)
            }
        } else {
            //Comprobante electrónico
            const { error } = await post<any>(
                `/api/sunat_vouchers/${sunat_voucher_id}/annular`,
                {
                    sunat_voucher_id,
                    //issue_date: dayjs().format(),
                    comment: formElem.comment.value,
                    user_id_cancel:
                        isLogged && session !== null ? session.user.id : null
                }
            )

            if (error) {
                toast({
                    title: error.message,
                    status: 'error',
                    position: 'top'
                })
                setOpen(false)
            } else {
                toast({
                    title: 'Venta anulada',
                    status: 'success',
                    position: 'top',
                    isClosable: true
                })
                setOpen(false)
            }
        }

        onDone()
        setLoading(false)
    }

    function changeHandler(
        ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const name = ev.target.name as keyof typeof helpers
        if (helpers[name]) {
            setHelpers({
                ...helpers,
                [name]: ''
            })
        }
    }

    return {
        isOpen,
        setOpen,
        submitHandler,
        isLoading,
        helpers,
        setHelpers,
        changeHandler
    }
}
