import { Box, FormControl, FormLabel, Textarea } from '@chakra-ui/react'
import React from 'react'

interface Props {
    handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

export default function Reason({ handleChange }: Props) {
    return (
        <Box bg="white" rounded={'lg'} p={4}>
            <FormControl mb={4}>
                <FormLabel>Motivo</FormLabel>
                <Textarea
                    maxLength={300}
                    placeholder="Ingresar motivo"
                    resize={'none'}
                    onChange={handleChange}
                />
            </FormControl>
        </Box>
    )
}
