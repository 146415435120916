import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useRender } from 'redity'
import Keys from './keys'
import { controlList } from './storage'
import load from './utilities/load'

export default function useInitial() {
    useRender(Keys.MAIN)
    useEffect(() => {
        controlList.setQuery('issue_date', dayjs().format('YYYY-MM-DD'))
        load()
    }, [])
}
