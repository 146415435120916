import { SVGProps } from 'react'

const Table = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 34 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M33.7362 8.81979C33.5546 9.24587 33.2274 9.38733 32.7881 9.37455C32.1713 9.35665 31.5537 9.37029 30.8875 9.37029V9.81171C30.8875 14.147 30.8875 18.4819 30.8875 22.8166C30.8875 23.0109 30.9009 23.2223 30.8348 23.3961C30.5955 24.0165 30.0465 23.8742 29.5703 23.8793C29.1401 23.8844 28.8832 23.7037 28.7954 23.3458C28.7608 23.1873 28.7453 23.025 28.7493 22.8626C28.7493 18.5064 28.7493 14.1498 28.7493 9.79296V9.38904H26.617V16.9605C26.617 17.8911 26.4287 18.0794 25.5031 18.0794H25.3115C24.7608 18.0692 24.4922 17.8025 24.4679 17.2409C24.4679 17.1557 24.4679 17.0705 24.4679 16.9801V9.39585H10.2244V9.79467C10.2244 12.2177 10.2244 14.6404 10.2244 17.0628C10.2244 17.8357 9.98674 18.0777 9.23356 18.0786C9.12727 18.0786 9.02015 18.0786 8.91387 18.0786C8.36656 18.0683 8.08956 17.7974 8.077 17.2349C8.06612 16.7466 8.077 16.2575 8.077 15.7684V9.3933H5.9455V22.7501C5.9455 23.7037 5.76641 23.8835 4.8174 23.8827C4.68948 23.8871 4.56142 23.8825 4.43412 23.869C4.27865 23.8542 4.13316 23.7845 4.02283 23.672C3.91249 23.5595 3.84424 23.4113 3.82989 23.2529C3.81136 23.1023 3.80325 22.9505 3.80563 22.7987C3.80563 18.4743 3.80563 14.1501 3.80563 9.8262V9.37029C3.25832 9.37029 2.74866 9.37029 2.23901 9.37029C2.04736 9.37029 1.85405 9.37796 1.66408 9.36092C1.23309 9.32257 0.973661 9.06351 0.962782 8.62294C0.946882 7.91735 0.936843 7.2092 0.971992 6.50531C0.995552 6.27039 1.09504 6.05008 1.25485 5.87897C2.81394 4.24025 4.3906 2.61858 5.95889 0.989236C6.05729 0.882639 6.17682 0.798565 6.30956 0.742589C6.4423 0.686614 6.58521 0.660016 6.72881 0.664561C13.7808 0.669106 20.8331 0.669106 27.8857 0.664561C28.0294 0.660722 28.1722 0.687811 28.3049 0.744053C28.4375 0.800296 28.5571 0.884427 28.6556 0.990942C30.2364 2.61006 31.8248 4.21639 33.4032 5.8355C33.5412 5.97782 33.6274 6.17211 33.7379 6.34254L33.7362 8.81979Z" />
    </svg>
)

export default Table
