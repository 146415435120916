import useSubmit from '../../../hooks/useSubmit'
import modal from '../../../lib/modal'
import Supply from '../../../models/supply'
import { formSupply, listSupply } from '../storage'
import FormResource from './FormSupply'

export default function useSupply(supply?: Supply) {
    const { validation, observer, submit } = useSubmit(formSupply, {
        path: supply ? `/api/supplies/${supply.id}` : '/api/supplies',
        method: supply ? 'put' : 'post',
        done: () => listSupply.load()
    })

    validation((v, f) => {
        v.name.isEmpty('Es requerido el nombre')
        v.type_measure_id.isEmpty('Es requerido asignar una unidad de medida')
        if (f?.has_inventory_control && !supply?.id) {
            v.stock
                .isEqual(0, 'El stock debe ser mayor a 0')
                .isEmpty('Es requerido asignar el stock')
        }
    })

    observer<any>(f => ({
        name: f.name,
        type_measure_id: Number(f.type_measure_id),
        default_visual_measure_id: Number(f.type_measure_id),
        has_inventory_control: f.has_inventory_control,
        ...(f?.has_inventory_control && {
            stock: Number(f.stock)
        })
    }))

    function openModal() {
        if (supply) {
            formSupply.store({
                id: supply.id.toString() || '',
                name: supply.name,
                has_inventory_control: supply.has_inventory_control,
                default_visual_measure_id: supply.type_measure_id.toString(),
                stock: supply.stock?.toString() || '',
                type_measure_id: supply.type_measure_id.toString()
            })
            formSupply.init(formSupply.store)
        } else {
            formSupply.init()
        }
        modal(FormResource, {
            title: supply ? 'Editar Insumo' : 'Nuevo Insumo',
            onSubmit: submit
        })
    }

    return openModal
}

// interface FormSubmit {
//     name: string
//     price: number
//     category_item_id: number
//     image_url: string
// }
