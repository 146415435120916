import { Icon } from '@chakra-ui/react'
import Trash from '../../../components/Icon/Trash'
import colors from '../../../config/theme/colors'
import Delete from '../../../lib/delete'
import Driver from '../../../models/driver'
import { listDriver, PATH_ENDPOINT_DRIVER } from '../storage'

export default function DeleteDriver({ driver }: { driver: Driver }) {
    return (
        <Delete
            title="Eliminar guía local"
            path={`${PATH_ENDPOINT_DRIVER}/${driver.id}`}
            element={click => (
                <Icon
                    onClick={click}
                    fill={colors.error}
                    as={Trash}
                    cursor="pointer"
                />
            )}
            done={() => {
                listDriver.setQuery('page', 1)
                listDriver.load()
            }}
        >
            ¿Esta seguro de eliminar la guía local <b>{driver.name}</b>?
        </Delete>
    )
}
