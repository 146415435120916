import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { post } from '../../../lib/http'
import JoinTable from '../../../models/join_table'
import Table from '../../../models/table'

export interface TableJoin extends Table {
    join_tables: JoinTable[]
}

export default function useSeparateTables(onDone: () => void) {
    const [isLoading, setIsLoading] = useState(false)
    const [tables, setTables] = useState<TableJoin[]>([])
    const toast = useToast()

    async function submit() {
        setIsLoading(true)
        const data = await post(`/api/tables/separate`, {
            join_table_ids: tables
                .map(table =>
                    table.join_tables.map(join_table => join_table.id)
                )
                .flat()
        })
        if (data.error) {
            toast({
                title: data.error.message,
                position: 'top',
                status: 'error'
            })
        } else {
            onDone()
        }
        setIsLoading(false)
    }

    return {
        isLoading,
        tables,
        setTables,
        submit
    }
}
