import { useContext } from 'react'
import access from '../config/access'
import AuthContext from '../context/AuthContext'
import useStorage from './useStorage'

export default function useSession() {
    const { session } = useContext(AuthContext)

    function isAdmin() {
        if (!session) return false

        return !!session.roles.find(role => role.id === 1)
    }

    function isCashier() {
        if (!session) return false

        return !!session.roles.find(role => role.id === 2)
    }

    function isWaiter() {
        if (!session) return false

        return !!session.roles.find(role => role.id === 3)
    }

    function isDealer() {
        if (!session) return false
        return !!session.roles.find(role => role.id === 4)
    }

    function getAccessRoutes() {
        let role_id = 3
        if (session) {
            role_id = session.roles[0].id
        }

        return access[role_id].routes
    }

    function getRedirect() {
        let role_id = 3
        if (session) {
            role_id = session.roles[0].id
        }

        return access[role_id].redirect
    }

    function userCanCancelItems() {
        const { business_variables } = useStorage()
        const disable_cancel_items_by_cashier = business_variables.find(
            v => v.code === '012'
        )?.value

        return (
            isAdmin() ||
            (isCashier() && disable_cancel_items_by_cashier === '0')
        )
    }

    return {
        isAdmin,
        isCashier,
        isWaiter,
        isDealer,
        getAccessRoutes,
        getRedirect,
        isLogged: !!session,
        session,
        userCanCancelItems
    }
}
