import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Textarea,
    useDisclosure,
    HStack,
    Select,
    Text,
    FormErrorMessage
} from '@chakra-ui/react'
import { ChangeEvent, useEffect, useState } from 'react'
import colors from '../../../../../config/theme/colors'
import useStorage from '../../../../../hooks/useStorage'
import useSubmitStock from '../../../hooks/useSubmitStock'
import StockQuantity from './StockQuantity'
import { ModalStockProps } from './types'

export default function ModalStock({
    element,
    warehouses,
    inventoryItemWarehouse,
    onDone
}: ModalStockProps) {
    const { onOpen, onClose, isOpen } = useDisclosure()
    const { type_inventory_operations } = useStorage()
    const {
        isLoading,
        submitHandler,
        fieldMessages,
        setFieldMessages,
        onChange
    } = useSubmitStock(doneHandler)

    function doneHandler() {
        onDone()
        onClose()
    }

    useEffect(() => {
        setFieldMessages({})
    }, [isOpen])
    const [typeInventoryOperationId, setTypeInventoryOperationId] = useState('')

    function typeInvetoryOperationHandler(ev: ChangeEvent<HTMLSelectElement>) {
        onChange(ev)
        setTypeInventoryOperationId(ev.target.value)
    }

    return (
        <>
            {element(onOpen)}
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size="md"
                closeOnOverlayClick
            >
                <ModalOverlay />
                <ModalContent px={2} py={4}>
                    <form onSubmit={submitHandler} noValidate>
                        <ModalBody pb={6}>
                            <Text
                                fontSize="2xl"
                                fontWeight="bold"
                                textAlign="center"
                            >
                                Actualización de Stock
                            </Text>
                            <Text
                                textAlign="center"
                                fontSize="sm"
                                color={colors['gray-2']}
                                mb="5"
                            >
                                {inventoryItemWarehouse.inventory_item.name}
                            </Text>
                            <input
                                type="hidden"
                                name="inventory_item_id"
                                value={inventoryItemWarehouse.inventory_item.id}
                            />
                            <HStack mb={4} spacing={4}>
                                <FormControl
                                    isRequired
                                    isInvalid={
                                        !!fieldMessages.type_inventory_operation_id
                                    }
                                >
                                    <FormLabel>Movimiento</FormLabel>
                                    <Select
                                        placeholder="Seleccionar movimiento"
                                        name="type_inventory_operation_id"
                                        onChange={typeInvetoryOperationHandler}
                                    >
                                        {type_inventory_operations.map(
                                            typeInventoryOperation => (
                                                <option
                                                    key={
                                                        typeInventoryOperation.id
                                                    }
                                                    value={
                                                        typeInventoryOperation.id
                                                    }
                                                >
                                                    {
                                                        typeInventoryOperation.name
                                                    }
                                                </option>
                                            )
                                        )}
                                    </Select>
                                    <FormErrorMessage>
                                        {
                                            fieldMessages.type_inventory_operation_id
                                        }
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    isRequired
                                    isInvalid={!!fieldMessages.warehouse_id}
                                >
                                    <FormLabel>Almacén</FormLabel>
                                    <Select
                                        placeholder="Seleccionar almacén"
                                        name="warehouse_id"
                                        defaultValue={inventoryItemWarehouse.warehouse_id.toString()}
                                        onChange={onChange}
                                    >
                                        {warehouses.map(warehouse => (
                                            <option
                                                key={warehouse.id}
                                                value={warehouse.id}
                                            >
                                                {warehouse.name}
                                            </option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage>
                                        {fieldMessages.warehouse_id}
                                    </FormErrorMessage>
                                </FormControl>
                            </HStack>

                            <StockQuantity
                                stock={inventoryItemWarehouse.stock}
                                message={fieldMessages.operation_quantity || ''}
                                onChange={onChange}
                                typeInventoryOperationId={
                                    +typeInventoryOperationId
                                }
                            />

                            <FormControl
                                isInvalid={!!fieldMessages.comment}
                                isRequired
                            >
                                <FormLabel>Comentario</FormLabel>
                                <Textarea
                                    placeholder="Ingresar comentario"
                                    name="comment"
                                    onChange={onChange}
                                />
                                <FormErrorMessage>
                                    {fieldMessages.comment}
                                </FormErrorMessage>
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                variant="alo-gray:lg-md"
                                bg="transparent"
                                color="alo-gray"
                                onClick={onClose}
                                pointerEvents={isLoading ? 'none' : 'auto'}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="alo-green:lg-md"
                                ml={3}
                                type="submit"
                                isLoading={isLoading}
                            >
                                Confirmar
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}
