import { Button, ModalFooter } from '@chakra-ui/react'
import { FooterButtonsProps } from './types'

export default function FooterButtons({
    onClose,
    onConfirm,
    isDisabled
}: FooterButtonsProps) {
    return (
        <ModalFooter>
            <Button variant="outline" fontSize="md" size="lg" onClick={onClose}>
                Cancelar
            </Button>
            <Button
                colorScheme="green"
                ml={2}
                fontWeight="normal"
                size="lg"
                onClick={onConfirm}
                isDisabled={isDisabled}
            >
                Confirmar
            </Button>
        </ModalFooter>
    )
}
