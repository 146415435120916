import { ChangeEvent, FormEvent, useState } from 'react'
import val from '../../../../lib/val'

export default function useDialogEditOrder(
    update: (reason?: string | null) => Promise<void>
) {
    const [isOpen, setOpen] = useState(false)
    const [isLoading] = useState(false)
    const [helpers, setHelpers] = useState<{ reason?: string }>({})

    async function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const formElem = ev.target as typeof ev.target & {
            reason: { value: string }
        }

        const v = val({
            reason: formElem.reason.value
        })

        v.reason.isEmpty('Es requerido ingresar el motivo')

        const result = v.runtest()
        if (result) {
            setHelpers(result)
            return
        }
        setOpen(false)
        update(formElem.reason.value)
    }

    function changeHandler(
        ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const name = ev.target.name as keyof typeof helpers
        if (helpers[name]) {
            setHelpers({
                ...helpers,
                [name]: ''
            })
        }
    }

    return {
        isOpen,
        setOpen,
        submitHandler,
        isLoading,
        setHelpers,
        changeHandler,
        helpers
    }
}
