import { Box, Heading, Text } from '@chakra-ui/react'
import { storagePage } from '../storage'
import ListItems from './ListItems'
import BoxTotal from '../../../components/BoxTotal'

export default function ProductsSection() {
    const total = storagePage.matrix_items
        .map(order_item => order_item.final_price_item)
        .reduce((prev, curr) => prev + curr, 0)
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            h="full"
            px={2}
        >
            <Box px={4}>
                <Heading size="md" textAlign="center" mb={6}>
                    Detalle de pedido
                </Heading>
                <ListItems />
            </Box>
            <Box px="8">
                <BoxTotal border>
                    <Text fontSize="20px">TOTAL</Text>
                    <Text fontSize="20px">S/ {total.toFixed(2)}</Text>
                </BoxTotal>
            </Box>
        </Box>
    )
}
