import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Table,
    TableContainer,
    Td,
    Thead,
    Tr,
    useDisclosure
} from '@chakra-ui/react'
import BookingsBody from './BookingsTBody'
import { BookingsModalProps } from './types'

export default function BookingsModal({ date }: BookingsModalProps) {
    const { isOpen, onClose, onOpen } = useDisclosure()

    return (
        <>
            <Button
                rounded="full"
                w={['full', 'full', 'auto']}
                variant="outline"
                colorScheme="blue"
                onClick={onOpen}
            >
                Ver reservas
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} size="3xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Reservas</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <TableContainer>
                            <Table size="sm" className="table-a-la-orden">
                                <Thead>
                                    <Tr>
                                        <Td>#</Td>
                                        <Td>Fecha y hora</Td>
                                        <Td>Cliente</Td>
                                        <Td>Tipo</Td>
                                        <Td>N° de personas</Td>
                                        <Td>Comentario</Td>
                                    </Tr>
                                </Thead>
                                <BookingsBody date={date} />
                            </Table>
                        </TableContainer>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="outline" onClick={onClose}>
                            Cerrar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
