import React from 'react'
import {
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberDecrementStepper,
    NumberIncrementStepper
} from '@chakra-ui/react'

interface Props {
    valueForm: string | number
    placeholder: string
    handleChange: (
        value: string,
        index: number,
        name: string,
        maxValue?: number
    ) => void
    name: string
    precision?: number
    index: number
    isReadOnly?: boolean
    max?: number
}

export default function CustomNumberInput({
    handleChange,
    valueForm,
    placeholder,
    name,
    precision = 0,
    index,
    isReadOnly = false,
    max
}: Props) {
    return (
        <NumberInput
            onChange={value => handleChange(value, index, name, max)}
            placeholder={placeholder}
            value={valueForm}
            precision={precision}
            min={0}
            max={max}
            isReadOnly={isReadOnly}
            isDisabled={isReadOnly}
        >
            <NumberInputField />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
    )
}
