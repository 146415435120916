import { Button } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import writeXlsxFile, { Row, SheetData } from 'write-excel-file'
import DownloadIcon from '../../../components/Icon/Download'
import { TouristGroup } from '../hooks/useTouristGruops'

interface DownloadProps {
    touristGroups: TouristGroup[]
    date: string
}

export default function Download({ date, touristGroups }: DownloadProps) {
    const [isLoading, setLoading] = useState(false)
    async function download() {
        const head: (...values: string[]) => Row[number][] = (...values) =>
            values.map<Row[number]>((val, i) => ({
                value: val,
                fontWeight: 'bold',
                wrap: true,
                alignVertical: 'center',
                align: i === 0 ? 'left' : 'center'
            }))

        const rows: SheetData = [
            [],
            [
                {
                    value: 'Reporte de Comprobante',
                    fontWeight: 'bold',
                    span: 4,
                    height: 24,
                    alignVertical: 'center',
                    align: 'left',
                    fontSize: 15
                }
            ],
            [
                {
                    value: `Mes: ${dayjs(date).format('MMMM')}`,
                    span: 4,
                    align: 'left'
                }
            ],
            [],
            [],
            head(
                'Grupo Turístico',
                'N° de Reservas',
                'Platos Vendidos',
                'Venta Total',
                'Comisión'
            )
        ]

        touristGroups.forEach(touristGroup => {
            rows.push([
                {
                    value: touristGroup.groupTouristName,
                    type: String
                },
                {
                    value: touristGroup.nBookings,
                    type: Number
                    // format: '#,##0.00'
                },
                {
                    value: touristGroup.soldPlates,
                    type: Number,
                    format: '#,##0.00'
                },
                {
                    value: touristGroup.totalSales,
                    type: Number,
                    format: '#,##0.00'
                },
                {
                    value: touristGroup.commission,
                    type: Number,
                    format: '#,##0.00'
                }
            ])
        })

        await writeXlsxFile(rows, {
            fileName: `Comisión_por_reservas_${date}.xlsx`,
            columns: [
                {
                    width: 50
                },
                {
                    width: 12
                },
                {
                    width: 12
                },
                {
                    width: 12
                },
                {
                    width: 12
                }
            ],
            fontSize: 10,
            fontFamily: 'Calibri'
        })
        setLoading(false)
    }

    return (
        <Button
            variant="alo-gray:md-xl"
            onClick={download}
            isLoading={isLoading}
            rightIcon={<DownloadIcon fill="white" />}
        >
            Descargar
        </Button>
    )
}
