import { Box, Flex, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'

export function ResultItem({ title, value }: { title: string; value: number }) {
    return (
        <Flex justifyContent="space-between">
            <Text>{title}</Text>
            <Text>S/ {value.toFixed(2)}</Text>
        </Flex>
    )
}

export function OrderItem({ quantity, children, cost }: OrderItemProps) {
    return (
        <Flex justifyContent="space-between" mb={1}>
            <Flex fontSize="sm" mr={8}>
                <Text minW="8">{quantity}</Text>
                <Text>{children}</Text>
            </Flex>
            <Box whiteSpace="nowrap">S/ {(cost * quantity).toFixed(2)}</Box>
        </Flex>
    )
}

interface OrderItemProps {
    quantity: number
    children: ReactNode
    cost: number
}
