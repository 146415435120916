import {
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Box,
    Select,
    Textarea,
    useToast
} from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
import { ModalChangeStockProps } from './types'
import ErrorHelper from '../../../../components/ErrorHelper'
import NumberField from '../../../../components/NumberField'
import useStorage from '../../../../hooks/useStorage'
import useUpdateStock from '../../hooks/useUpdateStock'
import onlyNumber from '../../../../utilities/onlyNumber'

export default function ModalChangeStock({
    isOpen,
    onClose,
    item,
    reload
}: ModalChangeStockProps) {
    const [form, setForm] = useState({
        reason: '',
        quantity: '',
        type_movement_id: '',
        actual_stock: item.stock
    })
    const [helper, setHelper] = useState({
        type_movement_id: '',
        quantity: ''
    })

    const toast = useToast()

    const { movement_type } = useStorage()
    const { submit, isLoading } = useUpdateStock({
        handleFinish: () => {
            toast({
                status: 'success',
                title: 'Se actualizo el stock correctamente',
                position: 'top'
            })
            onClose()
            reload()
        },
        handleError: () => {
            toast({
                status: 'error',
                title: 'Ocurrio un error, al actualizar el stock',
                position: 'top'
            })
        }
    })

    const changeHandlerTypeMovement = (ev: ChangeEvent<HTMLSelectElement>) => {
        const value = ev.target.value

        if (value === '2') {
            if (Number(form.quantity) > Number(form.actual_stock)) {
                setForm({
                    ...form,
                    quantity: form.actual_stock.toString(),
                    type_movement_id: value
                })
                return
            }
        }
        setForm({
            ...form,
            type_movement_id: value
        })
        setHelper({
            ...helper,
            type_movement_id: ''
        })
    }

    const changeHandler = (ev: ChangeEvent<HTMLInputElement>) => {
        const value = onlyNumber(ev.target.value, {
            decimal: 2,
            min: 0,
            max:
                form.type_movement_id === '1' || form.type_movement_id === ''
                    ? Infinity
                    : Number(form.actual_stock),
            negative: false
        })

        setForm({
            ...form,
            quantity: value
        })
        setHelper({
            ...helper,
            quantity: ''
        })
    }

    const getNewStock = () => {
        if (!form.type_movement_id) return form.actual_stock

        return form.type_movement_id === '1'
            ? (Number(form.actual_stock) + Number(form.quantity)).toString()
            : (Number(form.actual_stock) - Number(form.quantity)).toString()
    }

    const isValid = () => {
        let errors: any = {}

        if (form.quantity === '') {
            errors = {
                ...errors,
                quantity: 'Es requerido'
            }
        } else if (Number(form.quantity) <= 0) {
            errors = {
                ...errors,
                quantity: 'La cantidad debe ser mayor a 0'
            }
        }

        if (form.type_movement_id === '') {
            errors = {
                ...errors,
                type_movement_id: 'Es requerido'
            }
        }
        setHelper({ ...errors })

        return Object.keys(errors).length === 0
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!isValid()) {
            return
        }

        await submit({
            warehouse_id: item.warehouse.id,
            reason: form.reason,
            type_change: form.type_movement_id === '1',
            production_items: [
                {
                    production_item_id: item.production_item.id,
                    quantity: Number(form.quantity),
                    unit_cost: item.cost
                }
            ]
        })
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="md"
            closeOnOverlayClick={!isLoading}
        >
            <ModalOverlay />
            <ModalContent px={2} py={4}>
                <ModalHeader pb={'0'}>
                    <Text fontSize="2xl" fontWeight="bold" textAlign="center">
                        Actualización de Stock
                    </Text>
                </ModalHeader>
                <form onSubmit={handleSubmit} noValidate>
                    <ModalBody pb={6}>
                        <Text mb="4" textAlign="center" color="#434343">
                            {item.production_item.name}
                        </Text>
                        <FormControl mb={4}>
                            <FormLabel>Almacén</FormLabel>
                            <Text>{item.warehouse.name}</Text>
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Movimiento</FormLabel>
                            <Select
                                value={form.type_movement_id}
                                onChange={changeHandlerTypeMovement}
                                placeholder="Seleccionar Tipo de Movimiento"
                            >
                                {movement_type.values.map(movementType => (
                                    <option
                                        key={movementType.value}
                                        value={movementType.value}
                                    >
                                        {movementType.description}
                                    </option>
                                ))}
                            </Select>
                            <ErrorHelper helper={helper.type_movement_id} />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Cantidad</FormLabel>
                            <NumberField
                                autoFocus
                                onChange={changeHandler}
                                placeholder="Ingresa cantidad"
                                value={form.quantity}
                            />
                            <ErrorHelper helper={helper.quantity} />
                        </FormControl>
                        <Box display="flex" justifyContent="space-between">
                            <FormControl mb={4}>
                                <FormControl>
                                    <FormLabel>Stock Actual</FormLabel>
                                    <Text fontWeight="bold" fontSize="3xl">
                                        {form.actual_stock}
                                    </Text>
                                </FormControl>
                            </FormControl>
                            <FormControl mb={4}>
                                <FormControl>
                                    <FormLabel>Nuevo Stock</FormLabel>
                                    <Text fontWeight="bold" fontSize="3xl">
                                        {getNewStock()}
                                    </Text>
                                </FormControl>
                            </FormControl>
                        </Box>
                        <FormControl mb={4}>
                            <FormLabel>Comentario</FormLabel>
                            <Textarea
                                value={form.reason}
                                onChange={e => {
                                    setForm(prev => {
                                        return {
                                            ...prev,
                                            reason: e.target.value
                                        }
                                    })
                                }}
                                maxLength={300}
                                placeholder="Ingresar comentario"
                                resize={'none'}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="alo-gray:lg-md"
                            bg="transparent"
                            color="alo-gray"
                            onClick={onClose}
                            disabled={isLoading}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="alo-green:lg-md"
                            ml={3}
                            type="submit"
                            isLoading={isLoading}
                        >
                            Confirmar
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}
