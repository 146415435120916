export default function CircleCheck(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 42 42"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 .167C9.5.167.167 9.5.167 21 .167 32.5 9.5 41.833 21 41.833c11.5 0 20.833-9.333 20.833-20.833C41.833 9.5 32.5.167 21 .167zm0 37.5c-9.188 0-16.667-7.48-16.667-16.667 0-9.188 7.48-16.667 16.667-16.667 9.187 0 16.666 7.48 16.666 16.667 0 9.187-7.479 16.666-16.666 16.666zM16.833 25.52l13.73-13.73L33.5 14.75 16.833 31.417 8.5 23.083l2.937-2.937 5.396 5.375z"
            />
        </svg>
    )
}
