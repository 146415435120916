import { Button, Flex, Input } from '@chakra-ui/react'
import Clean from '../../../../components/Clean'
import { listWarehouses } from '../../storage'
import WarehouseForm from './WarehouseForm'

export default function Actions({ reload }: { reload: () => void }) {
    const { clear, ...props } = listWarehouses.useSearch()

    return (
        <Flex
            mt={4}
            mb={4}
            w={{
                xl: 'auto',
                lg: 'full'
            }}
            justifyContent={{
                md: 'space-between',
                base: 'flex-end'
            }}
        >
            <Clean value={props.value} onClick={clear}>
                <Input {...props} placeholder="🏸 Buscar por almacén" />
            </Clean>
            <WarehouseForm
                element={click => (
                    <Button
                        onClick={click}
                        variant="alo-blue:md-xl"
                        display={{ base: 'none', md: 'inline' }}
                        ml="2"
                    >
                        Nuevo almacén
                    </Button>
                )}
                onDone={() => {
                    listWarehouses.load()
                    reload()
                }}
            />
        </Flex>
    )
}
