import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Textarea,
    useId,
    VStack
} from '@chakra-ui/react'
import { FormEvent, useState } from 'react'
import useDelivery from '../../../../hooks/useDelivery'
import val from '../../../../lib/val'
import Client from '../../../../models/client'
import extractDataForm from '../../../../utilities/extractDataForm'
import useUpdateClient from '../../hooks/useUpdateClient'
import { useDeliveryForm } from '../../store/delivery-form.store'

interface AddressFormData {
    address: string
    reference: string
}

export default function AddressFormModal() {
    const { submit, isLoading } = useUpdateClient()
    const [isOpen, setIsOpen] = useState(false)
    const client = useDelivery().client as Client
    const setForm = useDeliveryForm(state => state.set)
    const id = useId()
    const [helpers, setHelpers] = useState<Partial<AddressFormData>>({})

    async function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()

        const form = extractDataForm<AddressFormData>(ev.target)
        const v = val<AddressFormData>(form)
        v.address.isEmpty('La dirección es requerida')
        const result = v.runtest()
        if (result) {
            setHelpers(result)
        }
        const currentAddress = client.addresses.find(
            _address =>
                _address[0].toLocaleLowerCase() ===
                form.address.toLowerCase().trim()
        )

        if (currentAddress) {
            await submit({
                addresses: [
                    ...client.addresses,
                    [currentAddress[0], form.reference]
                ]
            })
            setForm({
                address: [currentAddress[0], form.reference]
            })
        } else {
            await submit({
                addresses: [...client.addresses, [form.address, form.reference]]
            })
            setForm({
                address: [form.address, form.reference]
            })
        }

        setIsOpen(false)
    }

    return (
        <>
            <Button
                fontWeight="medium"
                colorScheme="blue"
                variant="link"
                size="sm"
                onClick={() => setIsOpen(true)}
            >
                + Nueva dirección
            </Button>
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Nueva dirección</ModalHeader>
                    <ModalBody>
                        <form id={id} onSubmit={submitHandler} noValidate>
                            <VStack spacing={4}>
                                <FormControl
                                    isRequired
                                    isInvalid={!!helpers.address}
                                >
                                    <FormLabel>Dirección</FormLabel>
                                    <Input
                                        autoFocus
                                        name="address"
                                        placeholder="Ingresar dirección"
                                    />
                                    <FormErrorMessage>
                                        {helpers.address}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Referencia (Opcional)</FormLabel>
                                    <Textarea
                                        name="reference"
                                        placeholder="Ejemplo: a dos cuadras del hospital"
                                    ></Textarea>
                                </FormControl>
                            </VStack>
                        </form>
                    </ModalBody>
                    <ModalFooter gap={2}>
                        <Button onClick={() => setIsOpen(false)}>
                            Cancelar
                        </Button>
                        <Button
                            isLoading={isLoading}
                            form={id}
                            type="submit"
                            colorScheme="green"
                        >
                            Guardar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
