import { Flex, Input, Text } from '@chakra-ui/react'
import { memo } from 'react'
import { CommentaryProps } from './types'

function Commentary({ value, setCommentary }: CommentaryProps) {
    return (
        <Flex
            justifyContent="flex-end"
            align="center"
            w={{ base: 'xl', md: 'auto' }}
            mb={{ base: 4, md: 0 }}
        >
            <Text mr={2} color="gray.700" fontSize="sm">
                Comentario:
            </Text>
            <Input
                size="sm"
                w={{
                    base: 'full',
                    md: 'auto'
                }}
                minW={{
                    base: 'auto',
                    md: 'xl'
                }}
                borderRadius="md"
                placeholder="Escriba un comentario (opcional)"
                value={value}
                onChange={ev => setCommentary(ev.target.value)}
            />
        </Flex>
    )
}

export default memo(Commentary)
