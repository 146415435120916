import { HStack, Text } from '@chakra-ui/react'
import useOrderByTable from '../../../hooks/useOrderByTable'
import ModalWaiters from '../../../modals/ModalWaiters'
import { useState } from 'react'
import ModalTables from '../../../modals/ModalTables'
import useSession from '../../../../../hooks/useSession'

export default function OrderInfo() {
    const { table, waiter, orderId } = useOrderByTable()
    const [isOpenWaiters, setIsOpenWaiters] = useState(false)
    const [isOpenTables, setIsOpenTables] = useState(false)
    const { isAdmin, isCashier } = useSession()

    return (
        <>
            {isAdmin() || isCashier() ? (
                <ModalWaiters
                    isOpen={isOpenWaiters}
                    onClose={() => setIsOpenWaiters(false)}
                />
            ) : null}
            <ModalTables
                isOpen={isOpenTables}
                onClose={() => setIsOpenTables(false)}
            />
            <HStack w="full" justify="space-between" fontSize="sm" spacing={0}>
                {waiter && (
                    <Text>
                        Mozo:{' '}
                        <Text
                            as={'b'}
                            color="dark-2"
                            textTransform="capitalize"
                            textDecoration={
                                isAdmin() || isCashier()
                                    ? 'underline'
                                    : 'initial'
                            }
                            cursor="pointer"
                            pointerEvents={
                                isAdmin() || isCashier() ? 'auto' : 'none'
                            }
                            onClick={() => setIsOpenWaiters(true)}
                        >
                            {waiter.firstname} {waiter.lastname}
                        </Text>
                    </Text>
                )}
                <Text>
                    Mesa:{' '}
                    <Text
                        color="dark-2"
                        textDecoration={orderId ? 'underline' : 'none'}
                        cursor={orderId ? 'pointer' : 'text'}
                        pointerEvents={orderId ? 'initial' : 'none'}
                        as={'b'}
                        onClick={() => setIsOpenTables(true)}
                    >
                        {table?.name}
                    </Text>
                </Text>
            </HStack>
        </>
    )
}
