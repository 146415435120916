import { Flex, Spinner, Text } from '@chakra-ui/react'

export default function ContentModalLoading() {
    return (
        <>
            <Text fontSize="2xl" fontWeight="bold" textAlign="center" mb={10}>
                Registrando venta
            </Text>
            <Flex justifyContent="center">
                <Spinner size="xl" />
            </Flex>
        </>
    )
}
