import { Box, Button, Text } from '@chakra-ui/react'
import { useRender } from 'redity'
import { formItem, storagePage } from '../../storage'
import useSession from '../../../../hooks/useSession'

export default function Counter() {
    const { quantity, currentQuantity } = formItem.store()
    const { userCanCancelItems } = useSession()
    const r = useRender()

    function clickPrevHandler() {
        if (quantity <= 1) return
        formItem.store.set('quantity', quantity - 1)
        r()
    }

    function clickNext() {
        formItem.store.set('quantity', quantity + 1)
        r()
    }

    return (
        <Box
            display="flex"
            justifyContent={{ base: 'flex-start', lg: 'space-around' }}
            alignItems="center"
            mb="4"
            px={{ base: 0, lg: '5' }}
        >
            <Button
                variant="alo-gray:2xl-md"
                w={{ base: '50px', lg: '80px' }}
                h={{ base: '50px', lg: '80px' }}
                fontSize="3xl"
                isDisabled={
                    quantity <= (currentQuantity || 0) &&
                    !userCanCancelItems() &&
                    storagePage.editOrderTable
                }
                onClick={clickPrevHandler}
            >
                -
            </Button>
            <Text
                fontWeight="bold"
                minWidth={{ base: '100px' }}
                fontSize="2xl"
                textAlign="center"
            >
                {quantity}
            </Text>
            <Button
                variant="alo-gray:2xl-md"
                w={{ base: '50px', lg: '80px' }}
                h={{ base: '50px', lg: '80px' }}
                fontSize="3xl"
                onClick={clickNext}
            >
                +
            </Button>
        </Box>
    )
}
