import { Td, Text, useDisclosure } from '@chakra-ui/react'
import ModalChangeStock from '../../modals/ModalChangeStock'
import { FindItem } from './types'

export default function TdStock({
    item,
    stock,
    reload
}: {
    item: FindItem
    stock: number
    reload: () => void
}) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Td>
            {isOpen ? (
                <ModalChangeStock
                    isOpen={isOpen}
                    onClose={onClose}
                    item={item}
                    reload={reload}
                />
            ) : null}
            <Text
                onClick={onOpen}
                as="span"
                textDecoration={'underline'}
                textColor={'blue'}
                fontSize="sm"
                mr={1}
                cursor={'pointer'}
            >
                {stock}
            </Text>
        </Td>
    )
}
