import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Badge,
    Button,
    IconButton,
    useDisclosure
} from '@chakra-ui/react'
import { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import ArrowRight from '../../../components/Icon/ArrowRight'
import CashIcon from '../../../components/Icon/CashIcon'
import Close from '../../../components/Icon/Close'
import useDelivery from '../../../hooks/useDelivery'
import useGoToPay from '../hooks/useGoToPay'
import useOpenCashbox from '../hooks/useOpenCashbox'
import {
    useDeliveryForm,
    useDeliveryHelper
} from '../store/delivery-form.store'

export default function CloseOrGoToPay() {
    const { setClient, setDelivery, setProducts, delivery } = useDelivery()
    const clear = useDeliveryHelper(state => state.clear)
    const { clear: clearForm } = useDeliveryForm()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef<HTMLButtonElement>(null)
    const { push } = useHistory()
    const { go } = useGoToPay()
    const { isOpen: isOpenCashbox } = useOpenCashbox()

    function yesHandler() {
        setClient(null)
        setDelivery(null)
        setProducts([])
        push('/delivery/customers')
        clear()
        clearForm()
        onClose()
    }

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Cancelar
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            ¿Está seguro cancelar la order para este cliente?
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button
                                ref={cancelRef}
                                onClick={yesHandler}
                                colorScheme="red"
                            >
                                Si, continuar
                            </Button>
                            <Button onClick={onClose} ml={2}>
                                Cancelar
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            {delivery && !delivery.is_paid && (
                <Button
                    fontWeight="normal"
                    colorScheme="blue"
                    fill="white"
                    title={
                        isOpenCashbox
                            ? ''
                            : 'Para realizar un cobro necesita abrir CAJA con su usuario'
                    }
                    leftIcon={<CashIcon />}
                    rightIcon={<ArrowRight />}
                    onClick={() => go(delivery.order_id)}
                    isDisabled={!isOpenCashbox || delivery.is_paid}
                    display={{
                        base: 'none',
                        md: 'block'
                    }}
                >
                    Realizar pago
                </Button>
            )}
            {delivery && delivery.is_paid && (
                <Badge
                    colorScheme="green"
                    fontWeight="normal"
                    variant="solid"
                    rounded="md"
                >
                    Pagado
                </Badge>
            )}
            {delivery && !delivery.is_paid && (
                <Button
                    fontWeight="normal"
                    colorScheme="blue"
                    fill="white"
                    isDisabled={!isOpenCashbox || delivery.is_paid}
                    display={{
                        base: 'block',
                        md: 'none'
                    }}
                    rightIcon={<ArrowRight />}
                    onClick={() => go(delivery.order_id)}
                >
                    Pagar
                </Button>
            )}
            {!delivery && (
                <IconButton
                    icon={<Close />}
                    aria-label="Cancelar"
                    size="sm"
                    variant="ghost"
                    onClick={onOpen}
                />
            )}
        </>
    )
}
