import { Box, Text, Divider, Heading, Button } from '@chakra-ui/react'
import { useContext } from 'react'
import Check from '../../../components/Icon/Check'
import colors from '../../../config/theme/colors'
import getInformationForWaiter from '../../../utilities/getInformationForWaiter'
import getNumberWithTwoDecimals from '../../../utilities/getNumberWithTwoDecimals'
import CheckInContext from '../context/CheckInContext'

export default function RightContent() {
    const {
        itemsByCategory,
        order,
        handleConfirmPay,
        isLoadingConfirmPay,
        getTotal,
        getTotalWithDiscount
    } = useContext(CheckInContext)

    const paymentTotal = getTotal()
    const { paymentTotalWithDiscount } = getTotalWithDiscount()

    return (
        <Box
            bg={colors['base-3']}
            w="33%"
            minH="calc(100vh - 60px)"
            position="relative"
            color={colors['alo-gray']}
            overflowY="auto"
            p="8"
            display="flex"
            flexDirection="column"
            justifyContent="center"
        >
            <Box
                bg="white"
                borderRadius="2xl"
                p="8"
                w="full"
                minH="calc(100vh - 460px)"
            >
                <Box mb={4}>
                    <Heading size="md" textAlign="center" mb="10">
                        Detalle de pedido
                    </Heading>
                    <Box mb="3" display="flex" justifyContent="space-between">
                        <Box display="flex">
                            <Text mr="2">Mozo: </Text>
                            <Text fontWeight="bold">
                                {getInformationForWaiter(
                                    order?.waiter?.firstname,
                                    order?.waiter?.lastname
                                ) || '-'}
                            </Text>
                        </Box>
                        <Box display="flex">
                            <Text mr="2">Mesa: </Text>
                            <Text fontWeight="bold">{order?.table?.name}</Text>
                        </Box>
                    </Box>
                </Box>
                <Divider mb="2" />

                <Box>
                    {itemsByCategory.map((category: any) => (
                        <Box key={category.id}>
                            <Text fontSize="sm" color="alo-gray" my="2">
                                {category.name.toUpperCase()}
                            </Text>
                            {category?.items.map((item: any) => (
                                <Box
                                    key={item.id}
                                    mb="3"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Text
                                        w="10%"
                                        fontSize="sm"
                                        color="alo-gray"
                                        fontWeight="bold"
                                    >
                                        {item.quantity}
                                    </Text>
                                    <Text
                                        w="65%"
                                        fontSize="sm"
                                        color="alo-gray"
                                        fontWeight="bold"
                                    >
                                        {item.name}
                                    </Text>

                                    <Text
                                        w="25%"
                                        textAlign="right"
                                        color="alo-gray"
                                        fontWeight="bold"
                                        fontSize="sm"
                                    >
                                        {getNumberWithTwoDecimals(item.total)}
                                    </Text>
                                </Box>
                            ))}
                        </Box>
                    ))}
                </Box>
            </Box>
            <Button
                variant="alo-green:xl-xl"
                onClick={handleConfirmPay}
                isLoading={isLoadingConfirmPay}
                leftIcon={<Check fill={colors.white} fontSize="28px" />}
                w="full"
                mt="6"
                isDisabled={
                    (paymentTotal - order?.amount < 0 &&
                        paymentTotalWithDiscount === '-') ||
                    paymentTotal - Number(paymentTotalWithDiscount) < 0
                }
            >
                Confirmar Pedido
            </Button>
        </Box>
    )
}
