import { Box, Flex, Image, Radio, Text } from '@chakra-ui/react'

export default function RadioLabel({
    value,
    label,
    image,
    description
}: RadioLabelProps) {
    return (
        <label>
            <Flex
                w="full"
                cursor="pointer"
                borderWidth={1}
                rounded="md"
                px={2}
                py={1}
                bg="white"
                justify="space-between"
                alignItems="center"
            >
                <Flex gap={3}>
                    <Radio name="payment" value={value} />
                    <Box>
                        <Text fontSize="sm">{label}</Text>
                        {description && (
                            <Text
                                fontSize="xs"
                                color="blackAlpha.700"
                                fontWeight="light"
                            >
                                {description}
                            </Text>
                        )}
                    </Box>
                </Flex>
                {image && <Image src={image} alt={label} w="6" />}
            </Flex>
        </label>
    )
}

export interface RadioLabelProps {
    value: string
    label: string
    description?: string
    image?: string
}
