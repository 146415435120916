import { Tbody, Td, Tr } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useGet } from '../../../../hooks/useHttp'
import Booking from '../../../../models/booking'
import { BookingsTBodyProps } from './types'

const TYPE_BOOKINGS_VALUE = {
    AGENCY: 1,
    DRIVER: 2,
    CLIENT: 3
}

function getTextType(type: number) {
    switch (type) {
        case 1:
            return 'Agencia'
        case 2:
            return 'Guía local'
        case 3:
            return 'Cliente independiente'
        default:
            return 'Nuevo destino de preparación'
    }
}

function getText(booking: Booking) {
    if (booking.type === TYPE_BOOKINGS_VALUE.AGENCY) {
        return `${booking.agency?.name ?? ''} - ${
            booking.agency_guide?.name ?? ''
        } `
    }

    if (booking.type === TYPE_BOOKINGS_VALUE.DRIVER) {
        return booking.driver?.name || ''
    }

    return booking.client_name
}

export default function BookingsBody({ date }: BookingsTBodyProps) {
    const { data } = useGet<Booking[]>(
        `/api/bookings?sort=hour&order=asc&date=${date}`,
        []
    )

    return (
        <Tbody>
            {data.result.map((booking, i) => (
                <Tr key={booking.id}>
                    <Td>{i + 1}</Td>
                    <Td>{`${dayjs(booking.date).format('DD/MM/YYYY')} ${dayjs(
                        booking.hour
                    ).format('hh:mm A')}`}</Td>
                    <Td>{getText(booking)}</Td>
                    <Td>{getTextType(booking.type)}</Td>
                    <Td>{booking.person_quantity}</Td>
                    <Td>{booking.comment || '-'}</Td>
                </Tr>
            ))}
        </Tbody>
    )
}
