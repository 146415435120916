import { createContext } from 'react'
import Booking from '../../../models/booking'
import Driver from '../../../models/driver'
import PreOrder from '../../../models/pre_order'

export interface PreOrderContextInterface {
    /**
     * Preorder actual a edición
     */
    preOrderId: number
    booking: Booking | null
    setBooking: (booking: Booking | null) => void
    alias: string
    setAlias: (text: string) => void
    /**
     * Preorders seleccionados
     */
    preOrders: PreOrder[]
    /**
     * Setea y reemplaza la preorders actual
     */
    setPreOrders: (preOrders: PreOrder[]) => void
    /**
     * Carga de la preorder
     */
    isLoading: boolean
    /**
     * Local guide
     */
    setDriver: (driver: Driver | null) => void
    driver: Driver | null
}

const PreOrderContext = createContext<PreOrderContextInterface>({
    preOrderId: 0,
    booking: null,
    setBooking: () => null,
    alias: '',
    setAlias: () => null,
    preOrders: [],
    setPreOrders: () => null,
    isLoading: false,
    setDriver: () => null,
    driver: null
})

export default PreOrderContext
