import { Icon } from '@chakra-ui/react'
import Pencil from '../../../components/Icon/Pencil'
import Colors from '../../../config/colors'
import useBooking from './useBooking'
import Booking from '../../../models/booking'

export default function Edition({ booking }: { booking: Booking }) {
    const openModal = useBooking(booking)

    return (
        <Icon
            fill={Colors.INFO}
            as={Pencil}
            marginRight={2}
            cursor="pointer"
            onClick={() => openModal()}
        />
    )
}
