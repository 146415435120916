import { Box, Text } from '@chakra-ui/react'
import colors from '../../../config/theme/colors'
import { storagePage } from '../storage'

export default function TypeConsumption() {
    const { alias } = storagePage

    return (
        <>
            {alias ? (
                <Box
                    w="full"
                    bg={colors['base-3']}
                    px={{ base: 2, md: 4 }}
                    py="2"
                    mt={4}
                >
                    <Text fontWeight={'semibold'}>{alias}</Text>
                </Box>
            ) : null}
        </>
    )
}
