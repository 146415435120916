import { render, renders } from 'redity'
import sttore, { Sttore } from 'sttore'
import { generateId } from '../../utilities/generateId'
import Fields from './fields'

export default class FormEvent<T> extends Fields<T> {
    readonly KeyMain: string

    constructor(form: T) {
        const keyMain = generateId()
        const store = sttore(form as any)
        super(keyMain, store)
        this.KeyMain = keyMain
    }

    setHelpers(helpers: Partial<Record<keyof T, string>>) {
        this.helpers = helpers || {}
        renders(this.KeyMain)
    }

    setValue<K extends keyof T>(fieldName: K, value: T[K]) {
        this.store.set(fieldName, value)
        if (this.exception) {
            this.exception.errors[fieldName as string] = ''
        }
        render(this.KeyMain, fieldName as any)
    }

    init(store?: Sttore<T>) {
        this.store.init(store)
        this.exception = null
        this.helpers = {}
    }
}
