import {
    Box,
    Flex,
    Heading,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import Assignment from '../../../components/Icon/Assignment'
import colors from '../../../config/theme/colors'

import CustomButton from '../Components/CustomButton'
import useMovements from '../hooks/useMovements'
import { storagePage } from '../storage'
import { CashboxUserData } from '../types'
import MovementTr from './MomentTr'

export default function Moviments() {
    const { clickAddHandler } = useMovements()

    const cashboxUser = storagePage.cashboxUser as CashboxUserData

    return (
        <Box mt="10">
            <Box mb="10">
                <Flex justifyContent="space-between" alignItems="center">
                    <Heading fontSize="lg" color="dark" mb="4">
                        Movimientos
                    </Heading>
                    <CustomButton
                        leftIcon={<Assignment fill={colors.orange[400]} />}
                        onClick={clickAddHandler}
                        isDisabled={!storagePage.isYourBox}
                    >
                        Nuevo Movimiento
                    </CustomButton>
                </Flex>
                <TableContainer>
                    <Table className="table-a-la-orden" mt={4}>
                        <Thead>
                            <Tr textAlign="center">
                                <Th textAlign="center">Fecha</Th>
                                <Th textAlign="center">Tipo de movimientos</Th>
                                <Th textAlign="center">Concepto</Th>
                                <Th textAlign="center">Monto</Th>
                                <Th textAlign="center">Comentario</Th>
                                <Th textAlign="center">Acciones</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {cashboxUser.movements.map(movement => (
                                <MovementTr
                                    key={movement.id}
                                    movement={movement}
                                />
                            ))}
                            {cashboxUser.movements.length === 0 && (
                                <Tr>
                                    <Td colSpan={6}>No existen movimientos</Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}
