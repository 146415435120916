import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import useDelivery from '../../../hooks/useDelivery'
import { put } from '../../../lib/http'
import Client from '../../../models/client'

export default function useUpdateClient() {
    const toast = useToast()
    const { client, setClient } = useDelivery()
    const [isLoading, setIsLoading] = useState(false)

    async function submit(_client: Partial<ClientData>) {
        if (!client) return
        const data = {
            type_identification_document_id:
                client.type_identification_document_id,
            firstname: client.firstname,
            lastname: client.lastname,
            business_name: client.business_name,
            ..._client
        }

        setIsLoading(true)
        const updated = await put<Client>(`/api/clients/${client.id}`, data)
        setIsLoading(false)

        if (updated.data) {
            setClient(updated.data.result)
        }

        if (updated.error) {
            toast({
                title: 'No fue posible actualizar el cliente',
                position: 'top',
                status: 'error'
            })
        }
    }

    return {
        submit,
        isLoading
    }
}

export interface ClientData {
    /**
     * [address, references]
     */
    addresses: Array<[string, string]>
    phones: string[]
}
