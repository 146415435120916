export default function Printer(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.8337 4.66667H14.0003V0.5H4.00033V4.66667H3.16699C1.78366 4.66667 0.666992 5.78333 0.666992 7.16667V12.1667H4.00033V15.5H14.0003V12.1667H17.3337V7.16667C17.3337 5.78333 16.217 4.66667 14.8337 4.66667ZM5.66699 2.16667H12.3337V4.66667H5.66699V2.16667ZM12.3337 13.8333V12.1667V10.5H5.66699V13.8333H12.3337ZM14.0003 10.5V8.83333H4.00033V10.5H2.33366V7.16667C2.33366 6.70833 2.70866 6.33333 3.16699 6.33333H14.8337C15.292 6.33333 15.667 6.70833 15.667 7.16667V10.5H14.0003ZM13.167 7.58333C13.167 7.1231 13.5401 6.75 14.0003 6.75C14.4606 6.75 14.8337 7.1231 14.8337 7.58333C14.8337 8.04357 14.4606 8.41667 14.0003 8.41667C13.5401 8.41667 13.167 8.04357 13.167 7.58333Z"
            />
        </svg>
    )
}
