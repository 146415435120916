import { Box } from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import Initial from '../../components/Initial'
import PageState from '../../types/pageState'
import MenuUser from '../../components/MenuUser'
import NTabs from '../../components/NTabs'
import useInitial from './useInitial'
import TableSales from './TableSales'
import Filters from './Filters'

export default function Sales() {
    const { withVouchers } = useInitial()

    return (
        <Initial pageState={PageState.SUCCESS}>
            <Navbar component={<NTabs onSell={withVouchers} />}>
                <Box px="5" py="1">
                    <MenuUser />
                </Box>
            </Navbar>
            <Box
                w={{
                    base: '100vw',
                    xl: 'calc(100vw - 250px)'
                }}
                px={{
                    xl: 12,
                    lg: 6,
                    md: 6,
                    base: 4
                }}
                py="6"
                mx="auto"
            >
                <Filters />
                <TableSales />
            </Box>
        </Initial>
    )
}
