import {
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    ModalProps,
    Text
} from '@chakra-ui/react'

interface ModalContainerProps extends ModalProps {
    title: string
}

export default function ModalContainer({
    children,
    title,
    ...props
}: ModalContainerProps) {
    return (
        <Modal {...props} size="3xl">
            <ModalOverlay />
            <ModalContent
                px={{
                    base: 0,
                    xl: 6
                }}
                pt={{
                    base: 2,
                    xl: 4
                }}
                pb={4}
            >
                <ModalBody>
                    <Text fontSize="2xl" fontWeight="bold" mb="3">
                        {title}
                    </Text>
                    {children}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
