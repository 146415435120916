import { Button, Flex, Select } from '@chakra-ui/react'
import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import Tabs, { Tab } from '../../components/Tabs'
import { useGet } from '../../hooks/useHttp'
import useTab from '../../hooks/useTab'
import Warehouse from '../../models/warehouse'
import KardexSection from './components/KardexSection'
import ProductsSection from './components/ProductsSection'
import WarehousesSection from './components/WarehousesSection'

export default function Warehouses() {
    const props = useTab('warehouses')
    const { data, reload } = useGet<Warehouse[]>('2>/api/warehouses', [])

    return (
        <Sidebar>
            <SimpleNav title="Gestión de productos" />
            <Tabs {...props}>
                <Tab value="warehouses">Almacenes</Tab>
                <Tab value="products">Productos</Tab>
                <Tab value="kardex">Kardex</Tab>
            </Tabs>
            <Flex
                justifyContent="space-between"
                display={{ base: 'flex', md: 'none' }}
            >
                <Select w="200px">
                    <option value="warehouses">Almacenes</option>
                    <option value="products">Productos</option>
                    <option value="kardex">Kardex</option>
                </Select>
                <Button variant="alo-blue:md-xl">+ Nuevo</Button>
            </Flex>
            {props.value === 'warehouses' && (
                <WarehousesSection reload={reload} />
            )}
            {props.value === 'products' && (
                <ProductsSection warehouses={data.result} />
            )}
            {props.value === 'kardex' && (
                <KardexSection warehouses={data.result} />
            )}
        </Sidebar>
    )
}
