import { Icon } from '@chakra-ui/react'
import Trash from '../../../components/Icon/Trash'
import colors from '../../../config/theme/colors'
import Delete from '../../../lib/delete'
import { listProductionItems, PATH_ENDPOINT_ITEM } from '../storage'
import ProductionItem from '../../../models/production_item'

export default function DeleteItem({ item }: { item: ProductionItem }) {
    return (
        <Delete
            title="Eliminar artículo de inventario"
            path={`${PATH_ENDPOINT_ITEM}/${item.id}`}
            element={click => (
                <Icon
                    onClick={click}
                    fill={colors.error}
                    as={Trash}
                    cursor="pointer"
                />
            )}
            done={() => {
                listProductionItems.setQuery('page', 1)
                listProductionItems.load()
            }}
        >
            ¿Esta seguro de eliminar el artículo <b>{item.name}</b>?
        </Delete>
    )
}
