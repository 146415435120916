import { CustomCategoryItem, OrderItemForview } from '../types'

export default function rearrangeItems(orderItems: OrderItemForview[]) {
    const categoryItems: CustomCategoryItem[] = []
    for (const orderItem of orderItems) {
        const categoryItem = categoryItems.find(
            ci => ci.id === orderItem.item.category_item.id
        )
        if (categoryItem) {
            categoryItem.items.push({
                id: orderItem.item.id,
                name: orderItem.item.name,
                price: orderItem.price, //precio del item en el precio
                quantity: orderItem.quantity
            })
        } else {
            categoryItems.push({
                id: orderItem.item.category_item.id,
                name: orderItem.item.category_item.name,
                items: [
                    {
                        id: orderItem.item.id,
                        name: orderItem.item.name,
                        price: orderItem.price, //precio del item en el precio
                        quantity: orderItem.quantity
                    }
                ]
            })
        }
    }

    return categoryItems
}
