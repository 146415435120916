import {
    Box,
    Button,
    Icon,
    Table,
    Tbody,
    Td,
    Tfoot,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import { useRender } from 'redity'
import Close from '../../../components/Icon/Close'
import ModalKeyboard from '../../../components/ModalKeyboard'
import colors from '../../../config/theme/colors'
import { Keys } from '../constants'
import useMainContent from '../hooks/useMainContent'
import { storagePage } from '../storage'
import Edition from './Edition'
import Remove from './Remove'

export default function ProductsSection() {
    useRender(Keys.PRODUCTS_SECTION)
    const {
        submitAddDiscountHandler,
        deleteDiscount,
        discount,
        subtotal,
        total,
        valuePercentDiscount,
        typeDiscount
    } = useMainContent()
    const { selectedItems } = storagePage

    return (
        <Box>
            <Table mt={6}>
                <Thead>
                    <Tr>
                        <Th>#</Th>
                        <Th>Producto</Th>
                        <Th>Cant.</Th>
                        <Th isNumeric>Precio unit.</Th>
                        <Th isNumeric>Total</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {selectedItems.map((selectedItem, i) => (
                        <Tr key={i}>
                            <Td>{i + 1}</Td>
                            <Td>{selectedItem.item.name}</Td>
                            <Td>{selectedItem.quantity}</Td>
                            <Td isNumeric>
                                S/ {selectedItem.item.price.toFixed(2)}
                            </Td>
                            <Td isNumeric>
                                S/ {selectedItem.sub_total.toFixed(2)}
                            </Td>
                            <Td>
                                <Edition item={selectedItem} />
                                <Remove index={i} />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
                {selectedItems.length > 0 ? (
                    <Tfoot>
                        <Tr>
                            <Th borderColor="white" colSpan={4} isNumeric>
                                Subtotal
                            </Th>
                            <Th borderColor="white" isNumeric>
                                S/ {subtotal.toFixed(2)}
                            </Th>
                        </Tr>
                        <Tr>
                            <Th borderColor="white" colSpan={4} isNumeric>
                                <ModalKeyboard
                                    element={click => (
                                        <Button
                                            mr={2}
                                            color="white"
                                            fontSize="lg"
                                            cursor="pointer"
                                            onClick={click}
                                            size="xs"
                                            ml={1}
                                            _hover={{}}
                                            _active={{}}
                                            bg={colors.success}
                                        >
                                            +
                                        </Button>
                                    )}
                                    title="Descuento"
                                    maxValue={subtotal}
                                    onDone={submitAddDiscountHandler}
                                />
                                Descuento{' '}
                                {typeDiscount === 'percentage'
                                    ? `(${valuePercentDiscount}%)`
                                    : `(S/)`}
                            </Th>
                            <Th borderColor="white" isNumeric>
                                <Icon
                                    mr={2}
                                    as={Close}
                                    fontSize={14}
                                    cursor="pointer"
                                    onClick={deleteDiscount}
                                    ml={3}
                                    fill={colors.secondary}
                                    alignSelf="center"
                                    display={{ base: 'none', md: 'inline' }}
                                />
                                S/ {discount.toFixed(2)}
                            </Th>
                        </Tr>
                        <Tr>
                            <Th borderColor="white" colSpan={4} isNumeric>
                                Total
                            </Th>
                            <Th borderColor="white" isNumeric>
                                S/ {total.toFixed(2)}
                            </Th>
                        </Tr>
                    </Tfoot>
                ) : (
                    <Tfoot>
                        <Tr>
                            <Td colSpan={5} textAlign="center">
                                Agregue productos
                            </Td>
                        </Tr>
                    </Tfoot>
                )}
            </Table>
        </Box>
    )
}
