import { createContext } from 'react'

interface _CheckInContext {
    itemsByCategory: any[]
    setItemsByCategory: React.Dispatch<React.SetStateAction<any[]>>
    loader: boolean
    orderId: null | number
    setOrderId: React.Dispatch<React.SetStateAction<any>>
    openModalPay: boolean
    setOpenModalPay: React.Dispatch<React.SetStateAction<boolean>>
    openModalDiscount: boolean
    setOpenModalDiscount: React.Dispatch<React.SetStateAction<boolean>>
    typePay: null
    setTypePay: React.Dispatch<React.SetStateAction<any>>
    payments: any[]
    setPayments: React.Dispatch<React.SetStateAction<any[]>>
    order: null | any
    setOrder: React.Dispatch<React.SetStateAction<any>>
    getTotal: () => number
    removePayment: (type: string) => void
    handleConfirmPay: () => void
    stateDiscount: any
    setStateDiscount: React.Dispatch<React.SetStateAction<any>>
    getTotalWithDiscount: () => any
    isLoadingConfirmPay: boolean
}

const CheckInContext = createContext<_CheckInContext>({
    itemsByCategory: [],
    setItemsByCategory: () => null,
    loader: false,
    orderId: null,
    setOrderId: () => null,
    openModalPay: false,
    setOpenModalPay: () => null,
    openModalDiscount: false,
    setOpenModalDiscount: () => null,
    typePay: null,
    setTypePay: () => null,
    payments: [],
    setPayments: () => null,
    order: null as any,
    setOrder: () => null,
    getTotal: () => 0,
    removePayment: () => null,
    handleConfirmPay: () => null,
    stateDiscount: null,
    setStateDiscount: () => null,
    getTotalWithDiscount: () => null,
    isLoadingConfirmPay: false
})

export default CheckInContext
