import { Button } from '@chakra-ui/react'
import Pencil from '../../../components/Icon/Pencil'
import Colors from '../../../config/colors'
import Table from '../../../models/table'
import PropsButton from '../../../utilities/props_button'
import useForm from './useForm'

export default function Edition({ table }: { table: Table }) {
    const openModal = useForm(table)

    return (
        <Button
            {...PropsButton.Edit}
            leftIcon={<Pencil fill={Colors.INFO} />}
            onClick={openModal}
        >
            Editar
        </Button>
    )
}
