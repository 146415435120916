import ControlList from '../../lib/list/ControlList'
import { ReportSale } from '../../models/report_sale'

export const controlList = new ControlList<ReportSale>({
    path: '/api/reports/sales',
    theads: [
        'Fecha de creación',
        'Fecha de emisión',
        'Tipo de comprobante',
        'Comprobante',
        'DNI/RUC',
        'Client',
        'Base imponible gravada',
        'Base imponible no gravada',
        'IGV',
        'Importe total',
        'Moneda'
    ]
})
