const headers = new Headers()
headers.append('Content-Type', 'application/json')
headers.append('Accept', 'application/json')
headers.append('Acces-Control-Allow-Origin', '*')
headers.append(
    'Access-Control-Allow-Headers',
    'Origin, Content-Type, X-Auth-Token'
)
const token = localStorage.getItem('token')
if (token) {
    headers.append('Authorization', `Bearer ${localStorage.getItem('token')}`)
}

export default headers
