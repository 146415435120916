import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Box
} from '@chakra-ui/react'
import useModalEmit from '../../hooks/useModalEmit'
import { ModalEmitProps } from '../../types'
import Content from './Content'
import TypeIdentificationDocument from '../../../../utilities/type_identification_document'

export default function ModalEmit({
    element,
    orderPaymentId,
    client,
    amount,
    onDone,
    isCredit,
    orderPayment
}: ModalEmitProps) {
    const notHaveProof = orderPayment.serie === 4

    const {
        submitHandler,
        setOpen,
        isOpen,
        currentClient,
        setClient,
        helper,
        isLoading,
        setDate,
        date,
        setSerie,
        serie,
        setReason
    } = useModalEmit({
        orderPaymentId,
        client,
        amount,
        onDone,
        isCredit,
        notHaveProof
    })

    return (
        <>
            {element(() => setOpen(true))}
            <Modal
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                size="md"
                isCentered={false}
            >
                <ModalOverlay />
                <ModalContent px={6} py={4}>
                    <ModalHeader>
                        {notHaveProof
                            ? 'Emitir comprobante'
                            : 'Cambiar datos de comprobante'}
                    </ModalHeader>
                    <ModalBody pb={6}>
                        {!notHaveProof ? (
                            <>
                                <Text fontWeight={'semibold'}>
                                    {orderPayment.code}
                                </Text>
                                <Box mb={3}>
                                    <Text fontSize="xl">
                                        {client.type_identification_document_id ===
                                        2
                                            ? client.business_name
                                            : `${client.firstname} ${client.lastname}`}
                                    </Text>
                                    <Text>
                                        <Text as="b">
                                            {
                                                TypeIdentificationDocument[
                                                    client
                                                        .type_identification_document_id
                                                ]
                                            }
                                            :{' '}
                                        </Text>
                                        {client.number}
                                    </Text>
                                </Box>

                                <Text>Seleccionar tipo de comprobante</Text>
                            </>
                        ) : null}
                        <Content
                            onSubmit={submitHandler}
                            client={currentClient}
                            setClient={setClient}
                            helper={helper}
                            isCredit={isCredit}
                            setDate={setDate}
                            date={date}
                            setSerie={setSerie}
                            serie={serie}
                            notHaveProof={notHaveProof}
                            setReason={setReason}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="alo-gray:lg-md"
                            bg="transparent"
                            color="alo-gray"
                            w="36"
                            onClick={() => setOpen(false)}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="alo-green:lg-md"
                            w="36"
                            ml={3}
                            type="submit"
                            form="form-emit"
                            isLoading={isLoading}
                        >
                            {notHaveProof ? 'Emitir' : 'Cambiar'}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
