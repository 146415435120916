import { Box, Button, IconButton, SimpleGrid } from '@chakra-ui/react'
import { RefObject } from 'react'
import BackSpace from '../../components/Icon/BackSpace'
import colors from '../../config/theme/colors'
import useKeys from './useKeys'

export const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9]

export default function Keys({
    mode,
    refInput,
    maxValue
}: {
    mode: string
    refInput: RefObject<HTMLInputElement>
    maxValue: number
}) {
    const { clickNumHandler, eraserHandler, clearHandler } = useKeys(
        mode,
        refInput,
        maxValue
    )

    return (
        <Box display="flex" justifyContent="center" gridGap={4}>
            <Box>
                <SimpleGrid gap="3" columns={3}>
                    {numbers.map((number: number) => (
                        <Button
                            key={number}
                            w={{ base: '65px', md: '90px' }}
                            h={{ base: '65px', md: '90px' }}
                            variant="gray-3:2xl-lg"
                            bg="transparent"
                            color="gray-2"
                            onClick={() => clickNumHandler(number.toString())}
                        >
                            {number}
                        </Button>
                    ))}
                </SimpleGrid>
                <Box
                    mt="3"
                    display="flex"
                    justifyContent="flex-end"
                    gridGap="3"
                >
                    <Button
                        w={{ base: '65px', md: '90px' }}
                        h={{ base: '65px', md: '90px' }}
                        variant="gray-3:2xl-lg"
                        bg="transparent"
                        color="gray-2"
                        onClick={() => clickNumHandler('0')}
                    >
                        0
                    </Button>
                    <Button
                        w={{ base: '65px', md: '90px' }}
                        h={{ base: '65px', md: '90px' }}
                        variant="gray-3:2xl-lg"
                        bg="transparent"
                        color="gray-2"
                        onClick={() => clickNumHandler('.')}
                    >
                        .
                    </Button>
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" gridGap="3">
                <IconButton
                    aria-label="BackSpaceButton"
                    w={{ base: '65px', md: '90px' }}
                    h={{ base: '65px', md: '90px' }}
                    colorScheme="orange"
                    rounded="lg"
                    onClick={eraserHandler}
                    fontSize={{ base: 'md', md: '2xl' }}
                    icon={<BackSpace fill={colors.white} />}
                ></IconButton>
                <Button
                    w={{ base: '65px', md: '90px' }}
                    h={{ base: '65px', md: '90px' }}
                    colorScheme="orange"
                    rounded="lg"
                    onClick={clearHandler}
                    fontSize={{ base: 'lg', md: '2xl' }}
                >
                    Clear
                </Button>
                <Button
                    w={{ base: '65px', md: '90px' }}
                    h={{ base: '65px', md: '90px' }}
                    type="submit"
                    colorScheme="green"
                    rounded="lg"
                    fontSize={{ base: 'lg', md: '2xl' }}
                >
                    Ok
                </Button>
            </Box>
        </Box>
    )
}
