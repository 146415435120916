import { Spinner } from '@chakra-ui/react'
import { createContext, ReactNode, useEffect, useState } from 'react'
import ErrorConnection from '../components/ErrorConnection'
import storage, { initialStorage, Storage } from '../config/storage'
import FullScreenCenter from '../components/FullScreenCenter'
import colors from '../config/theme/colors'

export function InitialProvider({ children }: { children: ReactNode }) {
    const [data, setDataInitial] = useState<Storage>(() => initialStorage)
    const [loading, setLoading] = useState(true)
    const [isError, setError] = useState(false)

    useEffect(() => {
        storage()
            .then(result => {
                setDataInitial(result)
                setLoading(false)
                setError(false)
            })
            .catch(() => {
                setLoading(false)
                setError(true)
            })

        localStorage.removeItem('table_id')
    }, [])

    return (
        <InitialContext.Provider
            value={{
                storage: data
            }}
        >
            {loading && (
                <FullScreenCenter>
                    <Spinner color={colors.primary} size="xl" />
                </FullScreenCenter>
            )}
            {!loading && !isError && children}
            {isError && !loading && (
                <FullScreenCenter>
                    <ErrorConnection />
                </FullScreenCenter>
            )}
        </InitialContext.Provider>
    )
}

export const InitialContext = createContext<InitialData>({
    storage: initialStorage
})

interface InitialData {
    storage: Storage
}
