/**
 * @deprecated
 */
export const FORMAT = 'DD/MM/YYYY hh:mm:ss A'
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL || '/'
export const DSN = process.env.REACT_APP_SENTRY_DSN
export const NODE_ENV = process.env.NODE_ENV
export const TIMEZONE = 'America/Lima'
/**
 * Minutes
 */
export const INACTIVITY_TOLERANCE = process.env.INACTIVITY_TOLERANCE
    ? Number(process.env.INACTIVITY_TOLERANCE)
    : 30
