import {
    Button,
    Flex,
    SimpleGrid,
    Spinner,
    Text,
    useDisclosure
} from '@chakra-ui/react'
import { ReactNode, useEffect, useState } from 'react'
import colors from '../../../config/theme/colors'
import { useGet } from '../../../hooks/useHttp'
import Zone from '../../../models/zone'
import useExtendsJoinTables from '../../../hooks/useJoinTables'
import useSeparateTables, { TableJoin } from '../hooks/useSeparateTables'
import ModalContainer from '../libs/ModalContainer'
import TableItem from '../libs/TableItem'
import SelectZones from '../SelectZones'
import TabZones from '../TabZones'
import { TableData } from '../types'

interface ModalSeparateTablesProps {
    element: (onOpen: () => void) => ReactNode
    zones: Zone[]
    onDone: (zoneId: number) => void
}

export default function ModalSeparateTables({
    element,
    zones,
    onDone
}: ModalSeparateTablesProps) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [zoneId, setZoneId] = useState(() => zones[0].id)
    const use = useSeparateTables(() => {
        onDone(zoneId)
        onClose()
    })
    const {
        data,
        reload,
        isLoading: tablesIsLoading
    } = useGet<TableData[]>(
        `3>/api/tables?sort=name&is_enabled=true&zone_id=${zoneId}&is_free=true`,
        []
    )

    useEffect(() => {
        if (isOpen) {
            reload()
            use.setTables([])
        }
    }, [zoneId, isOpen])

    const tables = useExtendsJoinTables(data.result).filter(
        table => table.join_tables.length > 0
    )

    function clickTableHandler(table: TableJoin) {
        if (use.tables.find(t => t.id === table.id)) {
            use.setTables(use.tables.filter(t => t.id !== table.id))
            return
        }
        use.setTables([...use.tables, table])
    }

    return (
        <>
            {element(onOpen)}
            <ModalContainer
                isOpen={isOpen}
                onClose={onClose}
                title="Separar mesas"
            >
                <SelectZones
                    zones={zones}
                    currentZone={zoneId}
                    onClick={setZoneId}
                />
                <TabZones
                    zones={zones}
                    currentZone={zoneId}
                    onClick={setZoneId}
                />
                <SimpleGrid
                    mt={4}
                    spacing={3}
                    minChildWidth={{
                        base: '120px',
                        xl: '150px'
                    }}
                    maxH={{
                        base: 'sm',
                        lg: 'container.sm'
                    }}
                    overflowY="auto"
                >
                    {!tablesIsLoading &&
                        tables.map(table => (
                            <TableItem
                                key={table.id}
                                table={table}
                                isActive={
                                    !!use.tables.find(t => t.id === table.id)
                                }
                                onClick={() => clickTableHandler(table)}
                            />
                        ))}
                </SimpleGrid>
                {tables.length === 0 && !tablesIsLoading && (
                    <Text textAlign="center">
                        En esta zona no existen mesas agrupadas
                    </Text>
                )}
                <Flex justify="center">
                    {tablesIsLoading && (
                        <Spinner mt={6} borderColor="gray.400" size="lg" />
                    )}
                </Flex>
                <Flex mt="8" justifyContent="flex-end">
                    <Button
                        variant="outline"
                        w="36"
                        onClick={() => onClose()}
                        mr={2}
                        disabled={use.isLoading}
                    >
                        Cancelar
                    </Button>
                    <Button
                        w="36"
                        bg={colors['alo-red']}
                        color="white"
                        onClick={use.submit}
                        isDisabled={use.tables.length < 1}
                        isLoading={use.isLoading}
                    >
                        Confirmar
                    </Button>
                </Flex>
            </ModalContainer>
        </>
    )
}
