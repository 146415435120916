import useSubmit from '../../../hooks/useSubmit'
import modal from '../../../lib/modal'
import Driver from '../../../models/driver'
import { formDriver, listDriver, PATH_ENDPOINT_DRIVER } from '../storage'
import FormResource from './FormDriver'

export default function useDriver(driver?: Driver) {
    const { validation, observer, submit } = useSubmit(formDriver, {
        path: driver
            ? `${PATH_ENDPOINT_DRIVER}/${driver.id}`
            : PATH_ENDPOINT_DRIVER,
        method: driver ? 'put' : 'post',
        done: () => listDriver.load()
    })

    validation(v => {
        v.name.isEmpty('Es requerido el nombre')
    })

    observer<any>(f => ({
        name: f.name
    }))

    function openModal() {
        if (driver) {
            formDriver.store({
                id: driver.id.toString() || '',
                name: driver.name
            })
            formDriver.init(formDriver.store)
        } else {
            formDriver.init()
        }
        modal(FormResource, {
            title: driver ? 'Editar Guía Local' : 'Nueva Guía Local',
            onSubmit: submit
        })
    }

    return openModal
}
