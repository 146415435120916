import { Input, Td, Tr } from '@chakra-ui/react'
import onlyNumber from '../../../../utilities/onlyNumber'
import { TableRowProps } from './types'

export default function TableRow({ coin, quantity, onChange }: TableRowProps) {
    return (
        <Tr>
            <Td>S/{coin.toFixed(2)}</Td>
            <Td p="0">
                <Input
                    size="sm"
                    borderTopWidth="0"
                    borderBottomWidth="0"
                    value={quantity}
                    textAlign="center"
                    onChange={ev =>
                        onChange(Number(onlyNumber(ev.target.value, {})))
                    }
                />
            </Td>
            <Td>{(coin * quantity).toFixed(2)}</Td>
        </Tr>
    )
}
