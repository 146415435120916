import useMenu from '../../../hooks/useMenu'
import useSession from '../../../hooks/useSession'
import useOrderByTable from './useOrderByTable'
import useParamsQuickSaleBy from './useParamsQuickSaleBy'

/**
 * Herramientas para validar los permisos para cada rol
 */
export default function useManagerPermission() {
    const { isWaiter, isDealer, isLogged } = useSession()
    const { isModeEdition } = useOrderByTable()
    const { initialMenu } = useMenu()
    const { by } = useParamsQuickSaleBy()

    function canWaiterOrDealarEdit(itemId: number) {
        if (!isModeEdition) return false
        if (by === 'pre_orders') return true
        if (!isWaiter() && !isDealer() && isLogged) return true
        /**
         * Si existe un item es porque se cargo inicialmente. Esto se carga en edición.
         */
        const existInitMenuItem = !!initialMenu.find(
            initMenuItem => initMenuItem.itemId === itemId
        )
        /**
         * Si existe entonces el mozo no podrá reducir o eliminar
         */
        return !existInitMenuItem
    }

    return {
        canWaiterOrDealarEdit
    }
}
