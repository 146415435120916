import React from 'react'
import {
    Box,
    FormControl,
    FormLabel,
    Grid,
    Icon,
    Input,
    InputProps,
    Select,
    SelectProps,
    Switch,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
    Heading
} from '@chakra-ui/react'
import ErrorHelper from '../../../components/ErrorHelper'
import { formItem } from '../storage'
import { useGet } from '../../../hooks/useHttp'
import ProductionItemCategory from '../../../models/production_item_category'
import TypeMeasure from '../../../models/type_measure'
// import Warehouse from '../../../models/warehouse'
import ModalWarehouses from './ModalWarehouses'
import { ItemWarehouse } from '../types'
import Pencil from '../../../components/Icon/Pencil'
import Colors from '../../../config/colors'

export default function FormItem() {
    return (
        <>
            <>
                <Text mb={3} fontWeight="bold" fontSize="20px">
                    Información General
                </Text>
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                    <Name />
                    <Unity />
                </Grid>
                <Category />
            </>
            <Inventory />
        </>
    )
}

function Name() {
    const { props, helper, error } = formItem.useField<InputProps>('name')
    return (
        <FormControl mb={4}>
            <FormLabel>Nombre</FormLabel>
            <Input
                {...props}
                placeholder="Ingresar el nombre del artículo"
                autoFocus
                maxLength={100}
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function Category() {
    const { data } = useGet<ProductionItemCategory[]>(
        '/api/production_item_categories?sort=name',
        []
    )
    const { props, helper } = formItem.useField<SelectProps>('category_id')

    return (
        <FormControl mb={4}>
            <FormLabel>Categoría</FormLabel>
            <Select {...props} placeholder="Seleccionar categoría del artículo">
                {data.result.map(category => (
                    <option key={category.id} value={category.id}>
                        {category.name}
                    </option>
                ))}
            </Select>
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}

function Unity() {
    const { data } = useGet<TypeMeasure[]>('/api/magnitudes?sort=name', [])
    const { props, helper } = formItem.useField<SelectProps>('magnitude_id')

    const { value } = formItem.useValue('id')
    const isEdit = Number(value) > 0

    return (
        <FormControl mb={4}>
            <FormLabel>Unidad</FormLabel>
            <Tooltip
                hasArrow
                label="No se puede modificar la unidad de medida"
                placement="top"
                shouldWrapChildren
                isDisabled={!isEdit}
            >
                <Select
                    {...props}
                    disabled={isEdit}
                    placeholder="Seleccionar unidad"
                >
                    {data.result.map(typeMeasure => (
                        <option key={typeMeasure.id} value={typeMeasure.id}>
                            {typeMeasure.name}
                        </option>
                    ))}
                </Select>
            </Tooltip>

            <ErrorHelper helper={helper} />
        </FormControl>
    )
}

function Inventory() {
    //const { value, setValue } = formItem.useValue('has_features')
    const { value: warehouse, setValue: setWarehouses } =
        formItem.useValue('inventory')
    const [open, setOpen] = React.useState<boolean>(false)
    const [currentItemWarehouse, setCurrentItemWarehouse] =
        React.useState<null | ItemWarehouse>(null)

    const handleAddWarehouse = (itemWarehouse: ItemWarehouse) => {
        const findIndex = warehouse.findIndex(
            data => data.warehouse.id === itemWarehouse.warehouse.id
        )

        if (findIndex === -1) {
            setWarehouses([...warehouse, itemWarehouse])
        } else {
            warehouse[findIndex] = {
                ...itemWarehouse
            }
            setCurrentItemWarehouse(null)
        }
        setOpen(false)
    }

    const changeStatus = async (
        ev: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        const enabled = ev.target.checked
        warehouse[index].is_enabled = enabled
        setWarehouses([...warehouse])
    }

    return (
        <>
            <Text mb={3} fontWeight="bold" fontSize="20px">
                Inventario
            </Text>

            <Heading
                fontSize="lg"
                cursor="pointer"
                textDecoration="underline"
                color="alo-blue"
                mb="4"
                onClick={() => {
                    setCurrentItemWarehouse(null)
                    setOpen(true)
                }}
            >
                Asociar almacén
            </Heading>
            <FormControl mb={4}>
                <>
                    <TableContainer>
                        <Table size="sm">
                            <Thead>
                                <Tr whiteSpace={'normal'}>
                                    <Th>Habilitado</Th>
                                    <Th>Almacén</Th>
                                    <Th>Stock mínimo</Th>
                                    <Th>Stock máximo</Th>
                                    <Th>Acciones</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {warehouse.map((itemWarehouse, i) => (
                                    <Tr key={i}>
                                        <Td>
                                            <Switch
                                                colorScheme="green"
                                                size="md"
                                                isChecked={
                                                    itemWarehouse.is_enabled
                                                }
                                                onChange={e =>
                                                    changeStatus(e, i)
                                                }
                                            ></Switch>
                                        </Td>
                                        <Td>{itemWarehouse.warehouse?.name}</Td>
                                        <Td>{itemWarehouse.min_quantity}</Td>
                                        <Td>{itemWarehouse.max_quantity}</Td>

                                        <Td>
                                            <Box
                                                display={'flex'}
                                                alignItems="center"
                                                justifyContent={'center'}
                                            >
                                                <Icon
                                                    fill={Colors.INFO}
                                                    as={Pencil}
                                                    marginRight={2}
                                                    cursor="pointer"
                                                    onClick={() => {
                                                        setCurrentItemWarehouse(
                                                            itemWarehouse
                                                        )
                                                        setOpen(true)
                                                    }}
                                                />
                                            </Box>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </>
            </FormControl>
            {open ? (
                <ModalWarehouses
                    handleAddWarehouse={handleAddWarehouse}
                    open={open}
                    onClose={() => setOpen(false)}
                    itemWarehouse={currentItemWarehouse}
                />
            ) : null}
        </>
    )
}
