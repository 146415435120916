import { Box, HStack } from '@chakra-ui/react'
import Initial from '../../components/Initial'
import NavbarAlt from '../../components/NavbarAlt'
import colors from '../../config/theme/colors'
import OrderAccounts from './OrderAccounts'
import OrderItems from './OrderItems'
import useInitial from './useInitial'

export default function SplitPayment() {
    const { pageState, order_id, delivery_id, client_id } = useInitial()
    const path = delivery_id
        ? `/orders/${order_id}/confirm?delivery_id=${delivery_id}&client_id=${client_id}`
        : `/orders/${order_id}/confirm`

    return (
        <Box minH="100vh" bg="base-4">
            <NavbarAlt title="Pago dividido" path={path} />
            <Initial pageState={pageState} h="calc(100vh - 60px)">
                <HStack w="full" align="stretch">
                    <Box
                        w="full"
                        bg={colors['base-4']}
                        minH="calc(100vh - 60px)"
                        display={'flex'}
                        flexDirection={{ base: 'column', md: 'row' }}
                    >
                        <OrderItems />
                        <OrderAccounts />
                    </Box>
                </HStack>
            </Initial>
        </Box>
    )
}
