import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import Close from '../../../../../components/Icon/Close'
import Description from '../../../../../components/Icon/Description'
import colors from '../../../../../config/theme/colors'
import { ListDetailsProps } from '../types'
import ModalInformation from './ModalInformation'

export default function ListDetails({
    featuresSelected,
    delFeature,
    onInformation
}: ListDetailsProps) {
    return (
        <>
            {featuresSelected
                .filter(fs => fs.quantity > 0 || fs.subs.length > 0)
                .map((fs, i) => (
                    <Box key={i}>
                        <Text
                            bg={(i + 2) % 2 === 1 ? colors.dark : colors.orange}
                            color="white"
                            borderRadius="md"
                            px="1"
                            fontSize="sm"
                            display="inline-block"
                            mb={2}
                        >
                            {fs.name}
                        </Text>
                        <Box mb={3}>
                            {fs.subs.map((sub, _i) => (
                                <Flex key={_i} justifyContent="space-between">
                                    <ModalInformation
                                        title={sub.name}
                                        value={sub.aditional_information || ''}
                                        onDone={info =>
                                            onInformation(
                                                sub.sub_feature_id,
                                                _i,
                                                info
                                            )
                                        }
                                        element={click => (
                                            <Text
                                                onClick={click}
                                                cursor="pointer"
                                            >
                                                {sub.name}{' '}
                                                {sub.aditional_information && (
                                                    <Icon
                                                        as={Description}
                                                        fill={colors['gray-2']}
                                                        fontSize="16"
                                                        ml={1}
                                                    />
                                                )}
                                            </Text>
                                        )}
                                    />
                                    <Text>
                                        S/{sub.price.toFixed(2)}{' '}
                                        <Icon
                                            as={Close}
                                            fill="red"
                                            fontSize="sm"
                                            ml={1}
                                            mb={1}
                                            cursor="pointer"
                                            onClick={() =>
                                                delFeature(
                                                    sub.sub_feature_id,
                                                    _i
                                                )
                                            }
                                        />
                                    </Text>
                                </Flex>
                            ))}
                            {fs.subs.length === 0 && (
                                <Text color={colors.dark} fontSize="sm">
                                    Seleccionar {fs.quantity} {fs.name}
                                </Text>
                            )}
                        </Box>
                    </Box>
                ))}
        </>
    )
}
