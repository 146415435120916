import { SelectedItem } from '../../../types/runway_state'

export default function filterItems(selectedItems: SelectedItem[]) {
    const selecteds = selectedItems.filter(
        selected =>
            selected.items.filter(item => item.quantity === 0).length !==
            selected.items.length
    )

    return selecteds.map(selected => ({
        ...selected
        // items: selected.items.filter(item => item.quantity !== 0)
    }))
}
