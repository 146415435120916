import { Box } from '@chakra-ui/react'
import { ReactNode } from 'react'
import colors from '../../config/theme/colors'

export default function BoxResult({
    children,
    border = false
}: {
    children: ReactNode
    border?: boolean
}) {
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            {...(border && {
                borderTop: `1px solid ${colors['alo-gray']}`,
                pt: '4'
            })}
            fontWeight="bold"
        >
            {children}
        </Box>
    )
}
