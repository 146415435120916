import { useEffect, useState } from 'react'
import CashboxUser from '../../../models/cashbox_user'
import { CashboxContextInterface } from '../contexts/CashboxContext'
import { get } from '../../../lib/http'
import useSession from '../../../hooks/useSession'

export default function useCashboxProviderValues(): CashboxContextInterface {
    const [status, setStatus] =
        useState<CashboxContextInterface['status']>('loading')
    const [cashboxUser, setCashboxUser] = useState<CashboxUser | null>(null)
    const { isLogged } = useSession()

    useEffect(() => {
        if (!isLogged) {
            setStatus('open')
            return
        }
        get<CashboxUser | null>('/api/cashbox_users/cash_box_user').then(
            result => {
                setStatus(result.data === null ? 'closed' : 'open')
                if (result.data) {
                    setCashboxUser(result.data.result)
                }
            }
        )
    }, [])

    return {
        cashboxUser,
        status,
        isLoading: status === 'loading'
    }
}
