import { ValTest } from './type'

type CatchError = (key: string, message: string) => any

export default function test(
    key: any,
    value: any,
    catch_error: CatchError
): ValTest<any> {
    function isEmpty(message: string) {
        if (
            value === '' ||
            value === null ||
            value === undefined ||
            (Array.isArray(value) && value.length === 0) ||
            (typeof value === 'object' &&
                !(value instanceof File) &&
                Object.values(value).length === 0)
        ) {
            catch_error(key, message)
        }
        return test(key, value, catch_error)
    }

    function number(message: string) {
        const parsed = parseInt(value) || null
        if (!Number.isInteger(parsed)) {
            catch_error(key, message)
        }
        return test(key, value, catch_error)
    }

    function minLength(length: number, message: string) {
        if (typeof value === 'string' && value.length < length) {
            catch_error(key, message)
        }
        return test(key, value, catch_error)
    }

    function maxLength(length: number, message: string) {
        if (typeof value === 'string' && value.length > length) {
            catch_error(key, message)
        }
        return test(key, value, catch_error)
    }

    function isFalse(message: string) {
        if (value === false) {
            catch_error(key, message)
        }
        return test(key, value, catch_error)
    }

    function isTrue(message: string) {
        if (value === true) {
            catch_error(key, message)
        }
        return test(key, value, catch_error)
    }

    function condition(expect: (value: any) => string | void) {
        const message = expect(value)
        if (message) {
            catch_error(key, message)
        }
        return test(key, value, catch_error)
    }

    function email(message: string) {
        const regex = /\S+@\S+\.\S+/
        if (!regex.test(value)) {
            catch_error(key, message)
        }
        return test(key, value, catch_error)
    }

    function isGreater(valTest: number, message: string) {
        if (value > valTest) {
            catch_error(key, message)
        }
        return test(key, value, catch_error)
    }

    function isMinor(valTest: number, message: string) {
        if (value < valTest) {
            catch_error(key, message)
        }
        return test(key, value, catch_error)
    }

    function isIP(message: string) {
        const regex =
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
        if (!regex.test(value)) {
            catch_error(key, message)
        }
        return test(key, value, catch_error)
    }

    function isEqual(valTest: number, message: string) {
        if (Number(value) === Number(valTest)) {
            catch_error(key, message)
        }
        return test(key, value, catch_error)
    }

    return {
        isEmpty,
        number,
        minLength,
        maxLength,
        isFalse,
        isTrue,
        condition,
        email,
        isGreater,
        isMinor,
        isIP,
        isEqual
    }
}
