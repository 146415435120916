import { Tr, Td } from '@chakra-ui/react'
import List from '../../../lib/list'
import { listProdItemCategories } from '../storage'
import Edition from './Edition'
import DeleteCategory from './DeleteCategory'

export default function Category() {
    return (
        <List
            control={listProdItemCategories}
            tbody={(category, e) => (
                <Tr key={e}>
                    <Td textAlign="center" width="28">
                        {e}
                    </Td>
                    <Td textAlign="center">{category.name}</Td>
                    <Td textAlign="center">
                        <Edition category={category} />
                        <DeleteCategory category={category} />
                    </Td>
                </Tr>
            )}
        />
    )
}
