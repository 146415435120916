import {
    Box,
    Button,
    Flex,
    Heading,
    Icon,
    Text,
    useToast
} from '@chakra-ui/react'
import { useHistory, useParams } from 'react-router-dom'
import { render } from 'redity'
import BoxTotal from '../../../components/BoxTotal'
import Pencil from '../../../components/Icon/Pencil'
import Trash from '../../../components/Icon/Trash'
import useQuery from '../../../hooks/useQuery'
import { del, get } from '../../../lib/http'
import useSelectedItems from '../../ConfirmOrder/hooks/useSelectedItems'
import useAccount from '../hooks/useAccount'
import Keys from '../keys'
import { storagePage } from '../storage'
import { OrderAccount } from '../types'

interface Query {
    delivery_id: string
    client_id: string
}

export default function SubaccountCard({
    account,
    orderId
}: {
    account: any
    orderId: any
}) {
    const { order_id } = useParams<{ order_id?: string }>()
    const { delivery_id, client_id } = useQuery<Query>()
    const openModalAccount = useAccount(account)
    const { push } = useHistory()
    const { saveSelectedItems } = useSelectedItems()
    const toast = useToast()

    async function done() {
        const { data } = await get<OrderAccount>(
            `/api/account_orders/${order_id}/order`
        )
        storagePage.matrix_items = data.result.matrix_items
        storagePage.account_orders = data.result.account_orders
        storagePage.items = []
        storagePage.items_to_divide = []
        render(Keys.ORDER_ACCOUNTS)
        render(Keys.ORDER_ITEMS)
    }

    async function deleteAccount(id: number) {
        const { error } = await del(`/api/account_orders/${id}`)
        if (!error) {
            toast({
                title: 'Se ha eliminado la cuenta',
                status: 'success',
                position: 'top',
                isClosable: true
            })
            done()
        } else {
            if (
                error.type === 'development' ||
                error.type === 'customer' ||
                error.type === 'dependency'
            ) {
                const values = Object.values(error.errors)
                const message = values.length > 0 ? values[0] : error.message
                toast({
                    title: message,
                    status: 'error',
                    position: 'top'
                })
            } else {
                toast({
                    title: 'Surgió un problema al eliminar, vuelva a reintentar',
                    status: 'error',
                    position: 'top'
                })
            }
        }
    }

    function OpenPaySplitPayment() {
        saveSelectedItems([
            {
                category_item_id: 0,
                category_item_name: account.name,
                items: account.order_payment_items.map((item: any) => {
                    return {
                        id: 0,
                        order_item_id: item.id,
                        name: item.item_name,
                        quantity: item.quantity,
                        quantity_recorded: 0,
                        price: item.price_item,
                        user_price_edition: 0,
                        description: '',
                        feature_settings: [],
                        _id_generated: ''
                    }
                })
            }
        ])
        if (delivery_id) {
            push(
                `/orders/${orderId}/confirm/split/${account.id}?action=split&delivery_id=${delivery_id}&client_id=${client_id}`
            )
        } else {
            push(`/orders/${orderId}/confirm/split/${account.id}`)
        }
    }

    const total = account.order_payment_items
        .map((order_item: any) => order_item.final_price_item)
        .reduce((prev: any, curr: any) => prev + curr, 0)

    return (
        <Flex
            h="250px"
            bg="white"
            borderRadius="lg"
            boxShadow="md"
            p="6"
            flexDirection="column"
            justifyContent="space-between"
            position="relative"
            {...(account.status == 2 && { color: 'InactiveCaptionText' })}
        >
            <Box>
                <Flex justifyContent="space-between">
                    <Heading size="sm">
                        {account.name}
                        {account.status == 1 && (
                            <Icon
                                as={Pencil}
                                fill="blue.600"
                                marginLeft="2"
                                cursor="pointer"
                                onClick={openModalAccount}
                            />
                        )}
                    </Heading>
                    {account.status == 1 && (
                        <Icon
                            as={Trash}
                            fill="red.400"
                            cursor="pointer"
                            onClick={() => deleteAccount(account.id)}
                        />
                    )}
                </Flex>
                <Box mt={2} px={2} overflowY="auto" maxHeight="94px">
                    {account.order_payment_items.map((opi: any, i: number) => (
                        <Box
                            key={i}
                            display="flex"
                            justifyContent="space-between"
                            mb="2"
                            alignItems="start"
                        >
                            <Box display="flex" mr={8}>
                                <Text w="7">{opi.quantity}</Text>
                                <Text fontSize="sm">{opi.item_name}</Text>
                            </Box>
                            <Text fontSize="sm" w="24" textAlign="right">
                                S/ {opi.final_price_item.toFixed(2)}
                            </Text>
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box>
                <BoxTotal border pt="2">
                    <Text fontSize="14px">TOTAL</Text>
                    <Text fontSize="14px" pr={2}>
                        S/ {total.toFixed(2)}
                    </Text>
                </BoxTotal>
                {account.status == 1 && (
                    <Button
                        colorScheme="green"
                        w="full"
                        mt="3"
                        size="sm"
                        minH="32px"
                        borderRadius="md"
                        isDisabled={account.order_payment_items.length === 0}
                        onClick={OpenPaySplitPayment}
                    >
                        Pagar
                    </Button>
                )}
                {account.status == 2 && (
                    <Button
                        colorScheme="blue"
                        w="full"
                        mt="3"
                        size="sm"
                        minH="32px"
                        borderRadius="md"
                        isDisabled
                    >
                        Pagado
                    </Button>
                )}
            </Box>
        </Flex>
    )
}
