import {
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react'
import usePrintReports from '../../hooks/usePrintReports'
import ContentForm from './ContentForm'
import { ModalProdCatCourtesyProps } from './types'

export default function ModalProdCatCourtesy({
    isOpen,
    onClose
}: ModalProdCatCourtesyProps) {
    const { submit, isLoading } = usePrintReports()

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick>
            <ModalOverlay />
            <ModalContent p={4}>
                <ModalHeader textAlign="center">
                    Reporte de Ventas por Productos, Categorías y Cortesías
                </ModalHeader>
                <ModalBody>
                    <ContentForm onSubmit={submit} isLoading={isLoading} />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
