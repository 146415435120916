import { Button, Flex, SimpleGrid } from '@chakra-ui/react'
import { render, useRender } from 'redity'
import { get, post } from '../../../lib/http'
import Keys from '../keys'
import { storagePage } from '../storage'
import useInitial from '../useInitial'
import { OrderAccount } from '../types'
import SubaccountCard from './AccountCard'
import { useState } from 'react'

export default function OrderAccounts() {
    useRender(Keys.ORDER_ACCOUNTS)
    const { order_id } = useInitial()
    const [addingAccount, setAddingAccount] = useState(false)

    async function done() {
        const { data } = await get<OrderAccount>(
            `/api/account_orders/${order_id}/order`
        )
        storagePage.account_orders = data.result.account_orders
        storagePage.matrix_items = data.result.matrix_items
        render(Keys.ORDER_ITEMS)
        render(Keys.ORDER_ACCOUNTS)
        setAddingAccount(false)
    }

    async function newAccount() {
        setAddingAccount(!addingAccount)
        await post('/api/account_orders', {
            order_id: order_id
        })
        done()
    }

    return (
        <Flex p="4" pr="0" flexDirection="column" w="full">
            <Button
                colorScheme="blue"
                w="fit-content"
                size="sm"
                minH="32px"
                borderRadius="md"
                px="5"
                mb="3"
                onClick={newAccount}
                {...(addingAccount === true && { isLoading: true })}
            >
                Agregar subcuenta
            </Button>
            <SimpleGrid
                spacing={3}
                overflowY="auto"
                maxH={{ md: 'calc(100vh - 136px)' }}
                minChildWidth={{
                    sm: '300px',
                    xl: '300px'
                }}
                pr={3}
                templateColumns={{ base: '1fr', xl: 'repeat(2, 1fr)' }}
            >
                {storagePage.account_orders.map((account, i) => (
                    <SubaccountCard
                        key={i}
                        account={account}
                        orderId={order_id}
                    />
                ))}
            </SimpleGrid>
        </Flex>
    )
}
