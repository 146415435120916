import FormEvent from '../../lib/form_event'
import ControlList from '../../lib/list/ControlList'
import PrinterMachine from '../../models/printer_machine'
import { FormPrinterMachine } from './types'

export const controlList = new ControlList<PrinterMachine>({
    path: '/api/printer_machines',
    theads: ['Nombre', 'Tipo de conexión', 'IP', 'Zona', 'Acciones']
})

export const formPrinterMachine = new FormEvent<FormPrinterMachine>({
    name: '',
    ip: '',
    type_printer: '',
    zone_id: ''
})
