import useTab from '../../hooks/useTab'
import useAgency from './Agency/useAgency'
import useSupply from './Driver/useDriver'

export default function useControl() {
    const openModalFeature = useAgency()
    const openModalSupply = useSupply()
    const props = useTab('agency')

    function openModal() {
        if (props.value === 'driver') {
            openModalSupply()
        }
        if (props.value === 'agency') {
            openModalFeature()
        }
    }

    function getTextButton() {
        switch (props.value) {
            case 'driver':
                return 'Nueva guía local'
            case 'agency':
                return 'Nueva agencia'
            default:
                return 'Nuevo destino de preparación'
        }
    }

    return {
        openModal,
        getTextButton,
        tabProps: props
    }
}
