import React from 'react'
import { Box, Flex, HStack } from '@chakra-ui/react'
import NavbarAlt from '../../components/NavbarAlt'
import Initial from '../../components/Initial'
import useInitial from './useInitial'
import colors from '../../config/theme/colors'
import Details from './Details'
import MainContent from './MainContent'

const index = () => {
    const { pageState } = useInitial()
    return (
        <>
            <NavbarAlt title="Actualizar pagos" path="/sales" />
            <Initial pageState={pageState} h="calc(100vh - 60px)">
                <HStack
                    w="full"
                    align="stretch"
                    display="flex"
                    flexDirection={{ base: 'column', md: 'row' }}
                >
                    <Box
                        w="full"
                        px={{
                            xl: '16',
                            base: '1'
                        }}
                        py={{
                            xl: '10',
                            base: '6'
                        }}
                        bg="white"
                        minH={{ base: 'fit-content', md: 'calc(100vh - 60px)' }}
                    >
                        <MainContent />
                    </Box>
                    <Flex
                        minW={{
                            xl: '550px',
                            lg: '400px'
                        }}
                        minH={{ base: 'fit-content', md: 'calc(100vh - 60px)' }}
                        color={colors['alo-gray']}
                        p={{
                            base: '4',
                            md: '6'
                        }}
                        flexDirection="column"
                        marginLeft={0}
                    >
                        <Details />
                    </Flex>
                </HStack>
            </Initial>
        </>
    )
}

export default index
