import { Box, Button, Flex, Heading, IconButton, Text } from '@chakra-ui/react'
import ArrowLeft from '../../../../components/Icon/ArrowLeft'
import useDelivery from '../../../../hooks/useDelivery'
import useMenu from '../../../../hooks/useMenu'
import { HeadDetailsProps } from './types'

export default function HeadDetails({ onClose }: HeadDetailsProps) {
    const { clear } = useMenu()
    const { client } = useDelivery()

    return (
        <Box>
            <Flex
                align="center"
                pl={{
                    base: 1,
                    lg: 6
                }}
                pr={6}
                pb={2}
                gap={{ base: 2, lg: 0 }}
                justify="space-between"
            >
                <Flex alignItems="center" gap={2}>
                    <IconButton
                        icon={<ArrowLeft />}
                        size="sm"
                        aria-label="Atras"
                        display={{
                            base: 'flex',
                            lg: 'none'
                        }}
                        onClick={onClose}
                    />
                    <Heading size="sm" color="dark">
                        Detalle de pedido
                    </Heading>
                </Flex>
                <Button
                    variant="outline"
                    bg="transparent"
                    colorScheme="red"
                    size="sm"
                    rounded="full"
                    onClick={clear}
                >
                    Limpiar pedido
                </Button>
            </Flex>
            <Flex gap={2} px={6} pb={2} fontSize="sm">
                <Text whiteSpace="nowrap">Cliente:</Text>
                <Text as="b" color="dark-2" whiteSpace="nowrap">
                    {client?.type_identification_document_id === 2
                        ? client?.business_name
                        : `${client?.firstname} ${client?.lastname}`}
                </Text>
            </Flex>
        </Box>
    )
}
