import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { storagePage } from '../storage'
import { TypeIdentificationDocument } from '../constants'

export default function ClientSection() {
    const clientSelected = storagePage.client

    const type_identification_document_id =
        clientSelected!.type_identification_document_id

    return (
        <Flex alignItems="flex-start">
            <Box>
                <Heading fontSize="lg" cursor="pointer" color="alo-blue">
                    {clientSelected?.business_name ||
                        `${clientSelected?.firstname} ${clientSelected?.lastname}`}
                </Heading>
                <Text fontSize="xs" opacity=".7">
                    <Text as="b">
                        {
                            TypeIdentificationDocument[
                                type_identification_document_id
                            ]
                        }
                        :{' '}
                    </Text>
                    {clientSelected?.number}
                </Text>
            </Box>
        </Flex>
    )
}
