import { Box } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { storagePage } from '../../storage'
import SelectZones from './SelectZones'
import Tables from './Tables'
import TabZones from './TabZones'

export default function TableList() {
    const { zones } = storagePage
    const [zoneId, setZoneId] = useState(0)
    const [tableId, setTableId] = useState(0)

    useEffect(() => {
        if (zones.length > 0) {
            newSetZoneId(zones[0].id)
        }
    }, [zones])

    function newSetZoneId(_zoneId: number) {
        setZoneId(_zoneId)
    }

    return (
        <Box px={6} h="full">
            <Box mr="auto" display={{ base: 'block', md: 'none' }}>
                {zoneId !== 0 && (
                    <SelectZones
                        zones={zones}
                        currentZone={zoneId}
                        onClick={newSetZoneId}
                    />
                )}
            </Box>
            <TabZones
                zones={zones}
                currentZone={zoneId}
                onClick={newSetZoneId}
            />
            {zoneId !== 0 && (
                <Box maxH="65vh" overflowX={'hidden'} overflowY={'auto'}>
                    <Tables
                        zoneId={zoneId}
                        tableId={tableId}
                        setTableId={setTableId}
                    />
                </Box>
            )}
        </Box>
    )
}
