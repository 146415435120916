import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { ModalBookingsProps } from './types'

export default function ModalBookings({
    isOpen,
    onClose,
    bookings,
    date
}: ModalBookingsProps) {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="xl"
            isCentered
            closeOnOverlayClick
        >
            <ModalOverlay />
            <ModalContent pb={4}>
                <ModalHeader>Reservas de la comisión</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <TableContainer>
                        <Table size="sm" className="table-a-la-orden">
                            <Thead>
                                <Tr>
                                    <Th textAlign="center">Fecha</Th>
                                    <Th textAlign="center">Guía</Th>
                                    <Th textAlign="center">Platos vendidos</Th>
                                    <Th textAlign="center">Comisión</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {bookings.map(booking => (
                                    <Tr key={booking.id}>
                                        <Td>
                                            {dayjs(booking.hour).format(
                                                'DD/MM/YYYY hh:mm A'
                                            )}
                                        </Td>
                                        <Td>
                                            {booking.agency_guide?.name ||
                                                booking.driver?.name ||
                                                ''}
                                        </Td>
                                        <Td>
                                            {booking.orders.reduce(
                                                (n, order) =>
                                                    n +
                                                    order.order_items.reduce(
                                                        (_n, orderItem) =>
                                                            _n +
                                                            orderItem.quantity,
                                                        0
                                                    ),
                                                0
                                            )}
                                        </Td>
                                        <Td>
                                            S/
                                            {booking.orders.reduce(
                                                (n, order) =>
                                                    n +
                                                    order.order_items.reduce(
                                                        (_n, orderItem) =>
                                                            _n +
                                                            orderItem.commission,
                                                        0
                                                    ),
                                                0
                                            )}
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                            {bookings.length === 0 && (
                                <TableCaption>
                                    Sin reservas en el mes de{' '}
                                    {dayjs(date).format('MMMM')}
                                </TableCaption>
                            )}
                        </Table>
                    </TableContainer>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
