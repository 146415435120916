import { RefObject } from 'react'

export default function useKeys(
    mode: string,
    refInput: RefObject<HTMLInputElement>,
    maxValue: number
) {
    function clickNumHandler(num: string) {
        const elem = refInput.current as HTMLInputElement
        const value = elem.value
        if (num === '.' && value.search('\\.') > -1) {
            return
        }

        if (mode === 'amount') {
            if (Number(value + num) > maxValue) {
                elem.value = maxValue.toString()
            } else {
                elem.value = value + num
            }
        } else {
            if (Number(value + num) > 100) {
                elem.value = '100'
            } else {
                elem.value = value + num
            }
        }
    }

    function clearHandler() {
        const elem = refInput.current as HTMLInputElement
        elem.value = ''
    }

    function eraserHandler() {
        const elem = refInput.current as HTMLInputElement
        elem.value = elem.value.slice(0, -1)
    }

    return {
        clickNumHandler,
        clearHandler,
        eraserHandler
    }
}
