import { Flex, Select, SelectProps, Text } from '@chakra-ui/react'
import colors from '../../config/theme/colors'

export default function SelectWithLabel({
    label,
    w,
    width,
    h,
    height,
    m,
    mr,
    ml,
    mt,
    mb,
    margin,
    marginTop,
    marginLeft,
    marginRight,
    marginBottom,
    ...props
}: SelectWithLabelProps) {
    const nextProps = {
        w,
        width,
        height,
        h,
        m,
        mr,
        ml,
        mt,
        mb,
        margin,
        marginTop,
        marginLeft,
        marginRight,
        marginBottom
    }

    return (
        <Flex
            {...nextProps}
            alignItems="center"
            bg={colors['base-4']}
            pl="4"
            borderRadius="full"
        >
            <Text display={{ base: 'none', md: 'flex' }} whiteSpace="nowrap">
                {label}
            </Text>
            <Select
                {...props}
                border="none"
                bg="transparent"
                fontWeight="bold"
            />
        </Flex>
    )
}

export interface SelectWithLabelProps extends SelectProps {
    label: string
}
