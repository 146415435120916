import { Flex, Text } from '@chakra-ui/react'
import colors from '../../../../../config/theme/colors'
import { ItemSearchProps } from './types'

export default function ItemSearch({
    onSelect,
    item,
    isActive
}: ItemSearchProps) {
    return (
        <Flex
            py="2"
            px="4"
            bg={isActive ? 'gray.100' : 'white'}
            cursor="pointer"
            _hover={{
                bg: isActive ? 'gray.100' : 'gray.50'
            }}
            justify="space-between"
            align="center"
            onClick={() => onSelect(item)}
        >
            <Text>
                {item.name}
                <Text as="span" color="blackAlpha.700" fontSize="xs" ml={2}>
                    {item.category_item.name}
                </Text>
            </Text>
            <Text
                color={colors['orange']}
                fontSize="sm"
                ml={2}
                whiteSpace="nowrap"
            >
                S/ {item.price.toFixed(2)}
            </Text>
        </Flex>
    )
}
