import { useEffect, useState } from 'react'
import { PreOrderContextInterface } from '../contexts/PreOrderContext'
import PreOrder, { PreOrderItem } from '../../../models/pre_order'
import Booking from '../../../models/booking'
import { get } from '../../../lib/http'
import useParamsQuickSaleBy from './useParamsQuickSaleBy'
import { MenuContextInterface, MenuItem } from '../../../context/MenuContext'
import Driver from '../../../models/driver'
import itemHash from '../../../utilities/item-hash'

export default function usePreOrderProviderValues(
    menuProviderValues: MenuContextInterface
): PreOrderContextInterface {
    const { preOrderId, by } = useParamsQuickSaleBy()
    const { init, setMenu } = menuProviderValues
    const [booking, setBooking] = useState<Booking | null>(null)
    const [alias, setAlias] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [driver, setDriver] = useState<Driver | null>(null)
    /**
     * Almacena las preorders seleccionados
     */
    const [preOrders, setPreOrders] = useState<PreOrder[]>([])

    async function load() {
        const response = await get<PreOrder>(`/api/pre_orders/${preOrderId}`)
        setIsLoading(false)
        if (!response.data) {
            init([])
            return
        }
        const preOrderItems = response.data.result
            .pre_order_items as PreOrderItem[]
        const menuData = preOrderItems.map<MenuItem>(preOrderItem => ({
            hash: itemHash({
                itemId: preOrderItem.item_id
            }),
            itemId: preOrderItem.item_id,
            preOrderItemId: preOrderItem.id,
            name: preOrderItem.item.name,
            quantity: preOrderItem.quantity,
            price: preOrderItem.item.price,
            description: preOrderItem?.aditional_information || '',
            categoryItemId: preOrderItem.item.category_item_id,
            categoryName: preOrderItem.item.category_item?.name || '',
            commission: preOrderItem.item.has_commission
                ? preOrderItem.item.commission
                : 0
        }))
        setBooking(response.data.result.booking)
        setAlias(response.data.result.alias)
        setPreOrders([response.data.result])
        // cuando es pedido en mesa se cargarán los menús, pero no como valores iniciales
        if (by === 'tables') {
            setMenu(menuData)
            // si es por pre-order se cargará como datos iniciales
        } else {
            init(menuData)
        }
    }

    /**
     * Este effect se ejecuta cuando la preorderId cambia.
     * La preOrderId se obtiene directamente desde la query url.
     */
    useEffect(() => {
        if (!preOrderId) {
            setIsLoading(false)
            init([])
            return
        }
        // Si es agencia no se cargarán los items de la pre-orden al menú
        // porque la agencia puede tener varias pre-orders y se tienen
        // que seleccionar en el en selector pre-pedidos
        if (booking?.type === 1) {
            setIsLoading(false)
            return
        }

        load()
    }, [preOrderId])

    return {
        preOrderId: Number(preOrderId || 0),
        booking,
        setBooking,
        alias,
        setAlias,
        preOrders,
        setPreOrders,
        isLoading,
        setDriver,
        driver
    }
}
