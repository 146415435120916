import PageState from '../../types/pageState'
import { Text, Box, Spinner, Flex, BoxProps } from '@chakra-ui/react'

export default function Initial({
    pageState = PageState.SUCCESS,
    h = '100vh',
    children,
    ...props
}: PropsInitial) {
    if (pageState === PageState.LOADING) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                h={h}
                alignItems="center"
            >
                <Spinner size="md" />
            </Box>
        )
    }

    if (pageState === PageState.ERROR) {
        return (
            <Flex justifyContent="center" p="8">
                <Text textAlign="center" color="gray-3">
                    Ha ocurrido un error con el servidor y la petición no podría
                    completarse. <br />
                    Por favor intente refrescar la página.
                </Text>
            </Flex>
        )
    }

    return <Box {...props}>{children}</Box>
}

interface PropsInitial extends BoxProps {
    pageState?: PageState
}
