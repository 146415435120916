import { Box, Heading, Text } from '@chakra-ui/react'
import { SelectedItem } from '../../types/runway_state'
import BoxResult from './BoxResult'
import ListItems from './ListItems'

interface Props {
    title: string
    alias: string
    typeConsumption: string
    selectedItems: SelectedItem[]
    subTotal: number
    discount: number
    total: number
    minHeight?: string
}

export default function CardOrder({
    title,
    alias,
    typeConsumption,
    minHeight = 'calc(100vh - 180px)',
    ...props
}: Props) {
    return (
        <Box
            bg="white"
            borderRadius="lg"
            py="8"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            minH={minHeight}
            boxShadow="md"
        >
            <Box>
                <Heading size="md" textAlign="center" mb={1}>
                    {title}
                </Heading>
                <Text textAlign="center" fontSize="xs">
                    {alias.toUpperCase()}
                </Text>
                <Text textAlign="center" fontSize="sm" fontWeight="bold" mb={8}>
                    {typeConsumption?.toUpperCase()}
                </Text>
                <ListItems items={props.selectedItems} />
            </Box>
            <Box px="8">
                <BoxResult border>
                    <Text fontSize="16px">Subtotal</Text>
                    <Text fontSize="16px">S/ {props.subTotal.toFixed(2)}</Text>
                </BoxResult>
                <BoxResult>
                    <Text fontSize="16px">Descuento</Text>
                    <Text fontSize="16px">S/ {props.discount.toFixed(2)}</Text>
                </BoxResult>
                <BoxResult>
                    <Text fontSize="20px" pt="6">
                        TOTAL
                    </Text>
                    <Text fontSize="20px" pt="6">
                        S/ {props.total.toFixed(2)}
                    </Text>
                </BoxResult>
            </Box>
        </Box>
    )
}
