import { useEffect, useState, useContext } from 'react'
import PageState from '../../types/pageState'
import { get } from '../../lib/http'
import CheckInContext from './context/CheckInContext'
import getItemsbyCategory from './utils'

export default function useInitial(order_id: string) {
    const [pageState, setPageState] = useState(PageState.LOADING)

    const { setOrderId, setItemsByCategory, setOrder } =
        useContext(CheckInContext)

    useEffect(() => {
        setOrderId(Number(order_id))

        get<any>(`/api/orders/${order_id}`)
            .then(res => {
                const { data, error } = res

                if (error) {
                    setPageState(PageState.ERROR)
                    return
                }
                const { amount, items } = getItemsbyCategory(
                    data.result?.['order_items']
                )

                setOrder({
                    ...data.result,
                    amount: amount
                })
                setItemsByCategory(items)
                setPageState(PageState.SUCCESS)
            })
            .catch(() => {
                setPageState(PageState.ERROR)
            })
    }, [])

    return {
        pageState: pageState
    }
}
