import { Input } from '@chakra-ui/react'
import Clean from '../../../components/Clean'
import { listZone } from '../storage'

export default function Filters() {
    const { clear, ...props } = listZone.useSearch()
    return (
        <Clean value={props.value} onClick={clear}>
            <Input
                w="80"
                placeholder="🏸 Buscar por nombre"
                borderRadius="md"
                {...props}
            />
        </Clean>
    )
}
