import { useGet } from '../../../hooks/useHttp'
import { CategoryCollection, SearchOrderItems } from '../types'

export default function useCategoryCollection(bookingId: number) {
    const { data, reload, isLoading } = useGet<SearchOrderItems>(
        `/api/order_items?booking_id=${bookingId}&order=asc`,
        []
    )

    const categoriesCollection: CategoryCollection[] = []

    data.result.forEach(orderItem => {
        const categoryCollection = categoriesCollection.find(
            _data => _data.category_item_id === orderItem.item.category_item_id
        )

        if (categoryCollection) {
            categoryCollection.items.push({
                order_item_id: orderItem.id,
                item_id: orderItem.item_id,
                item_name: orderItem.item.name,
                quantity: orderItem.quantity,
                states: []
            })
            return
        }

        categoriesCollection.push({
            category_item_id: orderItem.item.category_item_id,
            category_name: orderItem.item.category_item.name,
            items: [
                {
                    order_item_id: orderItem.id,
                    item_id: orderItem.item_id,
                    item_name: orderItem.item.name,
                    quantity: orderItem.quantity,
                    states: []
                }
            ]
        })
    })

    data.result.forEach(orderItem => {
        const categoryCollection = categoriesCollection.find(
            _data => _data.category_item_id === orderItem.item.category_item_id
        )

        if (categoryCollection) {
            const itemData = categoryCollection.items.find(
                _itemData => _itemData.item_id === orderItem.item_id
            )

            if (itemData) {
                orderItem.order_item_states.forEach(orderItemState => {
                    itemData.states.push({
                        order_item_state_id: orderItemState.id,
                        created_at: orderItemState.created_at,
                        counter: orderItemState.counter,
                        table_name: orderItem.order.table.name,
                        zone_name: orderItem.order.table.zone.name,
                        aditional_information:
                            orderItemState.aditional_information,
                        initial_quatity: orderItemState.initial_quantity
                    })
                })
            }
        }
    })

    categoriesCollection.forEach(categoryCollection => {
        categoryCollection.items.forEach(item => {
            const orderItems = data.result.filter(
                orderItem => orderItem.item_id === item.item_id
            )
            item.quantity = orderItems.reduce(
                (n, orderItem) => n + orderItem.quantity,
                0
            )
        })
    })

    categoriesCollection.forEach(categoryCollection => {
        categoryCollection.items = categoryCollection.items.filter(
            item => item.states.length > 0
        )
    })

    const hasCommandGroup = !!data.result.find(orderItem =>
        orderItem.order.commands.find(command => command.group_command_id)
    )

    return {
        data,
        reload,
        isLoading,
        categoriesCollection,
        hasCommandGroup
    }
}
