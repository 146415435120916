import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    Text
} from '@chakra-ui/react'
import { ChangeEvent, useEffect, useState } from 'react'
import onlyNumber from '../../../../../utilities/onlyNumber'
import { StockQuantityProps } from './types'

export default function StockQuantity({
    stock,
    message,
    onChange,
    typeInventoryOperationId
}: StockQuantityProps) {
    const [value, setValue] = useState('')

    function changeHandler(ev: ChangeEvent<HTMLInputElement>) {
        const nextValue = ev.target.value
        onChange(ev)
        setValue(
            onlyNumber(nextValue, {
                decimal: 0,
                max: typeInventoryOperationId === 1 ? 99999 : stock
            })
        )
    }

    useEffect(() => {
        setValue('')
    }, [typeInventoryOperationId])

    return (
        <>
            <FormControl isRequired isInvalid={!!message}>
                <FormLabel>Cantidad</FormLabel>
                <Input
                    placeholder="Ingresar cantidad"
                    name="operation_quantity"
                    type="number"
                    onChange={changeHandler}
                    isDisabled={typeInventoryOperationId === 0}
                    value={value}
                />
                <FormErrorMessage>{message}</FormErrorMessage>
            </FormControl>
            <HStack fontWeight="bold" spacing={4} w="full" my={4}>
                <FormControl flexDir="column" w="full">
                    <FormLabel>Stock Actual</FormLabel>
                    <Text fontSize="3xl">{stock}</Text>
                </FormControl>
                <FormControl flexDir="column" w="full">
                    <FormLabel>Nuevo Stock</FormLabel>
                    <Text fontSize="3xl">
                        {typeInventoryOperationId === 1
                            ? stock + Number(value)
                            : stock - Number(value)}
                    </Text>
                </FormControl>
            </HStack>
        </>
    )
}
