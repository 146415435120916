import { Input, useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { ChangeEvent, useState } from 'react'
import { listBooking } from '../storage'

export default function DateBooking({ type }: { type: 1 | 2 }) {
    const [value, setValue] = useState(dayjs().format('YYYY-MM-DD'))
    const toast = useToast()

    const handleChangeDate = async (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
        listBooking.setQuery('date', dayjs(e.target.value).format('YYYY-MM-DD'))
        const response = await listBooking.load()
        if (!response) {
            toast({
                title: 'Ocurrió un error al recuperar la información. Volver a intentarlo',
                position: 'top',
                status: 'error'
            })
        }
    }

    return (
        <Input
            type="date"
            w="64"
            value={value}
            onChange={handleChangeDate}
            display={{
                base: type === 1 ? 'none' : 'inline',
                md: type === 1 ? 'inline' : 'none'
            }}
        />
    )
}
