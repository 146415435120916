import { Box, Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import useExtendsJoinTables from '../../../../hooks/useJoinTables'
import { useGet } from '../../../../hooks/useHttp'
import { TableData } from '../../../ByTables/types'

export default function Tables({
    zoneId,
    tableId,
    setTableId
}: {
    zoneId: number
    tableId: number
    setTableId: (tableId: number) => void
}) {
    const { data, setQuery } = useGet<TableData[]>(
        '/api/tables/available/all',
        []
    )

    useEffect(() => {
        setQuery('zone_id', zoneId)
    }, [zoneId])

    const tables = useExtendsJoinTables(data.result)

    return (
        <>
            {tables.length === 0 && (
                <Text py="5" fontSize={'lg'}>
                    No hay mesas disponibles
                </Text>
            )}
            {tables.length !== 0 && (
                <SimpleGrid spacing={3} columns={[2, 4]} py={5}>
                    {tables.map(table => (
                        <Box
                            key={table.id}
                            cursor="pointer"
                            borderRadius="lg"
                            onClick={() => setTableId(table.id)}
                            h="120px"
                            bg={table.id !== tableId ? 'gray-3' : 'primary'}
                            p="4"
                            color="white"
                            textAlign="center"
                            display="flex"
                            alignItems={'center'}
                            justifyContent="center"
                        >
                            <Box>
                                <Heading size="md" textAlign="center">
                                    {table.name}
                                </Heading>
                                <Flex justify="center" mb={1}>
                                    {table.join_tables.map(jt => (
                                        <Text
                                            fontSize="xs"
                                            key={jt.id}
                                            fontWeight="bold"
                                            color="gray.100"
                                            mx={1}
                                        >
                                            {jt.to_table.name}
                                        </Text>
                                    ))}
                                </Flex>
                            </Box>
                        </Box>
                    ))}
                </SimpleGrid>
            )}
        </>
    )
}
