import { useToast } from '@chakra-ui/react'
import useSubmit from '../../../hooks/useSubmit'
import { get } from '../../../lib/http'
import modal from '../../../lib/modal'
import ProductionItem from '../../../models/production_item'
import { formItem, listProductionItems, PATH_ENDPOINT_ITEM } from '../storage'
import FormItem from './FormItem'

export default function useItem(item?: ProductionItem) {
    const { validation, observer, submit } = useSubmit(formItem, {
        path: item ? `${PATH_ENDPOINT_ITEM}/${item.id}` : PATH_ENDPOINT_ITEM,
        method: item ? 'put' : 'post',
        done: () => listProductionItems.load()
    })

    const toast = useToast()

    validation(v => {
        v.name.isEmpty('Es requerido el nombre')
        v.category_id.isEmpty('Es requerida la categoría')
        v.magnitude_id.isEmpty('Es requerida la unidad de medida')
    })

    observer<FormSubmit>(f => ({
        name: f.name,
        category_id: Number(f.category_id),
        magnitude_id: Number(f.magnitude_id),
        production_item_warehouses: f.inventory.map((_f: any) => {
            return {
                ...(_f.id !== 0 && {
                    id: _f.id
                }),
                warehouse_id: Number(_f.warehouse.id),
                cost: Number(_f.cost),
                min_quantity: Number(_f.min_quantity),
                max_quantity: Number(_f.max_quantity),
                stock: Number(_f.stock),
                is_enabled: _f.is_enabled
            }
        })
    }))

    async function openModal() {
        if (item) {
            const { data, error } = await get(
                `/api/production_items/${item.id}`
            )

            if (error) {
                toast({
                    title:
                        error.message ??
                        'Lo sentimos, ocurrió un error al recuperar la información , vuelva a intentarlo',
                    status: 'error',
                    position: 'top'
                })
                return
            }
            if (data) {
                const _item: any = data.result
                formItem.store({
                    id: _item.id?.toString() ?? '',
                    name: _item.name,
                    category_id: _item.production_item_category_id.toString(),
                    magnitude_id: _item.magnitude_id.toString(),
                    inventory:
                        _item.production_item_warehouses.map(
                            (production_item_warehouse: any) => {
                                return {
                                    id: production_item_warehouse.id,
                                    warehouse:
                                        production_item_warehouse.warehouse,
                                    stock: production_item_warehouse.stock,
                                    cost: production_item_warehouse.cost,
                                    min_quantity:
                                        production_item_warehouse.min_quantity,
                                    max_quantity:
                                        production_item_warehouse.max_quantity,
                                    is_enabled:
                                        production_item_warehouse.is_enabled
                                }
                            }
                        ) ?? []
                })
                formItem.init(formItem.store)
            }
        } else {
            formItem.init()
        }
        modal(FormItem, {
            title: item
                ? 'Editar artículo de inventario'
                : 'Nuevo artículo de inventario',
            onSubmit: submit,
            size: '2xl'
        })
    }

    return openModal
}

interface FormSubmit {
    name: string
    category_id: number
    magnitude_id: number
    production_item_warehouses: Array<{
        id?: number
        warehouse_id: number
        cost: number
        min_quantity: number
        max_quantity: number
        stock: number
        is_enabled: boolean
    }>
}
