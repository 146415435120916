import { Box, Icon, Input, useOutsideClick } from '@chakra-ui/react'
import ItemSearch from './ItemSearch'
import Close from '../../../../../components/Icon/Close'
import { useGet } from '../../../../../hooks/useHttp'
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { ItemData } from '../../../../../context/MenuContext'
import { FieldSearchProps } from './types'

export default function FieldSearch({ item, onSelect }: FieldSearchProps) {
    const ref = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const [search, setSearch] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [index, setIndex] = useState(0)
    const { data } = useGet<ItemData[]>(
        `2>/api/items?search=${search}&limit=20`,
        []
    )

    useOutsideClick({
        ref,
        handler: () => {
            setIsOpen(false)
        }
    })

    async function searchHandler(ev: ChangeEvent<HTMLInputElement>) {
        if (item) {
            onSelect(null)
            setIsOpen(true)
            return
        }
        setSearch(ev.target.value)
        const value = ev.target.value.trim().toLowerCase()
        if (!value) {
            setIndex(0)
            return
        }

        setIsOpen(true)
        setIndex(_index =>
            data.result.length - 1 > _index ? _index : data.result.length - 1
        )
    }

    function keyHandler(ev: KeyboardEvent<HTMLInputElement>) {
        const items = data.result
        if (ev.code === 'ArrowUp') {
            setIndex(_index => (_index === 0 ? 0 : _index - 1))
        }
        if (ev.code === 'ArrowDown') {
            setIndex(_index => (_index === items.length ? _index : _index + 1))
        }
        if (ev.code === 'Enter' || ev.code === 'NumpadEnter') {
            setIsOpen(false)
            onSelect(items.find((_, i) => i === index) || null)
            setIndex(0)
        }
        if (ev.code === 'Escape') {
            setIsOpen(false)
            onSelect(null)
            setIndex(0)
        }
    }

    function clearHandler() {
        onSelect(null)
        if (ref.current) {
            ref.current.focus()
        }
    }

    useEffect(() => {
        setSearch('')
    }, [item])

    return (
        <Box position="relative" w="full" ref={ref}>
            <Input
                placeholder="Búsqueda"
                ref={inputRef}
                borderLeftWidth={{
                    base: '1px',
                    lg: '0px'
                }}
                borderLeftRadius={{
                    base: 'lg',
                    lg: 'none'
                }}
                mr={2}
                bg="white"
                onChange={searchHandler}
                onKeyUp={ev => keyHandler(ev)}
                value={item?.name || search}
                onFocus={() => setIsOpen(true)}
            />
            <Icon
                as={Close}
                fill="gray-3"
                position="absolute"
                top="calc(50% - 7px)"
                right="4"
                fontSize="sm"
                cursor="pointer"
                zIndex="1"
                display={item ? 'block' : 'none'}
                onClick={clearHandler}
            />
            <Box
                position="absolute"
                top={12}
                zIndex={10}
                py={2}
                bg="white"
                borderRadius="md"
                boxShadow="md"
                w="full"
                minW="72"
                borderWidth="1px"
                display={isOpen ? 'block' : 'none'}
                maxH="96"
                overflow="auto"
            >
                {data.result.map((_item, i) => (
                    <ItemSearch
                        key={i}
                        item={_item}
                        hasStock={
                            !(_item.has_inventory_control && _item.stock <= 0)
                        }
                        isActive={i === index}
                        onSelect={() => {
                            onSelect(_item)
                            if (inputRef.current) {
                                inputRef.current.focus()
                            }
                            setIsOpen(false)
                        }}
                    />
                ))}
            </Box>
        </Box>
    )
}
