import { useState } from 'react'
import { SECTIONS_INVENTORY_MANAGEMENT } from '../pages/InventoryManagement/utils/constants'

export default function useTab(defaultValue: string) {
    const [value, setValue] = useState(() => defaultValue)
    function onTab(nextValue: string) {
        setValue(nextValue)
    }

    function getTitle() {
        switch (value) {
            case SECTIONS_INVENTORY_MANAGEMENT.TRANSFER:
                return 'Transferir'
            case SECTIONS_INVENTORY_MANAGEMENT.DISCOUNT:
                return 'Descontar stock'
            case SECTIONS_INVENTORY_MANAGEMENT.ADD:
                return 'Agregar stock'
            default:
                return ''
        }
    }

    const title = getTitle()

    return {
        value,
        onTab,
        title
    }
}
