import { Box, Icon, Input, useOutsideClick, Text, Flex } from '@chakra-ui/react'
import { ChangeEvent, KeyboardEvent, useRef, useState } from 'react'
import { get } from '../../../lib/http'
import Close from '../../../components/Icon/Close'
import ProductionItemWarehouse from '../../../models/production_item_warehouse'

interface Props {
    warehouse_id: string
    onSelect: (productionItem: any | null) => void
    validWarehouse: () => void
    isSimple?: boolean
    destination_warehouse_id?: string
}

export default function FieldSearchProductionItem({
    warehouse_id,
    onSelect,
    validWarehouse,
    isSimple = true,
    ...props
}: Props) {
    const ref = useRef<HTMLInputElement>(null)
    const [search, setSearch] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [index, setIndex] = useState(0)
    const [items, setItems] = useState<any[]>([])

    useOutsideClick({
        ref,
        handler: () => {
            setTimeout(() => setIsOpen(false), 100)
        }
    })

    async function searchHandler(ev: ChangeEvent<HTMLInputElement>) {
        setSearch(ev.target.value)
        const value = ev.target.value.trim().toLowerCase()
        if (!value) {
            setIsOpen(false)
            setItems([])
            setIndex(0)

            return
        }

        let url = ''

        if (isSimple) {
            if (!warehouse_id) {
                validWarehouse()
                setSearch('')
                return
            }

            url = `/api/inventory_production_items?search=${value}&limit=20&warehouse_id=${warehouse_id}`
        } else {
            if (!warehouse_id || !props.destination_warehouse_id) {
                validWarehouse()
                setSearch('')
                return
            }

            url = `/api/inventory_production_items/production_items_of_warehouses?search=${value}&limit=20&origin_warehouse_id=${warehouse_id}&destination_warehouse_id=${props.destination_warehouse_id}`
        }

        setIsOpen(true)
        const { data } = await get<Array<ProductionItemWarehouse>>(url)

        if (data) {
            setItems(data.result)
            setIndex(_index =>
                data.result.length - 1 > _index
                    ? _index
                    : data.result.length - 1
            )
        }
    }

    const getData = (_item: any) => {
        if (isSimple) {
            return _item
        }

        return {
            id: _item.id,
            cost: _item.origin_unit_cost,
            origin_stock: _item.origin_stock,
            destination_stock: _item.destination_stock,
            production_item: {
                id: _item.id,
                name: _item.name,
                magnitude: {
                    name: _item.magnitude_name
                }
            }
        }
    }

    function keyHandler(ev: KeyboardEvent<HTMLInputElement>) {
        ev.stopPropagation()

        if (ev.code === 'ArrowUp') {
            setIndex(_index => (_index === 0 ? 0 : _index - 1))
        }
        if (ev.code === 'ArrowDown') {
            setIndex(_index => (_index === items.length ? _index : _index + 1))
        }
        if (ev.code === 'Enter' || ev.code === 'NumpadEnter') {
            ev.preventDefault()
            setIsOpen(false)
            const findItem = items.find((_, i) => i === index)
            if (findItem) {
                onSelect(getData(findItem))
            } else {
                onSelect(null)
            }

            setIndex(0)
            setItems([])
            setSearch('')
        }
        if (ev.code === 'Escape') {
            setIsOpen(false)
            onSelect(null)
            setIndex(0)
            setItems([])
            setSearch('')
        }
    }

    function clearHandler() {
        setSearch('')
        onSelect(null)
        if (ref.current) {
            ref.current.focus()
        }
    }

    return (
        <Box position="relative" w="full">
            <Input
                placeholder="🏸 Buscar por nombre"
                mr={2}
                ref={ref}
                bg="white"
                onChange={searchHandler}
                onKeyDown={ev => keyHandler(ev)}
                value={search}
                type="text"
            />
            {search && (
                <Icon
                    as={Close}
                    fill="gray-3"
                    position="absolute"
                    top="calc(50% - 7px)"
                    right="4"
                    fontSize="sm"
                    cursor="pointer"
                    zIndex="1"
                    onClick={clearHandler}
                />
            )}
            <Box
                position="absolute"
                top={12}
                zIndex={10}
                py={2}
                bg="white"
                borderRadius="md"
                boxShadow="md"
                w="full"
                minW="72"
                borderWidth="1px"
                display={isOpen && items.length > 0 ? 'block' : 'none'}
            >
                {items.map((_item, i) => (
                    <Flex
                        key={_item.id}
                        py="2"
                        px="4"
                        bg={i === index ? 'gray.100' : 'white'}
                        cursor="pointer"
                        _hover={{
                            bg: i === index ? 'gray.100' : 'gray.50'
                        }}
                        justify="space-between"
                        align="center"
                        onClick={() => {
                            onSelect(getData(_item))
                            setSearch('')
                        }}
                    >
                        <Text>
                            {_item?.production_item?.name ?? _item?.name}
                        </Text>
                    </Flex>
                ))}
            </Box>
        </Box>
    )
}
