import {
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Text,
    useToast
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useGet } from '../../../../hooks/useHttp'
import User from '../../../../models/user'
import { ModalWaitersProps } from './types'
import FooterButtons from './FooterButtons'
import ItemWaiter from './ItemWaiter'
import useSaveOrderByTable from '../../hooks/useSaveOrderByTable'
import useOrderByTable from '../../hooks/useOrderByTable'
import { put } from '../../../../lib/http'
import { useHistory } from 'react-router-dom'
import useOverlapLoader from '../../../../hooks/useOverlapLoader'

export default function ModalWaiters({ isOpen, onClose }: ModalWaitersProps) {
    const toast = useToast()
    const { start, end } = useOverlapLoader()
    const { push } = useHistory()
    const { waiter, orderId } = useOrderByTable()
    const { data, reload } = useGet<User[]>('5>/api/users/3', [])
    const [waiterId, setWaiterId] = useState(0)
    const { register } = useSaveOrderByTable()
    const waiters = data.result.filter(_waiter => _waiter.id !== waiter?.id)

    async function confirmHandler() {
        onClose()

        if (!orderId) {
            // Cuando recién se va registrar una orden
            register(waiterId)
            return
        }

        // Cuando ya existe una orden

        start('Actualizando mozo')

        const { error } = await put(`/api/orders/${orderId}/waiter`, {
            waiter_id: waiterId
        })

        end()

        if (error) {
            toast({
                title: error.message,
                position: 'top',
                status: 'error',
                isClosable: true
            })
        } else {
            push('/by_tables')
            toast({
                title: 'Cambio de mozo realizado',
                position: 'top',
                isClosable: true,
                status: 'success'
            })
        }
    }

    useEffect(() => {
        if (!isOpen) return
        reload()
        setWaiterId(0)
    }, [isOpen])

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {orderId ? 'Cambio de mozo' : 'Procesar pedido'}
                </ModalHeader>
                <ModalBody>
                    <Text mb="4" color="gray-2">
                        {orderId
                            ? 'Seleccione nuevo moz@'
                            : 'Seleccione mozo para realizar pedido'}
                    </Text>
                    <SimpleGrid columns={[2, 4]} spacing={3} mb={4}>
                        {waiters.map(_waiter => (
                            <ItemWaiter
                                key={_waiter.id}
                                isActive={_waiter.id === waiterId}
                                onClick={() => setWaiterId(_waiter.id)}
                            >
                                {_waiter.firstname} {_waiter.lastname}
                            </ItemWaiter>
                        ))}
                    </SimpleGrid>
                </ModalBody>
                <FooterButtons
                    isDisabled={waiterId === 0}
                    onClose={onClose}
                    onConfirm={confirmHandler}
                />
            </ModalContent>
        </Modal>
    )
}
