import { Flex, Input } from '@chakra-ui/react'
import Clean from '../../../components/Clean'
import { listAgency } from '../storage'

export default function FiltersAgency() {
    const { clear, ...props } = listAgency.useSearch()

    return (
        <Flex>
            <Clean value={props.value} onClick={clear}>
                <Input
                    w="80"
                    placeholder="🏸 Buscar por nombre de agencia"
                    {...props}
                />
            </Clean>
        </Flex>
    )
}
