import { Flex, Spinner, Stack } from '@chakra-ui/react'
import BoxCategory from './components/BoxCategory'
import { useGet } from '../../hooks/useHttp'
import CategoryItem from '../../models/category_item'
import { useEffect } from 'react'
import { SidebearCategoriesProps } from './types'

export default function SidebarCategories({
    onCategoryItem,
    categoryItemId
}: SidebearCategoriesProps) {
    const { data, isLoading } = useGet<CategoryItem[]>(
        '/api/category_items?sort=order',
        []
    )

    useEffect(() => {
        if (data.result.length === 0) return
        onCategoryItem(data.result[0].id)
    }, [data.result.length])

    return (
        <Flex
            flexDirection={{ base: 'row', lg: 'column' }}
            bg={{ base: 'white', lg: 'gray-2' }}
            minW={{ base: '100%', lg: '165px', xl: '180px' }}
            p="3"
            overflowY="auto"
        >
            {isLoading && (
                <Flex justify="center">
                    <Spinner color="white" />
                </Flex>
            )}
            <Stack
                direction={['row', 'row', 'column']}
                alignItems="flex-start"
                spacing={[1, 1, 1, 2]}
            >
                {data.result.map(categoryItem => (
                    <BoxCategory
                        key={categoryItem.id}
                        isActive={categoryItem.id === categoryItemId}
                        name={categoryItem.name}
                        onClick={() => onCategoryItem(categoryItem.id)}
                    />
                ))}
            </Stack>
        </Flex>
    )
}
