import { Button, ModalFooter } from '@chakra-ui/react'
import { AnnulFooterProps } from './types'

export default function AnnulFooter({ onClose }: AnnulFooterProps) {
    return (
        <ModalFooter
            mt={6}
            justifyContent="space-between"
            display="flex"
            flexDirection={{ base: 'column', sm: 'row' }}
        >
            <Button
                variant="alo-gray:lg-md"
                bg="transparent"
                color="alo-gray"
                onClick={onClose}
                fontSize={{ base: '16px', md: '20px' }}
                w="48"
                m="5px"
            >
                Cancelar
            </Button>
            <Button
                variant="alo-red:lg-md"
                w="48"
                type="submit"
                m="5px"
                fontSize={{ base: '16px', md: '20px' }}
            >
                Confirmar
            </Button>
        </ModalFooter>
    )
}
