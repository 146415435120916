import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    FormControl,
    FormLabel,
    Input,
    Text,
    useDisclosure,
    useId
} from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import Trash from '../../../../components/Icon/Trash'
import useAnnul from '../../hooks/useAnnul'

export default function AnnulDelivery() {
    const initRef = useRef<HTMLInputElement>(null)
    const { isOpen, onClose, onOpen } = useDisclosure()
    const annul = useAnnul()
    const [reason, setReason] = useState('')
    const id = useId()

    useEffect(() => {
        setReason('')
    }, [isOpen])

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={initRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Anular pedido
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <form
                                id={id}
                                onSubmit={ev => {
                                    ev.preventDefault()
                                    annul(reason)
                                }}
                            >
                                <Text mb={4}>
                                    ¿Está seguro anular el pedido?
                                </Text>
                                <FormControl isRequired>
                                    <FormLabel fontSize="sm">
                                        Escribir motivo de anulación
                                    </FormLabel>
                                    <Input
                                        placeholder="Razón"
                                        value={reason}
                                        autoFocus
                                        ref={initRef}
                                        onChange={ev =>
                                            setReason(ev.target.value)
                                        }
                                    />
                                </FormControl>
                            </form>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button onClick={onClose}>Cancelar</Button>
                            <Button
                                colorScheme="red"
                                isDisabled={!reason}
                                ml={2}
                                form={id}
                                type="submit"
                            >
                                Continuar
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <Button
                onClick={onOpen}
                colorScheme="red"
                w="full"
                mb={2}
                fill="white"
                fontWeight="normal"
                leftIcon={<Trash />}
            >
                Anular orden
            </Button>
        </>
    )
}
