import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { post } from '../../../lib/http'
import { controlList } from '../storage'

export default function useSendValidate(
    filename: string,
    typeVoucherId: number,
    sunatVoucherId: number
) {
    const [isOpen, setOpen] = useState(false)
    const [error, setError] = useState('')
    const [isLoading, setLoading] = useState(false)
    const toast = useToast()

    async function openHandler() {
        setLoading(true)
        setOpen(true)
        setError('')
        const path =
            typeVoucherId === 3
                ? '/api/sunat_vouchers/send_credit_note'
                : '/api/sunat_vouchers/send_sunat_voucher'

        const body =
            typeVoucherId === 3
                ? {
                      sunat_voucher_id: sunatVoucherId
                  }
                : {
                      filename
                  }

        const res = await post(path, body)

        if (res.error) {
            toast({
                title: 'Han ocurrido problemas',
                position: 'top',
                status: 'error'
            })
            setError(res.error.message)
        } else {
            setOpen(false)
            controlList.load()
            toast({
                title: 'La operación fue exitosa',
                position: 'top',
                status: 'success'
            })
        }
        setLoading(false)
    }

    return {
        isOpen,
        setOpen,
        isLoading,
        openHandler,
        error
    }
}
