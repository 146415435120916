import FormEvent from '../../lib/form_event'
import ControlList from '../../lib/list/ControlList'
import Agency from '../../models/agency'
import Driver from '../../models/driver'

export const PATH_ENDPOINT_DRIVER = '/api/drivers'
export const PATH_ENDPOINT_AGENCY = '/api/agencies'
export const listDriver = new ControlList<Driver>({
    path: PATH_ENDPOINT_DRIVER,
    theads: ['#', 'Nombre', 'Acciones']
})

export const listAgency = new ControlList<Agency>({
    path: PATH_ENDPOINT_AGENCY,
    theads: ['#', 'Nombre', 'N° de guías', 'Acciones']
})

export const formAgency = new FormEvent<any>({
    name: '',
    agency_guides: [
        {
            name: ''
        }
    ]
})

export const formDriver = new FormEvent({
    id: '',
    name: ''
})
