import { useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import useSubmit from '../../../../hooks/useSubmit'
import { get } from '../../../../lib/http'
import modal from '../../../../lib/modal'
import OutstandingQuota from '../../../../models/outstanding_quota'
import { controlList, formPayment } from '../../storage'
import ModalPayment from '.'

export default function useModalPayment(creditOrderId: number) {
    const toast = useToast()
    const { validation, observer, submit } = useSubmit(formPayment, {
        path: `/api/outstandings/${creditOrderId}/payment`,
        method: 'post',
        done: () => {
            controlList.load()
            toast({
                title: 'Pago de crédito realizado correctamente',
                status: 'success',
                position: 'top'
            })
        },
        error: e => {
            toast({
                title: e.message,
                position: 'top',
                status: 'error'
            })
        }
    })

    validation((v, f) => {
        v.payment_date.isEmpty('Es requerida la fecha de pago')
        v.destiny.isEmpty('Seleccione un destino')
        v.payment_type.isEmpty('Seleccione un método de pago')
        if (f?.payment_type == 3) {
            v.sub_payment_type.isEmpty('Seleccione una forma de pago')
        }
        v.amount.isMinor(0.01, 'El monto debe ser mayor a 0')
        v.amount.isEmpty('Es requerido el monto')
    })

    observer<FormSubmit>(f => ({
        payment_date: f.payment_date,
        cashbox_user_id: f.destiny,
        type_payment_id: f.payment_type,
        sub_payment_type: f.sub_payment_type,
        amount: f.amount
    }))

    async function openModal() {
        const { data, error } = await get<OutstandingQuota[]>(
            `/api/outstandings/${creditOrderId}/quotas`
        )

        if (error) {
            toast({
                title:
                    error.message ||
                    'Lo sentimos, ocurrió un error al recuperar el cronograma de pagos',
                status: 'error',
                position: 'top'
            })
            return
        }

        const schedule: OutstandingQuota[] = data.result
        formPayment.store({
            payment_date: dayjs().format('YYYY-MM-DD'),
            destiny: '',
            payment_type: '',
            sub_payment_type: '',
            amount: '',
            schedule: schedule
        })
        formPayment.init(formPayment.store)

        modal(ModalPayment, {
            title: 'Nuevo pago',
            onSubmit: submit,
            size: '2xl',
            isCentered: false
        })
    }

    return openModal
}

interface FormSubmit {
    payment_date: string
    cashbox_user_id: number
    type_payment_id: number
    sub_payment_type: number
    amount: number | null
}
