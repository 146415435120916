import { FeatureSetting, SelectedItem } from '../../../../types/runway_state'
import { storagePage } from '../../storage'

export function compareFeatureSetting(
    prevFeatureSettings: FeatureSetting[],
    nextFeatureSettings: FeatureSetting[]
) {
    function comparePrev(
        nextFS: FeatureSetting,
        nextSub: FeatureSetting['feature_setting_sub_features'][number]
    ) {
        let finded = false
        for (const prevFS of prevFeatureSettings) {
            if (
                nextFS.feature_id === prevFS.feature_id &&
                prevFS.feature_setting_sub_features.length !==
                    nextFS.feature_setting_sub_features.length
            ) {
                return false
            }

            for (const prevFSSF of prevFS.feature_setting_sub_features) {
                if (
                    nextFS.feature_id === prevFS.feature_id &&
                    prevFSSF.sub_feature_id === nextSub.sub_feature_id
                ) {
                    finded = true
                }
            }
        }

        return finded
    }

    for (const nextFS of nextFeatureSettings) {
        for (const nextSub of nextFS.feature_setting_sub_features) {
            if (!comparePrev(nextFS, nextSub)) {
                return false
            }
        }
    }

    return true
}

export function getItemsSelected(categoryItemId: number, itemId: number) {
    const { selectedItems } = storagePage

    const category = selectedItems.find(
        selected => selected.category_item_id === categoryItemId
    )

    let itemsFinded: SelectedItem['items'] = []

    if (category) {
        itemsFinded = category.items.filter(_item => _item.id === itemId)
    }

    return {
        items: itemsFinded,
        category
    }
}
