import CashBox from '../models/cashbox'
import Constant from '../models/constant'
import Role from '../models/role'
import BusinessVariable from '../models/business_variable'
import loadInitialData from '../utilities/loadInitialData'
import TypeMeasure from '../models/type_measure'
import TypeInventoryOperation from '../models/type_inventory_operation'

export default function storage() {
    return loadInitialData<Storage>({
        roles: '/api/roles',
        movement_type: '/api/constant/movement_types',
        cashboxes: '/api/cashboxes',
        income_concept: '1>/api/constant/concepts/1',
        output_concept: '2>/api/constant/concepts/2',
        business_variables: '/api/business_variables/all',
        type_measures: '/api/type_measures',
        type_inventory_operations: '/api/type_inventory_operations/all'
    })
}

export interface Storage {
    roles: Role[]
    movement_type: Constant
    cashboxes: CashBox[]
    income_concept: Constant
    output_concept: Constant
    business_variables: BusinessVariable[]
    type_measures: TypeMeasure[]
    type_inventory_operations: TypeInventoryOperation[]
}

export const initialStorage: Storage = {
    roles: [],
    movement_type: {} as any,
    cashboxes: [],
    income_concept: {} as any,
    output_concept: {} as any,
    business_variables: [],
    type_measures: [],
    type_inventory_operations: []
}
