import { Icon } from '@chakra-ui/react'
import Trash from '../../../../components/Icon/Trash'
import colors from '../../../../config/theme/colors'
import Delete from '../../../../lib/delete'
import PreOrder from '../../../../models/pre_order'
import { listPreOrder, PATH_ENDPOINT_PRE_ORDER } from '../../storage'

export default function DeletePreOrder({ preOrder }: { preOrder: PreOrder }) {
    const isAttended = preOrder.pre_order_state === 2
    return (
        <Delete
            title="Eliminar Pre-pedido"
            path={`${PATH_ENDPOINT_PRE_ORDER}/${preOrder.id}`}
            element={click => (
                <Icon
                    onClick={click}
                    fill={isAttended ? colors['gray-3'] : colors.error}
                    as={Trash}
                    cursor={isAttended ? 'default' : 'pointer'}
                    pointerEvents={isAttended ? 'none' : 'auto'}
                />
            )}
            done={() => {
                listPreOrder.setQuery('page', 1)
                listPreOrder.load()
            }}
        >
            ¿Está seguro de eliminar el Pre Pedido de <b>{preOrder.alias}</b>?
        </Delete>
    )
}
