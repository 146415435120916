export default function Touch(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 21 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.33 15.175l-4.05-2.025a6.811 6.811 0 002.65-5.4c0-3.788-3.088-6.875-6.875-6.875C6.267.875 3.18 3.962 3.18 7.75a6.867 6.867 0 003.75 6.113v4.075l-2.3-.488-.125-.025a1.978 1.978 0 00-.4-.038c-.663 0-1.288.263-1.763.738L.592 19.9l6.363 6.363c.537.55 1.287.862 2.062.862h7.875a2.486 2.486 0 002.463-2.087l1-5.888a3.74 3.74 0 00-2.025-3.975zm-.438 3.563l-1 5.887H9.017a.461.461 0 01-.3-.125l-4.6-4.6 5.313 1.113V7.75c0-.35.275-.625.625-.625s.625.275.625.625v7.5h2.2l4.325 2.163c.5.25.775.787.687 1.325zM10.055 3.375A4.38 4.38 0 005.68 7.75a4.33 4.33 0 001.25 3.05V7.75a3.126 3.126 0 016.25 0v3.05a4.33 4.33 0 001.25-3.05 4.38 4.38 0 00-4.375-4.375z"
            />
        </svg>
    )
}
