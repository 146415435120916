import { Tr, Td } from '@chakra-ui/react'
import List from '../../../lib/list'
import { listAgency } from '../storage'
import DeleteAgency from './DeleteAgency'
import Edition from './Edition'

export default function Agency() {
    return (
        <List
            control={listAgency}
            tbody={(agency, e) => (
                <Tr key={e}>
                    <Td textAlign="center" width="28">
                        {e}
                    </Td>
                    <Td textAlign="center">{agency?.name}</Td>
                    <Td textAlign="center">{agency.agency_guides?.length}</Td>
                    <Td textAlign="center">
                        <Edition agency={agency} />
                        <DeleteAgency agency={agency} />
                    </Td>
                </Tr>
            )}
        />
    )
}
