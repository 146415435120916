import { Box, Icon, Text } from '@chakra-ui/react'
import ChevronUp from '../../components/Icon/ChevronUp'
import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import Tabs, { Tab } from '../../components/Tabs'
import useTab from '../../hooks/useTab'
import AddStock from './components/AddStock'
import DiscountStock from './components/DiscountStock'
import SectionKardex from './components/SectionKadex'
import TransferStock from './components/Transfer'
import { SECTIONS_INVENTORY_MANAGEMENT } from './utils/constants'

export default function InventoryManagement() {
    const tabProps = useTab('')

    const handleClose = () => tabProps.onTab('')

    const renderHeader = () => {
        if (tabProps.value === '') {
            return (
                <Tabs {...tabProps}>
                    <Tab value={SECTIONS_INVENTORY_MANAGEMENT.ADD}>
                        Agregar stock
                    </Tab>
                    <Tab value={SECTIONS_INVENTORY_MANAGEMENT.DISCOUNT}>
                        Descontar stock
                    </Tab>
                    <Tab value={SECTIONS_INVENTORY_MANAGEMENT.TRANSFER}>
                        Transferir
                    </Tab>
                </Tabs>
            )
        } else {
            return (
                <Text fontWeight={'bold'} mb="4" fontSize={'2xl'}>
                    {tabProps.title}
                </Text>
            )
        }
    }

    return (
        <Sidebar>
            <SimpleNav title="Administración de Inventario" />
            {renderHeader()}
            {tabProps.value === '' ? (
                <SectionKardex />
            ) : (
                <Box
                    display={'inline-flex'}
                    gap={2}
                    alignItems={'center'}
                    onClick={handleClose}
                    cursor={'pointer'}
                    mt={2}
                >
                    <Icon
                        transform="rotate(-90deg)"
                        as={ChevronUp}
                        fill={'black'}
                    />
                    <Text>Regresar</Text>
                </Box>
            )}
            {tabProps.value === SECTIONS_INVENTORY_MANAGEMENT.TRANSFER && (
                <TransferStock onClose={handleClose} />
            )}
            {tabProps.value === SECTIONS_INVENTORY_MANAGEMENT.DISCOUNT && (
                <DiscountStock onClose={handleClose} />
            )}
            {tabProps.value === SECTIONS_INVENTORY_MANAGEMENT.ADD && (
                <AddStock onClose={handleClose} />
            )}
        </Sidebar>
    )
}
