import { Box, Flex } from '@chakra-ui/react'
import Initial from '../../components/Initial'
import NavbarAlt from '../../components/NavbarAlt'
import AlertCashbox from './components/AlertCashbox'
import SidebarCategories from './components/SidebarCategories'
import ListItems from './components/ListItems'
import Details from './components/Details'
import Searcher from './components/Searcher'
import useOrderByTableProviderValues from './hooks/useOrderByTableProviderValues'
import OrderByTableContext from './contexts/OrderByTableContext'
import useCashboxProviderValues from './hooks/useCashboxProviderValues'
import CashboxContext from './contexts/CashboxContext'
import useSession from '../../hooks/useSession'
import PreOrderContext from './contexts/PreOrderContext'
import usePreOrderProviderValues from './hooks/usePreOrderProviderValues'
import { useState } from 'react'
import useParamsQuickSaleBy from './hooks/useParamsQuickSaleBy'
import useMenuProviderValues from '../../hooks/useMenuProviderValues'
import MenuContext from '../../context/MenuContext'

export default function QuickSaleBy() {
    const menuProviderValues = useMenuProviderValues()
    const preOrderPrivderValues = usePreOrderProviderValues(menuProviderValues)
    const orderByTableProviderValues = useOrderByTableProviderValues(
        menuProviderValues,
        preOrderPrivderValues
    )
    const cashboxProviderValues = useCashboxProviderValues()
    const { isLogged } = useSession()
    const [categoryItemId, setCategoryItemId] = useState(0)
    const isOpenCashbox = cashboxProviderValues.status !== 'closed'
    const { by } = useParamsQuickSaleBy()

    return (
        <MenuContext.Provider value={menuProviderValues}>
            <OrderByTableContext.Provider value={orderByTableProviderValues}>
                <CashboxContext.Provider value={cashboxProviderValues}>
                    <PreOrderContext.Provider value={preOrderPrivderValues}>
                        <Initial h="calc(100vh - 60px)">
                            <NavbarAlt
                                title={
                                    by === 'tables'
                                        ? 'Nuevo pedido'
                                        : 'Nuevo Pre-pedido'
                                }
                                path={
                                    by === 'tables'
                                        ? '/by_tables'
                                        : '/pre_orders'
                                }
                            />
                            {!isOpenCashbox && isLogged && by === 'tables' && (
                                <AlertCashbox />
                            )}
                            <Flex
                                minH={`calc(100vh - ${
                                    !isOpenCashbox && isLogged ? '86' : '60'
                                }px)`}
                                w="full"
                                flexDirection={{ base: 'column', lg: 'row' }}
                                alignItems="stretch"
                            >
                                <Box w="full" position="relative">
                                    <Searcher />
                                    <Flex
                                        w="full"
                                        h={`calc(100% - 57px)`}
                                        position="relative"
                                        flexDirection={{
                                            base: 'column',
                                            lg: 'row'
                                        }}
                                    >
                                        <SidebarCategories
                                            onCategoryItem={setCategoryItemId}
                                            categoryItemId={categoryItemId}
                                        />
                                        <ListItems
                                            categoryItemId={categoryItemId}
                                        />
                                    </Flex>
                                    {!orderByTableProviderValues.isModeEdition && (
                                        <Box
                                            position="absolute"
                                            top="0"
                                            left={0}
                                            width="100%"
                                            h="100%"
                                            bg="dark-2"
                                            opacity="0.5"
                                            zIndex={2}
                                        />
                                    )}
                                </Box>
                                <Details />
                            </Flex>
                        </Initial>
                    </PreOrderContext.Provider>
                </CashboxContext.Provider>
            </OrderByTableContext.Provider>
        </MenuContext.Provider>
    )
}
