import dayjs from 'dayjs'
import { get } from '../../../lib/http'
import { controlList, storagePage } from '../storage'

export default async function load() {
    const [res1] = await Promise.all([
        get<boolean>(
            `/api/sunat_vouchers/status_daily_summary?generation_date=${dayjs().format(
                'YYYY-MM-DD'
            )}`
        ),
        controlList.load()
    ])

    switch (res1.data.result) {
        case false:
            storagePage.status = 'pending'
            break
        case true:
            storagePage.status = 'validated'
            break
        default:
            storagePage.status = null
            break
    }
    controlList.render()
}
