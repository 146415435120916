export default function getInformationForWaiter(
    name: string,
    lastname: string
) {
    if (!name && !lastname) return null
    const arr = name.split(' ')

    //loop through each element of the array and capitalize the first letter.

    for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
    }

    //Join all the elements of the array back into a string
    //using a blankspace as a separator
    const nameStr = arr.join(' ')
    return nameStr + ' ' + lastname.charAt(0).toUpperCase() + '.'
}
