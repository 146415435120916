import { Tabs, TabList, Tab } from '@chakra-ui/react'
import Zone from '../../../../models/zone'

export default function TabZones({
    zones,
    currentZone,
    onClick
}: {
    zones: Zone[]
    currentZone: number
    onClick: (zoneId: number) => void
}) {
    const index = zones.findIndex(zone => {
        return zone.id == currentZone
    })
    return (
        <Tabs display={{ base: 'none', md: 'block' }} index={index}>
            <TabList {...(zones.length === 0 && { border: 'none' })}>
                {zones.map(zone => (
                    <Tab key={zone.id} onClick={() => onClick(zone.id)}>
                        {zone.name}
                    </Tab>
                ))}
            </TabList>
        </Tabs>
    )
}
