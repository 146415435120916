import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PageState from '../../types/pageState'
import Zone from '../../models/zone'
import { Order, OrderAccount } from './types'
import { get } from '../../lib/http'
import { Result } from '../../lib/http/type'
import { storagePage } from './storage'
//import orderItemsToSelectedItems from '../QuickSale/utilities/orderItemsToSelectedItems'

export default function useInitial() {
    const [pageState, setPageState] = useState(PageState.LOADING)
    const { order_id } = useParams<{ order_id?: string }>()
    //const { saveSelectedItems } = useRunway()

    async function load() {
        const request: Array<Promise<any>> = [
            get<Order>(`/api/account_orders/${order_id}/items`),
            get<OrderAccount>(`/api/account_orders/${order_id}/order`),
            get<Zone[]>('2>/api/zones/?sort=name&is_enabled=true')
        ]

        const [result1, result2, result3] = (await Promise.all(request)) as [
            Result<Order>,
            Result<OrderAccount>,
            Result<Zone[]>
        ]

        if (result1.error || result2.error || result3.error) {
            setPageState(PageState.ERROR)
            return
        }

        storagePage.matrix_items = result2.data.result.matrix_items

        storagePage.zones = result3.data.result
        storagePage.table_id = result1.data.result.table_id
        storagePage.items_to_move = []
        storagePage.table_move_id = 0

        setPageState(PageState.SUCCESS)
    }

    useEffect(() => {
        load()
    }, [])

    return {
        pageState,
        order_id
    }
}
