import {
    Box,
    Flex,
    Input,
    Table,
    TableContainer,
    Tbody,
    Text,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import Clean from '../../../../components/Clean'
import Pagination from '../../../../components/Pagination'
import { useGet } from '../../../../hooks/useHttp'
import DownloadExcel from './DownloadExcel'
import TableRow from './TableRow'
import { FindItem } from './types'
import SelectWithLabel from '../../../../components/SelectWithLabel'
import Warehouse from '../../../../models/warehouse'

export default function SectionKardex() {
    const [search, setSearch] = useState('')
    const [startDate] = useState(() => dayjs().set('D', 1).format('YYYY-MM-DD'))
    const [endDate] = useState(() => {
        const date = new Date()
        date.setDate(24) //seteamos a 24 en caso la fecha el día sea 31 y evitar que la fecha final sea un mes mas
        date.setMonth(date.getMonth() + 1)
        date.setDate(0)
        return dayjs(date).format('YYYY-MM-DD')
    })
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(25)
    const [warehouse_id, setWarehouseId] = useState<string>('')

    const { data, reload } = useGet<FindItem[]>(
        `/api/inventory_production_items?limit=${limit}&page=${page}&search=${search}${
            warehouse_id ? `&warehouse_id=${warehouse_id}` : ''
        }`,
        []
    )

    const { data: dataWarehouse } = useGet<Warehouse[]>(
        '2>/api/warehouses?limit=0&page=1&order=asc&sort=name&search=',
        []
    )

    return (
        <Box mt={4}>
            <Flex
                justify="space-between"
                mb={4}
                flexDir={['column', 'column', 'row']}
                gap={2}
            >
                <Box
                    display={'flex'}
                    gap={2}
                    flexDir={{
                        base: 'column',
                        md: 'row'
                    }}
                >
                    <Clean value={search} onClick={() => setSearch('')}>
                        <Input
                            w={{ base: 'full', md: '380px' }}
                            placeholder="🏸 Buscar por nombre de artículo"
                            value={search}
                            onChange={ev => setSearch(ev.target.value)}
                        />
                    </Clean>
                    <Box>
                        <Text
                            display={{
                                base: 'block',
                                md: 'none'
                            }}
                            fontSize={'sm'}
                            fontWeight={'semibold'}
                        >
                            Seleccionar almacén
                        </Text>
                        <SelectWithLabel
                            onChange={e => {
                                setWarehouseId(e.target.value ?? '')
                            }}
                            label="Seleccionar almacén"
                            placeholder="Todos"
                        >
                            {dataWarehouse.result.map(d => (
                                <option key={d.id} value={d.id}>
                                    {d.name}
                                </option>
                            ))}
                        </SelectWithLabel>
                    </Box>
                </Box>

                <Flex gap={2} flexDir={['column', 'column', 'row']}>
                    <DownloadExcel
                        startDate={dayjs
                            .tz(startDate + ' 00:00:00', 'America/Lima')
                            .toISOString()}
                        endDate={dayjs
                            .tz(endDate + ' 23:59:59', 'America/Lima')
                            .toISOString()}
                    />
                </Flex>
            </Flex>
            <TableContainer>
                <Table size="md" className="table-a-la-orden">
                    <Thead>
                        <Tr>
                            <Th textAlign="center">Artículo</Th>
                            <Th textAlign="center">Almacén</Th>
                            <Th textAlign="center">Stock</Th>
                            <Th textAlign="center">Unidad de medida</Th>
                            <Th textAlign="center">Costo unitario</Th>
                            <Th textAlign="center">Costo total</Th>
                            <Th textAlign="center">Acciones</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.result.map(item => (
                            <TableRow
                                reload={reload}
                                key={item.id}
                                item={item}
                                startDate={startDate}
                                endDate={endDate}
                            />
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <Pagination
                limit={limit}
                page={page}
                pages={data.pagination?.pages ?? 0}
                onLimit={setLimit}
                onPage={setPage}
            />
        </Box>
    )
}
