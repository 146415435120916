import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Select,
    Text
} from '@chakra-ui/react'
import { ChangeEvent, useEffect, useState } from 'react'
import { render, useRender } from 'redity'
import CardIcon from '../../../components/Icon/CardIcon'
import CashIcon from '../../../components/Icon/CashIcon'
import Transfer from '../../../components/Icon/Transfer'
import ModalKeyboard from '../../../components/ModalKeyboard'
import { Keys } from '../constants'
import usePaymentMethods from '../hooks/usePaymentMethods'
import useSelectedItems from '../hooks/useSelectedItems'
import { storagePage } from '../storage'
import CreditPayments from './CreditPayments'
import TablePayments from './TablePayments'
import TypeTax from './TypeTax'
import dayjs from 'dayjs'

export default function PaymentSection() {
    useRender(Keys.PAYMENTS_SECTION)
    const { cashHandler, cardHandler, transferHandler } = usePaymentMethods()
    const { total } = useSelectedItems()

    const [paymentCondition, setPaymentCondition] = useState('1')
    const { paymentTypes, type } = storagePage

    function maxAmount() {
        const cash = storagePage.cash.reduce(
            (acc, curr) => acc + curr.amount,
            0
        )
        const card = storagePage.card.reduce(
            (acc, curr) => acc + curr.amount,
            0
        )
        const transfer = storagePage.transfer.reduce(
            (acc, curr) => acc + curr.amount,
            0
        )
        const accumulatedPayment = cash + card + transfer
        const lack = total - accumulatedPayment
        if (lack < 0) return 0
        else return Number(lack.toFixed(2))
    }

    const paymentIcons: any = {
        CashIcon: <CashIcon fill="gray" />,
        CardIcon: <CardIcon fill="gray" />,
        Transfer: <Transfer fill="gray" />
    }

    const attributes: any = {
        Efectivo: {
            title: 'Monto en efectivo',
            maxValue: Infinity,
            done: cashHandler
        },
        Tarjeta: {
            title: 'Monto por tarjeta',
            maxValue: maxAmount(),
            done: cardHandler
        },
        Transferencia: {
            title: 'Monto por transferencia',
            maxValue: maxAmount(),
            done: transferHandler
        }
    }

    useEffect(() => {
        setPaymentCondition('1')
    }, [type == 'ticket'])

    function changeConditionHandler(ev: ChangeEvent<HTMLSelectElement>) {
        setPaymentCondition(ev.target.value)

        if (ev.target.value == '1') {
            storagePage.creditFees = []
            storagePage.condition = 'cash'
        }
        if (ev.target.value == '2') {
            storagePage.cash = []
            storagePage.card = []
            storagePage.transfer = []
            storagePage.condition = 'credit'
            storagePage.creditFees = [
                {
                    due_date: dayjs().add(30, 'day').format('YYYY-MM-DD'),
                    amount: total.toFixed(2)
                }
            ]
        }
        //render(Keys.PAYMENTS_SECTION)
        //render(Keys.TABLE_PAYMENTS)
        render(Keys.MAIN)
    }

    return (
        <>
            <Box
                mt={{
                    xl: 4,
                    md: 4,
                    base: 3
                }}
            >
                <Box gridGap={6}>
                    <Flex gridGap={4}>
                        <TypeTax />
                        <FormControl>
                            <FormLabel>Cond. de Pago</FormLabel>
                            <Select
                                defaultValue={'1'}
                                onChange={changeConditionHandler}
                                disabled={type === 'ticket' ? true : false}
                            >
                                <option value={'1'}>Contado</option>
                                <option value={'2'}>Crédito</option>
                            </Select>
                        </FormControl>
                    </Flex>
                    {paymentCondition == '1' && (
                        <Box>
                            <Text fontSize="md" my={4}>
                                Métodos de Pago
                            </Text>
                            <Flex gridGap={{ base: 2, lg: 3 }}>
                                {paymentTypes.map((paymentType, i) => (
                                    <ModalKeyboard
                                        key={i}
                                        title={
                                            attributes[paymentType.name].title
                                        }
                                        element={click => (
                                            <Button
                                                colorScheme="blackAlpha"
                                                variant="outline"
                                                leftIcon={
                                                    paymentIcons[
                                                        paymentType.class_name
                                                    ]
                                                }
                                                size="sm"
                                                onClick={click}
                                                fontSize={{
                                                    base: 'sm',
                                                    lg: 'md'
                                                }}
                                            >
                                                {paymentType.name}
                                            </Button>
                                        )}
                                        maxValue={
                                            attributes[paymentType.name]
                                                .maxValue
                                        }
                                        onlyAmount
                                        onDone={
                                            attributes[paymentType.name].done
                                        }
                                        subPaymentTypes={
                                            paymentType.sub_payment_types
                                        }
                                    />
                                ))}
                            </Flex>
                        </Box>
                    )}
                </Box>
                {paymentCondition == '1' && <TablePayments />}
                {paymentCondition == '2' && <CreditPayments />}
            </Box>
        </>
    )
}
