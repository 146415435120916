export const INVENTORY_TYPES = Object.freeze({
    SIMPLE: 'Inventario simple',
    COMPUESTO: 'Inventario compuesto',
    NO_INVENTORY: 'Sin inventario'
})

export const inventory_types = [
    {
        label: 'Sin inventario',
        value: INVENTORY_TYPES.NO_INVENTORY
    },
    {
        label: 'Simple',
        value: INVENTORY_TYPES.SIMPLE
    },
    {
        label: 'Compuesto',
        value: INVENTORY_TYPES.COMPUESTO
    }
]
