import dayjs from 'dayjs'
import { FormEvent, useState } from 'react'
import writeXlsxFile, { Row, SheetData } from 'write-excel-file'
import { get } from '../../../lib/http'
import extractDataForm from '../../../utilities/extractDataForm'

import {
    //DataByExcelCourtesy,
    //DataByExcelProduct,
    DataForm,
    ReportSalesByProducts,
    ReportSalesByCategories,
    ReportSalesCourtesies
    // FindAccountOrder,
    //FindOrderPayment
} from './types'

export default function usePrintReports() {
    const [isLoading, setIsLoading] = useState(false)
    const titles = [
        'Reporte de Ventas por Producto',
        'Reporte de Ventas por Categoría',
        'Reporte de Ventas con Cortesías'
    ]
    const heads = [
        ['Producto', 'Categoría', 'Cantidad', 'Venta (S/.)'],
        ['Categoría', 'Cantidad', 'Venta (S/.)'],
        [
            'Fecha',
            'Producto',
            'Categoría',
            'Motivo',
            'Cantidad',
            'Precio',
            'Venta (S/.)'
        ]
    ]

    const widths = [
        [40, 40, 12, 12],
        [40, 12, 12],
        [20, 40, 24, 50, 12, 12, 12]
    ]

    const head: (...values: string[]) => Row[number][] = (...values) =>
        values.map<Row[number]>(val => ({
            value: val,
            fontWeight: 'bold',
            wrap: true,
            alignVertical: 'center',
            align: 'left',
            fontSize: 11
        }))

    function setDataByProducts(data: ReportSalesByProducts[], rows: SheetData) {
        data.forEach(item => {
            rows.push([
                {
                    value: item.product,
                    type: String
                },
                {
                    value: item.category,
                    type: String
                },
                {
                    value: item.quantity,
                    type: Number
                },
                {
                    value: item.total,
                    type: Number,
                    align: 'right',
                    format: '#,##0.00'
                }
            ])
        })
    }

    function setDateByCategories(
        data: ReportSalesByCategories[],
        rows: SheetData
    ) {
        data.forEach(item => {
            rows.push([
                {
                    value: item.category,
                    type: String
                },
                {
                    value: item.quantity,
                    type: Number
                },
                {
                    value: item.total,
                    type: Number,
                    align: 'right',
                    format: '#,##0.00'
                }
            ])
        })
    }

    function setDataByCourtesies(
        data: ReportSalesCourtesies[],
        rows: SheetData
    ) {
        data.forEach(item => {
            rows.push([
                {
                    value: item.date,
                    type: String
                },
                {
                    value: item.product,
                    type: String
                },
                {
                    value: item.category,
                    type: String
                },
                {
                    value: item.reason,
                    type: String
                },
                {
                    value: item.quantity,
                    type: Number
                },
                {
                    value: item.price,
                    type: Number,
                    align: 'right',
                    format: '#,##0.00'
                },
                {
                    value: item.total,
                    type: Number,
                    align: 'right',
                    format: '#,##0.00'
                }
            ])
        })
    }

    async function submit(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const form = extractDataForm<DataForm>(ev.target)
        const index = Number(form.report) - 1
        const startDate = dayjs
            .tz(form.startDate + ' 00:00:00', 'America/Lima')
            .toISOString()
        const endDate = dayjs
            .tz(form.endDate + ' 23:59:59', 'America/Lima')
            .toISOString()

        setIsLoading(true)

        const rows: SheetData = [
            [],
            [
                {
                    value: titles[index],
                    fontWeight: 'bold',
                    span: 4,
                    alignVertical: 'center',
                    align: 'center',
                    fontSize: 16
                }
            ],
            [
                {
                    value: `De: ${dayjs(startDate).format(
                        'DD/MM/YYYY'
                    )} - Hasta ${dayjs(endDate).format('DD/MM/YYYY')}`,
                    span: 4,
                    align: 'center'
                }
            ],
            [],
            head(...heads[index])
        ]

        if (index === 0) {
            const { data } = await get<ReportSalesByProducts[]>(
                `/api/reports/sales_by_products?start_date=${startDate}&end_date=${endDate}`
            )
            setDataByProducts(data.result, rows)
        } else if (index === 1) {
            const { data } = await get<ReportSalesByCategories[]>(
                `/api/reports/sales_by_categories?start_date=${startDate}&end_date=${endDate}`
            )
            setDateByCategories(data.result, rows)
        } else if (index === 2) {
            const { data } = await get<ReportSalesCourtesies[]>(
                `/api/reports/sales_courtesies?start_date=${startDate}&end_date=${endDate}`
            )
            setDataByCourtesies(data.result, rows)
        }

        await writeXlsxFile(rows, {
            fileName: `${titles[index]
                .toLowerCase()
                .replaceAll(' ', '_')}_${dayjs(startDate).format(
                'DD/MM/YYYY'
            )}_${dayjs(endDate).format('DD/MM/YYYY')}.xlsx`,
            columns: widths[index].map(width => ({ width })),
            fontSize: 10,
            fontFamily: 'Calibri'
        })

        setIsLoading(false)
    }

    return {
        submit,
        isLoading
    }
}
