import { Box } from '@chakra-ui/react'
import { ReactNode } from 'react'

export default function FullScreenCenter({
    children
}: {
    children: ReactNode
}) {
    return (
        <Box
            h="100vh"
            w="full"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            {children}
        </Box>
    )
}
