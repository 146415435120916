import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import useSession from '../../../hooks/useSession'
import { put } from '../../../lib/http'
import { DeliveryStatus } from '../../../types/deliveryMode'
import getPrinterConfig from '../../../utilities/getPrinterConfig'

export default function useUpdateStatus(
    deliveryId: number,
    onDone: () => void
) {
    const { session } = useSession()
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()

    async function update(status: DeliveryStatus, profileId?: number) {
        const ipConfig = getPrinterConfig()
        if (!ipConfig) {
            toast({
                title: 'Configure primero la impresosa',
                status: 'warning',
                position: 'top'
            })
            return
        }

        setIsLoading(true)
        const { error } = await put(`/api/deliveries/${deliveryId}/status`, {
            status,
            amount: 0,
            profileId,
            userId: session?.user.id as number,
            printerId: Number(ipConfig.printer_id)
        })
        setIsLoading(false)

        if (error) {
            toast({
                title: 'No fue posible actualizar el estado',
                status: 'error',
                position: 'top'
            })
            return
        }
        onDone()
    }

    return {
        update,
        isLoading
    }
}
