import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { post } from '../../lib/http'
import val from '../../lib/val'

interface Form {
    password: string
    new_password: string
    repeat_password: string
}

export default function useModalChangePassword({
    setData,
    handleClose
}: {
    setData: (data: any) => void
    handleClose: () => void
}) {
    const [errorField, setErrorField] = useState<Partial<Form>>({})
    const [form, setForm] = useState<Form>({
        password: '',
        new_password: '',
        repeat_password: ''
    })
    const [isLoading, setLoading] = useState(false)

    const toast = useToast()

    const handleSubmit = async (ev: any) => {
        ev.preventDefault()

        const v = val(form)

        v.password.isEmpty('Ingrese la contraseña')
        v.new_password.minLength(6, 'La contraseña mínima es de 6 carácteres')
        v.new_password.isEmpty('Ingrese la nueva contraseña')
        v.repeat_password.isEmpty('Repita la nueva contraseña')
        v.repeat_password.condition(value => {
            return value !== form.new_password
                ? 'No coincide con la nueva contraseña'
                : ''
        })

        const result = v.runtest()
        if (result) {
            setErrorField(result)
        } else {
            const postData = {
                password: form.password,
                new_password: form.new_password
            }

            setLoading(true)
            const { error } = await post<any>('/api/validate_password', {
                password: form.password
            })
            setLoading(false)
            if (error) {
                if (
                    error.errors === undefined ||
                    Object.values(error.errors).length === 0
                ) {
                    toast({
                        status: 'error',
                        title: error.message,
                        position: 'top'
                    })
                } else {
                    setErrorField(error.errors)
                }
                return
            }
            setData(postData)
            setForm({
                password: '',
                new_password: '',
                repeat_password: ''
            })
            handleClose()
        }
    }

    const handleChange = (ev: any) => {
        const name = ev.target.name
        setErrorField({
            ...errorField,
            [name]: ''
        })
        setForm({
            ...form,
            [name]: ev.target.value
        })
    }
    return {
        handleChange,
        handleSubmit,
        errorField,
        form,
        isLoading
    }
}
