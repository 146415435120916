import ControlList from '../../lib/list/ControlList'
import { OrderPaymentData } from './types'

export const controlList = new ControlList<OrderPaymentData>({
    path: '/api/v2/order_payments',
    theads: [
        'N°',
        'Fecha',
        'Caja',
        'Comprobante',
        'Cliente',
        'Monto',
        'Cond. de pago',
        'Estado',
        'Acciones'
    ]
})
