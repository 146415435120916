import { Badge, Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import ArrowDown from '../../../components/Icon/ArrowDown'
import ArrowRight from '../../../components/Icon/ArrowRight'
import ArrowUp from '../../../components/Icon/ArrowUp'
import useDelivery from '../../../hooks/useDelivery'
import { DetailsContainerProps } from './types'

export default function DetailsContainer({
    children,
    isMobile
}: DetailsContainerProps) {
    const { hasOrder, products, total } = useDelivery()
    const { isOpen, onToggle } = useDisclosure()
    const { push } = useHistory()

    return (
        <>
            {isMobile && (
                <Box
                    position="fixed"
                    w="full"
                    top={isOpen ? 0 : 'calc(100vh - 72px)'}
                    left={0}
                    zIndex={10}
                    transition=".3s ease"
                    h="100vh"
                    bg="white"
                >
                    <Box p={4} bg="dark-2">
                        {!hasOrder && (
                            <Button
                                w="full"
                                colorScheme="red"
                                fill="white"
                                fontWeight="normal"
                                rightIcon={<ArrowRight />}
                                rounded="full"
                                onClick={() => push('/delivery/order')}
                            >
                                Agregar orden de pedido
                            </Button>
                        )}
                        {hasOrder && (
                            <Flex
                                w="full"
                                justify="space-between"
                                alignItems="center"
                            >
                                <Box color="white">
                                    <Text color="whiteAlpha.700" fontSize="xs">
                                        Total
                                    </Text>
                                    <Text fontSize="md" fontWeight="bold">
                                        S/ {total.toFixed(2)}{' '}
                                        <Badge
                                            colorScheme="orange"
                                            rounded="full"
                                            variant="solid"
                                            textTransform="lowercase"
                                            fontWeight="normal"
                                            px={2}
                                        >
                                            {products.length} productos
                                        </Badge>
                                    </Text>
                                </Box>
                                <Button
                                    colorScheme="red"
                                    fill="white"
                                    fontWeight="normal"
                                    rightIcon={
                                        isOpen ? <ArrowDown /> : <ArrowUp />
                                    }
                                    onClick={onToggle}
                                >
                                    {isOpen ? 'Ocultar' : 'Ver resumen'}
                                </Button>
                            </Flex>
                        )}
                    </Box>
                    <Box p={4}>{children}</Box>
                </Box>
            )}
            {!isMobile && (
                <Box
                    minW={{
                        base: 'full',
                        md: '96'
                    }}
                    bg="white"
                    p={4}
                    rounded="lg"
                    borderWidth={1}
                >
                    {children}
                </Box>
            )}
        </>
    )
}
