import { useToast } from '@chakra-ui/react'
import { ChangeEvent, FormEvent, useState } from 'react'
import { post } from '../../../lib/http'
import val from '../../../lib/val'
import extractDataForm from '../../../utilities/extractDataForm'
import { SubmitDataStock } from './types'

export default function useSubmitStock(done: () => void) {
    const [fieldMessages, setFieldMessages] = useState<
        Partial<Record<keyof SubmitDataStock, string>>
    >({})
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()

    async function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const form = extractDataForm<SubmitDataStock>(ev.target)
        const v = val(form)
        if (form.operation_quantity !== undefined) {
            // cuando está desabilitado el input, no aparece en el evento del formulario
            v.operation_quantity.isEmpty('La cantidad es requerida')
        }
        v.comment.isEmpty('El comentario es requerido')
        v.type_inventory_operation_id.isEmpty('Seleccionar el movimiento')
        v.warehouse_id.isEmpty('Seleccionar el almacén')
        const result = v.runtest()
        if (result) {
            setFieldMessages(result)
            return
        }
        setIsLoading(true)
        const { error } = await post('/api/inventory_operations', {
            operation_quantity: +form.operation_quantity,
            comment: form.comment,
            type_inventory_operation_id: +form.type_inventory_operation_id,
            inventory_item_id: +form.inventory_item_id,
            warehouse_id: +form.warehouse_id
        })
        if (error) {
            toast({
                title: error.message,
                position: 'top',
                status: 'error'
            })
        } else {
            toast({
                title: 'Se ha actualizado el stock',
                position: 'top',
                status: 'success'
            })
            done()
        }
        setIsLoading(false)
    }

    function onChange(
        ev: ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) {
        const name = ev.target.name as keyof SubmitDataStock
        if (fieldMessages[name]) {
            setFieldMessages({
                ...fieldMessages,
                [name]: ''
            })
        }
    }

    return {
        submitHandler,
        isLoading,
        fieldMessages,
        setFieldMessages,
        onChange
    }
}
