import { Icon } from '@chakra-ui/react'
import Pencil from '../../../components/Icon/Pencil'
import Colors from '../../../config/colors'
import { ItemForBilling } from '../../../types/runway_state'
import ContenModalProduct from '../ContentModalProduct'

export default function Edition({ item }: { item: ItemForBilling }) {
    return (
        <ContenModalProduct
            element={click => (
                <Icon
                    fill={Colors.INFO}
                    as={Pencil}
                    marginRight={2}
                    cursor="pointer"
                    onClick={click}
                />
            )}
            itemToEdit={item}
        />
    )
}
