import { FormControl, FormLabel, Input, InputProps } from '@chakra-ui/react'
import ErrorHelper from '../../../components/ErrorHelper'

import { formCategory } from '../storage'

export default function FormCategory() {
    return (
        <>
            <Name />
        </>
    )
}

function Name() {
    const { props, helper, error } = formCategory.useField<InputProps>('name')
    return (
        <FormControl mb={4}>
            <FormLabel>Nombre</FormLabel>
            <Input
                {...props}
                placeholder="Ingresar el nombre de la categoría"
                autoFocus
                maxLength={100}
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}
