import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react'
import { useState } from 'react'
import Content from './Content'

export default function ModalEmitMultiple({
    element
}: {
    element: (click: () => void) => JSX.Element
}) {
    const [isOpen, setOpen] = useState(false)

    return (
        <>
            {element(() => setOpen(true))}
            <Modal
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                size="2xl"
                isCentered={false}
            >
                <ModalOverlay />
                <ModalContent px={6} py={4}>
                    <ModalHeader>
                        Emitir comprobante de múltiples notas
                    </ModalHeader>
                    <ModalBody pb={6}>
                        <Content />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="alo-gray:lg-md"
                            bg="transparent"
                            color="alo-gray"
                            w="36"
                            onClick={() => setOpen(false)}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="alo-green:lg-md"
                            w="36"
                            ml={3}
                            type="submit"
                            form="form-emit"
                        >
                            Emitir
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
