import { useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import { post } from '../../../lib/http'
import { controlList } from '../storage'
import load from '../utilities/load'

export default function useFilters() {
    const props = controlList.useFilter('issue_date')
    const toast = useToast()
    const [openModal, setOpenModal] = useState(false)
    const [dataModal, setDataModal] = useState({
        message: '',
        receipts: []
    })
    const [isLoading, setLoading] = useState(false)

    const handleSend = async () => {
        setLoading(true)
        const res = await post<any>('/api/sunat_vouchers/send_daily_summary', {
            type_voucher_id: 1,
            generation_date: props.value
                ? dayjs(props.value).format()
                : dayjs().format(),
            summary_date: props.value
                ? dayjs(props.value).format()
                : dayjs().format()
        })
        setLoading(false)

        if (res.data?.result.status === 400) {
            setOpenModal(true)
            setDataModal({
                message: res.data.result.message,
                receipts: res.data.result.response
            })
            return
        }

        if (res.error) {
            toast({
                title: res.error.message,
                status: 'error',
                position: 'top'
            })
        } else {
            toast({
                title: 'Envio de resumen diario realizado',
                status: 'success',
                position: 'top',
                isClosable: true
            })
            load()
        }
    }

    return {
        openModal,
        dataModal,
        setOpenModal,
        handleSend,
        isLoading
    }
}
