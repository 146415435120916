import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    VStack
} from '@chakra-ui/react'
import colors from '../../../../config/theme/colors'
import { ItemProps } from './types'

export default function Item({ title, children }: ItemProps) {
    return (
        <AccordionItem bg="white" borderColor="white" mb={4}>
            <h2>
                <AccordionButton
                    bg={colors.accent}
                    _hover={{
                        bg: colors.accent
                    }}
                >
                    <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                        {title}
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel p={4}>
                <VStack>{children}</VStack>
            </AccordionPanel>
        </AccordionItem>
    )
}
