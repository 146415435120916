import { Box, Container, Divider, Flex, Heading, Text } from '@chakra-ui/react'
import { TYPES_DISCOUNT } from '../constants'
import ClientSection from './ClientSection'
import ResultPay from './ResultPay'
import TypeConsumption from './TypeConsumption'
import { storagePage } from '../storage'
import PaymentMethods from './PaymentMethods'

export default function MainContent() {
    const {
        discount,
        typeDiscount,
        code,
        percentageDiscount,
        totalFinal,
        totalOriginal
    } = storagePage

    return (
        <Container maxW="container.md" color="alo-gray">
            <Flex
                justifyContent="space-between"
                alignItems="center"
                mb={4}
                flexDirection="row"
                gridGap={2}
            >
                <ClientSection />
                <Box>{code}</Box>
            </Flex>
            <Divider />
            <Flex
                mt={4}
                justifyContent="space-between"
                flexDirection={{ base: 'column', md: 'row' }}
            >
                <Flex
                    flexDirection={{ base: 'row', md: 'column' }}
                    justifyContent="space-between"
                >
                    <Text alignSelf="center">Total de pedido</Text>
                    <Heading fontSize={{ base: 'xl', md: '2xl' }}>
                        S/ {totalOriginal.toFixed(2)}
                    </Heading>
                </Flex>
                <Flex
                    flexDirection={{ base: 'row', md: 'column' }}
                    justifyContent="space-between"
                >
                    <Flex alignSelf="center">
                        <Text>
                            Descuento{' '}
                            {typeDiscount === TYPES_DISCOUNT.PERCENTAGE
                                ? `(${percentageDiscount}%)`
                                : `(S/)`}
                        </Text>
                    </Flex>
                    <Flex>
                        <Heading fontSize={{ base: 'xl', md: '2xl' }}>
                            S/ {discount.toFixed(2)}
                        </Heading>
                    </Flex>
                </Flex>
                <Flex
                    flexDirection={{ base: 'row', md: 'column' }}
                    justifyContent="space-between"
                >
                    <Text alignSelf="center">Total a pagar</Text>
                    <Heading fontSize={{ base: '2xl', md: '3xl' }}>
                        S/ {totalFinal.toFixed(2)}
                    </Heading>
                </Flex>
            </Flex>
            <TypeConsumption />
            <PaymentMethods />
            <ResultPay />
        </Container>
    )
}
