import dayjs from 'dayjs'
import { useEffect } from 'react'
import { render } from 'redity'
import { controlList } from './storage'

export default function useInitial() {
    useEffect(() => {
        controlList.setQuery('sort', 'created_at')
        controlList.setQuery(
            'start_date',
            dayjs().subtract(1, 'day').format('YYYY-MM-DD')
        )
        controlList.setQuery('end_date', dayjs().format('YYYY-MM-DD'))
        controlList.setQuery('with_invoices', true)
        controlList.setQuery('with_tickets', true)
        controlList.setQuery('with_sale_notes', true)
        controlList.load().then(() => {
            controlList.render('start_date')
            render('Download')
        })
    }, [])

    return {}
}
