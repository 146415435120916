import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react'
import { useState } from 'react'
import { get } from '../../../../lib/http'
import { HistoryPayment } from '../../types'
import Content from './Content'

export default function ModalPaymentHistory({
    element,
    total,
    pending,
    creditOrderId
}: {
    element: (click: () => void) => JSX.Element
    total: number
    pending: number
    creditOrderId: number
}) {
    const [isOpen, setOpen] = useState(false)

    const [payments, setPayments] = useState<HistoryPayment[] | []>([])

    async function openHandler() {
        setOpen(true)
        const resp = await get<HistoryPayment[]>(
            `/api/outstandings/${creditOrderId}/historial`
        )
        if (resp.data) {
            setPayments(resp.data.result)
        }
    }
    return (
        <>
            {element(openHandler)}
            <Modal
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                size="2xl"
                isCentered={false}
            >
                <ModalOverlay />
                <ModalContent px={6} py={4}>
                    <ModalCloseButton />
                    <ModalHeader>Historial de pagos</ModalHeader>
                    <ModalBody pb={6}>
                        <Content
                            total={total}
                            pending={pending}
                            payments={payments}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="alo-gray:lg-md"
                            bg="transparent"
                            color="alo-gray"
                            w="36"
                            onClick={() => setOpen(false)}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
