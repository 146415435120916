import { SVGProps } from 'react'

const AssignmentTurned = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.525 3H20.75C22.125 3 23.25 4.125 23.25 5.5V23C23.25 24.375 22.125 25.5 20.75 25.5H3.25C3.075 25.5 2.9125 25.4875 2.75 25.4625C2.2625 25.3625 1.825 25.1125 1.4875 24.775C1.2625 24.5375 1.075 24.275 0.95 23.975C0.825 23.675 0.75 23.3375 0.75 23V5.5C0.75 5.15 0.825 4.825 0.95 4.5375C1.075 4.2375 1.2625 3.9625 1.4875 3.7375C1.825 3.4 2.2625 3.15 2.75 3.05C2.9125 3.0125 3.075 3 3.25 3H8.475C9 1.55 10.375 0.5 12 0.5C13.625 0.5 15 1.55 15.525 3ZM17.7375 8.725L19.5 10.5L9.5 20.5L4.5 15.5L6.2625 13.7375L9.5 16.9625L17.7375 8.725ZM12 2.6875C12.5125 2.6875 12.9375 3.1125 12.9375 3.625C12.9375 4.1375 12.5125 4.5625 12 4.5625C11.4875 4.5625 11.0625 4.1375 11.0625 3.625C11.0625 3.1125 11.4875 2.6875 12 2.6875ZM3.25 23H20.75V5.5H3.25V23Z"
            fill="#FF9933"
        />
    </svg>
)

export default AssignmentTurned
