import { Button, ButtonGroup, useId } from '@chakra-ui/react'
import Printer from '../../../../../components/Icon/Printer'
import CashIcon from '../../../../../components/Icon/CashIcon'
import Block from '../../../../../components/Icon/Block'
import ModalAnnul from '../../../modals/ModalAnnul'
import { useState } from 'react'
import usePrecount from '../../../hooks/usePrecount'
import useCashbox from '../../../hooks/useCashbox'
import { useHistory } from 'react-router-dom'
import useOrderByTable from '../../../hooks/useOrderByTable'
import useRunway from '../../../../../hooks/useRunway'
import { SelectedItem } from '../../../../../types/runway_state'
import useSession from '../../../../../hooks/useSession'
import useMenu from '../../../../../hooks/useMenu'
import useStorage from '../../../../../hooks/useStorage'
import ModalPin from '../../../../../components/ModalPin'

export default function ButtonsOptions() {
    const id = useId()
    const { push } = useHistory()
    const { orderId } = useOrderByTable()
    const { cashboxUser } = useCashbox()
    const { menu } = useMenu()
    const { print, isLoading } = usePrecount()
    const { saveSelectedItems } = useRunway()
    const [isOpenAnnul, setIsOpenAnnul] = useState(false)
    const isDisabled = menu.filter(item => item.quantity > 0).length === 0
    const { isLogged, isWaiter, isDealer, session } = useSession()

    const { business_variables } = useStorage()

    const enable_admin_pin = business_variables.find(
        v => v.code === '013'
    )?.value

    const need_admin_pin =
        enable_admin_pin == '1' && session?.user.is_vip != true

    function payHandler() {
        if (isDisabled) return

        const itemsForToPay: SelectedItem[] = []
        menu.forEach(item => {
            if (!item.quantity) return

            const itemForToPay = itemsForToPay.find(
                _by => _by.category_item_id === item.categoryItemId
            )

            const itemData: SelectedItem['items'][number] = {
                id: item.itemId,
                order_item_id: item.orderItemId,
                name: item.name,
                quantity: item.quantity,
                quantity_recorded: item.quantity,
                price: item.price,
                user_price_edition: item.userPriceEdition as number,
                description: item.description,
                feature_settings: [],
                _id_generated: id
            }

            if (itemForToPay) {
                itemForToPay.items.push(itemData)
                return
            }

            itemsForToPay.push({
                category_item_id: item.categoryItemId,
                category_item_name: item.categoryName,
                items: [itemData]
            })
        })

        saveSelectedItems(itemsForToPay)
        push(`/orders/${orderId}/confirm`)
    }

    return (
        <>
            <ModalAnnul
                isOpen={isOpenAnnul}
                onClose={() => setIsOpenAnnul(false)}
                annulOrder
                onDone={() => setIsOpenAnnul(false)}
            />
            <ButtonGroup spacing="2" w="full">
                <Button
                    colorScheme="blue"
                    size="lg"
                    fontSize="sm"
                    fontWeight="medium"
                    isLoading={isLoading}
                    leftIcon={<Printer fill="white" fontSize={20} />}
                    onClick={print}
                    flexGrow={1}
                >
                    Precuenta
                </Button>
                {isLogged && !isWaiter() && !isDealer() && (
                    <Button
                        colorScheme="green"
                        size="lg"
                        fontSize="sm"
                        isDisabled={!cashboxUser || isDisabled}
                        leftIcon={<CashIcon fill="white" fontSize={20} />}
                        onClick={payHandler}
                        flexGrow={1}
                    >
                        Pagar
                    </Button>
                )}
                {isLogged && !isWaiter() && !isDealer() && (
                    <ModalPin
                        title="Pin de autorización"
                        element={clickPin => (
                            <Button
                                colorScheme="red"
                                size="lg"
                                fontSize="sm"
                                leftIcon={<Block fill="white" fontSize={20} />}
                                onClick={() =>
                                    need_admin_pin
                                        ? clickPin()
                                        : setIsOpenAnnul(true)
                                }
                                flexGrow={1}
                            >
                                Anular
                            </Button>
                        )}
                        onDone={() => setIsOpenAnnul(true)}
                        adminPin
                    />
                )}
            </ButtonGroup>
        </>
    )
}
