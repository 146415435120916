import { SVGProps } from 'react'

const Alert = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 22 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M11.866 0.999956L21.392 17.5C21.4798 17.652 21.526 17.8244 21.526 18C21.526 18.1755 21.4798 18.3479 21.392 18.4999C21.3042 18.652 21.178 18.7782 21.026 18.866C20.874 18.9537 20.7015 19 20.526 19H1.474C1.29847 19 1.12602 18.9537 0.974009 18.866C0.821994 18.7782 0.695759 18.652 0.607994 18.4999C0.520229 18.3479 0.474025 18.1755 0.474026 18C0.474027 17.8244 0.520233 17.652 0.608 17.5L10.134 0.999956C10.2218 0.847949 10.348 0.721722 10.5 0.633962C10.652 0.546202 10.8245 0.5 11 0.5C11.1755 0.5 11.348 0.546202 11.5 0.633962C11.652 0.721722 11.7782 0.847949 11.866 0.999956ZM10 14V16H12V14H10ZM10 6.99996V12H12V6.99996H10Z" />
    </svg>
)
export default Alert
