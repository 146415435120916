import { Box, Heading, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import colors from '../../../config/theme/colors'
import { FeatureSetting } from '../../../types/runway_state'

export default function Item({
    quantity,
    description,
    cost,
    featureSettings
}: PropsItem) {
    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                mb="2"
                alignItems="start"
            >
                <Box display="flex" mr={8}>
                    <Text w="7">{quantity}</Text>
                    <Text fontSize="sm">{description}</Text>
                </Box>
                <Text fontSize="sm" w="24" textAlign="right">
                    {cost}
                </Text>
            </Box>
            {featureSettings.map((featureSetting, i) => (
                <Box pl={7} key={i}>
                    <Heading fontSize="sm">{featureSetting.name}</Heading>
                    <UnorderedList
                        styleType="none"
                        color={colors['gray-3']}
                        spacing={1}
                        ml={0}
                        fontSize="sm"
                        mb={1}
                    >
                        {featureSetting.feature_setting_sub_features.map(
                            (fssf, _i) => (
                                <ListItem
                                    display="flex"
                                    justifyContent="space-between"
                                    key={_i}
                                >
                                    <Box>
                                        <Box
                                            mr={1}
                                            minW="2"
                                            as="span"
                                            display="inline-block"
                                        >
                                            {fssf.quantity}
                                        </Box>
                                        {fssf.name}
                                    </Box>
                                    <Box w="20" textAlign="right">
                                        S/{' '}
                                        {(fssf.price * fssf.quantity).toFixed(
                                            2
                                        )}
                                    </Box>
                                </ListItem>
                            )
                        )}
                    </UnorderedList>
                </Box>
            ))}
        </>
    )
}

interface PropsItem {
    quantity: number
    description: string
    cost: string
    featureSettings: FeatureSetting[]
}
