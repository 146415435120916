import { Button, Flex, Heading, Icon, Text } from '@chakra-ui/react'
import CircleCheck from '../../../components/Icon/CircleCheck'
import { close } from '../../../lib/modal'

export default function ModalClosedCashbox() {
    return (
        <>
            <Flex justifyContent="center" mb={6}>
                <Icon as={CircleCheck} fill="success" fontSize="5xl" />
            </Flex>
            <Heading fontSize="2xl" mb={4} textAlign="center">
                Cierre de caja realizado
            </Heading>
            <Text mb={10} textAlign="center" fontSize="xl">
                Se finalizó día de trabajo
            </Text>
            <Flex justifyContent="center">
                <Button
                    variant="alo-gray:lg-md"
                    bg="transparent"
                    color="alo-gray"
                    w="40"
                    onClick={close}
                >
                    De acuerdo
                </Button>
            </Flex>
        </>
    )
}
