import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import Tabs, { Tab } from '../../components/Tabs'
import SectionKardex from './components/SectionKadex'

export default function Inventories() {
    return (
        <Sidebar>
            <SimpleNav title="Inventario" />
            <Tabs value="1" onTab={() => null}>
                <Tab value="1">Kardex</Tab>
            </Tabs>
            <SectionKardex />
        </Sidebar>
    )
}
