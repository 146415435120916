export default function CashIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 30 22"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M16.582 2.00015C11.0215 3.07241 6.41602 3.98062 6.3457 4.01577C6.15234 4.12124 6.03516 4.32046 6.03516 4.55483C6.03516 4.81265 6.16992 5.01187 6.41602 5.11733C6.62109 5.19937 6.30469 5.2521 11.9531 4.17397C14.6719 3.65249 18.5508 2.90835 22.1484 2.21694C24.4512 1.77163 26.3438 1.41421 26.3496 1.41421C26.3555 1.42007 26.8594 4.05679 27.4805 7.27944C28.0957 10.5021 28.6113 13.1857 28.6289 13.2384C28.6523 13.3146 28.6055 13.3439 28.4355 13.3791C28.084 13.4435 27.8906 13.5666 27.7969 13.7951C27.6797 14.0646 27.7383 14.299 27.9609 14.4865C28.1719 14.6681 28.3711 14.674 29.1152 14.5158C29.7188 14.3927 29.918 14.2638 29.9707 13.965C30.0059 13.7775 27.5391 0.75796 27.4102 0.429835C27.3281 0.230617 27.041 0.0431166 26.8359 0.0489759C26.7539 0.0548353 22.1426 0.933742 16.582 2.00015Z" />
            <path d="M24.8145 3.70996C24.75 3.72168 20.168 4.20801 14.6309 4.78809C7.92188 5.49121 4.50586 5.87207 4.40039 5.9248C4.21875 6.02441 4.10156 6.24121 4.10156 6.49316C4.10156 6.74512 4.42969 7.07324 4.67578 7.07324C4.83398 7.07324 7.26562 6.82129 21.2988 5.34473C23.2617 5.13965 24.8789 4.97559 24.8906 4.9873C24.8965 4.99902 25.1836 7.6416 25.5176 10.8584C25.8574 14.0811 26.1387 16.7822 26.1504 16.8701C26.168 17.0225 26.1562 17.0342 25.9043 17.0635C25.3184 17.1396 25.2305 17.1631 25.0664 17.3271C24.7734 17.6201 24.8672 18.0947 25.2539 18.2939C25.4062 18.376 25.5117 18.376 26.2148 18.2939C27.1113 18.1943 27.252 18.1475 27.3926 17.8896C27.4922 17.708 27.4746 17.4971 26.7832 10.917C26.3906 7.17871 26.0391 4.07324 26.0039 4.00293C25.8809 3.75098 25.7168 3.6748 25.3125 3.68652C25.1016 3.68652 24.8789 3.69824 24.8145 3.70996Z" />
            <path d="M0.199219 7.50735L0 7.70657V14.562C0 18.8745 0.0234375 21.4761 0.0585938 21.5699C0.0878906 21.6519 0.199219 21.7749 0.304688 21.8394C0.498047 21.9566 0.609375 21.9566 12.3223 21.9566C25.4355 21.9566 24.4102 21.9859 24.5859 21.5757C24.7148 21.2652 24.709 7.91165 24.5801 7.6597C24.3809 7.27884 25.4355 7.30814 12.3164 7.30814H0.398438L0.199219 7.50735ZM23.3789 14.6031V20.6675H12.3047H1.23047V14.6031V8.5386H12.3047H23.3789V14.6031Z" />
            <path d="M4.34766 9.30064C4.13086 9.42954 4.06641 9.56431 4.01367 9.94517C3.9375 10.5311 3.50391 10.9706 2.92383 11.0526C2.56055 11.0995 2.38477 11.1815 2.26172 11.3631C2.17969 11.4803 2.16797 11.8963 2.16797 14.6502V17.8084L2.33789 17.9784C2.43164 18.0721 2.56641 18.1483 2.63086 18.1483C2.97656 18.1483 3.3457 18.2831 3.59766 18.5057C3.86719 18.7401 3.94336 18.9159 4.08398 19.578C4.11328 19.7245 4.18945 19.8534 4.29492 19.9295L4.45898 20.0526H12.2988C20.8594 20.0526 20.3613 20.0702 20.543 19.7186C20.5898 19.6307 20.625 19.4725 20.625 19.3612C20.625 19.0741 20.8887 18.5936 21.1406 18.4178C21.2637 18.3358 21.5215 18.2362 21.709 18.201C21.9023 18.1659 22.125 18.1073 22.1953 18.0721C22.5 17.9256 22.5 17.9198 22.5 14.6327C22.5 11.9784 22.4883 11.5565 22.4062 11.4042C22.3125 11.2108 22.0195 11.0584 21.7559 11.0584C21.2051 11.0526 20.625 10.4374 20.625 9.84556C20.625 9.61704 20.5137 9.41782 20.3203 9.30064C20.127 9.18345 20.0215 9.18345 12.334 9.18345C4.65234 9.18345 4.54102 9.18345 4.34766 9.30064ZM19.4883 10.5721C19.6113 10.9354 19.8691 11.3397 20.1621 11.6151C20.4668 11.9081 21.0059 12.2303 21.1992 12.2303C21.2578 12.2303 21.2695 12.7284 21.2578 14.6092L21.2402 16.9881L21.0059 17.0819C20.3672 17.328 19.7988 17.8846 19.5703 18.4823L19.4648 18.7635L12.334 18.7811L5.20898 18.7928L5.15039 18.6288C4.93359 18.0135 4.45312 17.4803 3.82617 17.1698L3.39844 16.9588V14.6034V12.2538L3.62109 12.1776C4.21289 11.9667 4.76367 11.4569 5.05078 10.8475L5.22656 10.4725H12.3398C19.0664 10.4725 19.459 10.4784 19.4883 10.5721Z" />
            <path d="M11.5073 11.2937C10.2651 11.6159 9.3042 12.571 8.99365 13.7839C8.88232 14.2292 8.88232 15.0378 8.99365 15.4773C9.28662 16.6081 10.1655 17.5339 11.2788 17.8913C13.1362 18.4949 15.1167 17.4109 15.6382 15.5124C16.3296 13.0222 13.9976 10.6374 11.5073 11.2937ZM12.6499 12.4656C13.5522 12.6179 14.2202 13.2214 14.4487 14.1062C14.7007 15.0964 14.2026 16.1452 13.2534 16.6081C12.9546 16.7546 12.855 16.7722 12.3335 16.7722C11.8179 16.7722 11.7065 16.7546 11.4136 16.614C10.98 16.4148 10.5229 15.9519 10.3237 15.5242C10.1948 15.2429 10.1714 15.1081 10.1714 14.6335C10.1655 14.1179 10.1772 14.0476 10.3589 13.6843C10.6577 13.0691 11.2612 12.5945 11.8999 12.4714C12.2573 12.4011 12.2339 12.4011 12.6499 12.4656Z" />
        </svg>
    )
}
