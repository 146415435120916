export default function Reload(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 27 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5 14V9c-5.517 0-10 4.483-10 10 0 1.683.417 3.283 1.167 4.666l-2.434 2.433A13.218 13.218 0 01.167 19c0-7.366 5.966-13.333 13.333-13.333v-5l6.667 6.667-6.667 6.666zm8.833.333l2.434-2.434a13.218 13.218 0 012.066 7.1c0 7.367-5.966 13.334-13.333 13.334v5l-6.667-6.667 6.667-6.667v5c5.517 0 10-4.483 10-10a10.03 10.03 0 00-1.167-4.666z"
            />
        </svg>
    )
}
