import { useState, useEffect } from 'react'
import { Box, Button, Flex, Input, SimpleGrid, Text } from '@chakra-ui/react'
import { ModalContentProps } from '../../../../lib/modal/types'
import { formPreOrder, storagePage } from '../../storage'
import getInformationOfBooking from '../../utilities/getInformationOfBooking'
import { render } from 'redity'
import Keys from '../../keys'
import { useGet } from '../../../../hooks/useHttp'
import Booking from '../../../../models/booking'
import dayjs from 'dayjs'
import colors from '../../../../config/theme/colors'
import orderItemsToSelectedItems from '../../utilities/orderItemsToSelectedItems'
import { get } from '../../../../lib/http'
import PreOrder from '../../../../models/pre_order'

export default function Bookings(props: ModalContentProps) {
    const [isLoading, setIsLoading] = useState(false)
    const [date, setDate] = useState(() => dayjs().format('YYYY-MM-DD'))
    const [bookingSelected, setBookingSelected] = useState<number | null>(null)
    const [textBooking, setTextBooking] = useState<string>('')
    const [bookingType, setBookingType] = useState<number>(0)
    const response = useGet<Booking[]>(`/api/bookings?date=${date}`, [])

    const bookings = response.data.result ?? []

    const { booking_id } = formPreOrder.store()

    useEffect(() => {
        if (booking_id !== 0) setBookingSelected(booking_id)
    }, [])

    useEffect(() => {
        setBookingSelected(null)
        setTextBooking('')
        setBookingType(0)
        response.reload()
    }, [date])

    const handleConfirm = async () => {
        if (!bookingSelected) return
        formPreOrder.setValue('booking_id', bookingSelected)
        formPreOrder.setValue('text_booking', textBooking)
        formPreOrder.setValue('type', bookingType)
        if (bookingType !== 1) {
            setIsLoading(true)
            const { data } = await get<PreOrder>(
                `/api/pre_orders/booking/${bookingSelected}`
            )
            setIsLoading(false)
            if (data.result) {
                formPreOrder.setValue('pre_order_id', data.result.id)
                storagePage.selectedItems = orderItemsToSelectedItems(
                    data.result.pre_order_items as any
                )
                render(Keys.CONTENT_LEFT)
            }
        }
        render(Keys.CONTENT_RIGHT)
        props.onClose()
    }

    function orderByDate(a: Booking, b: Booking) {
        return new Date(a.hour).getTime() - new Date(b.hour).getTime()
    }

    return (
        <>
            <Text fontSize="2xl" fontWeight="bold" textAlign="left" mb="3">
                Reserva
            </Text>
            <Input
                mb={4}
                size="md"
                borderRadius="full"
                w="auto"
                type="date"
                bg="base-4"
                value={date}
                onChange={_ev => setDate(_ev.target.value || date)}
            />
            <SimpleGrid columns={[2, 2, 3]} spacing={3}>
                {bookings.sort(orderByDate).map((booking: any, i) => {
                    const { text, textTotal, hour, agency } =
                        getInformationOfBooking(booking)
                    return (
                        <Box
                            h="150px"
                            borderRadius={'20px'}
                            p={2}
                            textAlign="center"
                            display={'flex'}
                            justifyContent="center"
                            alignItems={'center'}
                            bg={
                                bookingSelected === booking?.id
                                    ? colors['alo-red']
                                    : 'gray.400'
                            }
                            key={i}
                            color={
                                bookingSelected === booking?.id
                                    ? 'white'
                                    : 'whiteAlpha.900'
                            }
                            cursor="pointer"
                            flexDirection={'column'}
                            onClick={() => {
                                setBookingSelected(booking?.id)
                                setTextBooking(textTotal)
                                setBookingType(booking.type)
                            }}
                        >
                            {agency && <Text as="b">{agency}</Text>}
                            <Text>{text}</Text>
                            <Text>{hour}</Text>
                        </Box>
                    )
                })}
            </SimpleGrid>
            <Flex w="full" mt="5" gridGap={2} justifyContent="space-between">
                <Button
                    _hover={{}}
                    h="60px"
                    w="180px"
                    borderWidth="1px"
                    borderColor="#434343"
                    color="#434343"
                    bg="white"
                    onClick={props.onClose}
                >
                    Cancelar
                </Button>
                <Button
                    h="60px"
                    w="180px"
                    color="white"
                    isLoading={isLoading}
                    bg="orange"
                    _hover={{}}
                    onClick={handleConfirm}
                    autoFocus
                >
                    Confirmar
                </Button>
            </Flex>
        </>
    )
}
