import { Icon, Td, Text, Tr, useDisclosure } from '@chakra-ui/react'
import ArrowUp from '../../../../components/Icon/ArrowUp'
import Description from '../../../../components/Icon/Description'
import colors from '../../../../config/theme/colors'
import ModalHistories from '../../modals/ModalHistories'
import controlStock from '../../utils/controlStock'
import { TableRowProps } from './types'

export default function TableRow({ item, startDate, endDate }: TableRowProps) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const { initialQuantity, input, output, finalStock } = controlStock(item)

    return (
        <Tr>
            <Td>{item.name}</Td>
            <Td>{initialQuantity}</Td>
            <Td>
                <Text as="span" fontSize="sm" mr={1}>
                    {input}
                </Text>
                <Icon fill={colors['alo-green']} as={ArrowUp} />
            </Td>
            <Td>
                <Text as="span" fontSize="sm" mr={1}>
                    {output}
                </Text>
                <Icon
                    fill={colors['alo-red']}
                    as={ArrowUp}
                    transform="rotate(180deg)"
                />
            </Td>
            <Td>{finalStock}</Td>
            <Td>
                <ModalHistories
                    isOpen={isOpen}
                    onClose={onClose}
                    initialStartDate={startDate}
                    inititalEndDate={endDate}
                    item={item}
                />
                <Icon
                    onClick={onOpen}
                    fill={colors['alo-blue']}
                    as={Description}
                    marginRight={2}
                    cursor="pointer"
                />
            </Td>
        </Tr>
    )
}
