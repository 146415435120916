import { Box, Image } from '@chakra-ui/react'
import image from '../../assets/login.png'
import Form from './Form'

export default function Login() {
    return (
        <>
            <Box w="100%" display="flex" h="100%" minH="100vh">
                <Box w="50%" display={{ base: 'none', md: 'flex' }} h="100vh">
                    <Image
                        h="100%"
                        w="100%"
                        objectFit="cover"
                        src={image}
                        alt=""
                    />
                </Box>
                <Box
                    w={{ sm: '100%', md: '50%' }}
                    marginX="auto"
                    marginY="10"
                    px={{ sm: 12, lg: 16, xl: 32 }}
                    display="flex"
                    alignItems="center"
                >
                    <Form />
                </Box>
            </Box>
        </>
    )
}
