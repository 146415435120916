import { Box, Flex, Text } from '@chakra-ui/react'
import { PreOrderCategoryWithItems } from '../../../../models/pre_order'
import getNumberWithTwoDecimals from '../../../../utilities/getNumberWithTwoDecimals'

export default function ItemInfo({
    item
}: {
    item: PreOrderCategoryWithItems['items'][number]
}) {
    let restCost = 0
    if (item.feature_settings) {
        restCost = item?.feature_settings?.reduce(
            (c, d) =>
                c +
                d?.feature_setting_sub_features?.reduce(
                    (e, f) => e + f.price * f.quantity,
                    0
                ),
            0
        )
    }
    const total = (item.price + restCost) * item.quantity

    return (
        <Flex
            justifyContent="space-between"
            align="center"
            role="group"
            fontSize="lg"
            py={2}
            mx={-6}
            px={6}
        >
            <Text
                textAlign="left"
                fontSize={{
                    xl: '16px',
                    lg: '14px'
                }}
            >
                <Box mr={1} minW="6" as="span" display="inline-block">
                    {item.quantity}
                </Box>
                {item.name}
            </Text>
            <Text textAlign="right" fontSize={{ xl: '16px', lg: '14px' }}>
                S/
                {getNumberWithTwoDecimals(total)}
            </Text>
        </Flex>
    )
}
