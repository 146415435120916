import { render, useRender } from 'redity'
import { Keys } from '../../constants'
import { storagePage } from '../../storage'
import ComponentTablePayments from '../../../../components/TablePayments'

export default function TablePayments() {
    useRender(Keys.TABLE_PAYMENTS)
    const { cash, card, transfer } = storagePage

    function deleteHandler(who: 'cash' | 'card' | 'transfer', i: number) {
        storagePage[who].splice(i, 1)
        render(Keys.TABLE_PAYMENTS)
        render(Keys.RESULT_PAY)
        render(Keys.DETAILS)
        render(Keys.PAYMENTS_METHODS)
    }

    return (
        <ComponentTablePayments
            card={card}
            cash={cash}
            transfer={transfer}
            deleteHandler={deleteHandler}
        />
    )
}
