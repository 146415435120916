import { Button, Flex, Select } from '@chakra-ui/react'
import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import Tabs, { Tab } from '../../components/Tabs'
import useControl from './useControl'
import useInitial from './useInitial'
import FiltersCategory from './Category/FiltersCategory'
import FiltersItem from './Item/FiltersItem'
import Item from './Item'
import Category from './Category'

export default function ProductionItems() {
    useInitial()
    const { tabProps, openModal, getTextButton } = useControl()

    return (
        <Sidebar>
            <SimpleNav title="Artículos de inventario" />
            <Tabs {...tabProps}>
                <Tab value="production_items">Artículos</Tab>
                <Tab value="prod_item_categories">Categorías</Tab>
            </Tabs>
            <Flex
                justifyContent="space-between"
                display={{ base: 'flex', md: 'none' }}
            >
                <Select
                    w="200px"
                    value={tabProps.value}
                    onChange={e => tabProps.onTab(e.target.value)}
                >
                    <option value="production_items">Artículos</option>

                    <option value="prod_item_categories">Categorías</option>
                </Select>
                <Button variant="alo-blue:md-xl" onClick={openModal}>
                    + Nuevo
                </Button>
            </Flex>
            <Flex
                mt={4}
                mb={4}
                w={{
                    xl: 'auto',
                    lg: tabProps.value === 'item' ? 'full' : 'auto'
                }}
                justifyContent={{
                    md:
                        tabProps.value === 'category'
                            ? 'flex-end'
                            : 'space-between',
                    base: 'flex-end'
                }}
            >
                {tabProps.value === 'production_items' && <FiltersItem />}
                {tabProps.value === 'prod_item_categories' && (
                    <FiltersCategory />
                )}
                <Button
                    variant="alo-blue:md-xl"
                    onClick={openModal}
                    display={{ base: 'none', md: 'inline' }}
                    ml="2"
                >
                    {getTextButton()}
                </Button>
            </Flex>
            {tabProps.value === 'production_items' && <Item />}
            {tabProps.value === 'prod_item_categories' && <Category />}
        </Sidebar>
    )
}
