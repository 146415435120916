export default function LogOut(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 4.5v3l-4 4 4 4v3l-7-7 7-7Zm6 0v4c7 1 10 6 11 11-2.5-3.5-6-5.1-11-5.1v4.1l-7-7 7-7Z"
                fill="#434343"
            />
        </svg>
    )
}
