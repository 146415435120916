import { useEffect } from 'react'
import dayjs from 'dayjs'
import { listPreOrder } from '../storage'

export default function useInitial() {
    useEffect(() => {
        listPreOrder.setQuery('date', dayjs().format('YYYY-MM-DD'))
        listPreOrder.setQuery('pre_order_state', '1')
        listPreOrder.setQuery('sort', 'booking.hour')
        listPreOrder.setQuery('booking_id', '')
        listPreOrder.setQuery('order', 'asc')
        Promise.all([listPreOrder.load()])
    }, [])
}
