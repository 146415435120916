import { SVGProps } from 'react'

const PriceTag = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_2_38484)">
            <path d="M3 6.99999L11.445 1.36999C11.6093 1.26035 11.8025 1.20184 12 1.20184C12.1975 1.20184 12.3907 1.26035 12.555 1.36999L21 6.99999V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V6.99999ZM5 8.06999V20H19V8.06999L12 3.40399L5 8.06999ZM8 16H16V18H8V16ZM8 13H16V15H8V13ZM12 11C11.4696 11 10.9609 10.7893 10.5858 10.4142C10.2107 10.0391 10 9.53043 10 8.99999C10 8.46956 10.2107 7.96085 10.5858 7.58578C10.9609 7.21071 11.4696 6.99999 12 6.99999C12.5304 6.99999 13.0391 7.21071 13.4142 7.58578C13.7893 7.96085 14 8.46956 14 8.99999C14 9.53043 13.7893 10.0391 13.4142 10.4142C13.0391 10.7893 12.5304 11 12 11Z" />
        </g>
        <defs>
            <clipPath id="clip0_2_38484">
                <rect width={24} height={24} fill="white" />
            </clipPath>
        </defs>
    </svg>
)
export default PriceTag
