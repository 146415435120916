import { Icon } from '@chakra-ui/react'
import Pencil from '../../components/Icon/Pencil'
import Colors from '../../config/colors'
import RoleUser from '../../models/role_user'
import User from '../../models/user'
import useForm from './useForm'

export default function Edition({
    user
}: {
    user: User & { roles_users: RoleUser[] }
}) {
    const { openModal } = useForm(user)

    return (
        <Icon
            fill={Colors.INFO}
            as={Pencil}
            marginRight={2}
            cursor="pointer"
            onClick={openModal}
        />
    )
}
