import { Flex, Input } from '@chakra-ui/react'
import { useRender } from 'redity'
import SelectWithLabel from '../../../components/SelectWithLabel'
import { Keys } from '../constant'
import { storagePage } from '../storage'
import useOpeningHistory from './useOpeningHistory'

export default function Filters() {
    useRender(Keys.OPENING_HISTORY_FILTER)
    const {
        date,
        changeHandlerDate,
        changeHandlerSelect,
        opening,
        disabledFilters
    } = useOpeningHistory()

    return (
        <Flex gridGap="2" mb={4} justifyContent="flex-end">
            <Flex justifyContent="flex-end" alignItems="center">
                <Input
                    type="date"
                    placeholder="Seleccionar fecha"
                    bg="base-4"
                    borderRadius="full"
                    onChange={changeHandlerDate}
                    value={date}
                    disabled={disabledFilters}
                />
            </Flex>
            <SelectWithLabel
                label="Apertura:"
                placeholder="Todos"
                onChange={changeHandlerSelect}
                value={opening}
                isDisabled={disabledFilters}
            >
                {storagePage.openingDates.map(o => (
                    <option key={o.id} value={o.id}>
                        {o.opening_date}
                    </option>
                ))}
            </SelectWithLabel>
        </Flex>
    )
}
