import { Radio, RadioGroup } from '@chakra-ui/react'
import { RefObject } from 'react'

export default function GroupRadios({
    refInput,
    onChange,
    mode
}: {
    refInput: RefObject<HTMLInputElement>
    onChange: (mode: string) => void
    mode: string
}) {
    function changeHandler(_mode: string) {
        const elem = refInput.current as HTMLInputElement
        elem.focus()
        elem.value = ''
        onChange(_mode)
    }

    return (
        <RadioGroup name="mode" mb={6} value={mode} onChange={changeHandler}>
            <Radio fontWeight="semibold" fontSize="2xl" value="amount" mr="4">
                Monto (S/)
            </Radio>
            <Radio fontWeight="semibold" fontSize="2xl" value="percentage">
                Porcentaje (%)
            </Radio>
        </RadioGroup>
    )
}
