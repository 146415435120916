import { useHistory, useParams } from 'react-router-dom'
import { useState } from 'react'
import { useRender } from 'redity'
import modal, { close } from '../../../lib/modal'
import { Keys } from '../constants'
import { storagePage } from '../storage'
import { useToast } from '@chakra-ui/react'
import { Payment } from '../types'
import { put } from '../../../lib/http'
import ContentModalLoading from '../ModalContents/ContentModalLoading'

export default function useDetails() {
    const { order_payment_id } = useParams<{ order_payment_id?: string }>()
    useRender(Keys.DETAILS)

    const toast = useToast()
    const { push } = useHistory()

    const [openModal, setOpenModal] = useState(false)
    const [dataModal] = useState<any>({
        message: '',
        receipts: []
    })

    let cash = 0
    for (let i = 0; i < storagePage.cash.length; i++) {
        cash += storagePage.cash[i].amount
    }

    let card = 0
    for (let i = 0; i < storagePage.card.length; i++) {
        card += storagePage.card[i].amount
    }

    let transfer = 0
    for (let i = 0; i < storagePage.transfer.length; i++) {
        transfer += storagePage.transfer[i].amount
    }

    async function confirmPayment() {
        const payments: Payment[] = []
        if (storagePage.cash.length > 0) {
            for (const data of storagePage.cash) {
                payments.push({
                    type_payment_id: 1,
                    ...data
                })
            }
        }

        if (storagePage.card.length > 0) {
            for (const data of storagePage.card) {
                payments.push({
                    type_payment_id: 2,
                    ...data
                })
            }
        }

        if (storagePage.transfer.length > 0) {
            for (const data of storagePage.transfer) {
                payments.push({
                    type_payment_id: 3,
                    ...data
                })
            }
        }

        try {
            modal(ContentModalLoading, {
                cleaned: true,
                closeOnOverlayClick: false,
                size: 'md'
            })
            const accumulatedPayment = cash + card + transfer
            const { error } = await put(
                `/api/v2/order_payments/${order_payment_id}/update_payments`,
                {
                    order_payment_id,
                    payments,
                    accumulated_payment: accumulatedPayment,
                    cashback: accumulatedPayment - storagePage.totalFinal
                }
            )

            if (error) {
                close()
                toast({
                    title: error?.message,
                    position: 'top',
                    status: 'error'
                })
                return
            }

            close()
            toast({
                title: 'Se actualizo los pagos correctamente',
                position: 'top',
                status: 'success'
            })
            push(`/sales`)
        } catch (error) {
            close()
            toast({
                title: 'Lo sentimos, ocurrio un error al actualizar los pagos. Vuelve a intentar',
                position: 'top',
                status: 'error'
            })
            return
        }
    }

    return {
        openModal,
        dataModal,
        setOpenModal,
        alias: storagePage.alias,
        confirmPayment,
        accumulatedPayment: cash + card + transfer,
        typeOrder: storagePage.type
    }
}
