import {
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    TableCaption,
    SimpleGrid,
    Box,
    Text
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { useRender } from 'redity'
import ControlList from './ControlList'
import Pagination from './Pagination'
import { ListProps } from './types'

export default function List<T>({
    control,
    tbody,
    type = 'table'
}: ListProps<T>) {
    useRender(control.KeyMain)
    useEffect(() => {
        return () => {
            control.setQuery('search', '')
            control.setQuery('limit', 10)
        }
    }, [])

    return (
        <>
            {type === 'table' && (
                <Box overflowX="auto">
                    <Table size="md" className="table-a-la-orden">
                        <Header control={control} />
                        <Body tbody={tbody} control={control} />
                    </Table>
                </Box>
            )}
            {type === 'grid' && <ContentGrid tbody={tbody} control={control} />}
            {!control.content.removeLimit && <Pagination control={control} />}
        </>
    )
}

function Header({ control }: { control: ControlList<any> }) {
    useRender(control.KeyMain, control.Indexs.HEADER)
    return (
        <Thead>
            <Tr>
                {control.content.theads.map(thead => (
                    <Th
                        key={thead}
                        textTransform="capitalize"
                        fontSize="14px"
                        textAlign="center"
                    >
                        {thead}
                    </Th>
                ))}
            </Tr>
        </Thead>
    )
}

function Body({ control, tbody }: ListProps<any>) {
    useRender(control.KeyMain, control.Indexs.BODY)
    if (control.data.length === 0)
        return <TableCaption>No existen resultados</TableCaption>
    return (
        <Tbody textAlign="center">
            {control.data.map((d, i) => tbody(d, i + 1))}
        </Tbody>
    )
}

function ContentGrid({ control, tbody }: ListProps<any>) {
    useRender(control.KeyMain, control.Indexs.BODY)
    if (control.data.length === 0)
        return (
            <Text textAlign="center" color="gray-3" mt={12}>
                No existen resultados
            </Text>
        )
    return (
        <SimpleGrid
            columns={{
                '2xl': 5,
                xl: 4,
                lg: 3
            }}
            spacing="5"
        >
            {control.data.map((d, i) => tbody(d, i + 1))}
        </SimpleGrid>
    )
}
