import { useOutsideClick } from '@chakra-ui/react'
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useRunway from '../../../../hooks/useRunway'
import { get } from '../../../../lib/http'
import Client from '../../../../models/client'
import OrderPayment from '../../../../models/order_payment'
import { ItemForBilling } from '../../../../types/runway_state'

export default function useModalEmitMultiple() {
    const ref = useRef<HTMLInputElement>(null)
    const { push } = useHistory()
    const { saveItemsForBilling } = useRunway()
    const [client, setClient] = useState<Client | null>(null)
    const [search, setSearch] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [index, setIndex] = useState(0)
    const [clients, setClients] = useState<Array<Client>>([])

    const [saleNotes, setSaleNotes] = useState<OrderPayment[]>([])

    const [selectedSaleNoteIds, setSelectedSaleNotesIds] = useState<string[]>(
        []
    )
    const [selectedSaleNotes, setSelectedSaleNotes] = useState<OrderPayment[]>(
        []
    )

    // Función para seleccionar notas de venta de un cliente
    const handleOnChange = (e: any, saleNote: OrderPayment) => {
        if (e.target.checked) {
            setSelectedSaleNotesIds([...selectedSaleNoteIds, e.target.value])
            setSelectedSaleNotes([...selectedSaleNotes, saleNote])
        } else {
            selectedSaleNoteIds.splice(
                selectedSaleNoteIds.indexOf(e.target.value),
                1
            )
            setSelectedSaleNotesIds([...selectedSaleNoteIds])
            selectedSaleNotes.splice(selectedSaleNotes.indexOf(saleNote), 1)
            setSelectedSaleNotes([...selectedSaleNotes])
        }
    }

    // Función para obtener todas las notas de venta pagadas a crédito de un cliente
    async function getSaleNotes(clientId: number) {
        const resp = await get<OrderPayment[]>(
            `/api/orders/salenotes/client/${clientId}`
        )
        if (resp.data) {
            setSaleNotes(resp.data.result)
        }
    }

    useOutsideClick({
        ref,
        handler: () => {
            setTimeout(() => setIsOpen(false), 100)
        }
    })

    useEffect(() => {
        if (!client) {
            setSearch('')
        } else {
            getSaleNotes(client.id)
        }
    }, [client])

    async function searchHandler(ev: ChangeEvent<HTMLInputElement>) {
        setSaleNotes([])
        if (client) {
            setClient(null)
            return
        }
        setSearch(ev.target.value)
        const value = ev.target.value.trim().toLowerCase()
        if (!value) {
            setIsOpen(false)
            setClients([])
            setIndex(0)
            return
        }

        setIsOpen(true)
        const { data } = await get<Array<Client>>(
            `/api/clients?search=${value}&limit=10`
        )

        if (data) {
            setClients(data.result)
            setIndex(_index =>
                data.result.length - 1 > _index
                    ? _index
                    : data.result.length - 1
            )
        }
    }

    function keyHandler(ev: KeyboardEvent<HTMLInputElement>) {
        if (ev.code === 'ArrowUp') {
            setIndex(_index => (_index === 0 ? 0 : _index - 1))
        }
        if (ev.code === 'ArrowDown') {
            setIndex(_index =>
                _index === clients.length ? _index : _index + 1
            )
        }
        if (ev.code === 'Enter' || ev.code === 'NumpadEnter') {
            setIsOpen(false)
            setClient(clients.find((_, i) => i === index) || null)
            setIndex(0)
            setClients([])
        }
        if (ev.code === 'Escape') {
            setIsOpen(false)
            setClient(null)
            setIndex(0)
            setClients([])
            setSearch('')
        }
    }

    function clearHandler() {
        setSearch('')
        setClient(null)
        setSaleNotes([])
        if (ref.current) {
            ref.current.focus()
        }
    }

    const total = selectedSaleNotes.reduce(
        (acc, curr) => acc + curr.total_final,
        0
    )

    async function submitHandler() {
        const { data } = await get<ItemForBilling[]>(
            `/api/orders/salenotes/items?sale_notes=${selectedSaleNoteIds}`
        )
        saveItemsForBilling(data.result)
        push('/generateVoucher')
    }

    return {
        client,
        setClient,
        saleNotes,
        searchHandler,
        search,
        isOpen,
        index,
        clients,
        keyHandler,
        clearHandler,
        ref,
        selectedSaleNoteIds,
        handleOnChange,
        total,
        submitHandler
    }
}
