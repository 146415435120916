import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Select,
    Text
} from '@chakra-ui/react'
import { useState } from 'react'
import ErrorHelper from '../../../components/ErrorHelper'
import { storagePage } from '../storage'
import useSplitPayment from '../hooks/useSplitPayment'
import { render } from 'redity'
import Keys from '../keys'

export default function FormSplit({
    element,
    onDone
}: {
    element: (click: () => void) => JSX.Element
    onDone: () => void
}) {
    const {
        setOpen,
        isOpen,
        submitHandler,
        isLoading,
        helpers,
        changeHandler
    } = useSplitPayment(onDone)

    const items = storagePage.items

    function handleCancel() {
        setOpen(false)
        storagePage.items = []
        storagePage.items_to_divide = []
        render(Keys.ORDER_ITEMS)
    }

    return (
        <>
            {element(() => setOpen(true))}
            <Modal
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                size="md"
                isCentered
                onOverlayClick={handleCancel}
            >
                <ModalOverlay />
                <ModalContent px={6} py={4}>
                    <ModalHeader fontSize="xl" fontWeight="bold">
                        Dividir cuenta
                    </ModalHeader>
                    <form noValidate onSubmit={submitHandler}>
                        <ModalBody>
                            <FormControl>
                                <FormControl mb={4}>
                                    <FormLabel>Items</FormLabel>
                                    <Box maxH="500px" overflowY="auto" pl="2">
                                        {items.map(order_item => (
                                            <Flex
                                                key={order_item.item_id}
                                                justifyContent="space-between"
                                                mb={2}
                                            >
                                                <Text alignSelf={'center'}>
                                                    {order_item.item.name}
                                                </Text>
                                                <InputNumber
                                                    orderItem={order_item}
                                                    maxValue={
                                                        order_item.quantity
                                                    }
                                                />
                                            </Flex>
                                        ))}
                                    </Box>
                                </FormControl>
                                <FormControl marginBottom="4" isRequired>
                                    <FormLabel>Subcuenta</FormLabel>
                                    <Select
                                        placeholder="Seleccionar subcuenta"
                                        name="account"
                                        onChange={changeHandler}
                                    >
                                        {storagePage.account_orders.map(
                                            account =>
                                                account.status == 1 && (
                                                    <option
                                                        key={account.id}
                                                        value={account.id}
                                                    >
                                                        {account.name}
                                                    </option>
                                                )
                                        )}
                                    </Select>
                                    <ErrorHelper helper={helpers.account} />
                                </FormControl>
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Flex justifyContent="space-between">
                                <Button
                                    variant="alo-gray:lg-md"
                                    bg="transparent"
                                    color="alo-gray"
                                    onClick={handleCancel}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    variant="alo-green:lg-md"
                                    ml={3}
                                    type="submit"
                                    isLoading={isLoading}
                                >
                                    Confirmar
                                </Button>
                            </Flex>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}

function InputNumber({
    orderItem,
    maxValue
}: {
    orderItem: any
    maxValue: number
}) {
    const [value, setValue] = useState(orderItem.quantity.toString())

    function changeHandler(valueAsString: string) {
        setValue(valueAsString)
        for (let i = 0; i < storagePage.items_to_divide.length; i++) {
            if (storagePage.items_to_divide[i].id == orderItem.id) {
                storagePage.items_to_divide[i].quantity_to_divide =
                    Number(valueAsString)
            }
        }
    }
    return (
        <NumberInput
            value={value}
            maxW="32"
            max={maxValue}
            bg="white"
            min={1}
            name="quantity"
            onChange={changeHandler}
            mx={2}
        >
            <NumberInputField placeholder="Cantidad" />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
    )
}
