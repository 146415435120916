import {
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Spinner,
    Text
} from '@chakra-ui/react'
import { ReactNode, createContext, useCallback, useState } from 'react'

export interface OverlapLoaderContextInterface {
    start: (description: string) => void
    end: () => void
}

const OverlapLoaderContext = createContext<OverlapLoaderContextInterface>({
    start: () => null,
    end: () => null
})

export interface OverlapLoaderProviderProps {
    children: ReactNode
}

/**
 * Muestra un modal con un mensaje de carga
 */
export function OverlapLoaderProvider({
    children
}: OverlapLoaderProviderProps) {
    const [isOpen, setIsOpen] = useState(false)
    const [description, setDescription] = useState('')

    const start = useCallback((_description: string) => {
        setIsOpen(true)
        setDescription(_description)
    }, [])
    const end = useCallback(() => setIsOpen(false), [])

    return (
        <>
            <Modal
                isOpen={isOpen}
                size="md"
                onClose={() => setIsOpen(false)}
                isCentered
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent px={6} py={4}>
                    <ModalBody pb={6}>
                        <Text
                            fontSize="2xl"
                            fontWeight="bold"
                            textAlign="center"
                            mb={10}
                        >
                            {description}
                        </Text>
                        <Flex justifyContent="center">
                            <Spinner size="xl" />
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <OverlapLoaderContext.Provider value={{ start, end }}>
                {children}
            </OverlapLoaderContext.Provider>
        </>
    )
}

export default OverlapLoaderContext
