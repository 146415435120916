import { Box, Button, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useGet } from '../../../../hooks/useHttp'
import { put } from '../../../../lib/http'
import { ModalContentProps } from '../../../../lib/modal/types'
import Zone from '../../../../models/zone'
import { storagePage } from '../../storage'
import SelectZones from './SelectZones'
import Tables from './Tables'
import TabZones from './TabZones'

export default function ChangeTable(props: ModalContentProps) {
    const { data } = useGet<Zone[]>(
        '2>/api/zones/?sort=name&is_enabled=true',
        []
    )

    const toast = useToast()
    const { push } = useHistory()
    const [zoneId, setZoneId] = useState(0)
    const [tableId, setTableId] = useState(0)

    useEffect(() => {
        if (data.result.length > 0) {
            newSetZoneId(data.result[0].id)
        }
    }, [data.result])

    function newSetZoneId(_zoneId: number) {
        setZoneId(_zoneId)
    }

    async function handleConfirm() {
        if (tableId === 0) return
        const { error } = await put(
            `/api/orders/${storagePage.table?.order.id}/table`,
            {
                table_id: tableId
            }
        )
        if (error) {
            toast({
                title: error.message,
                position: 'top',
                status: 'error',
                isClosable: true
            })
        } else {
            push('/by_tables')
            props.onClose()
            toast({
                title: 'Cambio de mesa realizado',
                position: 'top',
                isClosable: true,
                status: 'success'
            })
        }
    }
    return (
        <Box>
            <Text fontSize={'xl'} mb="2" fontWeight={'bold'}>
                Cambio de mesa
            </Text>
            <Box
                d={{ base: 'flex', lg: 'none' }}
                mb="5"
                justifyContent="space-between"
            >
                <Button
                    h="40px"
                    w="100px"
                    border="1px solid #434343"
                    color="#434343"
                    bg="white"
                    _hover={{}}
                    onClick={props.onClose}
                >
                    Cancelar
                </Button>
                <Button
                    h="40px"
                    w="100px"
                    color="white"
                    bg="#44C281"
                    _hover={{}}
                    onClick={handleConfirm}
                >
                    Confirmar
                </Button>
            </Box>
            <Box mr="auto" display={{ base: 'block', md: 'none' }}>
                {zoneId !== 0 && (
                    <SelectZones
                        zones={data.result}
                        currentZone={zoneId}
                        onClick={newSetZoneId}
                    />
                )}
            </Box>
            <TabZones
                zones={data.result}
                currentZone={zoneId}
                onClick={newSetZoneId}
            />
            {zoneId !== 0 && (
                <Box
                    maxH="55vh"
                    overflowX={'hidden'}
                    overflowY={'auto'}
                    px="10"
                    mx="-10"
                >
                    <Tables
                        zoneId={zoneId}
                        tableId={tableId}
                        setTableId={setTableId}
                    />
                </Box>
            )}
            <Box
                w="full"
                mt="5"
                justifyContent="space-between"
                d={{ base: 'none', lg: 'flex' }}
            >
                <Button
                    h="60px"
                    w="180px"
                    border="1px solid #434343"
                    color="#434343"
                    bg="white"
                    _hover={{}}
                    onClick={props.onClose}
                >
                    Cancelar
                </Button>
                <Button
                    h="60px"
                    w="180px"
                    color="white"
                    bg="orange"
                    _hover={{}}
                    onClick={handleConfirm}
                >
                    Confirmar
                </Button>
            </Box>
        </Box>
    )
}
