import {
    Badge,
    Box,
    Divider,
    Flex,
    Icon,
    Spinner,
    Text
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import Printer from '../../../../components/Icon/Printer'
import colors from '../../../../config/theme/colors'
import { CommandData } from '../../types'
import useCommandHistory from './useCommandHistory'

export default function CommandCard({ command }: { command: CommandData }) {
    const { printCommand, loading } = useCommandHistory(command.id)
    return (
        <Flex
            border="1px"
            borderRadius="md"
            w="100%"
            p={{ base: '4', md: '6' }}
            bg="base-3"
            borderColor="gray.400"
            flexDirection={{ base: 'column-reverse', md: 'row' }}
        >
            <Box w={{ md: '50%' }} pl={{ base: '2', md: '0' }} pr={{ md: '6' }}>
                <Flex gridGap="2" mb={1}>
                    <Text minW="12" as={'b'}>
                        Cant.
                    </Text>
                    <Text as={'b'}>Producto</Text>
                </Flex>
                {command.command_items.map((item_command, index) => (
                    <Flex key={index} gridGap="2" mb={1.5}>
                        <Text minW="12" align="center">
                            {item_command.quantity}
                        </Text>
                        <Box>
                            <Text>{item_command.item.name}</Text>
                            {item_command.aditional_information && (
                                <Text fontSize="sm" lineHeight={1}>
                                    {item_command.aditional_information}
                                </Text>
                            )}
                        </Box>
                    </Flex>
                ))}
            </Box>
            <Divider
                orientation="horizontal"
                my="4"
                display={{ base: 'block', md: 'none' }}
            />
            <Flex borderLeft="1px" borderColor="gray.300"></Flex>
            <Flex
                w={{ md: '50%' }}
                gridGap={{ md: 8 }}
                justifyContent={'space-between'}
            >
                <Flex
                    flexDirection="column"
                    alignSelf="center"
                    justifySelf="center"
                    gridGap={{ md: '2' }}
                    pl={{ base: '2', md: 8 }}
                >
                    <Box display={{ base: 'none', md: 'block' }}>
                        <Text as={'b'}>Fecha y hora: </Text>
                        <Text>
                            {dayjs(command.created_at).format(
                                'DD/MM/YYYY hh:mm A'
                            )}
                        </Text>
                    </Box>
                    <Box display={{ base: 'block', md: 'none' }}>
                        <Flex>
                            <Text as={'b'} mr="2">
                                Fecha:
                            </Text>
                            <Text>
                                {dayjs(command.created_at).format('DD/MM/YYYY')}
                            </Text>
                        </Flex>
                        <Flex>
                            <Text as={'b'} mr="2">
                                Hora:
                            </Text>
                            <Text>
                                {dayjs(command.created_at).format('hh:mm A')}
                            </Text>
                        </Flex>
                    </Box>
                    <Flex flexDirection={{ base: 'row', md: 'column' }}>
                        <Text as={'b'} mr="2">
                            Tipo:
                        </Text>
                        {command.type_command == 1 ? (
                            <Badge
                                bg={colors.success}
                                color="white"
                                w="fit-content"
                                h="fit-content"
                                alignSelf={{ base: 'center', md: 'flex-start' }}
                            >
                                Nuevo pedido
                            </Badge>
                        ) : (
                            <Badge
                                bg="error"
                                color="white"
                                w="fit-content"
                                h="fit-content"
                                alignSelf={{ base: 'center', md: 'flex-start' }}
                            >
                                Anulación
                            </Badge>
                        )}
                    </Flex>
                </Flex>
                <Box
                    alignSelf="center"
                    justifySelf="center"
                    p={{ base: '2', md: '4' }}
                    w={{ md: '80px' }}
                >
                    {loading ? (
                        <Flex justifyContent="center">
                            <Spinner />
                        </Flex>
                    ) : (
                        <Icon
                            fontSize={{ base: '4xl', md: '5xl' }}
                            as={Printer}
                            fill={colors['alo-blue']}
                            cursor="pointer"
                            onClick={printCommand}
                        />
                    )}
                </Box>
            </Flex>
        </Flex>
    )
}
