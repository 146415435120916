export default function DashBoardIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 3h8v10H3V3Zm18 0h-8v6h8V3ZM9 11V5H5v6h4Zm10-4V5h-4v2h4Zm0 6v6h-4v-6h4ZM9 19v-2H5v2h4Zm12-8h-8v10h8V11ZM3 15h8v6H3v-6Z"
            />
        </svg>
    )
}
