import { useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import useSubmit from '../../../hooks/useSubmit'
import modal from '../../../lib/modal'
import Booking from '../../../models/booking'
import {
    formBooking,
    getTextType,
    listBooking,
    PATH_ENDPOINT_BOOKING,
    TYPE_BOOKINGS_VALUE
} from '../storage'
import FormBooking from './FormBooking'

export default function useBooking(booking?: Booking) {
    const toast = useToast()
    const { submit, validation, observer } = useSubmit(formBooking, {
        path: booking
            ? `${PATH_ENDPOINT_BOOKING}/${booking.id}`
            : PATH_ENDPOINT_BOOKING,
        method: booking ? 'put' : 'post',
        done: () => listBooking.load(),
        error: e => {
            toast({
                title: e.message,
                position: 'top',
                status: 'error'
            })
        }
    })

    validation((v, f) => {
        if (f.type === TYPE_BOOKINGS_VALUE.AGENCY) {
            v.agency_id.isEmpty('Es requerido una Agencia')
            v.agency_guide_id.isEmpty('Es requerido un Guía Turístico')
        }
        if (f.type === TYPE_BOOKINGS_VALUE.DRIVER) {
            v.driver_id.isEmpty('Es requerido la guía local')
        }
        if (f.type === TYPE_BOOKINGS_VALUE.CLIENT) {
            v.client_name.isEmpty('Es requerido el Nombre del Cliente')
        }
        v.date.isEmpty('Es requerido la Fecha')
        v.hour.isEmpty('Es requerido la Hora')
        v.person_quantity.isEmpty('Es requerido la Cantidad de Personas')
        v.person_quantity.isEqual(0, 'Es requerido una cantidad mayor a cero')
    })

    observer<FormSubmit>(f => ({
        person_quantity: Number(f.person_quantity),
        comment: f.comment || '',
        hour: dayjs(`${dayjs().format('YYYY-MM-DD')} ${f.hour}`).format(),
        date: dayjs(f.date).format(),
        type: Number(f.type),
        ...(f.type === TYPE_BOOKINGS_VALUE.AGENCY && {
            agency_id: Number(f.agency_id),
            agency_guide_id: Number(f.agency_guide_id)
        }),
        ...(f.type === TYPE_BOOKINGS_VALUE.DRIVER && {
            driver_id: Number(f.driver_id)
        }),
        ...(f.type === TYPE_BOOKINGS_VALUE.CLIENT && {
            client_name: f.client_name
        })
    }))

    function openModal(type?: number) {
        if (booking) {
            formBooking.store({
                type: booking.type,
                client_name: booking.client_name ?? '',
                comment: booking.comment ?? '',
                agency_id: booking.agency_id ?? '',
                agency_guide_id: booking.agency_guide_id ?? '',
                driver_id: booking.driver_id ?? '',
                person_quantity: booking.person_quantity,
                hour: dayjs(booking.hour).format('HH:mm'),
                date: dayjs(booking.date).format('YYYY-MM-DD')
            })
            formBooking.init(formBooking.store)
        } else {
            formBooking.init()
            formBooking.store({
                type: type
            })
            formBooking.init(formBooking.store)
        }
        modal(FormBooking, {
            title: booking ? 'Editar Reserva' : 'Nueva Reserva',
            centerTitle: true,
            subTitle: `(${getTextType(booking ? booking.type : type ?? 1)})`,
            onSubmit: submit,
            size: '2xl'
        })
    }

    return openModal
}

interface FormSubmit {
    client_name?: string
    comment?: string
    agency_id?: number
    agency_guide_id?: number
    driver_id?: number
    person_quantity: number
    type: number
    hour: string
    date: string
}
