import useSubmit from '../../../hooks/useSubmit'
import modal from '../../../lib/modal'
import Supply from '../../../models/supply'
import { formEditionStock, listSupply } from '../storage'
import FormEditionStock from './FormEditionStock'

export default function useEditionStock(supply?: Supply) {
    const { validation, observer, submit } = useSubmit(formEditionStock, {
        path: supply ? `/api/inventories/supply` : '/api/inventories/supply',
        method: 'post',
        done: () => listSupply.load()
    })

    validation(v => {
        v.name.isEmpty('Es requerido el nombre')
        v.type_movement_id.isEmpty('Es requerido el tipo de movimiento')

        v.quantity
            .isEqual(0, 'El stock debe ser mayor a 0')
            .isEmpty('Es requerido asignar el stock')
    })

    observer<any>(f => ({
        name: f.name,
        supply_id: Number(f.id),
        stock: Number(f.quantity),
        increase: f.type_movement_id === '1',
        reason: f.comment
    }))

    function openModal() {
        if (supply) {
            formEditionStock.store({
                id: supply.id.toString() || '',
                name: supply.name,
                actual_stock: supply.stock?.toString() || '',
                stock: supply.stock?.toString() || '',
                type_movement_id: '1',
                comment: '',
                quantity: ''
            })
            formEditionStock.init(formEditionStock.store)
        } else {
            formEditionStock.init()
        }
        modal(FormEditionStock, {
            title: supply ? 'Actualización del Inventario' : 'Nuevo Insumo',
            onSubmit: submit,
            centerTitle: true
        })
    }

    return openModal
}
