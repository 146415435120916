import { ButtonProps } from '@chakra-ui/react'
import ColorType from '../../types/colorType'
import colors from './colors'

export default function getVariantsButton(colorType: ColorType) {
    const buttonPrimaryDefault: ButtonProps = {
        bg: colorType,
        color: 'white',
        h: '30px',
        rounded: '5px',
        fontWeight: 'normal',
        fontSize: '14px',
        border: `1px solid ${colors[colorType]}`,
        _disabled: {
            bgColor: colorType
        },
        _hover: {
            _disabled: {
                bgColor: colorType
            }
        }
    }

    if (colorType === 'white') {
        buttonPrimaryDefault.color = 'dark'
    }

    const variantsButton: Record<string, ButtonProps> = {
        [`${colorType}:sm-sm`]: buttonPrimaryDefault,
        [`${colorType}:md-sm`]: {
            ...buttonPrimaryDefault,
            h: '40px',
            minW: 'auto',
            fontSize: '15px'
        },
        [`${colorType}:lg-sm`]: {
            ...buttonPrimaryDefault,
            h: '50px',
            fontSize: '20px'
        },
        [`${colorType}:xl-sm`]: {
            ...buttonPrimaryDefault,
            h: '60px',
            fontSize: '20px'
        },
        [`${colorType}:2xl-sm`]: {
            ...buttonPrimaryDefault,
            h: '75px',
            minW: 'auto',
            fontSize: '22px'
        },

        [`${colorType}:md-md`]: {
            ...buttonPrimaryDefault,
            h: '40px',
            minW: 'auto',
            fontSize: '15px',
            rounded: '9px'
        },
        [`${colorType}:lg-md`]: {
            ...buttonPrimaryDefault,
            h: '50px',
            fontSize: '20px',
            rounded: '9px'
        },
        [`${colorType}:xl-md`]: {
            ...buttonPrimaryDefault,
            h: '60px',
            fontSize: '20px',
            rounded: '9px'
        },
        [`${colorType}:2xl-md`]: {
            ...buttonPrimaryDefault,
            h: '75px',
            fontSize: '22px',
            rounded: '9px'
        },

        [`${colorType}:sm-lg`]: {
            ...buttonPrimaryDefault,
            h: '30px',
            minW: 'auto',
            fontSize: '14px',
            rounded: '14px'
        },
        [`${colorType}:md-lg`]: {
            ...buttonPrimaryDefault,
            h: '40px',
            minW: 'auto',
            fontSize: '15px',
            rounded: '14px'
        },
        [`${colorType}:lg-lg`]: {
            ...buttonPrimaryDefault,
            h: '50px',
            fontSize: '20px',
            rounded: '14px'
        },
        [`${colorType}:xl-lg`]: {
            ...buttonPrimaryDefault,
            h: '60px',
            fontSize: '20px',
            rounded: '14px'
        },
        [`${colorType}:2xl-lg`]: {
            ...buttonPrimaryDefault,
            h: '75px',
            fontSize: '22px',
            rounded: '14px'
        },

        [`${colorType}:sm-xl`]: {
            ...buttonPrimaryDefault,
            h: '30px',
            minW: 'auto',
            fontSize: '14px',
            rounded: '20px'
        },
        [`${colorType}:md-xl`]: {
            ...buttonPrimaryDefault,
            h: '40px',
            minW: 'auto',
            fontSize: '15px',
            rounded: '20px'
        },
        [`${colorType}:lg-xl`]: {
            ...buttonPrimaryDefault,
            h: '50px',
            fontSize: '20px',
            rounded: '20px'
        },
        [`${colorType}:xl-xl`]: {
            ...buttonPrimaryDefault,
            h: '60px',
            fontSize: '20px',
            rounded: '20px'
        },
        [`${colorType}:2xl-xl`]: {
            ...buttonPrimaryDefault,
            h: '75px',
            fontSize: '22px',
            rounded: '20px'
        }
    }

    return variantsButton
}
