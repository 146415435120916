import React from 'react'
import {
    FormControl,
    FormLabel,
    Input,
    InputProps,
    Grid,
    SelectProps,
    Select,
    Switch,
    Text,
    Box,
    Icon,
    RadioGroup,
    Stack,
    Radio,
    Button,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberDecrementStepper,
    NumberIncrementStepper
} from '@chakra-ui/react'
import { ChangeEvent } from 'react'
import ErrorHelper from '../../../../components/ErrorHelper'
import FileField from '../../../../components/FileField'
import { FileFieldProps } from '../../../../components/FileField/types'
import Close from '../../../../components/Icon/Close'
import NumberField from '../../../../components/NumberField'
import colors from '../../../../config/theme/colors'
import { useGet } from '../../../../hooks/useHttp'
import useStorage from '../../../../hooks/useStorage'
import CategoryItem from '../../../../models/category_item'
import Feature from '../../../../models/feature'
import { formItem } from '../../storage'
import Commission from './Commission'
import { INVENTORY_TYPES, inventory_types } from '../util'
import ModalProductionItems from './ModalProductionItems'
import ProductionItem from '../../../../models/production_item'
import TypeMeasure from '../../../../models/type_measure'

export default function FormCategory() {
    const { business_variables } = useStorage()
    const isEnabledCommission =
        business_variables.find(bv => bv.code === '015')?.value === '1'

    return (
        <>
            <>
                <Text mb={3} fontWeight="bold" fontSize="20px">
                    Información General
                </Text>
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                    <Name />
                    <Price />
                </Grid>
                <Category />
                <Image />
            </>
            {/* <Stock /> */}
            <InventoryManagement />
            <Features />
            {isEnabledCommission && <Commission />}
        </>
    )
}

function Name() {
    const { props, helper, error } = formItem.useField<InputProps>('name')
    return (
        <FormControl mb={4}>
            <FormLabel>Nombre</FormLabel>
            <Input
                {...props}
                placeholder="Ingresar el nombre del producto"
                autoFocus
                maxLength={180}
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function Price() {
    const { props, helper } = formItem.useField<InputProps>('price')
    return (
        <FormControl mb={4}>
            <FormLabel>Precio (S/)</FormLabel>
            <NumberField {...props} placeholder="Ingresar el precio" />
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}

function Category() {
    const { data } = useGet<CategoryItem[]>('/api/category_items?sort=name', [])
    const { props, helper } = formItem.useField<SelectProps>('category_id')

    return (
        <FormControl mb={4}>
            <FormLabel>Categoría</FormLabel>
            <Select {...props} placeholder="Seleccionar categoría del producto">
                {data.result.map(category => (
                    <option key={category.id} value={category.id}>
                        {category.name}
                    </option>
                ))}
            </Select>
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}

function Image() {
    const { props, helper } = formItem.useField<FileFieldProps>('image_url')
    return (
        <FormControl mb={4}>
            <FormLabel>Foto del producto (opcional)</FormLabel>
            <FileField {...props} />
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}

// function Stock() {
//     const { value, setValue } = formItem.useValue('has_inventory_control')
//     const { props, helper, error } =
//         formItem.useField<InputProps>('minimal_stock')

//     const id = formItem.store().id

//     return (
//         <FormControl mb={4}>
//             <FormControl display="flex" alignItems="center">
//                 <FormLabel>¿Tiene control Stock?</FormLabel>
//                 <Switch
//                     isChecked={value}
//                     onChange={e => setValue(e.target.checked)}
//                 />
//             </FormControl>
//             {value && !id && (
//                 <>
//                     <NumberField
//                         {...props}
//                         placeholder="Ingresar stock inicial"
//                     />
//                     <ErrorHelper helper={helper || error} />
//                 </>
//             )}
//         </FormControl>
//     )
// }

function InventoryManagement() {
    const [open, setOpen] = React.useState<boolean>(false)
    const { value, setValue } = formItem.useValue('inventory_management_type')

    const { value: item_production_items, setValue: setItemProductionItems } =
        formItem.useValue('item_production_items')

    const { data: dataMagnitude } = useGet<TypeMeasure[]>(
        '/api/magnitudes?sort=name',
        []
    )

    const renderButton = () => {
        if (value === INVENTORY_TYPES.NO_INVENTORY) return null

        if (
            value === INVENTORY_TYPES.SIMPLE &&
            item_production_items.length > 0
        )
            return null

        return (
            <Button
                variant="alo-green:lg-md"
                type="button"
                onClick={() => setOpen(true)}
                size="sm"
                mt="2"
            >
                Añadir
            </Button>
        )
    }

    const handleAddItemProductionItems = (data: ProductionItem[]) => {
        const newsProductionItems = []
        for (const productionItem of data) {
            const findProductionItem = item_production_items?.find(
                (_data: any) => _data.production_item.id === productionItem?.id
            )
            if (!findProductionItem) {
                newsProductionItems.push({
                    production_item: productionItem,
                    quantity: 0
                })
            }
        }

        setItemProductionItems([
            ...item_production_items,
            ...newsProductionItems
        ])
        setOpen(false)
    }

    const handleDelete = (index: number) => {
        const newsProductionItems = []
        for (let i = 0; i < item_production_items.length; i++) {
            if (i !== index) {
                newsProductionItems.push(item_production_items[i])
            }
        }
        setItemProductionItems([...newsProductionItems])
    }

    const handleChange = (index: number, _value: string, name: string) => {
        item_production_items[index][name] = _value
        setItemProductionItems([...item_production_items])
    }

    const handleChangeMeasure = (
        e: ChangeEvent<HTMLSelectElement>,
        index: number
    ) => {
        item_production_items[index]['magnitude_id'] = e.target.value
        setItemProductionItems([...item_production_items])
    }

    return (
        <>
            <Text mb={3} fontWeight="bold" fontSize="20px">
                Gestión de Inventario
            </Text>
            <FormControl mb={4}>
                <FormControl display="flex" alignItems="center">
                    <RadioGroup value={value} onChange={setValue}>
                        <Stack direction={'row'} gap="10px">
                            {inventory_types.map(data => (
                                <Radio
                                    key={data.value}
                                    name="report"
                                    value={data.value}
                                >
                                    {data.label}
                                </Radio>
                            ))}
                        </Stack>
                    </RadioGroup>
                </FormControl>
                {value !== INVENTORY_TYPES.NO_INVENTORY && (
                    <>
                        {renderButton()}
                        {open ? (
                            <ModalProductionItems
                                open={open}
                                onClose={() => setOpen(false)}
                                handleAddProductionItems={
                                    handleAddItemProductionItems
                                }
                                isSimple={value === INVENTORY_TYPES.SIMPLE}
                            />
                        ) : null}
                        {item_production_items.length !== 0 ? (
                            <Box display="flex" mt="4">
                                <Box width={'40%'} paddingX="4">
                                    <Text fontSize="12px">Nombre</Text>
                                </Box>
                                <Box paddingX="2" width={'25%'}>
                                    <Text fontSize="12px">Cantidad</Text>
                                </Box>
                                <Box width={'30%'} paddingX="2">
                                    <Text fontSize="12px">Unidad</Text>
                                </Box>
                            </Box>
                        ) : null}
                        {item_production_items.map(
                            (item_production_item: any, i: number) => (
                                <Box
                                    key={i}
                                    display="flex"
                                    border="1px solid #C4C4C4"
                                    borderRadius="10px"
                                    marginBottom="2"
                                    marginTop="2"
                                    paddingX="2"
                                >
                                    <Box
                                        width={'40%'}
                                        paddingX="2"
                                        paddingY="2"
                                        borderRight="1px solid #C4C4C4"
                                    >
                                        <Input
                                            value={
                                                item_production_item
                                                    .production_item?.name
                                            }
                                            placeholder=""
                                            border="none"
                                            borderRadius="0px"
                                            height="30px"
                                            readOnly
                                            padding={'0px'}
                                        />
                                    </Box>
                                    <Box
                                        width={'25%'}
                                        paddingX="1"
                                        paddingY="2"
                                        borderRight="1px solid #C4C4C4"
                                    >
                                        <NumberInput
                                            onChange={_value =>
                                                handleChange(
                                                    i,
                                                    _value,
                                                    'quantity'
                                                )
                                            }
                                            placeholder={''}
                                            value={
                                                item_production_item.quantity ??
                                                0
                                            }
                                            precision={2}
                                            min={0}
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                    </Box>
                                    <Box
                                        width={'30%'}
                                        paddingX="1"
                                        paddingY="2"
                                        borderRight="1px solid #C4C4C4"
                                    >
                                        <Select
                                            value={
                                                item_production_item.magnitude_id
                                            }
                                            onChange={e =>
                                                handleChangeMeasure(e, i)
                                            }
                                            placeholder="Seleccionar unidad"
                                        >
                                            {dataMagnitude.result.map(
                                                typeMeasure => (
                                                    <option
                                                        key={typeMeasure.id}
                                                        value={typeMeasure.id}
                                                    >
                                                        {typeMeasure.name}
                                                    </option>
                                                )
                                            )}
                                        </Select>
                                    </Box>
                                    <Box
                                        paddingX="2"
                                        paddingY="2"
                                        width={'5%'}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Icon
                                            onClick={() => handleDelete(i)}
                                            cursor="pointer"
                                            fill={colors.error}
                                            as={Close}
                                        />
                                    </Box>
                                </Box>
                            )
                        )}
                    </>
                )}
            </FormControl>
        </>
    )
}

function Features() {
    const { value, setValue } = formItem.useValue('has_features')
    const { data } = useGet<Feature[]>('/api/features?sort=name', [])
    const { value: features, setValue: setFeatures } =
        formItem.useValue('features')

    const handleChange = (index: number, e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'required') {
            features[index][e.target.name] = e.target.checked
            if (!e.target.checked) {
                features[index]['quantity'] = 0
            }
        } else {
            features[index][e.target.name] = e.target.value
        }
        setFeatures([...features])
    }

    const handleChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        const existFeature = [...features].find(
            f => f.feature_id === Number(e.target.value)
        )
        if (existFeature) {
            e.target.value = ''
            return
        }
        const feature = data.result.find(f => f.id === Number(e.target.value))
        if (feature) {
            setFeatures([
                ...features,
                {
                    feature_id: feature.id,
                    name: feature.name,
                    quantity: 1,
                    required: true
                }
            ])
        }
        e.target.value = ''
    }

    const handleDelete = (index: number) => {
        const newFeatures = []
        for (let i = 0; i < features.length; i++) {
            if (i !== index) {
                newFeatures.push(features[i])
            }
        }
        setFeatures([...newFeatures])
    }

    return (
        <>
            <Text mb={3} fontWeight="bold" fontSize="20px">
                Características
            </Text>
            <FormControl mb={4}>
                <FormControl display="flex" alignItems="center">
                    <FormLabel>¿Tiene caracteristica?</FormLabel>
                    <Switch
                        isChecked={value}
                        color="red"
                        onChange={e => setValue(e.target.checked)}
                    />
                </FormControl>
                {value && (
                    <>
                        <Select
                            onChange={handleChangeSelect}
                            mb="5"
                            placeholder="Seleccionar característica"
                        >
                            {data.result.map(feature => (
                                <option key={feature.id} value={feature.id}>
                                    {feature.name}
                                </option>
                            ))}
                        </Select>
                        <TitlesFeature />
                        {features.map((sub_feature: any, i: number) => (
                            <FeatureComponent
                                key={i}
                                sub_feature={sub_feature}
                                handleChange={handleChange}
                                index={i}
                                handleDelete={handleDelete}
                            />
                        ))}
                    </>
                )}
            </FormControl>
        </>
    )
}

function TitlesFeature() {
    return (
        <Box display="flex">
            <Box width={'50%'} paddingX="4">
                <Text fontSize="12px" mb="2">
                    Nombre
                </Text>
            </Box>
            <Box paddingX="2" width={'25%'}>
                <Text fontSize="12px" mb="2">
                    Obligatorio
                </Text>
            </Box>
            <Box width={'20%'} paddingX="2">
                <Text fontSize="12px" mb="2">
                    Especificar cant.
                </Text>
            </Box>
        </Box>
    )
}

function FeatureComponent({
    sub_feature,
    handleChange,
    index,
    handleDelete
}: {
    sub_feature: any
    handleChange: any
    index: number
    handleDelete: any
}) {
    return (
        <>
            <Box
                display="flex"
                border="1px solid #C4C4C4"
                borderRadius="10px"
                marginBottom="2"
                paddingX="2"
            >
                <Box
                    width={'50%'}
                    paddingX="2"
                    paddingY="2"
                    borderRight="1px solid #C4C4C4"
                >
                    <Input
                        value={sub_feature.name}
                        placeholder="Subcaracterística"
                        maxLength={180}
                        border="none"
                        borderRadius="0px"
                        borderBottom="1px solid #434343"
                        height="30px"
                        name="name"
                        readOnly
                    />
                </Box>
                <Box
                    paddingX="2"
                    borderRight="1px solid #C4C4C4"
                    paddingY="2"
                    width={'25%'}
                    display="flex"
                    alignItems="center"
                >
                    <Switch
                        isChecked={sub_feature.required}
                        color="#434343"
                        name="required"
                        onChange={e => handleChange(index, e)}
                    />
                </Box>
                <Box
                    width={'20%'}
                    paddingX="2"
                    paddingY="2"
                    borderRight="1px solid #C4C4C4"
                >
                    {sub_feature.required && (
                        <Input
                            value={sub_feature.quantity}
                            placeholder="Cantidad"
                            maxLength={180}
                            border="none"
                            borderRadius="0px"
                            borderBottom="1px solid #434343"
                            height="30px"
                            name="quantity"
                            onChange={e => handleChange(index, e)}
                        />
                    )}
                </Box>
                <Box
                    paddingX="2"
                    paddingY="2"
                    width={'5%'}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Icon
                        onClick={() => handleDelete(index)}
                        cursor="pointer"
                        fill={colors.error}
                        as={Close}
                    />
                </Box>
            </Box>
        </>
    )
}
