import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
    Textarea
} from '@chakra-ui/react'
import colors from '../../config/theme/colors'
import { ModalFormAnullProps } from './types'
import useAnullWithCreditNote from './useAnullWithCreditNote'
import ErrorHelper from '../ErrorHelper'

export default function ModalFormAnnul({
    element,
    sunatVoucherId,
    code,
    onDone
}: ModalFormAnullProps) {
    const {
        setOpen,
        isOpen,
        submitHandler,
        isLoading,
        helpers,
        changeHandler
    } = useAnullWithCreditNote(sunatVoucherId, onDone)

    return (
        <>
            {element(() => setOpen(true))}
            <Modal
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                size="md"
                isCentered
            >
                <ModalOverlay />
                <ModalContent px={6} py={4}>
                    <form noValidate onSubmit={submitHandler}>
                        <ModalBody pb={6}>
                            <Text
                                fontSize="2xl"
                                fontWeight="bold"
                                textAlign="center"
                                mb="5"
                            >
                                Anular venta
                            </Text>
                            <Text fontSize="sm" mb={6}>
                                Debido a que se ha generado un comprobante
                                electrónico, debe ingresar el motivo de
                                anulación.
                            </Text>
                            <FormControl mb={4}>
                                <FormLabel>Comprobante a anular</FormLabel>
                                <Input isReadOnly value={code} />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Motivo</FormLabel>
                                <Textarea
                                    autoFocus
                                    name="motivo"
                                    placeholder="Ingresar movito de anulación"
                                    maxLength={300}
                                    onChange={changeHandler}
                                    disabled={isLoading}
                                />
                                <ErrorHelper helper={helpers.motivo} />
                            </FormControl>
                            <Flex mt="8" justifyContent="space-between">
                                <Button
                                    variant="outline"
                                    w="36"
                                    onClick={() => setOpen(false)}
                                    disabled={isLoading}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    w="36"
                                    bg={colors.error}
                                    color="white"
                                    type="submit"
                                    isLoading={isLoading}
                                >
                                    Anular
                                </Button>
                            </Flex>
                        </ModalBody>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}
