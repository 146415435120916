import { Box, Center, SimpleGrid, Text } from '@chakra-ui/react'
import { GridPreOrdersProps } from './types'
import { useGet } from '../../../../hooks/useHttp'
import colors from '../../../../config/theme/colors'
import PreOrder from '../../../../models/pre_order'
import dayjs from 'dayjs'

export const TYPE_BOOKINGS_VALUE = {
    AGENCY: 1,
    DRIVER: 2,
    CLIENT: 3
}

export default function GridPreOrders({
    onClick,
    preOrders,
    bookingId
}: GridPreOrdersProps) {
    const response = useGet<PreOrder[]>(
        `/api/pre_orders?order=asc&pre_order_state=1&booking_id=${bookingId}`,
        []
    )

    function isSelect(preOrderId: number) {
        return preOrders.find(preOrder => preOrder.id === preOrderId)
    }

    return (
        <>
            <SimpleGrid columns={[2, 2, 3]} spacing={3}>
                {response.data.result.map(preOrder => (
                    <Box
                        h="150px"
                        borderRadius={'20px'}
                        p={2}
                        textAlign="center"
                        display={'flex'}
                        justifyContent="center"
                        alignItems={'center'}
                        bg={
                            isSelect(preOrder.id)
                                ? colors['alo-red']
                                : 'gray.400'
                        }
                        key={preOrder.id}
                        color={
                            isSelect(preOrder.id) ? 'white' : 'whiteAlpha.900'
                        }
                        cursor="pointer"
                        flexDirection={'column'}
                        onClick={() => onClick(preOrder)}
                    >
                        <Text as="b">{preOrder.alias}</Text>
                        <Text>
                            {preOrder.booking.agency_guide?.name} -{' '}
                            {preOrder.booking.agency?.name} -{' '}
                            {dayjs(preOrder.booking.hour).format('hh:mm A')}
                        </Text>
                    </Box>
                ))}
            </SimpleGrid>
            {response.data.result.length === 0 && (
                <Center p={6}>No existen pre-pedidos</Center>
            )}
        </>
    )
}
