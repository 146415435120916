import { get } from '../lib/http'
import { Result } from '../lib/http/type'

export default async function loadInitialData<T>(
    paths: Record<keyof T, string>
): Promise<T> {
    const promises: Promise<Result<T[keyof T]>>[] = Object.values(paths).map(
        path => get(path as string)
    )
    const responses = await Promise.all(promises)
    const storage: Partial<Record<keyof T, T[keyof T]>> = {}
    Object.keys(paths).forEach((key, i) => {
        storage[key as keyof T] = responses[i].data.result
    })

    return storage as T
}
