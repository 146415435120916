import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import { useState } from 'react'
import { storagePage } from '../../storage'
import { DataItemsOrderCanceled } from '../../types'
import Card from '../../Components/Card'
import StatBox from '../../Components/StatBox'
import StatCard from '../../Components/StatCard'
import ViewDetailButton from '../../Components/ViewDetailButton'
import StatDivider from '../../Components/StatDivider'

export default function AnnulmentReport({ type }: { type: 'order' | 'item' }) {
    const [showDetail, setShowDetail] = useState(false)

    const { cashboxMovements } = storagePage

    let title,
        itemQuantity,
        orderQuantity = 0
    const data: DataItemsOrderCanceled[] = []
    if (type == 'order') {
        title = 'Pedidos anulados'
        cashboxMovements.map(i => data.push(...i.dataItemsOrderCanceled))
        itemQuantity = data.map(i => i.cantidad).reduce((a, b) => a + b, 0)
        orderQuantity = data
            .map(i => i.order_id)
            .filter(
                (value, index, self) => self.indexOf(value) === index
            ).length
    } else {
        title = 'Items anulados'
        cashboxMovements.map(i => data.push(...i.dataOrderItemsCanceled))
        itemQuantity = data.map(i => i.cantidad).reduce((a, b) => a + b, 0)
    }

    return (
        <Card direction="column" gridGap={4}>
            <Text as="b" mr="2" fontSize="xl" align="center" w="full">
                {title}
            </Text>
            <StatCard>
                {type == 'order' && (
                    <>
                        <StatBox
                            label={'Cant. Pedidos anulados'}
                            value={orderQuantity}
                        />
                        <StatDivider />
                    </>
                )}
                <StatBox label={'Cant. Items anulados'} value={itemQuantity} />
            </StatCard>
            <ViewDetailButton
                showDetail={showDetail}
                setShowDetail={setShowDetail}
            />
            {showDetail && (
                <TableContainer overflow="auto">
                    <Table size="sm">
                        <Thead>
                            <Tr>
                                <Th>Fecha</Th>
                                {type == 'order' && <Th>ID Pedido</Th>}
                                <Th>Cant.</Th>
                                <Th>Producto</Th>
                                <Th>Motivo</Th>
                                <Th>Usuario</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.map((movement, i) => (
                                <Tr key={i}>
                                    <Td>{movement.fecha}</Td>
                                    {type == 'order' && (
                                        <Td>{movement.order_id}</Td>
                                    )}
                                    <Td>{movement.cantidad}</Td>
                                    <Td>{movement.producto}</Td>
                                    <Td>{movement.motivo}</Td>
                                    <Td>{movement.user_cancel.username}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            )}
        </Card>
    )
}
