import dayjs from 'dayjs'
import { useEffect } from 'react'
import { listBooking } from './storage'

export default function useInitial() {
    useEffect(() => {
        listBooking.setQuery('date', dayjs().format('YYYY-MM-DD'))
        listBooking.setQuery('sort', 'hour')
        listBooking.setQuery('order', 'asc')
        Promise.all([listBooking.load()])
    }, [])
}
