import {
    Button,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Spinner,
    Text
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
// import { useGet } from '../../../../hooks/useHttp'
import Driver from '../../../../models/driver'
import usePreOrder from '../../hooks/usePreOrder'
import GuideItem from './GuideItem'
import { ModalLocalGuideProps } from './types'
import Clean from '../../../../components/Clean'
import useDebounce from '../../../../hooks/useDebounce'
import { get } from '../../../../lib/http'

export default function ModalLcalGuide({
    isOpen,
    onClose
}: ModalLocalGuideProps) {
    const { setDriver, driver: driverSaved } = usePreOrder()
    const [drivers, setDrivers] = useState<Driver[]>([])
    const [driverId, setDriverId] = useState(0)
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)

    const debounceValue = useDebounce<string>(search, 1500)

    const getDrivers = async (_search: string) => {
        try {
            setLoading(true)
            const { data } = await get<Driver[]>(
                `/api/drivers?page=1&order=asc&sort=id&search=${_search}`
            )
            setDrivers(data.result)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (debounceValue === '' || debounceValue.length < 3) {
            setDrivers([])
        } else {
            getDrivers(debounceValue)
        }
    }, [debounceValue])

    function confirmHandler() {
        if (!driverId) return
        const driver = drivers.find(_driver => _driver.id === driverId)
        if (driver) {
            setDriver(driver)
        }
        if (search) setSearch('')
        onClose()
    }

    useEffect(() => {
        if (!isOpen) return
        if (driverSaved) {
            setDriverId(driverSaved.id)
        } else {
            setDriverId(0)
        }
    }, [isOpen])

    const handleClose = () => {
        if (search) setSearch('')
        onClose()
    }

    return (
        <Modal isOpen={isOpen} onClose={handleClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize="2xl" fontWeight="bold">
                    Guías locales
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex mb={3}>
                        <Clean
                            width={'100%'}
                            value={search}
                            onClick={() => setSearch('')}
                        >
                            <Input
                                autoFocus
                                value={search}
                                width={'100%'}
                                placeholder="🏸 Buscar por nombre"
                                onChange={e => setSearch(e.target.value)}
                            />
                        </Clean>
                    </Flex>
                    {loading ? (
                        <Flex
                            my={8}
                            alignItems={'center'}
                            justifyContent="center"
                        >
                            <Spinner size="xl" />
                        </Flex>
                    ) : (
                        <>
                            {search === '' || search.length < 3 ? (
                                <Text my={8}>
                                    Escriba en la barra de búsqueda para mostrar
                                    los guías
                                </Text>
                            ) : (
                                <SimpleGrid columns={[2, 2, 3]} spacing={3}>
                                    {drivers.map(driver => (
                                        <GuideItem
                                            key={driver.id}
                                            isChecked={driverId === driver.id}
                                            label={driver.name}
                                            onClick={() =>
                                                setDriverId(driver.id)
                                            }
                                        />
                                    ))}
                                </SimpleGrid>
                            )}
                        </>
                    )}

                    <Flex
                        w="full"
                        py="5"
                        gap={2}
                        justifyContent="space-between"
                    >
                        <Button
                            _hover={{}}
                            h="60px"
                            w="180px"
                            borderWidth="1px"
                            borderColor="#434343"
                            color="#434343"
                            bg="white"
                            onClick={handleClose}
                        >
                            Cancelar
                        </Button>
                        <Button
                            h="60px"
                            w="180px"
                            color="white"
                            bg="orange"
                            _hover={{}}
                            onClick={confirmHandler}
                            isDisabled={!driverId}
                        >
                            Confirmar
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
