import { Link, ListItem, Text } from '@chakra-ui/react'
import { GroupItemProps } from './types'
import colors from '../../../config/theme/colors'
import { useHistory } from 'react-router-dom'

export default function GroupItem({ to, label, onClick }: GroupItemProps) {
    const { push } = useHistory()

    return (
        <ListItem color={colors['gray-2']}>
            {onClick && (
                <Text
                    _hover={{ textDecor: 'underline' }}
                    onClick={onClick}
                    cursor="pointer"
                >
                    {label}
                </Text>
            )}
            {to && <Link onClick={() => push(to)}>{label}</Link>}
        </ListItem>
    )
}
