import {
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
    useDisclosure,
    SimpleGrid
} from '@chakra-ui/react'
import colors from '../../../../../config/theme/colors'
import ItemStock from './ItemStock'
import TableMovements from './TableMovements'
import { ModalDetailsProps } from './types'

export default function ModalDetails({
    element,
    dailySummaryOperation,
    date
}: ModalDetailsProps) {
    const { onOpen, onClose, isOpen } = useDisclosure()

    const [d, m, y] = date.split('/')

    return (
        <>
            {element(onOpen)}
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size="xl"
                closeOnOverlayClick
            >
                <ModalOverlay />
                <ModalContent px={2} py={4}>
                    <ModalBody pb={6}>
                        <Text
                            fontSize="2xl"
                            fontWeight="bold"
                            textAlign="center"
                        >
                            Detalles de ingreso y salida
                        </Text>
                        <Text
                            textAlign="center"
                            fontSize="sm"
                            color={colors['gray-2']}
                            mb="5"
                        >
                            {dailySummaryOperation.inventory_item.name} -{' '}
                            {dailySummaryOperation.warehouse.name}
                        </Text>
                        <SimpleGrid
                            columns={4}
                            border="1px solid"
                            borderColor={colors['gray-3']}
                            borderRadius="xl"
                            padding={4}
                        >
                            <ItemStock
                                label="Stock Inicial"
                                value={dailySummaryOperation.stock_start}
                            />
                            <ItemStock
                                label="Ingresos"
                                value={dailySummaryOperation.daily_inputs}
                            />
                            <ItemStock
                                label="Salidas"
                                value={dailySummaryOperation.daily_outputs}
                            />
                            <ItemStock
                                label="Stock Final"
                                value={dailySummaryOperation.stock_end}
                            />
                        </SimpleGrid>
                        <Text
                            fontSize="xs"
                            mt={4}
                            fontWeight="bold"
                            color={colors['gray-2']}
                        >
                            Movimientos
                        </Text>
                        <TableMovements
                            inventoryItemId={
                                dailySummaryOperation.inventory_item_id
                            }
                            warehouseId={dailySummaryOperation.warehouse_id}
                            date={[y, m, d].join('-')}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
