import modal from '../../../lib/modal'
import Bookings from '../ContentModals/Bookings'

export default function useBookings() {
    function openModalBookings() {
        modal(Bookings, {
            size: 'xl',
            cleaned: true
        })
    }

    return {
        openModalBookings
    }
}
