import { Box, Icon } from '@chakra-ui/react'
import Navbar from '../../../components/Navbar'
import { useParams, useHistory } from 'react-router-dom'

import Initial from '../../../components/Initial'

import useInitial from '../useInitial'
import LeftContent from './LeftContent'
import RightContent from './RightContent'
import Navigation from '../../../components/Icon/Navigation'

export default function OrderContent() {
    const { order_id } = useParams<{
        order_id: string
    }>()

    const { pageState } = useInitial(order_id)

    const history = useHistory()

    const handleBack = () => {
        history.goBack()
    }

    return (
        <Initial pageState={pageState}>
            <Navbar text="CONFIRMACIÓN DE PAGO">
                <Box
                    px="10"
                    display={{
                        base: 'none',
                        md: 'flex'
                    }}
                    alignItems="center"
                    fontWeight="bold"
                    fontSize="xl"
                    cursor="pointer"
                    onClick={() => handleBack()}
                    gridGap="10px"
                >
                    <Icon as={Navigation} fontSize="24" fill="white" />
                    CANCELAR
                </Box>
            </Navbar>
            <Box
                minH="calc(100vh - 60px)"
                w="full"
                display="flex"
                overflow="hidden"
                flexDirection={{
                    base: 'column',
                    lg: 'row'
                }}
            >
                <LeftContent />
                <RightContent />
            </Box>
        </Initial>
    )
}
