interface ItemHashProps {
    alias?: string
    itemId: number
    orderItemId?: number
    // description?: string
}

export default function itemHash(data: ItemHashProps) {
    let hash = ''
    const order: Array<keyof ItemHashProps> = ['alias', 'itemId', 'orderItemId']
    const values: Array<string | number> = []
    order.forEach(key => {
        const value = data[key]
        if (value) {
            values.push(value)
        }
    })

    values.forEach((value, i) => {
        const formatted =
            typeof value === 'string'
                ? value
                      .toLowerCase()
                      .split(' ')
                      .filter(v => v.trim())
                      .join('_')
                : value
        if (i === 0) {
            hash = `${formatted}`
            return
        }
        hash = `${hash}-${formatted}`
    })

    return hash
}
