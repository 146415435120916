import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import { useState } from 'react'
import { storagePage } from '../../storage'
import { Credit } from '../../types'
import Card from '../../Components/Card'
import StatBox from '../../Components/StatBox'
import StatCard from '../../Components/StatCard'
import ViewDetailButton from '../../Components/ViewDetailButton'
import StatDivider from '../../Components/StatDivider'

export default function Credits({ type }: { type: 'granted' | 'collected' }) {
    const [showDetail, setShowDetail] = useState(false)

    const { cashboxMovements } = storagePage

    let title, creditQuantity, totalCredit
    const data: Credit[] = []
    if (type == 'granted') {
        title = 'Créditos otorgados'
        cashboxMovements.map(i => data.push(...i.creditsGranted))
        creditQuantity = data.length
        totalCredit = data.reduce((acc, curr) => acc + curr.amount, 0)
    } else {
        title = 'Recaudaciones de créditos'
        cashboxMovements.map(i => data.push(...i.dataCreditColecction))
        creditQuantity = data.length
        totalCredit = data.reduce((acc, curr) => acc + curr.amount, 0)
    }

    return (
        <Card
            direction="column"
            gridGap={4}
            w={{ base: 'full', md: '49.5%', lg: '50%' }}
        >
            <Text as="b" mr="2" fontSize="xl" align="center" w="full">
                {title}
            </Text>
            <StatCard>
                <StatBox
                    label={type == 'granted' ? 'Créditos' : 'Cobros'}
                    value={creditQuantity}
                />
                <StatDivider />

                <StatBox
                    label={type == 'granted' ? 'Total' : 'Total recaudado'}
                    value={totalCredit}
                />
            </StatCard>
            <ViewDetailButton
                showDetail={showDetail}
                setShowDetail={setShowDetail}
            />
            {showDetail && (
                <TableContainer overflow="auto">
                    <Table size="sm">
                        <Thead>
                            <Tr>
                                <Th>Fecha</Th>
                                <Th>Cliente</Th>
                                <Th isNumeric>
                                    {type == 'granted'
                                        ? 'Monto'
                                        : 'Monto recaudado'}
                                </Th>
                                {type == 'granted' && (
                                    <Th>Fecha de vencimiento</Th>
                                )}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.map((movement, i) => (
                                <Tr key={i}>
                                    <Td>
                                        {type == 'granted'
                                            ? movement.date
                                            : movement.datetime}
                                    </Td>
                                    <Td>{movement.client}</Td>
                                    <Td isNumeric>
                                        S/{movement.amount.toFixed(2)}
                                    </Td>
                                    {type == 'granted' && (
                                        <Td>{movement.due_date}</Td>
                                    )}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            )}
        </Card>
    )
}
