import { useToast } from '@chakra-ui/react'
import { FormEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { put } from '../../../../lib/http'
import { close } from '../../../../lib/modal'
import { storagePage } from '../../storage'

export default function ChangeWaiter() {
    const toast = useToast()
    const { push } = useHistory()

    async function handleChangeWaiter(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const formElem = ev.target as typeof ev.target & {
            waiter_id: { value: string }
        }
        const { error } = await put(
            `/api/orders/${storagePage.table?.order.id}/waiter`,
            {
                waiter_id: formElem.waiter_id.value
            }
        )
        if (error) {
            toast({
                title: error.message,
                position: 'top',
                status: 'error',
                isClosable: true
            })
            close()
        } else {
            push('/by_tables')
            toast({
                title: 'Cambio de mozo realizado',
                position: 'top',
                isClosable: true,
                status: 'success'
            })
            close()
        }
    }

    return handleChangeWaiter
}
