import { Button, Flex } from '@chakra-ui/react'
import TableList from './TableList'
import colors from '../../../config/theme/colors'
import useMoveProducts from '../hooks/useMoveProducts'

export default function TablesSection() {
    const { confirmMovement } = useMoveProducts()
    return (
        <Flex direction="column" justifyContent="space-between" h="full">
            <TableList />
            <Flex p={6} pb={0} justifyContent="flex-end">
                <Button
                    bg={colors['alo-green']}
                    color="white"
                    w="fit-content"
                    size="lg"
                    minH="48px"
                    borderRadius="lg"
                    onClick={confirmMovement}
                    _hover={{}}
                    type={'reset'}
                >
                    Confirmar
                </Button>
            </Flex>
        </Flex>
    )
}
