import { useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { FormEvent, useEffect, useState } from 'react'
import { post } from '../../../lib/http'
import Client from '../../../models/client'
import {
    BodyGenerateSunatVoucher,
    ResponseGenerateSunatVoucher
} from '../types'
import { DateWithTimeCurrent } from '../../../utilities/date_tools'
import { Result } from '../../../lib/http/type'
import getPrinterConfig from '../../../utilities/getPrinterConfig'

export default function useModalEmit({
    orderPaymentId,
    client,
    amount,
    onDone,
    isCredit,
    notHaveProof
}: {
    orderPaymentId?: number
    client: Client
    amount: number
    onDone: () => void
    isCredit?: boolean
    notHaveProof: boolean
}) {
    const [isLoading, setLoading] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const toast = useToast()
    const [currentClient, setClient] = useState(() => client)
    const [helper, setHelper] = useState('')
    const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'))
    const [serie, setSerie] = useState(0)
    const [reason, setReason] = useState('')

    useEffect(() => {
        changeClient(client)
    }, [isOpen])

    const printerConfig = getPrinterConfig()

    async function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const formElem = ev.target as typeof ev.target & {
            type_voucher_id: { value: string }
        }

        if (
            formElem.type_voucher_id.value === '2' &&
            currentClient.type_identification_document_id !== 2
        ) {
            setHelper(
                'Es necesario que el cliente tenga documento RUC para la emisión de factura'
            )
            return
        }

        if (currentClient.id === 1 && amount > 700) {
            setHelper(
                'Para ventas con boleta mayores de S/700, el cliente no puede ser Genérico'
            )
            return
        }

        if (!notHaveProof && reason === '') {
            setHelper('El motivo es requerido')
            return
        }

        setHelper('')
        setLoading(true)

        const bodySunat: BodyGenerateSunatVoucher = {
            //issue_date: isCredit ? dayjs().format() : date,
            issue_date: DateWithTimeCurrent(date),
            order_payment_id: orderPaymentId,
            /*
            type_voucher_id: isCredit
                ? 2
                : parseInt(formElem.type_voucher_id.value),
            */
            type_voucher_id: parseInt(formElem.type_voucher_id.value),
            client_id: currentClient.id,
            printer_id: Number(printerConfig?.printer_id),
            ...(!isCredit && { total_final: amount }),
            ...(!notHaveProof && { reason })
        }

        let responseSend: Result<unknown>

        if (!notHaveProof) {
            responseSend = await post(
                '/api/sunat_vouchers/change_receipt_type',
                bodySunat
            )
        } else {
            const responseGenerate = await post<ResponseGenerateSunatVoucher>(
                '/api/sunat_vouchers/generate_sunat_voucher',
                bodySunat
            )

            if (responseGenerate.error) {
                toast({
                    title: responseGenerate.error.message,
                    status: 'error',
                    position: 'top'
                })
                setLoading(false)
                return
            }

            responseSend = await post(
                '/api/sunat_vouchers/send_sunat_voucher',
                {
                    filename: responseGenerate.data.result.result.xml_name
                }
            )
        }

        if (responseSend.error) {
            toast({
                title: responseSend.error.message,
                status: 'error',
                position: 'top',
                isClosable: true
            })
        } else {
            toast({
                title: 'Comprobante emitido',
                status: 'success',
                position: 'top',
                isClosable: true
            })
        }

        setOpen(false)
        onDone()
        setLoading(false)
    }

    function changeClient(_client: Client) {
        setClient(_client)
        setHelper('')
    }

    function changeDate(_date: string) {
        setDate(_date)
        setHelper('')
    }

    function changeSerie(_serie: number) {
        setSerie(_serie)
        setHelper('')
    }

    function changeReason(_reason: string) {
        setReason(_reason)
        setHelper('')
    }

    return {
        submitHandler,
        isLoading,
        isOpen,
        setOpen,
        currentClient,
        setClient: changeClient,
        helper,
        setDate: changeDate,
        date,
        setSerie: changeSerie,
        serie,
        setReason: changeReason
    }
}
