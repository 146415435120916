import {
    FormControl,
    FormLabel,
    Input,
    Button,
    FormHelperText,
    Image,
    Alert,
    AlertIcon,
    Flex
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import logo from '../../assets/logo.svg'
import Touch from '../../components/Icon/Touch'
import colors from '../../config/theme/colors'
import useSubmit from './useSubmit'
import useStorage from '../../hooks/useStorage'

export default function Form() {
    const { submitHandler, errorField, changeHandler, loading, errorText } =
        useSubmit()

    const { business_variables } = useStorage()
    const isEnabledTable =
        business_variables.find(bv => bv.code === '023')?.value === '1'

    const { push } = useHistory()

    return (
        <form onSubmit={submitHandler} style={{ width: '100%' }}>
            <Image
                mx="auto"
                w="150px"
                h={{
                    base: '80px',
                    md: '100px'
                }}
                mb={{
                    base: '10',
                    md: '20'
                }}
                src={logo}
                alt="logo"
            />
            <FormControl id="user" mb="10">
                <FormLabel color="alo-gray">Usuario</FormLabel>
                <Input
                    h="60px"
                    placeholder="Usuario"
                    name="username"
                    onChange={changeHandler}
                    rounded="xl"
                    autoFocus
                />
                {errorField.username && (
                    <FormHelperText color="red">
                        {errorField.username}
                    </FormHelperText>
                )}
            </FormControl>
            <FormControl id="password">
                <FormLabel color="alo-gray">Contraseña</FormLabel>
                <Input
                    h="60px"
                    name="password"
                    placeholder="Contraseña"
                    type="password"
                    rounded="xl"
                    onChange={changeHandler}
                />
                {errorField.password && (
                    <FormHelperText color="red">
                        {errorField.password}
                    </FormHelperText>
                )}
            </FormControl>
            {errorText && (
                <Alert mt="5" status="error">
                    <AlertIcon />
                    {errorText}
                </Alert>
            )}
            <Flex flexDirection="column">
                <Button
                    colorScheme="orange"
                    mt={{
                        base: '10',
                        md: '20'
                    }}
                    type="submit"
                    size="lg"
                    w="250px"
                    isLoading={loading}
                    mx="auto"
                >
                    Ingresar
                </Button>
                {isEnabledTable && (
                    <Button
                        variant="outline"
                        colorScheme="orange"
                        size="lg"
                        mt="4"
                        w="250px"
                        mx="auto"
                        leftIcon={<Touch fill={colors.orange[400]} />}
                        onClick={() => push('/by_tables')}
                    >
                        Iniciar Pedido
                    </Button>
                )}
            </Flex>
        </form>
    )
}
