// import ColorType from '../../types/colorType'

const colors = {
    primary: '#E1453D',
    secondary: '#FE6A5E',
    accent: '#F8C765',
    'alo-blue': '#4E6BD3',
    'alo-red': '#FE6A5E',
    yellow: '#FFC82C',
    'alo-green': '#44C281',
    turquoise: '#44C2C2',
    dark: '#262C2F',
    'dark-2': '#334148',
    'alo-gray': '#434343',
    'gray-2': '#334148',
    'gray-3': '#708595',
    white: '#FFFFFF',
    base: '#FAFAFA',
    'base-2': '#F9F9F9',
    'base-3': '#F6F6F6',
    'base-4': '#ECECEC',
    success: '#44C281',
    error: '#FE6A5E',
    black: '#262C2F',
    red: {
        50: '#ffeef0',
        100: '#ffd5d8',
        200: '#f8a7a4',
        300: '#f28580',
        400: '#fe6b5e',
        500: '#ff5f44',
        600: '#f65844',
        700: '#e34e3e',
        800: '#d64837',
        900: '#c73e2b'
    },
    'blue-gray': {
        50: '#eceff3',
        100: '#d0d9df',
        200: '#b3c0c9',
        300: '#94a6b3',
        400: '#7d92a2',
        500: '#667f91',
        600: '#597080',
        700: '#495c69',
        800: '#3b4954',
        900: '#29343c'
    },
    orange: {
        50: '#fff9e4',
        100: '#ffeeba',
        200: '#ffe48f',
        300: '#ffda65',
        400: '#ffd04a',
        500: '#ffc83f',
        600: '#ffbb3a',
        700: '#ffa936',
        800: '#ff9933',
        900: '#fc7d2f'
    },
    green: {
        50: '#e5f6ed',
        100: '#c1e9d1',
        200: '#98dbb5',
        300: '#6bcd97',
        400: '#44c281',
        500: '#00b76b',
        600: '#00a760',
        700: '#009553',
        800: '#008447',
        900: '#006432'
    },
    blue: {
        50: '#e7eaf9',
        100: '#c3caef',
        200: '#9ba8e5',
        300: '#7186db',
        400: '#4e6bd3',
        500: '#2350cb',
        600: '#1c48c0',
        700: '#0a3eb4',
        800: '#0034a8',
        900: '#002192'
    },
    cyan: {
        50: '#e1f4f5',
        100: '#b3e4e5',
        200: '#7fd4d5',
        300: '#44c2c2',
        400: '#00b4b2',
        500: '#00a5a2',
        600: '#009893',
        700: '#008782',
        800: '#007771',
        900: '#005a52'
    },
    purple: {
        50: '#f2e7f6',
        100: '#dec3e9',
        200: '#ca9bdb',
        300: '#b575cc',
        400: '#a559c0',
        500: '#9541b4',
        600: '#883dad',
        700: '#7537a4',
        800: '#65329a',
        900: '#462989'
    }
}

export default colors
