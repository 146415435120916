import dayjs from 'dayjs'
import SimpleNav from '../../components/SimpleNav'
import { controlList, storagePage } from './storage'

export default function Nav() {
    const dateQuery = controlList.getQuery('issue_date')
    controlList.useData()

    const date = dateQuery
        ? dayjs(dateQuery as string, 'YYYY-MM-DD').format('DD/MM/YYYY')
        : ''

    let status = 'No existen comprobantes'

    if (storagePage.status === 'pending') {
        status = 'Pendiente'
    }

    if (storagePage.status === 'validated') {
        status = 'Validado'
    }

    return (
        <SimpleNav
            title={`Resúmenes diarios - ${date}`}
            badge={status}
            badgeColor={
                storagePage.status === 'validated' ? 'success' : 'accent'
            }
        />
    )
}
