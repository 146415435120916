import { useEffect, useState } from 'react'
import { get } from '../../../lib/http'
import { storagePage } from '../storage'
import { BanknotesValues, CashCountData, CoinsValues } from './types'

export default function useArchingValues() {
    const [isLoading, setLoading] = useState(true)
    const { denominationMoneys, cashboxUser } = storagePage
    const [commentary, setCommentary] = useState('')

    const getDenominations = (type: number) =>
        denominationMoneys.filter(money => money.type === type)

    const [banknotesValues, setBanknotesValues] = useState<BanknotesValues>(
        () => {
            const values: BanknotesValues = {}
            getDenominations(1).forEach(({ id, value }) => {
                values[value] = {
                    quantity: 0,
                    denominationMoneyId: id
                }
            })
            return values
        }
    )

    const [coinsValues, setCoinsValues] = useState<CoinsValues>(() => {
        const values: CoinsValues = {}
        for (const { id, value } of getDenominations(2)) {
            values[value] = {
                quantity: 0,
                denominationMoneyId: id
            }
        }
        return values
    })

    const [card, setCard] = useState('0')
    const [transfer, setTransfer] = useState('0')

    const banknoteTotal = Object.entries(banknotesValues).reduce(
        (a, [banknote, { quantity }]) => a + Number(banknote) * quantity,
        0
    )

    const coinsTotal = Object.entries(coinsValues).reduce(
        (a, [banknote, { quantity }]) => a + Number(banknote) * quantity,
        0
    )

    async function load() {
        setLoading(true)
        const { error, data } = await get<CashCountData>(
            `/api/cash_counts/${cashboxUser?.id}` // cashboxUser no será undefined
        )
        if (error) {
            storagePage.cashCount = null
            setLoading(false)
            return
        }
        storagePage.cashCount = data.result
        setCommentary(data.result.comment || '')
        const _banknotesValues: BanknotesValues = { ...banknotesValues }
        const _coinsValues: CoinsValues = { ...coinsValues }
        for (const cashCountDetail of data.result.cash_count_details) {
            const { denomination_money_id, quantity } = cashCountDetail
            const { type } = cashCountDetail.denomination_money
            if (type === 1) {
                for (const [key, { denominationMoneyId }] of Object.entries(
                    banknotesValues
                )) {
                    if (denominationMoneyId === denomination_money_id) {
                        _banknotesValues[Number(key)] = {
                            quantity,
                            denominationMoneyId
                        }
                        break
                    }
                }
            }
            if (type === 2) {
                for (const [key, { denominationMoneyId }] of Object.entries(
                    _coinsValues
                )) {
                    if (denominationMoneyId === denomination_money_id) {
                        _coinsValues[Number(key)] = {
                            quantity,
                            denominationMoneyId
                        }
                        break
                    }
                }
            }
            if (type === 3) {
                setCard(cashCountDetail.quantity.toString())
            }
            if (type === 4) {
                setTransfer(cashCountDetail.quantity.toString())
            }
        }

        setBanknotesValues(_banknotesValues)
        setCoinsValues(_coinsValues)
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [])

    return {
        commentary,
        setCommentary,
        banknotesValues,
        setBanknotesValues,
        coinsValues,
        setCoinsValues,
        card,
        setCard,
        transfer,
        setTransfer,
        banknoteTotal,
        coinsTotal,
        isLoading
    }
}
