import FormEvent from '../../lib/form_event'
import { StoragePage } from './types'

export const storagePage: StoragePage = {
    account_orders: [],
    matrix_items: [],
    items: [],
    account_id: 0,
    items_to_divide: []
}

export const formSplitPayment = new FormEvent<any>({
    items: [],
    account_id: ''
})

export const formAccount = new FormEvent<any>({
    name: ''
})
