import { Flex, Input } from '@chakra-ui/react'
import Clean from '../../../components/Clean'
import SelectWithLabel from '../../../components/SelectWithLabel'
import { useGet } from '../../../hooks/useHttp'
import Zone from '../../../models/zone'
import { listTable } from '../storage'

export default function Filters() {
    return (
        <Flex w="full" justifyContent={{ base: 'flex-end', md: 'flex-start' }}>
            <SearchField />
            <SelectField />
        </Flex>
    )
}

function SearchField() {
    const { clear, ...props } = listTable.useSearch()
    return (
        <Clean value={props.value} onClick={clear}>
            <Input
                mr="4"
                w={{ base: 'full', md: '80' }}
                placeholder="🏸 Buscar por nombre"
                {...props}
            />
        </Clean>
    )
}

function SelectField() {
    const props = listTable.useFilter('zone_id')
    const { data } = useGet<Zone[]>('2>/api/zones/?sort=name', [])

    return (
        <SelectWithLabel
            {...props}
            label="Seleccionar ambiente:"
            ml="2"
            placeholder="Todos"
        >
            {data.result.map(zone => (
                <option value={zone.id} key={zone.id}>
                    {zone.name}
                </option>
            ))}
        </SelectWithLabel>
    )
}
