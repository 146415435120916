import { render } from 'redity'
import ModalProvider, { Key } from './ModalProvider'
import states from './states'
import { InfoModal } from './types'

export default function modal(
    Component: (...props: any) => JSX.Element,
    info: InfoModal
) {
    states.isOpen = true
    states.Component = Component
    states.title = info.title || ''
    states.subTitle = info.subTitle || ''
    states.size = info.size || 'md'
    states.cleaned = info.cleaned || false
    states.onSubmit = info.onSubmit || (() => null)
    states.isCentered = info.isCentered !== false
    states.closeOnOverlayClick = info.closeOnOverlayClick !== false
    states.centerTitle = info.centerTitle || false
    render(Key)
}

export function loading(is = true) {
    if (!states.isOpen) return
    states.isLoading = is
    render(Key)
}

export function close() {
    if (!states.isOpen) return
    states.isLoading = false
    states.isOpen = false
    render(Key)
}

export { ModalProvider, states }
