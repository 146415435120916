/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Extrae datos de un formulario
 * @param target elemento form
 * @returns data
 */
export default function extractDataForm<T extends Record<string, any>>(
    target: HTMLFormElement | EventTarget
): T {
    const data = Object.fromEntries(
        new FormData(target as HTMLFormElement)
    ) as Record<keyof T, string>
    return data as T
}
