/**
 * @deprecated user colors of ./theme
 */
const Colors = {
    PRIMARY: '#E1453D',
    SECONDARY: '#FE6A5E',
    /**
     * @deprecated
     */
    HOVER_SECONDARY: '#290208',
    WHITE: '#FFFFFF',
    SUCCESS: '#45C86A',
    /**
     * @deprecated
     */
    ACCESS: '#29A6FF',
    ERROR: '#FE6A5E',
    INFO: '#437FC6',
    /**
     * @deprecated
     */
    INFO_LIGHT: '#EAF6FF',
    PURPLE: '#9641B4',
    DARK: '#262C2F',
    BASE: '#FAFAFA',
    BASE2: '#FBFAFC',
    BASE3: '#F6F6F6',
    ACCENT: '#FF9933',
    // -- colors -- //
    GRAY: '#434343',
    GREEN: '#45C86A',
    ORANGE: '#F8C765',
    RED: '#FE6A5E',
    BLUE: '#4E6BD3',
    YELLOW: '#FFC82C',
    TURQUOISE: '#44C2C2'
}

export default Colors
