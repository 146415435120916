import React, { useState } from 'react'
import Reason from '../Reason'
import {
    Box,
    FormControl,
    FormLabel,
    Icon,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Button,
    useToast,
    Text
} from '@chakra-ui/react'
import Close from '../../../../components/Icon/Close'
import CustomNumberInput from '../CustomNumberInput'
import useUpdateStock from '../../hooks/useUpdateStock'
import Warehouse from '../../../../models/warehouse'
import { useGet } from '../../../../hooks/useHttp'
import SelectWarehouse from '../SelectWarehouse'
import FieldSearchProductionItem from '../FieldSearchProductionItem'
import colors from '../../../../config/theme/colors'
import onlyNumber from '../../../../utilities/onlyNumber'

interface Props {
    onClose: () => void
}

export default function DiscountStock({ onClose }: Props) {
    const [helper, setHelper] = useState<string>('')
    const [form, setForm] = useState({
        warehouse_id: '',
        reason: ''
    })
    const [productionItems, setProductionsItems] = useState<any[]>([])
    const [errorItems, setErrorItems] = useState<any[]>([])

    const { data } = useGet<Warehouse[]>('/api/warehouses', [])

    const toast = useToast()

    const { submit, isLoading } = useUpdateStock({
        handleFinish: () => {
            toast({
                status: 'success',
                title: 'Se realizó el descuento correctamente',
                position: 'top'
            })
            onClose()
        },
        handleError: () => {
            toast({
                status: 'error',
                title: 'Ocurrio un error, al realizar el descuento',
                position: 'top'
            })
        }
    })

    const validData = () => {
        let isValid = true
        if (!form.warehouse_id) {
            setHelper('Debe seleccionar un almacén')
            isValid = false
        }

        if (productionItems.length === 0) {
            isValid = false
        } else {
            const errorProductionItems = []
            for (const productionItem of productionItems) {
                if (Number(productionItem.quantity) <= 0) {
                    errorProductionItems.push({
                        quantity: 'La cantidad debe ser mayor a 0'
                    })
                    isValid = false
                } else {
                    errorProductionItems.push({
                        quantity: ''
                    })
                }
            }
            if (errorProductionItems.length > 0) {
                setErrorItems(errorProductionItems)
            }
        }

        return isValid
    }

    const handleSubmit = async () => {
        if (!validData()) {
            return
        }

        const production_items = []
        for (const dataproductionItem of productionItems) {
            production_items.push({
                production_item_id: dataproductionItem.production_item.id,
                quantity: dataproductionItem.quantity,
                unit_cost: dataproductionItem.cost
            })
        }

        await submit({
            type_change: false,
            warehouse_id: Number(form.warehouse_id),
            reason: form.reason,
            production_items
        })
    }

    const handleChange = (
        value: string,
        index: number,
        name: string,
        maxValue?: number
    ) => {
        if (name === 'quantity') {
            if (errorItems[index]) {
                errorItems[index][name] = ''
                setErrorItems([...errorItems])
            }
            const v = onlyNumber(value, {
                decimal: 2,
                min: 0,
                negative: false,
                max: maxValue ?? Infinity
            })
            productionItems[index][name] = v
        } else {
            productionItems[index][name] = value
        }
        setProductionsItems([...productionItems])
    }

    const handleDelete = (index: number) => {
        const newProductionItems = []
        for (let i = 0; i < productionItems.length; i++) {
            if (i !== index) {
                newProductionItems.push(productionItems[i])
            }
        }
        setProductionsItems([...newProductionItems])
        if (index < errorItems.length && errorItems.length > 0) {
            const newErrorItems = []
            for (let i = 0; i < errorItems.length; i++) {
                if (i !== index) {
                    newErrorItems.push(errorItems[i])
                }
            }
            setErrorItems([...newErrorItems])
        }
    }

    return (
        <>
            <Box
                display={'flex'}
                flexDir={{
                    base: 'column',
                    md: 'row'
                }}
                gap={4}
                mt={4}
            >
                <Box
                    w={{
                        base: 'full',
                        md: '70%'
                    }}
                    rounded={'lg'}
                    bg="white"
                >
                    <FormControl
                        p={4}
                        borderBottom={'1px solid'}
                        borderColor={'gray.200'}
                    >
                        <SelectWarehouse
                            value={form.warehouse_id}
                            handleChange={e => {
                                setForm({
                                    ...form,
                                    warehouse_id: e.target.value
                                })
                                setHelper('')
                            }}
                            helper={helper}
                            options={data.result}
                        />
                    </FormControl>
                    <Box p={4} mt={4}>
                        <FormControl
                            mb={6}
                            w={{
                                base: 'full',
                                md: '50%'
                            }}
                        >
                            <FormLabel>Buscar Articulo</FormLabel>
                            <FieldSearchProductionItem
                                onSelect={_productionItem => {
                                    if (!_productionItem) return
                                    const findProductionItem =
                                        productionItems.find(
                                            _data =>
                                                _data.id === _productionItem?.id
                                        )

                                    if (!findProductionItem) {
                                        setProductionsItems(prev => [
                                            ...prev,
                                            {
                                                ..._productionItem,
                                                quantity: 0
                                            }
                                        ])
                                    }
                                }}
                                warehouse_id={form.warehouse_id}
                                validWarehouse={() => {
                                    setHelper('Debe seleccionar un almacén')
                                }}
                            />
                        </FormControl>
                        <TableContainer>
                            <Table size="sm">
                                <Thead>
                                    <Tr>
                                        <Th>Articulo</Th>
                                        <Th>Stock</Th>
                                        <Th>Unidad</Th>
                                        <Th>Costo unitario</Th>
                                        <Th>Cantidad a descontar</Th>
                                        <Th></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {productionItems.map(
                                        (itemMovement, index) => (
                                            <Tr p={2} key={itemMovement.id}>
                                                <Td>
                                                    {
                                                        itemMovement
                                                            .production_item
                                                            ?.name
                                                    }
                                                </Td>
                                                <Td>{itemMovement.stock}</Td>
                                                <Td>
                                                    {
                                                        itemMovement
                                                            ?.production_item
                                                            ?.magnitude?.name
                                                    }
                                                </Td>
                                                <Td>
                                                    <CustomNumberInput
                                                        valueForm={
                                                            itemMovement.cost ??
                                                            0
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        name="cost"
                                                        placeholder="Ingresar costo"
                                                        precision={2}
                                                        index={index}
                                                        isReadOnly={true}
                                                    />
                                                </Td>
                                                <Td>
                                                    <CustomNumberInput
                                                        valueForm={
                                                            itemMovement.quantity
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        name="quantity"
                                                        placeholder="Ingresar cant."
                                                        precision={2}
                                                        index={index}
                                                        max={itemMovement.stock}
                                                    />
                                                    <Text
                                                        fontSize={'xs'}
                                                        color="red.500"
                                                    >
                                                        {
                                                            errorItems?.[index]
                                                                ?.quantity
                                                        }
                                                    </Text>
                                                </Td>

                                                <Td>
                                                    <Icon
                                                        onClick={() =>
                                                            handleDelete(index)
                                                        }
                                                        cursor="pointer"
                                                        fill={colors.error}
                                                        as={Close}
                                                    />
                                                </Td>
                                            </Tr>
                                        )
                                    )}
                                </Tbody>
                                {productionItems.length === 0 && (
                                    <TableCaption>
                                        No existen articulos
                                    </TableCaption>
                                )}
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
                <Box
                    w={{
                        base: 'full',
                        md: '30%'
                    }}
                >
                    <Reason
                        handleChange={e => {
                            setForm({
                                ...form,
                                reason: e.target.value
                            })
                        }}
                    />
                </Box>
            </Box>
            <Box mt={3} display={'flex'} justifyContent={'end'}>
                <Button
                    variant="alo-gray:lg-md"
                    bg="white"
                    color="alo-gray"
                    onClick={onClose}
                    disabled={isLoading}
                    type="button"
                >
                    Cancelar
                </Button>

                <Button
                    variant="alo-green:lg-md"
                    ml={3}
                    type="submit"
                    isLoading={isLoading}
                    onClick={handleSubmit}
                >
                    Guardar
                </Button>
            </Box>
        </>
    )
}
