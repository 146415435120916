import { Box, Flex, Icon, IconButton, Text } from '@chakra-ui/react'
import Description from '../../../../../components/Icon/Description'
import colors from '../../../../../config/theme/colors'
import Close from '../../../../../components/Icon/Close'
import { MenuItem as MenuItemInterface } from '../../../../../context/MenuContext'
import ModalEditItem from '../../../modals/ModalEditItem'
import { useState } from 'react'
import useOrderByTable from '../../../hooks/useOrderByTable'
import useManagerPermission from '../../../hooks/useManagerPermission'

interface MenuItemProps {
    menuItem: MenuItemInterface
    onDelete: () => void
    onClick: () => void
}

export default function MenuItem({ menuItem, onDelete }: MenuItemProps) {
    const { isModeEdition } = useOrderByTable()
    const [isOpen, setIsOpen] = useState(false)
    const { canWaiterOrDealarEdit } = useManagerPermission()
    function clickHandler(ev: any) {
        const target = ev.target as HTMLDivElement
        if (target.classList.contains('close')) return
        setIsOpen(true)
    }

    return (
        <>
            <ModalEditItem
                isOpen={isOpen}
                data={{
                    hash: menuItem.hash,
                    itemId: menuItem.itemId,
                    categoryItemId: menuItem.categoryItemId,
                    name: menuItem.name,
                    quantity: menuItem.quantity,
                    description: menuItem.description,
                    unitPrice: menuItem.price
                }}
                onClose={() => setIsOpen(false)}
                onOpen={() => setIsOpen(true)}
            />
            <Flex
                onClick={clickHandler}
                justifyContent="space-between"
                align="center"
                role="group"
                fontSize="lg"
                px={{ base: 3, lg: 6 }}
                py={0.5}
                cursor="pointer"
                pointerEvents={isModeEdition ? 'auto' : 'none'}
                w="full"
                _hover={{
                    bg: 'blackAlpha.50'
                }}
            >
                <Text
                    textAlign="left"
                    fontSize={{
                        xl: '16px',
                        lg: '14px'
                    }}
                >
                    <Box minW="6" as="span" display="inline-block">
                        {menuItem.quantity}
                    </Box>
                    {menuItem.name}
                    {menuItem.description && (
                        <Icon
                            as={Description}
                            fill={colors['gray-2']}
                            fontSize="18px"
                            ml={2}
                        />
                    )}
                </Text>
                <Flex align="center">
                    <Text
                        textAlign="right"
                        fontSize={{ xl: '16px', lg: '14px' }}
                    >
                        S/{(menuItem.price * menuItem.quantity).toFixed(2)}
                    </Text>
                    {canWaiterOrDealarEdit(menuItem.itemId) && (
                        <IconButton
                            icon={<Close fill={colors.secondary} />}
                            aria-label="Eliminar"
                            colorScheme="gray"
                            size="sm"
                            fontSize={{
                                xl: 18,
                                lg: 15
                            }}
                            className="close"
                            cursor="pointer"
                            ml={2}
                            mr={{
                                base: 0,
                                lg: -4
                            }}
                            onClick={onDelete}
                        />
                    )}
                </Flex>
            </Flex>
        </>
    )
}
