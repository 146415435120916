import { Icon } from '@chakra-ui/react'
import Pencil from '../../../components/Icon/Pencil'
import Colors from '../../../config/colors'
import useItem from './useItem'
import Item from '../../../models/item'

export default function Edition({ item }: { item: Item }) {
    const openModal = useItem(item)

    return (
        <Icon
            fill={Colors.INFO}
            as={Pencil}
            marginRight={2}
            cursor="pointer"
            onClick={openModal}
        />
    )
}
