import useSubmit from '../../../hooks/useSubmit'
import modal from '../../../lib/modal'
import ProductionItemCategory from '../../../models/production_item_category'
import {
    formCategory,
    listProdItemCategories,
    PATH_ENDPOINT_CATEGORY
} from '../storage'
import FormCategory from './FormCategory'

export default function useCategory(category?: ProductionItemCategory) {
    const { validation, observer, submit } = useSubmit(formCategory, {
        path: category
            ? `${PATH_ENDPOINT_CATEGORY}/${category.id}`
            : PATH_ENDPOINT_CATEGORY,
        method: category ? 'put' : 'post',
        done: () => listProdItemCategories.load()
    })

    validation(v => {
        v.name.isEmpty('Es requerido el nombre')
    })

    observer<any>(f => ({
        name: f.name
    }))

    function openModal() {
        if (category) {
            formCategory.store({
                id: category.id.toString() || '',
                name: category.name
            })
            formCategory.init(formCategory.store)
        } else {
            formCategory.init()
        }
        modal(FormCategory, {
            title: category ? 'Editar categoría' : 'Nueva categoría',
            onSubmit: submit
        })
    }

    return openModal
}
