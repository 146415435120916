import { Checkbox, Flex } from '@chakra-ui/react'
import { ReactNode } from 'react'
import Colors from '../../../../config/colors'

export default function TypeField({
    handleOnChange,
    documents
}: {
    handleOnChange: (e: any) => void
    documents: string[]
}) {
    return (
        <Flex mb={4} gridGap={2}>
            <Type
                value="pdf"
                isChecked={documents.includes('pdf')}
                handleOnChange={handleOnChange}
            >
                PDF
            </Type>
            <Type
                value="xml"
                isChecked={documents.includes('xml')}
                handleOnChange={handleOnChange}
            >
                XML
            </Type>
            <Type
                value="zip"
                isChecked={documents.includes('zip')}
                handleOnChange={handleOnChange}
            >
                ZIP
            </Type>
        </Flex>
    )
}

function Type({
    children,
    value,
    handleOnChange,
    isChecked
}: {
    children: ReactNode
    value: string
    handleOnChange: (e: any) => void
    isChecked: boolean
}) {
    return (
        <Checkbox
            border="1px"
            px="4"
            py="2"
            borderColor={'gray.300'}
            w="50%"
            onChange={handleOnChange}
            isChecked={isChecked}
            value={value}
            bg={'white'}
            _checked={{ bg: Colors.INFO_LIGHT, borderColor: Colors.INFO }}
        >
            {children}
        </Checkbox>
    )
}
