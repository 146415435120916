import Routes from './Routes'
import { Box } from '@chakra-ui/react'
import { ModalProvider } from '../lib/modal'
import { InitialProvider } from '../context/InitialContext'
import { useEffect } from 'react'
// import './app.css'
import { OverlapLoaderProvider } from '../context/OverlapLoaderContext'
import { DeliveryProvider } from '../context/DeliveryContext'
import { InfoDialogProvider } from '../context/InfoDialogContext'

function App() {
    useEffect(() => {
        document.oncontextmenu = () => false
        if (!localStorage.getItem('all_sales')) {
            localStorage.setItem('all_sales', '0')
        }
    }, [])

    return (
        <InitialProvider>
            <div className="App">
                <InfoDialogProvider>
                    <OverlapLoaderProvider>
                        <ModalProvider />
                        <Box
                            w="full"
                            top="0"
                            position="relative"
                            style={{
                                minHeight: '100vh'
                            }}
                        >
                            <DeliveryProvider>
                                <Routes />
                            </DeliveryProvider>
                        </Box>
                    </OverlapLoaderProvider>
                </InfoDialogProvider>
            </div>
        </InitialProvider>
    )
}

export default App
