import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import getInformationForWaiter from '../../../utilities/getInformationForWaiter'
import { TableDataJoinTable } from '../types'

interface TableItemProps {
    table: TableDataJoinTable
    isActive: boolean
    onClick: () => void
}

export default function TableItem(props: TableItemProps) {
    const bgIsFree = props.table.is_free ? 'gray-3' : 'primary'
    const block = props.table.order_items.length > 0
    return (
        <Box
            cursor={block ? 'not-allowed' : 'pointer'}
            borderRadius="lg"
            h="140px"
            bg={props.isActive ? 'orange' : bgIsFree}
            p="4"
            title={block ? 'Tiene una orden' : undefined}
            color="white"
            textAlign="center"
            onClick={block ? undefined : props.onClick}
        >
            <Heading size="md">{props.table.name}</Heading>
            <Flex justify="center" mb={1}>
                {props.table.join_tables.map(jt => (
                    <Text
                        fontSize="xs"
                        key={jt.id}
                        fontWeight="bold"
                        color="gray.100"
                        mx={1}
                    >
                        {jt.to_table.name}
                    </Text>
                ))}
            </Flex>
            <Text fontSize="sm">Mozo:</Text>
            {props.table.waiter ? (
                <Text fontWeight="bold" fontSize="md">
                    {getInformationForWaiter(
                        props.table.waiter.firstname,
                        props.table.waiter.lastname
                    )}
                </Text>
            ) : (
                '-'
            )}
        </Box>
    )
}
