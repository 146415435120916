import { Box, Flex, ModalCloseButton, Text, useToast } from '@chakra-ui/react'
import { useGet } from '../../../../hooks/useHttp'
import { storagePage } from '../../storage'
import { CommandHistoryData } from '../../types'
import CommandCard from './CommandCard'

export default function CommandHistory() {
    const order_id = storagePage.table?.order.id
    const toast = useToast()
    const defaultData = {
        commands: []
    }

    const { data, error } = useGet<CommandHistoryData>(
        `/api/commands/order/${order_id}`,
        defaultData
    )
    if (error) {
        toast({
            title: error.message,
            position: 'top',
            status: 'error',
            isClosable: true
        })
    }

    const commandHistory = data.result

    //Ordenando comandas desde la más reciente
    const commands = commandHistory.commands.sort((a, b) => b.id - a.id)

    return (
        <Box>
            <ModalCloseButton />
            <Text
                fontSize={'xl'}
                my="4"
                fontWeight={'bold'}
                align={{ base: 'center', md: 'left' }}
            >
                Historial de comandas
            </Text>
            <Flex
                justifyContent="space-between"
                mb="4"
                direction={{ base: 'column', md: 'row' }}
            >
                <Text as={'b'}>
                    Pedido N°{' '}
                    {commandHistory.order?.id.toString().padStart(5, '0')}
                </Text>
                <Flex justifyContent="space-between" gridGap={{ md: 20 }}>
                    <Text>
                        Mesa:{' '}
                        <Text color="dark-2" as={'b'}>
                            {commandHistory.order?.table.name}
                        </Text>
                    </Text>
                    <Text>
                        Mozo:{' '}
                        <Text color="dark-2" as={'b'}>
                            {commandHistory.order?.user.firstname}{' '}
                            {commandHistory.order?.user.lastname}
                        </Text>
                    </Text>
                </Flex>
            </Flex>
            <Flex direction="column" gridGap={4}>
                {commands.map((command, i) => (
                    <CommandCard key={i} command={command} />
                ))}
            </Flex>
        </Box>
    )
}
