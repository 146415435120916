import { Box, Heading } from '@chakra-ui/react'
import { ReactNode } from 'react'

export interface CardContainerProps {
    title: string
    children: ReactNode
}

export default function CardContainer({ title, children }: CardContainerProps) {
    return (
        <Box borderRadius="lg" bg="blackAlpha.50" p={4} boxShadow="base">
            <Heading size="sm" textTransform="uppercase" mb={3}>
                {title}
            </Heading>
            {children}
        </Box>
    )
}
