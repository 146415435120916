import CheckInContent from './CheckInContent'
import OrderContext from './context/CheckInContext'

import useCheckin from './useCheckIn'

export default function Orders() {
    const checkInData = useCheckin()

    return (
        <OrderContext.Provider value={checkInData}>
            <CheckInContent />
        </OrderContext.Provider>
    )
}
