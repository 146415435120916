import { Button, HStack } from '@chakra-ui/react'
import Zone from '../../models/zone'
import ModalJoinTables from './Modals/ModalJoinTables'
import ModalSeparateTables from './Modals/ModalSeparateTables'

interface JoinTablesOptionsProps {
    zones: Zone[]
    onLoad: (zoneId: number) => void
}

export default function JoinTablesOptions({
    zones,
    onLoad
}: JoinTablesOptionsProps) {
    return (
        <HStack
            bg="gray.100"
            justify="center"
            p={4}
            position="fixed"
            width="full"
            left={0}
            bottom={0}
            spacing={4}
        >
            <ModalJoinTables
                zones={zones}
                onDone={onLoad}
                element={onOpen => (
                    <Button
                        onClick={onOpen}
                        fontSize="md"
                        variant="alo-blue:lg-md"
                    >
                        Unir Mesas
                    </Button>
                )}
            />
            <ModalSeparateTables
                zones={zones}
                onDone={onLoad}
                element={onOpen => (
                    <Button
                        onClick={onOpen}
                        fontSize="md"
                        variant="alo-red:lg-md"
                    >
                        Separar Mesas
                    </Button>
                )}
            />
        </HStack>
    )
}
