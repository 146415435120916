import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import colors from '../../../../../config/theme/colors'

export default function Total({ total }: { total: string }) {
    return (
        <Box>
            <Divider borderStyle="dashed" borderColor={colors.dark} />
            <Flex justifyContent="space-between" mt={3}>
                <Text fontWeight="bold">Total</Text>
                <Text>S/{total}</Text>
            </Flex>
        </Box>
    )
}
