import { Flex, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import useMenu from '../../../../../../hooks/useMenu'
import useOrderByTable from '../../../../hooks/useOrderByTable'
import useParamsQuickSaleBy from '../../../../hooks/useParamsQuickSaleBy'
import usePreOrder from '../../../../hooks/usePreOrder'
import ModalBookings from '../../../../modals/ModalBookings'
import ItemSelected from './ItemSelected'

export default function Booking() {
    const { push } = useHistory()
    const { init } = useMenu()
    const { by, tableId } = useParamsQuickSaleBy()
    const { orderId } = useOrderByTable()
    const [isBookingOpen, setIsBookingOpen] = useState(false)
    const { booking, setBooking, setPreOrders } = usePreOrder()

    function closeHandler() {
        setBooking(null)
        setPreOrders([])
        init([])
        push({
            search: tableId ? `table_id=${tableId}` : ''
        })
    }

    /**
     * Si existe una orden (edición) no será posible cambiar la reserva
     */
    const isOrderAlready = !!orderId

    const name = booking?.agency
        ? `${booking.agency.name} - ${booking.agency_guide?.name}`
        : booking?.driver?.name || booking?.client_name || '-'

    return (
        <>
            {!isOrderAlready && (
                <ModalBookings
                    isOpen={isBookingOpen}
                    onClose={() => setIsBookingOpen(false)}
                />
            )}
            <Flex gap={2} align="center">
                <Text>Reserva</Text>
                {!booking && (
                    <Text
                        as="b"
                        color="dark-2"
                        cursor="pointer"
                        decoration={isOrderAlready ? 'none' : 'underline'}
                        pointerEvents={isOrderAlready ? 'none' : 'auto'}
                        onClick={() => setIsBookingOpen(true)}
                    >
                        {by === 'tables' ? 'Sin ' : 'Seleccionar'} reserva
                    </Text>
                )}
                {booking && (
                    <ItemSelected
                        onClick={() => setIsBookingOpen(true)}
                        onRemove={closeHandler}
                        enableClose={!isOrderAlready}
                    >
                        {name}
                    </ItemSelected>
                )}
            </Flex>
        </>
    )
}
