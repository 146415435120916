import { Button, Flex, Icon, Text } from '@chakra-ui/react'
import CircleCheck from '../../../components/Icon/CircleCheck'
import { close, states } from '../../../lib/modal'
import { formOpenCashbox } from '../storage'

export default function ModalAreYouSure() {
    const { cashbox_name } = formOpenCashbox.store()

    return (
        <>
            <Flex justifyContent="center" mb={6}>
                <Icon as={CircleCheck} fill="success" fontSize="5xl" />
            </Flex>
            <Text mb={10} textAlign="center" fontSize="xl">
                ¿Está seguro de aperturar la caja <b>{cashbox_name}</b>?
            </Text>
            <Flex
                justifyContent="space-between"
                alignItems="center"
                flexDirection={{ base: 'column', md: 'row' }}
                gridGap={2}
            >
                <Button
                    variant="alo-gray:lg-md"
                    bg="transparent"
                    color="alo-gray"
                    w="40"
                    onClick={close}
                >
                    Cancelar
                </Button>
                <Button
                    variant="alo-green:lg-md"
                    type="submit"
                    w="40"
                    isLoading={states.isLoading}
                >
                    Sí, confirmar
                </Button>
            </Flex>
        </>
    )
}
