import { Icon } from '@chakra-ui/react'
import { render } from 'redity'
import Trash from '../../../components/Icon/Trash'
import Colors from '../../../config/colors'
import { Keys } from '../constants'
import { storagePage } from '../storage'

export default function Remove({ index }: { index: number }) {
    //const openModal = useForm(printerMachine)
    function removeItem() {
        storagePage.selectedItems.splice(index, 1)
        render(Keys.PRODUCTS_SECTION)
        render(Keys.MAIN)
    }

    return (
        <Icon
            fill={Colors.ERROR}
            as={Trash}
            cursor="pointer"
            onClick={removeItem}
        />
    )
}
