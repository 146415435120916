import { Badge, Box, Flex, Heading, useMediaQuery } from '@chakra-ui/react'
import MenuUser from '../MenuUser'
import SidebarMenu from './sidebarMenu'

export default function SimpleNav({
    title,
    badge,
    badgeColor = 'dark'
}: SimpleNavProps) {
    const [isSmallScreen] = useMediaQuery('(max-width: 62em)')

    return (
        <Box
            as="nav"
            marginBottom="10"
            color="dark"
            w="full"
            display="flex"
            justifyContent="space-between"
        >
            <Flex w="full" alignItems="center">
                {isSmallScreen && <SidebarMenu></SidebarMenu>}
                <Flex flexDirection={{ base: 'column', md: 'row' }}>
                    <Heading as="h1" fontSize="2xl" color="gray-2">
                        {title}
                    </Heading>
                    <Flex
                        h="fit-content"
                        alignSelf={{ base: 'flex-start', md: 'center' }}
                    >
                        {badge && (
                            <Badge
                                bg={badgeColor}
                                color="white"
                                ml={{ base: 0, md: 4 }}
                            >
                                {badge}
                            </Badge>
                        )}
                    </Flex>
                </Flex>
            </Flex>
            <MenuUser />
        </Box>
    )
}

interface SimpleNavProps {
    title: string
    badge?: string
    badgeColor?: 'error' | 'success' | 'purple' | 'dark' | 'accent'
}
