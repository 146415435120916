import { useToast } from '@chakra-ui/react'
import { FormEvent, useState } from 'react'
import { API_BASE_URL } from '../../../config/credentials'

export default function useModalDownload({
    onDone,
    filename
}: {
    onDone: () => void
    filename: string
}) {
    const [isLoading, setLoading] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const [href, setHref] = useState('')
    const toast = useToast()

    const [documents, setDocuments] = useState<string[]>([])

    const handleOnChange = (e: any) => {
        if (e.target.checked) {
            setDocuments([...documents, e.target.value])
        } else {
            documents.splice(documents.indexOf(e.target.value), 1)
            setDocuments([...documents])
        }
    }

    async function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        setLoading(true)
        for (let i = 0; i < documents.length; i++) {
            await fetch(
                `${API_BASE_URL}/api/downloads/${filename}?type_file=${documents[i]}`
            )
                .then(response => {
                    if (!response.ok) {
                        toast({
                            title: `Ha ocurrido un problema al descargar el ${documents[i]}`,
                            status: 'error',
                            position: 'top'
                        })
                        return
                    }
                    return response.blob()
                })
                .then(blob => {
                    if (blob) {
                        const url = URL.createObjectURL(blob)
                        const anchor = document.createElement('a')
                        anchor.href = url
                        setHref(url)
                        anchor.download = `${filename}.${documents[i]}`
                        document.body.appendChild(anchor)
                        anchor.click()
                        anchor.remove()
                    }
                })
                .catch(error => {
                    toast({
                        title: error,
                        status: 'error',
                        position: 'top'
                    })
                })
        }

        setLoading(false)
        onDone()
        setOpen(false)
    }

    function openModal() {
        setOpen(true)
        setDocuments([])
    }

    return {
        submitHandler,
        isLoading,
        isOpen,
        setOpen,
        openModal,
        href,
        handleOnChange,
        documents
    }
}
