import { Box, Button, Flex, Input, Spinner, Text } from '@chakra-ui/react'
import { useGet } from '../../hooks/useHttp'
import Client from '../../models/client'
import ModalFormClient from '../ModalFormClient'
import ClientItem from './ClientItem'

const TypeIdentificationDocument: Record<number, string> = {
    1: 'DNI',
    2: 'RUC',
    3: 'Carnet de extranjería',
    4: 'Pasaporte',
    5: 'Partida de nacimiento',
    6: 'Otros'
}

export default function BodyContent({
    onSelect,
    onClose
}: {
    onSelect: (client: Client) => void
    onClose: () => void
}) {
    const { data, setQuery, queries, isInitialLoading } = useGet<Client[]>(
        '/api/clients',
        []
    )

    return (
        <>
            <Text fontSize="2xl" fontWeight="bold" textAlign="center" mb="5">
                Seleccionar cliente
            </Text>
            <Input
                w="full"
                autoFocus
                borderRadius="xl"
                mb="4"
                value={queries.search || ''}
                placeholder="🏸 Buscar por nombre o N° de documento"
                onChange={ev => setQuery('search', ev.target.value)}
            />
            <Box maxHeight="350px" overflowY="auto">
                {data.result.map(client => (
                    <ClientItem
                        key={client.id}
                        name={
                            client.type_identification_document_id === 2
                                ? client.business_name
                                : `${client.firstname} ${client.lastname}`
                        }
                        document={`${
                            TypeIdentificationDocument[
                                client.type_identification_document_id
                            ]
                        }: ${client.number}`}
                        onClick={() => onSelect(client)}
                    />
                ))}
            </Box>
            {isInitialLoading && (
                <Flex justifyContent="center" my={4}>
                    <Spinner />
                </Flex>
            )}
            <Flex justifyContent="space-between" mt={14}>
                <Button variant="outline" onClick={onClose}>
                    Cancelar
                </Button>
                <ModalFormClient
                    element={click => (
                        <Button colorScheme="orange" size="md" onClick={click}>
                            Nuevo cliente
                        </Button>
                    )}
                    onDone={onSelect}
                />
            </Flex>
        </>
    )
}
