export default function Phone(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 30 33"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M29.4 16.5C29.4 7.802 22.953.75 15 .75v2.625c6.627 0 12 5.876 12 13.125h2.4z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M.62 8.395c-.116 1.706.27 3.312.802 4.884 1.101 3.252 2.778 6.129 4.81 8.778 2.747 3.573 6.027 6.403 9.87 8.444 1.732.917 3.525 1.626 5.47 1.74 1.335.08 2.426-.383 3.44-1.432.441-.457.895-.967 1.349-1.479.227-.256.455-.512.682-.762 1.004-1.105 1.01-2.45.012-3.547a517.564 517.564 0 00-3.58-3.914c-.979-1.063-2.202-1.057-3.181 0-.297.323-.534.57-.756.804-.336.354-.642.674-1.08 1.17-.202.227-.304.254-.618.127s-.991-.522-1.456-.837c-2.16-1.478-3.972-3.392-5.575-5.546-.795-1.07-1.505-2.215-1.995-3.5-.104-.26-.085-.434.11-.642l.642-.674a69.05 69.05 0 001.182-1.259c1.022-1.124 1.016-2.442-.006-3.573A249.78 249.78 0 009.574 5.9l-.003-.003-.58-.632c-.6-.663-1.199-1.325-1.805-1.974-.979-1.05-2.209-1.057-3.188 0 0 0-1.49 1.553-2.24 2.422C1.01 6.581.695 7.345.62 8.395zM5.972 5.67c-.209-.224-.232-.249-.377-.249-.15 0-.225.077-.362.217l-.072.073c-.186.185-1.636 1.774-1.636 1.774s-.369.41-.484.806c-.114.396-.043 1.35.056 1.893.81 4.43 3.689 8.797 6.243 11.747 2.596 2.997 6.293 5.949 10.692 7.434.558.189 2.02.345 2.468.158.45-.186.883-.633.883-.633l1.266-1.41s.4-.395.4-.637-.112-.398-.262-.563a422.046 422.046 0 01-2.364-2.609l-.026-.03c-.224-.256-.331-.378-.522-.378-.184 0-.367.21-.483.344l-.026.03c-1.517 1.73-2.037 2.182-2.965 2.262-1.518.132-4.465-1.665-7.586-5.126-3.12-3.462-4.625-6.785-4.463-8.013.152-1.158.733-1.776.894-1.947l.025-.027c.113-.124 1.332-1.4 1.332-1.4s.265-.26.265-.41c0-.14-.057-.203-.136-.29l-.018-.02A358.96 358.96 0 006.11 5.819c-.054-.056-.099-.105-.138-.147z"
            />
            <path d="M15 6c5.302 0 9.6 4.701 9.6 10.5h-2.4c0-4.35-3.224-7.875-7.2-7.875V6z" />
        </svg>
    )
}
