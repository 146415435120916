import dayjs from 'dayjs'
import { useMemo } from 'react'

export default function useMonths() {
    const mouths = useMemo(
        () => [
            'Enero', // 0
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre'
        ],
        []
    )
    /**
     * El mes inicia desde el número 0
     */
    const data = useMemo(() => {
        const date = dayjs()
        let mount = date.get('M')
        let year = date.get('y')
        const list: Array<[number, number, string]> = []
        while (list.length < 12) {
            list.push([mount, year, mouths[mount]])
            if (mount === 0) {
                mount = 11
                year--
            } else {
                mount--
            }
        }
        return list
    }, [])

    return data
}
