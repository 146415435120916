import {
    Box,
    Center,
    Flex,
    Icon,
    SimpleGrid,
    Spinner,
    Text
} from '@chakra-ui/react'
import Initial from '../../components/Initial'
import CardContainer from './components/CardContainer'
import ListItems from './components/ListItems'
import ButtonsActions from './components/ButtonsActions'
import { useState } from 'react'
import NTabs from '../../components/NTabs'
import MenuUser from '../../components/MenuUser'
import Navbar from '../../components/Navbar'
import Alert from '../../components/Icon/Alert'
import colors from '../../config/theme/colors'
import useCategoryCollection from './hooks/useCategoryCollection'

export default function OrderStatus() {
    const [bookingId, setBookingId] = useState(0)
    const { categoriesCollection, reload, isLoading, hasCommandGroup } =
        useCategoryCollection(bookingId)

    return (
        <Initial>
            <Navbar component={<NTabs />}>
                <Box px="5" py="1">
                    <MenuUser />
                </Box>
            </Navbar>
            <Box p={[3, 3, 4, 6]}>
                <Flex justify="flex-end" mb={6} w="full">
                    <ButtonsActions
                        bookingId={bookingId}
                        onBookingId={setBookingId}
                        hasGroupCommand={hasCommandGroup}
                        onLoad={reload}
                    />
                </Flex>
                {categoriesCollection.length > 0 && (
                    <SimpleGrid columns={[1, 2, 3, 4]} spacing={6}>
                        {categoriesCollection.map(categoryCollection => (
                            <CardContainer
                                key={categoryCollection.category_item_id}
                                title={categoryCollection.category_name}
                            >
                                <ListItems
                                    items={categoryCollection.items}
                                    onLoad={reload}
                                />
                            </CardContainer>
                        ))}
                    </SimpleGrid>
                )}
                {categoriesCollection.length === 0 &&
                bookingId &&
                !isLoading ? (
                    <Center py={10}>
                        <Box textAlign="center">
                            <Icon
                                as={Alert}
                                fill={colors.orange}
                                fontSize="4xl"
                            />
                            <Text>Sin pedidos registrados</Text>
                        </Box>
                    </Center>
                ) : (
                    ''
                )}
                {!bookingId && (
                    <Center py={10}>
                        <Box textAlign="center">
                            <Icon
                                as={Alert}
                                fill={colors['gray-3']}
                                fontSize="4xl"
                            />
                            <Text>Seleccione una reserva</Text>
                        </Box>
                    </Center>
                )}
                {isLoading && (
                    <Center py={10}>
                        <Spinner />
                    </Center>
                )}
            </Box>
        </Initial>
    )
}
