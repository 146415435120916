import Sidebar from '../../components/Sidebar'
import { Tr, Td, Icon, Text, Badge } from '@chakra-ui/react'
import List from '../../lib/list'
import { controlList } from './storage'
import useInitial from './useInitial'
import dayjs from 'dayjs'
import CircleClose from '../../components/Icon/CircleClose'
import CircleCheck from '../../components/Icon/CircleCheck'
import Filters from './Filters'
import Nav from './Nav'
import TableActions from './TableActions'
import { TypeIdentificationDocumentByCode } from '../../utilities/type_identification_document'

export default function Statement() {
    useInitial()

    const TypeVoucher: Record<number, string> = {
        1: 'Boleta',
        2: 'Factura',
        3: 'Nota de crédito'
    }

    return (
        <Sidebar>
            <Nav />
            <Filters />
            <List
                control={controlList}
                tbody={(sunatVoucher, e) => (
                    <Tr key={e}>
                        <Td>{e}</Td>
                        <Td>
                            {dayjs(sunatVoucher.created_at).format(
                                'DD/MM/YYYY hh:mm a'
                            )}
                        </Td>
                        <Td>
                            {dayjs(sunatVoucher.issue_date).format(
                                'DD/MM/YYYY hh:mm a'
                            )}
                        </Td>
                        <Td>{TypeVoucher[sunatVoucher.type_voucher_id]}</Td>
                        <Td>{sunatVoucher.code}</Td>
                        <Td>
                            <Text>{sunatVoucher.client_name}</Text>
                            <Text fontSize="xs" whiteSpace="nowrap">
                                {
                                    TypeIdentificationDocumentByCode[
                                        parseInt(
                                            sunatVoucher.client_type_document
                                        )
                                    ]
                                }
                                : {sunatVoucher.client_number_document}
                            </Text>
                        </Td>
                        <Td>S/{sunatVoucher.total.toFixed(2)}</Td>
                        <Td>
                            {sunatVoucher?.status === 1 ? (
                                <Badge bg="alo-blue" color="white">
                                    Vigente
                                </Badge>
                            ) : (
                                <Badge bg="error" color="white">
                                    Anulado
                                </Badge>
                            )}
                        </Td>
                        <Td>
                            {sunatVoucher.sent ? (
                                <Icon as={CircleCheck} fill="success" />
                            ) : (
                                <Icon as={CircleClose} fill="error" />
                            )}
                        </Td>
                        <TableActions sunatVoucher={sunatVoucher} />
                    </Tr>
                )}
            />
        </Sidebar>
    )
}
