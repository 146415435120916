import { SVGProps } from 'react'

const Assginment = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 23 26"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.025 3H20.25C21.625 3 22.75 4.125 22.75 5.5V23C22.75 24.375 21.625 25.5 20.25 25.5H2.75C2.575 25.5 2.4125 25.4875 2.25 25.4625C1.7625 25.3625 1.325 25.1125 0.9875 24.775C0.7625 24.5375 0.575 24.275 0.45 23.975C0.325 23.675 0.25 23.3375 0.25 23V5.5C0.25 5.15 0.325 4.825 0.45 4.5375C0.575 4.2375 0.7625 3.9625 0.9875 3.7375C1.325 3.4 1.7625 3.15 2.25 3.05C2.4125 3.0125 2.575 3 2.75 3H7.975C8.5 1.55 9.875 0.5 11.5 0.5C13.125 0.5 14.5 1.55 15.025 3ZM5.25 10.5V8H17.75V10.5H5.25ZM17.75 15.5V13H5.25V15.5H17.75ZM14 18H5.25V20.5H14V18ZM11.5 2.6875C12.0125 2.6875 12.4375 3.1125 12.4375 3.625C12.4375 4.1375 12.0125 4.5625 11.5 4.5625C10.9875 4.5625 10.5625 4.1375 10.5625 3.625C10.5625 3.1125 10.9875 2.6875 11.5 2.6875ZM2.75 23H20.25V5.5H2.75V23Z"
        />
    </svg>
)

export default Assginment
