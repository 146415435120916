import { Button } from '@chakra-ui/react'
import { JSXElementConstructor, ReactElement, ReactNode } from 'react'

export default function CustomButton({
    children,
    leftIcon,
    onClick,
    isLoading = false,
    isDisabled = false
}: CustomButtonProps) {
    return (
        <Button
            color="orange"
            border="2px solid orange"
            bg="white"
            borderRadius="md"
            cursor="pointer"
            w="auto"
            minW="fit-content"
            h="40px"
            leftIcon={leftIcon}
            onClick={onClick}
            isLoading={isLoading}
            isDisabled={isDisabled}
            _hover={{}}
        >
            {children}
        </Button>
    )
}

interface CustomButtonProps {
    children: ReactNode
    leftIcon?: ReactElement<any, JSXElementConstructor<any>>
    onClick?: () => void
    isLoading?: boolean
    isDisabled?: boolean
}
