import {
    Box,
    Button,
    Flex,
    Grid,
    IconButton,
    Input,
    Text
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { ChangeEvent } from 'react'
import Add from '../../../../components/Icon/Add'
import { render, useRender } from 'redity'
import Close from '../../../../components/Icon/Close'
import NumberField from '../../../../components/NumberField'
import colors from '../../../../config/theme/colors'
import useMainContent from '../../hooks/useMainContent'
import { storagePage } from '../../storage'
import { Keys } from '../../constants'

export default function CreditPayments() {
    useRender(Keys.TABLE_CREDITS)

    const { total, fees, setFees } = useMainContent()

    storagePage.creditFees = fees

    const changeHandler = (
        index: number,
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target
        const list = [...fees]
        list[index][name] = value
        setFees(list)
        render(Keys.RESULT_PAY)
        render(Keys.DETAILS)
    }

    const addFee = () => {
        setFees([
            ...fees,
            {
                //due_date: dayjs().add(30, 'day').format('YYYY-MM-DD'),
                amount: (total / (fees.length + 1)).toFixed(2)
            }
        ])
        //Actualiza los montos al agregar una cuota
        fees.map(
            (fee: any) => (fee.amount = (total / (fees.length + 1)).toFixed(2))
        )
        render(Keys.RESULT_PAY)
        render(Keys.DETAILS)
    }

    const deleteHandler = (index: number) => {
        const list = [...fees]
        list.splice(index, 1)
        setFees(list)
        //Actualiza los montos al eliminar una cuota
        fees.map(
            (fee: any) => (fee.amount = (total / (fees.length - 1)).toFixed(2))
        )
        render(Keys.RESULT_PAY)
        render(Keys.DETAILS)
    }

    return (
        <Box border="1px" mt={4} p={4} borderRadius={6} borderColor="gray.200">
            <Grid templateColumns="11fr 11fr 1fr" gap={3}>
                <Text>Fecha de vencimiento</Text>
                <Text>Monto</Text>
            </Grid>
            {storagePage.type != 'free' ? (
                <>
                    {fees.map((fee: any, i: number) => (
                        <Flex key={i} gridGap={3} mt={3}>
                            <Input
                                name="due_date"
                                type="date"
                                min={dayjs().add(1, 'day').format('YYYY-MM-DD')}
                                value={fee.due_date}
                                onChange={e => changeHandler(i, e)}
                            />
                            <NumberField
                                name="amount"
                                value={fee.amount}
                                onChange={(e: any) => changeHandler(i, e)}
                            />
                            {fees.length > 1 && (
                                <IconButton
                                    alignSelf="center"
                                    icon={<Close fill="white" />}
                                    aria-label="pencil"
                                    size="xs"
                                    bg="error"
                                    onClick={() => deleteHandler(i)}
                                />
                            )}
                        </Flex>
                    ))}
                    <Button
                        mt={4}
                        size="sm"
                        variant="ghost"
                        color={colors.turquoise}
                        leftIcon={
                            <Add
                                fill={colors.turquoise}
                                height="1.5em"
                                width="1.5em"
                            />
                        }
                        onClick={() => addFee()}
                        _focus={{}}
                    >
                        Agregar cuota
                    </Button>
                </>
            ) : (
                // Se muestra cuando la venta es por cortesía
                <Flex mt={3} justify="center">
                    --
                </Flex>
            )}
        </Box>
    )
}
