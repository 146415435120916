import { FormHelperText } from '@chakra-ui/react'
import Colors from '../../config/colors'

export default function ErrorHelper({ helper }: { helper?: string }) {
    return (
        <>
            {helper && (
                <FormHelperText color={Colors.ERROR}>{helper}</FormHelperText>
            )}
        </>
    )
}
