import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { post } from '../../../lib/http'
import getPrinterConfig from '../../../utilities/getPrinterConfig'
import useOrderByTable from './useOrderByTable'

export default function usePrecount() {
    const toast = useToast()
    const { orderId } = useOrderByTable()
    const [isLoading, setIsLoading] = useState(false)

    /**
     * Imprime la precuenta
     */
    async function print() {
        const printerConfig = getPrinterConfig()

        if (printerConfig) {
            setIsLoading(true)
            const res = await post('/api/v2/printers/print_pre_account', {
                printer_id: Number(printerConfig.printer_id),
                order_id: orderId
            })

            if (res.error) {
                toast({
                    title: res.error.message,
                    position: 'top',
                    status: 'error'
                })
            } else {
                toast({
                    title: 'Precuenta obtenida',
                    position: 'top',
                    status: 'success'
                })
            }
            setIsLoading(false)
        } else {
            toast({
                title: 'Lo sentimos, se requiere configurar la impresora para imprimir las precuentas',
                position: 'top',
                status: 'error'
            })
        }
    }

    return {
        print,
        isLoading
    }
}
