import { ErrorTypes, Exception } from './type'

export default function parseError(exception: any): Exception {
    const result = exception?.response?.data || exception

    let type: ErrorTypes = result?.type || 'network'
    const message = exception?.message || ''

    if (exception.name === 'AbortError') {
        type = 'cancel'
    }

    return {
        message: message as string,
        ...result,
        type
    }
}
