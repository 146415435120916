import React from 'react'
import { Box, Flex, Input, InputProps, Text, Icon } from '@chakra-ui/react'
import useBookings from '../hooks/useBookings'
import { formPreOrder } from '../storage'
import colors from '../../../config/theme/colors'
import Close from '../../../components/Icon/Close'
import { render } from 'redity'
import Keys from '../keys'

export default function InformationPreOrder() {
    const { openModalBookings } = useBookings()

    const { props } = formPreOrder.useField<InputProps>('alias')
    const { booking_id, text_booking } = formPreOrder.store()

    const handleDelete = () => {
        formPreOrder.setValue('text_booking', '')
        formPreOrder.setValue('booking_id', 0)
        formPreOrder.setValue('type', 0)
        formPreOrder.setValue('pre_order_id', 0)
        render(Keys.CONTENT_TOP)
    }

    return (
        <Box padding={2}>
            <Flex gridGap={2} padding={2}>
                <Text>Reserva</Text>
                {booking_id === 0 ? (
                    <Text
                        as="b"
                        color="dark-2"
                        cursor="pointer"
                        decoration={'underline'}
                        onClick={openModalBookings}
                    >
                        Seleccionar reserva
                    </Text>
                ) : (
                    <Flex w="full" gridGap={2} justifyContent={'space-between'}>
                        <Text
                            as="b"
                            color="dark-2"
                            cursor="pointer"
                            decoration={'underline'}
                            onClick={openModalBookings}
                        >
                            {text_booking}
                        </Text>
                        <Icon
                            as={Close}
                            fontSize={{
                                xl: 18,
                                lg: 15
                            }}
                            cursor="pointer"
                            zIndex="1"
                            fill={colors.secondary}
                            onClick={handleDelete}
                        />
                    </Flex>
                )}
            </Flex>
            {formPreOrder.store().type === 1 && (
                <Input {...props} bg="white" placeholder="Nombre del cliente" />
            )}
        </Box>
    )
}
