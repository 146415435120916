import { Button, Flex } from '@chakra-ui/react'
// import colors from '../../../../config/theme/colors'
import FieldQuantity from './components/FieldQuantity'
import FieldSearch from './components/FieldSearch'
import { FormEvent, useState } from 'react'
import useMenu from '../../hooks/useMenu'
import { ItemData } from '../../context/MenuContext'

export default function ItemsSearcher() {
    const { addItem: setMenu } = useMenu()
    const [item, setItem] = useState<ItemData | null>(null)
    const [quantity, setQuantity] = useState('0')

    function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        if (!item) return
        setMenu(item, parseInt(quantity))
        setItem(null)
        setQuantity('')
    }

    return (
        <Flex
            p="2"
            bg="base-3"
            borderBottomWidth={{
                base: 0,
                md: '1px'
            }}
            borderRightWidth={{
                base: 0,
                md: '1px'
            }}
            borderColor="blackAlpha.50"
            position="relative"
            w="full"
            gap={2}
        >
            <FieldSearch item={item} onSelect={setItem} />
            <form onSubmit={submitHandler}>
                <Flex gap={2}>
                    <FieldQuantity
                        item={item}
                        quantity={quantity}
                        setQuantity={setQuantity}
                    />
                    <Button
                        fontSize="xl"
                        colorScheme="blue"
                        isDisabled={
                            !item || quantity === '' || quantity === '0'
                        }
                        type="submit"
                    >
                        +
                    </Button>
                </Flex>
            </form>
        </Flex>
    )
}
