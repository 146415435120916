import { useToast } from '@chakra-ui/react'
import { FormEvent, useState } from 'react'
import useSession from '../../../hooks/useSession'
import { post } from '../../../lib/http'
import val from '../../../lib/val'
import extractDataForm from '../../../utilities/extractDataForm'

interface Form {
    commentary: string
    comission: string
}

export interface UsePayCommission {
    submit: (ev: FormEvent<HTMLFormElement>) => void
    isLoading: boolean
    helpers: Partial<Form>
    setHelpers: React.Dispatch<React.SetStateAction<Partial<Form>>>
}

interface BodyPayCommission {
    booking_id: number
    amount: number
    commentary: string
    user_id: number
}

export default function usePayCommission(bookingId: number) {
    const [helpers, setHelpers] = useState<Partial<Form>>({})
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()
    const { session, isLogged } = useSession()

    async function submit(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const form = extractDataForm(ev.target) as Form
        const v = val(form)
        v.comission.isEmpty('La comisión es requerida')

        const result = v.runtest()

        if (result) {
            setHelpers(result)
            return
        }

        if (!isLogged) return

        const body: BodyPayCommission = {
            booking_id: bookingId,
            amount: Number(form.comission),
            commentary: form.commentary,
            user_id: session?.user.id as number
        }

        setIsLoading(true)
        const { error } = await post('/api/movements/commission', body)
        setIsLoading(false)

        if (error) {
            toast({
                title: error.message,
                position: 'top',
                status: 'error'
            })
            return false
        } else {
            toast({
                title: 'Pago guardado',
                position: 'top',
                status: 'success',
                isClosable: true
            })
            return true
        }
    }

    return {
        submit,
        isLoading,
        helpers,
        setHelpers
    }
}
