import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    VStack
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import Download from '../../../../components/Icon/Download'
import { ContentFormProps } from './types'

export default function ContentForm({ onSubmit, isLoading }: ContentFormProps) {
    const [startDate, setStartDate] = useState(() =>
        dayjs().set('D', 1).format('YYYY-MM-DD')
    )
    const [endDate, setEndDate] = useState(() => {
        const date = new Date()
        date.setMonth(date.getMonth() + 1)
        date.setDate(0)
        return dayjs(date).format('YYYY-MM-DD')
    })

    return (
        <form onSubmit={onSubmit}>
            <VStack w="full" mb={8} spacing={4}>
                <FormControl>
                    <FormLabel>De</FormLabel>
                    <Input
                        type="date"
                        name="startDate"
                        value={startDate}
                        max={endDate}
                        onChange={ev => setStartDate(ev.target.value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Hasta</FormLabel>
                    <Input
                        type="date"
                        name="endDate"
                        min={startDate}
                        value={endDate}
                        onChange={ev => setEndDate(ev.target.value)}
                    />
                </FormControl>
            </VStack>
            <Box textAlign="center">
                <Button
                    variant="alo-gray:md-xl"
                    isLoading={isLoading}
                    isDisabled={!startDate || !endDate}
                    type="submit"
                    rightIcon={<Download fill="white" />}
                >
                    Descargar
                </Button>
            </Box>
        </form>
    )
}
