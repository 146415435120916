import { Box, Flex, SimpleGrid, Spinner, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useGet } from '../../../hooks/useHttp'
import User from '../../../models/user'
import { storagePage } from '../storage'

export default function ContentModalWaiters() {
    const { data, isLoading } = useGet<User[]>('/api/users/3', [])
    const [currentWaiterId, setWaiterId] = useState<number>(0)
    const table = storagePage
    const waiterId = table.table?.waiter?.id

    const newData = []
    for (let i = 0; i < data.result.length; i++) {
        if (data.result[i].id != waiterId) {
            newData.push(data.result[i])
        }
    }

    return (
        <>
            <Text mb="8" color="gray-2">
                Seleccione mozo para realizar pedido
            </Text>
            <input
                type="hidden"
                name="waiter_id"
                value={currentWaiterId.toString()}
            />
            <SimpleGrid columns={[2, 4]} spacing={3}>
                {newData.map(waiter => (
                    <Box
                        key={waiter.id}
                        h="100px"
                        borderRadius="lg"
                        px="4"
                        border="1px solid #D1D5DB"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        fontSize="md"
                        cursor="pointer"
                        textAlign="center"
                        bg={waiter.id === currentWaiterId ? 'alo-green' : ''}
                        color={
                            waiter.id === currentWaiterId ? 'white' : 'gray-3'
                        }
                        onClick={() => setWaiterId(waiter.id)}
                    >
                        {waiter.firstname} {waiter.lastname}
                    </Box>
                ))}
            </SimpleGrid>
            {isLoading && (
                <Flex justifyContent="center" alignItems="center" my={4}>
                    <Spinner />
                </Flex>
            )}
        </>
    )
}
