import { Icon } from '@chakra-ui/react'
import Pencil from '../../components/Icon/Pencil'
import Colors from '../../config/colors'
import PrinterMachine from '../../models/printer_machine'
import useForm from './useForm'

export default function Edition({
    printerMachine
}: {
    printerMachine: PrinterMachine
}) {
    const openModal = useForm(printerMachine)

    return (
        <Icon
            fill={Colors.INFO}
            as={Pencil}
            marginRight={2}
            cursor="pointer"
            onClick={openModal}
        />
    )
}
