import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { post } from '../../../lib/http'

export default function usePrint(
    order_payment_id: number,
    voucher_type: number
) {
    const toast = useToast()
    const [isLoading, setLoading] = useState(false)

    async function printHandler() {
        setLoading(true)
        const printerConfig = JSON.parse(
            localStorage.getItem('printer_config') || 'null'
        ) as { printer_id: string } | null

        if (!printerConfig) {
            toast({
                position: 'top',
                title: 'Se requiere configurar la impresora',
                status: 'error'
            })
            return
        }

        if (voucher_type === 4) {
            //imprime nota de venta
            const { error } = await post('/api/v2/printers/print_sale_note', {
                order_payment_id,
                printer_id: parseInt(printerConfig.printer_id)
            })
            if (error) {
                toast({
                    position: 'top',
                    title:
                        error.type === 'customer' ||
                        error.type === 'development'
                            ? error.message
                            : 'No posible realizar la impresión, vuelva a intentar',
                    status: 'error'
                })
            }
        } else {
            //imprime boleta o factura
            const { error } = await post('/api/v2/printers/print_voucher', {
                order_payment_id,
                printer_id: parseInt(printerConfig.printer_id)
            })
            if (error) {
                toast({
                    position: 'top',
                    title:
                        error.type === 'customer' ||
                        error.type === 'development'
                            ? error.message
                            : 'No posible realizar la impresión, vuelva a intentar',
                    status: 'error'
                })
            }
        }

        setLoading(false)
    }

    return {
        printHandler,
        isLoading
    }
}
