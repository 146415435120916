import DataRouting from '../types/data_routing'

import Users from '../pages/Users'
import Login from '../pages/Login'
import Tables from '../pages/Tables'
import CheckIn from '../pages/CheckIn'
import Cards from '../pages/Cards'
import Social from '../components/Icon/Social'
import Grid from '../components/Icon/Grid'
import RoomService from '../components/Icon/RoomService'
import ConfirmOrder from '../pages/ConfirmOrder'
import QuickSale from '../pages/QuickSale'
import CashRegister from '../pages/CashRegister'
import Sales from '../pages/Sales'
import Statement from '../pages/Statement'

/**
 * No use este. Para crear rutas ve a src/App/Routes
 * Para el Sidebear busquelo en componentes
 * @deprecated go to src/App/Routes.tsx
 */
export default function routing(): DataRouting[] {
    const routes = [
        {
            label: 'Iniciar Sesión',
            Component: Login,
            path: '/',
            mode: 'public'
        },
        {
            label: 'Ordenes',
            Component: QuickSale,
            path: '/orders'
        },
        {
            label: 'Gestión de productos',
            Component: Cards,
            path: '/cards',
            Icon: RoomService,
            mode: 'private'
        },
        {
            label: 'Pago',
            Component: CheckIn,
            path: '/checkin/:order_id',
            mode: 'private'
        },
        {
            label: 'Gestión de usuarios',
            Component: Users,
            path: '/users',
            Icon: Social,
            mode: 'private'
        },
        {
            label: 'Gestión de Mesas',
            Component: Tables,
            path: '/tables',
            Icon: Grid,
            mode: 'private'
        },
        {
            label: 'Confirmar pedido',
            Component: ConfirmOrder,
            path: '/confirm'
        },
        {
            label: '',
            Component: CashRegister,
            path: '/cash_register',
            mode: 'private'
        },
        {
            label: '',
            Component: Sales,
            path: '/sales',
            mode: 'private'
        },
        {
            label: 'Declaración SUNAT',
            Component: Statement,
            path: '/statement',
            mode: 'private',
            Icon: Grid
        }
    ]

    return routes as DataRouting[]
}
