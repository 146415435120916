import { Box, Center, Spinner } from '@chakra-ui/react'
import Filters from './Filters'
import HistoryBoard from './HistoryBoard'
import useOpeningHistory from './useOpeningHistory'

export default function OpeningHistory() {
    const { isLoading } = useOpeningHistory()

    return (
        <>
            {isLoading && (
                <Center h="48">
                    <Spinner />
                </Center>
            )}
            {!isLoading && (
                <Box mb={8}>
                    <Filters />
                    <HistoryBoard />
                </Box>
            )}
        </>
    )
}
