import { useToast } from '@chakra-ui/react'
import useSubmit from '../../../hooks/useSubmit'
import modal from '../../../lib/modal'
import Feature from '../../../models/feature'
import { formFeature, listFeature } from '../storage'
import FormFeature from './FormFeature'

export default function useFeature(feature?: Feature) {
    const toast = useToast()
    const { validation, observer, submit } = useSubmit(formFeature, {
        path: feature ? `/api/features/${feature.id}` : '/api/features',
        method: feature ? 'put' : 'post',
        done: () => listFeature.load(),
        error: e => {
            toast({
                title: e.message,
                position: 'top',
                status: 'error'
            })
        }
    })

    validation(v => {
        v.name.isEmpty('Es requerido el nombre')
    })

    observer<FormSubmit>(f => ({
        name: f.name,
        sub_features: f.sub_features.map((sf: any) => {
            return {
                name: sf.name,
                price: Number(sf.price),
                ...(sf.id && {
                    id: sf.id
                }),
                supply_sub_features: [
                    {
                        supply_id: Number(sf.supply_id),
                        quantity: Number(sf.quantity),
                        ...(sf.supply_sub_feature_id && {
                            id: sf.supply_sub_feature_id
                        })
                    }
                ]
            }
        })
    }))

    function openModal() {
        if (feature) {
            const sub_features = []
            for (const sub_feature of feature.sub_features) {
                const { supply_sub_features, ...rest } = sub_feature
                if (supply_sub_features) {
                    sub_features.push({
                        ...rest,
                        quantity: supply_sub_features[0]?.quantity || 0,
                        supply_id: supply_sub_features[0]?.supply_id || null,
                        supply_sub_feature_id:
                            supply_sub_features[0]?.id || null
                    })
                }
            }
            formFeature.store({
                name: feature.name,
                sub_features: sub_features
            })
            formFeature.init(formFeature.store)
        } else {
            formFeature.init()
        }
        modal(FormFeature, {
            title: feature ? 'Editar Característica' : 'Nueva Característica',
            onSubmit: submit,
            size: '2xl'
        })
    }

    return openModal
}

interface FormSubmit {
    name: string
    sub_features: any[]
}
