import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { post } from '../../../lib/http'

export default function useCommandHistory(commandId: number) {
    const toast = useToast()
    const [loading, setLoading] = useState(false)

    async function printCommand() {
        setLoading(true)
        const { error } = await post<any>(`/api/commands/print`, {
            command_id: commandId
        })
        if (error) {
            toast({
                status: 'error',
                title: error.message,
                position: 'top'
            })
        } else {
            setLoading(false)
            toast({
                status: 'success',
                title: 'Comanda obtenida',
                position: 'top'
            })
        }
    }

    return { printCommand, loading, setLoading }
}
