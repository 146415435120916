import { API_BASE_URL } from '../../config/credentials'
import build from './build'
import headers from './headers'
import { getPathWithoutParams } from './tools'
import { Result } from './type'

/**
 * @deprecated
 */
export function setAuth(token: string) {
    localStorage.setItem('token', token)
    headers.delete('Authorization')
    headers.append('Authorization', `Bearer ${token}`)
}

/**
 * @deprecated
 */
export function removeAuth() {
    headers.delete('Authorization')
    localStorage.removeItem('token')
}

const signals: Map<string, AbortController> = new Map()
const http = build(headers, signals)
http.baseUrl = API_BASE_URL

export async function get<T>(path: string): Promise<Result<T>> {
    signals.get(getPathWithoutParams(path))?.abort()
    return http(path, 'GET')
}

export async function post<T>(
    path: string,
    body: Record<string, any> | FormData
): Promise<Result<T>> {
    return http(path, 'POST', body)
}

export async function put<T>(
    path: string,
    body: Record<string, any> | FormData
): Promise<Result<T>> {
    return http(path, 'PUT', body)
}

export async function del<T>(path: string): Promise<Result<T>> {
    return http(path, 'DELETE')
}

export default http
