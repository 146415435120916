import React, { ChangeEvent } from 'react'
import {
    Button,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalContent,
    ModalBody,
    ModalFooter,
    Text,
    Box,
    FormControl,
    FormLabel,
    Grid,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberDecrementStepper,
    NumberIncrementStepper,
    Select,
    Tooltip
} from '@chakra-ui/react'
import ErrorHelper from '../../../components/ErrorHelper'
import Warehouse from '../../../models/warehouse'
import ProductionItemWarehouse from '../../../models/production_item_warehouse'
import { ItemWarehouse } from '../types'
import { useGet } from '../../../hooks/useHttp'
// import { HttpResponse } from '../../../lib/http/type'

interface Props {
    open: boolean
    onClose: () => void
    handleAddWarehouse: (itemWarehouse: ItemWarehouse) => void
    itemWarehouse: null | ItemWarehouse
}

export default function ModalWarehouses({
    open,
    onClose,
    handleAddWarehouse,
    ...props
}: Props) {
    const finalRef = React.useRef<any>()
    const [warehouse, setWarehouse] = React.useState<Warehouse | null>(null)
    const [helper, setHelper] = React.useState<string>('')
    const [form, setForm] = React.useState<
        Omit<
            ProductionItemWarehouse,
            | 'warehouse_id'
            | 'production_item_id'
            | 'created_at'
            | 'updated_at'
            | 'deleted'
        >
    >({
        id: 0,
        stock: 0,
        max_quantity: 0,
        min_quantity: 0,
        is_enabled: true,
        cost: 0
    })

    const { data } = useGet<Warehouse[]>('/api/warehouses', [])

    React.useEffect(() => {
        if (props.itemWarehouse) {
            setForm({
                id: props.itemWarehouse.id,
                stock: Number(props.itemWarehouse.stock),
                max_quantity: Number(props.itemWarehouse.max_quantity),
                min_quantity: Number(props.itemWarehouse.min_quantity),
                is_enabled: props.itemWarehouse.is_enabled,
                cost: Number(props.itemWarehouse.cost)
            })
            setWarehouse(props.itemWarehouse.warehouse)
        }
    }, [])

    const handleChange = (value: string, name: string) => {
        setForm({
            ...form,
            [name]: value
        })
    }

    const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault()
        ev.stopPropagation()

        if (!warehouse) {
            setHelper('Requerido')
            return
        }

        handleAddWarehouse({
            warehouse,
            ...form
        })
    }

    const hasAsociationWarehouse =
        props.itemWarehouse !== null && props.itemWarehouse?.id !== 0

    return (
        <Modal
            finalFocusRef={finalRef}
            isOpen={open}
            onClose={onClose}
            isCentered={true}
            size={'2xl'}
            closeOnOverlayClick={false}
            autoFocus={false}
        >
            <ModalOverlay />
            <ModalContent px={6} py={4}>
                <ModalHeader>
                    <Text>
                        {props.itemWarehouse ? 'Editar' : 'Agregar'} almacén
                    </Text>
                </ModalHeader>
                <form onSubmit={handleSubmit} noValidate>
                    <ModalBody pb={6}>
                        <Box mb={4}>
                            <FormControl mb={4}>
                                <FormLabel>Almacén</FormLabel>
                                <Tooltip
                                    hasArrow
                                    label="No se puede modificar el almacén"
                                    placement="top"
                                    shouldWrapChildren
                                    isDisabled={!hasAsociationWarehouse}
                                >
                                    <Select
                                        disabled={hasAsociationWarehouse}
                                        value={warehouse?.id ?? ''}
                                        onChange={(
                                            e: ChangeEvent<HTMLSelectElement>
                                        ) => {
                                            const id = Number(e.target.value)
                                            const findWarehouse =
                                                data.result.find(
                                                    _data => _data.id === id
                                                )
                                            if (findWarehouse) {
                                                setWarehouse(findWarehouse)
                                                setHelper('')
                                            }
                                        }}
                                        placeholder="Seleccionar almacén"
                                    >
                                        {data.result.map(warehoouse => (
                                            <option
                                                key={warehoouse.id}
                                                value={warehoouse.id}
                                            >
                                                {warehoouse.name}
                                            </option>
                                        ))}
                                    </Select>
                                </Tooltip>

                                <ErrorHelper helper={helper} />
                            </FormControl>

                            {hasAsociationWarehouse ? null : (
                                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                    <FormControl mb="4">
                                        <FormLabel>Costo</FormLabel>

                                        <CustomNumberInput
                                            name="cost"
                                            valueForm={form.cost}
                                            placeholder="Ingresar su costo"
                                            handleChange={handleChange}
                                            precision={2}
                                        />
                                    </FormControl>
                                    <FormControl mb="4">
                                        <FormLabel>Stock</FormLabel>

                                        <CustomNumberInput
                                            name="stock"
                                            valueForm={form.stock}
                                            placeholder="Ingresar su stock"
                                            handleChange={handleChange}
                                            precision={2}
                                        />
                                    </FormControl>
                                </Grid>
                            )}

                            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                <FormControl mb="4">
                                    <FormLabel>Cantidad mínima</FormLabel>
                                    <CustomNumberInput
                                        name="min_quantity"
                                        valueForm={form.min_quantity}
                                        placeholder="Ingresar su cantidad mínima"
                                        handleChange={handleChange}
                                        precision={2}
                                    />
                                </FormControl>
                                <FormControl mb="4">
                                    <FormLabel>Cantidad máxima</FormLabel>
                                    <CustomNumberInput
                                        name="max_quantity"
                                        valueForm={form.max_quantity}
                                        placeholder="Ingresar su cantidad máxima"
                                        handleChange={handleChange}
                                        precision={2}
                                    />
                                </FormControl>
                            </Grid>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="alo-gray:lg-md"
                            bg="transparent"
                            color="alo-gray"
                            onClick={onClose}
                            type="button"
                        >
                            Cancelar
                        </Button>
                        <Button variant="alo-green:lg-md" ml={3} type="submit">
                            {props.itemWarehouse ? 'Confirmar' : 'Agregar'}
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}

interface NumberInputProps {
    valueForm: string | number
    placeholder: string
    handleChange: (value: string, name: string) => void
    name: string
    precision?: number
}

const CustomNumberInput = ({
    handleChange,
    valueForm,
    placeholder,
    name,
    precision = 0
}: NumberInputProps) => {
    return (
        <NumberInput
            onChange={value => handleChange(value, name)}
            placeholder={placeholder}
            value={valueForm}
            precision={precision}
            min={0}
        >
            <NumberInputField />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
    )
}
