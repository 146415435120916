enum DeliveryMode {
    INMEDIATE,
    SCHEDULED
}

export enum DeliveryStatus {
    CANCELED,
    PENDING,
    IN_PREPARATION,
    ON_THE_WAY,
    DELIVERED,
    CLOSED
}

export enum DeliveryHistoryStatus {
    CANCELED,
    PENDING,
    IN_PREPARATION,
    ON_THE_WAY,
    DELIVERED,
    CLOSED,
    PAID = 100
}

export type DeliveryStatuNames =
    | 'pending'
    | 'in_preparation'
    | 'canceled'
    | 'on_the_way'
    | 'delivered'
    | 'closed'

export default DeliveryMode

export enum PaymentMethod {
    OTHER = -1,
    CASH,
    TRANSFERENCE,
    CARD,
    /**
     * Billetera digital
     */
    YAPE = 10,
    PLIN,
    /**
     * Bancos
     */
    BCP = 100,
    INTERBANK,
    BBVA,
    SCOTIABANK,
    PEDIDOS_YA,
    RAPPI
}
