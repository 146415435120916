import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Thead,
    Tr
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import colors from '../../../../config/theme/colors'
import { TableInfoProps } from './types'

export default function TableInfo({
    isAttented,
    totalCommission,
    date
}: TableInfoProps) {
    return (
        <>
            <Text mb={0.5} fontWeight="bold">
                Información adicional
            </Text>
            <TableContainer borderWidth="1px" borderColor={colors.black}>
                <Table variant="simple" size="sm">
                    <Thead>
                        <Tr>
                            <Td borderColor={colors.black}>Estado</Td>
                            <Td borderColor={colors.black} borderLeftWidth={1}>
                                {isAttented ? 'Atendido' : 'En espera'}
                            </Td>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td borderColor={colors.black}>Comisión Total</Td>
                            <Td borderColor={colors.black} borderLeftWidth={1}>
                                S/{totalCommission.toFixed(2)}
                            </Td>
                        </Tr>
                        <Tr>
                            <Td border={0}>Reserva</Td>
                            <Td
                                borderBottom={0}
                                borderLeftWidth={1}
                                borderColor={colors.black}
                            >
                                {dayjs(date).format('DD/MM/YYYY hh:mm A')}
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    )
}
