import { Tr, Td } from '@chakra-ui/react'
import dayjs from 'dayjs'
import List from '../../../lib/list'
import IBooking from '../../../models/booking'
import { getTextType, listBooking, TYPE_BOOKINGS_VALUE } from '../storage'
import DeleteBooking from './DeleteBooking'
import Edition from './Edition'

function getText(booking: IBooking) {
    if (booking.type === TYPE_BOOKINGS_VALUE.AGENCY) {
        return `${booking.agency?.name ?? ''} - ${
            booking.agency_guide?.name ?? ''
        } `
    }

    if (booking.type === TYPE_BOOKINGS_VALUE.DRIVER) {
        return booking.driver?.name || ''
    }

    return booking.client_name
}

export default function Booking() {
    return (
        <List
            control={listBooking}
            tbody={(booking, e) => (
                <Tr key={e}>
                    <Td textAlign="center" width="28">
                        {e}
                    </Td>
                    <Td textAlign="center">
                        {dayjs(booking.hour).format('hh:mm A')}
                    </Td>
                    <Td textAlign="center">{getText(booking)}</Td>
                    <Td textAlign="center">{getTextType(booking.type)}</Td>
                    <Td textAlign="center">{booking.person_quantity}</Td>
                    <Td textAlign="center">
                        {booking.is_attented ? 'Atendido' : 'No atendido'}
                    </Td>
                    <Td textAlign="center">
                        <Edition booking={booking} />
                        <DeleteBooking booking={booking} />
                    </Td>
                </Tr>
            )}
        />
    )
}
