import {
    Box,
    Button,
    Divider,
    Flex,
    Icon,
    Spinner,
    Text
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import Check from '../../../components/Icon/Check'
import usePreferences from '../hooks/usePreferences'

export default function Preferences() {
    const {
        preferences,
        isLoading,
        clickAcceptHandler,
        valuesPreferences,
        checkedHandler
    } = usePreferences()

    return (
        <>
            <Text fontSize="2xl" fontWeight="bold" textAlign="center" mb="6">
                Preferencias
            </Text>
            <Text fontSize="sm" mb="2">
                Seleccione las preferencias del cliente:
            </Text>
            <Divider />
            {!isLoading && (
                <Box maxH="400px" overflowY="auto" pr="1">
                    <Flex
                        gridColumnGap={3}
                        gridRowGap={3}
                        mt={4}
                        flexWrap="wrap"
                    >
                        {preferences.map(aic => (
                            <PreferenceBox
                                key={aic.id}
                                isChecked={!!valuesPreferences[aic.id]}
                                onChecked={isChecked =>
                                    checkedHandler(isChecked, aic.id)
                                }
                            >
                                {aic.description}
                            </PreferenceBox>
                        ))}
                    </Flex>
                </Box>
            )}
            {preferences.length === 0 && !isLoading && (
                <Text color="gray-3" fontSize="sm" textAlign="center">
                    No existen resultados
                </Text>
            )}
            {isLoading && (
                <Flex justifyContent="center" mt={8}>
                    <Spinner />
                </Flex>
            )}
            <Flex textAlign="center" mt="12" justifyContent="space-between">
                <Button
                    h="40px"
                    borderRadius="xl"
                    flexGrow={1}
                    border="1px solid #434343"
                    color="#434343"
                    bg="white"
                    onClick={clickAcceptHandler}
                    margin={{ base: '0 5px', md: '0 10px' }}
                >
                    Cancelar
                </Button>
                <Button
                    h="40px"
                    variant="accent:lg-lg"
                    flexGrow={1}
                    onClick={clickAcceptHandler}
                    margin="0 5px"
                >
                    Agregar
                </Button>
            </Flex>
        </>
    )
}

function PreferenceBox({
    children,
    isChecked,
    onChecked
}: {
    children: ReactNode
    isChecked: boolean
    onChecked: (isChecked: boolean) => void
}) {
    return (
        <Flex
            borderRadius="3xl"
            px="4"
            py="2"
            onClick={() => onChecked(!isChecked)}
            alignItems="center"
            cursor="pointer"
            border="1px solid"
            borderColor={isChecked ? 'success' : 'gray.300'}
            color={isChecked ? 'success' : 'gray.700'}
        >
            {isChecked && <Icon as={Check} fill="success" mr={3} />}
            {children}
        </Flex>
    )
}
