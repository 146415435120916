import { Button, Flex, Input, Select } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { listPreOrder } from '../storage'
import { useGet } from '../../../hooks/useHttp'
import Booking from '../../../models/booking'
import BookingsModal from '../modals/BookingsModal'

function SelectTourGroup() {
    const date = listPreOrder.getQuery('date')
    const propsSelect = listPreOrder.useFilter('booking_id')
    const { data } = useGet<Booking[]>(
        `/api/bookings?sort=id&date=${date || ''}`,
        []
    )

    const bookingAgencies = data.result
        .filter(booking => booking.agency_id)
        .sort((a, b) => {
            const aName = a.agency?.name || ''
            const bName = b.agency?.name || ''
            if (aName < bName) {
                return -1
            }
            if (aName > bName) {
                return 1
            }
            return 0
        })
    const bookingAgenciesFiltered: Array<Booking & { ids: number[] }> = []
    bookingAgencies.forEach(booking => {
        const bookingAgencyFiltrd = bookingAgenciesFiltered.find(
            _booking =>
                _booking.agency_id === booking.agency_id &&
                _booking.agency_guide_id === booking.agency_guide_id
        )
        if (bookingAgencyFiltrd) {
            bookingAgencyFiltrd.ids.push(booking.id)
            return
        }
        bookingAgenciesFiltered.push({ ...booking, ids: [booking.id] })
    })

    const bookingDrivers = data.result
        .filter(booking => booking.driver_id)
        .sort((a, b) => {
            const aName = a.driver?.name || ''
            const bName = b.driver?.name || ''
            if (aName < bName) {
                return -1
            }
            if (aName > bName) {
                return 1
            }
            return 0
        })
    const bookingIndenpendecies = data.result
        .filter(booking => !booking.driver_id && !booking.agency_id)
        .sort((a, b) => {
            const aName = a.client_name || ''
            const bName = b.client_name || ''
            if (aName < bName) {
                return -1
            }
            if (aName > bName) {
                return 1
            }
            return 0
        })

    return (
        <Select
            {...propsSelect}
            size="md"
            borderRadius="full"
            placeholder="Todos"
            bg="base-4"
            w={['full', 'full', 'auto']}
        >
            <optgroup label="Agencias">
                {bookingAgenciesFiltered.map(booking => (
                    <option
                        key={booking.agency_guide_id}
                        value={booking.ids.join(',')}
                    >
                        {booking.agency?.name} - {booking.agency_guide?.name}
                    </option>
                ))}
                {bookingAgenciesFiltered.length === 0 && (
                    <option disabled>- Ninguno - </option>
                )}
            </optgroup>
            <optgroup label="Guías locales">
                {bookingDrivers.map(booking => (
                    <option key={booking.id} value={booking.id}>
                        {booking.driver?.name}
                    </option>
                ))}
                {bookingDrivers.length === 0 && (
                    <option disabled>- Ninguno - </option>
                )}
            </optgroup>
            <optgroup label="Clientes independientes">
                {bookingIndenpendecies.map(booking => (
                    <option key={booking.id} value={booking.id}>
                        {booking.client_name}
                    </option>
                ))}
                {bookingIndenpendecies.length === 0 && (
                    <option disabled>- Ninguno - </option>
                )}
            </optgroup>
        </Select>
    )
}

export default function HeadTable() {
    const { push } = useHistory()
    const propsDate = listPreOrder.useFilter('date')
    const propsSelect = listPreOrder.useFilter('pre_order_state')

    return (
        <Flex
            justify="space-between"
            align={['auto', 'auto', 'center']}
            my={[0, 0, 4]}
            mb={4}
            direction={['column', 'column', 'row']}
            gridGap={2}
        >
            <Flex w="full" gap={2} flexWrap={['wrap', 'wrap', 'nowrap']}>
                <Input
                    {...propsDate}
                    size="md"
                    borderRadius="full"
                    w={['full', 'full', 'auto']}
                    type="date"
                    bg="base-4"
                />
                <Select
                    {...propsSelect}
                    size="md"
                    borderRadius="full"
                    placeholder="Todos"
                    bg="base-4"
                    w={['full', 'full', 'auto']}
                >
                    <option value="1">En espera</option>
                    <option value="2">Atendido</option>
                </Select>
                <SelectTourGroup />
            </Flex>
            <Flex gap={2}>
                <BookingsModal date={propsDate.value} />
                <Button
                    w={['full', 'full', 'auto']}
                    onClick={() => push('/quick_sale_by/pre_orders')}
                    colorScheme="blue"
                    rounded="full"
                    fontWeight="normal"
                >
                    Nuevo Pre-pedido
                </Button>
            </Flex>
        </Flex>
    )
}
