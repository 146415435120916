import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { get } from '../../../lib/http'
import { AgencyGuide } from '../../../models/agency'

export default function useSelectAgency() {
    const [agencyGuides, setAgencyGuides] = useState<AgencyGuide[]>([])

    const toast = useToast()

    const getAgencyGuide = async (agency_id: number | string) => {
        const { data: dataAgencyGuide, error: errorGetAgencyGuide } = await get<
            AgencyGuide[]
        >(`/api/agencies/agency_guide?sort=name&agency_id=${agency_id}`)
        if (errorGetAgencyGuide) {
            toast({
                title: 'Ocurrió un error al recuperar la información. Volver a intentarlo',
                position: 'top',
                status: 'error'
            })
            return false
        }
        setAgencyGuides(dataAgencyGuide.result || [])

        return true
    }

    return {
        agencyGuides,
        getAgencyGuide,
        setAgencyGuides
    }
}
