import FormEvent from '../../lib/form_event'
import ControlList from '../../lib/list/ControlList'
import Table from '../../models/table'
import Zone from '../../models/zone'

export const listTable = new ControlList<Table>({
    path: '/api/tables',
    theads: [],
    removeLimit: true // true: establece limit a 0, 0 significa traer todos los datos
})

listTable.setQuery('sort', 'name')

export const formTable = new FormEvent({
    name: '',
    zone_id: ''
})

export const listZone = new ControlList<Zone>({
    path: '1>/api/zones',
    theads: [],
    removeLimit: true
})

listZone.setQuery('sort', 'name')

export const formZone = new FormEvent({
    name: ''
})
