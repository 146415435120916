import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Icon,
    Input,
    InputProps,
    Text
} from '@chakra-ui/react'
import { ChangeEvent } from 'react'
import ErrorHelper from '../../../components/ErrorHelper'
import Close from '../../../components/Icon/Close'
import colors from '../../../config/theme/colors'
import { formAgency } from '../storage'

export default function FormAgency() {
    return (
        <>
            <Name />
            <Guides />
        </>
    )
}

function Name() {
    const { props, helper, error } = formAgency.useField<InputProps>('name')
    return (
        <FormControl mb={4}>
            <FormLabel>Nombre</FormLabel>
            <Input
                {...props}
                placeholder="Ingresar el nombre de la agencia"
                autoFocus
                maxLength={180}
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function Guides() {
    const { value, setValue, helper } = formAgency.useValue('agency_guides')

    const handleChange = (
        index: number,
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        value[index][e.target.name] = e.target.value
        setValue([...value])
    }

    const handleDelete = (index: number) => {
        const newFeatures = []
        for (let i = 0; i < value.length; i++) {
            if (i !== index) {
                newFeatures.push(value[i])
            }
        }
        setValue([...newFeatures])
    }

    return (
        <FormControl mb={4}>
            <FormLabel>Guías</FormLabel>
            <Text fontSize="13px" mb="3">
                Agrega nuevos guías
            </Text>
            {value?.map((agency_guide: any, i: number) => (
                <Guide
                    key={i}
                    agency_guide={agency_guide}
                    handleChange={handleChange}
                    index={i}
                    handleDelete={handleDelete}
                />
            ))}
            <ErrorHelper helper={helper} />
            <Box>
                <Button
                    background="#FFF"
                    height="40px"
                    fontSize="14px"
                    color="#4E6BD3"
                    border="1px solid #C5C9D0"
                    borderRadius="10px"
                    fontWeight={500}
                    width="100%"
                    onClick={() =>
                        setValue([
                            ...value,
                            {
                                name: ''
                            }
                        ])
                    }
                >
                    + Agregar guía
                </Button>
            </Box>
            {/* <ErrorHelper helper={helper} /> */}
        </FormControl>
    )
}

function Guide({
    agency_guide,
    handleChange,
    index,
    handleDelete
}: {
    agency_guide: any
    handleChange: any
    index: number
    handleDelete: any
}) {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gridGap="2"
            marginBottom="2"
        >
            <Box width={'95%'}>
                <Input
                    value={agency_guide.name}
                    placeholder="Nombre del guía"
                    maxLength={100}
                    borderRadius="10px"
                    name="name"
                    onChange={e => handleChange(index, e)}
                />
            </Box>
            <Box
                width={'5%'}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Icon
                    onClick={() => handleDelete(index)}
                    fill={colors.error}
                    as={Close}
                    cursor="pointer"
                />
            </Box>
        </Box>
    )
}
