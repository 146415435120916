import {
    Modal,
    ModalBody,
    ModalOverlay,
    ModalContent,
    HStack,
    Center,
    Spinner,
    Fade
} from '@chakra-ui/react'
import useIceCream from './hooks/useIceCream'
import Section1 from './Section1'
import Section2 from './Section2'
import { IceCreamProps } from './types'

export default function IceCream({
    element,
    itemId,
    onDone,
    edition,
    quantity = 1
}: IceCreamProps) {
    const {
        isOpen,
        onOpen,
        onClose,
        counter,
        setCounter,
        setFeature,
        features,
        isLoading,
        item,
        clearFeatures,
        submitHandler,
        delFeature,
        setInformation
    } = useIceCream(itemId, onDone, quantity, edition)

    return (
        <>
            {element(onOpen)}
            <Modal
                isCentered={false}
                size="4xl"
                onClose={onClose}
                isOpen={isOpen}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalBody p="0">
                        {isLoading && (
                            <Center h="96" w="full">
                                <Spinner />
                            </Center>
                        )}
                        <form onSubmit={submitHandler}>
                            {!isLoading && (
                                <Fade in={isOpen}>
                                    <HStack
                                        flexDirection={{
                                            base: 'column',
                                            md: 'row'
                                        }}
                                        align="stretch"
                                    >
                                        <Section1
                                            setFeature={setFeature}
                                            itemFeatures={
                                                item ? item.item_features : []
                                            }
                                            featuresSelected={features}
                                        />
                                        <Section2
                                            basePrice={item?.price || 0}
                                            clearFeatures={clearFeatures}
                                            onClose={onClose}
                                            counter={counter}
                                            setCounter={setCounter}
                                            featuresSelected={features}
                                            delFeature={delFeature}
                                            isEdition={!!edition}
                                            itemName={item?.name || ''}
                                            onInformation={setInformation}
                                        />
                                    </HStack>
                                </Fade>
                            )}
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
