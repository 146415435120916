import { useToast } from '@chakra-ui/react'
import { ChangeEvent, FormEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useOverlapLoader from '../../../hooks/useOverlapLoader'
import useSession from '../../../hooks/useSession'
import { post } from '../../../lib/http'
import val from '../../../lib/val'
import extractDataForm from '../../../utilities/extractDataForm'
import useCashbox from './useCashbox'
import useOrderByTable from './useOrderByTable'

export default function useAnnul(
    sendRequestCancel: boolean,
    onDone: (form: { reason: string }) => void
) {
    const toast = useToast()
    const { orderId } = useOrderByTable()
    const { cashboxUser } = useCashbox()
    const { start, end } = useOverlapLoader()
    const { push } = useHistory()
    const { isLogged, session } = useSession()
    const [helpers, setHelpers] = useState<{ reason?: string }>({})

    async function submit(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const form = extractDataForm<{ reason: string }>(ev.target)

        const v = val(form)
        v.reason.isEmpty('Es requerido ingresar el motivo')

        const result = v.runtest()
        if (result) {
            setHelpers(result)
            return
        }

        onDone(form)

        if (!sendRequestCancel) return

        start('Anulando pedido')

        const { error } = await post(`/api/orders/${orderId}/request/cancel`, {
            cashbox_id: cashboxUser?.cashbox_id as number,
            user_id_cancel:
                isLogged && session !== null ? session.user.id : null,
            comment: form.reason
        })

        end()

        if (error) {
            toast({
                status: 'error',
                position: 'top',
                title: error.message
            })
        } else {
            push('/by_tables')
            toast({
                status: 'success',
                position: 'top',
                title: 'Se ha anulado el pedido',
                isClosable: true
            })
        }
    }

    function changeEvent(
        ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const name = ev.target.name as keyof typeof helpers
        if (helpers[name]) {
            setHelpers({
                ...helpers,
                [name]: ''
            })
        }
    }

    return {
        submit,
        changeEvent,
        helpers
    }
}
