import { Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import PreOrder from '../../../../models/pre_order'
import { styleTd, TYPE_BOOKINGS_VALUE } from '../../storage'

export default function AditionalInformation({
    preOrder
}: {
    preOrder: PreOrder
}) {
    return (
        <>
            <Text fontSize="xl" marginBottom={'0.5'} fontWeight="bold">
                Información adicional
            </Text>
            <table
                style={{
                    width: '100%'
                }}
            >
                <tbody>
                    <tr>
                        <td style={styleTd}>Estado</td>
                        <td style={styleTd}>
                            {preOrder?.pre_order_state === 1
                                ? 'En espera'
                                : 'Atendido'}
                        </td>
                    </tr>
                    <tr>
                        <td style={styleTd}>Guia turistico</td>
                        <td style={styleTd}>
                            {preOrder?.booking.type ===
                            TYPE_BOOKINGS_VALUE.AGENCY
                                ? `${preOrder.booking.agency?.name} - ${preOrder.booking.agency_guide?.name}`
                                : '-'}
                        </td>
                    </tr>
                    <tr>
                        <td style={styleTd}>Reserva</td>
                        <td style={styleTd}>
                            {dayjs(preOrder?.booking.hour).format(
                                'DD/MM/YYYY hh:mm A'
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}
