import { Icon } from '@chakra-ui/react'
import Pencil from '../../../components/Icon/Pencil'
import Colors from '../../../config/colors'
import ProductionItemCategory from '../../../models/production_item_category'
import useCategory from './useCategory'

export default function Edition({
    category
}: {
    category: ProductionItemCategory
}) {
    const openModal = useCategory(category)

    return (
        <Icon
            fill={Colors.INFO}
            as={Pencil}
            marginRight={2}
            cursor="pointer"
            onClick={openModal}
        />
    )
}
