import { Icon } from '@chakra-ui/react'
import { useHistory } from 'react-router'
import Pencil from '../../../../components/Icon/Pencil'
import Colors from '../../../../config/colors'
import colors from '../../../../config/theme/colors'
import PreOrder from '../../../../models/pre_order'

export default function Edition({ preOrder }: { preOrder: PreOrder }) {
    const { push } = useHistory()
    const isAttended = preOrder.pre_order_state === 2
    return (
        <Icon
            as={Pencil}
            marginRight={2}
            fill={isAttended ? colors['gray-3'] : Colors.INFO}
            cursor={isAttended ? 'default' : 'pointer'}
            pointerEvents={isAttended ? 'none' : 'auto'}
            onClick={() =>
                push(`/quick_sale_by/pre_orders?pre_order_id=${preOrder.id}`)
            }
        />
    )
}
