import {
    FormControl,
    FormLabel,
    Input,
    Select,
    InputProps,
    SelectProps
} from '@chakra-ui/react'
import ErrorHelper from '../../../components/ErrorHelper'
import { useGet } from '../../../hooks/useHttp'
import PrinterMachine from '../../../models/printer_machine'
import { formCategory } from '../storage'

export default function FormCategory() {
    return (
        <>
            <Name />
            <Printer />
        </>
    )
}

function Name() {
    const { props, helper, error } = formCategory.useField<InputProps>('name')
    return (
        <FormControl mb={4}>
            <FormLabel>Nombre</FormLabel>
            <Input
                {...props}
                placeholder="Ingresar el nombre de la categoría"
                autoFocus
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function Printer() {
    const { data } = useGet<PrinterMachine[]>(
        '/api/printer_machines?sort=name',
        []
    )

    const { props, helper } = formCategory.useField<SelectProps>('printer_id')
    return (
        <FormControl marginBottom="4">
            <FormLabel>Destino de impresión</FormLabel>
            <Select {...props} placeholder="Seleccionar impresora">
                {data.result.map(d => (
                    <option key={d.id} value={d.id}>
                        {d.name}
                    </option>
                ))}
            </Select>
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}
