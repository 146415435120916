import { Flex, Input } from '@chakra-ui/react'
import Clean from '../../../components/Clean'
import { listProdItemCategories } from '../storage'

export default function FiltersCategory() {
    const { clear, ...props } = listProdItemCategories.useSearch()

    return (
        <Flex>
            <Clean value={props.value} onClick={clear}>
                <Input
                    w="80"
                    placeholder="🏸 Buscar por nombre de categoría"
                    {...props}
                />
            </Clean>
        </Flex>
    )
}
