import { Tr, Td } from '@chakra-ui/react'
import List from '../../../lib/list'
import { listProductionItems } from '../storage'
import Edition from './Edition'
import DeleteItem from './DeleteItem'

export default function Item() {
    return (
        <List
            control={listProductionItems}
            tbody={(item, e) => (
                <Tr key={e}>
                    <Td textAlign="center" width="28">
                        {e}
                    </Td>
                    <Td textAlign="center">{item.name}</Td>
                    <Td textAlign="center">
                        {item.production_item_category?.name}
                    </Td>
                    <Td textAlign="center">{item.magnitude?.name}</Td>
                    <Td textAlign="center">
                        <Edition item={item} />
                        <DeleteItem item={item} />
                    </Td>
                </Tr>
            )}
        />
    )
}
