import { FindItem } from '../components/SectionKadex/types'

export default function controlStock(item: FindItem) {
    const item_movements = item.item_movements.sort((a, b) => {
        if (a.id < b.id) return -1
        if (b.id < a.id) return 1
        return 0
    })

    const initialQuantity =
        item_movements.length > 0
            ? item_movements[0].initial_quantity
            : item.initial_stock
    const input = item_movements
        .filter(iteMovement => iteMovement.marginal_quantity > 0)
        .reduce((n, itemMovement) => itemMovement.marginal_quantity + n, 0)
    const output =
        item_movements
            .filter(iteMovement => iteMovement.marginal_quantity < 0)
            .reduce(
                (n, itemMovement) => itemMovement.marginal_quantity + n,
                0
            ) * -1
    const finalStock =
        item_movements.length > 0
            ? item_movements[item_movements.length - 1].current_quantity
            : item.initial_stock

    return {
        initialQuantity,
        input,
        output,
        finalStock
    }
}
