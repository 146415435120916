import { Icon, Td, Tr, useDisclosure } from '@chakra-ui/react'
import PriceTag from '../../../components/Icon/PriceTag'
import colors from '../../../config/theme/colors'
import { TouristGroup } from '../hooks/useTouristGruops'
import ModalBookings from '../modals/ModalBookings'

export default function Row({
    touristGroup,
    date
}: {
    touristGroup: TouristGroup
    date: string
}) {
    const { isOpen, onClose, onOpen } = useDisclosure()

    return (
        <Tr>
            <Td>{touristGroup.groupTouristName}</Td>
            <Td>{touristGroup.nBookings}</Td>
            <Td>{touristGroup.soldPlates}</Td>
            <Td>{touristGroup.totalSales}</Td>
            <Td>{touristGroup.commission}</Td>
            <Td>
                <ModalBookings
                    isOpen={isOpen}
                    onClose={onClose}
                    bookings={touristGroup.bookings}
                    date={date}
                />
                <Icon
                    as={PriceTag}
                    fill={colors['alo-gray']}
                    cursor={'pointer'}
                    onClick={onOpen}
                />
            </Td>
        </Tr>
    )
}
