import { Icon, Td, Text, Tr } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import CashIcon from '../../../components/Icon/CashIcon'
import colors from '../../../config/theme/colors'
import ModalBooking from '../modals/ModalBooking'
import ModalPay from '../modals/ModalPay'
import { RowProps } from './types'

export default function Row({ booking, onReload }: RowProps) {
    const [isOpenBooking, setIsOpenBooking] = useState(false)
    const [isOpenPay, setIsOpenPay] = useState(false)

    const totalItems = booking.orders
        .filter(order => order.order_state !== 2)
        .reduce(
            (n, order) =>
                n +
                order.order_items.reduce(
                    (_n, orderItem) => _n + orderItem.quantity,
                    0
                ),
            0
        )

    const commision = booking.orders.reduce(
        (n, order) =>
            n +
            order.order_items
                .filter(orderItem => orderItem.item.has_commission)
                .reduce(
                    (_n, orderItem) =>
                        _n + orderItem.commission * orderItem.quantity,
                    0
                ),
        0
    )

    const name = booking?.agency?.name || booking?.driver?.name || '-'
    const guideName = booking?.agency_guide?.name || ''
    const isPayed = !!booking.movements.find(movement => !movement.deleted)

    return (
        <Tr>
            <Td>{dayjs(booking.hour).format('hh:mm A')}</Td>
            <Td>
                {name}
                {guideName ? ` - ${guideName}` : ''}
            </Td>
            <Td>
                <ModalBooking
                    isOpen={isOpenBooking}
                    onClose={() => setIsOpenBooking(false)}
                    bookingId={booking.id}
                />
                <Text
                    textDecor="underline"
                    onClick={() => setIsOpenBooking(true)}
                    color={colors['alo-blue']}
                    cursor="pointer"
                >
                    {totalItems}
                </Text>
            </Td>
            <Td>{booking.is_attented ? 'Atendido' : 'No atendido'}</Td>
            <Td>S/{commision}</Td>
            <Td>{isPayed ? 'Pagado' : 'Pendiente'}</Td>
            <Td>
                <ModalPay
                    isOpen={isOpenPay}
                    onClose={() => setIsOpenPay(false)}
                    booking={booking}
                    onDone={onReload}
                />
                <Icon
                    onClick={() => setIsOpenPay(true)}
                    as={CashIcon}
                    fontSize="2xl"
                    cursor="pointer"
                    pointerEvents={
                        isPayed || !booking.is_attented ? 'none' : 'auto'
                    }
                    fill={
                        isPayed || !booking.is_attented
                            ? 'blackAlpha.300'
                            : colors['alo-green']
                    }
                />
            </Td>
        </Tr>
    )
}
