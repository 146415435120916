import { Button, Flex } from '@chakra-ui/react'
import ModalKeyboard from '../ModalKeyboard'
import CashIcon from '../Icon/CashIcon'
import CardIcon from '../Icon/CardIcon'
import Transfer from '../Icon/Transfer'
import { PaymentTypeData } from '../../pages/UpdatePayments/types'

interface Props {
    maxAmount: number
    cashHandler: (cash: string, type: string) => void
    cardHandler: (cash: string, type: string) => void
    transferHandler: (
        transfer: string,
        type: string,
        name?: string | undefined
    ) => void
    paymentTypes: PaymentTypeData[]
    disabledButton: boolean
}

export default function ListTypePayments({
    maxAmount,
    cashHandler,
    cardHandler,
    transferHandler,
    ...props
}: Props) {
    const paymentIcons: any = {
        CashIcon: <CashIcon fill="gray" />,
        CardIcon: <CardIcon fill="gray" />,
        Transfer: <Transfer fill="gray" />
    }

    const attributes: any = {
        Efectivo: {
            title: 'Monto en efectivo',
            maxValue: Infinity,
            done: cashHandler
        },
        Tarjeta: {
            title: 'Monto por tarjeta',
            maxValue: maxAmount,
            done: cardHandler
        },
        Transferencia: {
            title: 'Monto por transferencia',
            maxValue: maxAmount,
            done: transferHandler
        }
    }

    return (
        <>
            <Flex gridGap={{ base: 2, lg: 3 }}>
                {props.paymentTypes?.map((paymentType, i) => (
                    <ModalKeyboard
                        key={i}
                        title={attributes[paymentType.name].title}
                        element={click => (
                            <Button
                                colorScheme="blackAlpha"
                                variant="outline"
                                leftIcon={paymentIcons[paymentType.class_name]}
                                size="md"
                                onClick={click}
                                disabled={props.disabledButton}
                                fontSize={{ base: 'sm', lg: 'md' }}
                            >
                                {paymentType.name}
                            </Button>
                        )}
                        maxValue={attributes[paymentType.name].maxValue}
                        onlyAmount
                        onDone={attributes[paymentType.name].done}
                        subPaymentTypes={paymentType.sub_payment_types}
                    />
                ))}
            </Flex>
        </>
    )
}
