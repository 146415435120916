import {
    FormControl,
    FormLabel,
    InputProps,
    SelectProps,
    Select,
    Box,
    Text,
    Textarea,
    TextareaProps
} from '@chakra-ui/react'
import ErrorHelper from '../../../components/ErrorHelper'
import NumberField from '../../../components/NumberField'
import { formEditionStock } from '../storage'
import useStorage from '../../../hooks/useStorage'
import onlyNumber from '../../../utilities/onlyNumber'
import { ChangeEvent } from 'react'

export default function FormEditionStock() {
    return (
        <>
            <NameProduct />
            <Quantity />
            <Comment />
        </>
    )
}

function NameProduct() {
    const { props } = formEditionStock.useField<InputProps>('name')
    return (
        <Text mb="4" textAlign="center" color="#434343">
            {props.value}
        </Text>
    )
}

function Quantity() {
    const { props, helper, error } =
        formEditionStock.useField<InputProps>('quantity')

    const { props: propsActualStock } =
        formEditionStock.useField<TextareaProps>('actual_stock')

    const { movement_type } = useStorage()

    const { props: propsTypeMovement, helper: helperTypeMovement } =
        formEditionStock.useField<SelectProps>('type_movement_id')

    const type_movement_id =
        formEditionStock.useField('type_movement_id').props.value

    function changeHandler(ev: ChangeEvent<HTMLInputElement>) {
        const value = onlyNumber(ev.target.value, {
            decimal: 2,
            min: 0,
            max:
                type_movement_id === '1'
                    ? Infinity
                    : Number(propsActualStock.value),
            negative: false
        })
        props.onChange({
            ...ev,
            target: {
                ...ev.target,
                value
            }
        })
    }

    function changeHandlerTypeMovement(ev: ChangeEvent<HTMLSelectElement>) {
        const value = ev.target.value
        propsTypeMovement.onChange(ev)
        if (value === '2') {
            if (Number(props.value) > Number(propsActualStock.value)) {
                formEditionStock.setValue(
                    'quantity',
                    propsActualStock.value.toString()
                )
            }
        }
    }

    function getNewStock() {
        return type_movement_id === '1'
            ? (Number(propsActualStock.value) + Number(props.value)).toString()
            : (Number(propsActualStock.value) - Number(props.value)).toString()
    }

    return (
        <>
            <FormControl mb={4}>
                <FormLabel>Movimiento</FormLabel>
                <Select
                    {...propsTypeMovement}
                    onChange={changeHandlerTypeMovement}
                    placeholder="Seleccionar Tipo de Movimiento"
                >
                    {movement_type.values.map(movementType => (
                        <option
                            key={movementType.value}
                            value={movementType.value}
                        >
                            {movementType.description}
                        </option>
                    ))}
                </Select>
                <ErrorHelper helper={helperTypeMovement} />
            </FormControl>
            <FormControl mb={4}>
                <FormLabel>Cantidad</FormLabel>
                <NumberField
                    {...props}
                    autoFocus
                    onChange={changeHandler}
                    placeholder="Ingresa cantidad"
                />
                <ErrorHelper helper={helper || error} />
            </FormControl>
            <Box display="flex" justifyContent="space-between">
                <FormControl mb={4}>
                    <FormControl>
                        <FormLabel>Stock Actual</FormLabel>
                        <Text fontWeight="bold" fontSize="3xl">
                            {propsActualStock.value}
                        </Text>
                    </FormControl>
                </FormControl>
                <FormControl mb={4}>
                    <FormControl>
                        <FormLabel>Nuevo Stock</FormLabel>
                        <Text fontWeight="bold" fontSize="3xl">
                            {getNewStock()}
                        </Text>
                    </FormControl>
                </FormControl>
            </Box>
        </>
    )
}
function Comment() {
    const { props } = formEditionStock.useField<TextareaProps>('comment')
    return (
        <FormControl mb={4}>
            <FormLabel>Comentario</FormLabel>

            <Textarea
                {...props}
                maxLength={300}
                placeholder="Ingresar comentario"
            />
            {/* <ErrorHelper helper={helper || error} /> */}
        </FormControl>
    )
}
