import { Box, Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Alarm from '../../components/Icon/Alarm'
import getInformationForWaiter from '../../utilities/getInformationForWaiter'
import useJoinTables from '../../hooks/useJoinTables'
import { TableData, TableDataJoinTable } from './types'

interface CardTableProps {
    table: TableDataJoinTable
}

function CardTable({ table }: CardTableProps) {
    const { push } = useHistory()
    const hasOrder = table.order_items.length > 0
    const dateInitial = hasOrder ? dayjs(table.order_items[0].created_at) : null
    const getDiffSecond = () => {
        const diff = dayjs().diff(dateInitial, 's')
        if (diff < 0) return 0
        return diff
    }
    const [totalSeconds, setTotalSeconds] = useState(() =>
        hasOrder ? getDiffSecond() : 0
    )

    useEffect(() => {
        if (!hasOrder) return
        const interval = setInterval(() => {
            setTotalSeconds(getDiffSecond())
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    function getTimer() {
        const totalHours = Math.floor(totalSeconds / (60 * 60)) // How many hours?
        let seconds = totalSeconds - totalHours * 60 * 60 // Pull those hours out of totalSeconds
        let totalMinutes = Math.floor(totalSeconds / 60) //With hours out this will retun minutes
        seconds = totalSeconds - totalMinutes * 60 // Again pull out of totalSeconds
        totalMinutes = totalMinutes - totalHours * 60
        return [totalHours, totalMinutes, seconds]
    }

    const [h, m, s] = getTimer()

    return (
        <Box
            key={table.id}
            cursor="pointer"
            borderRadius="lg"
            onClick={() => push(`/quick_sale_by/tables?table_id=${table.id}`)}
            h="175px"
            bg={table.is_free ? 'gray-3' : 'primary'}
            p="4"
            color="white"
            textAlign="center"
            position="relative"
        >
            <Heading size="lg" textAlign="center">
                {table.name}
            </Heading>
            <Flex justify="center" mb={1}>
                {table.join_tables.map(jt => (
                    <Text
                        fontSize="xs"
                        key={jt.id}
                        fontWeight="bold"
                        color="gray.100"
                        mx={1}
                    >
                        {jt.to_table.name}
                    </Text>
                ))}
            </Flex>
            <Text fontSize="sm">Mozo:</Text>
            {table.waiter ? (
                <Text fontWeight="bold" fontSize="md">
                    {getInformationForWaiter(
                        table.waiter.firstname,
                        table.waiter.lastname
                    )}
                </Text>
            ) : (
                '-'
            )}
            {hasOrder && (
                <Flex
                    w="full"
                    justify="center"
                    align="center"
                    position="absolute"
                    bottom={2}
                    left={0}
                    zIndex={1}
                >
                    <Alarm fill="white" />
                    <Flex fontSize="xs" ml={2}>
                        {h > 0 && <Text mr={1}>{h} h</Text>}
                        <Text mr={1}>{m} min</Text>
                        {h === 0 && <Text>{s} seg</Text>}
                    </Flex>
                </Flex>
            )}
        </Box>
    )
}

export default function Tables({ tables }: { tables: TableData[] }) {
    const _tables = useJoinTables(tables)

    return (
        <SimpleGrid
            spacing={5}
            p={5}
            minChildWidth={{
                base: '150px',
                xl: '180px'
            }}
        >
            {_tables.map(table => (
                <CardTable key={table.id} table={table} />
            ))}
        </SimpleGrid>
    )
}
