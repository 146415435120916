import { useEffect, useState } from 'react'
import {
    Button,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react'
import Booking from '../../../../models/booking'
import dayjs from 'dayjs'
import { get } from '../../../../lib/http'
import PreOrder from '../../../../models/pre_order'
import GridBookings from './GridBookings'
import usePreOrder from '../../hooks/usePreOrder'
import { ModalBoookingsProps } from './types'
import { useHistory } from 'react-router-dom'
import useParamsQuickSaleBy from '../../hooks/useParamsQuickSaleBy'

export default function ModalBookings(props: ModalBoookingsProps) {
    const { push } = useHistory()
    const { tableId } = useParamsQuickSaleBy()
    const [isLoading, setIsLoading] = useState(false)
    const { setBooking, booking } = usePreOrder()
    const [date, setDate] = useState(() => dayjs().format('YYYY-MM-DD'))
    const [bookingSelected, setBookingSelected] = useState<Booking | null>(null)

    useEffect(() => {
        if (!props.isOpen) return
        if (booking) {
            setBookingSelected(booking)
        } else {
            setBookingSelected(null)
        }
    }, [props.isOpen])

    const handleConfirm = async () => {
        if (!bookingSelected) return
        setBooking(bookingSelected)
        if (bookingSelected.type !== 1) {
            setIsLoading(true)
            const { data } = await get<PreOrder>(
                `/api/pre_orders/booking/${bookingSelected.id}`
            )
            setIsLoading(false)
            if (data.result) {
                // revisar hooks/usePreOrderProviderValues.ts
                push({
                    search: `${
                        tableId ? `table_id=${tableId}&` : ''
                    }pre_order_id=${data.result.id}`
                })
            } else {
                push({
                    search: tableId ? `table_id=${tableId}` : ''
                })
            }
        } else {
            push({
                search: tableId ? `table_id=${tableId}` : ''
            })
        }
        props.onClose()
    }

    return (
        <Modal
            isOpen={props.isOpen}
            onClose={props.onClose}
            size="xl"
            closeOnOverlayClick
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize="2xl" fontWeight="bold">
                    Reservas
                </ModalHeader>
                <ModalBody>
                    <Input
                        mb={4}
                        size="md"
                        borderRadius="full"
                        w="auto"
                        type="date"
                        bg="base-4"
                        value={date}
                        onChange={_ev => setDate(_ev.target.value || date)}
                    />
                    <GridBookings
                        date={date}
                        booking={bookingSelected}
                        onClick={setBookingSelected}
                    />
                    <Flex
                        w="full"
                        py="5"
                        gap={2}
                        justifyContent="space-between"
                    >
                        <Button
                            _hover={{}}
                            h="60px"
                            w="180px"
                            borderWidth="1px"
                            borderColor="#434343"
                            color="#434343"
                            bg="white"
                            onClick={props.onClose}
                        >
                            Cancelar
                        </Button>
                        <Button
                            h="60px"
                            w="180px"
                            color="white"
                            isLoading={isLoading}
                            bg="orange"
                            _hover={{}}
                            onClick={handleConfirm}
                            autoFocus
                        >
                            Confirmar
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
