import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ProductGroup } from '../../../context/DeliveryContext/types'
import useDelivery from '../../../hooks/useDelivery'
import {
    useDeliveryForm,
    useDeliveryHelper
} from '../store/delivery-form.store'

export default function useInitial() {
    const { delivery_id } = useParams<{ delivery_id: string }>()
    const { delivery, setProducts, isLoading, orderEdition, isOrderEdition } =
        useDelivery(Number(delivery_id || '0'))
    const set = useDeliveryForm(state => state.set)
    const clearHelper = useDeliveryHelper(state => state.clear)

    useEffect(() => {
        if (!delivery) return
        if (isOrderEdition) {
            orderEdition(false)
            return
        }

        set({
            phone: delivery.phone_number,
            address: [delivery.address, delivery.reference || ''],
            deliveryMode: delivery.programmed ? '1' : '0',
            paymentMethod: delivery.payment_method.toString(),
            priceDelivery: delivery.delivery_price.toString(),
            date: dayjs(delivery.delivery_date).format('YYYY-MM-DD'),
            hour: dayjs(delivery.delivery_date).format('HH:mm'),
            cashClient: delivery.cash_client.toString(),
            observation: delivery.observation || ''
        })

        const productsGroup: ProductGroup[] = []

        delivery.order.order_items.forEach(orderItem => {
            const productGroup = productsGroup.find(
                prdG =>
                    prdG.category_item_id === orderItem.item.category_item_id
            )
            if (productGroup) {
                productGroup.items.push({
                    orderItemId: orderItem.id,
                    itemId: orderItem.item_id,
                    name: orderItem.item.name,
                    quantity: orderItem.quantity,
                    price: orderItem.price,
                    description: '',
                    feature_settings: []
                })
                return
            }

            productsGroup.push({
                category_item_id: orderItem.item.category_item_id,
                category_item_name: orderItem.item.category_item.name,
                items: [
                    {
                        orderItemId: orderItem.id,
                        itemId: orderItem.item_id,
                        name: orderItem.item.name,
                        quantity: orderItem.quantity,
                        price: orderItem.price,
                        description: '',
                        feature_settings: []
                    }
                ]
            })
        })

        setProducts(productsGroup)

        return clearHelper
    }, [delivery?.id])

    return {
        isLoading
    }
}
