import { FormControl, FormLabel, Select } from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
//import { render } from 'redity'
//import { Keys } from '../constants'
import { storagePage } from '../storage'

export default function TypeTax() {
    const [value, setValue] = useState('levied')

    function changeHandler(ev: ChangeEvent<HTMLSelectElement>) {
        setValue(ev.target.value)
        if (ev.target.value == 'levied') {
            storagePage.typeTax = 'levied'
        } else {
            storagePage.typeTax = 'exempt'
        }
    }

    return (
        <FormControl>
            <FormLabel>IGV</FormLabel>
            <Select bg="white" value={value} onChange={changeHandler}>
                <option value="levied">Gravado</option>
                <option value="exempt">Exonerado</option>
            </Select>
        </FormControl>
    )
}
