import { Button, useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useRender } from 'redity'
import writeXlsxFile, { Row, SheetData } from 'write-excel-file'
import { CashboxUserRowQuery, controlList } from '../storage'
import DownloadIcon from '../../../components/Icon/Download'
import { get } from '../../../lib/http'

export default function Download() {
    useRender('Download')
    const toast = useToast()
    const [isLoading, setLoading] = useState(false)

    async function download() {
        setLoading(true)
        const start_date = controlList.getQuery('start_date') as string
        const end_date = controlList.getQuery('end_date') as string
        const startDateReFormated = dayjs(start_date, 'YYYY-MM-DD').format(
            'DD/MM/YYYY'
        )
        const endDateReFormated = dayjs(end_date, 'YYYY-MM-DD').format(
            'DD/MM/YYYY'
        )

        const { data } = await get<CashboxUserRowQuery[]>(
            `/api/cashbox_users/sales_by_line?start_date=${start_date}&end_date=${end_date}`
        )

        if (!data) {
            toast({
                title: 'Hubo problemas al cargar los datos',
                position: 'top',
                status: 'error'
            })
            setLoading(false)
            return
        }

        const head: (...values: string[]) => Row[number][] = (...values) =>
            values.map<Row[number]>(val => ({
                value: val,
                fontWeight: 'bold',
                wrap: true,
                alignVertical: 'center',
                align: 'center'
            }))

        const rows: SheetData = [
            [],
            [
                {
                    value: 'Resumen de Venta por Línea de Negocio',
                    fontWeight: 'bold',
                    span: 3,
                    height: 24,
                    alignVertical: 'center',
                    align: 'center',
                    fontSize: 15
                }
            ],
            [
                {
                    value: `De: ${startDateReFormated} - Hasta: ${endDateReFormated}`,
                    span: 3,
                    align: 'center'
                }
            ],
            [],
            [],
            head(
                'Fecha de apertura',
                'Cajer@',
                'Línea de negocio',
                'Venta (S/)'
            )
        ]

        data.result.forEach(sale => {
            rows.push([
                {
                    value: sale.fecha_apertura,
                    type: String
                },
                {
                    value: sale.cajero,
                    type: String
                },
                {
                    value: sale.linea_negocio,
                    type: String
                },
                {
                    value: sale.venta,
                    type: Number
                }
            ])
        })

        await writeXlsxFile(rows, {
            fileName: `venta_por_linea_de_negocio${startDateReFormated}_${endDateReFormated}.xlsx`,
            columns: [
                {
                    width: 12
                },
                {
                    width: 24
                },
                {
                    width: 12
                }
            ],
            fontSize: 10,
            fontFamily: 'Calibri'
        })
        setLoading(false)
    }

    return (
        <Button
            variant="alo-gray:md-xl"
            onClick={download}
            isLoading={isLoading}
            // isDisabled={!controlList.getQuery('start_date')}
            rightIcon={<DownloadIcon fill="white" />}
        >
            Descargar
        </Button>
    )
}
