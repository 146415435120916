import { Icon } from '@chakra-ui/react'
import { render } from 'redity'
import Trash from '../../../components/Icon/Trash'
import colors from '../../../config/theme/colors'
import Delete from '../../../lib/delete'
import { Keys } from '../constant'
import { storagePage } from '../storage'
import { MovimentCustom } from '../types'
import loadMain from '../utilities/loadMain'
// import { listItem } from '../storage'

export default function DeleteMovement({
    movement
}: {
    movement: MovimentCustom
}) {
    return (
        <Delete
            title="Eliminar Movimiento"
            path={`/api/movements/${movement.id}`}
            element={click => (
                <Icon
                    onClick={click}
                    fill={colors.error}
                    as={Trash}
                    cursor="pointer"
                />
            )}
            done={async () => {
                if (storagePage.currentCashbox) {
                    await loadMain(storagePage.currentCashbox?.id)
                    render(Keys.MAIN)
                }
            }}
        >
            ¿Esta seguro de eliminar el movimiento?
        </Delete>
    )
}
