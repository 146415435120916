import { Box, Text } from '@chakra-ui/layout'
import { BoxContentProps } from '../components/Summary/types'

export default function BoxContent({
    title,
    children,
    ...props
}: BoxContentProps) {
    return (
        <Box
            bg="blackAlpha.50"
            {...props}
            borderWidth={1}
            borderRadius="lg"
            py={2}
            px={3}
            w="full"
        >
            <Text fontSize="xs" mb={2} color="gray.500">
                {title}
            </Text>
            {children}
        </Box>
    )
}
