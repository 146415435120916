import { useState } from 'react'
import { post } from '../../../lib/http'
import { UpdateStockProps } from '../components/SectionKadex/types'

export default function useUpdateStock({
    handleFinish,
    handleError
}: {
    handleFinish: () => void
    handleError: () => void
}) {
    const [isLoading, setIsLoading] = useState(false)

    async function submit(_data: UpdateStockProps) {
        setIsLoading(true)
        const { error } = await post<any>(
            '/api/inventory_production_items/update_stock',
            _data
        )

        if (error) {
            setIsLoading(true)
            handleError()
            return
        }

        handleFinish()

        setIsLoading(false)
    }

    return {
        isLoading,
        submit
    }
}
