import { Box, Button, Input } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { ChangeEvent } from 'react'
import Rocket from '../../components/Icon/Rocket'
import { get } from '../../lib/http'
import useFilters from './hooks/useFilters'
import ModalErrorSend from './ModalContents/ModalErrorSend'
import { controlList, storagePage } from './storage'

export default function Filters() {
    const props = controlList.useFilter('issue_date')

    const onChange = async (
        ev: ChangeEvent<HTMLSelectElement | HTMLInputElement>
    ) => {
        props.onChange(ev)
        if (ev.target.value) {
            const res = await get<boolean>(
                `/api/sunat_vouchers/status_daily_summary?generation_date=${ev.target.value}`
            )

            switch (res.data.result) {
                case false:
                    storagePage.status = 'pending'
                    break
                case true:
                    storagePage.status = 'validated'
                    break
                default:
                    storagePage.status = null
                    break
            }

            await controlList.load()
        }
    }

    const { openModal, setOpenModal, dataModal, handleSend, isLoading } =
        useFilters()

    return (
        <Box marginBottom="5" display="flex" justifyContent="flex-end">
            <Input
                type="date"
                w="64"
                {...props}
                max={dayjs().format('YYYY-MM-DD')}
                onChange={onChange}
                isDisabled={isLoading}
            />
            <Button
                variant="alo-green:md-xl"
                ml={4}
                onClick={handleSend}
                isLoading={isLoading}
                leftIcon={<Rocket fill="white" />}
            >
                Enviar resumen diario
            </Button>
            {openModal && (
                <ModalErrorSend
                    message={dataModal.message}
                    receipts={dataModal.receipts}
                    isOpen={openModal}
                    setOpen={setOpenModal}
                />
            )}
        </Box>
    )
}
