import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    Input,
    InputProps,
    Select,
    SelectProps,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import ErrorHelper from '../../../../components/ErrorHelper'
import NumberField from '../../../../components/NumberField'
import ViewDetailButton from '../../../../components/ViewDetailButton/ViewDetailButton'
import { useGet } from '../../../../hooks/useHttp'
import OutstandingQuota from '../../../../models/outstanding_quota'
import SubPaymentType from '../../../../models/sub_payment_type'
import { formPayment, storagePage } from '../../storage'
import { DestinationCashbox } from '../../types'
import dayjs from 'dayjs'

export default function ModalPayment() {
    const [showSchedule, setShowschedule] = useState(false)

    return (
        <>
            <Grid templateColumns="repeat(2, 1fr)" gap={6} mb={4}>
                <PaymentDateAndDestiny />
            </Grid>
            <Flex gridGap={6} mb={6}>
                <PaymentMethod />
                <Amount />
            </Flex>
            <ViewDetailButton
                showDetail={showSchedule}
                onClick={() => setShowschedule(!showSchedule)}
                description="Cronograma de pagos"
            />
            {showSchedule && <PaymentSchedule />}
        </>
    )
}

function PaymentDateAndDestiny() {
    const { props: propsDate, helper: helperDate } =
        formPayment.useField<InputProps>('payment_date')
    const { props: propsDestiny, helper: helperDestiny } =
        formPayment.useField<SelectProps>('destiny')

    const { value: date } = formPayment.useValue('payment_date')

    const { data, reload } = useGet<DestinationCashbox[]>(
        `/api/outstandings/cashboxes?payment_date=${date}`,
        []
    )

    useEffect(() => {
        formPayment.setValue('destiny', '')
        reload()
    }, [date])

    return (
        <>
            <FormControl isRequired>
                <FormLabel>Fecha de pago</FormLabel>
                <Input
                    {...propsDate}
                    type="date"
                    max={dayjs().format('YYYY-MM-DD')}
                />
                <ErrorHelper helper={helperDate} />
            </FormControl>
            <FormControl isRequired>
                <FormLabel>Destino</FormLabel>
                <Select {...propsDestiny} placeholder="Seleccionar destino">
                    {data.result.map((cashbox: any) => (
                        <option key={cashbox.id} value={cashbox.id}>
                            {cashbox.opening_date}
                        </option>
                    ))}
                </Select>
                <ErrorHelper helper={helperDestiny} />
            </FormControl>
        </>
    )
}

function PaymentMethod() {
    const payment_methods = storagePage.payment_methods
    const { props, helper } = formPayment.useField<SelectProps>('payment_type')

    const { value } = formPayment.useValue('payment_type')
    const methodWithSubs = payment_methods.find(method => method.id == value)

    return (
        <>
            <FormControl isRequired>
                <FormLabel>Método de pago</FormLabel>
                <Select {...props} placeholder="Seleccionar método de pago">
                    {payment_methods.map(method => (
                        <option key={method.id} value={method.id}>
                            {method.name}
                        </option>
                    ))}
                </Select>
                <ErrorHelper helper={helper} />
            </FormControl>
            {methodWithSubs && methodWithSubs.sub_payment_types.length > 0 && (
                <PaymentWay subPayments={methodWithSubs.sub_payment_types} />
            )}
        </>
    )
}

function PaymentWay({ subPayments }: { subPayments: SubPaymentType[] }) {
    const { props, helper } =
        formPayment.useField<SelectProps>('sub_payment_type')

    return (
        <FormControl isRequired>
            <FormLabel>Forma de pago</FormLabel>
            <Select {...props} placeholder="Seleccionar forma de pago">
                {subPayments.map(sub => (
                    <option key={sub.id} value={sub.id}>
                        {sub.name}
                    </option>
                ))}
            </Select>
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}

function Amount() {
    const { props, helper, error } = formPayment.useField<InputProps>('amount')
    return (
        <FormControl isRequired>
            <FormLabel>Monto</FormLabel>
            <NumberField {...props} placeholder="Monto"></NumberField>
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function PaymentSchedule() {
    const { value } = formPayment.useValue('schedule')
    return (
        <Box mt={4}>
            <Table>
                <Thead>
                    <Tr>
                        <Th>Cuota</Th>
                        <Th>Monto</Th>
                        <Th>Saldo</Th>
                        <Th>Vencimiento</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {value.map((quota: OutstandingQuota, i: number) => (
                        <Tr key={i}>
                            <Td>{i + 1}</Td>
                            <Td>S/{quota.amount.toFixed(2)}</Td>
                            <Td>
                                S/
                                {(quota.amount - quota.payed_amount).toFixed(2)}
                            </Td>
                            <Td>{quota.due_date}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    )
}
