import React from 'react'
import { Button } from '@chakra-ui/react'
import { storagePage } from '../storage'
import colors from '../../../config/theme/colors'
import Check from '../../../components/Icon/Check'
import useDetails from '../hooks/useDetails'
import { TYPES_ORDER_PAYMENT } from '../constants'
import CardOrder from '../../../components/CardOrder'

export default function Details() {
    const {
        selectedItems,
        typeConsumption,
        discount,
        totalFinal,
        totalOriginal
    } = storagePage

    const { alias, confirmPayment, typeOrder, accumulatedPayment } =
        useDetails()

    return (
        <>
            <CardOrder
                alias={alias}
                title="DETALLE DE PEDIDO"
                total={totalFinal}
                discount={discount}
                subTotal={totalOriginal}
                typeConsumption={typeConsumption?.name ?? ''}
                selectedItems={selectedItems}
            />
            <Button
                bg={colors.success}
                color="white"
                leftIcon={<Check fill={colors.white} />}
                w="full"
                mt={{ base: 2, md: 6 }}
                size="lg"
                minH="48px"
                borderRadius="xl"
                isDisabled={
                    typeOrder === TYPES_ORDER_PAYMENT.FREE
                        ? false
                        : accumulatedPayment < totalFinal
                }
                onClick={confirmPayment}
                _hover={{}}
            >
                Actualizar Pagos (S/ {totalFinal.toFixed(2)})
            </Button>
        </>
    )
}
