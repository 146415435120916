import { Flex, Icon, Td } from '@chakra-ui/react'
import Block from '../../components/Icon/Block'
import Rocket from '../../components/Icon/Rocket'
import ModalFormAnnul from '../../components/ModalFormAnnul'
import ModalSend from './ModalContents/ModalSend'
import { controlList } from './storage'
import { SunatVoucherData } from './types'
import ModalAnnul from '../../components/ModalAnnul'
import ModalDownload from './ModalContents/ModalDownload'
import Download from '../../components/Icon/Download'

export default function TableActions({
    sunatVoucher
}: {
    sunatVoucher: SunatVoucherData
}) {
    const isNullable = sunatVoucher.status === 1 && !sunatVoucher.sent_daily

    return (
        <Td>
            <Flex justifyContent="center">
                <ModalSend
                    element={click => (
                        <Icon
                            cursor="pointer"
                            as={Rocket}
                            fill="gray-2"
                            pointerEvents={
                                sunatVoucher.sent || sunatVoucher.status === 3
                                    ? 'none'
                                    : 'auto'
                            }
                            opacity={
                                sunatVoucher.sent || sunatVoucher.status === 3
                                    ? '.4'
                                    : '1'
                            }
                            mr={2}
                            onClick={click}
                        />
                    )}
                    typeVoucherId={sunatVoucher.type_voucher_id}
                    filename={sunatVoucher.xml_name}
                    onDone={controlList.load}
                    sunatVoucherId={sunatVoucher.id}
                />
                {sunatVoucher.type_voucher_id !== 3 && (
                    <>
                        {sunatVoucher.type_voucher_id === 2 &&
                        sunatVoucher.sent ? (
                            <ModalFormAnnul
                                element={click => (
                                    <IconBlock
                                        isNullable={isNullable}
                                        onClick={click}
                                    />
                                )}
                                onDone={controlList.load}
                                orderPaymentId={sunatVoucher.order_payment_id}
                                sunatVoucherId={sunatVoucher.id}
                                code={sunatVoucher.code}
                            />
                        ) : (
                            <ModalAnnul
                                element={click => (
                                    <IconBlock
                                        isNullable={isNullable}
                                        onClick={click}
                                    />
                                )}
                                orderPaymentId={sunatVoucher.order_payment_id}
                                sunatVoucherId={sunatVoucher.id}
                                typeVoucher={sunatVoucher.type_voucher_id}
                                onDone={controlList.load}
                            />
                        )}
                    </>
                )}
                <ModalDownload
                    element={click => (
                        <Icon
                            as={Download}
                            display="block"
                            fill="dark"
                            onClick={click}
                            cursor="pointer"
                        />
                    )}
                    onDone={controlList.load}
                    filename={sunatVoucher.xml_name}
                />
            </Flex>
        </Td>
    )
}

function IconBlock({
    isNullable,
    onClick
}: {
    isNullable: boolean
    onClick: () => void
}) {
    return (
        <Icon
            as={Block}
            fill="error"
            cursor="pointer"
            pointerEvents={isNullable ? 'auto' : 'none'}
            opacity={isNullable ? '1' : '.5'}
            onClick={onClick}
            mr={2}
        />
    )
}
