import {
    Modal,
    ModalOverlay,
    ModalContent,
    Text,
    Button,
    //Box,
    Textarea,
    FormControl,
    FormLabel,
    Flex
} from '@chakra-ui/react'
//import Block from '../../../../components/Icon/Block'
//import colors from '../../../../config/theme/colors'
import useDialogEditOrder from './useDialogEditOrder'
import ErrorHelper from '../../../../components/ErrorHelper'

interface ModalProps {
    element: (click: () => void) => JSX.Element
    update: (reason?: string | null) => Promise<void>
}

export default function DialogEditOrder({ element, update }: ModalProps) {
    const {
        isOpen,
        setOpen,
        isLoading,
        helpers,
        submitHandler,
        changeHandler
    } = useDialogEditOrder(update)

    return (
        <>
            {element(() => setOpen(true))}
            <Modal
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                size="md"
                isCentered
            >
                <ModalOverlay />
                <ModalContent px={10} pb={9} pt={4}>
                    <form noValidate onSubmit={submitHandler}>
                        <Text
                            color="gray-2"
                            fontSize="2xl"
                            fontWeight="bold"
                            textAlign="center"
                            mb="5"
                            mt="5"
                        >
                            Anulación de ítems
                        </Text>
                        <Text fontSize="sm" mb={3}>
                            Debido a que se han disminuído ítems al pedido, debe
                            ingresar el motivo de anulación.
                        </Text>
                        <FormControl isRequired>
                            <FormLabel>Ingresar Motivo</FormLabel>
                            <Textarea
                                name="reason"
                                placeholder="Ingresar motivo de anulación de ítem(s)"
                                maxLength={50}
                                onChange={changeHandler}
                                disabled={isLoading}
                            />
                            <ErrorHelper helper={helpers.reason} />
                        </FormControl>
                        <Flex
                            mt={10}
                            justifyContent="space-between"
                            alignItems="center"
                            flexDirection={{ base: 'column', sm: 'row' }}
                            gridGap={{ base: 2, sm: 10 }}
                        >
                            <Button
                                variant="alo-gray:lg-md"
                                bg="transparent"
                                color="alo-gray"
                                onClick={() => setOpen(false)}
                                fontSize={{ base: '16px', md: '20px' }}
                                w="48"
                                disabled={isLoading}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="alo-red:lg-md"
                                w="48"
                                type="submit"
                                isLoading={isLoading}
                                fontSize={{ base: '16px', md: '20px' }}
                            >
                                Confirmar
                            </Button>
                        </Flex>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}
