export default function ThumbUp(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            fill="none"
            viewBox="0 0 28 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.75 25.5H22a2.483 2.483 0 002.3-1.525l3.775-8.813a2.47 2.47 0 00.175-.912v-2.5c0-1.375-1.125-2.5-2.5-2.5h-7.887l1.187-5.713.037-.4c0-.512-.212-.987-.55-1.325L17.213.5 8.975 8.738A2.484 2.484 0 008.25 10.5V23c0 1.375 1.125 2.5 2.5 2.5zm0-15l5.425-5.425L14.5 11.75h11.25v2.5L22 23H10.75V10.5zm-5 0h-5v15h5v-15z"
            />
        </svg>
    )
}
