import { Flex, Input } from '@chakra-ui/react'
import Clean from '../../components/Clean'
import SelectWithLabel from '../../components/SelectWithLabel'
import { controlList } from './storage'

export default function Filters() {
    const { clear, ...props } = controlList.useSearch()
    const propsFilter = controlList.useFilter('status')

    return (
        <Flex gridGap="2">
            <Clean value={props.value} onClick={clear}>
                <Input
                    placeholder="🏸 Buscar por comprobante o nombre de cliente"
                    {...props}
                    width="96"
                />
            </Clean>
            <SelectWithLabel
                {...propsFilter}
                label="Estado:"
                placeholder="Todos"
            >
                <option value={'pendiente'}>Pendiente</option>
                <option value={'pagado'}>Pagado</option>
            </SelectWithLabel>
        </Flex>
    )
}
