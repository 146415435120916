import UserAccess from '../types/user_access'

const access: Record<number, UserAccess> = {
    1: {
        role: 'administrador',
        role_id: 1,
        routes: [
            '/orders',
            '/cards',
            '/users',
            '/tables',
            '/tourist_groups',
            '/bookings',
            '/confirm',
            '/quick_sale',
            '/cash_register',
            '/sales',
            '/proof',
            '/statement',
            '/by_tables',
            '/by_tables/:table_id/quick_sale',
            '/printerMachines',
            '/orders/:order_id/confirm',
            '/reports',
            '/reports/receipts',
            '/reports/business_line',
            '/dashboard',
            '/warehouses',
            '/orders/:order_id/confirm/split',
            '/orders/:order_id/confirm/split/:account_id',
            '/pre_orders',
            '/pre_order',
            '/pre_order/:pre_order_id',
            '/quick_sale_by/:by',
            '/orders/:order_id/move_products',
            '/order_status',
            '/pay_commissions',
            '/reports/commissions',
            '/inventories',
            '/deliveries',
            '/delivery/customers',
            '/delivery/order',
            '/deliveries/:delivery_id/customers',
            '/pendingAccounts',
            '/generateVoucher',
            '/orders/:order_id/move_products',
            '/inventories',
            '/sales/:order_payment_id/update_payments',
            '/inventory_items',
            '/inventory_management'
        ],
        redirect: '/cards'
    },
    2: {
        role: 'cajero',
        role_id: 2,
        routes: [
            '/quick_sale',
            '/checkin/:order_id',
            '/cash_register',
            '/sales',
            '/confirm',
            '/by_tables',
            '/by_tables/:table_id/quick_sale',
            '/orders/:order_id/confirm',
            // '/reports',
            // '/reports/receipts',
            // '/reports/business_line',
            // '/warehouses',
            '/orders/:order_id/confirm/split',
            '/orders/:order_id/confirm/split/:account_id',
            '/orders/:order_id/move_products',
            '/pay_commissions',
            '/pre_orders',
            '/pre_order',
            '/pre_order/:pre_order_id',
            '/quick_sale_by/:by',
            '/deliveries',
            '/delivery/customers',
            '/delivery/order',
            '/deliveries/:delivery_id/customers',
            '/inventories',
            '/sales/:order_payment_id/update_payments'
        ],
        redirect: '/quick_sale'
    },
    3: {
        role: 'mozo',
        role_id: 3,
        routes: [
            '/',
            // '/orders',
            '/by_tables',
            '/by_tables/:table_id/quick_sale',
            '/pre_orders',
            '/pre_order',
            '/pre_order/:pre_order_id',
            '/quick_sale_by/:by'
        ],
        redirect: '/by_tables'
    },
    4: {
        role: 'dealer',
        role_id: 4,
        routes: [
            // '/',
            '/by_tables',
            '/by_tables/:table_id/quick_sale',
            '/order_status',
            '/pre_orders',
            '/pre_order',
            '/pre_order/:pre_order_id',
            '/quick_sale_by/:by',
            '/deliveries',
            '/delivery/customers',
            '/deliveries/:delivery_id/customers',
            '/delivery/order'
        ],
        redirect: '/by_tables'
    }
}

export default access
