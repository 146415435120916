import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { post } from '../../../lib/http'

export default function useJoinTables(onDone: () => void) {
    const [isLoading, setIsLoading] = useState(false)
    const [tableIds, setTableIds] = useState<number[]>([])
    const toast = useToast()

    async function submit() {
        setIsLoading(true)
        const data = await post<{ table_ids: number[] }>(`/api/tables/join`, {
            table_ids: tableIds
        })
        if (data.error) {
            toast({
                title: data.error.message,
                position: 'top',
                status: 'error'
            })
        } else {
            toast({
                title: 'Se unieron las mesas',
                position: 'top',
                status: 'success'
            })
            onDone()
        }
        setIsLoading(false)
    }

    return {
        isLoading,
        tableIds,
        setTableIds,
        submit
    }
}
