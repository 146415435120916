import {
    Box,
    Checkbox,
    Flex,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Text
} from '@chakra-ui/react'
import { MatrixItem } from '../types'
import { useEffect, useState } from 'react'
import { storagePage } from '../storage'

export default function Item({
    matrix_item,
    items,
    handleOnChange
}: PropsItem) {
    useEffect(() => {
        setQuantity(matrix_item.quantity.toString())
    }, [items.includes(matrix_item)])

    const [quantity, setQuantity] = useState(matrix_item.quantity.toString())

    function handleChangeQuantity(value: string) {
        setQuantity(value)
        for (let i = 0; i < storagePage.items_to_move.length; i++) {
            if (storagePage.items_to_move[i].id == matrix_item.id) {
                storagePage.items_to_move[i].quantity = Number(value)
            }
        }
    }

    return (
        <Flex>
            <Checkbox
                onChange={e => handleOnChange(e, matrix_item)}
                isChecked={items.includes(matrix_item)}
                value={String(matrix_item)}
                size="lg"
                mr={4}
            />
            <Flex width="full" justifyContent="space-between" py={1}>
                <Box display="flex" alignItems="center">
                    {!items.includes(matrix_item) ? (
                        <Text fontSize="sm" minW={16} mr={2} align="center">
                            {matrix_item.quantity}
                        </Text>
                    ) : (
                        <NumberInput
                            size="sm"
                            mr={2}
                            maxW={16}
                            value={quantity}
                            min={1}
                            max={matrix_item.quantity}
                            onChange={handleChangeQuantity}
                            name="quantity"
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    )}

                    <Text fontSize="sm">{matrix_item.item.name}</Text>
                </Box>
                <Text fontSize="sm" w="24" textAlign="right" alignSelf="center">
                    S/ {matrix_item.final_price_item.toFixed(2)}
                </Text>
            </Flex>
        </Flex>
    )
}

interface PropsItem {
    matrix_item: MatrixItem
    items: MatrixItem[]
    handleOnChange: (e: any, items_to_move: MatrixItem) => void
}
