import { useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { ChangeEvent, useEffect, useState } from 'react'
import { render } from 'redity'
import { get } from '../../../lib/http'
import { Result } from '../../../lib/http/type'
import { Keys } from '../constant'
import { storagePage } from '../storage'
import { CashboxMovement, OpeningDate } from '../types'

export default function useOpeningHistory() {
    const toast = useToast()
    const [isLoading, setLoading] = useState(true)

    const startDate = dayjs().format('YYYY-MM-DD')

    async function loadOpeningHistory() {
        setLoading(true)
        const request: Array<Promise<any>> = [
            get<CashboxMovement[]>(
                `/api/cashboxes/cashbox_movement?date=${startDate}`
            ),
            get<OpeningDate[]>(
                `/api/cashboxes/opening_dates?start_date=${startDate}`
            )
        ]

        const [result1, result2] = (await Promise.all(request)) as [
            Result<CashboxMovement[]>,
            Result<OpeningDate[]>
        ]

        if (result1.error || result2.error) {
            setLoading(false)
            toast({
                title: 'Ocurrió un problema al cargar los datos',
                status: 'error',
                position: 'top'
            })
        }

        storagePage.cashboxMovements = result1.data.result
        storagePage.openingDates = result2.data.result
        setLoading(false)
    }

    const [date, setDate] = useState(() => dayjs().format('YYYY-MM-DD'))
    const [opening, setOpenign] = useState('')
    const [disabledFilters, setDisabledfilters] = useState(false)

    async function changeHandlerDate(ev: ChangeEvent<HTMLInputElement>) {
        storagePage.openingStartDate = ev.target.value
        setDate(ev.target.value)
        setDisabledfilters(true)
        setOpenign('')

        const request: Array<Promise<any>> = [
            get<CashboxMovement[]>(
                `/api/cashboxes/cashbox_movement?date=${ev.target.value}`
            ),
            get<OpeningDate[]>(
                `/api/cashboxes/opening_dates?start_date=${ev.target.value}`
            )
        ]

        const [result1, result2] = (await Promise.all(request)) as [
            Result<CashboxMovement[]>,
            Result<OpeningDate[]>
        ]

        if (result1.error || result2.error) {
            toast({
                title: 'Ocurrió un problema al cargar los datos',
                status: 'error',
                position: 'top'
            })
            return
        }

        storagePage.cashboxMovements = result1.data.result
        storagePage.openingDates = result2.data.result
        render(Keys.OPENING_HISTORY_FILTER)
        render(Keys.OPENING_HISTORY_BOARD)
        setDisabledfilters(false)
    }

    const openingDate = storagePage.openingStartDate

    async function changeHandlerSelect(
        ev: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) {
        setOpenign(ev.target.value)
        if (ev.target.value == '') {
            setDisabledfilters(true)
            const { error, data } = await get<CashboxMovement[]>(
                `/api/cashboxes/cashbox_movement?date=${startDate}`
            )
            if (error) {
                toast({
                    title: 'Ocurrió un problema al cargar los datos',
                    status: 'error',
                    position: 'top'
                })
            }
            storagePage.cashboxMovements = data.result
            render(Keys.OPENING_HISTORY_BOARD)
            setDisabledfilters(false)
        } else {
            setDisabledfilters(true)
            const { error, data } = await get<CashboxMovement[]>(
                `/api/cashboxes/cashbox_movement?date=${openingDate}&cashboxuser_id=${ev.target.value}`
            )
            if (error) {
                toast({
                    title: 'Ocurrió un problema al cargar los datos',
                    status: 'error',
                    position: 'top'
                })
            }
            storagePage.cashboxMovements = data.result
            render(Keys.OPENING_HISTORY_BOARD)
            setDisabledfilters(false)
        }
    }

    useEffect(() => {
        loadOpeningHistory()
    }, [])

    return {
        opening,
        setOpenign,
        isLoading,
        setLoading,
        date,
        changeHandlerDate,
        changeHandlerSelect,
        disabledFilters
    }
}
