import { useState } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import Initial from '../../components/Initial'
import ContentLeft from './ContentLeft'
import ContentRight from './ContentRight'
import MenuUser from '../../components/MenuUser'
import NTabs from '../../components/NTabs'
import useInitial from './useInitial'
import { useParams, useLocation } from 'react-router-dom'
import { storagePage } from './storage'
import NavbarAlt from '../../components/NavbarAlt'
import ButtonSeeOrder from './ButtonSeeOrder'
import AlertCashbox from './AlertCashbox'

export default function QuickSale() {
    const [showDetailsOnMobile, setShowDetailsOnMobile] = useState(false)
    const { pageState } = useInitial()
    const { isOpenCashbox, hasOrderTable } = storagePage
    const { table_id } = useParams<{ table_id?: string }>()
    const titleOrderTable = hasOrderTable
        ? 'Registro de pedido'
        : 'Nuevo pedido'

    const location = useLocation()
    const isPreOrder = location.pathname.includes('pre_order')
    const path = isPreOrder ? '/pre_orders' : '/by_tables'

    return (
        <>
            {table_id || isPreOrder ? (
                <NavbarAlt title={titleOrderTable} path={path} />
            ) : (
                <Navbar component={<NTabs />}>
                    <Box px="5" py="1">
                        <MenuUser />
                    </Box>
                </Navbar>
            )}
            <Initial pageState={pageState} h="calc(100vh - 60px)">
                {!isOpenCashbox && !table_id && <AlertCashbox />}
                <Flex
                    minH={`calc(100vh - ${
                        !isOpenCashbox && !table_id ? '86' : '60'
                    }px)`}
                    w="full"
                    flexDirection={{ base: 'column', lg: 'row' }}
                    alignItems="stretch"
                >
                    <ContentLeft />
                    <ButtonSeeOrder
                        onClick={() =>
                            setShowDetailsOnMobile(!showDetailsOnMobile)
                        }
                    />
                    <ContentRight
                        showDetailsOnMobile={showDetailsOnMobile}
                        onClickBack={() => setShowDetailsOnMobile(false)}
                    />
                </Flex>
            </Initial>
        </>
    )
}
