import Icon from '@chakra-ui/icon'
import { Box, Flex, Text } from '@chakra-ui/layout'
import ArrowRight from '../../../../components/Icon/ArrowRight'
import Phone from '../../../../components/Icon/Phone'
import { ClientItemProps } from './types'

export default function ClientItem({
    isActive,
    title,
    subTitle,
    onClick
}: ClientItemProps) {
    return (
        <Flex
            w="full"
            bg={isActive ? 'blue.50' : 'white'}
            cursor="pointer"
            py={2}
            px={3}
            fontWeight={isActive ? 'bold' : 'light'}
            justify="space-between"
            alignItems="center"
            color={isActive ? 'blue.800' : 'gray.900'}
            pointerEvents={isActive ? 'none' : 'auto'}
            onClick={onClick}
            _hover={{
                bg: 'gray.50'
            }}
            _active={{
                bg: 'gray.100'
            }}
            borderBottomWidth={1}
        >
            <Box>
                <Text lineHeight={1} fontSize="sm">
                    {title}
                </Text>
                <Text fontSize="xs" color="blue.500" fontWeight="medium">
                    <Icon fontSize="9px" as={Phone} /> {subTitle}
                </Text>
            </Box>
            <Icon
                as={ArrowRight}
                fill={isActive ? 'blue.800' : 'gray.900'}
                h="3"
                w="3"
            />
        </Flex>
    )
}
