import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    useDisclosure
} from '@chakra-ui/react'
import { useEffect } from 'react'
import useStorage from '../../../../hooks/useStorage'
import useSubmitProduct from '../../hooks/useSubmitProduct'
import { ProductFormProps } from './types'

export default function ProductForm({
    element,
    edition,
    onDone
}: ProductFormProps) {
    const { onOpen, onClose, isOpen } = useDisclosure()
    const {
        setFieldNameMessage,
        setFieldUnitMessage,
        submitHandler,
        fieldNameMessage,
        fieldUnitMessage,
        isLoading
    } = useSubmitProduct(doneHandler, edition?.inventory_item_id || undefined)

    function doneHandler() {
        onDone()
        onClose()
    }
    const { type_measures } = useStorage()

    useEffect(() => {
        setFieldNameMessage('')
        setFieldUnitMessage('')
    }, [isOpen])

    return (
        <>
            {element(onOpen)}
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size="lg"
                closeOnOverlayClick
            >
                <ModalOverlay />
                <ModalContent px={6} py={4}>
                    <ModalHeader>
                        {edition ? 'Editar producto' : 'Nuevo producto'}{' '}
                    </ModalHeader>
                    <form onSubmit={submitHandler} noValidate>
                        <ModalBody pb={6}>
                            <FormControl
                                isRequired
                                mb={4}
                                isInvalid={!!fieldNameMessage}
                            >
                                <FormLabel>Nombre</FormLabel>
                                <Input
                                    name="name"
                                    placeholder="Ingresa el nombre del producto"
                                    onChange={() => setFieldNameMessage('')}
                                    defaultValue={
                                        edition?.inventory_item.name || ''
                                    }
                                />
                                <FormErrorMessage>
                                    {fieldNameMessage}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl
                                isRequired
                                isInvalid={!!fieldUnitMessage}
                            >
                                <FormLabel>Unidad</FormLabel>
                                <Select
                                    name="type_measure_id"
                                    placeholder="Selecciona la unidad de producto"
                                    onChange={() => setFieldUnitMessage('')}
                                    defaultValue={
                                        edition?.inventory_item
                                            .type_measure_id || undefined
                                    }
                                >
                                    {type_measures.map(typeMeasure => (
                                        <option
                                            key={typeMeasure.id}
                                            value={typeMeasure.id}
                                        >
                                            {typeMeasure.name}
                                        </option>
                                    ))}
                                </Select>
                                <FormErrorMessage>
                                    {fieldUnitMessage}
                                </FormErrorMessage>
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                variant="alo-gray:lg-md"
                                bg="transparent"
                                color="alo-gray"
                                onClick={onClose}
                                pointerEvents={isLoading ? 'none' : 'auto'}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="alo-green:lg-md"
                                ml={3}
                                type="submit"
                                isLoading={isLoading}
                            >
                                Confirmar
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}
