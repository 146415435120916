import { Box, Flex } from '@chakra-ui/react'
import { PinValues } from './types'

export default function Points({ values }: PointsProps) {
    return (
        <Flex justifyContent="center" mb={6}>
            {values.map((value, i) => (
                <Box
                    key={i}
                    bg={value === null ? 'transparent' : 'dark-2'}
                    w="20px"
                    h="20px"
                    mx={1}
                    borderRadius="full"
                    border="1px solid"
                    borderColor="dark-2"
                />
            ))}
        </Flex>
    )
}

interface PointsProps {
    values: PinValues
}
