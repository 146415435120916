import {
    Button,
    Container,
    Divider,
    Flex,
    Heading,
    Icon,
    Text
} from '@chakra-ui/react'
import { useRender } from 'redity'
import Close from '../../../components/Icon/Close'
import ModalKeyboard from '../../../components/ModalKeyboard'
import colors from '../../../config/theme/colors'
import useStorage from '../../../hooks/useStorage'
import { Keys } from '../constants'
import useMainContent from '../hooks/useMainContent'
import { storagePage } from '../storage'
import ClientSection from './ClientSection'
import PaymentMethods from './PaymentMethods'
import ResultPay from './ResultPay'
import TypeConsumption from './TypeConsumption'
import TypeOrder from './TypeOrder'
import TypeTax from './TypeTax'
import ModalPin from '../../../components/ModalPin'
import useSession from '../../../hooks/useSession'

export default function MainContent() {
    useRender(Keys.MAIN)
    const {
        discount,
        subtotal,
        total,
        valuePercentDiscount,
        typeDiscount,
        submitAddDiscountHandler,
        deleteDiscount
    } = useMainContent()

    const { session } = useSession()

    const { business_variables } = useStorage()
    const enable_type_tax = business_variables.find(
        v => v.code === '009'
    )?.value
    const enable_admin_pin = business_variables.find(
        v => v.code === '013'
    )?.value

    return (
        <Container maxW="container.md" color="alo-gray">
            <Flex
                justifyContent="space-between"
                alignItems={{ base: 'flex-start', lg: 'flex-end' }}
                mb={4}
                flexDirection={{ base: 'column', lg: 'row' }}
                gridGap={2}
            >
                <ClientSection />
                <TypeOrder />
            </Flex>
            <Divider />
            <Flex
                mt={4}
                justifyContent="space-between"
                flexDirection={{ base: 'column', md: 'row' }}
            >
                <Flex
                    flexDirection={{ base: 'row', md: 'column' }}
                    justifyContent="space-between"
                >
                    <Text alignSelf="center">Total de pedido</Text>
                    <Heading fontSize={{ base: 'xl', md: '2xl' }}>
                        S/ {subtotal.toFixed(2)}
                    </Heading>
                </Flex>
                <Flex
                    flexDirection={{ base: 'row', md: 'column' }}
                    justifyContent="space-between"
                >
                    <Flex alignSelf="center">
                        <Text>
                            Descuento{' '}
                            {typeDiscount === 'percentage'
                                ? `(${valuePercentDiscount}%)`
                                : `(S/)`}
                        </Text>

                        <ModalKeyboard
                            element={click => (
                                <ModalPin
                                    title="Pin de autorización"
                                    element={clickPin => (
                                        <Button
                                            color="white"
                                            fontSize="lg"
                                            cursor="pointer"
                                            onClick={
                                                enable_admin_pin === '1' &&
                                                !session?.user.is_vip
                                                    ? clickPin
                                                    : click
                                            }
                                            size="xs"
                                            ml={1}
                                            _hover={{}}
                                            _active={{}}
                                            bg={colors.success}
                                        >
                                            +
                                        </Button>
                                    )}
                                    onDone={click}
                                    adminPin
                                />
                            )}
                            title="Descuento del pedido"
                            maxValue={subtotal}
                            onDone={submitAddDiscountHandler}
                        />
                    </Flex>
                    <Flex>
                        <Icon
                            as={Close}
                            fontSize={12}
                            cursor="pointer"
                            onClick={deleteDiscount}
                            mr={3}
                            fill={colors.secondary}
                            alignSelf="center"
                            display={{ base: 'inline', md: 'none' }}
                        />
                        <Heading fontSize={{ base: 'xl', md: '2xl' }}>
                            S/ {discount.toFixed(2)}
                        </Heading>
                        <Icon
                            as={Close}
                            fontSize={14}
                            cursor="pointer"
                            onClick={deleteDiscount}
                            ml={3}
                            fill={colors.secondary}
                            alignSelf="center"
                            display={{ base: 'none', md: 'inline' }}
                        />
                    </Flex>
                </Flex>
                <Flex
                    flexDirection={{ base: 'row', md: 'column' }}
                    justifyContent="space-between"
                >
                    <Text alignSelf="center">Total a pagar</Text>
                    <Heading fontSize={{ base: '2xl', md: '3xl' }}>
                        S/ {total.toFixed(2)}
                    </Heading>
                </Flex>
            </Flex>
            <TypeConsumption />
            {enable_type_tax === '1' &&
                (storagePage.type == 'ticket' ||
                    storagePage.type == 'invoice') && <TypeTax />}
            <PaymentMethods />
            <ResultPay />
        </Container>
    )
}
