import { useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import useDelivery from '../../../hooks/useDelivery'
import useOverlapLoader from '../../../hooks/useOverlapLoader'
import useSession from '../../../hooks/useSession'
import { put } from '../../../lib/http'
import {
    useDeliveryForm,
    useDeliveryHelper
} from '../store/delivery-form.store'

export default function useAnnul() {
    const { delivery, setDelivery, setClient, setProducts } = useDelivery()
    const { clear: clearForm } = useDeliveryForm()
    const { clear } = useDeliveryHelper()
    const loader = useOverlapLoader()
    const { push } = useHistory()
    const { session } = useSession()
    const toast = useToast()

    async function annul(reason: string) {
        loader.start('Anulando delivery')
        const { error } = await put(`/api/deliveries/${delivery?.id}/annul`, {
            reason,
            userId: session?.user.id
        })
        loader.end()
        if (error) {
            toast({
                title: 'No fue posible anular el pedido, vuelva a reintentar.',
                status: 'error',
                position: 'top'
            })
            return
        }
        setDelivery(null)
        setClient(null)
        setProducts([])
        clearForm()
        clear()
        toast({
            title: 'Delivery anulado.',
            status: 'success',
            position: 'top'
        })
        push('/deliveries')
    }

    return annul
}
