import { Box, Button, Flex, Text, useId, useToast } from '@chakra-ui/react'
import { ItemDetailProps } from './types'
import Check from '../../../../components/Icon/Check'
import colors from '../../../../config/theme/colors'
import { useState } from 'react'
import { put } from '../../../../lib/http'

export default function ItemDetail({
    label,
    description,
    defaultCounter,
    maxQuantity,
    orderItemStateId,
    onLoad
}: // date
ItemDetailProps) {
    const toast = useToast()
    const id = useId()
    const [count, setCount] = useState(() => defaultCounter)

    async function counterHandler() {
        if (count >= maxQuantity) return
        setCount(count + 1)
        const { error } = await put(
            `/api/order_item_states/${orderItemStateId}/counter`,
            { value: 1 }
        )
        if (error) {
            setCount(_count => _count - 1)
            toast({
                title: 'No fue posible actualizar. Vuelva a intentar',
                position: 'top',
                status: 'error'
            })
        } else {
            onLoad()
        }
    }

    async function desmisHandler() {
        if (count <= 0) return
        setCount(count - 1)
        const { error } = await put(
            `/api/order_item_states/${orderItemStateId}/counter`,
            { value: -1 }
        )
        if (error) {
            setCount(_count => _count + 1)
            toast({
                title: 'No fue posible actualizar. Vuelva a intentar',
                position: 'top',
                status: 'error'
            })
        } else {
            onLoad()
        }
    }

    return (
        <Box
            w="full"
            userSelect="none"
            p={2}
            borderRadius="md"
            borderWidth="1px"
            borderColor="gray.300"
        >
            {/* <Text color="gray.600" fontSize="9px" lineHeight={0.8}>
                {dayjs(date).format('hh:mm A')}
            </Text> */}
            <Flex justify="space-between">
                <Text>
                    <label htmlFor={id}>{label}</label>
                </Text>
                <Flex gap={1}>
                    <Button
                        id={id}
                        size="xs"
                        onClick={counterHandler}
                        leftIcon={
                            count >= maxQuantity ? (
                                <Check fill={colors['alo-green']} />
                            ) : undefined
                        }
                    >
                        {count} de {maxQuantity}
                    </Button>
                    <Button onClick={desmisHandler} size="xs">
                        -
                    </Button>
                </Flex>
            </Flex>
            {description && <Text fontSize="xs">{description}</Text>}
        </Box>
    )
}
