export default function Download(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 7 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.115 2.839H4.389V.249H1.8v2.59H.074l3.02 3.02 3.02-3.02zm-3.452.863v-2.59h.863v2.59h.505l-.936.936-.937-.936h.505zm3.452 3.883v-.863H.075v.863h6.04z"
            />
        </svg>
    )
}
