import { Icon } from '@chakra-ui/react'
import Transfer from '../../../components/Icon/Transfer'
import Colors from '../../../config/colors'
import Supply from '../../../models/supply'
import useEditionStock from './useEditionStock'

export default function EditionStock({ supply }: { supply: Supply }) {
    const openModalStock = useEditionStock(supply)

    return (
        <Icon
            fill={supply.has_inventory_control ? Colors.INFO : 'gray'}
            as={Transfer}
            marginRight={2}
            cursor={supply.has_inventory_control ? 'pointer' : 'normal'}
            pointerEvents={supply.has_inventory_control ? 'auto' : 'none'}
            onClick={openModalStock}
        />
    )
}
