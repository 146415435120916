import { storagePage } from '../storage'

export default function getItemsSelected(itemId: number) {
    const { selectedItems } = storagePage

    const itemsFinded = selectedItems.find(selected => selected.id === itemId)

    return {
        items: itemsFinded
    }
}
