import { States } from './types'

const states: States = {
    isOpen: false,
    Component: () => null as any,
    title: '',
    size: 'md',
    onSubmit: () => null,
    isLoading: false,
    cleaned: false,
    isCentered: false,
    closeOnOverlayClick: true,
    centerTitle: false
}

export default states
