import { useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { ChangeEvent, useEffect, useState } from 'react'
import { render, useRender } from 'redity'
import { post } from '../../../lib/http'
import getPrinterConfig from '../../../utilities/getPrinterConfig'
import { Keys } from '../constants'
//import { Keys } from '../constants'
import { storagePage } from '../storage'
import { BodyRegisterPayment, creditFee } from '../types'
import useSelectedItems from './useSelectedItems'
import { useGet } from '../../../hooks/useHttp'
import Serie from '../../../models/serie'
import useDialog from '../../../hooks/useDialog'
import modal, { close } from '../../../lib/modal'
import ContentModalLoading from '../Modals/ContentModalLoading'
import { DateWithTimeCurrent } from '../../../utilities/date_tools'
import { ResponseGenerateFormSunatVoucher } from '../types'

export default function useMainContent() {
    const r = useRender()

    const toast = useToast()

    const {
        subtotal,
        discount,
        total,
        typeDiscount,
        valuePercentDiscount,
        totalCredit,
        accumulatedPayment
    } = useSelectedItems()

    // Cuotas de crédito
    /*
    const [fees, setFees] = useState<any>([
        {
            due_date: dayjs().add(30, 'day').format('YYYY-MM-DD'),
            amount: total
        }
    ])
    */

    function submitAddDiscountHandler(value: string, type: string) {
        storagePage.discount = Number(value)
        storagePage.typeDiscount = type
        r()
        render(Keys.PRODUCTS_SECTION)
        render(Keys.PAYMENTS_SECTION)
        render(Keys.MAIN)
    }

    function deleteDiscount() {
        storagePage.discount = Number(0)
        storagePage.typeDiscount = 'amount'
        r()
        render(Keys.PRODUCTS_SECTION)
        render(Keys.PAYMENTS_SECTION)
        render(Keys.MAIN)
    }

    const [issueDate, setIssueDate] = useState(() =>
        dayjs().format('YYYY-MM-DD')
    )
    const [dueDate, setDueDate] = useState(() => dayjs().format('YYYY-MM-DD'))
    const [type, setType] = useState(storagePage.type)

    function changeHandlerDate(ev: ChangeEvent<HTMLInputElement>) {
        if (ev.target.name == 'issue_date') {
            storagePage.issueDate = ev.target.value
            setIssueDate(ev.target.value)
        } else {
            storagePage.dueDate = ev.target.value
            setDueDate(ev.target.value)
        }
        render(Keys.PAYMENTS_SECTION)
    }

    function changeHandlerType(ev: ChangeEvent<HTMLSelectElement>) {
        setType(ev.target.value)
        storagePage.type = ev.target.value
        render(Keys.PAYMENTS_SECTION)
    }

    const [serie, setSerie] = useState(storagePage.serie)

    function changeHandlerSerie(ev: ChangeEvent<HTMLSelectElement>) {
        setSerie(Number(ev.target.value))
        storagePage.serie = Number(ev.target.value)
        render(Keys.PAYMENTS_SECTION)
    }

    function onChangeCancel() {
        clearStates()
    }

    function clearStates() {
        storagePage.card = []
        storagePage.cash = []
        storagePage.transfer = []
        storagePage.creditFees = []
        storagePage.selectedItems = []
        storagePage.clientSelected = storagePage.defaultClient
        storagePage.typeDiscount = 'amount'
        storagePage.discount = 0

        storagePage.type = ''

        storagePage.typeTax = 'levied'
        storagePage.condition = 'cash'
        storagePage.issueDate = dayjs().format('YYYY-MM-DD')
        storagePage.dueDate = dayjs().format('YYYY-MM-DD')

        setType('')
        setIssueDate(dayjs().format('YYYY-MM-DD'))
        setDueDate(dayjs().format('YYYY-MM-DD'))
    }

    // Preparar body para generar voucher
    const payments: BodyRegisterPayment[] = []
    if (storagePage.cash.length > 0) {
        payments.push({
            type_payment_id: 1,
            amounts: storagePage.cash
        })
    }

    if (storagePage.card.length > 0) {
        payments.push({
            type_payment_id: 2,
            amounts: storagePage.card
        })
    }

    if (storagePage.transfer.length > 0) {
        payments.push({
            type_payment_id: 3,
            amounts: storagePage.transfer
        })
    }

    const enableButton =
        storagePage.selectedItems.length > 0 &&
        (storagePage.condition == 'credit' || payments.length > 0)

    let typeVoucher = 0
    switch (storagePage.type) {
        case 'ticket':
            typeVoucher = 1
            break
        case 'invoice':
            typeVoucher = 2
            break
    }

    const printerId = getPrinterConfig()?.printer_id

    const items: any = []
    storagePage.selectedItems.map(selectedItem =>
        items.push({
            id: selectedItem.id,
            price: selectedItem.item.price,
            quantity: selectedItem.quantity
        })
    )

    const typeTax = storagePage.typeTax == 'levied' ? 1 : 2

    const dialogCreditExceedsTotal = useDialog({
        title: 'Operación inválida',
        description: (
            <>
                El monto a crédito <b>S/ {totalCredit.toFixed(2)}</b> no debe
                superar el monto total a pagar <b>S/ {total.toFixed(2)}</b>.
            </>
        )
    })

    const dialogTotalExceedsCredit = useDialog({
        title: 'Operación inválida',
        description: (
            <>
                El monto a crédito <b>S/ {totalCredit.toFixed(2)}</b> debe ser
                igual al total a pagar <b>S/ {total.toFixed(2)}</b>.
            </>
        )
    })

    const dialogTotalExceedsAccumulatedPayment = useDialog({
        title: 'Operación inválida',
        description: (
            <>
                El monto acumulado <b>S/ {accumulatedPayment.toFixed(2)}</b> no
                debe ser menor que el monto total a pagar{' '}
                <b>S/ {total.toFixed(2)}</b>.
            </>
        )
    })

    const dialogAccumulatedPaymentExceedsTotal = useDialog({
        title: 'Operación inválida',
        description: (
            <>
                El monto acumulado <b>S/ {accumulatedPayment.toFixed(2)}</b> no
                debe superar el monto total a pagar <b>S/ {total.toFixed(2)}</b>
                .
            </>
        )
    })

    const dialogincorretDateValue = useDialog({
        title: 'Operación inválida',
        description: (
            <>
                La fecha de vencimiento de alguna cuota tiene un formato de
                fecha incorrecto
            </>
        )
    })

    const dialogIncorrectCreditDates = useDialog({
        title: 'Operación inválida',
        description: (
            <>
                Las fechas de vencimiento de las cuotas deben ser posteriores
                unas de otras
            </>
        )
    })

    async function submitHandler() {
        if (storagePage.condition == 'credit' && totalCredit > total) {
            dialogCreditExceedsTotal()
            return
        }

        if (storagePage.condition == 'credit' && total > totalCredit) {
            dialogTotalExceedsCredit()
            return
        }

        if (storagePage.condition == 'credit' && incorretDateValue()) {
            dialogincorretDateValue()
            return
        }

        if (storagePage.condition == 'credit' && incorretCreditDates()) {
            dialogIncorrectCreditDates()
            return
        }

        if (storagePage.condition == 'cash' && accumulatedPayment > total) {
            dialogAccumulatedPaymentExceedsTotal()
            return
        }
        if (storagePage.condition == 'cash' && accumulatedPayment < total) {
            dialogTotalExceedsAccumulatedPayment()
            return
        }

        modal(ContentModalLoading, {
            cleaned: true,
            closeOnOverlayClick: false,
            size: 'md'
        })

        //Actualizamos para obtener la fecha del Issue Date y la hora actual
        const new_issue_date = DateWithTimeCurrent(storagePage.issueDate)

        //Actualizamos para obtener la fecha del Due Date y la hora actual
        let new_due_date = null
        if (storagePage.dueDate.length > 0) {
            new_due_date = DateWithTimeCurrent(storagePage.dueDate)
        }

        // Preparar body para generar voucher
        const creditFees: creditFee[] = []
        storagePage.creditFees.map(quota =>
            creditFees.push({
                due_date: DateWithTimeCurrent(quota.due_date),
                amount: quota.amount
            })
        )

        const result = await post<ResponseGenerateFormSunatVoucher>(
            '/api/sunat_vouchers/generate_voucher_form',
            {
                issue_date: new_issue_date,
                due_date: new_due_date,
                type_voucher_id: typeVoucher,
                client_id: storagePage.clientSelected?.id,
                printer_id: printerId,
                serie_id: storagePage.serie,
                discount:
                    storagePage.typeDiscount === 'amount'
                        ? storagePage.discount
                        : 0,
                percentage_discount:
                    storagePage.typeDiscount === 'percentage'
                        ? storagePage.discount
                        : 0,
                total_original: subtotal,
                total_final: total,
                items: items,
                ...(storagePage.condition == 'cash'
                    ? { payments: payments }
                    : { payment_quotas: creditFees }),
                type_tax: typeTax,
                outstandings: storagePage.condition == 'cash' ? false : true
            }
        )

        if (result.data) {
            await Promise.all([
                post('/api/sunat_vouchers/send_sunat_voucher', {
                    filename: result.data.result.result.xml_name
                })
            ])

            done()
        } else {
            toast({
                title: result.error?.message,
                status: 'error',
                position: 'top'
            })
        }

        close()
    }

    /*
    let data_series
    let reload_series: any
    
    if (typeVoucher > 0) {
        const { data, reload } = useGet<Serie[]>(
            `/api/sunat_vouchers/series?voucher_type_id=${typeVoucher}`,
            []
        )
        data_series = data
        reload_series = reload
    }
    */

    //data_series = data
    //reload_series = reload

    const { data, reload, error } = useGet<Serie[]>(
        `/api/sunat_vouchers/series?voucher_type_id=${typeVoucher}`,
        []
    )

    function done() {
        toast({
            title: 'Comprobante registrado',
            status: 'success',
            position: 'top',
            isClosable: true
        })
        clearStates()
    }

    function incorretDateValue(): boolean {
        for (const quote of storagePage.creditFees) {
            if (quote.due_date === undefined || quote.due_date === '') {
                return true
            }
        }
        return false
    }

    function incorretCreditDates(): boolean {
        const issue_date = new Date()
        let temp_date = issue_date

        for (const quote of storagePage.creditFees) {
            const quote_date = new Date(quote.due_date + ' 05:00:00')

            if (quote_date.getTime() <= temp_date.getTime()) {
                return true
            }
            temp_date = quote_date
        }

        return false
    }

    useEffect(() => {
        reload()
    }, [type])

    return {
        subtotal,
        discount,
        total,
        valuePercentDiscount,
        typeDiscount,
        submitAddDiscountHandler,
        deleteDiscount,
        fees: storagePage.creditFees,
        //setFees,
        issueDate,
        dueDate,
        type,
        changeHandlerDate,
        changeHandlerType,
        onChangeCancel,
        submitHandler,
        enableButton,
        serie,
        changeHandlerSerie,
        data: error ? ([] as Serie[]) : data.result
    }
}
