import {
    Modal,
    ModalOverlay,
    ModalBody,
    ModalContent,
    Text,
    Box,
    Button,
    SimpleGrid,
    useToast
} from '@chakra-ui/react'
import Points from './Points'
import { DiscountProps } from './types'
import usePin from '../../hooks/usePin'
import ButtonsActions from './ButtonsActions'
import { useEffect, useState } from 'react'
import { post } from '../../../../lib/http'
import useOrderByTable from '../../hooks/useOrderByTable'

const buttonWidth = { base: '65px', sm: '80px', md: '90px' }

export default function ModalPin({ isOpen, onClose, onPin }: DiscountProps) {
    const toast = useToast()
    const { orderId } = useOrderByTable()
    const [isLoading, setLoading] = useState(false)
    const { numbers, clear, removeLastDigit, digit, pinValues } = usePin()

    async function okHandler() {
        if (pinValues.find(value => value === null) === null) return
        const pin = pinValues.join('')
        if (orderId) {
            setLoading(true)
            const { error } = await post('/api/utils/pin/order', {
                pin,
                order_id: orderId
            })
            setLoading(false)

            if (error) {
                toast({
                    title: error.message,
                    position: 'top',
                    status: 'error'
                })
                return
            }
        }
        onPin(pin)
        clear()
        onClose()
    }

    useEffect(() => {
        clear()
    }, [isOpen])

    return (
        <Modal
            isCentered
            size="lg"
            isOpen={isOpen}
            closeOnOverlayClick={!isLoading}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalBody py="10" px="12">
                    <Text
                        fontSize="2xl"
                        fontWeight="bold"
                        textAlign="center"
                        mb="2"
                    >
                        Procesar pedido
                    </Text>
                    <Text
                        mb="6"
                        textAlign="center"
                        color="gray-2"
                        fontSize="sm"
                    >
                        Ingresa tu PIN para realizar pedido
                    </Text>
                    <Points values={pinValues} />
                    <Box display="flex" justifyContent="space-around">
                        <Box textAlign="center">
                            <SimpleGrid gap="3" columns={3}>
                                {numbers.map((number: number) => (
                                    <Button
                                        key={number}
                                        w={buttonWidth}
                                        h="20"
                                        variant="outline"
                                        borderColor="gray.500"
                                        fontSize="2xl"
                                        rounded="xl"
                                        size="lg"
                                        onClick={() => digit(number)}
                                    >
                                        {number}
                                    </Button>
                                ))}
                            </SimpleGrid>
                            <Button
                                mt="3"
                                w={buttonWidth}
                                variant="outline"
                                borderColor="gray.500"
                                size="lg"
                                fontSize="2xl"
                                rounded="xl"
                                h="20"
                                onClick={() => digit(0)}
                            >
                                0
                            </Button>
                        </Box>
                        <ButtonsActions
                            onClear={clear}
                            onRemove={removeLastDigit}
                            onOk={okHandler}
                            isLoading={isLoading}
                        />
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
