import FormEvent from '../../lib/form_event'
import ControlList from '../../lib/list/ControlList'
import ProductionItem from '../../models/production_item'
import ProductionItemCategory from '../../models/production_item_category'
import Warehouse from '../../models/warehouse'
import { FormItemProps } from './types'

export const PATH_ENDPOINT_CATEGORY = '/api/production_item_categories'
export const PATH_ENDPOINT_ITEM = '/api/production_items'
const PATH_WAREHOUSES = '/api/warehouses'

export const listProdItemCategories = new ControlList<ProductionItemCategory>({
    path: PATH_ENDPOINT_CATEGORY,
    theads: ['#', 'Nombre', 'Acciones']
})

export const formCategory = new FormEvent({
    id: '',
    name: ''
})

export const listProductionItems = new ControlList<ProductionItem>({
    path: PATH_ENDPOINT_ITEM,
    theads: ['#', 'Nombre', 'Categoría', 'Unidad de medida', 'Acciones']
})

export const listWarehouses = new ControlList<Warehouse>({
    path: PATH_WAREHOUSES,
    theads: ['', 'Nombre']
})

export const formItem = new FormEvent<FormItemProps>({
    id: '',
    name: '',
    category_id: '',
    magnitude_id: '',
    inventory: []
})
