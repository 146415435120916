import { useToast } from '@chakra-ui/react'
import { FormEvent } from 'react'
import { render } from 'redity'
import { post } from '../../../lib/http'
import modal, { close, loading } from '../../../lib/modal'
import val from '../../../lib/val'
import { formOpenCashbox, storagePage } from '../storage'
import { Keys } from '../constant'
import ModalAreYouSure from '../ContentModals/ModalAreYouSure'
import ModalOpenTheBox from '../ContentModals/ModalOpenTheBox'
import loadMain from '../utilities/loadMain'
import useSession from '../../../hooks/useSession'

export default function useOpenCashbox() {
    const toast = useToast()
    const { isAdmin } = useSession()

    async function submitConfirmHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        loading()
        const { cashbox_id, amount, cashbox_name } = formOpenCashbox.store()
        const { error } = await post('/api/cashbox_users', {
            amount: Number(amount),
            cashbox_id: parseInt(cashbox_id)
        })
        if (error) {
            toast({
                status: 'error',
                title: `Lo sentimos, hubo problemas para aperturar la caja ${cashbox_name}. Vuelva a reintentar.`,
                position: 'top'
            })
        } else {
            toast({
                status: 'success',
                title: `Se aperturó ${cashbox_name}`,
                position: 'top'
            })
            await loadMain(storagePage.currentCashbox?.id || 1)
            render(Keys.MAIN)
        }
        close()
    }

    function submitOpenHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const form = formOpenCashbox.store()
        const v = val(form)
        v.cashbox_id.isEmpty('Se requiere seleccionar la caja')
        v.amount.isEmpty('Se requiere agregar el monto de apertura')
        const result = v.runtest()
        if (result) {
            formOpenCashbox.setHelpers(result)
            return
        }
        close()
        setTimeout(() => {
            modal(ModalAreYouSure, {
                cleaned: true,
                size: 'md',
                onSubmit: submitConfirmHandler
            })
        }, 100)
    }

    function openCashbox() {
        formOpenCashbox.init()
        if (isAdmin()) {
            formOpenCashbox.setValue(
                'cashbox_id',
                (storagePage.currentCashbox?.id || 0).toString()
            )
            formOpenCashbox.setValue(
                'cashbox_name',
                storagePage.currentCashbox?.name || ''
            )
        }
        formOpenCashbox.init(formOpenCashbox.store)
        modal(ModalOpenTheBox, {
            size: 'md',
            onSubmit: submitOpenHandler
        })
    }

    const isDisabled = storagePage.userSessionHaveOpenBox

    return {
        openCashbox,
        isDisabled
    }
}
