import { Box, Tr, Td } from '@chakra-ui/react'
import Initial from '../../components/Initial'
import MenuUser from '../../components/MenuUser'
import Navbar from '../../components/Navbar'
import NTabs from '../../components/NTabs'
import List from '../../lib/list'
import Booking from '../../models/booking'
import PageState from '../../types/pageState'
import DeletePreOrder from './components/PreOrder/DeletePreOrder'
import EditionPreOrder from './components/PreOrder/EditionPreOrder'
import ShowPreOrder from './components/PreOrder/ShowPreOrder'
import { listPreOrder, TYPE_BOOKINGS_VALUE } from './storage'
import useInitial from './hooks/useInitial'
import dayjs from 'dayjs'
import HeadTable from './components/HeadTable'
import useSession from '../../hooks/useSession'

function getText(booking: Booking) {
    if (booking.type === TYPE_BOOKINGS_VALUE.AGENCY) {
        return `${booking.agency?.name ?? ''} - ${
            booking.agency_guide?.name ?? ''
        } `
    }

    if (booking.type === TYPE_BOOKINGS_VALUE.DRIVER) {
        return booking.driver?.name || ''
    }

    return booking.client_name
}

const PreOrders = () => {
    useInitial()
    const { isWaiter, isLogged } = useSession()
    const _isWaiter = (isWaiter() && isLogged) || !isLogged

    return (
        <Initial pageState={PageState.SUCCESS}>
            <Navbar component={<NTabs />}>
                <Box px="5" py="1">
                    <MenuUser />
                </Box>
            </Navbar>
            <Box
                w={{
                    base: '100vw',
                    lg: 'calc(100vw - 232px)',
                    xl: 'calc(100vw - 250px)'
                }}
                px={{
                    xl: 12,
                    lg: 8,
                    md: '5',
                    base: '4'
                }}
                py={6}
                mx="auto"
            >
                <HeadTable />
                <List
                    control={listPreOrder}
                    tbody={(preOrder, i) => (
                        <Tr key={i}>
                            <Td>{i}</Td>
                            <Td>
                                {dayjs(preOrder.booking.hour).format('hh:mm A')}
                            </Td>
                            <Td>
                                {preOrder.alias.trim() ||
                                    getText(preOrder.booking)}
                            </Td>
                            <Td textAlign="center">
                                {getText(preOrder.booking)}
                            </Td>
                            <Td textAlign="center">S/{preOrder.amount ?? 0}</Td>
                            <Td textAlign="center">
                                {preOrder.pre_order_state === 2
                                    ? 'ATENDIDO'
                                    : 'EN ESPERA'}
                            </Td>
                            <Td textAlign={'center'}>
                                <ShowPreOrder preOrder={preOrder} />
                                <EditionPreOrder preOrder={preOrder} />
                                {!_isWaiter && (
                                    <DeletePreOrder preOrder={preOrder} />
                                )}
                            </Td>
                        </Tr>
                    )}
                />
            </Box>
        </Initial>
    )
}

export default PreOrders
