export default function Grid(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 .963h16c1.1 0 2 .9 2 2v16c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2v-16c0-1.1.9-2 2-2zm0 18h4v-4H2v4zm4-6H2v-4h4v4zm-4-6h4v-4H2v4zm10 12H8v-4h4v4zm-4-6h4v-4H8v4zm4-6H8v-4h4v4zm2 12h4v-4h-4v4zm4-6h-4v-4h4v4zm-4-6h4v-4h-4v4z"
            />
        </svg>
    )
}
