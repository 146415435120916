import { Box, Heading, useToast, Switch } from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
import { put } from '../../../lib/http'
import Table from '../../../models/table'
import Edition from './Edition'
import { listTable } from '../storage'
import hexRgb from 'hex-rgb'
import colors from '../../../config/theme/colors'

const h = hexRgb as any

export default function CardTable({ table }: CardTableProps) {
    const [checked, setChecked] = useState(() => table.is_enabled)
    const toast = useToast()

    async function changeHandler(ev: ChangeEvent<HTMLInputElement>) {
        const is_enabled = ev.target.checked
        setChecked(is_enabled)
        const { error } = await put(`/api/tables/${table.id}`, {
            name: table.name,
            is_enabled
        })
        if (error) {
            setChecked(!is_enabled)
            toast({
                title: 'Hubo problemas al actualizar, vuelva a intentar',
                status: 'error',
                position: 'top',
                isClosable: true
            })
        } else {
            toast({
                title: is_enabled
                    ? 'Se ha habilitado la mesa'
                    : 'Se ha deshabilitado la mesa',
                status: 'success',
                position: 'top',
                isClosable: true
            })
            listTable.load()
        }
    }

    return (
        <Box
            key={table.id}
            borderRadius="10px"
            bg={
                table.is_enabled
                    ? 'white'
                    : h(colors['gray-2'], { alpha: 0.1, format: 'css' })
            }
            w="100%"
            h="147px"
            transition="ease"
            border="1px solid #E5E7EB"
            p="4"
            display="flex"
            flexDirection={'column'}
            justifyContent={'space-between'}
        >
            <Heading
                fontSize="3xl"
                display="flex"
                h="100%"
                alignItems={'center'}
                justifyContent="center"
                fontWeight="bold"
                textAlign="center"
                mb={4}
                color={table.is_enabled ? colors['alo-gray'] : colors.black}
            >
                {table.name}
            </Heading>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Edition table={table} />
                <Switch
                    colorScheme="green"
                    size="md"
                    isChecked={checked}
                    onChange={changeHandler}
                />
            </Box>
        </Box>
    )
}

interface CardTableProps {
    table: Table
}
