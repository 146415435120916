import { Button, Icon, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'
import Close from '../components/Icon/Close'
import modal, { close } from '../lib/modal'

export default function useDialog({ title, description }: DialogProps) {
    function Dialog() {
        return (
            <>
                <Icon
                    as={Close}
                    fill="error"
                    mx="auto"
                    fontSize="2xl"
                    my={4}
                    display="block"
                />
                <Text
                    fontSize="2xl"
                    fontWeight="bold"
                    textAlign="center"
                    mb="5"
                >
                    {title}
                </Text>
                <Text textAlign="center">{description}</Text>
                <Button
                    mt="8"
                    variant="outline"
                    mx="auto"
                    display="block"
                    w="32"
                    onClick={() => close()}
                >
                    Aceptar
                </Button>
            </>
        )
    }

    function openModal() {
        modal(Dialog, {
            cleaned: true,
            size: 'md'
        })
    }

    return openModal
}

export interface DialogProps {
    title: string
    description: ReactNode
    type?: 'error' | 'success' | 'warning'
}
