import YapeLogo from '../../../assets/yape-logo.png'
import PlinLogo from '../../../assets/plin-logo.png'
import BcpLogo from '../../../assets/bcp-logo.png'
import CreditCard from '../../../assets/credit-card.png'
// import InterbankLogo from '../../../assets/interbank-logo.png'
// import BBVALogo from '../../../assets/bbva-logo.png'
// import ScotiabankLogo from '../../../assets/scotiabank-logo.png'
// import PedidosYaLogo from '../../../assets/pedidosya-logo.png'
// import RappiLogo from '../../../assets/rappi-logo.png'
import Cash from '../../../assets/cash.jpg'
import { PaymentMethod } from '../../../types/deliveryMode'

export const paymentLogos: PaymentLogo[] = [
    { src: Cash, value: PaymentMethod.CASH.toString(), label: 'Efectivo' },
    { src: CreditCard, value: PaymentMethod.CARD.toString(), label: 'Tarjeta' },
    { src: YapeLogo, value: PaymentMethod.YAPE.toString(), label: 'Yape' },
    { src: PlinLogo, value: PaymentMethod.PLIN.toString(), label: 'Plin' },
    { src: BcpLogo, value: PaymentMethod.BCP.toString(), label: 'BCP' }
]

export interface PaymentLogo {
    src: string
    value: string
    label: string
}
