import { FormControl, FormErrorMessage, RadioGroup } from '@chakra-ui/react'
import useDelivery from '../../../../../hooks/useDelivery'
import Client from '../../../../../models/client'
import { DeliveryStatus } from '../../../../../types/deliveryMode'
import AddressFormModal from '../../../modals/AddressFormModal'
import BoxContent from '../../../shared/BoxContent'
import RadioLabel from '../../../shared/RadioLabel'
import {
    useDeliveryForm,
    useDeliveryHelper
} from '../../../store/delivery-form.store'

export default function AddressesList() {
    const { client, delivery } = useDelivery()
    const address = useDeliveryForm(state => state.address)
    const set = useDeliveryForm(state => state.set)
    const helpers = useDeliveryHelper()

    function updateAddress(addressValue: string) {
        const _address = (client as Client).addresses.find(
            ([__address]) => __address === addressValue
        )

        helpers.setHelpers({ address: '' })

        if (!_address) return

        set({ address: [_address[0], _address[1]] })
    }

    return (
        <BoxContent title="Seleccione dirección">
            <FormControl isInvalid={!!helpers.address}>
                <RadioGroup
                    value={address[0]}
                    mb={1}
                    onChange={updateAddress}
                    isDisabled={
                        delivery
                            ? delivery.status > 2 ||
                              delivery.status === DeliveryStatus.CANCELED
                            : false
                    }
                >
                    {(client as Client).addresses.map(_address => (
                        <RadioLabel
                            key={_address[0]}
                            value={_address[0]}
                            label={_address[0]}
                            description={_address[1]}
                        />
                    ))}
                </RadioGroup>
                <FormErrorMessage>{helpers.address}</FormErrorMessage>
            </FormControl>
            <AddressFormModal />
        </BoxContent>
    )
}
