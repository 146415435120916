import React from 'react'
import Warehouse from '../../../models/warehouse'
import { Select, FormLabel } from '@chakra-ui/react'
import ErrorHelper from '../../../components/ErrorHelper'

interface Props {
    options: Warehouse[]
    handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
    helper: string
    value: string
    label?: string
}

export default function SelectWarehouse({
    options,
    handleChange,
    helper,
    value,
    ...props
}: Props) {
    return (
        <>
            <FormLabel> {props.label ?? 'Almacén'} </FormLabel>
            <Select
                value={value ?? ''}
                onChange={handleChange}
                placeholder="Seleccionar almacén"
            >
                {options.map(warehoouse => (
                    <option key={warehoouse.id} value={warehoouse.id}>
                        {warehoouse.name}
                    </option>
                ))}
            </Select>

            <ErrorHelper helper={helper} />
        </>
    )
}
