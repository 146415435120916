import { useToast } from '@chakra-ui/react'
import useSubmit from '../../../hooks/useSubmit'
import modal from '../../../lib/modal'
import Agency from '../../../models/agency'
import { formAgency, listAgency, PATH_ENDPOINT_AGENCY } from '../storage'
import FormFeature from './FormAgency'

export default function useAgency(agency?: Agency) {
    const toast = useToast()
    const { validation, observer, submit } = useSubmit(formAgency, {
        path: agency
            ? `${PATH_ENDPOINT_AGENCY}/${agency.id}`
            : PATH_ENDPOINT_AGENCY,
        method: agency ? 'put' : 'post',
        done: () => listAgency.load(),
        error: e => {
            toast({
                title: e.message,
                position: 'top',
                status: 'error'
            })
        }
    })

    validation(v => {
        v.name.isEmpty('Es requerido el nombre')
        v.agency_guides.isEmpty('Es requerido mínimo 1 guía')
    })

    observer<FormSubmit>(f => ({
        name: f.name,
        agency_guides: f.agency_guides.map((sf: any) => {
            return {
                name: sf.name,
                ...(sf.id && {
                    id: sf.id
                })
            }
        })
    }))

    function openModal() {
        if (agency) {
            formAgency.store({
                name: agency.name,
                agency_guides: agency.agency_guides
            })
            formAgency.init(formAgency.store)
        } else {
            formAgency.init()
        }
        modal(FormFeature, {
            title: agency ? 'Editar Agencia' : 'Nueva Agencia',
            onSubmit: submit,
            size: '2xl'
        })
    }

    return openModal
}

interface FormSubmit {
    name: string
    agency_guides: any[]
}
