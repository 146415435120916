import { MenuItemOption, MenuOptionGroup } from '@chakra-ui/react'
import { useState } from 'react'
import MultipleSelector from '../../../components/MultipleSelector'
import { controlList } from '../storage'

export default function TypeProofFilter() {
    const [proofTypes, setProofTypes] = useState<string | string[]>([
        'with_invoices',
        'with_tickets',
        'with_sale_notes'
    ])

    const changeHandler = (_proofTypes: string | string[]) => {
        setProofTypes(_proofTypes)
        if (typeof _proofTypes === 'string') {
            controlList.setQuery(_proofTypes, true)
        } else {
            if (_proofTypes.includes('with_invoices')) {
                controlList.setQuery('with_invoices', true)
            } else {
                controlList.setQuery('with_invoices', false)
            }

            if (_proofTypes.includes('with_tickets')) {
                controlList.setQuery('with_tickets', true)
            } else {
                controlList.setQuery('with_tickets', false)
            }

            if (_proofTypes.includes('with_sale_notes')) {
                controlList.setQuery('with_sale_notes', true)
            } else {
                controlList.setQuery('with_sale_notes', false)
            }
        }
        controlList.load()
    }

    return (
        <MultipleSelector label={'Seleccionar tipo de comprobante'}>
            <MenuOptionGroup
                type="checkbox"
                value={proofTypes}
                onChange={changeHandler}
            >
                <MenuItemOption value="with_invoices">Factura</MenuItemOption>
                <MenuItemOption value="with_tickets">Boleta</MenuItemOption>
                <MenuItemOption value="with_sale_notes">
                    Nota de venta
                </MenuItemOption>
            </MenuOptionGroup>
        </MultipleSelector>
    )
}
