import { TYPES_DISCOUNT } from '../constants'
import { storagePage } from '../storage'

export default function clearStates() {
    storagePage.typeConsumption = null
    storagePage.totalFinal = 0
    storagePage.totalOriginal = 0
    storagePage.discount = 0
    storagePage.subTotal = 0
    storagePage.alias = ''
    storagePage.client = null
    storagePage.code = ''
    storagePage.percentageDiscount = 0
    storagePage.typeDiscount = TYPES_DISCOUNT.AMOUNT
    storagePage.card = []
    storagePage.cash = []
    storagePage.transfer = []
    storagePage.type = null
    storagePage.paymentTypes = []
}
