export default function ArrowUp(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 18 20"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_2_40973)">
                <path d="M12 10.828L7.05 15.778L5.636 14.364L12 8L18.364 14.364L16.95 15.778L12 10.828Z" />
            </g>
            <defs>
                <clipPath id="clip0_2_40973">
                    <rect width={24} height={24} />
                </clipPath>
            </defs>
        </svg>
    )
}
