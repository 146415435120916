import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { post } from '../../../lib/http'
// import getIPsConfig from '../../../utilities/getIpsConfig'
import getPrinterConfig from '../../../utilities/getPrinterConfig'
import { storagePage } from '../storage'

export default function usePrecount() {
    const toast = useToast()
    const [isPrecountLoading, setPrecountLoading] = useState(false)
    async function precountHandler() {
        // const ipsConfig = getIPsConfig()
        const printerConfig = getPrinterConfig()

        if (printerConfig) {
            setPrecountLoading(true)
            const res = await post('/api/v2/printers/print_pre_account', {
                printer_id: Number(printerConfig.printer_id),
                order_id: storagePage.table?.order.id
            })

            if (res.error) {
                toast({
                    title: res.error.message,
                    position: 'top',
                    status: 'error'
                })
            } else {
                toast({
                    title: 'Precuenta obtenida',
                    position: 'top',
                    status: 'success'
                })
            }
            setPrecountLoading(false)
        } else {
            toast({
                title: 'Lo sentimos, se requiere configurar la impresora para imprimir las precuentas',
                position: 'top',
                status: 'error'
            })
        }
    }

    return {
        precountHandler,
        isPrecountLoading
    }
}
