import { Tr, Td, Icon, Text, Tooltip } from '@chakra-ui/react'
import dayjs from 'dayjs'
import Pencil from '../../../components/Icon/Pencil'
import Colors from '../../../config/colors'
import useMovements from '../hooks/useMovements'
import { MovimentCustom } from '../types'
import DeleteMovement from './DeleteMovement'

export default function MovementTr({ movement }: { movement: MovimentCustom }) {
    const { clickAddHandler } = useMovements(movement)

    const name =
        movement?.booking?.agency?.name || movement?.booking?.driver?.name || ''
    const guideName = movement?.booking?.agency_guide?.name || ''

    return (
        <Tr key={movement.id}>
            <Td textAlign="center">
                {dayjs(movement.date).format('DD/MM/YYYY')}
            </Td>
            <Td textAlign="center">{movement.type}</Td>
            <Td textAlign="center">
                <Tooltip
                    label={
                        movement.booking
                            ? `${name} ${guideName ? ` - ${guideName}` : ''}`
                            : ''
                    }
                    isDisabled={!movement.booking}
                >
                    <Text>{movement.concept}</Text>
                </Tooltip>
            </Td>
            <Td textAlign="center">{movement.amount}</Td>
            <Td textAlign="center">{movement.comment || '-'}</Td>
            <Td textAlign="center">
                <Icon
                    cursor="pointer"
                    marginRight={2}
                    as={Pencil}
                    fill={Colors.INFO}
                    onClick={clickAddHandler}
                />
                <DeleteMovement movement={movement} />
            </Td>
        </Tr>
    )
}
