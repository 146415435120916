import { Icon, Td, Tr } from '@chakra-ui/react'
import { useEffect } from 'react'
import Pencil from '../../../../components/Icon/Pencil'
import Transfer from '../../../../components/Icon/Transfer'
import Trash from '../../../../components/Icon/Trash'
import colors from '../../../../config/theme/colors'
import Delete from '../../../../lib/delete'
import List from '../../../../lib/list'
import Warehouse from '../../../../models/warehouse'
import { listProducts } from '../../storage'
import Actions from './Actions'
import ModalStock from './ModalStock'
import ProductForm from './ProductForm'

export default function ProductSection({
    warehouses
}: {
    warehouses: Warehouse[]
}) {
    useEffect(() => {
        listProducts.load()
    }, [])

    return (
        <>
            <Actions warehouses={warehouses} />
            <List
                control={listProducts}
                tbody={(invItemWarehouse, e) => (
                    <Tr key={e}>
                        <Td textAlign="center">
                            {invItemWarehouse.inventory_item.name}
                        </Td>
                        <Td textAlign="center">
                            {invItemWarehouse.inventory_item.type_measure.name}
                        </Td>
                        <Td textAlign="center">
                            {invItemWarehouse.warehouse.name}
                        </Td>
                        <Td textAlign="center">{invItemWarehouse.stock}</Td>
                        <Td textAlign="center">
                            <ModalStock
                                inventoryItemWarehouse={invItemWarehouse}
                                warehouses={warehouses}
                                element={click => (
                                    <Icon
                                        onClick={click}
                                        fill={colors['alo-blue']}
                                        as={Transfer}
                                        marginRight={2}
                                        cursor="pointer"
                                    />
                                )}
                                onDone={() => listProducts.load()}
                            />
                            <ProductForm
                                element={click => (
                                    <Icon
                                        fill={colors['alo-blue']}
                                        as={Pencil}
                                        marginRight={2}
                                        cursor="pointer"
                                        onClick={click}
                                    />
                                )}
                                onDone={() => listProducts.load()}
                                edition={invItemWarehouse}
                            />
                            <Delete
                                title="Eliminar producto"
                                path={`/api/inventory_items/${invItemWarehouse.inventory_item_id}`}
                                element={click => (
                                    <Icon
                                        onClick={click}
                                        fill={colors.error}
                                        as={Trash}
                                        cursor="pointer"
                                    />
                                )}
                                done={() => {
                                    listProducts.setQuery('page', 1)
                                    listProducts.load()
                                }}
                            >
                                ¿Esta seguro de eliminar el producto{' '}
                                <b>{invItemWarehouse.inventory_item.name}</b>?
                            </Delete>
                        </Td>
                    </Tr>
                )}
            />
        </>
    )
}
