import { useHistory } from 'react-router-dom'
import useDelivery from '../../../hooks/useDelivery'
import { ProductGroup } from '../../../context/DeliveryContext/types'
import useMenu from '../../../hooks/useMenu'

export default function useConfirm() {
    const { push } = useHistory()
    const { menu } = useMenu()
    const { setProducts } = useDelivery()

    function confirm() {
        const productGroups: ProductGroup[] = []

        menu.forEach(menuItem => {
            const productGroup = productGroups.find(
                productGr =>
                    productGr.category_item_id === menuItem.categoryItemId
            )
            if (productGroup) {
                productGroup.items.push({
                    orderItemId: menuItem.orderItemId,
                    itemId: menuItem.itemId,
                    name: menuItem.name,
                    quantity: menuItem.quantity,
                    price: menuItem.price,
                    description: menuItem.description,
                    feature_settings: []
                })
                return
            }

            productGroups.push({
                category_item_id: menuItem.categoryItemId,
                category_item_name: menuItem.categoryName,
                items: [
                    {
                        orderItemId: menuItem.orderItemId,
                        itemId: menuItem.itemId,
                        name: menuItem.name,
                        quantity: menuItem.quantity,
                        price: menuItem.price,
                        description: menuItem.description,
                        feature_settings: []
                    }
                ]
            })
        })

        setProducts(productGroups)

        push('/delivery/customers')
        return
    }

    return {
        confirm
    }
}
