import { createContext, useMemo, useState } from 'react'
import Client from '../../models/client'
import {
    DeliveryContextInterface,
    DeliveryProviderProps,
    FindOneDelivery,
    ProductGroup
} from './types'

const DeveliryContext = createContext<DeliveryContextInterface>({
    products: [],
    setProducts: () => null,
    client: null,
    setClient: () => null,
    hasOrder: false,
    total: 0,
    delivery: null,
    setDelivery: () => null,
    isOrderEdition: false,
    orderEdition: () => null
})

export function DeliveryProvider({ children }: DeliveryProviderProps) {
    const [products, setProducts] = useState<ProductGroup[]>([])
    const [client, setClient] = useState<Client | null>(null)
    const [delivery, setDelivery] = useState<FindOneDelivery | null>(null)
    const [isOrderEdition, setIsOrderEdition] = useState(false)
    const total = useMemo(
        () =>
            products.reduce(
                (n, prod) =>
                    n +
                    prod.items.reduce(
                        (m, item) => m + item.price * item.quantity,
                        0
                    ),
                0
            ),
        [products]
    )

    return (
        <DeveliryContext.Provider
            value={{
                products,
                setProducts,
                client,
                setClient,
                hasOrder: products.length > 0,
                total,
                delivery,
                setDelivery,
                isOrderEdition,
                orderEdition: setIsOrderEdition
            }}
        >
            {children}
        </DeveliryContext.Provider>
    )
}

export default DeveliryContext
