import { useContext } from 'react'
import OverlapLoaderContext from '../context/OverlapLoaderContext'

/**
 * Muestra un modal con contenido de carga.
 * Requiere del context OverlapLoaderProvider en la raíz de la página.
 */
export default function useOverlapLoader() {
    return useContext(OverlapLoaderContext)
}
