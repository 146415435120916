import { useToast } from '@chakra-ui/react'
import useSubmit from '../../../hooks/useSubmit'
import modal from '../../../lib/modal'
import FormTable from './FormZone'
import { formZone, listZone } from '../storage'
import Zone from '../../../models/zone'

export default function useFormZone(zone?: Zone) {
    const toast = useToast()
    const { submit, validation } = useSubmit(formZone, {
        path: zone ? `/api/zones/${zone.id}` : '/api/zones',
        method: zone ? 'put' : 'post',
        done: async () => {
            await listZone.load()
            toast({
                title: zone
                    ? 'Se ha actualizado la zona'
                    : 'Se ha guardado la nueva zona',
                status: 'success',
                position: 'top'
            })
        },
        error: e => {
            if (e.type === 'development') {
                toast({
                    title: e.message,
                    position: 'top',
                    status: 'error'
                })
            }
        }
    })

    validation(v => {
        v.name.isEmpty('El nombre es requerido')
    })

    function openModal() {
        if (zone) {
            formZone.store({
                name: zone.name
            })
            formZone.init(formZone.store)
        } else {
            formZone.init()
        }
        modal(FormTable, {
            title: zone ? 'Editar zona' : 'Nueva zona',
            onSubmit: submit
        })
    }

    return openModal
}
