import { Flex, FormControl, FormLabel, Input } from '@chakra-ui/react'
import { DocumentProps } from './types'

export default function TypeDocument({
    value,
    onChange
}: {
    value: string
    onChange: (value: string) => void
}) {
    return (
        <FormControl mb={4} isRequired>
            <FormLabel>Tipo de documento</FormLabel>
            <Flex gridColumnGap={3} gridRowGap={3} mt={4} flexWrap="wrap">
                <Document
                    value="1"
                    onChecked={onChange}
                    isChecked={value === '1'}
                >
                    DNI
                </Document>
                <Document
                    value="2"
                    onChecked={onChange}
                    isChecked={value === '2'}
                >
                    RUC
                </Document>
                <Document
                    value="3"
                    onChecked={onChange}
                    isChecked={value === '3'}
                >
                    Carnet Ext.
                </Document>
                <Document
                    value="4"
                    onChecked={onChange}
                    isChecked={value === '4'}
                >
                    Pasaporte
                </Document>
                <Document
                    value="5"
                    onChecked={onChange}
                    isChecked={value === '5'}
                >
                    Partida de Nac.
                </Document>
                <Document
                    value="6"
                    onChecked={onChange}
                    isChecked={value === '6'}
                >
                    Otros
                </Document>
            </Flex>
            <Input
                name="type_identification_document_id"
                type="hidden"
                value={value}
            />
        </FormControl>
    )
}

function Document({
    children,
    isChecked = false,
    value,
    onChecked
}: DocumentProps) {
    return (
        <Flex
            borderRadius="3xl"
            px="8"
            py="1"
            alignItems="center"
            cursor="pointer"
            fontSize="sm"
            border="1px solid"
            borderColor={isChecked ? 'gray-2' : 'gray.300'}
            color={isChecked ? 'white' : 'gray.700'}
            bg={isChecked ? 'gray-2' : 'white'}
            transition=".1s ease"
            onClick={() => onChecked(value)}
        >
            {children}
        </Flex>
    )
}
