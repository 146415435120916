import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { post } from '../../lib/http'
import getIP from '../../utilities/getIp'
import getNumberWithTwoDecimals from '../../utilities/getNumberWithTwoDecimals'
import { getCashBack, getFinalPayments } from './utils'

export default function useCheckIn() {
    const [itemsByCategory, setItemsByCategory] = useState<any>(null)
    const [loader] = useState(false)
    const [orderId, setOrderId] = useState<any>(null)
    const [openModalPay, setOpenModalPay] = useState<boolean>(false)
    const [openModalDiscount, setOpenModalDiscount] = useState<boolean>(false)
    const [typePay, setTypePay] = useState<any>(null)
    const [payments, setPayments] = useState<any>([])
    const [order, setOrder] = useState<any>(null)
    const [isLoadingConfirmPay, setIsLoadingConfirmPay] = useState(false)
    const [stateDiscount, setStateDiscount] = useState({
        percentage: 0,
        amount: 0
    })
    const toast = useToast()

    const { push } = useHistory()

    const getTotal = () => {
        let total = 0
        for (const payment of payments) {
            total = total + payment.amount
        }
        return total
    }

    const getTotalWithDiscount = () => {
        if (stateDiscount.percentage !== 0) {
            return {
                paymentTotalWithDiscount: getNumberWithTwoDecimals(
                    (
                        (order.amount *
                            (100 - Number(stateDiscount.percentage))) /
                        100
                    ).toFixed(2)
                ),
                discount: `(${Number(stateDiscount.percentage)}%)`
            }
        }

        if (stateDiscount.amount !== 0) {
            return {
                paymentTotalWithDiscount: getNumberWithTwoDecimals(
                    (order.amount - Number(stateDiscount.amount)).toFixed(2)
                ),
                discount: `(S/${Number(stateDiscount.amount)})`
            }
        }

        return {
            paymentTotalWithDiscount: '-',
            discount: '',
            discountValue: 0
        }
    }

    const removePayment = (type: string) => {
        const newPayments = []
        for (const payment of payments) {
            if (payment.type !== type) {
                newPayments.push(payment)
            }
        }
        setPayments(newPayments)
    }

    const handleConfirmPay = () => {
        setIsLoadingConfirmPay(true)
        const accumulated_payment = getTotal()
        const { paymentTotalWithDiscount } = getTotalWithDiscount()
        const cashback = getCashBack(
            paymentTotalWithDiscount,
            accumulated_payment,
            order.amount
        )

        const total_final =
            paymentTotalWithDiscount === '-'
                ? order.amount
                : Number(paymentTotalWithDiscount)

        const finalPayments = getFinalPayments(payments, total_final)

        post<any>(`/api/orders/${orderId}/checkin`, {
            payments: finalPayments,
            accumulated_payment: accumulated_payment,
            cashback: Number(cashback.toFixed(2)),
            total_original: order.amount,
            total_final: total_final,
            percentage_discount: Number(stateDiscount.percentage) || 0,
            discount: Number(stateDiscount.amount) || 0,
            ip: getIP('report')
        }).then(({ error }) => {
            if (error) {
                toast({
                    title: error.message || 'Lo sentimos, vuelva a intentarlo',
                    status: 'error',
                    position: 'top'
                })
                setIsLoadingConfirmPay(false)
                return
            }
            toast({
                title: 'Pago confirmado',
                status: 'success',
                position: 'top'
            })
            push('/main')
            setIsLoadingConfirmPay(false)
        })
    }

    return {
        itemsByCategory,
        setItemsByCategory,
        loader,
        typePay,
        setTypePay,
        getTotal,
        openModalPay,
        setOpenModalPay,
        orderId,
        setOrderId,
        payments,
        setPayments,
        order,
        setOrder,
        removePayment,
        handleConfirmPay,
        setOpenModalDiscount,
        openModalDiscount,
        stateDiscount,
        setStateDiscount,
        getTotalWithDiscount,
        isLoadingConfirmPay
    }
}
