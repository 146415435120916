import { useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { FormEvent, useState } from 'react'
import writeXlsxFile, { Row, SheetData } from 'write-excel-file'
import { get } from '../../../lib/http'
import extractDataForm from '../../../utilities/extractDataForm'

import { DataForm, ResultPaymentsMethod } from './types'

export default function usePrintPayments() {
    const toast = useToast()
    const [isLoading, setIsLoading] = useState(false)

    const head: (...values: string[]) => Row[number][] = (...values) =>
        values.map<Row[number]>(val => ({
            value: val,
            fontWeight: 'bold',
            wrap: true,
            alignVertical: 'center',
            align: 'left',
            fontSize: 11
        }))

    function setDataByProducts(data: ResultPaymentsMethod[], rows: SheetData) {
        data.forEach(item => {
            rows.push([
                {
                    value: item.fecha_comprobante,
                    type: String
                },
                {
                    value: item.comprobante,
                    type: String
                },
                {
                    value: item.documento,
                    type: String
                },
                {
                    value: item.cliente,
                    type: String
                },
                {
                    value: item.metodo_pago,
                    type: String
                },
                {
                    value: item.cuenta_destino || '',
                    type: String
                },
                {
                    value: item.amount,
                    type: Number,
                    align: 'right',
                    format: '#,##0.00'
                }
            ])
        })
    }

    async function submit(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const form = extractDataForm<DataForm>(ev.target)
        const startDate = dayjs
            .tz(form.startDate + ' 00:00:00', 'America/Lima')
            .toISOString()
        const endDate = dayjs
            .tz(form.endDate + ' 23:59:59', 'America/Lima')
            .toISOString()

        const rows: SheetData = [
            [],
            [
                {
                    value: 'Reporte de Ventas con Métodos de Pago',
                    fontWeight: 'bold',
                    span: 7,
                    alignVertical: 'center',
                    align: 'center',
                    fontSize: 16
                }
            ],
            [
                {
                    value: `De: ${dayjs(startDate).format(
                        'DD/MM/YYYY'
                    )} - Hasta ${dayjs(endDate).format('DD/MM/YYYY')}`,
                    span: 7,
                    align: 'center'
                }
            ],
            [],
            head(
                ...[
                    'Fecha de comprobante',
                    'Comprobante',
                    'Documento',
                    'Cliente',
                    'Método de pago',
                    'Cuenta destino ',
                    'Monto (S/)'
                ]
            )
        ]

        setIsLoading(true)

        const { data, error } = await get<ResultPaymentsMethod[]>(
            `/api/reports/payments_method?start_date=${startDate}&end_date=${endDate}`
        )

        if (error) {
            toast({
                title: 'Hubo un error al cargar los datos',
                status: 'error',
                position: 'top'
            })
            return
        }

        setDataByProducts(data.result, rows)

        await writeXlsxFile(rows, {
            fileName: `reporte_de_ventas_con_métodos_de_pago_${dayjs(
                startDate
            ).format('DD/MM/YYYY')}_${dayjs(endDate).format(
                'DD/MM/YYYY'
            )}.xlsx`,
            columns: [24, 15, 12, 40, 20, 25, 12].map(width => ({
                width
            })),
            fontSize: 10,
            fontFamily: 'Calibri'
        })

        setIsLoading(false)
    }

    return {
        submit,
        isLoading
    }
}
