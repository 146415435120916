import dayjs from 'dayjs'
import { useState } from 'react'
import writeXlsxFile, { Row, SheetData } from 'write-excel-file'
import { FindItemMovement } from '../modals/ModalHistories/types'
import { get } from '../../../lib/http'
import { FindItem } from '../components/SectionKadex/types'

const head: (...values: string[]) => Row[number][] = (...values) =>
    values.map<Row[number]>(val => ({
        value: val,
        fontWeight: 'bold',
        wrap: true,
        alignVertical: 'center',
        align: 'left',
        fontSize: 11
    }))

export default function useExportHistory(
    item: FindItem,
    startDate: string,
    endDate: string
) {
    const [isLoading, setIsLoading] = useState(false)

    async function submit() {
        setIsLoading(true)
        const itemMovements = await get<FindItemMovement[]>(
            `/api/movement_production_items?search=&limit=0&page=1&start_date=${dayjs
                .tz(startDate + ' 00:00:00', 'America/Lima')
                .toISOString()}&end_date=${dayjs
                .tz(endDate + ' 23:59:59', 'America/Lima')
                .toISOString()}&production_item_id=${
                item.production_item.id
            }&warehouse_id=${item.warehouse.id}&sort=created_at&order=desc`
        )

        const getReason = (type_movement: number) => {
            switch (type_movement) {
                case 1:
                    return 'Venta'
                case 2:
                    return 'Anulación de venta'
                case 3:
                    return 'Aumento de stock'
                case 4:
                    return 'Decremento de stock'
            }
        }

        const sDate = dayjs(startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
        const eDate = dayjs(endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')

        const rows: SheetData = [
            [],
            [
                {
                    value: `Historial - ${item.production_item.name} - ${item.warehouse.name}`,
                    fontWeight: 'bold',
                    span: 6,
                    alignVertical: 'center',
                    align: 'center',
                    fontSize: 16
                }
            ],
            [
                {
                    value: `De: ${sDate} - Hasta ${eDate}`,
                    span: 6,
                    align: 'center'
                }
            ],
            [],
            head(
                'Fecha y Hora',
                'Operación',
                'Usuario',
                'Unidad',
                'Costo unitario',
                'Stock Inicial',
                'Ajuste',
                'Stock Final',
                'Motivo'
            )
        ]

        itemMovements.data.result.forEach(itemMovement => {
            const user = itemMovement.user
            const fullName = user ? `${user.firstname} ${user.lastname}` : '-'

            rows.push([
                {
                    value: dayjs(itemMovement.created_at).format(
                        'DD/MM/YYYY hh:mm a'
                    ),
                    type: String,
                    align: 'left'
                },
                {
                    value: getReason(itemMovement.type_movement),
                    type: String
                },
                {
                    value: fullName,
                    type: String
                },
                {
                    value: itemMovement?.production_item_warehouse
                        ?.production_item?.magnitude?.name,
                    type: String
                },
                {
                    value: itemMovement.cost ?? '-',
                    type: itemMovement.cost ? Number : String
                },
                {
                    value: itemMovement.initial_quantity,
                    type: Number
                },
                {
                    value: itemMovement.marginal_quantity,
                    type: Number
                },
                {
                    value: itemMovement.current_quantity,
                    type: Number
                },
                {
                    value: itemMovement.reason ?? '',
                    type: String
                }
            ])
        })

        let itemName = item.production_item.name.toLowerCase()
        while (itemName.search(' ') > -1) {
            itemName = itemName.replace(' ', '_')
        }
        await writeXlsxFile(rows, {
            fileName: `historial_${itemName}_${sDate}_${eDate}.xlsx`,
            columns: [20, 40, 20, 12, 12, 12, 12, 12].map(width => ({
                width
            })),
            fontSize: 10
        })

        setIsLoading(false)
    }

    return {
        isLoading,
        submit
    }
}
