import { Icon, useDisclosure } from '@chakra-ui/react'
import Pencil from '../../../components/Icon/Pencil'
import ModalFormCategory from '../modals/ModalFormCategory'
import { listCategory } from '../storage'
import { ListCategory } from '../types'

export default function Edition({ category }: { category: ListCategory }) {
    const { isOpen, onClose, onOpen } = useDisclosure()

    return (
        <>
            <ModalFormCategory
                isOpen={isOpen}
                onClose={onClose}
                onDone={() => listCategory.load()}
                edition={category}
            />
            <Icon
                fill="blue.400"
                as={Pencil}
                marginRight={2}
                cursor="pointer"
                onClick={onOpen}
            />
        </>
    )
}
