import { useEffect } from 'react'
import useTab from '../../hooks/useTab'
import { listTable, listZone } from './storage'

export default function useInitial() {
    const props = useTab('table')

    useEffect(() => {
        listTable.load()
        listZone.load()
    }, [])

    return {
        tabProps: props
    }
}
