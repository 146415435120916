import { Flex, Icon } from '@chakra-ui/react'
import Check from '../../../../components/Icon/Check'
import { CheckboxPreferences } from './types'

export default function CheckboxPreference({
    children,
    isChecked,
    onChecked
}: CheckboxPreferences) {
    return (
        <Flex
            borderRadius="3xl"
            px="4"
            py="2"
            onClick={() => onChecked(!isChecked)}
            alignItems="center"
            cursor="pointer"
            border="1px solid"
            borderColor={isChecked ? 'success' : 'gray.300'}
            color={isChecked ? 'success' : 'gray.700'}
        >
            {isChecked && <Icon as={Check} fill="success" mr={3} />}
            {children}
        </Flex>
    )
}
