import { useToast } from '@chakra-ui/react'
import { post, put } from '../../../lib/http'
import usePreOrder from './usePreOrder'
import { useHistory } from 'react-router-dom'
import useOverlapLoader from '../../../hooks/useOverlapLoader'
import useMenu from '../../../hooks/useMenu'

export default function useSavePreOrder() {
    const toast = useToast()
    const { push } = useHistory()
    const { menu } = useMenu()
    const { preOrderId, booking, alias } = usePreOrder()
    const { start, end } = useOverlapLoader()

    function validate() {
        if (!booking) return false
        if (alias.trim() === '' && booking.type < 2) {
            toast({
                title: 'Es requerido escribir el nombre del cliente',
                status: 'error',
                position: 'top'
            })
            return false
        }
        return true
    }

    async function register() {
        if (!validate()) return
        start('Registrado la Pre Orden')
        const { error } = await post('/api/pre_orders', {
            alias,
            booking_id: booking?.id,
            items: menu
                .filter(item => item.quantity)
                .map(item => ({
                    id: item.itemId, // Item ID
                    quantity: item.quantity,
                    aditional_information: item.description
                }))
        })
        end()
        if (error) {
            toast({
                title: error.message,
                position: 'top',
                status: 'error',
                isClosable: true
            })
            return
        }

        toast({
            title: 'Se realiza la Pre Orden correctamente',
            position: 'top',
            status: 'success',
            isClosable: true
        })
        push('/pre_orders')
    }

    async function update() {
        if (!validate()) return
        start('Guardando la Pre Orden')
        const { error } = await put(`/api/pre_orders/${preOrderId}`, {
            alias,
            booking_id: booking?.id,
            items: menu
                .filter(item => item.quantity)
                .map(item => ({
                    id: item.preOrderItemId, // Pre Order Item Id
                    item_id: item.itemId,
                    quantity: item.quantity,
                    aditional_information: item.description
                }))
        })
        end()
        if (error) {
            toast({
                title: error.message,
                position: 'top',
                status: 'error',
                isClosable: true
            })
            return
        }

        toast({
            title: 'Se actualizo la Pre Orden correctamente',
            position: 'top',
            status: 'success',
            isClosable: true
        })
        push('/pre_orders')
    }

    return {
        register,
        update
    }
}
