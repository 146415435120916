import { Text } from '@chakra-ui/react'
import { storagePage } from '../../storage'
import Card from '../../Components/Card'
import StatBox from '../../Components/StatBox'
import StatCard from '../../Components/StatCard'
import StatDivider from '../../Components/StatDivider'

export default function Arching() {
    const { cashboxMovements } = storagePage

    const surpluses: any[] = []
    cashboxMovements.map(i => surpluses.push(i.surplus))
    const surplus = surpluses
        .filter(i => typeof i === 'number')
        .reduce((acc, curr) => acc + curr, 0)
    const valueSurplus = surplus ? surplus : '-'
    const surplusWithSymbol = surplus ? true : false

    const missings: any[] = []
    cashboxMovements.map(i => missings.push(i.missing))
    const missing = missings
        .filter(i => typeof i === 'number')
        .reduce((acc, curr) => acc + curr, 0)
    const valueMissing = missing ? missing : '-'
    const missingWithSymbol = missing ? true : false

    return (
        <Card
            direction="column"
            gridGap={4}
            w={{ base: 'full', md: '49.5%', lg: '50%' }}
        >
            <Text as="b" mr="2" fontSize="xl" align="center" w="full">
                Arqueo
            </Text>
            <StatCard>
                <StatBox
                    label={'Sobrante'}
                    value={valueSurplus}
                    withSymbol={surplusWithSymbol}
                />
                <StatDivider />
                <StatBox
                    label={'Faltante'}
                    value={valueMissing}
                    withSymbol={missingWithSymbol}
                />
            </StatCard>
        </Card>
    )
}
