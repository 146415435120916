import { Text } from '@chakra-ui/layout'
import useDelivery from '../../../../hooks/useDelivery'
import BoxContent from '../../shared/BoxContent'
import DetailItem from '../../shared/DataItem'
import { useDeliveryForm } from '../../store/delivery-form.store'
import { paymentLogos } from '../../utils/paymentLogos'

export default function Info() {
    const { total } = useDelivery()
    const store = useDeliveryForm()

    const paymentMethodName = paymentLogos.find(
        pl => pl.value === store.paymentMethod
    )

    return (
        <BoxContent title="RESUMEN">
            <Text fontSize="3xl" mb={2} fontWeight="medium" color="green.400">
                {(total + Number(store.priceDelivery)).toFixed(2)} soles
            </Text>
            <DetailItem
                title="Orden total"
                value={`${total.toFixed(2)} soles`}
            />
            <DetailItem
                title="Delivery"
                value={`${Number(store.priceDelivery).toFixed(2)} soles`}
            />
            <DetailItem
                title="Método de pago"
                value={paymentMethodName ? paymentMethodName.label : '-'}
            />
            <DetailItem title="Dirección" value={store.address[0] || '-'} />
            <DetailItem title="Referencia" value={store.address[1] || '-'} />
            <DetailItem title="Contacto" value={store.phone || '-'} />
        </BoxContent>
    )
}
