import { Flex, Select, Text } from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
import { render } from 'redity'
import colors from '../../../config/theme/colors'
import { Keys } from '../constants'
import { storagePage } from '../storage'

export default function TypeTax() {
    const [value, setValue] = useState('levied')

    function changeHandler(ev: ChangeEvent<HTMLSelectElement>) {
        setValue(ev.target.value)
        if (ev.target.value == 'levied') {
            storagePage.typeTax = 'levied'
        } else {
            storagePage.typeTax = 'exempt'
        }
        render(Keys.DETAILS)
    }

    return (
        <Flex bg={colors['base-3']} px={{ base: 2, md: 4 }} py="2" mt={4}>
            <Text mr="4" as={'b'} alignSelf="center">
                IGV
            </Text>
            <Select
                bg="white"
                value={value}
                onChange={changeHandler}
                size="sm"
                borderRadius="md"
            >
                <option value="levied">Gravado</option>
                <option value="exempt">Exonerado</option>
            </Select>
        </Flex>
    )
}
