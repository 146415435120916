import { useToast } from '@chakra-ui/react'
import { ChangeEvent, FormEvent, useState } from 'react'
import { post } from '../../../lib/http'
import val from '../../../lib/val'
import Client from '../../../models/client'
import extractDataForm from '../../../utilities/extractDataForm'
import { FormBody } from './types'

export default function useSaveClient(onDone: (client: Client) => void) {
    const [isLoading, setLoading] = useState(false)
    const toast = useToast()
    const [errorHelper, setErrorHelper] = useState<
        Partial<Record<keyof FormBody, string>>
    >({})

    async function submit(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const form = extractDataForm<FormBody>(ev.target)

        const v = val(form)
        v.firstname.isEmpty('El nombre es requerido')
        // v.lastname.isEmpty('El apellido es requerido')
        v.address.isEmpty('La dirección es requerida')
        v.phone.isEmpty('El teléfono es requerido')

        const result = v.runtest()
        if (result) {
            setErrorHelper(result)
            return
        }
        setLoading(true)
        const { error, data } = await post<Client>('/api/v2/clients', form)
        setLoading(false)
        if (error) {
            setErrorHelper(error.errors)
        } else {
            toast({
                title: 'El cliente se ha creado',
                status: 'success'
            })
            onDone(data.result)
        }
    }

    function onChange(ev: ChangeEvent<HTMLInputElement>) {
        const name = ev.target.name as keyof FormBody
        if (errorHelper[name]) {
            setErrorHelper({
                ...errorHelper,
                [name]: ''
            })
        }
    }

    function clear() {
        setErrorHelper({})
    }

    return {
        isLoading,
        submit,
        errorHelper,
        onChange,
        clear
    }
}
