import { Box, Center, SimpleGrid, Text } from '@chakra-ui/react'
import { GridBookingsProps } from './types'
import Booking from '../../../../models/booking'
import { useGet } from '../../../../hooks/useHttp'
import colors from '../../../../config/theme/colors'
import dayjs from 'dayjs'
import useParamsQuickSaleBy from '../../hooks/useParamsQuickSaleBy'

export const TYPE_BOOKINGS_VALUE = {
    AGENCY: 1,
    DRIVER: 2,
    CLIENT: 3
}

export default function GridBookings({
    onClick,
    booking,
    date
}: GridBookingsProps) {
    const { by } = useParamsQuickSaleBy()
    const { data } = useGet<Booking[]>(`/api/bookings?date=${date}`, [])

    function orderByDate(a: Booking, b: Booking) {
        return new Date(a.hour).getTime() - new Date(b.hour).getTime()
    }

    function getInformationOfBooking(_booking: Booking) {
        let agency = ''
        let name = ''
        // let textTotal = ''
        const hour = dayjs(_booking.hour).format('hh:mm A')

        switch (_booking.type) {
            case TYPE_BOOKINGS_VALUE.DRIVER:
                name = _booking.driver?.name ?? ''
                // textTotal = text + ' - ' + hour
                break
            case TYPE_BOOKINGS_VALUE.AGENCY:
                agency = _booking.agency?.name || ''
                name = _booking.agency_guide?.name ?? ''
                // textTotal = agency + ' - ' + text + ' - ' + hour
                break
            case TYPE_BOOKINGS_VALUE.CLIENT:
                name = _booking.client_name ?? ''
                // textTotal = text + ' - ' + hour
                break
        }

        return {
            agency: agency,
            name,
            // textTotal: textTotal,
            hour: hour
        }
    }

    let bookings = data.result
        .sort(orderByDate)
        .filter(_booking => _booking.orders.length === 0 || _booking.type === 1)

    if (by === 'pre_orders') {
        bookings = bookings.filter(_booking => !_booking.is_attented)
    }

    return (
        <>
            <SimpleGrid columns={[2, 2, 3]} spacing={3}>
                {bookings.map((_booking, i) => {
                    const { name, hour, agency } =
                        getInformationOfBooking(_booking)
                    return (
                        <Box
                            h="150px"
                            borderRadius={'20px'}
                            p={2}
                            // pointerEvents={_booking}
                            textAlign="center"
                            display={'flex'}
                            justifyContent="center"
                            alignItems={'center'}
                            bg={
                                booking?.id === _booking?.id
                                    ? colors['alo-red']
                                    : 'gray.400'
                            }
                            key={i}
                            color={
                                booking?.id === _booking?.id
                                    ? 'white'
                                    : 'whiteAlpha.900'
                            }
                            cursor="pointer"
                            flexDirection={'column'}
                            onClick={() => onClick(_booking)}
                        >
                            {agency && <Text as="b">{agency}</Text>}
                            <Text>{name}</Text>
                            <Text>{hour}</Text>
                        </Box>
                    )
                })}
            </SimpleGrid>
            {bookings.length === 0 && (
                <Center p={6}>No existen reservas</Center>
            )}
        </>
    )
}
