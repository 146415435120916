import { Button, Flex, Heading, IconButton, Image } from '@chakra-ui/react'
import ArrowLeft from '../Icon/ArrowLeft'
import logo from '../../assets/logo-alt.svg'
import { useHistory } from 'react-router-dom'
import colors from '../../config/theme/colors'
import { MouseEvent } from 'react'

export default function NavbarAlt({
    title,
    path,
    backLabel = 'VOLVER',
    onClick
}: NavbarAltProps) {
    const { goBack, push } = useHistory()

    function clickHandler(ev: MouseEvent<HTMLButtonElement>) {
        if (onClick) {
            onClick(ev)
        }
        if (path) {
            push(path)
        } else {
            goBack()
        }
    }

    return (
        <Flex
            w="full"
            bg={colors.dark}
            color={colors.white}
            h="60px"
            justifyContent={{ base: 'flex-start', lg: 'space-between' }}
            pl={[0, 0, 4, 6]}
            pr={[0, 0, 4, 6]}
            alignItems="center"
            gap={[3, 3, 4, 6]}
        >
            <IconButton
                icon={<ArrowLeft />}
                aria-label="Volver"
                variant="ghost"
                fill="white"
                display={{ base: 'flex', lg: 'none' }}
                onClick={clickHandler}
            />
            <Flex gap={6} alignItems="center">
                <Image
                    w="12"
                    h="auto"
                    src={logo}
                    alt="logo"
                    display={{ base: 'none', lg: 'block' }}
                />
                <Heading size="sm" fontWeight="medium">
                    {title}
                </Heading>
            </Flex>
            <Button
                variant="ghost"
                colorScheme="whiteAlpha"
                color="white"
                display={{ base: 'none', md: 'flex' }}
                leftIcon={<ArrowLeft fill="white" />}
                onClick={clickHandler}
            >
                {backLabel}
            </Button>
        </Flex>
    )
}

export interface NavbarAltProps {
    title: string
    path?: string
    backLabel?: string
    onClick?: (ev: MouseEvent<HTMLButtonElement>) => void
}
