import {
    Box,
    Divider,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Spinner,
    Text
} from '@chakra-ui/react'
import { useState } from 'react'
import { useGet } from '../../../../hooks/useHttp'
import AditionalInformationCategory from '../../../../models/aditional_information_category'
import FooterButtons from './FooterButtons'
import CheckboxPreference from './CheckboxPreference'
import { ModalPreferencesProps } from './types'

export default function ModalPreferences(props: ModalPreferencesProps) {
    const [valuesPreferences, setValuesPreferences] = useState<
        Record<string, string>
    >({})
    const { data, isLoading } = useGet<AditionalInformationCategory[]>(
        `/api/aditional_informations?category_item_id=${props.categoryItemId}&sort=description`,
        []
    )
    const preferences = data.result

    function checkedHandler(isChecked: boolean, id: number) {
        const values: Record<string, string> = {
            ...valuesPreferences,
            [id]: preferences.find(preference => preference.id === id)
                ?.description
        }

        if (!isChecked) {
            delete values[id]
        }

        setValuesPreferences(values)
    }

    return (
        <Modal
            isOpen={props.isOpen}
            onClose={props.onClose}
            closeOnOverlayClick
            isCentered
            size="md"
        >
            <ModalOverlay />
            <ModalContent px={6} py={4}>
                <ModalBody>
                    <Text
                        fontSize="2xl"
                        fontWeight="bold"
                        textAlign="center"
                        mb="6"
                    >
                        Preferencias
                    </Text>
                    <Text fontSize="sm" mb="2">
                        Seleccione las preferencias del cliente:
                    </Text>
                    <Divider />
                    {!isLoading && (
                        <Box maxH="400px" overflowY="auto" pr="1">
                            <Flex
                                gridColumnGap={3}
                                gridRowGap={3}
                                mt={4}
                                flexWrap="wrap"
                            >
                                {data.result.map(aic => (
                                    <CheckboxPreference
                                        key={aic.id}
                                        isChecked={!!valuesPreferences[aic.id]}
                                        onChecked={isChecked =>
                                            checkedHandler(isChecked, aic.id)
                                        }
                                    >
                                        {aic.description}
                                    </CheckboxPreference>
                                ))}
                            </Flex>
                        </Box>
                    )}
                    {preferences.length === 0 && !isLoading && (
                        <Text color="gray-3" fontSize="sm" textAlign="center">
                            No existen resultados
                        </Text>
                    )}
                    {isLoading && (
                        <Flex justifyContent="center" mt={8}>
                            <Spinner />
                        </Flex>
                    )}
                    <FooterButtons
                        onClose={props.onClose}
                        onAdd={() => props.onAdd(valuesPreferences)}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
