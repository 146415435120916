import { Box } from '@chakra-ui/react'
import { useRender } from 'redity'
import { storagePage } from '../../storage'
import Keys from '../../keys'
import BoxCategory from './BoxCategory'

export default function SidebarCategories() {
    useRender(Keys.LIST_CATEGORIES)

    return (
        <Box
            display="flex"
            flexDirection={{ base: 'row', lg: 'column' }}
            bg={{ base: 'white', lg: 'gray-2' }}
            w={{ base: '100%', lg: '165px', xl: '180px' }}
            p="3"
            minW="120px"
            gridGap="15px"
            overflowY="auto"
            maxH="calc(100vh - 60px)"
        >
            {storagePage.categories.map(category => (
                <BoxCategory
                    id={category.id}
                    name={category.name}
                    key={category.id}
                />
            ))}
        </Box>
    )
}
