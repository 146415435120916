import ControlList from '../../lib/list/ControlList'
import PreOrder from '../../models/pre_order'

export const TYPE_BOOKINGS_VALUE = {
    AGENCY: 1,
    DRIVER: 2,
    CLIENT: 3
}

export const styleTd = {
    border: '1px solid black',
    padding: '0.3em'
}

export const PATH_ENDPOINT_PRE_ORDER = '/api/pre_orders'

export const listPreOrder = new ControlList<PreOrder>({
    path: PATH_ENDPOINT_PRE_ORDER,
    theads: [
        '#',
        'Hora esperada',
        'Nombre del cliente',
        'Grupo Turístico',
        'Monto',
        'Estado',
        'Acciones'
    ]
})
