import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useId
} from '@chakra-ui/react'
import ErrorHelper from '../../../../components/ErrorHelper'
import PrintDestinationField from './PrintDestinationField'
import { ModalFormCategoryProps } from './types'
import useSubmitCategory from '../../hooks/useSubmitCategory'
import { useEffect } from 'react'

export default function ModalFormCategory(props: ModalFormCategoryProps) {
    const id = useId()
    const submitCategory = useSubmitCategory(() => {
        props.onDone()
        props.onClose()
    }, props.edition)

    useEffect(() => {
        submitCategory.setHelper({})
        if (props.edition && props.isOpen) {
            submitCategory.setName(props.edition.name)
            if (
                props.edition.category_item_printers.length === 0 &&
                props.edition.printer_id
            ) {
                submitCategory.setPrinterIds([props.edition.printer_id])
            } else {
                submitCategory.setPrinterIds(
                    props.edition.category_item_printers.map(
                        cip => cip.printer_id
                    )
                )
            }
        } else {
            submitCategory.setName('')
            submitCategory.setPrinterIds([])
        }
    }, [props.isOpen])

    return (
        <Modal
            isOpen={props.isOpen}
            onClose={props.onClose}
            isCentered
            size="md"
        >
            <ModalOverlay />
            <ModalContent px={6} py={4}>
                <ModalHeader>
                    {props.edition ? 'Editar Categoría' : 'Nueva Categoría'}{' '}
                </ModalHeader>
                <ModalBody pb={6}>
                    <form onSubmit={submitCategory.submit} id={id}>
                        <FormControl mb={4} isRequired>
                            <FormLabel>Nombre</FormLabel>
                            <Input
                                placeholder="Ingresar el nombre de la categoría"
                                autoFocus
                                name="name"
                                value={submitCategory.name}
                                onChange={ev =>
                                    submitCategory.setName(ev.target.value)
                                }
                                defaultValue={props.edition?.name}
                            />
                            <ErrorHelper helper={submitCategory.helper.name} />
                        </FormControl>
                        <FormControl marginBottom="4">
                            <FormLabel>Destino de impresión</FormLabel>
                            <PrintDestinationField
                                printerIds={submitCategory.printerIds}
                                setPrinterIds={submitCategory.setPrinterIds}
                            />
                            <ErrorHelper
                                helper={submitCategory.helper.printer_ids}
                            />
                        </FormControl>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="alo-gray:lg-md"
                        bg="transparent"
                        color="alo-gray"
                        onClick={props.onClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="alo-green:lg-md"
                        ml={3}
                        form={id}
                        type="submit"
                        isLoading={submitCategory.isLoading}
                    >
                        Confirmar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
