import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Icon,
    Input,
    InputProps,
    // SelectProps,
    Select,
    Text
} from '@chakra-ui/react'
import { ChangeEvent } from 'react'
import ErrorHelper from '../../../components/ErrorHelper'
import Close from '../../../components/Icon/Close'
import colors from '../../../config/theme/colors'
import { useGet } from '../../../hooks/useHttp'
import { HttpResponse } from '../../../lib/http/type'
import Supply from '../../../models/supply'
import { formFeature } from '../storage'

export default function FormFeature() {
    return (
        <>
            <Name />
            <Category />
        </>
    )
}

function Name() {
    const { props, helper, error } = formFeature.useField<InputProps>('name')
    return (
        <FormControl mb={4}>
            <FormLabel>Nombre</FormLabel>
            <Input
                {...props}
                placeholder="Ingresar el nombre de la caracteristica"
                autoFocus
                maxLength={180}
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function Category() {
    const { data } = useGet<Supply[]>('/api/supplies?sort=name', [])
    // const { props } = formFeature.useField<SelectProps>('category_id')
    const { value, setValue } = formFeature.useValue('sub_features')

    const handleChange = (
        index: number,
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        value[index][e.target.name] = e.target.value
        setValue([...value])
    }

    const handleDelete = (index: number) => {
        const newFeatures = []
        for (let i = 0; i < value.length; i++) {
            if (i !== index) {
                newFeatures.push(value[i])
            }
        }
        setValue([...newFeatures])
    }

    return (
        <FormControl mb={4}>
            <FormLabel>Subcaracteristicas</FormLabel>
            <Text fontSize="13px" mb="3">
                Agrega nuevas subcaracteristicas
            </Text>
            <TitlesSubFeature />
            {value.map((sub_feature: any, i: number) => (
                <SubFeature
                    key={i}
                    sub_feature={sub_feature}
                    handleChange={handleChange}
                    data={data}
                    index={i}
                    handleDelete={handleDelete}
                />
            ))}
            <Box>
                <Button
                    background="#FFF"
                    height="40px"
                    fontSize="14px"
                    color="#4E6BD3"
                    border="1px solid #C5C9D0"
                    borderRadius="18px"
                    fontWeight={500}
                    width="114px"
                    onClick={() =>
                        setValue([
                            ...value,
                            {
                                name: '',
                                supply_id: '',
                                price: '',
                                quantity: ''
                            }
                        ])
                    }
                >
                    Agregar
                </Button>
            </Box>
            {/* <ErrorHelper helper={helper} /> */}
        </FormControl>
    )
}

function TitlesSubFeature() {
    return (
        <Box display="flex">
            <Box width={'30%'} paddingX="4">
                <Text fontSize="12px" mb="2">
                    Nombre
                </Text>
            </Box>
            <Box paddingX="2" width={'20%'}>
                <Text fontSize="12px" mb="2">
                    Precio adicional
                </Text>
            </Box>
            <Box width={'30%'} paddingX="2">
                <Text fontSize="12px" mb="2">
                    Insumo a consumir
                </Text>
            </Box>
            <Box width={'20%'} paddingX="2">
                <Text fontSize="12px" mb="2">
                    Cantidad
                </Text>
            </Box>
        </Box>
    )
}

function SubFeature({
    sub_feature,
    handleChange,
    data,
    index,
    handleDelete
}: {
    sub_feature: any
    handleChange: any
    data: HttpResponse<Supply[]>
    index: number
    handleDelete: any
}) {
    return (
        <Box
            display="flex"
            border="1px solid #C4C4C4"
            borderRadius="10px"
            marginBottom="2"
            paddingX="2"
        >
            <Box
                width={'30%'}
                paddingX="2"
                paddingY="2"
                borderRight="1px solid #C4C4C4"
            >
                <Input
                    value={sub_feature.name}
                    placeholder="Subcaracterística"
                    maxLength={180}
                    border="none"
                    borderRadius="0px"
                    borderBottom="1px solid #434343"
                    height="30px"
                    name="name"
                    onChange={e => handleChange(index, e)}
                />
            </Box>
            <Box
                paddingX="2"
                borderRight="1px solid #C4C4C4"
                paddingY="2"
                width={'20%'}
            >
                <Input
                    value={sub_feature.price}
                    placeholder="Precio"
                    maxLength={180}
                    border="none"
                    borderRadius="0px"
                    borderBottom="1px solid #434343"
                    height="30px"
                    name="price"
                    onChange={e => handleChange(index, e)}
                />
            </Box>
            <Box
                width={'30%'}
                paddingX="2"
                paddingY="2"
                borderRight="1px solid #C4C4C4"
            >
                <Select
                    value={sub_feature.supply_id}
                    placeholder="Seleccione recurso"
                    border="none"
                    borderRadius="0px"
                    borderBottom="1px solid #434343"
                    height="30px"
                    name="supply_id"
                    onChange={e => handleChange(index, e)}
                >
                    {data.result.map(category => (
                        <option key={category.id} value={category.id}>
                            {category.name}
                        </option>
                    ))}
                </Select>
            </Box>
            <Box
                width={'20%'}
                paddingX="2"
                paddingY="2"
                borderRight="1px solid #C4C4C4"
            >
                <Input
                    value={sub_feature.quantity}
                    placeholder="Cantidad"
                    maxLength={180}
                    border="none"
                    borderRadius="0px"
                    borderBottom="1px solid #434343"
                    height="30px"
                    name="quantity"
                    onChange={e => handleChange(index, e)}
                />
            </Box>
            <Box
                paddingX="2"
                paddingY="2"
                width={'5%'}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Icon
                    onClick={() => handleDelete(index)}
                    fill={colors.error}
                    as={Close}
                    cursor="pointer"
                />
            </Box>
        </Box>
    )
}
