import FormEvent from '../../lib/form_event'
import ControlList from '../../lib/list/ControlList'
import RoleUser from '../../models/role_user'
import User from '../../models/user'

export const controlList = new ControlList<User & { roles_users: RoleUser[] }>({
    path: '/api/users',
    theads: ['N°', 'Nombre', 'Apellidos', 'Perfil', 'Habilitado', 'Acciones']
})

export const formUser = new FormEvent({
    firstname: '',
    lastname: '',
    username: '',
    password: '',
    pin: '',
    role_id: ''
})
