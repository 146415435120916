import {
    Box,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Table,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import { useState } from 'react'
import { PaymentAmount } from '../types'

export default function ModalSubPayments({
    element,
    paymentAmount
}: {
    element: (click: () => void) => JSX.Element
    paymentAmount: PaymentAmount
}) {
    const [isOpen, setOpen] = useState(false)

    return (
        <>
            {element(() => setOpen(true))}
            <Modal
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                size="md"
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton mt={1} _focus={{}} />
                    <Box px={10} pb={9} pt={4}>
                        <Text
                            align="center"
                            fontWeight={'bold'}
                            my={4}
                            fontSize="lg"
                        >
                            Total por tipo de {paymentAmount.payment_name}
                        </Text>

                        <Table>
                            <Thead>
                                <Tr>
                                    <Th px={3} fontSize="14px">
                                        Tipo
                                    </Th>
                                    <Th px={3} fontSize="14px" isNumeric>
                                        Total
                                    </Th>
                                </Tr>
                            </Thead>
                            {paymentAmount.sub_payments.map((subPayment, i) => (
                                <Tr key={i}>
                                    <Td px={3}>
                                        {subPayment.sub_payment_name}
                                    </Td>
                                    <Td px={3} isNumeric>
                                        S/ {subPayment.amount.toFixed(2)}
                                    </Td>
                                </Tr>
                            ))}
                        </Table>
                    </Box>
                </ModalContent>
            </Modal>
        </>
    )
}
