import { Flex, Text } from '@chakra-ui/react'
import colors from '../../../../../config/theme/colors'
import CategoryItem from '../../../../../models/category_item'
import Item from '../../../../../models/item'

export interface ItemSearchProps {
    isActive: boolean
    item: Item & { category_item: CategoryItem }
    onSelect: (item: Item & { category_item: CategoryItem }) => void
    hasStock: boolean
}

export default function ItemSearch({
    onSelect,
    item,
    isActive,
    hasStock
}: ItemSearchProps) {
    return (
        <Flex
            py="2"
            px="4"
            bg={isActive ? 'gray.100' : 'white'}
            cursor="pointer"
            _hover={{
                bg: isActive ? 'gray.100' : 'gray.50'
            }}
            pointerEvents={hasStock ? 'auto' : 'none'}
            justify="space-between"
            align="center"
            onClick={() => onSelect(item)}
        >
            <Flex flexWrap="wrap" align="center" columnGap={2}>
                <Text textDecorationLine={hasStock ? 'none' : 'line-through'}>
                    {item.name}
                </Text>
                <Text
                    textDecor="none"
                    as="span"
                    color="blackAlpha.700"
                    fontSize="xs"
                >
                    {item.category_item.name}
                </Text>
            </Flex>
            <Text
                color={colors['orange']}
                fontSize="sm"
                ml={2}
                whiteSpace="nowrap"
            >
                S/ {item.price.toFixed(2)}
            </Text>
        </Flex>
    )
}
