import {
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text
} from '@chakra-ui/react'
import { useGet } from '../../../../hooks/useHttp'
import { CommandHistoryData } from '../../types'
import CommandCard from './CommandCard'
import useOrderByTable from '../../hooks/useOrderByTable'
import { ModalCommandHistoryProps } from './types'

export default function ModalCommandHistory({
    isOpen,
    onClose
}: ModalCommandHistoryProps) {
    const { orderId } = useOrderByTable()
    const defaultData = {
        commands: []
    }

    const { data } = useGet<CommandHistoryData>(
        `/api/commands/order/${orderId}`,
        defaultData
    )

    const commandHistory = data.result

    //Ordenando comandas desde la más reciente
    const commands = commandHistory.commands.sort((a, b) => b.id - a.id)

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl" closeOnOverlayClick>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize="xl" fontWeight="bold">
                    Historial de comandas
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Flex
                        justifyContent="space-between"
                        mb="4"
                        direction={{ base: 'column', md: 'row' }}
                    >
                        <Text as={'b'}>
                            Pedido N°{' '}
                            {commandHistory.order?.id
                                .toString()
                                .padStart(5, '0')}
                        </Text>
                        <Flex
                            justifyContent="space-between"
                            gridGap={{ md: 20 }}
                        >
                            <Text>
                                Mesa:{' '}
                                <Text color="dark-2" as={'b'}>
                                    {commandHistory.order?.table.name}
                                </Text>
                            </Text>
                            <Text>
                                Mozo:{' '}
                                <Text color="dark-2" as={'b'}>
                                    {commandHistory.order?.user.firstname}{' '}
                                    {commandHistory.order?.user.lastname}
                                </Text>
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex direction="column" gridGap={4}>
                        {commands.map((command, i) => (
                            <CommandCard key={i} command={command} />
                        ))}
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
