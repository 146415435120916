import { Flex, Input } from '@chakra-ui/react'
import Clean from '../../../components/Clean'
import { listProductionItems } from '../storage'
import ProductionItemCategory from '../../../models/production_item_category'
import { useGet } from '../../../hooks/useHttp'
import SelectWithLabel from '../../../components/SelectWithLabel'

export default function FiltersItem() {
    const { clear, ...props } = listProductionItems.useSearch()
    const propsFilter = listProductionItems.useFilter('category_id')
    const { data } = useGet<ProductionItemCategory[]>(
        '2>/api/production_item_categories?limit=0&page=1&order=asc&sort=name&search=',
        []
    )

    return (
        <Flex gridGap={2}>
            <Clean value={props.value} onClick={clear}>
                <Input
                    w="80"
                    placeholder="🏸 Buscar por nombre de artículo"
                    {...props}
                />
            </Clean>
            <SelectWithLabel
                {...propsFilter}
                label="Seleccionar categoría:"
                placeholder="Todos"
            >
                {data.result.map(d => (
                    <option key={d.id} value={d.id}>
                        {d.name}
                    </option>
                ))}
            </SelectWithLabel>
        </Flex>
    )
}
