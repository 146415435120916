import {
    Modal,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    Text,
    ModalBody,
    Box,
    Grid
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import colors from '../../../config/theme/colors'
import useAgency from './useBooking'

export default function ModalTypeBooking({
    isOpen,
    onClose
}: {
    isOpen: boolean
    onClose: () => void
}) {
    const openModal = useAgency()
    return (
        <Modal size="xl" isCentered onClose={onClose} isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent px={6} py={4}>
                <ModalHeader>
                    <Text fontSize="2xl" fontWeight="bold">
                        Nueva reserva
                    </Text>
                    <Text fontSize="md">Selecciona el tipo de cliente</Text>
                </ModalHeader>
                <ModalBody>
                    <Grid
                        gap={6}
                        templateColumns={{
                            base: 'repeat(1,1fr)',
                            md: 'repeat(2,1fr)',
                            lg: 'repeat(3,1fr)'
                        }}
                    >
                        <BoxTypeBooking
                            onClick={() => {
                                onClose()
                                openModal(1)
                            }}
                        >
                            Agencia
                        </BoxTypeBooking>
                        <BoxTypeBooking
                            onClick={() => {
                                onClose()
                                openModal(2)
                            }}
                        >
                            Guía Local
                        </BoxTypeBooking>
                        <BoxTypeBooking
                            onClick={() => {
                                onClose()
                                openModal(3)
                            }}
                        >
                            Cliente Independiente
                        </BoxTypeBooking>
                    </Grid>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

const BoxTypeBooking = ({
    onClick,
    children
}: {
    onClick: () => void
    children: ReactNode | string
}) => {
    return (
        <Box
            borderRadius="10px"
            bg={colors['gray-3']}
            color="white"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100px"
            textAlign="center"
            cursor="pointer"
            onClick={onClick}
        >
            {children}
        </Box>
    )
}
