import { Global } from '@emotion/react'
import Light from '../../assets/fonts/static/RedHatDisplay-Light.ttf'
import Regular from '../../assets/fonts/static/RedHatDisplay-Regular.ttf'
import Medium from '../../assets/fonts/static/RedHatDisplay-Medium.ttf'
import SemiBold from '../../assets/fonts/static/RedHatDisplay-SemiBold.ttf'
import Bold from '../../assets/fonts/static/RedHatDisplay-Bold.ttf'
import ExtraBold from '../../assets/fonts/static/RedHatDisplay-ExtraBold.ttf'

import PLight from '../../assets/fonts/poppins/Poppins-Light.ttf'
import PRegular from '../../assets/fonts/poppins/Poppins-Regular.ttf'
import PMedium from '../../assets/fonts/poppins/Poppins-Medium.ttf'
import PSemiBold from '../../assets/fonts/poppins/Poppins-SemiBold.ttf'
import PBold from '../../assets/fonts/poppins/Poppins-Bold.ttf'

const Fonts = () => (
    <Global
        styles={`
            @font-face {
                font-family: 'RedHatDisplay';
                font-style: light;
                font-weight: 300;
                font-display: swap;
                src: url('${Light}');
            }
            @font-face {
                font-family: 'RedHatDisplay';
                font-style: medium;
                font-weight: 400;
                font-display: swap;
                src: url('${Regular}');
            }
            @font-face {
                font-family: 'RedHatDisplay';
                font-style: medium;
                font-weight: 500;
                font-display: swap;
                src: url('${Medium}');
            }
            @font-face {
                font-family: 'RedHatDisplay';
                font-style: semibold;
                font-weight: 600;
                font-display: swap;
                src: url('${SemiBold}');
            }
            @font-face {
                font-family: 'RedHatDisplay';
                font-style: bold;
                font-weight: 700;
                font-display: swap;
                src: url('${Bold}');
            }
            @font-face {
                font-family: 'RedHatDisplay';
                font-style: extrabold;
                font-weight: 800;
                font-display: swap;
                src: url('${ExtraBold}');
            }

            @font-face {
                font-family: 'Poppins';
                font-style: light;
                font-weight: 300;
                font-display: swap;
                src: url('${PLight}');
            }
            @font-face {
                font-family: 'Poppins';
                font-style: regular;
                font-weight: 400;
                font-display: swap;
                src: url('${PRegular}');
            }
            @font-face {
                font-family: 'Poppins';
                font-style: medium;
                font-weight: 500;
                font-display: swap;
                src: url('${PMedium}');
            }
            @font-face {
                font-family: 'Poppins';
                font-style: semibold;
                font-weight: 600;
                font-display: swap;
                src: url('${PSemiBold}');
            }
            @font-face {
                font-family: 'Poppins';
                font-style: bold;
                font-weight: 700;
                font-display: swap;
                src: url('${PBold}');
            }

            .table-a-la-orden thead tr th {
                border-right: 1px solid #708595;
            }
            
            .table-a-la-orden thead tr th:last-child {
                border-right: none;
            }
            
            .table-a-la-orden tbody tr:nth-child(odd) {
                background-color: #d0d9df;
            }
            
            .table-a-la-orden tbody tr td {
                text-align: center;
            }
            
            /**
            * Scroll style
            */
            ::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }
            
            ::-webkit-scrollbar-track {
                background-color: #f6f6f6;
                border-radius: 10px;
                box-shadow: inset 0 0 5px #eee;
            }
            
            ::-webkit-scrollbar-thumb {
                background-color: #ececec;
                border-radius: 10px;
                background-clip: content-box;
            }
            
            ::-webkit-scrollbar-thumb:hover {
                background-color: #ececec;
            }

            .scroll-no-bar::-webkit-scrollbar {
                display: none;
            }
            
            body {
                font-family: 'Poppins', sans-serif;
            }
        
      `}
    />
)

export default Fonts
