import { SVGProps } from 'react'
const ArrowDown = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_2_40847)">
            <path d="M12 13.172L16.95 8.22205L18.364 9.63605L12 16L5.63599 9.63605L7.04999 8.22205L12 13.172Z" />
        </g>
        <defs>
            <clipPath id="clip0_2_40847">
                <rect width={24} height={24} />
            </clipPath>
        </defs>
    </svg>
)
export default ArrowDown
