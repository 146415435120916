import { StoragePage } from './types'

//Objeto para almacenar datos generales de la página
export const storagePage: StoragePage = {
    selectedItems: [],
    id: 0,
    client: null,
    orderPaymentDetails: [],
    totalOriginal: 0,
    totalFinal: 0,
    igv: 0,
    serie: 0,
    ticketNumber: 0,
    cashback: 0,
    accumulatedPayment: 0,
    proofPayment: 0,
    subTotal: 0,
    discount: 0,
    percentageDiscount: 0,
    alias: '',
    courtesy: false,
    code: '',
    correlative: 0,
    reason: null,
    sunatVoucher: null,
    typeConsumption: null,
    typeDiscount: '',
    paymentTypes: [],
    card: [],
    cash: [],
    transfer: [],
    type: null
}
