import {
    Modal,
    ModalOverlay,
    ModalBody,
    ModalContent,
    Text,
    Box,
    Grid,
    Input,
    Radio,
    RadioGroup,
    Button
} from '@chakra-ui/react'

import BackSpace from '../../../components/Icon/BackSpace'
import colors from '../../../config/theme/colors'

import useModalDiscount from '../useModalDiscount'

export default function ModalDiscount({
    isOpen,
    onClose
}: {
    isOpen: boolean
    onClose: () => void
}) {
    const {
        numbers,
        handleClear,
        handleSubmit,
        handleClickButton,
        handleRemoveLastDigit,
        discount,
        typeDiscount,
        setTypeDiscount,
        changeHandler
    } = useModalDiscount(isOpen)

    return (
        <Modal isCentered={true} size="xl" onClose={onClose} isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent p="10">
                <ModalBody color="alo-gray">
                    <Text
                        fontSize="2xl"
                        fontWeight="bold"
                        textAlign="center"
                        mb="5"
                    >
                        Descuento de pedido
                    </Text>
                    <RadioGroup
                        display="flex"
                        justifyContent="center"
                        onChange={setTypeDiscount}
                        value={typeDiscount}
                        gridGap="10px"
                    >
                        <Radio
                            fontWeight="semibold"
                            fontSize="2xl"
                            value="amount"
                        >
                            Monto (S/)
                        </Radio>
                        <Radio
                            fontWeight="semibold"
                            fontSize="2xl"
                            value="percentage"
                        >
                            Porcentaje(%)
                        </Radio>
                    </RadioGroup>
                    <Box mb="10" mt="5" display="flex" justifyContent="center">
                        <Input
                            w="350px"
                            value={discount}
                            onChange={changeHandler}
                            autoFocus
                            fontSize="24px"
                            fontWeight="bold"
                            h="50px"
                            borderRadius="xl"
                        />
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <Box>
                            <Grid
                                gap="20px"
                                templateColumns={{
                                    base: 'repeat(2,1fr)',
                                    md: 'repeat(3,1fr)'
                                }}
                            >
                                {numbers.map((number: number) => (
                                    <Button
                                        key={number}
                                        w="90px"
                                        variant="dark:2xl-lg"
                                        bg="transparent"
                                        color="dark"
                                        onClick={() =>
                                            handleClickButton(number)
                                        }
                                    >
                                        {number}
                                    </Button>
                                ))}
                            </Grid>
                            <Box
                                mt="20px"
                                display="flex"
                                justifyContent="flex-end"
                                gridGap="20px"
                            >
                                <Button
                                    w="90px"
                                    variant="dark:2xl-lg"
                                    bg="transparent"
                                    color="dark"
                                    onClick={() => handleClickButton(0)}
                                >
                                    0
                                </Button>
                                <Button
                                    w="90px"
                                    variant="dark:2xl-lg"
                                    bg="transparent"
                                    color="dark"
                                    onClick={() => handleClickButton('.')}
                                >
                                    .
                                </Button>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gridGap="20px"
                        >
                            <Button
                                w="90px"
                                variant="orange:2xl-lg"
                                onClick={handleRemoveLastDigit}
                            >
                                <BackSpace fill={colors.white} />
                            </Button>
                            <Button
                                w="90px"
                                variant="orange:2xl-lg"
                                onClick={handleClear}
                            >
                                Clear
                            </Button>
                            <Button
                                variant="alo-green:2xl-lg"
                                onClick={handleSubmit}
                                pointerEvents={
                                    discount === '' ? 'none' : 'auto'
                                }
                            >
                                Ok
                            </Button>
                        </Box>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
