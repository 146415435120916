import { memo } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { TableResultProps } from './types'

function TableResult({
    cashTotal,
    cardsAmountTotal,
    transferAmountTotal,
    systemTransferAmountTotal,
    systemCashTotal,
    systemCardsAmountTotal
}: TableResultProps) {
    const totalManual = cashTotal + cardsAmountTotal + transferAmountTotal
    const totalSystem =
        systemCashTotal + systemCardsAmountTotal + systemTransferAmountTotal
    const surplus = totalManual - totalSystem
    const missing = totalSystem - totalManual

    return (
        <Table size="sm" mt={5}>
            <Thead>
                <Tr whiteSpace="nowrap" bg="#d0d9df">
                    <Th textAlign="center">Arqueo</Th>
                    <Th textAlign="center">Efectivo + Apertura</Th>
                    <Th textAlign="center">Tarjeta</Th>
                    <Th textAlign="center">Transferencia</Th>
                    <Th textAlign="center">Total</Th>
                    <Th textAlign="center">Sobrante</Th>
                    <Th textAlign="center">Faltante</Th>
                </Tr>
            </Thead>
            <Tbody>
                <Tr>
                    <Th textAlign="center">MANUAL</Th>
                    <Td textAlign="center">S/{cashTotal.toFixed(2)}</Td>
                    <Td textAlign="center">S/{cardsAmountTotal.toFixed(2)}</Td>
                    <Td textAlign="center">
                        S/{transferAmountTotal.toFixed(2)}
                    </Td>
                    <Td textAlign="center">S/{totalManual.toFixed(2)}</Td>
                    <Td textAlign="center" rowSpan={2} borderLeftWidth="1px">
                        S/{(surplus <= 0 ? 0 : surplus).toFixed(2)}
                    </Td>
                    <Td textAlign="center" rowSpan={2}>
                        S/{(missing <= 0 ? 0 : missing).toFixed(2)}
                    </Td>
                </Tr>
                <Tr bg="gray.100">
                    <Th textAlign="center">Sistema</Th>
                    <Th textAlign="center">S/{systemCashTotal.toFixed(2)}</Th>
                    <Th textAlign="center">
                        S/{systemCardsAmountTotal.toFixed(2)}
                    </Th>
                    <Th textAlign="center">
                        S/{systemTransferAmountTotal.toFixed(2)}
                    </Th>
                    <Th textAlign="center">S/{totalSystem.toFixed(2)}</Th>
                </Tr>
            </Tbody>
        </Table>
    )
}

export default memo(TableResult)
