import { Box, Td, Tr } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import List from '../../../lib/list'
import { listHistory } from '../storage'
import DateField from './DateField'
import TdPrinter from './TdPrinter'

export default function ClosingHistory() {
    useEffect(() => {
        listHistory.setQuery('date', dayjs().format('YYYY-MM-DD'))
        listHistory.load()
    }, [])

    return (
        <Box mb={8}>
            <DateField />
            <List
                control={listHistory}
                fullWidth
                tbody={cashboxUser => (
                    <Tr key={cashboxUser.id}>
                        <Td>
                            {dayjs(cashboxUser.started_at).format(
                                'DD/MM/YYYY hh:mm A'
                            )}
                        </Td>
                        <Td>
                            {dayjs(cashboxUser.finished_at).format(
                                'DD/MM/YYYY hh:mm A'
                            )}
                        </Td>
                        <Td>{cashboxUser.cashbox}</Td>
                        <Td>{cashboxUser.cashier}</Td>
                        <Td>{cashboxUser.state}</Td>
                        <Td>{cashboxUser.started_amount}</Td>
                        <TdPrinter cashboxUserId={cashboxUser.id} />
                    </Tr>
                )}
            />
        </Box>
    )
}
