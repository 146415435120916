import { Button, Flex, Select, useDisclosure, useToast } from '@chakra-ui/react'
import Printer from '../../../../components/Icon/Printer'
import useBookings from '../../hooks/useBookings'
import dayjs from 'dayjs'
import { post } from '../../../../lib/http'
import getPrinterConfig from '../../../../utilities/getPrinterConfig'
import useSession from '../../../../hooks/useSession'
import useOverlapLoader from '../../../../hooks/useOverlapLoader'
import ModalConfirm from '../../modals/ModalConfirm'
import { ButtonsActionsProps } from '../types'
import { GroupPrinterBody } from '../../types'
import { useEffect, useRef, useState } from 'react'
import { CommandPrinterData } from './types'
import PrintComponent from './PrintComponent'
import { useReactToPrint } from 'react-to-print'

export default function ButtonsActions({
    bookingId,
    onBookingId,
    hasGroupCommand,
    onLoad
}: ButtonsActionsProps) {
    const componentRef = useRef<HTMLDivElement>(null)
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    })
    const [isLocalPrint, setLocalPrint] = useState(false)
    const [dataPrinter, setDataPrinter] = useState<CommandPrinterData | null>(
        null
    )
    const { isOpen, onClose, onOpen } = useDisclosure()
    const { start, end } = useOverlapLoader()
    const toast = useToast()
    const { session } = useSession()
    const { bookingAgencies } = useBookings()

    useEffect(() => {
        if (!dataPrinter) return
        if (!isLocalPrint) return
        if (!componentRef.current) return
        componentRef.current.style.visibility = 'visible'
        handlePrint()
        componentRef.current.style.visibility = 'hidden'
        setLocalPrint(false)
        setDataPrinter(null)
    }, [dataPrinter, componentRef.current, isLocalPrint])

    async function printerHandler(_isLocalPrint: boolean) {
        const printerConfig = getPrinterConfig()
        if (!printerConfig) {
            toast({
                title: 'Debe configurar primero la impresora',
                status: 'error',
                position: 'top'
            })
            return
        }
        start('Imprimiendo comanda')
        const body: GroupPrinterBody = {
            user_id: session?.user.id as number,
            booking_id: bookingId,
            printer_id: Number(printerConfig.printer_id)
        }
        const result = await post<CommandPrinterData>(
            `/api/printer/group_commands${
                _isLocalPrint ? '?dont_print=' : ''
            } `,
            body
        )
        end()
        if (!result.data) {
            toast({
                title: 'No fue posible la impresión',
                position: 'top',
                status: 'error'
            })
            return
        }

        setLocalPrint(_isLocalPrint)
        if (_isLocalPrint) {
            setDataPrinter(result.data.result)
            return
        } else {
            setDataPrinter(null)
        }

        onLoad()
        toast({
            title: 'Impresión enviada',
            position: 'top',
            status: 'success',
            isClosable: true
        })
    }

    function clickHandler() {
        if (hasGroupCommand) {
            onOpen()
        } else {
            printerHandler(false)
        }
    }

    return (
        <Flex gap={[1, 1, 1, 3]} flexWrap={['wrap', 'wrap', 'nowrap']}>
            <PrintComponent ref={componentRef} data={dataPrinter} />
            <Select
                bg="base-4"
                size="md"
                borderRadius="full"
                placeholder="Seleccionar reserva"
                value={bookingId}
                onChange={ev => onBookingId(Number(ev.target.value))}
            >
                {bookingAgencies.map(booking => (
                    <option key={booking.id} value={booking.id}>
                        {booking.agency?.name} - {booking.agency_guide?.name} -{' '}
                        ({dayjs(booking.hour).format('hh:mm A')})
                    </option>
                ))}
                {bookingAgencies.length === 0 && (
                    <option disabled>- Ninguno - </option>
                )}
            </Select>
            <ModalConfirm
                isOpen={isOpen}
                onClose={onClose}
                title="Reimprimir comanda"
                onYes={() => printerHandler(false)}
            >
                ¿Está seguro de reimprimir la comanda agrupada?
            </ModalConfirm>
            <Flex gap={[1, 1, 1, 3]} w={{ base: 'full', md: 'auto' }}>
                <Button
                    variant="alo-green:md-xl"
                    w={{
                        base: 'full',
                        md: 'auto'
                    }}
                    isDisabled={!bookingId}
                    onClick={() => printerHandler(true)}
                    fontSize={{
                        xl: 'md',
                        base: 'sm'
                    }}
                    leftIcon={<Printer fill="white" fontSize={20} />}
                >
                    Imprimir
                </Button>
                <Button
                    variant="alo-green:md-xl"
                    w={{
                        base: 'full',
                        md: 'auto'
                    }}
                    isDisabled={!bookingId}
                    onClick={clickHandler}
                    fontSize={{
                        xl: 'md',
                        base: 'sm'
                    }}
                    leftIcon={<Printer fill="white" fontSize={20} />}
                >
                    Generar comanda
                </Button>
            </Flex>
        </Flex>
    )
}
