import { SelectedItem } from '../../../types/runway_state'
import { storagePage } from '../storage'

export default function getItemsSelected(
    categoryItemId: number,
    itemId: number
) {
    const { selectedItems } = storagePage

    const category = selectedItems.find(
        selected => selected.category_item_id === categoryItemId
    )

    let itemsFinded: SelectedItem['items'] = []

    if (category) {
        itemsFinded = category.items.filter(_item => _item.id === itemId)
    }

    return {
        items: itemsFinded,
        category
    }
}
