import { Alert, AlertIcon, Text } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

export default function AlertCashbox() {
    const { push } = useHistory()

    return (
        <Alert
            bg="error"
            fontSize="xs"
            color="white"
            py="1"
            justifyContent="center"
        >
            <AlertIcon color="white" boxSize="4" />
            Para realizar una venta necesitas
            <Text
                ml={1}
                textDecoration="underline"
                cursor="pointer"
                onClick={() => push('/cash_register')}
            >
                aperturar la caja
            </Text>
        </Alert>
    )
}
