import { useToast } from '@chakra-ui/react'
import { ChangeEvent, FormEvent, useState } from 'react'
import { post } from '../../../lib/http'
import val from '../../../lib/val'
import { storagePage } from '../storage'

export default function useSplitPayment(onDone: () => void) {
    const [isOpen, setOpen] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [helpers, setHelpers] = useState<{ account?: string }>({})
    const toast = useToast()

    async function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const formElem = ev.target as typeof ev.target & {
            account: { value: string }
        }

        const v = val({
            account: formElem.account.value
        })

        v.account.isEmpty('Es requerido seleccionar una cuenta')

        const result = v.runtest()
        if (result) {
            setHelpers(result)
            return
        }

        setLoading(true)
        const { error } = await post<any>(
            `/api/account_orders/${storagePage.account_id}/add_items`,
            {
                account_settings: storagePage.items_to_divide.map(item => {
                    return {
                        order_item_id: item.id,
                        quantity: item.quantity_to_divide
                    }
                })
            }
        )
        if (error) {
            toast({
                title: error.message,
                status: 'error',
                position: 'top'
            })
            setOpen(false)
        } else {
            toast({
                title: 'Pago dividido',
                status: 'success',
                position: 'top',
                isClosable: true
            })
            setOpen(false)
        }
        onDone()
        setLoading(false)
    }

    function changeHandler(
        ev: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) {
        const name = ev.target.name as keyof typeof helpers
        if (helpers[name]) {
            setHelpers({
                ...helpers,
                [name]: ''
            })
        }
        storagePage.account_id = Number(ev.target.value)
    }

    return {
        isOpen,
        setOpen,
        submitHandler,
        isLoading,
        helpers,
        setHelpers,
        changeHandler
    }
}
