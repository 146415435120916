import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Grid,
    Input,
    InputGroup,
    InputLeftAddon,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper
} from '@chakra-ui/react'
import NumberField from '../../../components/NumberField'
import { ItemForBilling } from '../../../types/runway_state'
import FieldSearch from './FieldSearch'
import useModalProduct from './useModalProduct'

export default function ContenModalProduct({
    element,
    itemToEdit
}: {
    element: (click: () => void) => JSX.Element
    itemToEdit?: ItemForBilling
}) {
    const {
        setOpen,
        isOpen,
        isLoading,
        submitHandler,
        changeHandler,
        changePriceHandler,
        quantity,
        price,
        ref,
        item,
        setItem
    } = useModalProduct(itemToEdit)
    return (
        <>
            {element(() => setOpen(true))}
            <Modal
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                size="xl"
                isCentered={false}
            >
                <ModalOverlay />
                <ModalContent px={6} py={4}>
                    <ModalHeader>
                        {itemToEdit ? 'Editar producto' : 'Agregar producto'}
                    </ModalHeader>
                    <ModalBody pb={6}>
                        <form onSubmit={submitHandler} id="form-add-product">
                            <Box>
                                <FormLabel>
                                    Buscar y seleccionar un producto
                                </FormLabel>
                                <FieldSearch item={item} onSelect={setItem} />
                                <Grid
                                    templateColumns="repeat(3, 1fr)"
                                    gap={4}
                                    mt={6}
                                >
                                    <FormControl>
                                        <FormLabel>Cantidad</FormLabel>
                                        <NumberInput
                                            value={quantity}
                                            max={9999}
                                            min={1}
                                            name="quantity"
                                            onChange={changeHandler}
                                        >
                                            <NumberInputField
                                                placeholder="Cantidad"
                                                ref={ref}
                                            />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Precio</FormLabel>
                                        <InputGroup>
                                            <InputLeftAddon>S/</InputLeftAddon>
                                            <NumberField
                                                value={price}
                                                name="price"
                                                placeholder="Precio"
                                                borderLeftRadius="none"
                                                onChange={changePriceHandler}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Total</FormLabel>
                                        <Input
                                            value={
                                                Number(quantity) * Number(price)
                                            }
                                            name="total"
                                            placeholder="Total"
                                            bgColor="gray.100"
                                            isReadOnly
                                        />
                                    </FormControl>
                                </Grid>
                            </Box>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="alo-gray:lg-md"
                            bg="transparent"
                            color="alo-gray"
                            w="36"
                            onClick={() => setOpen(false)}
                        >
                            Cerrar
                        </Button>
                        <Button
                            variant="alo-blue:lg-md"
                            w="36"
                            ml={3}
                            type="submit"
                            form="form-emit"
                            isLoading={isLoading}
                            isDisabled={
                                !item || quantity === '' || quantity === '0'
                            }
                            onClick={submitHandler}
                        >
                            {itemToEdit ? 'Editar' : 'Agregar'}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
