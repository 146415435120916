import { Icon } from '@chakra-ui/react'
import Pencil from '../../../components/Icon/Pencil'
import Colors from '../../../config/colors'
import useFeature from './useFeature'
import Feature from '../../../models/feature'

export default function Edition({ feature }: { feature: Feature }) {
    const openModal = useFeature(feature)

    return (
        <Icon
            fill={Colors.INFO}
            as={Pencil}
            marginRight={2}
            cursor="pointer"
            onClick={openModal}
        />
    )
}
