import { useState, useContext, ChangeEvent, useEffect } from 'react'
import CheckInContext from './context/CheckInContext'

export default function useModalDiscount(isOpen: boolean) {
    const [discount, setDiscount] = useState('')
    const [typeDiscount, setTypeDiscount] = useState<'amount' | 'percentage'>(
        'amount'
    )
    const [numbers] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9])
    const { setOpenModalDiscount, setStateDiscount, order } =
        useContext(CheckInContext)

    const handleClear = () => {
        setDiscount('')
    }

    useEffect(() => {
        if (isOpen) {
            handleClear()
        }
    }, [isOpen])

    const handleClickButton = (value: number | string) => {
        const currentDiscount = Number(discount + value)
        if (typeDiscount === 'percentage') {
            if (Number(discount + value) >= 100) {
                setDiscount('99')
            } else {
                setDiscount(discount + value)
            }
        } else {
            const amount = order?.amount ? order.amount - 0.01 : 0.0
            if (currentDiscount <= amount) {
                setDiscount(discount + value)
            } else {
                setDiscount(amount.toString())
            }
        }
    }

    function changeHandler(ev: ChangeEvent<HTMLInputElement>) {
        const value = Number(ev.target.value)
        const max =
            typeDiscount === 'percentage' ? 99 : order?.amout - 0.01 || 0.01

        setDiscount(value >= max ? max.toString() : value.toString())
    }

    const handleRemoveLastDigit = () => {
        if (discount.length !== 0) {
            setDiscount(discount.slice(0, -1))
        }
    }

    const handleSubmit = () => {
        setStateDiscount((prev: any) => {
            return {
                ...prev,
                [typeDiscount]: discount,
                ...(typeDiscount === 'amount' && {
                    percentage: 0
                }),
                ...(typeDiscount === 'percentage' && {
                    amount: 0
                })
            }
        })
        setOpenModalDiscount(false)
        handleClear()
    }

    return {
        discount,
        numbers,
        handleSubmit,
        handleRemoveLastDigit,
        handleClickButton,
        handleClear,
        typeDiscount,
        setTypeDiscount: (value: 'amount' | 'percentage') => {
            handleClear()
            setTypeDiscount(value)
        },
        changeHandler
    }
}
