import { useLocation } from 'react-router-dom'

export default function useQuery<T extends Record<keyof T, string>>(): T {
    const { search } = useLocation()
    const params = new URLSearchParams(search)
    const objParams: Record<string, string> = {}
    params.forEach((value, key) => {
        objParams[key] = value
    })
    return objParams as T
}
