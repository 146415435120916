import {
    Modal,
    ModalOverlay,
    ModalBody,
    ModalContent,
    Text,
    Box,
    Textarea,
    FormControl,
    FormLabel
} from '@chakra-ui/react'
import Block from '../../../../components/Icon/Block'
import colors from '../../../../config/theme/colors'
import ErrorHelper from '../../../../components/ErrorHelper'
import { ModalAnnulProps } from './types'
import AnnulFooter from './AnnulFooter'
import useAnnul from '../../hooks/useAnnul'

export default function ModalAnnul({
    isOpen,
    onClose,
    annulOrder,
    onDone
}: ModalAnnulProps) {
    const { submit, changeEvent, helpers } = useAnnul(annulOrder, onDone)

    return (
        <Modal isCentered={true} size="lg" onClose={onClose} isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent p="5">
                <form noValidate onSubmit={submit}>
                    <ModalBody>
                        <Box display="flex" justifyContent="center">
                            <Block fill={colors.error} fontSize="42" />
                        </Box>
                        <Text
                            color="gray-2"
                            fontSize="2xl"
                            fontWeight="bold"
                            textAlign="center"
                            my="5"
                        >
                            {annulOrder
                                ? 'Anular pedido'
                                : 'Anulación de ítems'}
                        </Text>
                        <Text textAlign="center" fontSize="lg" pb={3}>
                            {annulOrder
                                ? '¿Está seguro de anular el pedido?'
                                : 'Debido a que se han disminuído ítems al pedido, debe ingresar el motivo de anulación'}
                        </Text>
                        <FormControl isRequired>
                            <FormLabel>Ingresar motivo</FormLabel>
                            <Textarea
                                name="reason"
                                placeholder={
                                    annulOrder
                                        ? 'Ingresar motivo de anulación'
                                        : 'Ingresar motivo de anulación de ítem(S)'
                                }
                                maxLength={50}
                                onChange={changeEvent}
                            />
                            <ErrorHelper helper={helpers.reason} />
                        </FormControl>
                    </ModalBody>
                    <AnnulFooter onClose={onClose} />
                </form>
            </ModalContent>
        </Modal>
    )
}
