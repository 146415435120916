import {
    Box,
    Flex,
    SimpleGrid,
    Table,
    Tbody,
    Td,
    Text,
    Tr
} from '@chakra-ui/react'
import { ForwardedRef, forwardRef } from 'react'
import { PrintComponentProps, CommandPrinterDataItem } from './types'

function PrintComponent(
    { data }: PrintComponentProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const items = data?.dataItems || []

    let groupedItems: Array<{
        categoryName: string
        items: CommandPrinterDataItem[]
    }> = []

    items.forEach(item => {
        const groupItems = groupedItems.find(
            group => group.categoryName === item.categoryName
        )
        if (groupItems) {
            groupItems.items.push(item)
            return
        }

        groupedItems.push({
            categoryName: item.categoryName,
            items: [item]
        })
    })

    groupedItems = groupedItems.sort((a, b) =>
        a.categoryName.localeCompare(b.categoryName)
    )

    return (
        <Box
            position="absolute"
            ref={ref}
            zIndex={-2}
            left={0}
            visibility="hidden"
            p="10"
            w="full"
        >
            <Text textAlign="center" mb={6} fontWeight="bold" fontSize="xl">
                NUEVO PEDIDO GRUPAL
            </Text>
            <Flex justify="center" w="full" mb={8}>
                <Table
                    textAlign="left"
                    variant="unstyled"
                    size="md"
                    mb={3}
                    width="auto"
                >
                    <Tbody>
                        <Tr>
                            <Td py={1} pr={8}>
                                FECHA:{' '}
                                <Text as="span" fontWeight="bold">
                                    {data?.nowDateString}
                                </Text>
                            </Td>
                            <Td py={1}>
                                HORA:{' '}
                                <Text as="span" fontWeight="bold">
                                    {data?.nowHourString}
                                </Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td py={1} colSpan={2}>
                                MOZO:{' '}
                                <Text as="span" fontWeight="bold">
                                    {data?.waiterName}
                                </Text>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td py={1} pr={8}>
                                AGENCIA:{' '}
                                <Text as="span" fontWeight="bold">
                                    {data?.agencyName}
                                </Text>
                            </Td>
                            <Td py={1}>
                                GUÍA TURÍSTICO:{' '}
                                <Text as="span" fontWeight="bold">
                                    {data?.agencyGuideName}
                                </Text>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </Flex>
            <SimpleGrid columns={2} gap={6}>
                {groupedItems.map(group => (
                    <Box
                        key={group.categoryName}
                        borderWidth={1}
                        borderRadius="lg"
                        p={6}
                    >
                        <Box fontWeight="bold" fontSize="lg" mb={4}>
                            {group.categoryName}
                        </Box>
                        {group.items.map((item, i) => (
                            <Flex key={item.name + i} gap={6}>
                                <Text w="2">{item.quantity}</Text>
                                <Box mb={item.extra ? 1 : 0}>
                                    <Text>{item.name}</Text>
                                    <Text fontSize="sm" color="blackAlpha.700">
                                        {item.extra}
                                    </Text>
                                </Box>
                            </Flex>
                        ))}
                    </Box>
                ))}
            </SimpleGrid>
        </Box>
    )
}

export default forwardRef(PrintComponent)
