import { Box, Flex, Icon, IconButton, Text } from '@chakra-ui/react'
import Description from '../../../components/Icon/Description'
import Close from '../../../components/Icon/Close'
import ModalEditItem from '../modals/ModalEditItem'
import { useState } from 'react'
import { MenuItemProps } from './types'

export default function MenuItem({
    menuItem,
    onDelete,
    isModeEdition,
    canEdit
}: MenuItemProps) {
    const [isOpen, setIsOpen] = useState(false)
    function clickHandler(ev: any) {
        const target = ev.target as HTMLDivElement
        if (target.classList.contains('close')) return
        setIsOpen(true)
    }

    return (
        <>
            <ModalEditItem
                isOpen={isOpen}
                data={{
                    itemId: menuItem.itemId,
                    categoryItemId: menuItem.categoryItemId,
                    name: menuItem.name,
                    quantity: menuItem.quantity,
                    description: menuItem.description,
                    unitPrice: menuItem.price
                }}
                onClose={() => setIsOpen(false)}
                onOpen={() => setIsOpen(true)}
                by="pre_orders"
            />
            <Flex
                onClick={clickHandler}
                justifyContent="space-between"
                align="center"
                role="group"
                fontSize="lg"
                pr={{ base: 0, lg: 6 }}
                pl={{ base: 3, lg: 6 }}
                py={0.5}
                cursor="pointer"
                userSelect="none"
                pointerEvents={isModeEdition ? 'auto' : 'none'}
                w="full"
                _hover={{
                    bg: 'blackAlpha.50'
                }}
            >
                <Text
                    textAlign="left"
                    fontSize={{
                        xl: '16px',
                        lg: '14px'
                    }}
                >
                    <Box minW="6" as="span" display="inline-block">
                        {menuItem.quantity}
                    </Box>
                    {menuItem.name}
                    {menuItem.description && (
                        <Icon
                            as={Description}
                            fill="gray-2"
                            fontSize="18px"
                            ml={2}
                        />
                    )}
                </Text>
                <Flex align="center">
                    <Text
                        textAlign="right"
                        fontSize={{ xl: '16px', lg: '14px' }}
                    >
                        S/{(menuItem.price * menuItem.quantity).toFixed(2)}
                    </Text>
                    {canEdit && (
                        <IconButton
                            icon={<Close />}
                            aria-label="Eliminar"
                            size="sm"
                            fill="red.400"
                            className="close"
                            ml={1}
                            variant="ghost"
                            mr={{
                                base: 0,
                                lg: -4
                            }}
                            onClick={onDelete}
                        />
                    )}
                </Flex>
            </Flex>
        </>
    )
}
