import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { post } from '../../lib/http'
import DialogConfirm from './DialogConfirm'
import ModalChangePassword from './ModalChangePassword'

export default function Modals({
    openModal,
    setOpenModal
}: {
    openModal: boolean
    setOpenModal: (value: boolean) => void
}) {
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [data, setData] = useState<any>(null)
    const toast = useToast()
    const [isLoading, setLoading] = useState(false)

    const handleConfirm = async () => {
        setLoading(true)
        const { error } = await post<any>('/api/change_password', data)
        setLoading(false)
        if (error) {
            if (Object.values(error?.errors).length === 0) {
                toast({
                    status: 'error',
                    title: error.message,
                    position: 'top'
                })
            }
            return
        }
        setOpenDialog(false)
        toast({
            status: 'success',
            title: 'Se realizó el cambio de contraseña',
            position: 'top'
        })
    }

    const handleClose = () => {
        setOpenModal(false)
        setOpenDialog(true)
    }

    return (
        <>
            {openDialog && (
                <DialogConfirm
                    isOpen={openDialog}
                    text="Confirmar contraseña"
                    onClose={() => setOpenDialog(false)}
                    onConfirm={handleConfirm}
                    isLoading={isLoading}
                />
            )}
            {openModal && (
                <ModalChangePassword
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    setData={setData}
                    handleClose={handleClose}
                />
            )}
        </>
    )
}
