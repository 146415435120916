import dayjs from 'dayjs'
import { useState } from 'react'
import { useGet } from '../../../hooks/useHttp'
import Booking from '../../../models/booking'

export default function useBookings() {
    const [date] = useState(() => dayjs().format('YYYY-MM-DD'))
    const { data, isLoading } = useGet<Booking[]>(
        `/api/bookings?sort=id&limit=1000&date=${date}`,
        []
    )

    const bookingAgencies = data.result
        .filter(booking => booking.agency_id)
        .sort((a, b) => {
            const aName = a.agency?.name || ''
            const bName = b.agency?.name || ''
            if (aName < bName) {
                return -1
            }
            if (aName > bName) {
                return 1
            }
            return 0
        })
    const bookingDrivers = data.result
        .filter(booking => booking.driver_id)
        .sort((a, b) => {
            const aName = a.driver?.name || ''
            const bName = b.driver?.name || ''
            if (aName < bName) {
                return -1
            }
            if (aName > bName) {
                return 1
            }
            return 0
        })
    const bookingIndenpendecies = data.result
        .filter(booking => !booking.driver_id && !booking.agency_id)
        .sort((a, b) => {
            const aName = a.client_name || ''
            const bName = b.client_name || ''
            if (aName < bName) {
                return -1
            }
            if (aName > bName) {
                return 1
            }
            return 0
        })

    return {
        bookingAgencies,
        bookingDrivers,
        bookingIndenpendecies,
        isLoading
    }
}
