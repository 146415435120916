import { useToast } from '@chakra-ui/react'
import { FormEvent, useState } from 'react'
import { post, put } from '../../../lib/http'
import extractDataForm from '../../../utilities/extractDataForm'

export default function useSubmitWarehouse(
    done: () => void,
    warehouseId?: number
) {
    const [fieldNameMessage, setFieldNameMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()

    async function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const form = extractDataForm<{ name: string }>(ev.target)
        if (form.name.trim() === '') {
            setFieldNameMessage('El nombre es requerido')
            return
        }
        setIsLoading(true)
        const send = warehouseId ? put : post
        const { error } = await send(
            `/api/warehouses/${warehouseId ? warehouseId : ''}`,
            form
        )
        if (error) {
            if (error.type === 'customer' && error.errors.name) {
                setFieldNameMessage(error.errors.name)
                setIsLoading(false)
                return
            }

            toast({
                title: error.message,
                position: 'top',
                status: 'error'
            })
        } else {
            toast({
                title: warehouseId
                    ? 'Se ga actualizado el almacén'
                    : 'Se ha guardado el nuevo almacén',
                position: 'top',
                status: 'success'
            })
            done()
        }
        setIsLoading(false)
    }

    return {
        submitHandler,
        fieldNameMessage,
        setFieldNameMessage,
        isLoading
    }
}
