import FormEvent from '../../lib/form_event'
import ControlList from '../../lib/list/ControlList'
import Booking from '../../models/booking'

export const PATH_ENDPOINT_BOOKING = '/api/bookings'
export const TABS_VALUE = {
    BOOKING: 'booking'
}

export const TYPE_BOOKINGS_VALUE = {
    AGENCY: 1,
    DRIVER: 2,
    CLIENT: 3
}

export const listBooking = new ControlList<Booking>({
    path: PATH_ENDPOINT_BOOKING,
    theads: [
        '#',
        'Hora',
        'Cliente',
        'Tipo',
        'N° de personas',
        'Estado',
        'Acciones'
    ]
})

export function getTextType(type: number) {
    switch (type) {
        case 1:
            return 'Agencia'
        case 2:
            return 'Guía local'
        case 3:
            return 'Cliente independiente'
        default:
            return 'Nuevo destino de preparación'
    }
}

export const formBooking = new FormEvent<any>({
    client_name: '',
    type: 1,
    comment: '',
    person_quantity: 0,
    agency_id: '',
    agency_guide_id: '',
    driver_id: '',
    hour: '',
    date: ''
})
