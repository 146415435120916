import { Icon } from '@chakra-ui/react'
import Trash from '../../../components/Icon/Trash'
import colors from '../../../config/theme/colors'
import Delete from '../../../lib/delete'
import { listProdItemCategories, PATH_ENDPOINT_CATEGORY } from '../storage'
import ProductionItemCategory from '../../../models/production_item_category'

export default function DeleteCategory({
    category
}: {
    category: ProductionItemCategory
}) {
    return (
        <Delete
            title="Eliminar categoría de artículos de inventario"
            path={`${PATH_ENDPOINT_CATEGORY}/${category.id}`}
            element={click => (
                <Icon
                    onClick={click}
                    fill={colors.error}
                    as={Trash}
                    cursor="pointer"
                />
            )}
            done={() => {
                listProdItemCategories.setQuery('page', 1)
                listProdItemCategories.load()
            }}
        >
            ¿Esta seguro de eliminar la categoría <b>{category.name}</b>?
        </Delete>
    )
}
