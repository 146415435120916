import { Icon } from '@chakra-ui/react'
import Trash from '../../../components/Icon/Trash'
import colors from '../../../config/theme/colors'
import Delete from '../../../lib/delete'
import CategoryItem from '../../../models/category_item'
import { listCategory } from '../storage'

export default function DeleteCategory({
    category
}: {
    category: CategoryItem
}) {
    return (
        <Delete
            title="Eliminar categoría"
            path={`/api/category_items/${category.id}`}
            element={click => (
                <Icon
                    onClick={click}
                    fill={colors.error}
                    as={Trash}
                    cursor="pointer"
                />
            )}
            done={() => listCategory.load()}
        >
            ¿Esta seguro de eliminar la categoría <b>{category.name}</b>?
        </Delete>
    )
}
