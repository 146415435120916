import {
    Box,
    Button,
    Center,
    Flex,
    Grid,
    GridItem,
    Spinner
} from '@chakra-ui/react'
import useResolution from '../../../hooks/useResolution'
import { storagePage } from '../storage'
import { CashboxUserData } from '../types'
import Commentary from './Commentary'
import TableBanknotes from './TableBanknotes'
import TableCardControl from './TableCardControl'
import TableCoins from './TableCoins'
import TableResult from './TableResult'
import useArchingValues from './useArchingValues'
import useSubmitSave from './useSubmitSave'
import { memo } from 'react'
import { ArchingProps } from './types'

function Arching({ onDone }: ArchingProps) {
    const {
        commentary,
        setCommentary,
        banknotesValues,
        setBanknotesValues,
        coinsValues,
        setCoinsValues,
        card,
        setCard,
        transfer,
        setTransfer,
        banknoteTotal,
        coinsTotal,
        isLoading: isGetLoading
    } = useArchingValues()
    const { isDesktop } = useResolution()
    const { save, isLoading } = useSubmitSave(
        {
            commentary,
            banknotesValues,
            coinsValues,
            cardValue: card,
            transferValue: transfer,
            totalManual:
                banknoteTotal + coinsTotal + Number(card) + Number(transfer)
        },
        onDone
    )
    const cashboxUser = storagePage.cashboxUser as CashboxUserData

    return (
        <>
            {isGetLoading && (
                <Center h="48">
                    <Spinner />
                </Center>
            )}
            {!isGetLoading && (
                <Box>
                    <Grid
                        templateColumns={`repeat(${
                            isDesktop ? '3' : '1'
                        }, 1fr)`}
                        w="full"
                    >
                        <GridItem
                            overflowY={{ base: 'auto', md: 'initial' }}
                            borderRightWidth={{ base: '0', xl: '1px' }}
                        >
                            <TableBanknotes
                                banknotesValues={banknotesValues}
                                setBanknotesValues={setBanknotesValues}
                                total={banknoteTotal}
                            />
                        </GridItem>
                        <GridItem
                            overflowY={{ base: 'auto', md: 'initial' }}
                            borderRightWidth={{ base: '0', xl: '1px' }}
                        >
                            <TableCoins
                                coinsValues={coinsValues}
                                setCoinsValues={setCoinsValues}
                                total={coinsTotal}
                            />
                        </GridItem>
                        <GridItem overflowY={{ base: 'auto', md: 'initial' }}>
                            <TableCardControl
                                card={card}
                                systemCardAmount={cashboxUser.card}
                                setCard={setCard}
                                transfer={transfer}
                                systemTransferAmount={cashboxUser.transfer}
                                setTransfer={setTransfer}
                            />
                        </GridItem>
                    </Grid>
                    <Box overflowY="auto" w="full">
                        <TableResult
                            cashTotal={banknoteTotal + coinsTotal}
                            cardsAmountTotal={Number(card)}
                            transferAmountTotal={Number(transfer)}
                            systemCashTotal={
                                cashboxUser.cash +
                                cashboxUser.started_amount +
                                (cashboxUser.income - cashboxUser.expenses)
                            }
                            systemCardsAmountTotal={cashboxUser.card}
                            systemTransferAmountTotal={cashboxUser.transfer}
                        />
                    </Box>
                    <Flex
                        justifyContent="space-between"
                        mt={{
                            base: 4,
                            md: 8
                        }}
                        pb={4}
                        flexWrap={{
                            base: 'wrap',
                            md: 'nowrap'
                        }}
                    >
                        <Commentary
                            value={commentary}
                            setCommentary={setCommentary}
                        />
                        <Button
                            variant="dark-2:sm-xl"
                            boxShadow="md"
                            isLoading={isLoading}
                            onClick={save}
                            w={{
                                base: 'full',
                                md: 'auto'
                            }}
                        >
                            Guardar Arqueo
                        </Button>
                    </Flex>
                </Box>
            )}
        </>
    )
}

export default memo(Arching)
