import { useEffect } from 'react'
import { get } from '../../lib/http'
import { controlList, storagePage } from './storage'
import { PaymentTypeResponse } from './types'

export default function useInitial() {
    async function load() {
        const paymentTypes = await get<PaymentTypeResponse>(
            '/api/type_payments/all'
        )
        if (paymentTypes.error) {
            return
        }
        storagePage.payment_methods = paymentTypes.data.result.result
    }

    useEffect(() => {
        controlList.setQuery('order', 'desc')
        controlList.setQuery('sort', 'created_at')
        controlList.load()
        load()
        return () => {
            controlList.deleteQuery('search')
        }
    }, [])
}
