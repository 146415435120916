const TypeIdentificationDocument: Record<number, string> = {
    1: 'DNI',
    2: 'RUC',
    3: 'Carnet de extranjería',
    4: 'Pasaporte',
    5: 'Partida de nacimiento',
    6: 'Otros'
}

export const TypeIdentificationDocumentByCode: Record<string, string> = {
    '1': 'DNI',
    '6': 'RUC',
    '4': 'Carnet de extranjería',
    '7': 'Pasaporte',
    '8': 'Partida de nacimiento',
    '0': 'Otros'
}

export default TypeIdentificationDocument
