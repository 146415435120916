import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useId
} from '@chakra-ui/react'
import { FormEvent } from 'react'
import colors from '../../../../config/theme/colors'
import usePayCommission from '../../hooks/usePayCommission'
import FormPay from './FormPay'
import { ModalPayProps } from './types'

export default function ModalPay({
    isOpen,
    onClose,
    booking,
    onDone
}: ModalPayProps) {
    const { submit, setHelpers, helpers, isLoading } = usePayCommission(
        booking.id
    )
    const id = useId()

    async function sumitHandler(ev: FormEvent<HTMLFormElement>) {
        const result = await submit(ev)
        if (result) {
            onDone()
            onClose()
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick
            size="md"
            isCentered
        >
            <ModalOverlay />
            <ModalContent px={6} pt={4} pb={6}>
                <ModalHeader textAlign="center">
                    <Text fontSize="xl">Nuevo pago de comisión</Text>
                    <Text fontSize="sm">
                        ({booking?.agency ? 'Agencia' : 'Guía local'})
                    </Text>
                </ModalHeader>
                <ModalBody>
                    <form id={id} onSubmit={sumitHandler}>
                        <FormPay
                            booking={booking}
                            helpers={helpers}
                            setHelpers={setHelpers}
                        />
                    </form>
                </ModalBody>
                <ModalFooter gap={3}>
                    <Button
                        _hover={{}}
                        borderWidth="1px"
                        borderColor="#434343"
                        color="#434343"
                        bg="white"
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        color="white"
                        bg={colors['alo-green']}
                        form={id}
                        type="submit"
                        _hover={{}}
                        isLoading={isLoading}
                    >
                        Confirmar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
