import { Box, Flex, Image } from '@chakra-ui/react'
import logo from '../../assets/logo-alt.svg'
import PrinterConfig from './PrinterConfig'
import { PropsInitial } from './types'

export default function Navbar({ text, children, component }: PropsInitial) {
    return (
        <Box
            w="full"
            bg="dark"
            color="white"
            h="60px"
            display="flex"
            justifyContent="space-between"
        >
            <Flex
                justifyContent="space-between"
                alignItems="center"
                px={[4, 4, 4, 6]}
                gap={[4, 4, 4, 12]}
            >
                <Box display={{ base: 'none', xl: 'block' }}>
                    <Image w="12" h="auto" src={logo} alt="logo"></Image>
                </Box>
                {text && (
                    <Box fontSize="xl" fontWeight="bold">
                        {text}
                    </Box>
                )}
                {component && <>{component}</>}
            </Flex>
            <Flex alignItems="center">
                <PrinterConfig />
                {children}
            </Flex>
        </Box>
    )
}
