import { render } from 'redity'
import CategoryItem from '../../../models/category_item'
import Item from '../../../models/item'
import { FeatureSetting, SelectedItem } from '../../../types/runway_state'
import { generateId } from '../../../utilities/generateId'
import Keys from '../keys'
import { FeatureSelected } from '../ContentModals/IceCream/types'
import { storagePage } from '../storage'
import getFeatureSettingSubFeatures from '../utilities/getFeatureSettingSubFeatures'
import getItemsSelected from '../utilities/getItemsSelected'
import compareFeatureSetting from '../utilities/compareFeatureSetting'

export default function useAddItems(
    item: Item & {
        category_item: CategoryItem
    }
) {
    const { selectedItems } = storagePage
    const getItemForAdd = (
        quantity: number,
        feature_settings: FeatureSetting[],
        idGenerated?: string
    ) => ({
        id: item.id,
        _id_generated: idGenerated,
        name: item.name,
        quantity,
        quantity_recorded: 0,
        price: item.price,
        description: '',
        user_price_edition: 0,
        feature_settings
    })

    function addItem(quantity?: number) {
        const { items: currentItems, category } = getItemsSelected(
            item.category_item_id,
            item.id
        )
        if (currentItems[0]) {
            currentItems[0].quantity = quantity
                ? quantity + currentItems[0].quantity
                : ++currentItems[0].quantity
        } else {
            contitionalPushItem(category, quantity || 1)
        }
        render(Keys.DETAILS_LIST_ITEMS)
        render(Keys.CONTENT_BOTTOM)
        render(Keys.LIST_ITEM)
        render(Keys.BUTTON_SEE_ORDER)
    }

    function addItemWithFeatures(
        featureSelected: FeatureSelected[],
        quantity: number
    ) {
        const { items: currentItems, category } = getItemsSelected(
            item.category_item_id,
            item.id
        )

        const featureSettings = featureSelected.map(fs => ({
            feature_id: fs.feature_id,
            quantity: fs.quantity,
            name: fs.name,
            feature_setting_sub_features: getFeatureSettingSubFeatures(fs.subs)
        }))

        if (currentItems.length > 0) {
            let isFinded = false
            for (const currentItem of currentItems) {
                if (
                    compareFeatureSetting(
                        currentItem.feature_settings,
                        featureSettings
                    )
                ) {
                    currentItem.quantity = currentItem.quantity + quantity
                    isFinded = true
                    break
                }
            }
            if (!isFinded) {
                contitionalPushItem(category, quantity, featureSettings)
            }
        } else {
            contitionalPushItem(category, quantity, featureSettings)
        }

        render(Keys.DETAILS_LIST_ITEMS)
        render(Keys.CONTENT_BOTTOM)
        render(Keys.LIST_ITEM)
        render(Keys.BUTTON_SEE_ORDER)
    }

    function contitionalPushItem(
        category: SelectedItem | undefined,
        quantity = 1,
        featureSettings: FeatureSetting[] = []
    ) {
        const generatedId =
            featureSettings.length > 0 ? generateId() : undefined
        if (category) {
            category.items.push(
                getItemForAdd(quantity, featureSettings, generatedId)
            )
        } else {
            selectedItems.push({
                category_item_id: item.category_item_id,
                category_item_name: item.category_item.name,
                items: [getItemForAdd(quantity, featureSettings, generatedId)]
            })
        }
    }

    return {
        addItem,
        addItemWithFeatures
    }
}
