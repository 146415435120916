import { Fragment } from 'react'
import {
    Modal,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    Text,
    ModalBody,
    Spinner,
    Box,
    Heading,
    Divider
} from '@chakra-ui/react'
import PreOrder from '../../../../models/pre_order'
import { usePreOrder } from './usePreOrder'
import ItemInfo from './ItemInfo'
import AditionalInformation from './AditionalInformation'
import { getCategoryWithItems } from './utilities'

export default function ModalPreOrder({
    isOpen,
    onClose,
    dataPreOrder
}: {
    isOpen: boolean
    onClose: () => void
    dataPreOrder: PreOrder
}) {
    const { loading, preOrder } = usePreOrder(dataPreOrder, onClose)

    const listCategoryWithItems = getCategoryWithItems(
        preOrder?.pre_order_items ?? []
    )

    const money = listCategoryWithItems
        .map(selected =>
            selected.items.reduce((a, b) => {
                let restCost = 0
                if (b.feature_settings) {
                    restCost = b.feature_settings.reduce(
                        (c, d) =>
                            c +
                            d.feature_setting_sub_features.reduce(
                                (e, f) => e + f.price * f.quantity,
                                0
                            ),
                        0
                    )
                }
                return a + (b.price + restCost) * b.quantity
            }, 0)
        )
        .reduce((a, b) => a + b, 0)

    return (
        <Modal size="md" isCentered onClose={onClose} isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent px={6} pt={4} pb={10}>
                {loading ? (
                    <Box display="flex" justifyContent="center">
                        <Spinner />
                    </Box>
                ) : (
                    <>
                        <ModalHeader>
                            <Text
                                textAlign={'center'}
                                fontSize="2xl"
                                fontWeight="bold"
                            >
                                Pre-pedido
                            </Text>
                            <Text textAlign={'center'} fontSize="md">
                                {preOrder?.alias}
                            </Text>
                        </ModalHeader>
                        <ModalBody>
                            <Box
                                overflowY="auto"
                                overflowX="hidden"
                                maxH={{
                                    base: '30vh',
                                    md: '35vh'
                                }}
                            >
                                <Box pr="4">
                                    {listCategoryWithItems.map(
                                        (categoryWithItems, i) => (
                                            <Box color="alo-gray" key={i}>
                                                <Heading size="sm" my="2">
                                                    {
                                                        categoryWithItems.category_item_name
                                                    }
                                                </Heading>
                                                {categoryWithItems.items.map(
                                                    (item, _i) => (
                                                        <Fragment key={_i}>
                                                            {item.quantity !==
                                                                0 && (
                                                                <Box position="relative">
                                                                    <ItemInfo
                                                                        item={
                                                                            item
                                                                        }
                                                                    />
                                                                </Box>
                                                            )}
                                                        </Fragment>
                                                    )
                                                )}
                                            </Box>
                                        )
                                    )}
                                </Box>
                            </Box>

                            <Box px="6" mx="-6">
                                <Divider borderColor="black" mt="5" mb="3" />
                                <Box
                                    mb="4"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Text fontWeight="bold">TOTAL</Text>
                                    <Text fontWeight="bold" pr="4">
                                        S/ {money.toFixed(2)}
                                    </Text>
                                </Box>
                            </Box>
                            {preOrder && (
                                <Box pt="4">
                                    <AditionalInformation preOrder={preOrder} />
                                </Box>
                            )}
                        </ModalBody>
                    </>
                )}
            </ModalContent>
        </Modal>
    )
}
