import { IconButton, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { Payment } from '../../pages/UpdatePayments/types'
import colors from '../../config/theme/colors'
import Close from '../Icon/Close'

interface Props {
    card: Payment[]
    cash: Payment[]
    transfer: Payment[]
    deleteHandler: (who: 'cash' | 'card' | 'transfer', i: number) => void
}

export default function TablePayments({
    card,
    cash,
    transfer,
    deleteHandler
}: Props) {
    return (
        <Table
            mt={{
                xl: 6,
                lg: 5,
                base: 4
            }}
            size="sm"
            border="2px solid"
            borderColor={colors['gray-2']}
            borderRadius="lg"
        >
            <Thead>
                <Tr>
                    <Th borderBottomWidth="2px" borderColor={colors['gray-2']}>
                        Tipo de pago
                    </Th>
                    <Th borderBottomWidth="2px" borderColor={colors['gray-2']}>
                        Monto
                    </Th>
                    <Th borderBottomWidth="2px" borderColor={colors['gray-2']}>
                        Acciones
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                {cash.map((payment, i) => (
                    <Tr key={i}>
                        <Td
                            borderBottomWidth="2px"
                            borderColor={colors['gray-2']}
                        >
                            Efectivo
                        </Td>
                        <Td
                            borderBottomWidth="2px"
                            borderColor={colors['gray-2']}
                        >
                            {payment.amount}
                        </Td>
                        <Td
                            borderBottomWidth="2px"
                            borderColor={colors['gray-2']}
                        >
                            <IconButton
                                icon={<Close fill="white" />}
                                aria-label="pencil"
                                size="xs"
                                bg="error"
                                onClick={() => deleteHandler('cash', i)}
                            />
                        </Td>
                    </Tr>
                ))}
                {card.map((payment, i) => (
                    <Tr key={i}>
                        <Td
                            borderBottomWidth="2px"
                            borderColor={colors['gray-2']}
                        >
                            Tarjeta
                        </Td>
                        <Td
                            borderBottomWidth="2px"
                            borderColor={colors['gray-2']}
                        >
                            {payment.amount}
                        </Td>
                        <Td
                            borderBottomWidth="2px"
                            borderColor={colors['gray-2']}
                        >
                            <IconButton
                                icon={<Close fill="white" />}
                                aria-label="pencil"
                                size="xs"
                                bg="error"
                                onClick={() => deleteHandler('card', i)}
                            />
                        </Td>
                    </Tr>
                ))}
                {transfer.map((payment, i) => (
                    <Tr key={i}>
                        <Td
                            borderBottomWidth="2px"
                            borderColor={colors['gray-2']}
                        >
                            {payment.sub_payment_name}
                        </Td>
                        <Td
                            borderBottomWidth="2px"
                            borderColor={colors['gray-2']}
                        >
                            {payment.amount}
                        </Td>
                        <Td
                            borderBottomWidth="2px"
                            borderColor={colors['gray-2']}
                        >
                            <IconButton
                                icon={<Close fill="white" />}
                                aria-label="pencil"
                                size="xs"
                                bg="error"
                                onClick={() => deleteHandler('transfer', i)}
                            />
                        </Td>
                    </Tr>
                ))}
                {cash.length == 0 && card.length == 0 && transfer.length == 0 && (
                    <Tr>
                        <Td colSpan={3} textAlign="center">
                            --
                        </Td>
                    </Tr>
                )}
            </Tbody>
        </Table>
    )
}
