import { Box, Image, Heading, Text, Button } from '@chakra-ui/react'
import Colors from '../../config/colors'
import PropsButton from '../../utilities/props_button'
import ErrorConnectionImage from '../../assets/error-connection.png'

export default function ErrorConnection() {
    return (
        <Box display="flex" alignItems="center">
            <Image src={ErrorConnectionImage} alt="Error Conexión" />
            <Box ml={10}>
                <Heading color={Colors.ERROR}>Error de conectividad</Heading>
                <Text mb={4} color={Colors.DARK}>
                    Problemas al conectar con el servidor
                </Text>
                <Button
                    {...PropsButton.ModalYes}
                    onClick={() => location.reload()}
                >
                    Vuelva a Intentar
                </Button>
            </Box>
        </Box>
    )
}
