import {
    FormControl,
    FormLabel,
    Input,
    Grid,
    Text,
    Select,
    InputProps,
    SelectProps,
    Icon
} from '@chakra-ui/react'
import { ChangeEvent } from 'react'
import { render, useRender } from 'redity'
import ErrorHelper from '../../components/ErrorHelper'
import Reload from '../../components/Icon/Reload'
import Colors from '../../config/colors'
import useStorage from '../../hooks/useStorage'
import { get } from '../../lib/http'
import { Keys } from './constants'
import { formUser } from './storage'

export default function FormUser() {
    return (
        <>
            <Text fontSize="20px" marginBottom="3">
                Información personal
            </Text>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                <FirstName />
                <Lastname />
            </Grid>
            <Text fontSize="20px" marginBottom="3" marginTop="4">
                Información de cuenta
            </Text>
            <RoleField />
            <UsernamePassword />
            <Pin />
        </>
    )
}

function FirstName() {
    const { props, helper } = formUser.useField<InputProps>('firstname')
    return (
        <FormControl>
            <FormLabel>Nombres</FormLabel>
            <Input
                {...props}
                placeholder="Ingresar nombre"
                autoFocus
                maxLength={100}
            />
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}

function Lastname() {
    const { props, helper } = formUser.useField<InputProps>('lastname')
    return (
        <FormControl>
            <FormLabel>Apellidos</FormLabel>
            <Input
                {...props}
                placeholder="Ingresar apellidos"
                maxLength={100}
            />
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}

function RoleField() {
    const { roles } = useStorage()
    const { props, helper } = formUser.useField<SelectProps>('role_id')
    function changeHandler(ev: ChangeEvent<HTMLSelectElement>) {
        props.onChange(ev)
        render(formUser.KeyMain, 'pin')
        render(Keys.USERNAME_PASSWORD)
    }

    return (
        <FormControl marginBottom="4">
            <FormLabel>Perfil</FormLabel>
            <Select
                {...props}
                onChange={changeHandler}
                placeholder="Seleccionar tipo de perfil de usuario"
            >
                {roles.map(d => (
                    <option key={d.id} value={d.id}>
                        {d.name}
                    </option>
                ))}
            </Select>
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}

function UsernamePassword() {
    useRender(Keys.USERNAME_PASSWORD)
    //const { role_id } = formUser.store()
    //if (role_id === '3' || role_id === '') return null
    return (
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <Username />
            <Password />
        </Grid>
    )
}

function Username() {
    const { props, helper, error } = formUser.useField<InputProps>('username')
    function changeHandler(ev: ChangeEvent<HTMLInputElement>) {
        let value = ev.target.value.replace(' ', '') as string
        while (value.search(' ') > -1) {
            value = value.replace(' ', '')
        }
        props.onChange({
            ...ev,
            target: {
                ...ev.target,
                value
            }
        })
    }

    return (
        <FormControl>
            <FormLabel>Usuario</FormLabel>
            <Input
                {...props}
                onChange={changeHandler}
                placeholder="Ingresar usuario"
                maxLength={15}
                marginBottom="3"
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function Password() {
    const { props, helper } = formUser.useField<InputProps>('password')
    return (
        <FormControl>
            <FormLabel>Contraseña</FormLabel>
            <Input
                {...props}
                type="password"
                placeholder="Ingresar contraseña"
                marginBottom="3"
            />
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}

function Pin() {
    const { props, helper } = formUser.useField<InputProps>('pin')
    const { role_id } = formUser.store()

    const { business_variables } = useStorage()
    const enable_admin_pin = business_variables.find(
        v => v.code === '013'
    )?.value

    if (enable_admin_pin == '1') {
        if (role_id == '2') return null
    } else {
        if (role_id != '3') return null
    }

    async function clickHandler() {
        const { data, error } = await get<{ pin: string }>('/api/utils/pin')
        if (error) return
        formUser.setValue('pin', data.result.pin)
    }

    return (
        <Grid templateColumns="repeat(2,1fr)" gap={6}>
            <FormControl>
                <FormLabel>PIN</FormLabel>
                <Input
                    {...props}
                    placeholder="Genera nuevo PIN"
                    readOnly
                    opacity="0.6"
                    border="none"
                />
                <ErrorHelper helper={helper} />
            </FormControl>
            <FormControl>
                <FormLabel>Generar PIN</FormLabel>
                <Icon
                    as={Reload}
                    fill={Colors.INFO}
                    fontSize="28px"
                    cursor="pointer"
                    onClick={clickHandler}
                />
            </FormControl>
        </Grid>
    )
}
