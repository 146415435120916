import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import { TIMEZONE } from '../config/constants'
dayjs.extend(utc)
dayjs.extend(tz)

export function now() {
    dayjs.extend(utc)
    const local = dayjs().format()
    const utcFormat = dayjs.utc().format()

    return {
        local,
        utc: utcFormat
    }
}

export function parseToDateClient(
    date: string | dayjs.Dayjs | Date,
    format = 'DD/MM/YYYY hh:mm:ss A'
) {
    return dayjs(date).tz(TIMEZONE).format(format)
}

export function getMaxDate(array: Array<string>) {
    const max = array.reduce((value1, value2) =>
        new Date(value1) > new Date(value2) ? value1 : value2
    )
    return max
}

export function DateWithTimeCurrent(value: string) {
    const date = dayjs(value)

    const new_date = dayjs()
        .set('month', date.get('month'))
        .set('year', date.get('year'))
        .set('date', date.get('date'))
        .format()

    return new_date
}
