import {
    Badge,
    Box,
    Checkbox,
    FormLabel,
    Icon,
    Input,
    Table,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import Close from '../../../../components/Icon/Close'
import ItemSearch from './ItemSearch'
import useModalEmitMultiple from './useModalEmitMultiple'

export default function Content() {
    const {
        client,
        setClient,
        saleNotes,
        searchHandler,
        search,
        isOpen,
        index,
        clients,
        keyHandler,
        clearHandler,
        ref,
        handleOnChange,
        selectedSaleNoteIds,
        total,
        submitHandler
    } = useModalEmitMultiple()

    return (
        <>
            <form id="form-emit" onSubmit={submitHandler}>
                <Box position="relative" w="full">
                    <FormLabel>
                        Busque y seleccione un cliente para ver sus notas de
                        venta
                    </FormLabel>
                    <Input
                        placeholder="Buscar cliente"
                        mr={2}
                        ref={ref}
                        bg="white"
                        onChange={searchHandler}
                        onKeyUp={ev => keyHandler(ev)}
                        value={
                            client
                                ? client.type_identification_document_id === 2
                                    ? client.business_name
                                    : `${client.firstname} ${client.lastname}`
                                : search
                        }
                    />
                    {(client || search) && (
                        <Icon
                            as={Close}
                            fill="gray-3"
                            position="absolute"
                            top="calc(50% + 9px)"
                            right="4"
                            fontSize="sm"
                            cursor="pointer"
                            zIndex="1"
                            onClick={clearHandler}
                        />
                    )}
                    <Box
                        position="absolute"
                        top={16}
                        zIndex={10}
                        py={2}
                        bg="white"
                        borderRadius="md"
                        boxShadow="md"
                        w="full"
                        minW="72"
                        borderWidth="1px"
                        d={isOpen && clients.length > 0 ? 'block' : 'none'}
                    >
                        {clients.map((_client, i) => (
                            <ItemSearch
                                key={i}
                                client={_client}
                                isActive={i === index}
                                onSelect={setClient}
                            />
                        ))}
                    </Box>
                </Box>

                <FormLabel mt={6}>
                    Seleccione una o más notas de venta
                </FormLabel>
                <Table>
                    <Thead>
                        <Tr>
                            <Th></Th>
                            <Th>Nota de venta</Th>
                            <Th>Fecha de emisión</Th>
                            <Th isNumeric>Monto</Th>
                            <Th>Estado</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {saleNotes.map((saleNote, i) => (
                            <Tr key={i}>
                                <Td p={2}>
                                    <Checkbox
                                        onChange={ev =>
                                            handleOnChange(ev, saleNote)
                                        }
                                        isChecked={selectedSaleNoteIds.includes(
                                            saleNote.id.toString()
                                        )}
                                        value={saleNote.id}
                                        isDisabled={
                                            saleNote.payment_status ==
                                            'pendiente'
                                        }
                                    />
                                </Td>
                                <Td py={2}>{saleNote.code}</Td>
                                <Td py={2}>
                                    {dayjs(saleNote.created_at).format(
                                        'DD/MM/YYYY hh:mm A'
                                    )}
                                </Td>
                                <Td py={2} whiteSpace="nowrap" isNumeric>
                                    S/ {saleNote.total_final.toFixed(2)}
                                </Td>
                                <Td py={2}>
                                    {saleNote.payment_status == 'pendiente' ? (
                                        <Badge
                                            colorScheme="red"
                                            variant="solid"
                                        >
                                            Pendiente
                                        </Badge>
                                    ) : (
                                        <Badge
                                            colorScheme="green"
                                            variant="solid"
                                        >
                                            Pagado
                                        </Badge>
                                    )}
                                </Td>
                            </Tr>
                        ))}

                        {saleNotes.length < 1 && (
                            <Tr>
                                <Td colSpan={5}>
                                    <Text align="center">
                                        Sin notas de venta
                                    </Text>
                                </Td>
                            </Tr>
                        )}
                    </Tbody>
                    {selectedSaleNoteIds.length > 0 && (
                        <Tfoot>
                            <Tr>
                                <Th colSpan={3} isNumeric>
                                    Total
                                </Th>
                                <Th whiteSpace="nowrap" fontSize="md" isNumeric>
                                    S/ {total.toFixed(2)}
                                </Th>
                                <Th></Th>
                            </Tr>
                        </Tfoot>
                    )}
                </Table>
            </form>
        </>
    )
}
