import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button
} from '@chakra-ui/react'
import { ModalConfirmProps } from './types'
import { useRef } from 'react'

export default function ModalConfirm({
    isOpen,
    onClose,
    title,
    children,
    onYes
}: ModalConfirmProps) {
    const cancelRef = useRef(null)

    function yesHandler() {
        onYes()
        onClose()
    }

    return (
        <AlertDialog
            motionPreset="slideInBottom"
            onClose={onClose}
            isOpen={isOpen}
            isCentered
            leastDestructiveRef={cancelRef}
        >
            <AlertDialogOverlay />
            <AlertDialogContent>
                <AlertDialogHeader>{title}</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>{children}</AlertDialogBody>
                <AlertDialogFooter>
                    <Button
                        color="white"
                        bg="orange"
                        mr={3}
                        onClick={yesHandler}
                        autoFocus
                        _hover={{}}
                        _focus={{}}
                    >
                        Si, Reimprimir
                    </Button>
                    <Button
                        borderWidth="1px"
                        borderColor="#434343"
                        color="#434343"
                        ref={cancelRef}
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
