import createKeys from '../../utilities/createKeys'

export const Keys = createKeys('TESTS', [
    'DETAILS',
    'TYPE_CONSUMPTION',
    'TYPE_ORDER',
    'TABLE_PAYMENTS',
    'RESULT_PAY',
    'PAYMENTS_METHODS'
])

export const TypeIdentificationDocument: Record<number, string> = {
    1: 'DNI',
    2: 'RUC',
    3: 'Carnet de extranjería',
    4: 'Pasaporte',
    5: 'Partida de nacimiento',
    6: 'Otros'
}

export const TYPES_PAYMENTS = Object.freeze({
    CASH: 'Efectivo',
    TRANSFER: 'Transferencia',
    CARD: 'Tarjeta'
})

export const TYPES_DISCOUNT = Object.freeze({
    PERCENTAGE: 'percentage',
    AMOUNT: 'amount'
})

export const TYPES_ORDER_PAYMENT = Object.freeze({
    FREE: 'free',
    TICKET: 'ticket',
    INVOICE: 'invoice'
})
