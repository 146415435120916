import { FormControl, FormLabel, Textarea } from '@chakra-ui/react'
import useDelivery from '../../../../../hooks/useDelivery'
import { DeliveryStatus } from '../../../../../types/deliveryMode'
import { useDeliveryForm } from '../../../store/delivery-form.store'

export default function ObservationField() {
    const observation = useDeliveryForm(state => state.observation)
    const set = useDeliveryForm(state => state.set)
    const { delivery } = useDelivery()

    return (
        <FormControl>
            <FormLabel
                color="gray.700"
                fontWeight="normal"
                fontSize="sm"
                mb={0}
            >
                Observación (Opcional)
            </FormLabel>
            <Textarea
                onChange={ev => set({ observation: ev.target.value })}
                bg="white"
                isDisabled={
                    delivery?.status === DeliveryStatus.CLOSED ||
                    delivery?.status === DeliveryStatus.CANCELED
                }
                value={observation}
                placeholder="Escribir..."
            ></Textarea>
        </FormControl>
    )
}
