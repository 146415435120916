import { ChangeEvent, useState } from 'react'
import { API_BASE_URL } from '../../config/credentials'
import { post } from '../../lib/http'

export default function useFileField(
    onChange: (ev: ChangeEvent<HTMLInputElement>) => void
) {
    const [loading, setLoading] = useState(false)
    async function uploadHandler(ev: ChangeEvent<HTMLInputElement>) {
        const files = ev.target.files as FileList
        if (files.length === 0) return
        const fd = new FormData()
        fd.append('image', files[0])
        setLoading(true)
        const { data, error } = await post<string>('/api/utils/images', fd)
        setLoading(false)
        if (error) return

        const value = `${API_BASE_URL}${data.result}`

        onChange({
            ...ev,
            target: {
                ...ev.target,
                value
            }
        })
    }

    return {
        uploadHandler,
        loading
    }
}
