import { ChangeEvent, useEffect, useRef, useState } from 'react'
import CategoryItem from '../../../models/category_item'
import Item from '../../../models/item'
import { ItemForBilling } from '../../../types/runway_state'
import onlyNumber from '../../../utilities/onlyNumber'
import useAddItems from '../hooks/useAddItems'

export default function useModalProduct(itemToEdit?: ItemForBilling) {
    type ItemData = Item & { category_item?: CategoryItem }
    const [isLoading, setLoading] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const [item, setItem] = useState<ItemData | null>(null)
    const [quantity, setQuantity] = useState('')
    const [price, setPrice] = useState('')
    const { addItem } = useAddItems(item as ItemData)

    function submitHandler() {
        setLoading(true)
        if (!item || !quantity) return
        addItem(Number(quantity), Number(price))
        setItem(null)
        setLoading(false)
        if (itemToEdit) {
            setOpen(false)
        }
    }

    const ref = useRef<HTMLInputElement>(null)

    function changeHandler(valueAsString: string) {
        setQuantity(
            onlyNumber(valueAsString, {
                decimal: 2,
                max: 999
            })
        )
    }

    function changePriceHandler(ev: ChangeEvent<HTMLInputElement>) {
        setPrice(ev.target.value)
    }

    useEffect(() => {
        if (itemToEdit) {
            setItem(itemToEdit.item)
            setQuantity(itemToEdit.quantity.toString())
            setPrice(itemToEdit.item.price.toString())
        } else if (item) {
            setQuantity('1')
            setPrice(item.price.toString())
            if (ref.current) {
                ref.current.focus()
            }
        } else {
            setQuantity('')
            setPrice('')
        }
    }, [item?.id])
    return {
        submitHandler,
        changeHandler,
        changePriceHandler,
        itemToEdit,
        isOpen,
        isLoading,
        setOpen,
        price,
        quantity,
        ref,
        item,
        setItem
    }
}
