/**
 * @deprecated use localStorage('ips_config')
 */
export default function getIP(type: 'pre_check' | 'report' = 'pre_check') {
    if (type === 'pre_check') {
        return localStorage.getItem('ip_preCheck')
    } else {
        return localStorage.getItem('ip_report')
    }
}
