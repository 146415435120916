import {
    Box,
    Button,
    ButtonGroup,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useToast
} from '@chakra-ui/react'
import TabZones from './TabZones'
import { useGet } from '../../../../hooks/useHttp'
import Zone from '../../../../models/zone'
import { useEffect, useState } from 'react'
import GridTables from './GridTables'
import { ModalTablesProps } from './types'
import { put } from '../../../../lib/http'
import { useHistory } from 'react-router-dom'
import useOrderByTable from '../../hooks/useOrderByTable'
import useOverlapLoader from '../../../../hooks/useOverlapLoader'

export default function ModalTables({ isOpen, onClose }: ModalTablesProps) {
    const { orderId } = useOrderByTable()
    const { start, end } = useOverlapLoader()
    const { push } = useHistory()
    const toast = useToast()
    const [zoneId, setZoneId] = useState(0)
    const [tableId, setTableId] = useState(0)
    const { data } = useGet<Zone[]>(
        '2>/api/zones/?sort=name&is_enabled=true',
        []
    )

    useEffect(() => {
        setTableId(0)
    }, [isOpen])

    useEffect(() => {
        if (data.result.length === 0) return
        setZoneId(data.result[0].id)
    }, [data.result.length])

    async function handleConfirm() {
        if (!tableId) return
        onClose()

        start('Cambiando la mesa')

        const { error } = await put(`/api/orders/${orderId}/table`, {
            table_id: tableId
        })

        end()

        if (error) {
            toast({
                title: error.message,
                position: 'top',
                status: 'error',
                isClosable: true
            })
        } else {
            push('/by_tables')
            toast({
                title: 'Cambio de mesa realizado',
                position: 'top',
                isClosable: true,
                status: 'success'
            })
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Cambio de mesa</ModalHeader>
                <ModalBody>
                    <Box mb="5">
                        <TabZones
                            zones={data.result}
                            zoneId={zoneId}
                            onClick={setZoneId}
                        />
                        <Box
                            maxH="55vh"
                            overflowX={'hidden'}
                            overflowY={'auto'}
                            w="full"
                        >
                            {zoneId && (
                                <GridTables
                                    zoneId={zoneId}
                                    tableId={tableId}
                                    setTableId={setTableId}
                                />
                            )}
                        </Box>
                        <ButtonGroup w="full" mt="5" justifyContent="flex-end">
                            <Button
                                h="60px"
                                w="180px"
                                border="1px solid #434343"
                                color="#434343"
                                bg="white"
                                _hover={{}}
                                onClick={onClose}
                            >
                                Cancelar
                            </Button>
                            <Button
                                h="60px"
                                w="180px"
                                color="white"
                                bg="#44C281"
                                isDisabled={!tableId}
                                _hover={{}}
                                onClick={handleConfirm}
                            >
                                Confirmar
                            </Button>
                        </ButtonGroup>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
