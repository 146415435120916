import { useParams } from 'react-router-dom'
import useQuery from '../../../hooks/useQuery'

export interface ParamsQuickSale {
    /**
     * Indica el modo en que se encuentra la página.
     */
    by: 'tables' | 'pre_orders'
}

export interface QueryParamsQuickSale {
    pre_order_id: string
    table_id: string
}

export interface ParamsQuickSaleBy extends ParamsQuickSale {
    preOrderId: string
    tableId: string
}

/**
 * Determina si es una venta por mesa o por preorder. Tambien para venta rápida.
 */
export default function useParamsQuickSaleBy(): ParamsQuickSaleBy {
    const { by } = useParams<ParamsQuickSale>()
    const { pre_order_id, table_id } = useQuery<QueryParamsQuickSale>()

    const preOrderId = pre_order_id || ''
    const tableId = table_id || ''

    return {
        preOrderId,
        tableId,
        by
    }
}
