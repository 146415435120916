import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import { storagePage } from '../../storage'
import { DataCategory } from '../../types'
import Card from '../../Components/Card'

export default function CategoryReport() {
    const { cashboxMovements } = storagePage

    const data: DataCategory[] = []
    cashboxMovements.map(i => data.push(...i.dataCategories))

    const newData = Object.values(
        data.reduce((agg: any, category) => {
            if (!agg[category.name])
                agg[category.name] = {
                    name: category.name,
                    quantity: 0,
                    sale: 0
                }
            agg[category.name].quantity += category.quantity
            agg[category.name].sale += category.sale
            return agg
        }, {})
    )

    const totalSale = data.map(i => i.sale).reduce((a, b) => a + b, 0)

    return (
        <Card direction="column" gridGap={4}>
            <Text as="b" mr="2" fontSize="xl" align="center" w="full">
                Categorías
            </Text>
            <TableContainer>
                <Table size="sm">
                    <Thead>
                        <Tr>
                            <Th>Categoría</Th>
                            <Th>Cant.</Th>
                            <Th isNumeric>Total</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {newData.map((category: any, i) => (
                            <Tr key={i}>
                                <Td>{category.name}</Td>
                                <Td>{category.quantity}</Td>
                                <Td isNumeric>{category.sale.toFixed(2)}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                    <Tfoot>
                        <Tr>
                            <Th colSpan={2}>Total recaudado</Th>
                            <Th isNumeric>S/{totalSale.toFixed(2)}</Th>
                        </Tr>
                    </Tfoot>
                </Table>
            </TableContainer>
        </Card>
    )
}
