import { Button, Flex } from '@chakra-ui/react'
import colors from '../../config/theme/colors'
import ChevronDown from '../Icon/ChevronDown'
import ChevronUp from '../Icon/ChevronUp'

export default function ViewDetailButton({
    showDetail,
    onClick,
    description
}: {
    showDetail: boolean
    onClick: () => void
    description: string
}) {
    return (
        <Flex justifyContent="center">
            <Button
                size="sm"
                variant="link"
                onClick={onClick}
                color={colors.turquoise}
                rightIcon={
                    showDetail ? (
                        <ChevronUp
                            fill={colors.turquoise}
                            height="1.5em"
                            width="1.5em"
                        />
                    ) : (
                        <ChevronDown
                            fill={colors.turquoise}
                            height="1.5em"
                            width="1.5em"
                        />
                    )
                }
                _focus={{}}
            >
                {showDetail ? `Ocultar ${description}` : `Ver ${description}`}
            </Button>
        </Flex>
    )
}
