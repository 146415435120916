import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from '@chakra-ui/react'
import { useEffect } from 'react'
import useSubmitWarehouse from '../../hooks/useSubmitWarehouse'
import { WarehousesFormProps } from './types'

export default function WarehouseForm({
    element,
    edition,
    onDone
}: WarehousesFormProps) {
    const { onOpen, onClose, isOpen } = useDisclosure()
    const { submitHandler, fieldNameMessage, setFieldNameMessage, isLoading } =
        useSubmitWarehouse(doneHandler, edition?.id || undefined)

    function doneHandler() {
        onDone()
        onClose()
        setFieldNameMessage('')
    }

    useEffect(() => {
        setFieldNameMessage('')
    }, [isOpen])

    return (
        <>
            {element(onOpen)}
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size="lg"
                closeOnOverlayClick
            >
                <ModalOverlay />
                <ModalContent px={6} py={4}>
                    <ModalHeader>
                        {edition ? 'Editar almacén' : 'Nuevo almacén'}
                    </ModalHeader>
                    <form onSubmit={submitHandler} noValidate>
                        <ModalBody pb={6}>
                            <FormControl
                                isRequired
                                isInvalid={!!fieldNameMessage}
                            >
                                <FormLabel>Nombre</FormLabel>
                                <Input
                                    name="name"
                                    defaultValue={edition?.name || ''}
                                    placeholder="Ingrese el nombre del almacén"
                                    onChange={() => setFieldNameMessage('')}
                                />
                                <FormErrorMessage>
                                    {fieldNameMessage}
                                </FormErrorMessage>
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                variant="alo-gray:lg-md"
                                bg="transparent"
                                color="alo-gray"
                                onClick={onClose}
                                pointerEvents={isLoading ? 'none' : 'auto'}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="alo-green:lg-md"
                                ml={3}
                                type="submit"
                                isLoading={isLoading}
                            >
                                Confirmar
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}
