import { Flex, Text } from '@chakra-ui/react'
import colors from '../../../../config/theme/colors'
import { ItemStockProps } from './types'

export default function ItemStock({ label, value }: ItemStockProps) {
    return (
        <Flex justify="center" alignItems="center" flexDir="column">
            <Text fontSize="xs" fontWeight="bold" color={colors['gray-2']}>
                {label}
            </Text>
            <Text fontWeight="bold" fontSize="3xl" color={colors['alo-blue']}>
                {value}
            </Text>
        </Flex>
    )
}
