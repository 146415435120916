import { useState } from 'react'

export type PinValues = [
    number | null,
    number | null,
    number | null,
    number | null
]

export default function usePin() {
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9]
    const [pinValues, setPinValues] = useState<PinValues>([
        null,
        null,
        null,
        null
    ])

    function clear() {
        setPinValues([null, null, null, null])
    }

    function digit(number: number) {
        for (let i = 0; i < pinValues.length; i++) {
            const value = pinValues[i]
            const newPinValues: PinValues = [...pinValues]
            if (value === null) {
                newPinValues[i] = number
                setPinValues(newPinValues)
                break
            }
        }
    }

    function removeLastDigit() {
        for (let i = pinValues.length - 1; i >= 0; i--) {
            const value = pinValues[i]
            const newPinValues: PinValues = [...pinValues]
            if (value !== null) {
                newPinValues[i] = null
                setPinValues(newPinValues)
                break
            }
        }
    }

    return {
        numbers,
        clear,
        removeLastDigit,
        digit,
        pinValues
    }
}
