import { Input } from '@chakra-ui/react'
import Clean from '../../../components/Clean'
import { listBooking } from '../storage'

export default function FiltersBooking() {
    const { clear, ...props } = listBooking.useSearch()

    return (
        <Clean value={props.value} onClick={clear} width="100%">
            <Input
                placeholder="🏸 Buscar por nombre de cliente o grupo turístico"
                {...props}
                w="100%"
            />
        </Clean>
    )
}
