import { render } from 'redity'
import useSubmit from '../../../hooks/useSubmit'
import { get } from '../../../lib/http'
import modal from '../../../lib/modal'
import Account from '../../../models/account'
import FormAccount from '../Modals/FormAccount'
import Keys from '../keys'
import { formAccount, storagePage } from '../storage'
import useInitial from '../useInitial'
import { OrderAccount } from '../types'
export default function useAccount(account: Account) {
    const { order_id } = useInitial()
    async function onDone() {
        const { data } = await get<OrderAccount>(
            `/api/account_orders/${order_id}/order`
        )
        storagePage.account_orders = data.result.account_orders
        render(Keys.ORDER_ACCOUNTS)
    }

    const { validation, observer, submit } = useSubmit(formAccount, {
        path: `/api/account_orders/${account.id}`,
        method: 'put',
        done: onDone
    })

    validation(v => {
        v.name.isEmpty('Es requerido el nombre')
    })

    observer<FormSubmit>(f => ({
        name: f.name
    }))

    function openModalAccount() {
        formAccount.store({
            name: account.name
        })
        formAccount.init(formAccount.store)
        modal(FormAccount, {
            title: 'Editar nombre de subcuenta',
            onSubmit: submit
        })
    }
    return openModalAccount
}

interface FormSubmit {
    name: string
}
