import { Box, Heading, SimpleGrid, Text } from '@chakra-ui/react'
import { useGet } from '../../../../hooks/useHttp'
import { TableData } from '../../../ByTables/types'
import { GridTablesProps } from './types'

export default function GridTables({
    zoneId,
    tableId,
    setTableId
}: GridTablesProps) {
    const { data } = useGet<TableData[]>(
        `/api/tables/available/all?zone_id=${zoneId}`,
        []
    )

    return (
        <>
            {data.result.length === 0 && (
                <Text py="5" fontSize="lg">
                    No hay mesas disponibles
                </Text>
            )}
            {data.result.length !== 0 && (
                <SimpleGrid spacing={3} columns={[2, 4]} py={5} w="full">
                    {data.result.map(table => (
                        <Box
                            key={table.id}
                            cursor="pointer"
                            borderRadius="lg"
                            onClick={() => setTableId(table.id)}
                            h="120px"
                            bg={table.id !== tableId ? 'gray-3' : 'primary'}
                            p="4"
                            color="white"
                            textAlign="center"
                            display="flex"
                            alignItems={'center'}
                            justifyContent="center"
                        >
                            <Heading size="md" textAlign="center">
                                {table.name}
                            </Heading>
                        </Box>
                    ))}
                </SimpleGrid>
            )}
        </>
    )
}
