import { TableData, TableDataJoinTable } from '../pages/ByTables/types'

export default function useJoinTables(
    tables: TableData[]
): TableDataJoinTable[] {
    const tablesMainOfGroup: TableDataJoinTable[] = []

    for (const table of tables) {
        if (table.join_tables.length === 0) {
            tablesMainOfGroup.push(table)
            continue
        }

        const exist = table.join_tables.find(
            jt => jt.from_table_id === table.id
        )

        if (exist) {
            tablesMainOfGroup.push(table)
        }
    }

    return tablesMainOfGroup as TableDataJoinTable[]
}
