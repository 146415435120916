import { Icon } from '@chakra-ui/react'
import Trash from '../../../components/Icon/Trash'
import colors from '../../../config/theme/colors'
import Delete from '../../../lib/delete'
import Agency from '../../../models/agency'
import { listAgency, PATH_ENDPOINT_AGENCY } from '../storage'

export default function DeleteAgency({ agency }: { agency: Agency }) {
    return (
        <Delete
            title="Eliminar Agencia"
            path={`${PATH_ENDPOINT_AGENCY}/${agency.id}`}
            element={click => (
                <Icon
                    onClick={click}
                    fill={colors.error}
                    as={Trash}
                    cursor="pointer"
                />
            )}
            done={() => {
                listAgency.setQuery('page', 1)
                listAgency.load()
            }}
        >
            ¿Esta seguro de eliminar la agencia <b>{agency.name}</b>?
        </Delete>
    )
}
