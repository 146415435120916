import {
    Box,
    Button,
    Flex,
    FormControl,
    Progress,
    Select,
    Text,
    useBreakpointValue
} from '@chakra-ui/react'
import { MouseEvent, useEffect, useMemo, useState } from 'react'
import useInfoDialog from '../../../../hooks/useInfoDialog'
import { DeliveryStatus } from '../../../../types/deliveryMode'
import useUpdateStatus from '../../hooks/updateStatus'
import { getColorStatus, getNextNameStatus, getStatusNames } from '../../utils'
import { StatusContentProps } from './types'

export default function StatusContent({
    delivery,
    timer,
    onLoad,
    profiles
}: StatusContentProps) {
    const tableSize = useBreakpointValue({ base: 'sm', lg: 'md' })
    const { isLoading, update } = useUpdateStatus(delivery.id, onLoad)
    const [profileId, setProfileId] = useState<string>(() =>
        delivery.profile_id ? delivery.profile_id.toString() : ''
    )
    const [hasError, setHasError] = useState(false)
    const dialog = useInfoDialog()

    useEffect(() => {
        setProfileId('')
        setHasError(false)
    }, [delivery.status])

    function clickButtonHandler(
        ev: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
    ) {
        ev.stopPropagation()
        if (delivery.status === DeliveryStatus.DELIVERED && !delivery.is_paid) {
            dialog.show(
                'Cerrar delivery',
                'No es posible cerrar el delivery si aún no se ha realizado el pago.'
            )
            return
        }

        if (delivery.status === DeliveryStatus.IN_PREPARATION && !profileId) {
            setHasError(true)
            return
        }
        setHasError(false)
        update(delivery.status + 1, profileId ? Number(profileId) : undefined)
    }

    const colorStatus = useMemo(
        () => getColorStatus(delivery.status),
        [delivery.status]
    )

    return (
        <Flex gap={4} justify="center">
            <Box textAlign="left">
                <Text fontSize="sm" fontWeight="bold" color={colorStatus}>
                    {getStatusNames(delivery.status)}
                </Text>
                {delivery.status !== DeliveryStatus.CANCELED && (
                    <Progress
                        value={delivery.status}
                        size="xs"
                        colorScheme={colorStatus}
                        w="32"
                        max={5}
                    />
                )}
                <Text fontSize="xs" color="gray.500">
                    {timer}
                </Text>
            </Box>
            {!(
                delivery.status === DeliveryStatus.CANCELED ||
                delivery.status === DeliveryStatus.CLOSED
            ) && (
                <Flex
                    w={{
                        base: 'auto',
                        md: '72'
                    }}
                    gap={1}
                >
                    {delivery.status === DeliveryStatus.IN_PREPARATION && (
                        <FormControl isInvalid={hasError}>
                            <Select
                                bg="white"
                                fontSize="sm"
                                onClick={ev => ev.stopPropagation()}
                                value={profileId}
                                placeholder="Seleccionar"
                                onChange={ev => setProfileId(ev.target.value)}
                            >
                                {profiles.map(profile => (
                                    <option value={profile.id} key={profile.id}>
                                        {profile.firstname} {profile.lastname}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    <Button
                        fontWeight="300"
                        colorScheme="blue"
                        w="full"
                        fontSize="xs"
                        size={tableSize}
                        onClick={clickButtonHandler}
                        isLoading={isLoading}
                    >
                        {getNextNameStatus(delivery.status)}
                    </Button>
                </Flex>
            )}
        </Flex>
    )
}
