import { useToast } from '@chakra-ui/react'
import useSubmit from '../../../hooks/useSubmit'
import modal from '../../../lib/modal'
import Table from '../../../models/table'
import FormTable from './FormTable'
import { listTable, formTable } from '../storage'

export default function useFormTable(table?: Table) {
    const toast = useToast()
    const { submit, validation } = useSubmit(formTable, {
        path: table ? `/api/tables/${table.id}` : '/api/tables',
        method: table ? 'put' : 'post',
        done: async () => {
            await listTable.load()
            toast({
                title: table
                    ? 'Se ha actualizado la mesa'
                    : 'Se ha guardado la nueva mesa',
                status: 'success',
                position: 'top'
            })
        },
        error: e => {
            if (e.type === 'development') {
                toast({
                    title: e.message,
                    position: 'top',
                    status: 'error'
                })
            }
        }
    })

    validation(v => {
        v.name.isEmpty('El nombre es requerido')
        v.zone_id.isEmpty('Se requiere seleccionar la zona')
    })

    function openModal() {
        if (table) {
            formTable.store({
                name: table.name,
                zone_id: table.zone_id.toString()
            })
            formTable.init(formTable.store)
        } else {
            formTable.init()
        }
        modal(FormTable, {
            title: table ? 'Editar mesa' : 'Nueva mesa',
            onSubmit: submit
        })
    }

    return openModal
}
