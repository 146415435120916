import { Flex, Icon } from '@chakra-ui/react'
import Bill from '../../components/Icon/Bill'
//import Description from '../../components/Icon/Description'
import History from '../../components/Icon/History'
import colors from '../../config/theme/colors'
//import ModalEmit from '../Sales/ModalContents/ModalEmit'
//import ModalPayment from './ModalContents/ModalPayment'
import useModalPayment from './ModalContents/ModalPayment/useModalPayment'
import ModalPaymentHistory from './ModalContents/ModalPaymentHistory'
//import { controlList } from './storage'
import { CreditOrderData } from './types'

export default function TableActions({
    creditOrder
}: //generate_electronic_receipts
{
    creditOrder: CreditOrderData
    //generate_electronic_receipts: any
}) {
    //const { sunat_vouchers, client } = orderPayment
    /**
     * No deberia permitir emitir comprobante de una venta anulada que no tiene comprobante
     * order_state 5 === vigente
     */

    //const toEmit = creditOrder.proof_payment === 4 && creditOrder.status == 'pagado'

    const isPending = creditOrder.status == 'pendiente'

    const openModal = useModalPayment(creditOrder.id)
    return (
        <Flex justifyContent="center">
            {/*
            <ModalEmit
                element={click => (
                    <Icon
                        as={Description}
                        display="block"
                        cursor="pointer"
                        fill="dark"
                        onClick={click}
                        mr="2"
                        pointerEvents={toEmit ? 'auto' : 'none'}
                        opacity={toEmit ? '1' : '.6'}
                        alignSelf="center"
                    />
                )}
                client={creditOrder.client}
                amount={creditOrder.credit_debt}
                onDone={() => controlList.load()}
                orderPaymentId={creditOrder.order_payment_id}
                isCredit
            />
            */}

            <Icon
                fontSize="1.5em"
                as={Bill}
                display="block"
                cursor="pointer"
                fill={colors['alo-blue']}
                onClick={openModal}
                mr="2"
                pointerEvents={isPending ? 'auto' : 'none'}
                opacity={isPending ? '1' : '.6'}
            />
            <ModalPaymentHistory
                total={creditOrder.credit_debt}
                pending={creditOrder.balance_due}
                creditOrderId={creditOrder.id}
                element={click => (
                    <Icon
                        fontSize="1.5em"
                        as={History}
                        display="block"
                        cursor="pointer"
                        fill="dark"
                        onClick={click}
                    />
                )}
            />
        </Flex>
    )
}
