import { Button, Flex, IconButton } from '@chakra-ui/react'
import Search from '../../../../components/Icon/Search'
import colors from '../../../../config/theme/colors'
import FieldQuantity from './FieldQuantity'
import FieldSearch from './FieldSearch'
import { FormEvent, useState } from 'react'
import { ItemData } from '../../../../context/MenuContext'
import useMenu from '../../../../hooks/useMenu'

export default function Searcher() {
    const { addItem: setMenu } = useMenu()
    const [item, setItem] = useState<ItemData | null>(null)
    const [quantity, setQuantity] = useState('0')

    function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        if (!item) return
        setMenu(item, parseInt(quantity))
        setItem(null)
        setQuantity('')
    }

    return (
        <Flex
            p="2"
            bg="base-3"
            borderBottomWidth={{
                base: 0,
                md: '1px'
            }}
            borderRightWidth={{
                base: 0,
                md: '1px'
            }}
            borderColor="blackAlpha.50"
            position="relative"
            w="full"
            gap={2}
        >
            <IconButton
                aria-label="Search"
                icon={<Search fill={colors.dark} />}
                borderRightRadius={0}
                display={{
                    base: 'none',
                    lg: 'flex'
                }}
                pointerEvents="none"
            />
            <FieldSearch item={item} onSelect={setItem} />
            <form onSubmit={submitHandler}>
                <Flex gap={2}>
                    <FieldQuantity
                        item={item}
                        quantity={quantity}
                        setQuantity={setQuantity}
                    />
                    <Button
                        fontSize="xl"
                        colorScheme="blue"
                        isDisabled={
                            !item || quantity === '' || quantity === '0'
                        }
                        type="submit"
                    >
                        +
                    </Button>
                </Flex>
            </form>
        </Flex>
    )
}
