import { Text, VStack } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import useOrderByTable from '../../../hooks/useOrderByTable'
import useSession from '../../../../../hooks/useSession'
import ModalCommandHistory from '../../../modals/ModalCommandHistory'
import { useState } from 'react'
import useStorage from '../../../../../hooks/useStorage'

export default function OtherOptions() {
    const { business_variables } = useStorage()
    const [isOpen, setIsOpen] = useState(false)
    const { orderId } = useOrderByTable()
    const { push } = useHistory()
    const { isWaiter, isLogged, isDealer } = useSession()

    const isWaiter_ = isWaiter() || !isLogged
    const command_history_enable = business_variables.find(
        v => v.code === '008'
    )?.value

    return (
        <VStack w="full" fontSize="sm" justify="flex-start" align="flex-start">
            {orderId && command_history_enable === '1' && (
                <ModalCommandHistory
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                />
            )}

            {orderId && !(isWaiter_ || isDealer()) && (
                <Text
                    color="dark-2"
                    textDecoration={'underline'}
                    cursor={'pointer'}
                    as={'b'}
                    onClick={() => push(`/orders/${orderId}/move_products`)}
                >
                    Mover productos
                </Text>
            )}

            {orderId && !isWaiter_ && command_history_enable === '1' && (
                <Text
                    color="dark-2"
                    textDecoration="underline"
                    cursor="pointer"
                    as={'b'}
                    onClick={() => setIsOpen(true)}
                >
                    Historial de comandas
                </Text>
            )}
        </VStack>
    )
}
