import {
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    Textarea,
    VStack
} from '@chakra-ui/react'
import ErrorHelper from '../../../../components/ErrorHelper'
import colors from '../../../../config/theme/colors'
import { FormPayProps } from './types'

export default function FormPay({
    booking,
    helpers,
    setHelpers
}: FormPayProps) {
    const name = booking.agency?.name || booking.driver?.name || '-'
    const agencyGuideName = booking?.agency_guide?.name || '-'
    const isAgency = !!booking.agency
    const commission = booking.orders.reduce(
        (n, order) =>
            n +
            order.order_items
                .filter(orderItem => orderItem.item.has_commission)
                .reduce(
                    (_n, orderItem) =>
                        _n + orderItem.commission * orderItem.quantity,
                    0
                ),
        0
    )

    return (
        <VStack w="full">
            <FormControl>
                <FormLabel>{isAgency ? 'Agencia' : 'Guía local'}</FormLabel>
                <Input
                    name={isAgency ? 'agency_id' : 'driver_id'}
                    isReadOnly
                    value={name}
                    bg="blackAlpha.50"
                />
            </FormControl>
            {isAgency && (
                <FormControl>
                    <FormLabel>Guía turística</FormLabel>
                    <Input
                        name={isAgency ? 'agency_id' : 'driver_id'}
                        isReadOnly
                        value={agencyGuideName}
                        bg="blackAlpha.50"
                    />
                </FormControl>
            )}
            <FormControl>
                <FormLabel>Monto</FormLabel>
                <InputGroup>
                    <InputLeftElement
                        pointerEvents="none"
                        color={colors['alo-gray']}
                        fontSize="1.2em"
                    >
                        S/.
                    </InputLeftElement>
                    <Input
                        placeholder="0.00"
                        defaultValue={commission.toFixed(2)}
                        type="number"
                        name="comission"
                        autoFocus
                        onChange={() => setHelpers({})}
                    />
                </InputGroup>
                <ErrorHelper helper={helpers.comission} />
            </FormControl>
            <FormControl>
                <FormLabel>Comentario</FormLabel>
                <Textarea name="commentary" placeholder="Escribir"></Textarea>
            </FormControl>
        </VStack>
    )
}
