import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { useState } from 'react'
import Client from '../../models/client'
import BodyContent from './BodyContent'
import { ModalSelectClientProps } from './types'

export default function ModalSelectClient({
    element,
    onSelect
}: ModalSelectClientProps) {
    const [isOpen, setOpen] = useState(false)

    function selectHandler(client: Client) {
        onSelect(client)
        setOpen(false)
    }

    return (
        <>
            {element(() => setOpen(true))}
            <Modal
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                size="md"
                isCentered
                closeOnOverlayClick
            >
                <ModalOverlay />
                <ModalContent px={{ base: 2, md: 6 }} py={4}>
                    <form>
                        <ModalBody pb={6}>
                            <BodyContent
                                onSelect={selectHandler}
                                onClose={() => setOpen(false)}
                            />
                        </ModalBody>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}
