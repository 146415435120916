import Sidebar from '../../components/Sidebar'
import { Tr, Td, Box, Button } from '@chakra-ui/react'
import List from '../../lib/list'
import { controlList } from './storage'
import useInitial from './useInitial'
import SimpleNav from '../../components/SimpleNav'
import useForm from './useForm'
import Edition from './Edition'
import DeleteUser from './DeleteUser'
import useStorage from '../../hooks/useStorage'
import SwitchEnabled from './SwitchEnabled'

export default function Users() {
    const { openModal } = useForm()
    const { roles } = useStorage()

    const getRole = (role_id: number) => roles.find(role => role.id === role_id)

    useInitial()
    return (
        <Sidebar>
            <SimpleNav title="Gestión de Usuarios" />
            <Box marginBottom="5" display="flex" justifyContent="flex-end">
                <Button
                    variant="primary:md-xl"
                    onClick={openModal}
                    display={{ base: 'none', md: 'block' }}
                >
                    Nuevo usuario
                </Button>
                <Button
                    variant="primary:md-xl"
                    onClick={openModal}
                    display={{ base: 'block', md: 'none' }}
                >
                    + Nuevo
                </Button>
            </Box>
            <List
                control={controlList}
                tbody={(_user, e) => (
                    <Tr key={e}>
                        <Td>{e}</Td>
                        <Td>{_user.firstname}</Td>
                        <Td>{_user.lastname}</Td>
                        <Td>
                            {_user.roles_users
                                ? getRole(_user.roles_users[0].role_id)?.name
                                : '-'}
                        </Td>
                        <Td>
                            <SwitchEnabled user={_user} />
                        </Td>
                        <Td>
                            <Edition user={_user} />
                            <DeleteUser user={_user} />
                        </Td>
                    </Tr>
                )}
            />
        </Sidebar>
    )
}
