import { Box, Flex, FormControl, Image, Radio, Text } from '@chakra-ui/react'
import { Dispatch, RefObject, SetStateAction } from 'react'
import Colors from '../../config/colors'
import colors from '../../config/theme/colors'
import SubPaymentType from '../../models/sub_payment_type'
import ErrorHelper from '../ErrorHelper'
import { paymentLogos } from './PaymentLogos'

export default function PaymentOptions({
    refInput,
    onClick,
    type,
    isUnchecked,
    setUnchecked,
    subTypes
}: {
    refInput: RefObject<HTMLInputElement>
    onClick: (type: string) => void
    type: string
    isUnchecked: boolean
    setUnchecked: Dispatch<SetStateAction<boolean>>
    subTypes?: SubPaymentType[]
}) {
    const propsActive = {
        bg: Colors.INFO_LIGHT,
        border: '1px',
        borderColor: 'blue.500'
    }
    const propsDeactive = {
        bg: 'white',
        border: '1px',
        borderColor: 'gray.300'
    }

    function clickHandler(sub_type_id: string) {
        const elem = refInput.current as HTMLInputElement
        elem.focus()
        elem.selectionStart = elem.selectionEnd = elem.value.length
        //elem.value = ''
        onClick(sub_type_id)
        setUnchecked(false)
    }

    return subTypes && subTypes?.length > 0 ? (
        <FormControl mb={4}>
            <Box bg={colors['base-2']} borderRadius="lg" px={4}>
                <Text py={4}>¿Cómo quiere pagar?</Text>
                <Flex gridGap={3} pb={4} overflowX="auto">
                    {subTypes.map((subType, i) => (
                        <Flex
                            key={i}
                            {...(type === subType.id.toString()
                                ? propsActive
                                : propsDeactive)}
                            onClick={() => clickHandler(subType.id.toString())}
                            borderRadius="lg"
                            position="relative"
                            cursor="pointer"
                            h="90px"
                            w="90px"
                            minW="90px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Radio
                                isChecked={
                                    type === subType.id.toString()
                                        ? true
                                        : false
                                }
                                position="absolute"
                                cursor="pointer"
                                zIndex={1}
                                w="full"
                                alignSelf="flex-start"
                                p={1}
                            ></Radio>
                            <Image
                                src={paymentLogos[subType.path]}
                                alt={subType.name}
                                maxW="70%"
                                maxH="70%"
                                h="auto"
                                w="auto"
                            />
                        </Flex>
                    ))}
                </Flex>
            </Box>
            {isUnchecked && (
                <ErrorHelper
                    helper={'Es requerido seleccionar un tipo de pago'}
                />
            )}
        </FormControl>
    ) : (
        <></>
    )
}
