export default function getNumberWithTwoDecimals(number: string | number) {
    const [integer, decimals] = number.toString()?.split('.')

    if (decimals) {
        const decimsalST = decimals.substring(0, 2)
        return decimsalST.length === 1
            ? integer + '.' + decimsalST + '0'
            : integer + '.' + decimsalST
    } else {
        return integer + '.00'
    }
}
