import { ButtonProps } from '@chakra-ui/react'
import Colors from '../config/colors'

/**
 * @deprecated
 */
const PropsButton: Record<Keys, ButtonProps> = {
    Primary: {
        bg: Colors.PRIMARY,
        color: Colors.WHITE,
        w: '250px',
        h: '70px'
    },
    PrimaryOutline: {
        bg: Colors.WHITE,
        color: Colors.PRIMARY,
        border: `4px solid ${Colors.PRIMARY}`,
        w: '250px',
        h: '70px'
    },
    SecondarySm: {
        bg: Colors.SECONDARY,
        color: Colors.WHITE,
        paddingX: '12',
        h: '44px',
        fontSize: '18px',
        _hover: {
            bg: Colors.HOVER_SECONDARY
        }
    },
    ModalYes: {
        bg: Colors.SUCCESS,
        color: Colors.WHITE,
        h: '50px',
        fontSize: '15px',
        _hover: {
            bg: Colors.SUCCESS
        },
        type: 'submit',
        borderRadius: 'lg'
    },
    ModalNo: {
        borderColor: Colors.DARK,
        variant: 'outline',
        h: '50px',
        fontSize: '15px',
        borderRadius: 'lg'
    },
    ModalDelete: {
        bg: Colors.ERROR,
        color: Colors.WHITE,
        h: '50px',
        fontSize: '15px',
        _hover: {
            bg: Colors.ERROR
        },
        type: 'submit',
        borderRadius: 'lg'
    },
    Edit: {
        bg: Colors.INFO_LIGHT,
        h: '22px',
        fontSize: '12px',
        borderRadius: 'sm',
        color: Colors.INFO
    }
}

type Keys =
    | 'Primary'
    | 'PrimaryOutline'
    | 'SecondarySm'
    | 'ModalYes'
    | 'ModalNo'
    | 'ModalDelete'
    | 'Edit'

export default PropsButton
