import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from '@chakra-ui/react'
import { useEffect } from 'react'
import useSaveClient from '../../hooks/useSaveClient'
import BodyContent from './BodyContent'
import { ModalClientProps } from './types'

export default function ModalClient({ onDone }: ModalClientProps) {
    const { isOpen, onClose, onOpen } = useDisclosure()
    const { isLoading, submit, onChange, errorHelper, clear } = useSaveClient(
        client => {
            onClose()
            onDone(client)
        }
    )

    useEffect(() => {
        clear()
    }, [isOpen])

    return (
        <>
            <Button
                onClick={onOpen}
                w="full"
                mt={2}
                colorScheme="blue"
                boxShadow="base"
            >
                Nuevo cliente
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size="lg"
                closeOnOverlayClick
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Nuevo cliente</ModalHeader>
                    <form onSubmit={submit} noValidate>
                        <ModalBody pb={6}>
                            <BodyContent
                                onChange={onChange}
                                helpers={errorHelper}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                variant="outline"
                                bg="transparent"
                                onClick={onClose}
                            >
                                Cancelar
                            </Button>
                            <Button
                                colorScheme="green"
                                ml={2}
                                type="submit"
                                isLoading={isLoading}
                            >
                                Confirmar
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}
