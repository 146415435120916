import { useToast } from '@chakra-ui/react'
import { FormEvent } from 'react'
import { render } from 'redity'
import useDialog from '../../../hooks/useDialog'
import useSession from '../../../hooks/useSession'
import useStorage from '../../../hooks/useStorage'
import { post } from '../../../lib/http'
import modal, { close, loading } from '../../../lib/modal'
import val from '../../../lib/val'
import Logged from '../../../types/logged'
import getPrinterConfig from '../../../utilities/getPrinterConfig'
import { Keys } from '../constant'
import ModalCloseCashbox from '../ContentModals/ModalCloseCashbox'
import ModalClosedCashbox from '../ContentModals/ModalClosedCashbox'
import { formCloseCashbox, storagePage } from '../storage'
import loadMain from '../utilities/loadMain'

export default function useCloseCashbox() {
    const session = useSession().session as Logged
    const toast = useToast()
    const { cashboxUser } = storagePage
    const { business_variables } = useStorage()
    const closeCashboxWithOpenedTables = business_variables.find(
        v => v.code === '010'
    )?.value

    const dialogInvalidCloseCashbox = useDialog({
        title: 'Cierre de caja no permitido',
        description: <>Existen mesas pendientes de cobro.</>
    })

    // close cashbox
    async function submitCloseHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const form = formCloseCashbox.store()
        const v = val(form)
        v.password.isEmpty('Ingrese la contraseña')
        const result = v.runtest()
        if (result) {
            formCloseCashbox.setHelpers(result)
            return
        }

        const cashbox_id = storagePage.cashboxUser?.cashbox_id as number
        const printerConfig = getPrinterConfig()
        if (printerConfig) {
            loading()
            const { error } = await post('/api/cashboxes/close_cashbox', {
                cashbox_id,
                printer_id: Number(printerConfig.printer_id),
                password: form.password,
                username: session.user.username
            })
            if (error) {
                toast({
                    title: 'Hubo problemas para cerrar la caja, vuelva a intentar',
                    position: 'top',
                    status: 'error'
                })
                close()
            } else {
                // storagePage.cashboxUser = null
                await loadMain(storagePage.currentCashbox?.id || 1)
                render(Keys.MAIN)
                close()
                setTimeout(() => {
                    modal(ModalClosedCashbox, {
                        cleaned: true,
                        size: 'md'
                    })
                }, 100)
            }
        } else {
            toast({
                title: 'Lo sentimos, se requiere configurar la impresora para el cierre de caja',
                position: 'top',
                status: 'error'
            })
        }
    }

    function closeCashbox() {
        if (
            closeCashboxWithOpenedTables === '0' &&
            cashboxUser?.total_occupied_tables != 0
        ) {
            dialogInvalidCloseCashbox()
            return
        } else {
            formCloseCashbox.init()
            modal(ModalCloseCashbox, {
                size: 'md',
                onSubmit: submitCloseHandler
            })
        }
    }

    const isYourBox = storagePage.isYourBox

    return { closeCashbox, isYourBox }
}
