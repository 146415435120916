import { Box, Button, Flex } from '@chakra-ui/react'
import { useRender } from 'redity'
import Initial from '../../components/Initial'
import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import { Keys } from './constants'
import ContentTop from './ContentTop'
import useMainContent from './hooks/useMainContent'
import PaymentSection from './PaymentSection'
import ProductsSection from './ProductSection'
import { storagePage } from './storage'
import useInitial from './useInitial'

export default function GenerateVoucher() {
    useRender(Keys.MAIN)
    const { pageState } = useInitial()
    const {
        issueDate,
        dueDate,
        changeHandlerDate,
        type,
        changeHandlerType,
        onChangeCancel,
        submitHandler,
        enableButton,
        serie,
        changeHandlerSerie,
        data
    } = useMainContent()

    return (
        <Sidebar>
            <SimpleNav title="Facturador" />
            <Initial pageState={pageState}>
                <Box bgColor="white" py={4} px={6} minH="calc(100vh - 150px)">
                    <ContentTop
                        issueDate={issueDate}
                        dueDate={dueDate}
                        changeHandlerDate={changeHandlerDate}
                        type={type}
                        changeHandlerType={changeHandlerType}
                        serie={serie}
                        changeHandlerSerie={changeHandlerSerie}
                        series={data}
                    />
                    <ProductsSection />
                    {storagePage.selectedItems.length > 0 && <PaymentSection />}
                    <Flex justifyContent="flex-end" mt={6}>
                        <Button
                            variant="alo-gray:lg-md"
                            bg="transparent"
                            color="alo-gray"
                            w="36"
                            onClick={onChangeCancel}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="alo-green:lg-md"
                            w="36"
                            ml={3}
                            //type="submit"
                            //isLoading={isLoading}
                            onClick={submitHandler}
                            disabled={!enableButton}
                        >
                            Confirmar
                        </Button>
                    </Flex>
                </Box>
            </Initial>
        </Sidebar>
    )
}
