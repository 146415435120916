import FormEvent from '../../lib/form_event'
import { FormEvent as ReactFormEvent, useState } from 'react'
import modal, { close } from '../../lib/modal'
import val from '../../lib/val'
import PrinterConfig from '../../types/printer_config'
import getPrinterConfig from '../../utilities/getPrinterConfig'
import { post } from '../../lib/http'

export default function usePrinterConfig(
    modalContent: (...props: any) => JSX.Element
) {
    const [isDone, setDone] = useState(() =>
        localStorage.getItem('printer_config') ? true : false
    )
    async function submitHandler(ev: ReactFormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const f = formPrinter.store()
        const v = val(f)
        v.printer_id.isEmpty('Es requerido una impresora')

        const result = v.runtest()
        if (result) {
            formPrinter.setHelpers(result)
        } else {
            await post('/api/printer_machines/change_printer_global', {
                printer_id: Number(f.printer_id)
            })
            localStorage.setItem(
                'printer_config',
                JSON.stringify(formPrinter.store())
            )
            setDone(true)
            close()
        }
    }

    function clickHandler() {
        // const ips = getIPsConfig()
        const printerConfig = getPrinterConfig()
        if (printerConfig) {
            formPrinter.setValue('printer_id', printerConfig.printer_id)
            formPrinter.init(formPrinter.store)
        } else {
            formPrinter.init()
        }

        modal(modalContent, {
            title: 'Configuración de impresora',
            size: 'md',
            onSubmit: submitHandler
        })
    }

    return {
        clickHandler,
        isDone
    }
}

export const formPrinter = new FormEvent<PrinterConfig>({
    printer_id: ''
})
