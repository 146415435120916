import { Box, Icon, Input, Image, Spinner } from '@chakra-ui/react'
import Colors from '../../config/colors'
import Camera from '../Icon/Camera'
import { FileFieldProps } from './types'
import useFileField from './useFileField'

export default function FileField({
    value,
    name,
    onChange,
    ...props
}: FileFieldProps) {
    const { uploadHandler, loading } = useFileField(onChange)
    return (
        <Box
            {...props}
            w="120px"
            h="120px"
            border="1px"
            borderStyle="dashed"
            borderColor={Colors.DARK}
            marginX="auto"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
        >
            <Box
                w="100px"
                h="100px"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                {!value && !loading && (
                    <Icon as={Camera} fill={Colors.DARK} fontSize="24" />
                )}
                {value && !loading && (
                    <Image src={value} w="full" h="full" objectFit="cover" />
                )}
                {loading && <Spinner />}
            </Box>
            {!value && (
                <Input
                    type="file"
                    position="absolute"
                    w="full"
                    h="full"
                    cursor="pointer"
                    onChange={uploadHandler}
                    opacity="0"
                    accept="image/*"
                    name={name}
                />
            )}
            {value && (
                <Box
                    position="absolute"
                    bg={Colors.DARK}
                    w="37px"
                    h="37px"
                    borderRadius="full"
                    overflow="hidden"
                    bottom="-4"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    right="-4"
                >
                    <Icon
                        as={Camera}
                        fill={Colors.WHITE}
                        fontSize="20"
                        cursor="pointer"
                    />
                    <Input
                        type="file"
                        position="absolute"
                        w="full"
                        h="full"
                        accept="image/*"
                        name={name}
                        cursor="pointer"
                        onChange={uploadHandler}
                        opacity="0"
                    />
                </Box>
            )}
        </Box>
    )
}
