import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Textarea,
    Box
} from '@chakra-ui/react'
import colors from '../../../config/theme/colors'
import { useEffect, useState } from 'react'
import ErrorHelper from '../../../components/ErrorHelper'
import { storagePage } from '../storage'
import Description from '../../../components/Icon/Description'

export interface ModalCourtesyProps {
    isOpen: boolean
    onClose: () => void
    onDone: () => void
}

export default function ModalCourtesy({
    isOpen,
    onClose,
    onDone
}: ModalCourtesyProps) {
    // const cashbox = storagePage.cashboxUser?.cashbox as CashBox
    const [reason, setReason] = useState('')
    const [helper, setHelper] = useState('')

    function confirmHandler() {
        if (reason.trim() === '') {
            setHelper('Escriba el motivo')
            return
        }
        storagePage.reason = reason
        setReason('')
        onClose()
        onDone()
        setReason('')
    }

    useEffect(() => {
        setHelper('')
    }, [reason])

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalContent p={3}>
                <Box display="flex" justifyContent="center">
                    <Description fill={colors['alo-red']} fontSize="42" />
                </Box>
                <ModalHeader textAlign="center">Venta con cortesía</ModalHeader>
                <ModalBody>
                    <FormControl mb="4">
                        <FormLabel>Ingrese un comentario o motivo</FormLabel>
                        <Textarea
                            name="username"
                            placeholder="Ingresa un comentario"
                            value={reason}
                            autoFocus
                            onChange={ev => setReason(ev.target.value)}
                        ></Textarea>
                        <ErrorHelper helper={helper} />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="alo-gray:lg-md"
                        bg="transparent"
                        color="alo-gray"
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="alo-green:lg-md"
                        ml={3}
                        onClick={confirmHandler}
                    >
                        Confirmar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
