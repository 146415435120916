import { Box, Text } from '@chakra-ui/react'
import { useState } from 'react'
import ModalSubFeatures from '../../../components/ModalSubFeatures'
import { get } from '../../../lib/http'
import Feature, { SubFeature } from '../../../models/feature'
import Supply from '../../../models/supply'

export default function SubFeatures({ feature }: { feature: Feature }) {
    const [supplies, setSupplies] = useState<Supply[]>([])

    const getSupply = (sub_feature: SubFeature) => {
        if (
            sub_feature.supply_sub_features &&
            sub_feature.supply_sub_features[0]
        ) {
            const supply = supplies.find(
                s => s.id === sub_feature?.supply_sub_features[0]?.supply_id
            )
            return supply
        }

        return null
    }

    return (
        <ModalSubFeatures
            title={`Subcaracteristicas de ${feature.name}`}
            element={click => (
                <Box
                    cursor="pointer"
                    onClick={async () => {
                        const { data } = await get<Supply[]>(
                            '/api/supplies?sort=name'
                        )
                        setSupplies(data.result || [])
                        click()
                    }}
                    textDecoration="underline"
                >
                    {feature.sub_features.length}
                </Box>
            )}
        >
            <Text marginBottom="5" fontSize="13px" fontWeight={300}>
                Listado de subcaracteristicas
            </Text>
            <Box display="flex">
                <Box width={'30%'} paddingX="4">
                    <Text fontSize="12px" mb="2">
                        Nombre
                    </Text>
                </Box>
                <Box paddingX="2" width={'20%'}>
                    <Text fontSize="12px" mb="2">
                        Precio adicional
                    </Text>
                </Box>
                <Box width={'30%'} paddingX="2">
                    <Text fontSize="12px" mb="2">
                        Insumo a consumir
                    </Text>
                </Box>
                <Box width={'20%'} paddingX="2">
                    <Text fontSize="12px" mb="2">
                        Cantidad
                    </Text>
                </Box>
            </Box>
            {feature.sub_features.map(sub_feature => (
                <Box
                    key={sub_feature.id}
                    display="flex"
                    border="1px solid #C4C4C4"
                    borderRadius="10px"
                    marginBottom="2"
                    paddingX="2"
                >
                    <Box
                        width={'30%'}
                        paddingX="2"
                        paddingY="2"
                        borderRight="1px solid #C4C4C4"
                    >
                        {sub_feature.name}
                    </Box>
                    <Box
                        paddingX="2"
                        borderRight="1px solid #C4C4C4"
                        paddingY="2"
                        width={'20%'}
                    >
                        S./ {sub_feature.price}
                    </Box>
                    <Box
                        width={'30%'}
                        paddingX="2"
                        paddingY="2"
                        borderRight="1px solid #C4C4C4"
                    >
                        {sub_feature?.supply_sub_features &&
                        sub_feature?.supply_sub_features[0]
                            ? getSupply(sub_feature)?.name
                            : '-'}
                    </Box>
                    <Box width={'20%'} paddingX="2" paddingY="2">
                        {sub_feature?.supply_sub_features &&
                        sub_feature?.supply_sub_features[0]
                            ? `${
                                  sub_feature?.supply_sub_features[0].quantity
                              } ${
                                  getSupply(sub_feature)?.type_measure
                                      ?.symbol || ''
                              }`
                            : 0}
                    </Box>
                </Box>
            ))}
        </ModalSubFeatures>
    )
}
