import {
    Flex,
    Select,
    Table,
    Tbody,
    Tfoot,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import Download from './components/Download'
import Row from './components/Row'
import useMonths from './hooks/useMonths'
import useTouristGroups from './hooks/useTouristGruops'

export default function ReportBookings() {
    const [date, setDate] = useState(() =>
        dayjs().set('D', 1).format('YYYY-MM-DD')
    )
    const { touristGroups, totalSales, totalCommission } =
        useTouristGroups(date)
    const months = useMonths()
    const resolveMounth = (num: number) =>
        num < 10 ? `0${num}` : num.toString()

    return (
        <Sidebar>
            <SimpleNav title="Comisión por reservas" />
            <Flex gap={2} justify="flex-end" mb={2}>
                <Select
                    size="md"
                    borderRadius="full"
                    placeholder="Todos"
                    bg="base-4"
                    w={['full', 'full', 'auto']}
                    value={date}
                    onChange={ev => setDate(ev.target.value)}
                >
                    {months.map(month => (
                        <option
                            key={month[0]}
                            value={`${month[1]}-${resolveMounth(
                                month[0] + 1
                            )}-01`}
                        >
                            {month[2]}
                        </option>
                    ))}
                </Select>
                <Download date={date} touristGroups={touristGroups} />
            </Flex>
            <Table size="md" className="table-a-la-orden">
                <Thead>
                    <Tr>
                        <Th textAlign="center">Grupo turístico</Th>
                        <Th textAlign="center">N° de reservas</Th>
                        <Th textAlign="center">Plato vendidos</Th>
                        <Th textAlign="center">Venta total</Th>
                        <Th textAlign="center">Comisión</Th>
                        <Th textAlign="center">Acciones</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {touristGroups.map((touristGroup, i) => (
                        <Row key={i} touristGroup={touristGroup} date={date} />
                    ))}
                </Tbody>
                <Tfoot>
                    <Tr>
                        <Th colSpan={2} />
                        <Th fontSize="sm" textAlign="center">
                            Total
                        </Th>
                        <Th fontSize="sm" textAlign="center">
                            S/{totalSales.toFixed(2)}
                        </Th>
                        <Th fontSize="sm" textAlign="center">
                            S/{totalCommission.toFixed(2)}
                        </Th>
                        <Th />
                    </Tr>
                </Tfoot>
            </Table>
        </Sidebar>
    )
}
