import { Icon } from '@chakra-ui/react'
import Trash from '../../../components/Icon/Trash'
import colors from '../../../config/theme/colors'
import Delete from '../../../lib/delete'
import Item from '../../../models/item'
import { listItem } from '../storage'

export default function DeleteItem({ item }: { item: Item }) {
    return (
        <Delete
            title="Eliminar ítem"
            path={`/api/items/${item.id}`}
            element={click => (
                <Icon
                    onClick={click}
                    fill={colors.error}
                    as={Trash}
                    cursor="pointer"
                />
            )}
            done={() => {
                listItem.setQuery('page', 1)
                listItem.load()
            }}
        >
            ¿Esta seguro de eliminar el item <b>{item.name}</b>?
        </Delete>
    )
}
