import {
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper
} from '@chakra-ui/react'
import { useEffect, useRef } from 'react'
import onlyNumber from '../../../../utilities/onlyNumber'
import Item from '../../../../models/item'

export interface FieldQuantityProps {
    item: Item | null
    quantity: string
    setQuantity: (quantity: string) => void
}

export default function FieldQuantity({
    item,
    quantity,
    setQuantity
}: FieldQuantityProps) {
    const ref = useRef<HTMLInputElement>(null)

    const max = 999
    const min = 0

    function changeHandler(valueAsString: string) {
        setQuantity(
            onlyNumber(valueAsString, {
                decimal: 2,
                max: 999
            })
        )
    }

    useEffect(() => {
        if (item) {
            setQuantity('1')
            if (ref.current) {
                ref.current.focus()
            }
        } else {
            setQuantity('')
        }
    }, [item])

    return (
        <NumberInput
            value={quantity}
            maxW={{
                base: '20',
                lg: '32'
            }}
            minW={20}
            max={max}
            bg="white"
            min={min}
            name="quantity"
            onChange={changeHandler}
        >
            <NumberInputField placeholder="Cantidad" ref={ref} />
            <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
            </NumberInputStepper>
        </NumberInput>
    )
}
