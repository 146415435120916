import { Box, Flex } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/input'
import ArrowRight from '../../../../components/Icon/ArrowRight'
import FilterTabs from './FilterTabs'
import { useHistory } from 'react-router'
import { Icon, useBreakpointValue } from '@chakra-ui/react'
import { PageHeaderProps } from './types'
import { ChangeEvent, useState, useRef } from 'react'
import Search from '../../../../components/Icon/Search'

export default function PageHeader({
    search,
    onSearch,
    onStatus,
    status,
    counts
}: PageHeaderProps) {
    const { push } = useHistory()
    const isMobile = useBreakpointValue({ base: true, md: false })
    const [_search, _setSearch] = useState(() => search)
    const timerRef = useRef<NodeJS.Timeout | null>(null)

    function searchHandler(ev: ChangeEvent<HTMLInputElement>) {
        const value = ev.target.value
        _setSearch(value)
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }

        timerRef.current = setTimeout(() => {
            onSearch(value)
        }, 500)
    }

    return (
        <>
            <Box
                px={{ base: 3, lg: 6 }}
                bg="white"
                py={2}
                borderBottomWidth={1}
            >
                <Flex
                    justify="space-between"
                    gap={2}
                    flexDir={['column', 'column', 'column', 'row']}
                >
                    <Flex gap={4} alignItems="center">
                        <InputGroup w={{ base: 'full', lg: 'auto' }}>
                            <InputLeftElement pointerEvents="none">
                                <Icon as={Search} />
                            </InputLeftElement>
                            <Input
                                placeholder="Buscar cliente"
                                type="search"
                                value={_search}
                                onChange={searchHandler}
                                w={{ base: 'full', lg: 'auto' }}
                            />
                        </InputGroup>
                        {!isMobile && (
                            <FilterTabs
                                tabValue={status}
                                onTab={onStatus}
                                counts={counts}
                            />
                        )}
                    </Flex>
                    <Flex gap={2}>
                        <Button
                            size="md"
                            fontWeight="300"
                            colorScheme="green"
                            w={{
                                base: '40',
                                md: '60',
                                xl: '72'
                            }}
                            rightIcon={
                                <ArrowRight
                                    fill="white"
                                    width={12}
                                    height={12}
                                />
                            }
                            onClick={() => push('/delivery/customers')}
                        >
                            Nuevo{isMobile ? '' : ' delivery'}
                        </Button>
                    </Flex>
                </Flex>
            </Box>
            {isMobile && (
                <FilterTabs
                    tabValue={status}
                    onTab={onStatus}
                    counts={counts}
                />
            )}
        </>
    )
}
