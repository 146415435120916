import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import { useState } from 'react'
import { storagePage } from '../../storage'
import { dataItemsCanceledDes } from '../../types'
import Card from '../../Components/Card'
import StatBox from '../../Components/StatBox'
import StatCard from '../../Components/StatCard'
import ViewDetailButton from '../../Components/ViewDetailButton'

export default function CanceledSales() {
    const [showDetail, setShowDetail] = useState(false)

    const { cashboxMovements } = storagePage

    const data: dataItemsCanceledDes[] = []

    cashboxMovements.map(i => data.push(...i.dataItemsCanceledDes))
    const quantity = data.length
    const totalCanceledSales = cashboxMovements.reduce(
        (acc, curr) => acc + curr.totalSaleItemsCanceledDes,
        0
    )

    return (
        <Card direction="column" gridGap={4}>
            <Text as="b" mr="2" fontSize="xl" align="center" w="full">
                Ventas anuladas
            </Text>
            <StatCard>
                <StatBox label={'Cant. Ventas anuladas'} value={quantity} />
            </StatCard>
            <ViewDetailButton
                showDetail={showDetail}
                setShowDetail={setShowDetail}
            />
            {showDetail && (
                <TableContainer overflow="auto">
                    <Table size="sm">
                        <Thead>
                            <Tr>
                                <Th>Fecha</Th>
                                <Th>Comprobante</Th>
                                <Th>Motivo</Th>
                                <Th>Monto</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.map((movement, i) => (
                                <Tr key={i}>
                                    <Td>{movement.date}</Td>
                                    <Td>{movement.comprobante}</Td>
                                    <Td>{movement.motivo}</Td>
                                    <Td isNumeric>{movement.monto}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                        <Tfoot>
                            <Th colSpan={3}>Total</Th>
                            <Th isNumeric>S/{totalCanceledSales.toFixed(2)}</Th>
                        </Tfoot>
                    </Table>
                </TableContainer>
            )}
        </Card>
    )
}
