import { Box, Button, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { CounterProps } from '../types'

export default function Counter({ defaultQuantity, min = 1 }: CounterProps) {
    const [quantity, setQuantity] = useState(() => defaultQuantity)

    function clickPrevHandler() {
        if (quantity <= 1) return
        setQuantity(quantity - 1)
    }

    function clickNext() {
        setQuantity(quantity + 1)
    }

    return (
        <Box
            display="flex"
            justifyContent={{ base: 'center', lg: 'space-around' }}
            alignItems="center"
            mb="8"
            px={{ base: 0, lg: '5' }}
        >
            <input type="hidden" value={quantity} name="quantity" />
            <Button
                variant="alo-gray:2xl-md"
                w={{ base: '50px', lg: '80px' }}
                h={{ base: '50px', lg: '80px' }}
                fontSize="3xl"
                isDisabled={quantity <= min}
                onClick={clickPrevHandler}
            >
                -
            </Button>
            <Text
                fontWeight="bold"
                minWidth={{ base: '100px' }}
                fontSize="2xl"
                textAlign="center"
            >
                {quantity}
            </Text>
            <Button
                variant="alo-gray:2xl-md"
                w={{ base: '50px', lg: '80px' }}
                h={{ base: '50px', lg: '80px' }}
                fontSize="3xl"
                onClick={clickNext}
            >
                +
            </Button>
        </Box>
    )
}
