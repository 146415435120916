import React, { useState } from 'react'
import Reason from '../Reason'
import {
    Box,
    FormControl,
    FormLabel,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Button,
    Icon,
    useToast,
    Text
} from '@chakra-ui/react'
import Warehouse from '../../../../models/warehouse'
import { useGet } from '../../../../hooks/useHttp'
import Close from '../../../../components/Icon/Close'
import colors from '../../../../config/theme/colors'
import CustomNumberInput from '../CustomNumberInput'
import SelectWarehouse from '../SelectWarehouse'
import FieldSearchProductionItem from '../FieldSearchProductionItem'
import { post } from '../../../../lib/http'
import onlyNumber from '../../../../utilities/onlyNumber'

interface Props {
    onClose: () => void
}

export default function TransferStock({ onClose }: Props) {
    const { data } = useGet<Warehouse[]>('/api/warehouses', [])
    const [helper, setHelper] = useState<{ [key: string]: string }>({})
    const [form, setForm] = useState({
        origin_warehouse_id: '',
        destination_warehouse_id: '',
        reason: ''
    })
    const [productionItems, setProductionsItems] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [errorItems, setErrorItems] = useState<any[]>([])

    const toast = useToast()

    const validData = () => {
        let isValid = true
        const errors: any = {}
        if (!form.origin_warehouse_id) {
            isValid = false
            errors.origin_warehouse_id = 'Debe seleccionar un almacén de origen'
        }
        if (!form.destination_warehouse_id) {
            isValid = false
            errors.destination_warehouse_id =
                'Debe seleccionar un almacén de destino'
        }

        if (Object.keys(errors).length !== 0) {
            setHelper(errors)
        }

        if (productionItems.length === 0) {
            isValid = false
        } else {
            const errorProductionItems = []
            for (const productionItem of productionItems) {
                if (Number(productionItem.quantity) <= 0) {
                    errorProductionItems.push({
                        quantity: 'La cantidad debe ser mayor a 0'
                    })
                    isValid = false
                } else {
                    errorProductionItems.push({
                        quantity: ''
                    })
                }
            }
            if (errorProductionItems.length > 0) {
                setErrorItems(errorProductionItems)
            }
        }

        return isValid
    }

    const handleSubmit = async () => {
        if (!validData()) {
            return
        }

        const production_items = []
        for (const dataproductionItem of productionItems) {
            production_items.push({
                production_item_id: dataproductionItem.production_item.id,
                quantity: dataproductionItem.quantity,
                unit_cost: dataproductionItem.cost
            })
        }

        const _data = {
            origin_warehouse_id: Number(form.origin_warehouse_id),
            destination_warehouse_id: Number(form.destination_warehouse_id),
            reason: form.reason,
            production_items
        }

        setIsLoading(true)
        const { error } = await post<any>(
            '/api/inventory_production_items/transfer_stock',
            _data
        )

        if (error) {
            setIsLoading(false)
            toast({
                status: 'error',
                title:
                    error.message ?? 'Ocurrio un error, al querer transferir',
                position: 'top'
            })
            return
        }

        toast({
            status: 'success',
            title: 'Se realizó la transferencia correctamente',
            position: 'top'
        })

        setIsLoading(false)
        onClose()
    }

    const handleChange = (
        value: string,
        index: number,
        name: string,
        maxValue?: number
    ) => {
        if (name === 'quantity') {
            if (errorItems[index]) {
                errorItems[index][name] = ''
                setErrorItems([...errorItems])
            }
            const v = onlyNumber(value, {
                decimal: 2,
                min: 0,
                negative: false,
                max: maxValue ?? Infinity
            })
            productionItems[index][name] = v
        } else {
            productionItems[index][name] = value
        }
        setProductionsItems([...productionItems])
    }

    const handleDelete = (index: number) => {
        const newProductionItems = []
        for (let i = 0; i < productionItems.length; i++) {
            if (i !== index) {
                newProductionItems.push(productionItems[i])
            }
        }
        setProductionsItems([...newProductionItems])
        if (index < errorItems.length && errorItems.length > 0) {
            const newErrorItems = []
            for (let i = 0; i < errorItems.length; i++) {
                if (i !== index) {
                    newErrorItems.push(errorItems[i])
                }
            }
            setErrorItems([...newErrorItems])
        }
    }

    const filterWarehouses = data.result.filter(
        _warehouse => _warehouse.id !== Number(form.origin_warehouse_id)
    )

    return (
        <>
            <Box
                display={'flex'}
                flexDir={{
                    base: 'column',
                    md: 'row'
                }}
                gap={4}
                mt={4}
            >
                <Box
                    w={{
                        base: 'full',
                        md: '70%'
                    }}
                    rounded={'lg'}
                    bg="white"
                >
                    <Box display={'flex'} gap={'10px'}>
                        <FormControl
                            p={4}
                            borderBottom={'1px solid'}
                            borderColor={'gray.200'}
                        >
                            <SelectWarehouse
                                label="Almacén de origen"
                                value={form.origin_warehouse_id}
                                handleChange={e => {
                                    setForm({
                                        ...form,
                                        origin_warehouse_id: e.target.value
                                    })
                                    setHelper({
                                        ...helper,
                                        origin_warehouse_id: ''
                                    })
                                }}
                                helper={helper.origin_warehouse_id}
                                options={data.result}
                            />
                        </FormControl>
                        <FormControl
                            p={4}
                            borderBottom={'1px solid'}
                            borderColor={'gray.200'}
                        >
                            <SelectWarehouse
                                label="Almacén de destino"
                                value={form.destination_warehouse_id}
                                handleChange={e => {
                                    setForm({
                                        ...form,
                                        destination_warehouse_id: e.target.value
                                    })
                                    setHelper({
                                        ...helper,
                                        destination_warehouse_id: ''
                                    })
                                }}
                                helper={helper.destination_warehouse_id}
                                options={filterWarehouses}
                            />
                        </FormControl>
                    </Box>

                    <Box p={4} mt={4}>
                        <FormControl
                            mb={6}
                            w={{
                                base: 'full',
                                md: '50%'
                            }}
                        >
                            <FormLabel>Buscar Articulo</FormLabel>
                            <FieldSearchProductionItem
                                onSelect={_productionItem => {
                                    if (!_productionItem) return
                                    const findProductionItem =
                                        productionItems.find(
                                            _data =>
                                                _data.id === _productionItem?.id
                                        )

                                    if (!findProductionItem) {
                                        setProductionsItems(prev => [
                                            ...prev,
                                            {
                                                ..._productionItem,
                                                quantity: 0
                                            }
                                        ])
                                    }
                                }}
                                warehouse_id={form.origin_warehouse_id}
                                destination_warehouse_id={
                                    form.destination_warehouse_id
                                }
                                validWarehouse={() => {
                                    const errors: any = {}
                                    if (!form.origin_warehouse_id) {
                                        errors.origin_warehouse_id =
                                            'Debe seleccionar un almacén de origen'
                                    }
                                    if (!form.destination_warehouse_id) {
                                        errors.destination_warehouse_id =
                                            'Debe seleccionar un almacén de destino'
                                    }

                                    if (Object.keys(errors).length !== 0) {
                                        setHelper(errors)
                                    }
                                }}
                                isSimple={false}
                            />
                        </FormControl>
                        <TableContainer>
                            <Table size="sm">
                                <Thead>
                                    <Tr>
                                        <Th>Articulo</Th>
                                        <Th whiteSpace={'normal'}>
                                            Stock origen
                                        </Th>
                                        <Th whiteSpace={'normal'}>
                                            Stock destino
                                        </Th>
                                        <Th>Unidad</Th>
                                        <Th whiteSpace={'normal'}>
                                            Costo unitario
                                        </Th>
                                        <Th w="56">Cantidad</Th>
                                        <Th></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {productionItems.map(
                                        (itemMovement, index) => (
                                            <Tr p={2} key={itemMovement.id}>
                                                <Td>
                                                    {
                                                        itemMovement
                                                            .production_item
                                                            ?.name
                                                    }
                                                </Td>
                                                <Td>
                                                    {itemMovement.origin_stock}
                                                </Td>
                                                <Td>
                                                    {
                                                        itemMovement.destination_stock
                                                    }
                                                </Td>
                                                <Td>
                                                    {
                                                        itemMovement
                                                            ?.production_item
                                                            ?.magnitude?.name
                                                    }
                                                </Td>
                                                <Td
                                                    textAlign={
                                                        itemMovement.cost
                                                            ? 'initial'
                                                            : 'center'
                                                    }
                                                >
                                                    {itemMovement.cost ?? '-'}
                                                </Td>
                                                <Td w="56">
                                                    <CustomNumberInput
                                                        valueForm={
                                                            itemMovement.quantity
                                                        }
                                                        handleChange={
                                                            handleChange
                                                        }
                                                        name="quantity"
                                                        placeholder="Ingresar cant."
                                                        precision={2}
                                                        index={index}
                                                        max={
                                                            itemMovement.origin_stock
                                                        }
                                                    />

                                                    <Text
                                                        fontSize={'xs'}
                                                        color="red.500"
                                                    >
                                                        {
                                                            errorItems?.[index]
                                                                ?.quantity
                                                        }
                                                    </Text>
                                                </Td>

                                                <Td>
                                                    <Icon
                                                        onClick={() =>
                                                            handleDelete(index)
                                                        }
                                                        cursor="pointer"
                                                        fill={colors.error}
                                                        as={Close}
                                                    />
                                                </Td>
                                            </Tr>
                                        )
                                    )}
                                </Tbody>
                                {productionItems.length === 0 && (
                                    <TableCaption>
                                        No existen articulos
                                    </TableCaption>
                                )}
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
                <Box
                    w={{
                        base: 'full',
                        md: '30%'
                    }}
                >
                    <Reason
                        handleChange={e => {
                            setForm({
                                ...form,
                                reason: e.target.value
                            })
                        }}
                    />
                </Box>
            </Box>
            <Box mt={3} display={'flex'} justifyContent={'end'}>
                <Button
                    variant="alo-gray:lg-md"
                    bg="white"
                    color="alo-gray"
                    onClick={onClose}
                    disabled={isLoading}
                    type="button"
                >
                    Cancelar
                </Button>

                <Button
                    variant="alo-green:lg-md"
                    ml={3}
                    type="submit"
                    isLoading={isLoading}
                    onClick={handleSubmit}
                >
                    Guardar
                </Button>
            </Box>
        </>
    )
}
