import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { memo } from 'react'
import TableRow from './TableRow'
import { TableCoinsProps } from './types'

function TableCoins({ coinsValues, setCoinsValues, total }: TableCoinsProps) {
    return (
        <Table size="sm">
            <Thead>
                <Tr>
                    <Th textAlign="center" colSpan={3}>
                        Monedas
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                <Tr whiteSpace="nowrap" bg="#d0d9df">
                    <Th>Denominación</Th>
                    <Th textAlign="center">Cantidad</Th>
                    <Th textAlign="center">Total</Th>
                </Tr>
                {Object.entries(coinsValues).map(
                    ([coin, { quantity, denominationMoneyId }]) => (
                        <TableRow
                            key={coin}
                            coin={Number(coin)}
                            quantity={quantity}
                            onChange={nextQuantity =>
                                setCoinsValues({
                                    ...coinsValues,
                                    [coin]: {
                                        denominationMoneyId,
                                        quantity: nextQuantity
                                    }
                                })
                            }
                        />
                    )
                )}
                <Tr bg="gray.100">
                    <Th />
                    <Th textAlign="right">Total:</Th>
                    <Th>S/{total.toFixed(2)}</Th>
                </Tr>
            </Tbody>
        </Table>
    )
}

export default memo(TableCoins)
