export default function CheckIn(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 25 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.2625 12.475L13.0125 1.225C12.5625 0.775 11.9375 0.5 11.25 0.5H2.5C1.125 0.5 0 1.625 0 3V11.75C0 12.4375 0.275 13.0625 0.7375 13.525L11.9875 24.775C12.4375 25.225 13.0625 25.5 13.75 25.5C14.4375 25.5 15.0625 25.225 15.5125 24.7625L24.2625 16.0125C24.725 15.5625 25 14.9375 25 14.25C25 13.5625 24.7125 12.925 24.2625 12.475ZM13.75 23.0125L2.5 11.75V3H11.25V2.9875L22.5 14.2375L13.75 23.0125ZM3.75 6.125C3.75 5.08947 4.58947 4.25 5.625 4.25C6.66053 4.25 7.5 5.08947 7.5 6.125C7.5 7.16053 6.66053 8 5.625 8C4.58947 8 3.75 7.16053 3.75 6.125Z"
            />
        </svg>
    )
}
