import { useBreakpointValue } from '@chakra-ui/react'

export default function useResolution() {
    const isMobile = useBreakpointValue({ base: true, md: false })
    const isDesktop = useBreakpointValue({ base: false, xl: true })

    return {
        isMobile,
        isDesktop
    }
}
