import { Button } from '@chakra-ui/react'
import CashIcon from '../../../../../components/Icon/CashIcon'
import useSavePreOrder from '../../../hooks/useSavePreOrder'
import usePreOrder from '../../../hooks/usePreOrder'
import useMenu from '../../../../../hooks/useMenu'

export default function ButtonSavePreOrder() {
    const { register, update } = useSavePreOrder()
    const { getPriceTotal, menu } = useMenu()
    const { preOrderId, booking } = usePreOrder()

    const isDisabled = menu.filter(item => item.quantity > 0).length === 0

    function clickHandler() {
        if (preOrderId) {
            update()
        } else {
            register()
        }
    }

    return (
        <Button
            colorScheme="green"
            size="lg"
            fontSize={{
                xl: 'md',
                lg: 'sm'
            }}
            w="full"
            leftIcon={<CashIcon fill="white" fontSize={32} />}
            onClick={clickHandler}
            isDisabled={isDisabled || !booking}
        >
            Guardar pre-pedido (S/{getPriceTotal().toFixed(2)})
        </Button>
    )
}
