import { useEffect } from 'react'
import {
    listCategory,
    listDestiny,
    listFeature,
    listItem,
    listSupply
} from './storage'

export default function useInitial() {
    useEffect(() => {
        Promise.all([
            listCategory.load(),
            listDestiny.load(),
            listItem.load(),
            listFeature.load(),
            listSupply.load()
        ])
    }, [])
}
