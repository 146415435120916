import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import { Tr, Td } from '@chakra-ui/react'
import List from '../../lib/list'
import { controlList } from './storage'
import Actions from './Actions'
import useInitial from './useInitial'
import dayjs from 'dayjs'

export default function ReportReceipts() {
    useInitial()

    return (
        <Sidebar>
            <SimpleNav title="Reportes de comprobantes" />
            <Actions />
            <List
                control={controlList}
                tbody={(sale, e) => (
                    <Tr key={e}>
                        <Td>{dayjs(sale.create_date).format('DD/MM/YYYY')}</Td>
                        <Td>{dayjs(sale.issue_date).format('DD/MM/YYYY')}</Td>
                        <Td>{sale.type_voucher}</Td>
                        <Td>
                            {sale.serie}-{sale.correlative}
                        </Td>
                        <Td>{sale.document}</Td>
                        <Td>{sale.client}</Td>
                        <Td>{sale.sub_total}</Td>
                        <Td>{sale.sub_total_no_gravada}</Td>
                        <Td>{sale.igv}</Td>
                        <Td>{sale.total.toFixed(2)}</Td>
                        <Td>{sale.currency}</Td>
                    </Tr>
                )}
            />
        </Sidebar>
    )
}
