import {
    Button,
    Flex,
    Heading,
    HStack,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
    useDisclosure
} from '@chakra-ui/react'
import { FormEvent, useEffect, useState } from 'react'
import Description from '../../../../../../components/Icon/Description'
import colors from '../../../../../../config/theme/colors'
import { ModalInformationProps } from './types'

export default function ModalInformation({
    element,
    title,
    value,
    onDone
}: ModalInformationProps) {
    const { onOpen, onClose, isOpen } = useDisclosure()
    const [description, setDescription] = useState(() => value)

    useEffect(() => {
        if (isOpen) {
            setDescription(value)
        }
    }, [isOpen])

    function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        onDone(description)
        onClose()
    }

    return (
        <>
            {element(onOpen)}
            <Modal isCentered size="sm" onClose={onClose} isOpen={isOpen}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody p="6">
                        <Heading textAlign="center" size="md" mb={5}>
                            {title}
                        </Heading>
                        <Flex align="flex-end" mb={2}>
                            <Icon
                                as={Description}
                                fill={colors['alo-blue']}
                                fontSize="24"
                                mr={1}
                            />
                            <Text color={colors['gray-2']} mb={-1}>
                                Información Adicional
                            </Text>
                        </Flex>
                        <form onSubmit={submitHandler} name="form-information">
                            <Input
                                placeholder="Información"
                                mb={4}
                                size="sm"
                                borderRadius="lg"
                                value={description}
                                onChange={ev => setDescription(ev.target.value)}
                            />
                            <HStack justify="end">
                                <Button
                                    size="sm"
                                    variant="ghost"
                                    onClick={onClose}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    size="sm"
                                    bg={colors['alo-green']}
                                    color="white"
                                    borderRadius="xl"
                                    type="submit"
                                >
                                    Agregar
                                </Button>
                            </HStack>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
