import { Box, Text } from '@chakra-ui/react'
import colors from '../../../../config/theme/colors'
import { GuideItemProps } from './types'

export default function GuideItem({
    isChecked,
    label,
    onClick
}: GuideItemProps) {
    return (
        <Box
            h="150px"
            borderRadius={'20px'}
            p={2}
            // pointerEvents={_booking}
            textAlign="center"
            display={'flex'}
            justifyContent="center"
            alignItems={'center'}
            bg={isChecked ? colors['alo-red'] : 'gray.400'}
            color={isChecked ? 'white' : 'whiteAlpha.900'}
            cursor="pointer"
            flexDirection={'column'}
            onClick={onClick}
        >
            <Text>{label}</Text>
        </Box>
    )
}
