// config access route in src/config/access

import { Grid, useDisclosure } from '@chakra-ui/react'
import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import useStorage from '../../hooks/useStorage'
import Groups from './Groups'
import GroupItem from './Groups/GroupItem'
import ModalPaymentsMethod from './modals/ModalPaymentsMethod'
import ModalProdCatCourtesy from './modals/ModalProdCatCourtesy'
import ModalSalesByWaiters from './modals/ModalSalesByWaiters'

export default function Reports() {
    const { onOpen, isOpen, onClose } = useDisclosure()
    const { business_variables } = useStorage()
    const enabledBusinessLine = !!business_variables.find(
        bv => bv.code === '014' && bv.value === '1'
    )

    return (
        <Sidebar>
            <ModalProdCatCourtesy isOpen={isOpen} onClose={onClose} />
            <SimpleNav title="Reportes" />
            <Grid templateColumns="repeat(5, 1fr)" gap={6}>
                <Groups title="Ventas">
                    <GroupItem to="/reports/receipts" label="Comprobantes" />
                    <GroupItem
                        onClick={onOpen}
                        label="Productos, categorías y cortesías"
                    />
                    {enabledBusinessLine && (
                        <GroupItem
                            to="/reports/business_line"
                            label="Venta por línea de negocio"
                        />
                    )}
                    <ModalPaymentsMethod />
                    <ModalSalesByWaiters />
                </Groups>
                <Groups title="Comisiones">
                    <GroupItem to="/reports/commissions" label="Reservas" />
                </Groups>
            </Grid>
        </Sidebar>
    )
}
