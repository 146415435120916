import { useOutsideClick } from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useSession from '../../hooks/useSession'

export default function useNTabs(onSell: (isSell: boolean) => void) {
    const { pathname } = useLocation()
    const { isLogged, isWaiter, isDealer, isCashier } = useSession()
    const { push } = useHistory()
    const [isCheckedSell, setCheckedSell] = useState(
        () => localStorage.getItem('all_sales') === '1'
    )
    const ref = useRef(null)
    const [isOpenOptionsSell, setIsOpenOptionsSell] = useState(false)
    useOutsideClick({
        ref,
        handler: () => setIsOpenOptionsSell(false)
    })

    function isActive(pathNameLink: string) {
        if (pathname.search(pathNameLink) === 0) {
            return {
                color: 'orange.300',
                fill: 'orange.300',
                fontSize: 'md',
                alignItems: 'center'
            }
        }

        return {
            color: 'white',
            cursor: 'pointer',
            fill: 'white',
            fontSize: 'md',
            alignItems: 'center',
            onClick: () => (isOpenOptionsSell ? null : push(pathNameLink))
        }
    }

    function _setCheckedSell() {
        setCheckedSell(!isCheckedSell)
        localStorage.setItem('all_sales', !isCheckedSell ? '1' : '0')
        onSell(!isCheckedSell)
    }

    return {
        isLogged,
        isWaiter,
        isCheckedSell,
        setCheckedSell: _setCheckedSell,
        isActive,
        isOpenOptionsSell,
        setIsOpenOptionsSell,
        ref,
        isDealer,
        isCashier
    }
}
