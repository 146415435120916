import { Button, Menu, MenuButton, MenuList } from '@chakra-ui/react'
import { ReactNode } from 'react'
import colors from '../../config/theme/colors'
import ChevronDown from '../Icon/ChevronDown'

export default function MultipleSelector({
    label,
    children
}: MultipleSelectorProps) {
    return (
        <Menu closeOnSelect={false} autoSelect={false}>
            <MenuButton
                as={Button}
                rightIcon={<ChevronDown fill="gray.200" />}
                fontWeight={'light'}
                _focus={{}}
                bg={colors['base-4']}
                borderRadius="full"
                overflow={'hidden'}
                maxW="fit-content"
            >
                {label}
            </MenuButton>
            <MenuList>{children}</MenuList>
        </Menu>
    )
}

export interface MultipleSelectorProps {
    label: string
    children: ReactNode
}
