import { ChangeEvent, FormEvent, useState } from 'react'
import useRunway from '../../hooks/useRunway'
import useAuth from '../../hooks/useAuth'
import { post } from '../../lib/http'
import val from '../../lib/val'
import Logged from '../../types/logged'
import extractDataForm from '../../utilities/extractDataForm'
import { Form } from './types'

export default function useSubmit() {
    const { setAuth } = useAuth()
    const [errorField, setErrorField] = useState<Partial<Form>>({})
    const [loading, setLoading] = useState(false)
    const [errorText, setErrorText] = useState('')
    const { saveSelectedItems } = useRunway()

    async function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        setErrorText('')
        const form = extractDataForm<Form>(ev.target)

        const v = val(form)

        v.username.isEmpty('El nombre de usuario es requerido')
        v.password.isEmpty('La contraseña es requerido')

        const result = v.runtest()
        if (result) {
            setErrorField(result)
            return
        }

        saveSelectedItems([])
        setErrorField({})
        setLoading(true)

        const response = await post<Logged>('/api/login', form)

        setLoading(false)

        if (response.error) {
            setErrorText(response.error.message)
            return
        }
        setAuth(response.data.result)
    }

    function changeHandler(ev: ChangeEvent<HTMLInputElement>) {
        const name = ev.target.name as keyof Form
        if (errorField[name]) {
            setErrorField({
                ...errorField,
                [name]: ''
            })
        }
    }

    return {
        submitHandler,
        errorField,
        changeHandler,
        loading,
        errorText
    }
}
