import { Box, Text } from '@chakra-ui/react'
import { MouseEvent } from 'react'
import colors from '../../config/theme/colors'

export default function ClientItem({
    name,
    document,
    onClick
}: {
    name: string
    document: string
    onClick: (ev: MouseEvent<HTMLDivElement>) => void
}) {
    return (
        <Box
            borderBottom="2px solid"
            borderColor={colors['base-4']}
            py="2"
            px="4"
            cursor="pointer"
            _hover={{
                bg: 'base-2'
            }}
            onClick={onClick}
        >
            <Text color="orange" fontWeight="bold">
                {name}
            </Text>
            <Text fontSize="sm" color="gray-3">
                {document}
            </Text>
        </Box>
    )
}
