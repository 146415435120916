import { Box, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'
import getNumberWithTwoDecimals from '../../../utilities/getNumberWithTwoDecimals'
import ModalSubPayments from '../ContentModals/ModalSubPayments'
import { PaymentAmount } from '../types'

export default function BoxTotal({
    w,
    title,
    value,
    targets,
    wTarget,
    paymentAmounts
}: BoxTotalProps) {
    return (
        <Box
            w={w}
            p="5"
            borderRadius="5px"
            bg="dark-2"
            color="white"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
        >
            <Box>
                <Text
                    textAlign="center"
                    fontWeight="semibold"
                    fontSize="xl"
                    lineHeight={1.1}
                >
                    {title}
                </Text>
                <Text
                    textAlign="center"
                    fontWeight="semibold"
                    fontSize="2xl"
                    mt={2}
                >
                    S/
                    {value.toFixed(2)}
                </Text>
            </Box>

            <Box
                mt="3"
                display="flex"
                justifyContent={{
                    base: 'space-around',
                    md: 'space-around'
                }}
                flexWrap={'wrap'}
                gridGap={3}
            >
                {targets &&
                    targets.map((target, i) => (
                        <Box key={i} w={wTarget ? wTarget : 'auto'} p="1">
                            <TextCustom>{target.label}</TextCustom>
                            <TextCustom>
                                S/
                                {getNumberWithTwoDecimals(target.value)}
                            </TextCustom>
                        </Box>
                    ))}
                {paymentAmounts &&
                    paymentAmounts.map((paymentAmount, i) => (
                        <Box key={i} w={wTarget ? wTarget : 'auto'} p="1">
                            <TextCustom>
                                {paymentAmount.payment_name}
                            </TextCustom>
                            <Amount paymentAmount={paymentAmount}>
                                S/
                                {getNumberWithTwoDecimals(paymentAmount.amount)}
                            </Amount>
                        </Box>
                    ))}
            </Box>
        </Box>
    )
}

function TextCustom({ children }: { children: ReactNode }) {
    return (
        <Text fontWeight="bold" textAlign="center" lineHeight={1.2} mt={2}>
            {children}
        </Text>
    )
}

function Amount({
    children,
    paymentAmount
}: {
    children: ReactNode
    paymentAmount: PaymentAmount
}) {
    const areThereSubpayments = paymentAmount.sub_payments.length > 0
    return (
        <ModalSubPayments
            element={click => (
                <Text
                    fontWeight="bold"
                    textAlign="center"
                    lineHeight={1.2}
                    mt={2}
                    {...(areThereSubpayments && {
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        onClick: click
                    })}
                >
                    {children}
                </Text>
            )}
            paymentAmount={paymentAmount}
        />
    )
}

interface BoxTotalProps {
    w: any
    title: string
    value: number
    targets?: Array<{
        label: string
        value: number
    }> | null
    wTarget: any | null
    paymentAmounts?: PaymentAmount[]
}
