import { Icon } from '@chakra-ui/react'
import Trash from '../../../components/Icon/Trash'
import colors from '../../../config/theme/colors'
import Delete from '../../../lib/delete'
import Feature from '../../../models/feature'
import { listFeature } from '../storage'

export default function DeleteFeature({ feature }: { feature: Feature }) {
    return (
        <Delete
            title="Eliminar característica"
            path={`/api/features/${feature.id}`}
            element={click => (
                <Icon
                    onClick={click}
                    fill={colors.error}
                    as={Trash}
                    cursor="pointer"
                />
            )}
            done={() => {
                listFeature.setQuery('page', 1)
                listFeature.load()
            }}
        >
            ¿Esta seguro de eliminar la característica <b>{feature.name}</b>?
        </Delete>
    )
}
