import {
    FormControl,
    FormLabel,
    Input,
    InputProps,
    Select,
    SelectProps
} from '@chakra-ui/react'

import ErrorHelper from '../../../components/ErrorHelper'
import { useGet } from '../../../hooks/useHttp'
import Zone from '../../../models/zone'
import { formTable } from '../storage'

export default function FormTable() {
    return (
        <>
            <NameField />
            <ZoneField />
        </>
    )
}

function NameField() {
    const { props, helper, error } = formTable.useField<InputProps>('name')
    return (
        <FormControl mb={4}>
            <FormLabel>Nombre</FormLabel>
            <Input {...props} placeholder="Ingresar nombre" autoFocus />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function ZoneField() {
    const { props, helper, error } = formTable.useField<SelectProps>('zone_id')
    const { data } = useGet<Zone[]>('/api/zones', [])

    return (
        <FormControl>
            <FormLabel>Zona</FormLabel>
            <Select {...props} placeholder="Seleccione zona">
                {data.result.map(zone => (
                    <option key={zone.id} value={zone.id}>
                        {zone.name}
                    </option>
                ))}
            </Select>
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}
