export default function CardIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M3.77993 4.75164C3.62173 4.78679 3.24087 4.92742 2.93618 5.0739C2.18032 5.44304 1.69399 5.92937 1.32485 6.68523C1.17251 7.0075 1.03774 7.39422 0.996728 7.61687C0.955712 7.88054 0.938134 10.2067 0.949853 15.2341L0.967431 22.4704L1.09634 22.8102C1.56509 24.029 2.31509 24.7614 3.51626 25.1716C3.82681 25.277 4.24282 25.2829 14.9713 25.2829C27.1061 25.2829 26.3092 25.3063 27.0417 24.9489C27.9616 24.4977 28.5592 23.8005 28.9225 22.7341C29.028 22.4235 29.0338 22.113 29.0338 14.9997C29.0338 6.70867 29.069 7.43523 28.6178 6.56219C28.2194 5.80047 27.5045 5.18523 26.6432 4.86883L26.2213 4.71648L15.1471 4.71062C8.43227 4.70476 3.95571 4.72234 3.77993 4.75164ZM26.1979 6.71453C26.5319 6.87273 26.8717 7.21258 27.0358 7.55828L27.1588 7.82195V14.9997V22.1774L27.0358 22.4411C26.8776 22.7751 26.526 23.1266 26.192 23.2848L25.9284 23.4079H15.0006H4.0729L3.80923 23.2848C3.47524 23.1266 3.12368 22.7751 2.96548 22.4411C2.84243 22.1774 2.84243 22.1657 2.82485 15.1227C2.81313 9.01726 2.82485 8.02117 2.90102 7.76336C3.04751 7.25359 3.44009 6.84929 3.94985 6.66765C4.2311 6.5739 4.85806 6.56804 15.0885 6.57976L25.9284 6.59148L26.1979 6.71453Z" />
            <path d="M5.24445 9.45671C4.85187 9.62663 4.62921 10.0778 4.71124 10.5173C4.76398 10.7926 5.05695 11.1149 5.32062 11.1911C5.47296 11.238 8.51398 11.2497 15.1292 11.238C24.7093 11.2204 24.721 11.2204 24.8792 11.0973C25.1898 10.8688 25.2835 10.6813 25.2835 10.3122C25.2835 9.94304 25.1898 9.75554 24.8792 9.52702C24.721 9.40398 24.7152 9.40398 15.0706 9.39226C7.13703 9.38054 5.39093 9.39226 5.24445 9.45671Z" />
            <path d="M5.24445 19.7699C4.85187 19.9398 4.62921 20.391 4.71124 20.8304C4.76398 21.1058 5.05695 21.4281 5.32062 21.5042C5.46124 21.5453 6.25812 21.5628 7.62921 21.5511C9.63312 21.5335 9.72687 21.5277 9.87921 21.4105C10.1898 21.182 10.2835 20.9945 10.2835 20.6253C10.2835 20.2562 10.1898 20.0687 9.87921 19.8402C9.72687 19.723 9.63898 19.7171 7.57062 19.7054C5.8714 19.6937 5.38507 19.7054 5.24445 19.7699Z" />
        </svg>
    )
}
