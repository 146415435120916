import { useHistory } from 'react-router-dom'
import { storagePage } from '../storage'

export default function useMoveProducts() {
    const { push } = useHistory()
    function moveProductsHandler() {
        push(`/orders/${storagePage.table?.order.id}/move_products`)
    }
    return { moveProductsHandler }
}
