import { render } from 'redity'
import { Keys } from '../constants'
import { storagePage } from '../storage'

export default function usePaymentMethods() {
    function cashHandler(cash: string, type: string) {
        storagePage.cash.push({
            sub_payment_type_id: type ? Number(type) : null,
            amount: Number(cash || '0')
        })
        render(Keys.TABLE_PAYMENTS)
        render(Keys.RESULT_PAY)
        render(Keys.DETAILS)
        render(Keys.PAYMENTS_METHODS)
    }

    function cardHandler(card: string, type: string) {
        storagePage.card.push({
            sub_payment_type_id: type ? Number(type) : null,
            amount: Number(card || '0')
        })
        render(Keys.TABLE_PAYMENTS)
        render(Keys.RESULT_PAY)
        render(Keys.DETAILS)
        render(Keys.PAYMENTS_METHODS)
    }

    function transferHandler(transfer: string, type: string, name?: string) {
        storagePage.transfer.push({
            sub_payment_type_id: type ? Number(type) : null,
            amount: Number(transfer || '0'),
            sub_payment_name: name
        })
        render(Keys.TABLE_PAYMENTS)
        render(Keys.RESULT_PAY)
        render(Keys.DETAILS)
        render(Keys.PAYMENTS_METHODS)
    }

    return {
        cashHandler,
        cardHandler,
        transferHandler,
        disabledButton: storagePage.type === 'free'
    }
}
