export default function Description(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 0.5H2.5C1.125 0.5 0 1.625 0 3V23C0 24.375 1.1125 25.5 2.4875 25.5H17.5C18.875 25.5 20 24.375 20 23V8L12.5 0.5ZM15 13H5V15.5H15V13ZM15 18H5V20.5H15V18ZM2.5 23H17.5V9.25H11.25V3H2.5V23Z"
            />
        </svg>
    )
}
