import { Box, Button, Text } from '@chakra-ui/react'
import { ModalContentProps } from '../../../../lib/modal/types'
import { formItem } from '../../storage'
import Counter from './Counter'
import Forms from './Forms'

export default function Confirm(props: ModalContentProps) {
    const { currentQuantity, name } = formItem.store()

    return (
        <>
            <Box
                d={{ base: 'flex', lg: 'none' }}
                mb="5"
                justifyContent="space-between"
            >
                <Button
                    h="40px"
                    w="100px"
                    border="1px solid #434343"
                    color="#434343"
                    bg="white"
                    _hover={{}}
                    onClick={props.onClose}
                >
                    Cancelar
                </Button>
                <Button
                    h="40px"
                    w="100px"
                    color="white"
                    bg="#44C281"
                    _hover={{}}
                    type="submit"
                >
                    Confirmar
                </Button>
            </Box>
            <Text
                fontSize="2xl"
                fontWeight="bold"
                textAlign={{ base: 'left', lg: 'center' }}
                mb="2"
            >
                {name}
            </Text>
            <Text
                fontSize="sm"
                textAlign={{ base: 'left', lg: 'center' }}
                mb="8"
            >
                Cantidad actual: {currentQuantity}
            </Text>
            <Counter />
            <Forms />
            <Box
                w="full"
                mt="5"
                justifyContent="space-between"
                d={{ base: 'none', lg: 'flex' }}
            >
                <Button
                    h="60px"
                    w="180px"
                    border="1px solid #434343"
                    color="#434343"
                    bg="white"
                    _hover={{}}
                    onClick={props.onClose}
                >
                    Cancelar
                </Button>
                <Button
                    h="60px"
                    w="180px"
                    color="white"
                    bg="orange"
                    _hover={{}}
                    type="submit"
                >
                    Confirmar
                </Button>
            </Box>
        </>
    )
}
