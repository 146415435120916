import { Icon, Td, Text, Tr } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import ArrowUp from '../../../../components/Icon/ArrowUp'
import Description from '../../../../components/Icon/Description'
import colors from '../../../../config/theme/colors'
import List from '../../../../lib/list'
import Warehouse from '../../../../models/warehouse'
import { listKardex } from '../../storage'
import Actions from './Actions'
import ModalDetails from './ModalDetails'

export default function KardexSection({
    warehouses
}: {
    warehouses: Warehouse[]
}) {
    useEffect(() => {
        listKardex.setQuery('date', dayjs().format('YYYY-MM-DD'))
        listKardex.load()
    }, [])

    const getDate = () =>
        listKardex.getQuery('date')
            ? dayjs(listKardex.getQuery('date') as string, 'YYYY-MM-DD').format(
                  'DD/MM/YYYY'
              )
            : '-'

    return (
        <>
            <Actions warehouses={warehouses} />
            <List
                control={listKardex}
                tbody={operation => (
                    <Tr key={operation.id}>
                        <Td textAlign="center">{getDate()}</Td>
                        <Td textAlign="center">
                            {operation.inventory_item.name}
                        </Td>
                        <Td textAlign="center">{operation.warehouse.name}</Td>
                        <Td textAlign="center">
                            {operation.inventory_item.type_measure.name}
                        </Td>
                        <Td textAlign="center">{operation.stock_start}</Td>
                        <Td textAlign="center" align="center">
                            <Text as="span" mr={1} fontSize="sm">
                                {operation.daily_inputs}
                            </Text>
                            <Icon fill={colors['alo-green']} as={ArrowUp} />
                        </Td>
                        <Td textAlign="center">
                            <Text as="span" fontSize="sm" mr={1}>
                                {operation.daily_outputs}
                            </Text>
                            <Icon
                                fill={colors['alo-red']}
                                as={ArrowUp}
                                transform="rotate(180deg)"
                            />
                        </Td>
                        <Td textAlign="center">{operation.stock_end}</Td>
                        <Td textAlign="center">
                            <ModalDetails
                                date={getDate()}
                                dailySummaryOperation={operation}
                                element={click => (
                                    <Icon
                                        fill={colors['alo-blue']}
                                        as={Description}
                                        marginRight={2}
                                        cursor="pointer"
                                        onClick={click}
                                    />
                                )}
                            />
                        </Td>
                    </Tr>
                )}
            />
        </>
    )
}
