import useRunway from '../../../hooks/useRunway'
import { storagePage } from '../storage'

export default function useSelectedItems() {
    const { selectedItems, saveSelectedItems } = useRunway()
    const subtotal = selectedItems
        .map(selected =>
            selected.items.reduce((a, b) => {
                /*
                YA NO ES NECEARIO CALCULAR EL PRECIO DE LOS FEATURES, EL ITEM.PRICE YA LO INCLUYE
                let restCost = 0
                if (b.feature_settings) {
                    restCost = b.feature_settings.reduce(
                        (c, d) =>
                            c +
                            d.feature_setting_sub_features.reduce(
                                (e, f) => e + f.price * f.quantity,
                                0
                            ),
                        0
                    )
                }
                return a + (b.price + restCost) * b.quantity
                */
                return a + b.price * b.quantity
            }, 0)
        )
        .reduce((a, b) => a + b, 0)

    const discount =
        storagePage.typeDiscount === 'percentage'
            ? (subtotal / 100) * storagePage.discount
            : storagePage.discount
    const total = Number((subtotal - discount).toFixed(2))

    const valuePercentDiscount = storagePage.discount
    const totalCredit = storagePage.creditFees.reduce(
        (acc, curr) => acc + Number(curr.amount),
        0
    )

    return {
        subtotal,
        discount,
        total,
        totalCredit,
        valuePercentDiscount,
        typeDiscount: storagePage.typeDiscount,
        selectedItems,
        saveSelectedItems,
        deliveryPrice: storagePage.deliveryPrice
    }
}
