import Sidebar from '../../components/Sidebar'
import { Flex } from '@chakra-ui/react'
import SimpleNav from '../../components/SimpleNav'
import useStorage from '../../hooks/useStorage'

export default function Dashboard() {
    const { business_variables } = useStorage()

    const url_dashboard_datastudio = business_variables.find(
        v => v.code === '004'
    )?.value

    return (
        <>
            <Sidebar>
                <SimpleNav title="Dashboard" />
                <Flex h="calc(100vh - 140px)">
                    <iframe
                        width="100%"
                        height="fit-content"
                        src={url_dashboard_datastudio}
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </Flex>
            </Sidebar>
        </>
    )
}
