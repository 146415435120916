import dayjs from 'dayjs'
import parseError from './parseError'
import { expectIsAPIFake, getPathWithoutParams, parsePath } from './tools'
import { Result, Method } from './type'

export default function build(
    headers: Headers,
    signals: Map<string, AbortController>
) {
    async function http<T>(
        path: string,
        method: Method,
        body?: Record<string, any> | FormData
    ): Promise<Result<T>> {
        headers.delete('Content-Type')
        if (!(body instanceof FormData)) {
            headers.append('Content-Type', 'application/json')
        }

        const httpInit: RequestInit = {
            headers
        }

        const controller = new AbortController()

        signals.set(getPathWithoutParams(path), controller)

        const parsedPath = parsePath(path)
        const expect = expectIsAPIFake(parsedPath)
        const init: RequestInit = {
            ...httpInit,
            method,
            signal: controller.signal,
            ...(body && {
                body: body instanceof FormData ? body : JSON.stringify(body)
            })
        }

        const base = expect.isFake ? http.baseFake : http.baseUrl
        try {
            http.lastActivity = dayjs()
            const response = await fetch(`${base}${expect.path}`, init)
            const data = await response.json()

            if (response.status >= 400) {
                throw data
            }

            return { error: null, data }
        } catch (error) {
            return {
                error: parseError(error),
                data: null as any
            }
        }
    }

    http.baseUrl = ''
    http.baseFake = ''
    http.lastActivity = dayjs()

    return http
}
