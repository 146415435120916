import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import { Tr, Td, Button, Flex, Text, Badge } from '@chakra-ui/react'
import List from '../../lib/list'
import { controlList } from './storage'
import useInitial from './useInitial'
import Filters from './Filters'
import ModalView from '../Sales/ModalContents/ModalView'
import TableActions from './TableActions'
//import useStorage from '../../hooks/useStorage'
import ModalEmitMultiple from './ModalContents/ModalEmitMultiple'
import dayjs from 'dayjs'
import getFullNameClient from '../../utilities/getFullNameClient'

export default function PendingAccounts() {
    //const { business_variables } = useStorage()

    //const generate_electronic_receipts = business_variables.find(
    //    v => v.code === '002'
    //)?.value

    useInitial()
    return (
        <Sidebar>
            <SimpleNav title="Cuentas por cobrar" />
            <Flex
                marginBottom="5"
                gridGap={4}
                justifyContent="space-between"
                direction={{ base: 'column', md: 'row' }}
            >
                <Filters />
                <ModalEmitMultiple
                    element={click => (
                        <Button variant="alo-blue:md-xl" onClick={click}>
                            Emitir comprobante de múltiples notas
                        </Button>
                    )}
                />
            </Flex>

            <List
                control={controlList}
                tbody={(creditOrder, e) => (
                    <Tr key={e}>
                        <Td>{e}</Td>
                        <Td>
                            <ModalView
                                orderPaymentId={creditOrder.order_payment_id}
                                //Falta abrir modal para comprobantes a crédito generados por el Facturador
                                element={click => (
                                    <Text
                                        textDecoration="underline"
                                        cursor="pointer"
                                        whiteSpace="nowrap"
                                        onClick={click}
                                        color="alo-blue"
                                        fontFamily="Poppins-Semibold"
                                        pointerEvents={
                                            creditOrder.order_payment_id
                                                ? 'auto'
                                                : 'none'
                                        }
                                        //opacity={isPending ? '1' : '.6'}
                                    >
                                        {dayjs(creditOrder.created_at).format(
                                            'DD/MM/YYYY HH:mm'
                                        )}
                                    </Text>
                                )}
                            />
                        </Td>
                        <Td>{creditOrder.due_date}</Td>
                        <Td>{creditOrder.user}</Td>
                        <Td>{creditOrder.code}</Td>
                        <Td>
                            <Text width="60">
                                {getFullNameClient(creditOrder.client).fullName}
                            </Text>
                            <Text fontSize="xs" whiteSpace="nowrap">
                                {
                                    getFullNameClient(creditOrder.client)
                                        .fullNumber
                                }
                            </Text>
                        </Td>
                        <Td whiteSpace="nowrap">
                            S/ {creditOrder.credit_debt.toFixed(2)}
                        </Td>
                        <Td whiteSpace="nowrap">
                            S/ {creditOrder.balance_due.toFixed(2)}
                        </Td>
                        <Td>
                            <Badge
                                variant="solid"
                                colorScheme={
                                    creditOrder.status == 'pagado'
                                        ? 'green'
                                        : creditOrder.status == 'anulado'
                                        ? 'red'
                                        : 'blue'
                                }
                            >
                                {creditOrder.status}
                            </Badge>
                        </Td>
                        <Td>
                            <TableActions
                                creditOrder={creditOrder}
                                //generate_electronic_receipts={
                                //    generate_electronic_receipts
                                //}
                            />
                        </Td>
                    </Tr>
                )}
            />
        </Sidebar>
    )
}
