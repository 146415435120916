export default function CircleClose(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.821 0C9.531 0 .406 8.94.406 20s9.126 20 20.415 20c11.29 0 20.415-8.94 20.415-20S32.111 0 20.821 0zm0 36C11.818 36 4.49 28.82 4.49 20S11.82 4 20.821 4c9.003 0 16.332 7.18 16.332 16s-7.329 16-16.332 16zm0-18.82l7.33-7.18 2.878 2.82-7.33 7.18 7.33 7.18L28.15 30l-7.329-7.18L13.492 30l-2.878-2.82L17.943 20l-7.33-7.18 2.88-2.82 7.328 7.18z"
            />
        </svg>
    )
}
