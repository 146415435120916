import { FormEvent } from 'react'
import { render, renders } from 'redity'
import { close } from '../../../lib/modal'
import val from '../../../lib/val'
import { SelectedItem } from '../../../types/runway_state'
import Keys from '../keys'
import { formItem, storagePage } from '../storage'
import useSession from '../../../hooks/useSession'

export default function useSubmitModalConfirm() {
    const { session } = useSession()
    function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const v = val(formItem.store())
        v.price.isEmpty('Es requerido ingresar el precio')
        const result = v.runtest()
        if (result) {
            formItem.setHelpers(result)
            renders(formItem.KeyMain)
        } else {
            const selected = storagePage.selectedItems.find(
                _selected =>
                    _selected.category_item_id ===
                    storagePage.modalUtils.category_item_id
            ) as SelectedItem

            const item = selected.items.find(
                _item => _item.id === storagePage.modalUtils.item_id
            ) as SelectedItem['items'][number]

            const { price, quantity, description } = formItem.store()
            item.price = Number(Number(price).toFixed(2))
            item.quantity = quantity
            item.description = description
            item.user_price_edition = session ? session.user.id : 0
            render(Keys.DETAILS_LIST_ITEMS)
            render(Keys.CONTENT_BOTTOM)
            render(Keys.LIST_ITEM)
            close()
        }
    }

    return submitHandler
}
