import { Box, Button } from '@chakra-ui/react'
import colors from '../../config/theme/colors'
import { storagePage } from './storage'
import { useRender } from 'redity'
import Keys from './keys'

export default function ButtonSeeOrder({ onClick }: { onClick: () => void }) {
    useRender(Keys.BUTTON_SEE_ORDER)

    const total = storagePage.selectedItems.reduce(
        (a, selected) =>
            a + selected.items.reduce((b, item) => b + item.quantity, 0),
        0
    )

    return (
        <Box d={{ base: 'flex', lg: 'none' }} p={5}>
            <Button
                w="100%"
                bg={colors.dark}
                color={colors.white}
                _hover={{
                    background: colors['gray-3']
                }}
                borderRadius={20}
                onClick={onClick}
            >
                Ver pedido ({total})
            </Button>
        </Box>
    )
}
