export default function Block(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.00033 0.667969C4.40033 0.667969 0.666992 4.4013 0.666992 9.0013C0.666992 13.6013 4.40033 17.3346 9.00033 17.3346C13.6003 17.3346 17.3337 13.6013 17.3337 9.0013C17.3337 4.4013 13.6003 0.667969 9.00033 0.667969ZM2.33366 9.0013C2.33366 5.31797 5.31699 2.33464 9.00033 2.33464C10.542 2.33464 11.9587 2.85964 13.0837 3.74297L3.74199 13.0846C2.85866 11.9596 2.33366 10.543 2.33366 9.0013ZM4.91699 14.2596C6.04199 15.143 7.45866 15.668 9.00033 15.668C12.6837 15.668 15.667 12.6846 15.667 9.0013C15.667 7.45964 15.142 6.04297 14.2587 4.91797L4.91699 14.2596Z"
            />
        </svg>
    )
}
