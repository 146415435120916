import { Flex, Input, Select } from '@chakra-ui/react'
import Clean from '../../components/Clean'
import useSession from '../../hooks/useSession'
import useStorage from '../../hooks/useStorage'
import { controlList } from './storage'

export default function Filters() {
    return (
        <Flex mb="12" justifyContent="flex-end">
            <SearchField />
        </Flex>
    )
}

function SearchField() {
    const { clear, ...props } = controlList.useSearch()
    const { isAdmin } = useSession()

    return (
        <>
            {isAdmin() && <SelectField />}
            <Clean value={props.value} onClick={clear}>
                <Input
                    {...props}
                    w={{ base: 'auto', md: '96' }}
                    ml="2"
                    placeholder="🏸 Buscar por nombre"
                    borderRadius="md"
                />
            </Clean>
        </>
    )
}

export function SelectField() {
    const { cashboxes } = useStorage()
    const props = controlList.useFilter('cashbox_id')

    return (
        <Select
            {...props}
            placeholder="Seleccionar caja"
            bg="base-4"
            w="56"
            borderRadius="full"
        >
            {cashboxes.map(cashbox => (
                <option value={cashbox.id} key={cashbox.id}>
                    {cashbox.name}
                </option>
            ))}
        </Select>
    )
}
