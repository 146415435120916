import dayjs from 'dayjs'
import Booking from '../../../models/booking'
import { TYPE_BOOKINGS_VALUE } from '../storage'

export default function getInformationOfBooking(booking: Booking) {
    let agency = null
    let text = ''
    let textTotal = ''
    const hour = dayjs(booking.hour).format('hh:mm A')

    switch (booking.type) {
        case TYPE_BOOKINGS_VALUE.DRIVER:
            text = booking.driver?.name ?? ''
            textTotal = text + ' - ' + hour
            break
        case TYPE_BOOKINGS_VALUE.AGENCY:
            agency = booking.agency?.name
            text = booking.agency_guide?.name ?? ''
            textTotal = agency + ' - ' + text + ' - ' + hour
            break
        case TYPE_BOOKINGS_VALUE.CLIENT:
            text = booking.client_name ?? ''
            textTotal = text + ' - ' + hour
            break
    }

    return {
        agency: agency,
        text: text,
        textTotal: textTotal,
        hour: hour
    }
}
