import { Box } from '@chakra-ui/react'

export default function LabelStates() {
    return (
        <>
            <Box
                rounded="xl"
                color="white"
                px={5}
                mx="2px"
                bg="gray-3"
                textAlign={'center'}
                h={6}
            >
                <Box display={{ base: 'inline', md: 'none' }}>Disp.</Box>
                <Box display={{ base: 'none', md: 'inline' }}>Disponible</Box>
            </Box>
            <Box
                rounded="xl"
                color="white"
                px={5}
                mx="2px"
                h={6}
                bg="primary"
                textAlign={'center'}
            >
                <Box display={{ base: 'inline', md: 'none' }}>Ocup.</Box>
                <Box display={{ base: 'none', md: 'inline' }}>Ocupada</Box>
            </Box>
        </>
    )
}
