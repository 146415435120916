import { ThemingProps } from '@chakra-ui/react'
import { DeliveryStatus } from '../../types/deliveryMode'

export function getStatusNames(status: DeliveryStatus) {
    switch (status) {
        case DeliveryStatus.PENDING:
            return 'Pendiente'
        case DeliveryStatus.CANCELED:
            return 'Anulado'
        case DeliveryStatus.DELIVERED:
            return 'Entregado'
        case DeliveryStatus.IN_PREPARATION:
            return 'En preparación'
        case DeliveryStatus.ON_THE_WAY:
            return 'En camino'
        case DeliveryStatus.CLOSED:
            return 'Cerrado'
        default:
            return 'Cerrado'
    }
}

export function getNextNameStatus(status: DeliveryStatus) {
    switch (status) {
        case DeliveryStatus.PENDING:
            return 'Enviar a preparación'
        case DeliveryStatus.IN_PREPARATION:
            return 'Enviar delivery'
        case DeliveryStatus.ON_THE_WAY:
            return 'Entregado'
        case DeliveryStatus.DELIVERED:
            return 'Cerrar'
        case DeliveryStatus.CANCELED:
            return 'Anulado'
        default:
            return 'Cerrado'
    }
}

export function getColorStatus(
    status: DeliveryStatus
): ThemingProps<'Progress'>['colorScheme'] {
    if (status === DeliveryStatus.PENDING) {
        return 'blue'
    }

    if (status === DeliveryStatus.IN_PREPARATION) {
        return 'orange'
    }

    if (status === DeliveryStatus.ON_THE_WAY) {
        return 'red'
    }

    if (status === DeliveryStatus.DELIVERED) {
        return 'green'
    }

    if (status === DeliveryStatus.CLOSED) {
        return 'gray'
    }

    return 'red'
}
