import useTab from '../../hooks/useTab'
import { TABS_VALUE } from './storage'

export default function useControl() {
    const props = useTab('booking')

    function openModal() {
        if (props.value === TABS_VALUE.BOOKING) {
        }
    }

    function getTextButton() {
        switch (props.value) {
            case TABS_VALUE.BOOKING:
                return 'Nueva reserva'
            default:
                return 'Nuevo destino de preparación'
        }
    }

    return {
        openModal,
        getTextButton,
        tabProps: props
    }
}
