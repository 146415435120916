import FormEvent from '../../lib/form_event'
import ControlList from '../../lib/list/ControlList'
import {
    FormMovement,
    FormOpenCashbox,
    ListHistoryData,
    StoragePage
} from './types'

export const storagePage: StoragePage = {
    cashboxUser: null,
    currentCashbox: null, // Caja actual seleccionada
    userSessionHaveOpenBox: false,
    isAdmin: false,
    isYourBox: false,
    denominationMoneys: [],
    cashCount: null,
    openingStartDate: '',
    openingDates: [],
    cashboxMovements: []
}

export const formOpenCashbox = new FormEvent<FormOpenCashbox>({
    cashbox_id: '',
    cashbox_name: '',
    amount: ''
})

export const formCloseCashbox = new FormEvent({
    password: ''
})

export const formMovement = new FormEvent<FormMovement>({
    type: '',
    concept: '',
    amount: '',
    comment: '',
    money: ''
})

export const listHistory = new ControlList<ListHistoryData>({
    path: '/api/cashbox_users/user',
    theads: [
        'Apertura',
        'Cierre',
        'Caja',
        'Cajero',
        'Estado',
        'Monto de apertura',
        'Imprimir'
    ]
})
