import { Flex, Input } from '@chakra-ui/react'
import Clean from '../../../components/Clean'
import { listDriver } from '../storage'

export default function FiltersDriver() {
    const { clear, ...props } = listDriver.useSearch()

    return (
        <Flex>
            <Clean value={props.value} onClick={clear}>
                <Input
                    w="80"
                    placeholder="🏸 Buscar por nombre por guía local"
                    {...props}
                />
            </Clean>
        </Flex>
    )
}
