import { Box, Button, Divider, Flex, Heading } from '@chakra-ui/react'
import colors from '../../../../../config/theme/colors'
import { Section2Props } from '../types'
import Buttons from './Buttons'
import Counter from './Counter'
import ListDetails from './ListDetails'
import Total from './Total'

export default function Section2({
    onClose,
    counter,
    setCounter,
    featuresSelected,
    clearFeatures,
    delFeature,
    basePrice,
    isEdition,
    itemName,
    onInformation
}: Section2Props) {
    return (
        <Box
            w={{
                base: '100%',
                md: '50%'
            }}
            p="8"
        >
            <Box>
                <Flex align="center" justifyContent="space-between" mb={3}>
                    <Heading fontSize="xl">Detalle de pedido</Heading>
                    <Button
                        variant="outline"
                        colorScheme="red"
                        size="xs"
                        borderRadius="full"
                        onClick={clearFeatures}
                    >
                        Limpiar pedido
                    </Button>
                </Flex>
            </Box>
            <Divider />
            <Flex
                mt={4}
                mb={4}
                borderRadius="lg"
                bg={colors['base-2']}
                border="1px solid"
                borderColor={colors.dark}
                px={4}
                py={5}
                minH="md"
                flexDirection="column"
                justifyContent="space-between"
            >
                <Box>
                    <Heading size="sm" textAlign="center" mb={8}>
                        {itemName} S/ {basePrice.toFixed(2)}
                    </Heading>
                    <Counter counter={counter} setCounter={setCounter} />
                    <ListDetails
                        delFeature={delFeature}
                        featuresSelected={featuresSelected}
                        onInformation={onInformation}
                    />
                </Box>
                <Total
                    total={(
                        featuresSelected.reduce(
                            (a, b) =>
                                a + b.subs.reduce((c, d) => c + d.price, 0),
                            0
                        ) + basePrice
                    ).toFixed(2)}
                />
            </Flex>
            <Buttons onClose={onClose} isEdition={isEdition} />
        </Box>
    )
}
