import { Button, Flex, Select, Box } from '@chakra-ui/react'
import { useState } from 'react'
import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import Tabs, { Tab } from '../../components/Tabs'
import Booking from './Booking'
import DateBooking from './Booking/DateBooking'
import FiltersBooking from './Booking/FiltersBooking'
import ModalTypeBooking from './Booking/ModalTypeBooking'
import { TABS_VALUE } from './storage'
import useControl from './useControl'
import useInitial from './useInitial'

export default function Bookings() {
    useInitial()
    const { tabProps, openModal, getTextButton } = useControl()
    const [openModalTypeBooking, setOpenModalTypeBooking] = useState(false)

    const handleOpenModal = () => {
        if (tabProps.value === TABS_VALUE.BOOKING) {
            setOpenModalTypeBooking(true)
        } else {
            openModal()
        }
    }

    return (
        <Sidebar>
            <SimpleNav title="Reservas" />
            <Tabs {...tabProps}>
                <Tab value={TABS_VALUE.BOOKING}>Listado de reservas</Tab>
            </Tabs>
            <Flex
                justifyContent="space-between"
                display={{ base: 'flex', md: 'none' }}
            >
                <Select
                    w="200px"
                    value={tabProps.value}
                    onChange={e => tabProps.onTab(e.target.value)}
                >
                    <option value={TABS_VALUE.BOOKING}>
                        Listado de reservas
                    </option>
                </Select>
                <Button variant="alo-blue:md-xl" onClick={handleOpenModal}>
                    + Nuevo
                </Button>
            </Flex>
            <Flex
                mt={4}
                mb={4}
                w={{
                    xl: 'auto',
                    lg: tabProps.value === 'item' ? 'full' : 'auto'
                }}
                justifyContent={{
                    md:
                        tabProps.value === 'category'
                            ? 'flex-end'
                            : 'space-between',
                    base: 'flex-end'
                }}
                flexDirection={{
                    base: 'column',
                    md: 'row'
                }}
                gridGap={3}
            >
                {tabProps.value === TABS_VALUE.BOOKING && (
                    <>
                        <DateBooking type={2} />
                        <FiltersBooking />
                    </>
                )}

                <Box display="flex" gridGap={3}>
                    {tabProps.value === TABS_VALUE.BOOKING && (
                        <DateBooking type={1} />
                    )}
                    <Button
                        variant="alo-blue:md-xl"
                        onClick={handleOpenModal}
                        display={{ base: 'none', md: 'inline' }}
                        ml="2"
                    >
                        {getTextButton()}
                    </Button>
                </Box>
            </Flex>
            {tabProps.value === TABS_VALUE.BOOKING && <Booking />}
            {openModalTypeBooking ? (
                <ModalTypeBooking
                    onClose={() => setOpenModalTypeBooking(false)}
                    isOpen={openModalTypeBooking}
                />
            ) : null}
        </Sidebar>
    )
}
