import { useToast } from '@chakra-ui/react'
import { FormEvent, useState } from 'react'
import { post, put } from '../../../lib/http'
import val from '../../../lib/val'
import extractDataForm from '../../../utilities/extractDataForm'
import { SubmitDataProduct } from './types'

export default function useSubmitProduct(
    done: () => void,
    inventoryItemId?: number
) {
    const [fieldNameMessage, setFieldNameMessage] = useState('')
    const [fieldUnitMessage, setFieldUnitMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()

    async function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const form = extractDataForm<SubmitDataProduct>(ev.target)
        const v = val(form)
        v.name.isEmpty('El nombre es requerido')
        v.type_measure_id.isEmpty('La unidad es requerida')
        const result = v.runtest()
        if (result) {
            setFieldNameMessage(result.name || '')
            setFieldUnitMessage(result.type_measure_id || '')
            return
        }
        setIsLoading(true)
        const send = inventoryItemId ? put : post
        const { error } = await send(
            `/api/inventory_items/${inventoryItemId ? inventoryItemId : ''}`,
            {
                name: form.name,
                type_measure_id: +form.type_measure_id
            }
        )
        if (error) {
            if (
                error.type === 'customer' &&
                (error.errors.name || error.errors.type_measure_id)
            ) {
                setFieldNameMessage(error.errors.name || '')
                setFieldUnitMessage(error.errors.type_measure_id || '')
                setIsLoading(false)
                return
            }
            toast({
                title: error.message,
                position: 'top',
                status: 'error'
            })
        } else {
            toast({
                title: inventoryItemId
                    ? 'Se ga actualizado el producto'
                    : 'Se ha guardado el nuevo producto',
                position: 'top',
                status: 'success'
            })
            done()
        }
        setIsLoading(false)
    }

    return {
        submitHandler,
        fieldNameMessage,
        setFieldNameMessage,
        fieldUnitMessage,
        setFieldUnitMessage,
        isLoading
    }
}
