import { SVGProps } from 'react'

const Dns = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 23 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.5 0.75H1.5C0.8125 0.75 0.25 1.3125 0.25 2V9.5C0.25 10.1875 0.8125 10.75 1.5 10.75H21.5C22.1875 10.75 22.75 10.1875 22.75 9.5V2C22.75 1.3125 22.1875 0.75 21.5 0.75ZM2.75 8.25H20.25V3.25H2.75V8.25ZM1.5 13.25H21.5C22.1875 13.25 22.75 13.8125 22.75 14.5V22C22.75 22.6875 22.1875 23.25 21.5 23.25H1.5C0.8125 23.25 0.25 22.6875 0.25 22V14.5C0.25 13.8125 0.8125 13.25 1.5 13.25ZM20.25 15.75H2.75V20.75H20.25V15.75ZM5.25 20.125C4.225 20.125 3.375 19.2875 3.375 18.25C3.375 17.2125 4.225 16.375 5.25 16.375C6.275 16.375 7.125 17.2125 7.125 18.25C7.125 19.2875 6.2875 20.125 5.25 20.125ZM3.375 5.75C3.375 6.7875 4.225 7.625 5.25 7.625C6.2875 7.625 7.125 6.775 7.125 5.75C7.125 4.725 6.275 3.875 5.25 3.875C4.225 3.875 3.375 4.7125 3.375 5.75Z"
        />
    </svg>
)

export default Dns
