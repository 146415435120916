import { useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { ChangeEvent, FormEvent, useState } from 'react'
import useSession from '../../hooks/useSession'
import { post } from '../../lib/http'
import val from '../../lib/val'

export default function useAnullWithCreditNote(
    sunat_voucher_id: number,
    onDone: () => void
) {
    const [isOpen, setOpen] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [helpers, setHelpers] = useState<{ motivo?: string }>({})
    const toast = useToast()
    const { isLogged, session } = useSession()

    async function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const formElem = ev.target as typeof ev.target & {
            motivo: { value: string }
        }

        const v = val({
            motivo: formElem.motivo.value
        })

        v.motivo.isEmpty('Es requerido escribir el motivo')

        const result = v.runtest()
        if (result) {
            setHelpers(result)
            return
        }

        setLoading(true)
        const user_id = isLogged && session !== null ? session.user.id : null
        const { error } = await post<any>(
            '/api/sunat_vouchers/send_credit_note',
            {
                sunat_voucher_id,
                user_id_cancel: user_id,
                issue_date: dayjs().format(),
                motivo: formElem.motivo.value
            }
        )
        if (error) {
            toast({
                title: error.message,
                status: 'error',
                position: 'top'
            })
            setOpen(false)
        } else {
            toast({
                title: 'Venta anulada',
                status: 'success',
                position: 'top',
                isClosable: true
            })
            setOpen(false)
        }
        onDone()
        setLoading(false)
    }

    function changeHandler(
        ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const name = ev.target.name as keyof typeof helpers
        if (helpers[name]) {
            setHelpers({
                ...helpers,
                [name]: ''
            })
        }
    }

    return {
        isOpen,
        setOpen,
        submitHandler,
        isLoading,
        helpers,
        setHelpers,
        changeHandler
    }
}

// interface ResultGenerateCreditNote {
//     result: { xml_name: string }
//     status: number
//     message: string
// }
