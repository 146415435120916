import { Box, Flex } from '@chakra-ui/react'

import Dates from './Dates'
import Download from './Download'

export default function Actions() {
    return (
        <Box mb="5">
            <Flex
                justifyContent={{ base: 'flex-end', xl: 'space-between' }}
                mb="5"
                gridGap={5}
                direction={{ base: 'column', md: 'row' }}
            >
                <Dates />
                <Box display={{ base: 'none', xl: 'block' }}>
                    <Download />
                </Box>
            </Flex>
            <Flex justify="flex-end">
                <Box display={{ base: 'block', xl: 'none' }}>
                    <Download />
                </Box>
            </Flex>
        </Box>
    )
}
