import { Box, Center, Text } from '@chakra-ui/react'
import colors from '../../../../../config/theme/colors'
import { BoxItemProps } from '../types'

export default function BoxItem({
    children,
    isActive,
    onClick = () => null,
    price = 0,
    quantity = 0,
    hasStockControl = false,
    currentStock = 0
}: BoxItemProps) {
    return (
        <Center
            h="70px"
            fontSize="xs"
            border="1px solid"
            borderRadius="lg"
            onClick={onClick}
            textAlign="center"
            borderColor={colors.dark}
            px="1"
            bg={isActive ? colors.dark : colors.white}
            color={isActive ? colors.white : colors.dark}
            cursor={quantity === 1 && isActive ? 'auto' : 'pointer'}
            pointerEvents={
                hasStockControl && currentStock <= 0 ? 'none' : 'auto'
            }
        >
            <Box>
                <Text>{children}</Text>
                {price ? <Text>S/{price.toFixed(2)}</Text> : ''}
                {hasStockControl && currentStock <= 0 && (
                    <Text color={colors['alo-red']} fontWeight="bold">
                        Agotado
                    </Text>
                )}
            </Box>
        </Center>
    )
}
