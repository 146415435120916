import { Box } from '@chakra-ui/react'
import { createContext, ReactNode, useContext } from 'react'

const Context = createContext<ContextValues>({
    value: '',
    onTab: () => null
})

interface ContextValues {
    value: string
    onTab: (value: string) => void
}

export default function Tabs({ children, value, onTab }: TabsProps) {
    return (
        <Context.Provider value={{ value, onTab }}>
            <Box display="flex">{children}</Box>
        </Context.Provider>
    )
}

export function Tab({
    children,
    value
}: {
    value: string
    children: ReactNode
}) {
    const { value: currentValue, onTab } = useContext(Context)
    return (
        <Box
            textDecoration={value === currentValue ? 'underline' : 'none'}
            color={value === currentValue ? 'secondary' : 'dark'}
            marginRight={{
                xl: 8,
                lg: 4,
                md: 4,
                base: 4
            }}
            onClick={() => onTab(value)}
            fontWeight="bold"
            as="p"
            cursor="pointer"
            fontSize={{
                xl: 'xl',
                lg: 'lg'
            }}
            display={{ base: 'none', md: 'block' }}
        >
            {children}
        </Box>
    )
}

export interface TabsProps {
    children: ReactNode
    value: string
    onTab: (value: string) => void
}
