import { FormControl, FormLabel, Input, InputProps } from '@chakra-ui/react'
import ErrorHelper from '../../../components/ErrorHelper'

import { formDriver } from '../storage'

export default function FormDriver() {
    return (
        <>
            <Name />
        </>
    )
}

function Name() {
    const { props, helper, error } = formDriver.useField<InputProps>('name')
    return (
        <FormControl mb={4}>
            <FormLabel>Nombre</FormLabel>
            <Input
                {...props}
                placeholder="Ingresar el nombre por guía local"
                autoFocus
                maxLength={100}
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}
