import { Box, Heading, SimpleGrid, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useGet } from '../../../../hooks/useHttp'
import { TableData } from '../../../ByTables/types'
import { storagePage } from '../../storage'
import colors from '../../../../config/theme/colors'

export default function Tables({
    zoneId,
    tableId,
    setTableId
}: {
    zoneId: number
    tableId: number
    setTableId: (tableId: number) => void
}) {
    const { data, setQuery } = useGet<TableData[]>(
        '/api/tables?sort=name&is_enabled=true',
        []
    )

    useEffect(() => {
        setQuery('zone_id', zoneId)
    }, [zoneId])

    function handleClick(_tableId: number) {
        setTableId(_tableId)
        storagePage.table_move_id = _tableId
    }

    return (
        <Box h="full">
            {data.result.length === 0 && (
                <Text py="5" fontSize={'lg'}>
                    No hay mesas disponibles
                </Text>
            )}
            {data.result.length !== 0 && (
                <SimpleGrid spacing={3} columns={[2, 4]} py={5}>
                    {data.result.map(
                        table =>
                            storagePage.table_id != table.id && (
                                <Box
                                    key={table.id}
                                    cursor="pointer"
                                    borderRadius="lg"
                                    onClick={() => handleClick(table.id)}
                                    h="120px"
                                    bg={
                                        table.id !== tableId
                                            ? 'gray-3'
                                            : colors['alo-green']
                                    }
                                    p="4"
                                    color="white"
                                    textAlign="center"
                                    display="flex"
                                    alignItems={'center'}
                                    justifyContent="center"
                                >
                                    <Heading size="md" textAlign="center">
                                        {table.name}
                                    </Heading>
                                </Box>
                            )
                    )}
                </SimpleGrid>
            )}
        </Box>
    )
}
