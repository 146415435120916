import { Flex, Input, Text } from '@chakra-ui/react'
import { memo } from 'react'
import colors from '../../../config/theme/colors'
import { controlList } from '../storage'

function Dates() {
    const startDateProps = controlList.useFilter('start_date')
    const endDateProps = controlList.useFilter('end_date')

    return (
        <Flex alignItems="center">
            <Text fontSize="xs" color={colors['gray-3']}>
                De:
            </Text>
            <Input
                {...startDateProps}
                type="date"
                w="150px"
                max={endDateProps.value}
                ml={2}
                mr={4}
            />
            <Text fontSize="xs" color={colors['gray-3']}>
                Hasta:
            </Text>
            <Input
                {...endDateProps}
                type="date"
                w="150px"
                min={startDateProps.value}
                ml={2}
            />
        </Flex>
    )
}

export default memo(Dates)
