import { useState, useContext, useEffect, ChangeEvent } from 'react'
import CheckInContext from './context/CheckInContext'

export default function useModalPin(isOpen: boolean) {
    const [amount, setAmount] = useState('')
    const [numbers] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9])
    const { typePay, payments, setPayments, setOpenModalPay } =
        useContext(CheckInContext)

    const handleClear = () => {
        setAmount('')
    }

    useEffect(() => {
        if (isOpen) {
            handleClear()
        }
    }, [isOpen])

    const handleClickButton = (value: number | string) => {
        setAmount(amount + value)
    }

    const changeHandler = (ev: ChangeEvent<HTMLInputElement>) => {
        setAmount(ev.target.value)
    }

    const handleRemoveLastDigit = () => {
        if (amount.length !== 0) {
            setAmount(amount.slice(0, -1))
        }
    }

    const handleSubmit = () => {
        const payment = payments?.find(
            (_payment: any) => _payment.type === typePay
        )

        if (payment) {
            const newPayments = []
            for (const _payment of payments) {
                if (_payment.type === typePay) {
                    newPayments.push({
                        ..._payment,
                        amount: _payment.amount + Number(amount)
                    })
                } else {
                    newPayments.push(_payment)
                }
            }

            setPayments(newPayments)
        } else {
            const newPayments = [...payments]

            newPayments.push({
                type: typePay,
                amount: Number(amount)
            })
            setPayments(newPayments)
        }

        setOpenModalPay(false)
        handleClear()
    }
    const getTextsForModal = (_typePay: string) => {
        const dataForText: any = {
            Efectivo: {
                text: 'Monto en efectivo',
                subText: 'Ingresa la cantidad en efectivo'
            },
            Tarjeta: {
                text: 'Monto con tarjeta',
                subText: 'Ingresa la cantidad del monto'
            },
            Transferencia: {
                text: 'Monto por transferencia',
                subText: 'Ingresa la cantidad del monto'
            }
        }

        return {
            text: dataForText[_typePay]?.text || '-',
            subText: dataForText[_typePay]?.subText || '-'
        }
    }

    return {
        amount,
        numbers,
        handleSubmit,
        handleRemoveLastDigit,
        handleClickButton,
        handleClear,
        getTextsForModal,
        changeHandler
    }
}
