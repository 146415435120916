import { useEffect } from 'react'
import { controlList } from './storage'

export default function useInitial() {
    useEffect(() => {
        controlList.setQuery('order', 'desc')
        controlList.setQuery('sort', 'created_at')
        if (localStorage.getItem('all_sales') !== '1') {
            controlList.setQuery('with_vouchers', true)
        }
        controlList.load()
        return () => {
            controlList.deleteQuery('search')
            controlList.deleteQuery('cashbox_id')
            controlList.deleteQuery('with_vouchers')
        }
    }, [])

    function withVouchers(isSell: boolean) {
        controlList.setQuery('with_vouchers', !isSell)
        controlList.load()
    }

    return {
        withVouchers
    }
}
