import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { post, put } from '../../../lib/http'
import { storagePage } from '../storage'
import { CashboxUserData } from '../types'
import { DataSubmitSave, SubmitBody } from './types'

export default function useSubmitSave(
    data: DataSubmitSave,
    onDone: () => void
) {
    const toast = useToast()
    const [isLoading, setLoading] = useState(false)

    async function save() {
        const { cashboxUser, denominationMoneys, cashCount } = storagePage
        setLoading(true)

        function getQuantity(value: number, type: number) {
            if (type === 1) return data.banknotesValues[value].quantity
            if (type === 2) return data.coinsValues[value].quantity
            if (type === 3) return Number(data.cardValue)
            return Number(data.transferValue)
        }

        function getIdForCountDetail(denominationMoneyId: number) {
            if (cashCount) {
                return cashCount.cash_count_details.find(
                    ccd => ccd.denomination_money_id === denominationMoneyId
                )?.id
            }
            return undefined
        }

        const { id, cash, card, transfer, started_amount, income, expenses } =
            cashboxUser as CashboxUserData
        const totalSystem =
            cash + card + transfer + started_amount + (income - expenses)
        const surplus = data.totalManual - totalSystem
        const missing = totalSystem - data.totalManual
        const body: SubmitBody = {
            cashbox_user_id: id,
            surplus: Number((surplus <= 0 ? 0 : surplus).toFixed(2)),
            missing: Number((missing <= 0 ? 0 : missing).toFixed(2)),
            comment: data.commentary.trim() || null,
            total_system: totalSystem,
            total_manual: data.totalManual,
            cash_count_details: denominationMoneys.map(deno => ({
                denomination_money_id: deno.id,
                quantity: getQuantity(deno.value, deno.type),
                id: getIdForCountDetail(deno.id)
            }))
        }

        const send = cashCount ? put : post
        const path = cashCount
            ? `/api/cash_counts/${cashCount.id}`
            : '/api/cash_counts'
        const { error } = await send(path, body)
        if (error) {
            toast({
                title: error.message,
                position: 'top',
                status: 'error'
            })
        } else {
            onDone()
            toast({
                title: 'Arqueo guardado',
                position: 'top',
                status: 'success'
            })
        }
        setLoading(false)
    }

    return {
        save,
        isLoading
    }
}
