import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { memo } from 'react'
import TableRow from './TableRow'
import { TableCardControlProps } from './types'

function TableCardControl({
    card,
    systemCardAmount,
    setCard,
    transfer,
    systemTransferAmount,
    setTransfer
}: TableCardControlProps) {
    return (
        <Table size="sm">
            <Thead>
                <Tr>
                    <Th textAlign="center" colSpan={3}>
                        Control de tarjetas y transferencia
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                <Tr bg="#d0d9df">
                    <Th whiteSpace="nowrap">Modo</Th>
                    <Th whiteSpace="nowrap" textAlign="center">
                        Monto por sistema
                    </Th>
                    <Th whiteSpace="nowrap" textAlign="center">
                        Monto manual
                    </Th>
                </Tr>
                <TableRow
                    cardName="Tarjeta"
                    amount={card}
                    systemAmount={systemCardAmount}
                    onChange={setCard}
                />
                <TableRow
                    cardName="Transferencia"
                    amount={transfer}
                    systemAmount={systemTransferAmount}
                    onChange={setTransfer}
                />
                <Tr bg="gray.100">
                    <Th />
                    <Th textAlign="right">Total:</Th>
                    <Th textAlign="center">
                        S/{(Number(card) + Number(transfer)).toFixed(2)}
                    </Th>
                </Tr>
            </Tbody>
        </Table>
    )
}

export default memo(TableCardControl)
