import { Flex, Text, useDisclosure } from '@chakra-ui/react'
import useOrderByTable from '../../../../hooks/useOrderByTable'
import usePreOrder from '../../../../hooks/usePreOrder'
import ModalLcalGuide from '../../../../modals/ModalLocalGuide'
import ItemSelected from './ItemSelected'

export default function LocalGuide() {
    const { driver, setDriver } = usePreOrder()
    const { isModeEdition } = useOrderByTable()
    const { onOpen, onClose, isOpen } = useDisclosure()

    return (
        <>
            <ModalLcalGuide isOpen={isOpen} onClose={onClose} />
            <Flex gap={2} align="center">
                <Text whiteSpace="nowrap">Guía local</Text>
                {!driver && (
                    <Text
                        as="b"
                        color="dark-2"
                        cursor="pointer"
                        decoration={isModeEdition ? 'underline' : 'none'}
                        pointerEvents={isModeEdition ? 'auto' : 'none'}
                        onClick={onOpen}
                    >
                        Sin guía
                    </Text>
                )}
                {driver && (
                    <ItemSelected
                        enableClose={isModeEdition}
                        onClick={onOpen}
                        onRemove={() => setDriver(null)}
                    >
                        {driver.name}
                    </ItemSelected>
                )}
            </Flex>
        </>
    )
}
