import { Button } from '@chakra-ui/react'
import ChevronDown from '../../../components/Icon/ChevronDown'
import ChevronUp from '../../../components/Icon/ChevronUp'
import colors from '../../../config/theme/colors'

export default function ViewDetailButton({
    showDetail,
    setShowDetail
}: {
    showDetail: boolean
    setShowDetail: (showDetail: boolean) => void
}) {
    return (
        <Button
            size="sm"
            variant="link"
            onClick={() => setShowDetail(!showDetail)}
            color={colors.turquoise}
            rightIcon={
                showDetail ? (
                    <ChevronUp
                        fill={colors.turquoise}
                        height="1.5em"
                        width="1.5em"
                    />
                ) : (
                    <ChevronDown
                        fill={colors.turquoise}
                        height="1.5em"
                        width="1.5em"
                    />
                )
            }
            _focus={{}}
        >
            {showDetail ? 'Ocultar detalle' : 'Ver detalle'}
        </Button>
    )
}
