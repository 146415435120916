import { createContext } from 'react'
import CashboxUser from '../../../models/cashbox_user'

export interface CashboxContextInterface {
    cashboxUser: CashboxUser | null
    isLoading: boolean
    status: 'open' | 'closed' | 'loading'
}

const CashboxContext = createContext<CashboxContextInterface>({
    cashboxUser: null,
    isLoading: false,
    status: 'loading'
})

export default CashboxContext
