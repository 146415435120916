import { Input, Td, Tr } from '@chakra-ui/react'
import onlyNumber from '../../../../utilities/onlyNumber'
import { TableRowProps } from './types'

export default function TableRow({
    cardName,
    amount,
    systemAmount,
    onChange
}: TableRowProps) {
    return (
        <Tr>
            <Td>{cardName}</Td>
            <Td textAlign="center">S/{systemAmount.toFixed(2)}</Td>
            <Td p="0">
                <Input
                    size="sm"
                    textAlign="center"
                    borderTopWidth="0"
                    borderBottomWidth="0"
                    value={amount}
                    onChange={ev =>
                        onChange(onlyNumber(ev.target.value, { decimal: 2 }))
                    }
                />
            </Td>
        </Tr>
    )
}
