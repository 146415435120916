import {
    FormControl,
    FormLabel,
    Input,
    InputProps,
    Select,
    SelectProps
} from '@chakra-ui/react'
import { ChangeEvent } from 'react'
import { render } from 'redity'
import ErrorHelper from '../../../components/ErrorHelper'
import { formDestiny } from '../storage'

export default function FormDestiny() {
    return (
        <>
            <Name />
            <TypeConnection />
            <IP />
        </>
    )
}

function Name() {
    const { props, helper, error } = formDestiny.useField<InputProps>('name')
    return (
        <FormControl mb={4}>
            <FormLabel>Nombre</FormLabel>
            <Input
                {...props}
                placeholder="Ingresar el nombre del destino de preparación"
                autoFocus
                maxLength={50}
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function TypeConnection() {
    const { props, helper, error } =
        formDestiny.useField<SelectProps>('connectionType')

    function changeHandler(ev: ChangeEvent<HTMLSelectElement>) {
        props.onChange(ev)
        render(formDestiny.KeyMain, 'ip')
    }

    return (
        <FormControl mb={4}>
            <FormLabel>Tipo de conexión</FormLabel>
            <Select
                {...props}
                onChange={changeHandler}
                placeholder="Selecciona tipo de conexión"
            >
                <option value="1">Por IP</option>
                <option value="2">Por USB</option>
            </Select>
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function IP() {
    const { props, helper } = formDestiny.useField<InputProps>('ip')

    const { connectionType } = formDestiny.store()

    if (connectionType !== '1') {
        return <></>
    }

    return (
        <FormControl marginBottom="4">
            <FormLabel>IP</FormLabel>
            <Input
                {...props}
                placeholder="Ingresa la dirección IP"
                maxLength={15}
            />
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}
