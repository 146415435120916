import { Flex, SimpleGrid, Text } from '@chakra-ui/react'
import { useRender } from 'redity'
import { Keys } from '../constants'
import { storagePage } from '../storage'
import useSelectedItems from '../hooks/useSelectedItems'

export default function ResultPay() {
    useRender(Keys.RESULT_PAY)
    const { total } = useSelectedItems()

    const cash = storagePage.cash.reduce((acc, curr) => acc + curr.amount, 0)
    const card = storagePage.card.reduce((acc, curr) => acc + curr.amount, 0)
    const transfer = storagePage.transfer.reduce(
        (acc, curr) => acc + curr.amount,
        0
    )

    const accumulatedPayment = cash + card + transfer
    const accumulatedPaymentCredit = storagePage.creditFees.reduce(
        (acc, curr) => acc + Number(curr.amount),
        0
    )
    console.log(accumulatedPaymentCredit, '....')
    const lack = total - accumulatedPayment
    const turned = accumulatedPayment - total

    return (
        <SimpleGrid
            columns={{ base: 1, md: 3 }}
            spacingX={{ base: 0, md: 10 }}
            mt={{
                xl: 12,
                lg: 5,
                base: 3
            }}
        >
            <Flex
                flexDirection={{ base: 'row', md: 'column' }}
                justifyContent={'space-between'}
            >
                <Text fontSize={{ base: 'lg', md: 'xl' }}>Pago acumulado</Text>
                <Text
                    fontWeight="bold"
                    fontSize={{
                        xl: '4xl',
                        lg: '2xl'
                    }}
                    alignSelf={{ base: 'center', md: 'flex-start' }}
                >
                    S/ {accumulatedPayment.toFixed(2)}
                </Text>
            </Flex>
            <Flex
                flexDirection={{ base: 'row', md: 'column' }}
                justifyContent={'space-between'}
            >
                <Text fontSize={{ base: 'lg', md: 'xl' }}>Falta</Text>
                <Text
                    fontWeight="bold"
                    fontSize={{
                        xl: '4xl',
                        lg: '2xl'
                    }}
                    alignSelf={{ base: 'center', md: 'flex-start' }}
                >
                    S/ {lack < 0 ? '0.00' : lack.toFixed(2)}
                </Text>
            </Flex>
            <Flex
                flexDirection={{ base: 'row', md: 'column' }}
                justifyContent={'space-between'}
            >
                <Text fontSize={{ base: 'lg', md: 'xl' }}>Vuelto total</Text>
                <Text
                    fontWeight="bold"
                    fontSize={{
                        xl: '4xl',
                        lg: '2xl'
                    }}
                    alignSelf={{ base: 'center', md: 'flex-start' }}
                >
                    S/ {turned < 0 ? '0.00' : turned.toFixed(2)}
                </Text>
            </Flex>
        </SimpleGrid>
    )
}
