import { useToast } from '@chakra-ui/react'
import useStorage from '../../../hooks/useStorage'
import useSubmit from '../../../hooks/useSubmit'
import { get } from '../../../lib/http'
import modal from '../../../lib/modal'
import Item from '../../../models/item'
import { formItem, listItem } from '../storage'
import FormItem from './FormItem'
import { INVENTORY_TYPES } from './util'

export default function useItem(item?: Item) {
    const { business_variables } = useStorage()
    const isEnabledCommission =
        business_variables.find(bv => bv.code === '015')?.value === '1'

    const { validation, observer, submit } = useSubmit(formItem, {
        path: item ? `/api/items/${item.id}` : '/api/items',
        method: item ? 'put' : 'post',
        done: () => listItem.load()
    })

    const toast = useToast()

    validation((v, f) => {
        v.name.isEmpty('Es requerido el nombre')
        v.price.isEmpty('Es requerido asignar un precio')
        v.category_id.isEmpty('Selecciona una categoría')
        if (f?.has_inventory_control && !item?.id) {
            v.minimal_stock.isEmpty('Es requerido asignar el stock')
        }
        if (f.has_commission && isEnabledCommission) {
            v.commission.isEmpty('Es requerido la comisión')
        }
        // if(f.inventory_type !== INVENTORY_TYPES.NO_INVENTORY){
        //     if(f.item_production_items.length > 0){

        //     }
        // }
    })

    observer<FormSubmit>(f => ({
        name: f.name,
        price: Number(f.price),
        category_item_id: parseInt(f.category_id),
        image_url: f.image_url || '',
        has_inventory_control: f.has_inventory_control || false,
        ...(f?.has_inventory_control && {
            minimal_stock: Number(f.minimal_stock),
            stock: Number(f.minimal_stock)
        }),
        inventory_management_type: f.inventory_management_type,
        item_production_items:
            f.inventory_management_type === INVENTORY_TYPES.NO_INVENTORY
                ? []
                : f.item_production_items.map((_f: any) => {
                      return {
                          ...(_f.id && {
                              id: _f.id
                          }),
                          production_item_id: _f.production_item.id,
                          quantity: _f.quantity,
                          magnitude_id: _f.magnitude_id
                      }
                  }),
        features: f.features.map((_f: any) => {
            return {
                ...(_f.item_feature_id && {
                    item_feature_id: _f.item_feature_id
                }),
                id: _f.feature_id,
                quantity: parseInt(_f.quantity)
            }
        }),
        commission: f.commission || 0,
        has_commission: f.has_commission
    }))

    async function openModal() {
        if (item) {
            const { data, error } = await get(`/api/items/${item.id}`)

            if (data) {
                const _item: any = data.result

                formItem.store({
                    id: _item.id.toString() || '',
                    name: _item.name,
                    image_url: _item.image_url,
                    category_id: _item.category_item_id.toString(),
                    price: _item.price.toString(),
                    has_inventory_control: _item.has_inventory_control || false,
                    minimal_stock: _item.minimal_stock?.toString() || '',
                    has_features:
                        (_item.item_features &&
                            _item.item_features?.length !== 0) ||
                        false,
                    features:
                        _item.item_features.map((item_feature: any) => {
                            return {
                                item_feature_id: item_feature.id,
                                name: item_feature.feature.name,
                                feature_id: item_feature.feature_id,
                                required: item_feature.quantity !== 0,
                                quantity: item_feature.quantity
                            }
                        }) || [],
                    item_production_items: _item.item_production_items,
                    commission: _item.commission || '',
                    has_commission: !!_item.has_commission,
                    inventory_management_type: _item.inventory_management_type
                })
                formItem.init(formItem.store)
            }
            if (error) {
                toast({
                    title:
                        error.message ||
                        'Lo sentimos, ocurrió un error al recuperar la información , vuelva a intentarlo',
                    status: 'error',
                    position: 'top'
                })
                return
            }
        } else {
            formItem.init()
        }
        modal(FormItem, {
            title: item ? 'Editar producto' : 'Nuevo producto',
            onSubmit: submit,
            size: '2xl',
            isCentered: false
        })
    }

    return openModal
}

interface FormSubmit {
    name: string
    price: number
    minimal_stock: number
    category_item_id: number
    image_url: string
    has_inventory_control: boolean
    features: any[]
    commission: number
    has_commission: boolean
    item_production_items: any[]
}
