import { Input } from '@chakra-ui/react'

export default function NumberField({ onChange, decimal = 2, ...props }: any) {
    const handleChange = (ev: any) => {
        let val = ev.target.value
        let valParse
        if (decimal !== 0) {
            valParse = val
        } else {
            valParse = isNaN(parseInt(val)) ? '' : parseInt(val)
        }

        if (isNaN(valParse)) {
            if (!isNaN(valParse.substring(0, valParse.length - 1))) {
                ev.target.value = valParse.substring(0, valParse.length - 1)
            } else {
                ev.target.value = ''
            }
        } else {
            if (decimal !== 0) {
                if (val.split('.')[1] !== undefined) {
                    const tam = val.split('.')[1].length
                    const valt = tam >= decimal
                    const dec = tam !== 0
                    if (dec && valt) {
                        if (tam > decimal)
                            val = val.substring(0, val.length - 1)
                        valParse = Number.parseFloat(val).toFixed(decimal)
                    }
                }
            }

            ev.target.value = valParse
        }
        onChange(ev)
    }

    return <Input {...props} onChange={handleChange} />
}
