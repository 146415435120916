import { Icon } from '@chakra-ui/react'
import Pencil from '../../../components/Icon/Pencil'
import Colors from '../../../config/colors'
import Supply from '../../../models/supply'
import useSupply from './useSupply'

export default function Edition({ supply }: { supply: Supply }) {
    const openModal = useSupply(supply)

    return (
        <Icon
            fill={Colors.INFO}
            as={Pencil}
            marginRight={2}
            cursor="pointer"
            onClick={openModal}
        />
    )
}
