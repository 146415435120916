import { Icon } from '@chakra-ui/react'
import Trash from '../../../components/Icon/Trash'
import colors from '../../../config/theme/colors'
import Delete from '../../../lib/delete'
import Supply from '../../../models/supply'
import { listSupply } from '../storage'

export default function DeleteSupply({ supply }: { supply: Supply }) {
    return (
        <Delete
            title="Eliminar recurso"
            path={`/api/supplies/${supply.id}`}
            element={click => (
                <Icon
                    onClick={click}
                    fill={colors.error}
                    as={Trash}
                    cursor="pointer"
                />
            )}
            done={() => {
                listSupply.setQuery('page', 1)
                listSupply.load()
            }}
        >
            ¿Esta seguro de eliminar el recurso <b>{supply.name}</b>?
        </Delete>
    )
}
