import {
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from '@chakra-ui/react'
import GroupItem from '../../Groups/GroupItem'
import usePrintPayments from '../../hooks/usePrintPayments'
import ContentForm from './ContentForm'

export default function ModalPaymentsMethod() {
    const { isLoading, submit } = usePrintPayments()
    const { isOpen, onClose, onOpen } = useDisclosure()

    return (
        <>
            <GroupItem onClick={onOpen} label="Métodos de pago" />
            <Modal isOpen={isOpen} onClose={onClose} size="sm">
                <ModalOverlay />
                <ModalContent p={4} pb={6}>
                    <ModalHeader textAlign="center">
                        Reporte de Ventas con Métodos de Pago
                    </ModalHeader>
                    <ModalBody>
                        <ContentForm onSubmit={submit} isLoading={isLoading} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
