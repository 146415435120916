import {
    Box,
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Text
} from '@chakra-ui/react'
import { MdlEditItemFormData, ModalEditItemProps } from './types'
import { FormEvent, useState } from 'react'
import extractDataForm from '../../../../utilities/extractDataForm'
import ContentForm from './ContentForm'
import useMenu from '../../../../hooks/useMenu'
import ModalPreferences from '../ModalPreferences'
import useSession from '../../../../hooks/useSession'

export default function ModalEditItem(props: ModalEditItemProps) {
    const { isWaiter, isDealer, isLogged } = useSession()
    const [helperPrice, setHelperPrice] = useState('')
    const { updatePrice, updateQuantity, updateDescription, initialMenu } =
        useMenu()
    const [modlPreferenceIsOpen, setMoldPreferenceIsOpen] = useState(false)
    const [description, setDescription] = useState<string>(
        () => props.data.description
    )
    const _isWaiter = isWaiter() || !isLogged

    function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const form = extractDataForm<MdlEditItemFormData>(ev.target)
        if (!_isWaiter && form.price === '') {
            setHelperPrice('Escriba el precio')
            return
        }
        if (!_isWaiter) {
            updatePrice(props.data.itemId, Number(form.price))
        }
        updateQuantity(props.data.itemId, Number(form.quantity))
        updateDescription(props.data.itemId, description)
        props.onClose()
    }

    function openPreferencesHandler() {
        setMoldPreferenceIsOpen(true)
        props.onClose()
    }

    function addPreferenceHandler(prefence: Record<string, string>) {
        setDescription(
            `${description}${description ? ', ' : ''}${Object.values(
                prefence
            ).join(', ')}`
        )
        props.onOpen()
        setMoldPreferenceIsOpen(false)
    }

    function clickClosePreferenceHandler() {
        props.onOpen()
        setMoldPreferenceIsOpen(false)
    }

    function getMin() {
        if (props.by === 'pre_orders') return 1
        const initMenuItem = initialMenu.find(
            menuItem => menuItem.itemId === props.data.itemId
        )
        if (initMenuItem && (_isWaiter || isDealer())) {
            return initMenuItem.quantity
        }
        return 1
    }

    return (
        <>
            {modlPreferenceIsOpen && (
                <ModalPreferences
                    categoryItemId={props.data.categoryItemId}
                    isOpen={modlPreferenceIsOpen}
                    onClose={clickClosePreferenceHandler}
                    onAdd={addPreferenceHandler}
                />
            )}
            <Modal
                isOpen={props.isOpen}
                onClose={props.onClose}
                closeOnOverlayClick
                isCentered
                size="md"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        <Flex flexDir="column">
                            <Box
                                order={1}
                                textAlign="center"
                                w="full"
                                mb="8"
                                mt={4}
                            >
                                <Text fontSize="2xl" fontWeight="bold">
                                    {props.data.name}
                                </Text>
                                <Text fontSize="sm">
                                    Cantidad actual: {props.data.quantity}
                                </Text>
                            </Box>
                            <Box order={3}>
                                <ContentForm
                                    onSubmit={submitHandler}
                                    defaultUnitPrice={props.data.unitPrice}
                                    description={description}
                                    onChangeDescription={setDescription}
                                    defaultQuantity={props.data.quantity}
                                    onOpenPreference={openPreferencesHandler}
                                    helperPrice={helperPrice}
                                    onChangePrice={() => setHelperPrice('')}
                                    min={getMin()}
                                />
                            </Box>
                            <Flex
                                w="full"
                                mt="5"
                                justifyContent="space-between"
                                gap={2}
                                order={[0, 0, 4]}
                            >
                                <Button
                                    size="lg"
                                    w="full"
                                    variant="outline"
                                    onClick={props.onClose}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    size="lg"
                                    colorScheme="orange"
                                    w="full"
                                    type="submit"
                                    form="form-edit-item"
                                >
                                    Confirmar
                                </Button>
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter />
                </ModalContent>
            </Modal>
        </>
    )
}
