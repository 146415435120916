import { Flex, Icon } from '@chakra-ui/react'
import Block from '../../components/Icon/Block'
import Description from '../../components/Icon/Description'
import Printer from '../../components/Icon/Printer'
import usePrint from './hooks/usePrint'
import ModalEmit from './ModalContents/ModalEmit'
import ModalFormAnnul from '../../components/ModalFormAnnul'
import { controlList } from './storage'
import { OrderPaymentData } from './types'
import ModalAnnul from '../../components/ModalAnnul'
import ModalDownload from './ModalContents/ModalDownload'
import Download from '../../components/Icon/Download'
import useSession from '../../hooks/useSession'
import { useHistory } from 'react-router-dom'
import Coin from '../../components/Icon/Coin'

export default function TableActions({
    orderPayment,
    print_sale_note,
    generate_electronic_receipts
}: {
    orderPayment: OrderPaymentData
    print_sale_note: any
    generate_electronic_receipts: any
}) {
    const { push } = useHistory()
    //const { clickAnnulHandler } = useAnullPayment(orderPayment.id)
    const { userCanCancelItems } = useSession()

    const { printHandler, isLoading } = usePrint(
        orderPayment.id,
        orderPayment.proof_payment
    )
    const { sunat_voucher, code, client } = orderPayment

    const isNullable =
        orderPayment.account_order.status === 2 &&
        ((sunat_voucher && !sunat_voucher.sent_daily) || !sunat_voucher)

    /**
     * No deberia permitir emitir comprobante de una venta anulada que no tiene comprobante
     * order_state 5 === vigente
     */
    const toEmit =
        !orderPayment.courtesy &&
        //orderPayment.account_order.status === 2 &&
        (sunat_voucher || orderPayment.account_order.status === 2) &&
        generate_electronic_receipts == '1'

    const toDownload =
        orderPayment.proof_payment === 1 || orderPayment.proof_payment === 2

    const xml_name = toDownload ? sunat_voucher?.xml_name : ''

    return (
        <Flex justifyContent="center">
            <ModalDownload
                element={click => (
                    <Icon
                        as={Download}
                        display="block"
                        fill="dark"
                        onClick={click}
                        mr="2"
                        cursor="pointer"
                        pointerEvents={toDownload && xml_name ? 'auto' : 'none'}
                        opacity={toDownload ? '1' : '.6'}
                    />
                )}
                onDone={controlList.load}
                filename={xml_name}
            />
            <Icon
                as={Printer}
                display="block"
                fill="dark"
                mr="2"
                onClick={printHandler}
                cursor="pointer"
                pointerEvents={
                    isLoading ||
                    code.length === 0 ||
                    (print_sale_note == '0' && orderPayment.proof_payment == 4)
                        ? 'none'
                        : 'auto'
                }
                opacity={
                    isLoading ||
                    code.length === 0 ||
                    (print_sale_note == '0' && orderPayment.proof_payment == 4)
                        ? '.6'
                        : '1'
                }
            />
            <Icon
                as={Coin}
                display="block"
                fill="dark"
                mr="2"
                onClick={() => {
                    push(`/sales/${orderPayment.id}/update_payments`)
                }}
                cursor="pointer"
            />
            <ModalEmit
                element={click => (
                    <Icon
                        as={Description}
                        display="block"
                        cursor="pointer"
                        fill="dark"
                        onClick={click}
                        mr="2"
                        pointerEvents={toEmit ? 'auto' : 'none'}
                        opacity={toEmit ? '1' : '.6'}
                    />
                )}
                orderPaymentId={orderPayment.id}
                client={client}
                amount={orderPayment.total_final}
                onDone={() => controlList.load()}
                isCredit={orderPayment.have_outstanding}
                orderPayment={orderPayment}
            />
            {userCanCancelItems() &&
                (sunat_voucher &&
                sunat_voucher.type_voucher_id === 2 &&
                sunat_voucher.sent ? (
                    <ModalFormAnnul
                        element={click => (
                            <IconBlock
                                isNullable={isNullable}
                                onClick={click}
                            />
                        )}
                        onDone={controlList.load}
                        orderPaymentId={orderPayment.id}
                        sunatVoucherId={sunat_voucher.id}
                        code={sunat_voucher.code}
                    />
                ) : (
                    <ModalAnnul
                        element={click => (
                            <IconBlock
                                isNullable={isNullable}
                                onClick={click}
                            />
                        )}
                        orderPaymentId={orderPayment.id}
                        sunatVoucherId={sunat_voucher ? sunat_voucher.id : 0}
                        typeVoucher={orderPayment.proof_payment}
                        onDone={controlList.load}
                    />
                ))}
        </Flex>
    )
}

function IconBlock({
    isNullable,
    onClick
}: {
    isNullable: boolean
    onClick: () => void
}) {
    return (
        <Icon
            as={Block}
            fill="error"
            display="block"
            cursor="pointer"
            pointerEvents={isNullable ? 'auto' : 'none'}
            opacity={isNullable ? '1' : '.6'}
            onClick={onClick}
        />
    )
}
