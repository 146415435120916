import { Button } from '@chakra-ui/react'
import Pencil from '../../../components/Icon/Pencil'
import Colors from '../../../config/colors'
import Zone from '../../../models/zone'
import PropsButton from '../../../utilities/props_button'
import useForm from './useForm'

export default function Edition({ zone }: { zone: Zone }) {
    const openModal = useForm(zone)

    return (
        <Button
            {...PropsButton.Edit}
            leftIcon={<Pencil fill={Colors.INFO} />}
            onClick={openModal}
        >
            Editar
        </Button>
    )
}
