import { Flex, Input, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { ChangeEvent, useState } from 'react'
import { listHistory } from '../storage'

export default function DateField() {
    const [date, setDate] = useState(() => dayjs().format('YYYY-MM-DD'))
    function changeHandler(ev: ChangeEvent<HTMLInputElement>) {
        listHistory.setQuery('date', ev.target.value)
        setDate(ev.target.value)
        listHistory.load()
    }

    return (
        <Flex justifyContent="flex-end" alignItems="center" mb={4}>
            <Text mr={2} color="gray-3">
                Seleccionar Fecha:{' '}
            </Text>
            <Input
                type="date"
                w="72"
                placeholder="Seleccionar fecha"
                bg="base-4"
                borderRadius="full"
                onChange={changeHandler}
                value={date}
            />
        </Flex>
    )
}
