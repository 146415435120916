import {
    Box,
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import { HistoryPayment } from '../../types'

export default function Content({
    total,
    pending,
    payments
}: {
    total: number
    pending: number
    payments: HistoryPayment[]
}) {
    return (
        <Box>
            <Flex
                border="1px"
                borderRadius="md"
                p="4"
                bg="white"
                borderColor="gray.300"
                w="full"
                mb={4}
            >
                <Flex direction="column" w="full">
                    <Text align="center" fontSize="sm">
                        Total a pagar
                    </Text>
                    <Flex justifyContent="center">
                        <Text as="b" align="center" alignSelf="center">
                            S/
                        </Text>
                        <Text as="b" align="center" fontSize="xl">
                            {total.toFixed(2)}
                        </Text>
                    </Flex>
                </Flex>
                <Flex direction="column" w="full">
                    <Text align="center" fontSize="sm">
                        Pendiente de pago
                    </Text>
                    <Flex justifyContent="center">
                        <Text as="b" align="center" alignSelf="center">
                            S/
                        </Text>
                        <Text as="b" align="center" fontSize="xl">
                            {pending.toFixed(2)}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
            <Table>
                <Thead>
                    <Tr>
                        <Th>#</Th>
                        <Th>Fecha de pago</Th>
                        <Th>Método de pago</Th>
                        <Th isNumeric>Monto</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {payments.map((payment, i) => (
                        <Tr key={i}>
                            <Td>{i + 1}</Td>
                            <Td>{payment.payment_date}</Td>
                            <Td>
                                {payment.payment.sub_payment
                                    ? payment.payment.sub_payment.name
                                    : payment.payment.name}
                            </Td>
                            <Td whiteSpace="nowrap" isNumeric>
                                S/ {payment.amount.toFixed(2)}
                            </Td>
                        </Tr>
                    ))}
                    {payments.length < 1 && (
                        <Tr>
                            <Td colSpan={4}>
                                <Text align="center">
                                    Sin historial de pagos
                                </Text>
                            </Td>
                        </Tr>
                    )}
                </Tbody>
            </Table>
        </Box>
    )
}
