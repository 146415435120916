import useSession from '../../../hooks/useSession'
import useSubmit from '../../../hooks/useSubmit'
import modal from '../../../lib/modal'
import Item from '../../../models/item'
import { formEditionStock, listItem } from '../storage'
import FormEditionStock from './FormEditionStock'

export default function useEditionStock(item?: Item) {
    const { session } = useSession()
    const { validation, observer, submit } = useSubmit(formEditionStock, {
        path: item ? `/api/inventories/item` : '/api/inventories/item',
        method: 'post',
        done: () => listItem.load()
    })

    validation(v => {
        v.name.isEmpty('Es requerido el nombre')
        v.type_movement_id.isEmpty('Es requerido el tipo de movimiento')

        v.quantity
            .isEqual(0, 'El stock debe ser mayor a 0')
            .isEmpty('Es requerido asignar el stock')
    })

    observer<any>(f => ({
        item_id: Number(f.id),
        stock: Number(f.quantity),
        increase: f.type_movement_id === '1',
        reason: f.comment,
        user_id: session?.user.id
    }))

    function openModal() {
        if (item) {
            formEditionStock.store({
                id: item.id.toString() || '',
                name: item.name,
                actual_stock: item.stock?.toString() || '',
                stock: item.stock?.toString() || '',
                type_movement_id: '1',
                comment: '',
                quantity: ''
            })
            formEditionStock.init(formEditionStock.store)
        } else {
            formEditionStock.init()
        }
        modal(FormEditionStock, {
            title: item ? 'Actualización del Inventario' : 'Nuevo Insumo',
            onSubmit: submit,
            centerTitle: true
        })
    }

    return openModal
}
