import { storagePage } from '../storage'

export default function clearStates() {
    storagePage.alias = ''
    storagePage.card = []
    storagePage.cash = []
    storagePage.clientSelected = null
    storagePage.discount = 0
    storagePage.to = 'eat'
    storagePage.transfer = []
    storagePage.type = null
    storagePage.typeDiscount = 'amount'
    storagePage.typeTax = 'levied'
    storagePage.condition = 'cash'
    storagePage.creditFees = []
}
