import { Redirect, useLocation } from 'react-router-dom'
import useSession from '../../hooks/useSession'

export default function PageNotFound() {
    const { pathname } = useLocation()
    const { getAccessRoutes, getRedirect } = useSession()

    const routePath = getAccessRoutes().find(accessPathRoute => {
        const locationChar = accessPathRoute.search('/:')
        const currentPathRouteSplit = accessPathRoute.split('')

        if (locationChar > -1) {
            currentPathRouteSplit.splice(locationChar)
            for (let i = locationChar; i < pathname.length; i++) {
                currentPathRouteSplit.push(pathname[i])
            }
        }

        return currentPathRouteSplit.join('') === pathname
    })

    if (!routePath) {
        return <Redirect to={getRedirect()} />
    }

    return <></>
}
