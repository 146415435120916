import {
    Modal,
    ModalContent,
    ModalBody,
    ModalOverlay,
    ModalFooter,
    Button,
    Text,
    FormControl,
    FormLabel,
    Input
} from '@chakra-ui/react'
import PropsButton from '../../utilities/props_button'
import useModalChangePassword from './useModalChangePassword'
import ErrorHelper from '../ErrorHelper'

export default function ModalChangePassword({
    isOpen,
    onClose,
    setData,
    handleClose
}: {
    isOpen: boolean
    onClose: () => void
    handleClose: () => void
    setData: (data: any) => void
}) {
    const { handleChange, handleSubmit, errorField, form, isLoading } =
        useModalChangePassword({
            setData,
            handleClose
        })

    return (
        <Modal isCentered={true} size="lg" onClose={onClose} isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent p="10">
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <Text
                            mb="5"
                            color="#434343"
                            fontWeight="bold"
                            fontSize="2xl"
                        >
                            Cambiar contraseña
                        </Text>
                        <Text mb="5" color="#434343" fontSize="lg">
                            Para realizar el cambio de contraseña, ingrese su
                            contraseña actual.
                        </Text>
                        <FormControl mb="4">
                            <FormLabel>Contraseña actual</FormLabel>
                            <Input
                                type="password"
                                onChange={handleChange}
                                name="password"
                                placeholder="Ingresar su contraseña actual"
                                autoFocus
                                value={form.password}
                            />
                            <ErrorHelper helper={errorField.password} />
                        </FormControl>
                        <FormControl mb="4">
                            <FormLabel>Contraseña nueva</FormLabel>
                            <Input
                                type="password"
                                onChange={handleChange}
                                name="new_password"
                                placeholder="Ingresar la nueva contraseña"
                                value={form.new_password}
                            />
                            <ErrorHelper helper={errorField.new_password} />
                        </FormControl>
                        <FormControl mb="10">
                            <FormLabel>Repetir contraseña nueva</FormLabel>
                            <Input
                                type="password"
                                onChange={handleChange}
                                name="repeat_password"
                                placeholder="Ingresar la nueva contraseña"
                                value={form.repeat_password}
                            />
                            <ErrorHelper helper={errorField.repeat_password} />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter
                        justifyContent="flex-end"
                        gridGap="15px"
                        display="flex"
                    >
                        <Button
                            {...PropsButton.ModalNo}
                            onClick={() => onClose()}
                        >
                            Cancelar
                        </Button>
                        <Button {...PropsButton.ModalYes} isLoading={isLoading}>
                            Confirmar
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}
