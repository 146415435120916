import {
    FormControl,
    FormLabel,
    Input,
    InputProps,
    Text
} from '@chakra-ui/react'
import ErrorHelper from '../../../components/ErrorHelper'
import useSession from '../../../hooks/useSession'
import CashBox from '../../../models/cashbox'
import Logged from '../../../types/logged'
import { formCloseCashbox, storagePage } from '../storage'

export default function ModalCloseCashbox() {
    const session = useSession().session as Logged
    const cashbox = storagePage.cashboxUser?.cashbox as CashBox

    return (
        <>
            <Text
                textAlign="center"
                mb="5"
                mt="2"
                color="#434343"
                fontSize="2xl"
                fontWeight="bold"
            >
                Cierre de caja
            </Text>
            <FormControl mb="4">
                <FormLabel>Caja</FormLabel>
                <Input name="cashbox_id" isReadOnly value={cashbox.name} />
            </FormControl>
            <FormControl mb="4">
                <FormLabel>Usuario</FormLabel>
                <Input
                    name="username"
                    placeholder="Ingresa el usuario"
                    isReadOnly
                    value={session.user.username}
                />
            </FormControl>
            <PasswordField />
        </>
    )
}

function PasswordField() {
    const { props, helper } = formCloseCashbox.useField<InputProps>('password')

    return (
        <FormControl mb="4">
            <FormLabel>Contraseña</FormLabel>
            <Input
                {...props}
                type="password"
                placeholder="Ingresa la contraseña"
                autoFocus
            />
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}
