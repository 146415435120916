import { Flex, Button } from '@chakra-ui/react'
import colors from '../../../../../config/theme/colors'

export default function Buttons({
    onClose,
    isEdition
}: {
    onClose: () => void
    isEdition: boolean
}) {
    return (
        <Flex justifyContent="flex-end" mt={14}>
            <Button
                colorScheme="gray"
                variant="outline"
                mr={3}
                onClick={onClose}
                borderRadius="xl"
                fontSize="sm"
                w="full"
            >
                Cancelar
            </Button>
            <Button
                borderRadius="xl"
                bg={colors['alo-green']}
                fontSize="sm"
                w="full"
                color="white"
                type="submit"
            >
                {isEdition ? 'Guardar' : 'Agregar'}
            </Button>
        </Flex>
    )
}
