import { Icon } from '@chakra-ui/react'
import { useState } from 'react'
import PreOrder from '../../../../models/pre_order'
import ModalPreOrder from './ModalPreOrder'
import colors from '../../../../config/theme/colors'
import PriceTag from '../../../../components/Icon/PriceTag'

export default function ShowPreOrder({ preOrder }: { preOrder: PreOrder }) {
    const [open, setOpen] = useState(false)
    return (
        <>
            <Icon
                as={PriceTag}
                marginRight={2}
                fill={colors['alo-gray']}
                cursor={'pointer'}
                onClick={() => setOpen(true)}
            />
            {open && (
                <ModalPreOrder
                    dataPreOrder={preOrder}
                    isOpen={open}
                    onClose={() => setOpen(false)}
                />
            )}
        </>
    )
}
