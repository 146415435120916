import { Accordion } from '@chakra-ui/react'
import Item from './Item'
import ItemDetail from './ItemDetail'
import { ListItemsProps } from './types'

export default function ListItems({ items, onLoad }: ListItemsProps) {
    return (
        <Accordion allowMultiple defaultIndex={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}>
            {items
                .filter(item => item.quantity > 0)
                .map(item => (
                    <Item
                        key={item.order_item_id}
                        title={`${item.item_name} (${item.states.reduce(
                            (n, state) => n + state.counter,
                            0
                        )}/${item.quantity})`}
                    >
                        {item.states.map(state => (
                            <ItemDetail
                                key={state.order_item_state_id}
                                orderItemStateId={state.order_item_state_id}
                                date={state.created_at}
                                label={`${state.zone_name} - ${state.table_name}`}
                                maxQuantity={state.initial_quatity}
                                defaultCounter={state.counter}
                                description={state.aditional_information}
                                onLoad={onLoad}
                            />
                        ))}
                    </Item>
                ))}
        </Accordion>
    )
}
