import {
    Text,
    Select,
    Input,
    FormControl,
    FormLabel,
    InputProps,
    Textarea,
    TextareaProps,
    SelectProps
} from '@chakra-ui/react'
import { ChangeEvent } from 'react'
import ErrorHelper from '../../../components/ErrorHelper'
import useStorage from '../../../hooks/useStorage'
import onlyNumber from '../../../utilities/onlyNumber'
import { formMovement } from '../storage'

export default function ModalMovement() {
    return (
        <>
            <Text mb="4" color="#434343">
                Para realizar el movimiento de caja, detalle la siguiente
                información.
            </Text>
            <MovimentAndConceptFields />
            <MountField />
            <CommentField />
        </>
    )
}

function MovimentAndConceptFields() {
    const { props: propsTypeMovement, helper: helperTM } =
        formMovement.useField<SelectProps>('type')
    const { props: propsConcept, helper: helperM } =
        formMovement.useField<SelectProps>('concept')

    const { movement_type, income_concept, output_concept } = useStorage()

    async function changeHandler(ev: ChangeEvent<HTMLSelectElement>) {
        propsTypeMovement.onChange(ev)
        formMovement.setValue('concept', '')
    }

    const concepts =
        propsTypeMovement.value === '1'
            ? income_concept.values
            : output_concept.values

    return (
        <>
            <FormControl mb="4">
                <FormLabel>Tipo de movimiento</FormLabel>
                <Select
                    {...propsTypeMovement}
                    placeholder="Seleccionar tipo de movimiento"
                    onChange={changeHandler}
                >
                    {movement_type.values.map(movementType => (
                        <option
                            key={movementType.value}
                            value={movementType.value}
                        >
                            {movementType.description}
                        </option>
                    ))}
                </Select>
                <ErrorHelper helper={helperTM} />
            </FormControl>
            <FormControl mb="4">
                <FormLabel>Concepto</FormLabel>
                <Select {...propsConcept} placeholder="Seleccionar concepto">
                    {concepts
                        .filter(concept => concept.value !== 107)
                        .map((concept: any) => (
                            <option key={concept.value} value={concept.value}>
                                {concept.description}
                            </option>
                        ))}
                </Select>
                <ErrorHelper helper={helperM} />
            </FormControl>
        </>
    )
}

function MountField() {
    const { props, helper } = formMovement.useField<InputProps>('amount')

    function changeHandler(ev: ChangeEvent<HTMLInputElement>) {
        const value = onlyNumber(ev.target.value, {
            decimal: 2,
            min: 0,
            max: Infinity,
            negative: false
        })
        props.onChange({
            ...ev,
            target: {
                ...ev.target,
                value
            }
        })
    }

    return (
        <FormControl mb="4">
            <FormLabel>Monto</FormLabel>
            <Input
                {...props}
                onChange={changeHandler}
                placeholder="Ingresar monto"
            />
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}

function CommentField() {
    const { props, helper } = formMovement.useField<TextareaProps>('comment')
    return (
        <FormControl>
            <FormLabel>Comentario</FormLabel>
            <Textarea
                {...props}
                maxLength={300}
                placeholder="Ingresar comentario"
            />
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}
