import {
    Box,
    Input,
    Table,
    TableCaption,
    Tbody,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import Initial from '../../components/Initial'
import MenuUser from '../../components/MenuUser'
import Navbar from '../../components/Navbar'
import NTabs from '../../components/NTabs'
import { useGet } from '../../hooks/useHttp'
import Booking from '../../models/booking'
import Row from './components/Row'

export default function PayCommissions() {
    const [date, setDate] = useState(() => dayjs().format('YYYY-MM-DD'))
    const { data, reload } = useGet<Booking[]>(
        `/api/bookings?sort=id&types=1,2&date=${date}`,
        []
    )

    return (
        <Initial position="relative">
            <Navbar component={<NTabs />}>
                <Box px="5" py="1">
                    <MenuUser />
                </Box>
            </Navbar>
            <Box py={6} px={10}>
                <Input
                    size="md"
                    borderRadius="full"
                    w={['full', 'full', 'auto']}
                    type="date"
                    bg="base-4"
                    mb={2}
                    onChange={ev => setDate(ev.target.value)}
                    value={date}
                />
                <Table size="md" className="table-a-la-orden">
                    <Thead>
                        <Tr>
                            <Th textAlign="center">Hora de reserva</Th>
                            <Th textAlign="center">Guía turistico</Th>
                            <Th textAlign="center">Platos vendidos</Th>
                            <Th textAlign="center">Estado</Th>
                            <Th textAlign="center">Comisión</Th>
                            <Th textAlign="center">Pago por comisión</Th>
                            <Th textAlign="center">Acciones</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.result.map(booking => (
                            <Row
                                key={booking.id}
                                booking={booking}
                                onReload={reload}
                            />
                        ))}
                    </Tbody>
                    {data.result.length === 0 && (
                        <TableCaption>Sin reservas actuales</TableCaption>
                    )}
                </Table>
            </Box>
        </Initial>
    )
}
