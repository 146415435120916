import { createContext } from 'react'
import Table from '../../../models/table'
import User from '../../../models/user'

export interface OrderByTableContextInterface {
    table: Table | null
    setTable: (table: Table) => void
    /**
     * Mozo encargado de la orden
     */
    waiter: Omit<User, 'password'> | null
    /**
     * Cambia el mozo de la orden actual.
     */
    setWaiter: (waiter: Omit<User, 'password'>) => void
    /**
     * Evalúa si cargaron los datos de este contexto.
     */
    isSuccess: boolean
    /**
     * Con este se puede saber si la mesa ya tiene una orden.
     */
    orderId: number
    /**
     * Habilita la opción de poder agregar items al menú.
     */
    isModeEdition: boolean
    /**
     * Habilita el modo edición, en caso de no existir sesión, pedirá el pin
     */
    enableEdition: (pin?: string) => void
    /**
     * Inabilita el modo edición
     */
    disableEdition: () => void
    /**
     * Pin de usuario no logeado.
     */
    pin: string
    /**
     * Registra el pin en local
     */
    setPin: (pin: string) => void
}

const OrderByTableContext = createContext<OrderByTableContextInterface>({
    table: null,
    waiter: null,
    setWaiter: () => null,
    setTable: () => null,
    isSuccess: true,
    orderId: 0,
    isModeEdition: true,
    enableEdition: () => null,
    disableEdition: () => null,
    pin: '',
    setPin: () => null
})

export default OrderByTableContext
