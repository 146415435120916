import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import { Tr, Td } from '@chakra-ui/react'
import List from '../../lib/list'
import { controlList } from './storage'
import Actions from './Actions'
import useInitial from './useInitial'

export default function ReportBusinessLine() {
    useInitial()

    return (
        <Sidebar>
            <SimpleNav title="Venta por línea de negocio" />
            <Actions />
            <List
                control={controlList}
                tbody={(sale, e) => (
                    <Tr key={e}>
                        <Td>{sale.fecha_apertura}</Td>
                        <Td>{sale.cajero}</Td>
                        <Td>{sale.linea_negocio}</Td>
                        <Td>{sale.venta.toFixed(2)}</Td>
                    </Tr>
                )}
            />
        </Sidebar>
    )
}
