import { Flex, Text } from '@chakra-ui/react'

export default function StatBox({
    label,
    value,
    withSymbol
}: {
    label: string
    value: number | string
    withSymbol?: boolean
}) {
    return (
        <Flex direction="column" w="full">
            <Text align="center" fontSize="sm">
                {label}
            </Text>
            <Flex justifyContent="center">
                {withSymbol && (
                    <Text as="b" align="center" alignSelf="center">
                        S/
                    </Text>
                )}

                <Text as="b" align="center" fontSize="xl">
                    {value}
                </Text>
            </Flex>
        </Flex>
    )
}
