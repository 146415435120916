import {
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import Pagination from '../../../../components/Pagination'
import { TableMovementsProps } from './types'

export default function TableMovements({
    onLimit,
    onPage,
    itemMovements,
    page,
    limit,
    pages
}: TableMovementsProps) {
    return (
        <>
            <TableContainer>
                <Table size="sm" className="table-a-la-orden">
                    <Thead>
                        <Tr>
                            <Th>Fecha y Hora</Th>
                            <Th>Comentario</Th>
                            <Th>Usuario</Th>
                            <Th>Stock Inicial</Th>
                            <Th>Ajuste</Th>
                            <Th>Stock final</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {itemMovements.map(itemMovement => (
                            <Tr key={itemMovement.id}>
                                <Td>
                                    {dayjs(itemMovement.created_at).format(
                                        'DD/MM/YYYY hh:mm a'
                                    )}
                                </Td>
                                <Td>{itemMovement.reason}</Td>
                                <Td>
                                    {itemMovement.user
                                        ? `${itemMovement.user.firstname} ${itemMovement.user.lastname}`
                                        : ''}
                                </Td>
                                <Td>{itemMovement.initial_quantity}</Td>
                                <Td>{itemMovement.marginal_quantity}</Td>
                                <Td>{itemMovement.current_quantity}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                    {itemMovements.length === 0 && (
                        <TableCaption>No existen movimientos</TableCaption>
                    )}
                </Table>
            </TableContainer>
            <Pagination
                pages={pages}
                page={page}
                limit={limit}
                onLimit={onLimit}
                onPage={onPage}
            />
        </>
    )
}
