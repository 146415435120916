import { Box } from '@chakra-ui/layout'
import { useBreakpointValue } from '@chakra-ui/react'
import {
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Th,
    Thead,
    Tr
} from '@chakra-ui/table'
import { useState } from 'react'
import Initial from '../../components/Initial'
import MenuUser from '../../components/MenuUser'
import Navbar from '../../components/Navbar'
import NTabs from '../../components/NTabs'
import { useGet } from '../../hooks/useHttp'
import Profile from '../../models/profile'
import DeliveryRow from './components/DeliveryRow'
import PageHeader from './components/PageHeader'
import { DeliveryStatuNamesSearch } from './components/PageHeader/types'
import { CountDeliveriesStatus, FindAllDeliveries } from './types'

export default function Deliveries() {
    const tableSize = useBreakpointValue({ base: 'sm', lg: 'md' })
    const [search, setSearch] = useState('')
    const [status, setStatus] = useState<DeliveryStatuNamesSearch>('all')
    const { data, reload } = useGet<FindAllDeliveries>(
        `/api/deliveries?search=${search}&status=${
            status === 'unpaid' ? 'unpaid' : status
        }${status === 'unpaid' ? '&payment_status=unpaid' : ''}`,
        []
    )
    const { data: dataCount, reload: realoadCount } =
        useGet<CountDeliveriesStatus>(`/api/deliveries/count`, {
            canceled: 0,
            pending: 0,
            inPreparation: 0,
            onTheWay: 0,
            delivered: 0,
            closed: 0,
            pending_payments: 0
        })
    const { data: profileResult } = useGet<Profile[]>('/api/profiles', [])

    function load() {
        reload()
        realoadCount()
    }

    return (
        <Initial bg="base-2" minH="100vh">
            <Navbar component={<NTabs />}>
                <Box px="6" py="1">
                    <MenuUser />
                </Box>
            </Navbar>
            <Box>
                <PageHeader
                    search={search}
                    onSearch={setSearch}
                    status={status}
                    onStatus={setStatus}
                    counts={dataCount.result}
                />
                <TableContainer
                    p={{
                        base: 3,
                        md: 6
                    }}
                >
                    <Table
                        className="table-a-la-orden"
                        bg="white"
                        size={tableSize}
                    >
                        <Thead bg="base-2">
                            <Tr textAlign="center">
                                <Th textAlign="center">N°</Th>
                                <Th textAlign="center">Orden</Th>
                                <Th textAlign="center">Entrega</Th>
                                <Th textAlign="center">Motorizado</Th>
                                <Th textAlign="center">Estatus</Th>
                                <Th textAlign="center">Total</Th>
                                <Th p={0} />
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.result.map(delivery => (
                                <DeliveryRow
                                    key={delivery.id}
                                    delivery={delivery}
                                    onLoad={load}
                                    profiles={profileResult.result}
                                />
                            ))}
                        </Tbody>
                        {data.result.length === 0 && (
                            <TableCaption>Sin pedidos</TableCaption>
                        )}
                    </Table>
                </TableContainer>
            </Box>
        </Initial>
    )
}
