import {
    Modal,
    ModalOverlay,
    ModalBody,
    ModalContent,
    Text,
    ModalFooter,
    Button,
    Box,
    Textarea,
    FormControl,
    FormLabel
} from '@chakra-ui/react'
import Block from '../../../../components/Icon/Block'
import colors from '../../../../config/theme/colors'
import useDialogCancel from './useDialogCancel'
import ErrorHelper from '../../../../components/ErrorHelper'

interface DialogCancelProps {
    element: (click: () => void) => JSX.Element
    order_id: number
}

export default function DialogCancel({ element, order_id }: DialogCancelProps) {
    const {
        isOpen,
        setOpen,
        isLoading,
        submitHandler,
        openHandler,
        helpers,
        changeHandler
    } = useDialogCancel(order_id)

    return (
        <>
            {element(openHandler)}
            <Modal
                isCentered={true}
                size="lg"
                onClose={() => setOpen(false)}
                isOpen={isOpen}
            >
                <ModalOverlay />
                <ModalContent p="5">
                    <form noValidate onSubmit={submitHandler}>
                        <ModalBody>
                            <Box display="flex" justifyContent="center">
                                <Block fill={colors.error} fontSize="42" />
                            </Box>
                            <Text
                                color="gray-2"
                                fontSize="2xl"
                                fontWeight="bold"
                                textAlign="center"
                                mb="5"
                                mt="5"
                            >
                                Anular pedido
                            </Text>
                            <Text textAlign="center" fontSize="lg" pb={3}>
                                ¿Esta seguro de anular el pedido?
                            </Text>
                            <FormControl isRequired>
                                <FormLabel>Ingresar motivo</FormLabel>
                                <Textarea
                                    name="motivo"
                                    placeholder="Ingresar motivo de anulación"
                                    maxLength={50}
                                    onChange={changeHandler}
                                    disabled={isLoading}
                                />
                                <ErrorHelper helper={helpers.motivo} />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter
                            mt={6}
                            justifyContent="space-between"
                            display="flex"
                            flexDirection={{ base: 'column', sm: 'row' }}
                        >
                            <Button
                                variant="alo-gray:lg-md"
                                bg="transparent"
                                color="alo-gray"
                                onClick={() => setOpen(false)}
                                fontSize={{ base: '16px', md: '20px' }}
                                w="48"
                                m="5px"
                                disabled={isLoading}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="alo-red:lg-md"
                                w="48"
                                type="submit"
                                isLoading={isLoading}
                                m="5px"
                                fontSize={{ base: '16px', md: '20px' }}
                            >
                                Confirmar
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}
