import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    useToast
} from '@chakra-ui/react'
import { ReactNode, FormEvent, useState } from 'react'
import PropsButton from '../../utilities/props_button'
import { del } from '../http'

export default function Delete({
    element,
    title,
    children,
    path,
    done
}: DeleteProps) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setLoading] = useState(false)
    const toast = useToast()

    async function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        setLoading(true)
        const { error } = await del(path)
        setLoading(false)
        if (!error) {
            toast({
                title: 'Se ha eliminado exitosamente',
                status: 'success',
                position: 'top'
            })
            done && done()
        } else {
            if (
                error.type === 'development' ||
                error.type === 'customer' ||
                error.type === 'dependency'
            ) {
                const values = Object.values(error.errors)
                const message = values.length > 0 ? values[0] : error.message
                toast({
                    title: message,
                    status: 'error',
                    position: 'top'
                })
            } else {
                toast({
                    title: 'Surgió un problema al eliminar, vuelva a reintentar',
                    status: 'error',
                    position: 'top'
                })
            }
        }
        onClose()
    }

    return (
        <>
            {element(onOpen)}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <form onSubmit={submitHandler}>
                        <ModalBody>{children}</ModalBody>
                        <ModalFooter>
                            <Button {...PropsButton.ModalNo} onClick={onClose}>
                                Cancelar
                            </Button>
                            <Button
                                {...PropsButton.ModalDelete}
                                isLoading={isLoading}
                                type="submit"
                                autoFocus
                                marginLeft="4"
                            >
                                Si, Eliminar
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}

interface DeleteProps {
    title: string
    element: (onClick: () => void) => JSX.Element
    children: ReactNode
    path: string
    done?: () => void
}
