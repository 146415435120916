import {
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    ModalFooter,
    Button,
    ModalHeader
} from '@chakra-ui/react'
import { useState } from 'react'
import { ModalSubFeaturesProps } from './types'

export default function ModalSubFeatures({
    element,
    children,
    title
}: ModalSubFeaturesProps) {
    const [isOpen, setOpen] = useState(false)

    return (
        <>
            {element(() => setOpen(true))}
            <Modal
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                size="lg"
                isCentered
                closeOnOverlayClick
            >
                <ModalOverlay />
                <ModalContent px={3} py={4}>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalBody pb={6}>{children}</ModalBody>
                    <ModalFooter>
                        <Button
                            variant="alo-gray:lg-md"
                            bg="transparent"
                            color="alo-gray"
                            onClick={() => setOpen(false)}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
