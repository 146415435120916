import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text
} from '@chakra-ui/react'

export default function ModalErrorSend({
    isOpen,
    setOpen,
    message,
    receipts
}: {
    isOpen: boolean
    setOpen: (open: boolean) => void
    message: string
    receipts: string[]
}) {
    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                size="md"
                isCentered
            >
                <ModalOverlay />
                <ModalContent px={6} py={4}>
                    <ModalBody>
                        <Text
                            fontSize="2xl"
                            fontWeight="bold"
                            textAlign="center"
                            mb="5"
                        >
                            {message}
                        </Text>
                        {receipts.map((receipt, i) => (
                            <Text key={i} textAlign="center" mb="2">
                                {receipt}
                            </Text>
                        ))}

                        <Flex mt="8" justifyContent="center">
                            <Button
                                variant="outline"
                                w="36"
                                onClick={() => setOpen(false)}
                            >
                                Aceptar
                            </Button>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
