import {
    Box,
    Button,
    Divider,
    Flex,
    Text,
    useDisclosure
} from '@chakra-ui/react'
import ArrowUp from '../../../../components/Icon/ArrowUp'
import Check from '../../../../components/Icon/Check'
import useMenu from '../../../../hooks/useMenu'
import useConfirm from '../../hooks/useConfirm'
import { DetailsContainerProps } from '../types'
import HeadDetails from './HeadDetails'

export default function DetailsContainer({ children }: DetailsContainerProps) {
    const { getPriceTotal, menu } = useMenu()
    const { confirm } = useConfirm()
    const isDisabled = menu.filter(item => item.quantity > 0).length === 0
    const { isOpen, onClose, onOpen } = useDisclosure()

    return (
        <>
            <Box
                p={3}
                position="fixed"
                bottom={0}
                left={0}
                zIndex={1}
                w="full"
                bg="white"
                display={{
                    base: 'auto',
                    lg: 'none'
                }}
                borderTopWidth={1}
            >
                <Button
                    w="full"
                    rounded="full"
                    colorScheme="blackAlpha"
                    bg="black"
                    onClick={onOpen}
                    fill="white"
                    rightIcon={<ArrowUp />}
                >
                    Ver pedido ({menu.length})
                </Button>
            </Box>
            <Flex
                minW={{
                    xl: '450px',
                    lg: '355px',
                    base: 'full'
                }}
                width={{
                    xl: '450px',
                    lg: '355px',
                    base: 'full'
                }}
                h={{
                    base: '100vh',
                    lg: 'auto'
                }}
                py="6"
                position={{ base: 'fixed', lg: 'relative' }}
                top={isOpen ? 0 : '100%'}
                left={0}
                transition=".3s ease"
                flexDirection="column"
                justifyContent="space-between"
                bg="base-3"
                overflow="hidden"
                zIndex={11}
            >
                <Box>
                    <HeadDetails onClose={onClose} />
                    <Divider mb="2" />
                    <Box overflow="auto" maxH="calc(100vh - 290px)">
                        {children}
                    </Box>
                </Box>
                <Box
                    px={{
                        base: 3,
                        md: 6
                    }}
                >
                    <Flex
                        mb="4"
                        justifyContent="space-between"
                        fontWeight="bold"
                    >
                        <Text>TOTAL</Text>
                        <Text fontWeight="bold">
                            S/{getPriceTotal().toFixed(2)}
                        </Text>
                    </Flex>
                    <Button
                        colorScheme="green"
                        size="lg"
                        w="full"
                        leftIcon={<Check fill="white" />}
                        isDisabled={isDisabled}
                        onClick={confirm}
                    >
                        Confirmar pedido
                    </Button>
                </Box>
            </Flex>
        </>
    )
}
