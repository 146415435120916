import { Box, Button, Flex } from '@chakra-ui/react'
import List from '../../../lib/list'
import CardZone from './CardZone'
import Filters from './Filters'
import { listZone } from '../storage'
import useFormZone from './useForm'

export default function Zones() {
    const openModal = useFormZone()

    return (
        <>
            <Box
                marginBottom="5"
                display="flex"
                justifyContent="space-between"
                mt={6}
            >
                <Flex
                    w="full"
                    justifyContent={{ base: 'flex-end', md: 'flex-start' }}
                >
                    <Filters />
                </Flex>
                <Button
                    variant="alo-blue:md-xl"
                    onClick={() => openModal()}
                    display={{ base: 'none', md: 'inline' }}
                    ml="2"
                >
                    Nueva zona
                </Button>
            </Box>
            <List
                control={listZone}
                type="grid"
                tbody={zone => <CardZone key={zone.id} zone={zone} />}
            />
        </>
    )
}
