import { useEffect, useState } from 'react'
import { get } from '../../../lib/http'
import Booking from '../../../models/booking'
import { DriverData, AgencyData } from './types'

export interface TouristGroup {
    driverId?: number
    agencyId?: number
    groupTouristName: string
    nBookings: number
    /**
     * Platos vendidos
     */
    soldPlates: number
    totalSales: number
    commission: number
    bookings: Booking[]
}

export default function useTouristGroups(date: string) {
    const [touristGroups, setTouristGroups] = useState<TouristGroup[]>([])

    async function load() {
        const [resultDrivers, resultAgencies] = await Promise.all([
            get<DriverData[]>(`/api/drivers?date=${date}&is_attented=`),
            get<AgencyData[]>(`/api/agencies?date=${date}&is_attented=`)
        ])

        const data: TouristGroup[] = []

        function totalValues(bookings: Booking[]) {
            const soldPlates = bookings.reduce(
                (n, booking) =>
                    n +
                    booking.orders.reduce(
                        (_n, order) =>
                            _n +
                            order.order_items.reduce(
                                (__n, orderItem) => __n + orderItem.quantity,
                                0
                            ),
                        0
                    ),
                0
            )

            const totalSales = bookings.reduce(
                (n, booking) =>
                    n +
                    booking.orders.reduce(
                        (_n, order) =>
                            _n +
                            order.order_items.reduce(
                                (__n, orderItem) =>
                                    __n + orderItem.quantity * orderItem.price,
                                0
                            ),
                        0
                    ),
                0
            )

            const commission = bookings.reduce(
                (n, booking) =>
                    n +
                    booking.orders.reduce(
                        (_n, order) =>
                            _n +
                            order.order_items.reduce(
                                (__n, orderItem) => __n + orderItem.commission,
                                0
                            ),
                        0
                    ),
                0
            )

            return {
                soldPlates,
                totalSales,
                commission
            }
        }
        if (resultDrivers.data && resultAgencies.data) {
            resultDrivers.data.result.forEach(driver => {
                const { soldPlates, totalSales, commission } = totalValues(
                    driver.bookings
                )
                data.push({
                    driverId: driver.id,
                    groupTouristName: driver.name,
                    nBookings: driver.bookings.length,
                    soldPlates,
                    totalSales,
                    commission,
                    bookings: driver.bookings
                })
            })
            resultAgencies.data.result.forEach(agency => {
                const { soldPlates, totalSales, commission } = totalValues(
                    agency.bookings
                )
                data.push({
                    agencyId: agency.id,
                    groupTouristName: agency.name,
                    nBookings: agency.bookings.length,
                    soldPlates,
                    totalSales,
                    commission,
                    bookings: agency.bookings
                })
            })
        }

        setTouristGroups(
            data.sort((a, b) =>
                a.groupTouristName.localeCompare(b.groupTouristName)
            )
        )
    }

    useEffect(() => {
        load()
    }, [date])

    const totalSoldPlates = touristGroups.reduce(
        (n, touristGroup) => n + touristGroup.soldPlates,
        0
    )
    const totalSales = touristGroups.reduce(
        (n, touristGroup) => n + touristGroup.totalSales,
        0
    )

    const totalCommission = touristGroups.reduce(
        (n, touristGroup) => n + touristGroup.commission,
        0
    )

    return {
        touristGroups,
        totalSoldPlates,
        totalSales,
        totalCommission
    }
}
