import {
    PreOrderCategoryWithItems,
    PreOrderItem
} from '../../../../models/pre_order'

export function getCategoryWithItems(preOrderItems: PreOrderItem[]) {
    const preOrderCategoryWithItems: PreOrderCategoryWithItems[] = []
    for (const _preOrder of preOrderItems) {
        if (_preOrder.quantity > 0) {
            const selectedItem = preOrderCategoryWithItems.find(
                selected =>
                    selected.category_item_id ===
                    _preOrder.item.category_item?.id
            )

            if (selectedItem) {
                selectedItem.items = [
                    ...selectedItem.items,
                    {
                        ..._preOrder.item,
                        quantity: _preOrder.quantity
                    }
                ]
            } else {
                preOrderCategoryWithItems.push({
                    category_item_id: _preOrder.item.category_item?.id ?? 0,
                    category_item_name:
                        _preOrder.item.category_item?.name ?? '',
                    items: [
                        {
                            ..._preOrder.item,
                            quantity: _preOrder.quantity
                        }
                    ]
                })
            }
        }
    }

    return preOrderCategoryWithItems
}
