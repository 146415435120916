import { useRender } from 'redity'
import { Keys } from '../../constants'
import { Box, Heading } from '@chakra-ui/react'
import { storagePage } from '../../storage'
import usePaymentMethods from '../../hooks/usePaymentMethods'
import TablePayments from './TablePayments'
import ListTypePayments from '../../../../components/ListTypePayments'

export default function PaymentMethods() {
    useRender(Keys.PAYMENTS_METHODS)
    const { cashHandler, cardHandler, transferHandler, disabledButton } =
        usePaymentMethods()

    const { paymentTypes, totalFinal } = storagePage

    function maxAmount() {
        let cash = 0
        for (let i = 0; i < storagePage.cash.length; i++) {
            cash += storagePage.cash[i].amount
        }
        let card = 0
        for (let i = 0; i < storagePage.card.length; i++) {
            card += storagePage.card[i].amount
        }
        let transfer = 0
        for (let i = 0; i < storagePage.transfer.length; i++) {
            transfer += storagePage.transfer[i].amount
        }
        const accumulatedPayment = cash + card + transfer
        const lack = totalFinal - accumulatedPayment
        if (lack < 0) return 0
        else return Number(lack.toFixed(2))
    }

    const _maxAmount = maxAmount()

    return (
        <>
            <Box
                mt={{
                    xl: 4,
                    md: 4,
                    base: 3
                }}
            >
                <Heading fontSize="xl" mb={4}>
                    Métodos de Pago
                </Heading>
                <ListTypePayments
                    cashHandler={cashHandler}
                    cardHandler={cardHandler}
                    transferHandler={transferHandler}
                    paymentTypes={paymentTypes}
                    maxAmount={_maxAmount}
                    disabledButton={disabledButton}
                />
                <TablePayments />
            </Box>
        </>
    )
}
