import { Tabs, TabList, Tab, Select, Box } from '@chakra-ui/react'
import { TabZonesProps } from './types'

export default function TabZones({ zones, zoneId, onClick }: TabZonesProps) {
    const index = zones.findIndex(zone => {
        return zone.id == zoneId
    })
    return (
        <>
            <Box mr="auto" display={{ base: 'block', md: 'none' }}>
                <Select
                    onChange={ev => onClick(parseInt(ev.target.value))}
                    value={zoneId}
                >
                    {zones.map(zone => (
                        <option key={zone.id} value={zone.id}>
                            {zone.name}
                        </option>
                    ))}
                </Select>
            </Box>
            <Tabs display={{ base: 'none', md: 'block' }} index={index}>
                <TabList {...(zones.length === 0 && { border: 'none' })}>
                    {zones.map(zone => (
                        <Tab key={zone.id} onClick={() => onClick(zone.id)}>
                            {zone.name}
                        </Tab>
                    ))}
                </TabList>
            </Tabs>
        </>
    )
}
