import colors from '../config/theme/colors'

export default function useListColors() {
    const listColors: string[] = [
        colors.accent,
        colors.orange[400],
        colors.red[500],
        colors.red[400],
        colors['alo-gray'],
        colors.dark
    ]

    let _i = 0
    function get() {
        if (_i > 5) {
            _i = 0
        }
        return listColors[_i++]
    }

    return {
        colors: listColors,
        get
    }
}
