export default function Navigation(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="m15 25 1.762-1.762-6.975-6.988H25v-2.5H9.787l6.988-6.975L15 5 5 15l10 10Z" />
        </svg>
    )
}
