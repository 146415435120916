import { SelectedItem } from '../../../types/runway_state'
import { storagePage } from '../storage'

export default function getItemSelected(
    categoryItemId: number,
    itemId: number,
    idGenerated?: string
) {
    const { selectedItems } = storagePage
    let itemFinded: SelectedItem['items'][number] | null = null

    for (const si of selectedItems) {
        const _itemFinded = si.items.find(
            _item => _item.id === itemId && _item._id_generated === idGenerated
        )
        if (_itemFinded) {
            itemFinded = _itemFinded
            break
        }
    }

    const category = selectedItems.find(
        selected => selected.category_item_id === categoryItemId
    )

    return {
        item: itemFinded,
        category
    }
}
