import {
    Box,
    Flex,
    Input,
    Table,
    TableContainer,
    Tbody,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import Clean from '../../../../components/Clean'
import Pagination from '../../../../components/Pagination'
import { useGet } from '../../../../hooks/useHttp'
import DownloadExcel from './DownloadExcel'
import TableRow from './TableRow'
import { FindItem } from './types'

export default function SectionKardex() {
    const [search, setSearch] = useState('')
    const [startDate, setStartDate] = useState(() =>
        dayjs().set('D', 1).format('YYYY-MM-DD')
    )
    const [endDate, setEndDate] = useState(() => {
        const date = new Date()
        date.setDate(24) //seteamos a 24 en caso la fecha el día sea 31 y evitar que la fecha final sea un mes mas
        date.setMonth(date.getMonth() + 1)
        date.setDate(0)
        return dayjs(date).format('YYYY-MM-DD')
    })
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(25)
    const { data } = useGet<FindItem[]>(
        `/api/items?limit=${limit}&page=${page}&search=${search}&start_date=${dayjs
            .tz(startDate + ' 00:00:00', 'America/Lima')
            .toISOString()}&end_date=${dayjs
            .tz(endDate + ' 23:59:59', 'America/Lima')
            .toISOString()}`,
        []
    )

    return (
        <Box mt={4}>
            <Flex
                justify="space-between"
                mb={4}
                flexDir={['column', 'column', 'row']}
                gap={2}
            >
                <Clean value={search} onClick={() => setSearch('')}>
                    <Input
                        w={{ base: 'full', md: '550px' }}
                        placeholder="🏸 Buscar por nombre de producto"
                        value={search}
                        onChange={ev => setSearch(ev.target.value)}
                    />
                </Clean>
                <Flex gap={2} flexDir={['column', 'column', 'row']}>
                    <Input
                        type="date"
                        value={startDate}
                        max={endDate}
                        onChange={ev => setStartDate(ev.target.value)}
                    />
                    <Input
                        type="date"
                        value={endDate}
                        onChange={ev => setEndDate(ev.target.value)}
                        min={startDate}
                    />
                    <DownloadExcel
                        startDate={dayjs
                            .tz(startDate + ' 00:00:00', 'America/Lima')
                            .toISOString()}
                        endDate={dayjs
                            .tz(endDate + ' 23:59:59', 'America/Lima')
                            .toISOString()}
                    />
                </Flex>
            </Flex>
            <TableContainer>
                <Table size="md" className="table-a-la-orden">
                    <Thead>
                        <Tr>
                            <Th textAlign="center">Producto</Th>
                            <Th textAlign="center">Stock Inicial</Th>
                            <Th textAlign="center">Ingresos</Th>
                            <Th textAlign="center">Salidas</Th>
                            <Th textAlign="center">Stock Final</Th>
                            <Th textAlign="center">Acciones</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.result.map(item => (
                            <TableRow
                                key={item.id}
                                item={item}
                                startDate={startDate}
                                endDate={endDate}
                            />
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <Pagination
                limit={limit}
                page={page}
                pages={data.pagination?.pages || 0}
                onLimit={setLimit}
                onPage={setPage}
            />
        </Box>
    )
}
