export default function getItemsbyCategory(data: any[]) {
    const orderItemsObject: any = {}
    let amount = 0
    for (const orderItem of data) {
        amount = amount + orderItem.quantity * orderItem.price
        if (orderItemsObject[orderItem['item']?.['category_item_id']]) {
            orderItemsObject[orderItem['item']?.['category_item_id']] = {
                ...orderItemsObject[orderItem['item']?.['category_item_id']],
                items: [
                    ...orderItemsObject[orderItem['item']?.['category_item_id']]
                        .items,
                    {
                        id: orderItem.item.id,
                        name: orderItem.item.name,
                        quantity: orderItem.quantity,
                        total: orderItem.quantity * orderItem.price
                    }
                ]
            }
        } else {
            orderItemsObject[orderItem['item']?.['category_item_id']] = {
                id: orderItem.item?.['category_item'].id,
                name: orderItem.item?.['category_item'].name,
                items: [
                    {
                        id: orderItem.item.id,
                        name: orderItem.item.name,
                        quantity: orderItem.quantity,
                        total: orderItem.quantity * orderItem.price
                    }
                ]
            }
        }
    }

    return {
        amount: Math.round(amount * 100) / 100,
        items: Object.values(orderItemsObject)
    }
}

export function getCashBack(
    paymentTotalWithDiscount: string | number,
    paymentTotal: number,
    amount: number
) {
    if (paymentTotalWithDiscount === '-') {
        return paymentTotal - amount < 0 ? 0 : paymentTotal - amount
    } else {
        return paymentTotal - Number(paymentTotalWithDiscount) < 0
            ? 0
            : paymentTotal - Number(paymentTotalWithDiscount)
    }
}

export function getFinalPayments(payments: any[], total_final: number) {
    const paymentsData: any = []

    const paymentsObject: any = []
    for (const payment of payments) {
        paymentsObject[payment.type] = {
            ...payment
        }
    }

    if (paymentsObject['Tarjeta']) {
        let restTotal = 0
        const amountCard = paymentsObject['Tarjeta'].amount
        restTotal = total_final - amountCard
        paymentsData.push({
            description: 'Tarjeta',
            amount: restTotal >= 0 ? amountCard : total_final
        })

        if (restTotal > 0 && paymentsObject['Transferencia']) {
            const amountTransfer = paymentsObject['Transferencia'].amount
            const restTotalTemp = restTotal

            restTotal = restTotal - amountTransfer

            paymentsData.push({
                description: 'Transferencia',
                amount: restTotal >= 0 ? amountTransfer : restTotalTemp
            })
        }

        if (restTotal > 0 && paymentsObject['Efectivo']) {
            paymentsData.push({
                description: 'Efectivo',
                amount: restTotal
            })
        }
    } else {
        let _restTotal = total_final

        if (paymentsObject['Transferencia']) {
            const amountTransfer = paymentsObject['Transferencia'].amount

            _restTotal = _restTotal - amountTransfer

            paymentsData.push({
                description: 'Transferencia',
                amount: _restTotal >= 0 ? amountTransfer : total_final
            })
        }
        if (_restTotal > 0 && paymentsObject['Efectivo']) {
            const amountCash = paymentsObject['Efectivo'].amount
            const restTotalTemp = _restTotal
            _restTotal = _restTotal - amountCash
            paymentsData.push({
                description: 'Efectivo',
                amount: _restTotal >= 0 ? amountCash : restTotalTemp
            })
        }
    }

    return paymentsData
}
