import { useToast } from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import DeveliryContext from '../context/DeliveryContext'
import { FindOneDelivery } from '../context/DeliveryContext/types'
import { get } from '../lib/http'

export default function useDelivery(deliveryId = 0) {
    const toast = useToast()
    const context = useContext(DeveliryContext)
    const [isLoading, setIsLoading] = useState(deliveryId ? true : false)

    async function load() {
        const { error, data } = await get<FindOneDelivery>(
            `/api/deliveries/${deliveryId}`
        )
        if (error) {
            toast({
                title: 'Error al cargar los datos'
            })
            return
        }
        setIsLoading(false)
        context.setDelivery(data.result)
        context.setClient(data.result.client)
    }

    useEffect(() => {
        if (!deliveryId) return
        load()
    }, [deliveryId])

    return {
        ...context,
        isLoading
    }
}
