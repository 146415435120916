import { Box, Text, UnorderedList, ListItem } from '@chakra-ui/react'
import colors from '../../../../config/theme/colors'
import { FeatureSetting } from '../../../../types/runway_state'

export default function FeatureSettings({
    featureSettings
}: {
    featureSettings?: FeatureSetting[]
}) {
    return (
        <>
            {featureSettings &&
                featureSettings.map((fes, _i) => (
                    <Box key={_i} fontSize="14px">
                        <Text pl={6} fontWeight="bold">
                            {fes.name}
                        </Text>
                        <UnorderedList
                            styleType="none"
                            color={colors['gray-3']}
                            spacing={1}
                        >
                            {fes.feature_setting_sub_features.map(
                                (fssf, __i) => (
                                    <ListItem
                                        display="flex"
                                        justifyContent="space-between"
                                        key={__i}
                                        pl={3}
                                    >
                                        <Box>
                                            <Box
                                                mr={1}
                                                minW="2"
                                                as="span"
                                                display="inline-block"
                                            >
                                                {fssf.quantity}
                                            </Box>
                                            {fssf.name}
                                        </Box>
                                        <Box w="20">
                                            S/{' '}
                                            {(
                                                fssf.price * fssf.quantity
                                            ).toFixed(2)}
                                        </Box>
                                    </ListItem>
                                )
                            )}
                        </UnorderedList>
                    </Box>
                ))}
        </>
    )
}
