import { Box, Heading, Text } from '@chakra-ui/react'
import MenuItem from './MenuItem'
import { MenuItem as MenuItemInterface } from '../../../../../context/MenuContext'
import useMenu from '../../../../../hooks/useMenu'

interface ByCategory {
    categoryName: string
    menuItems: MenuItemInterface[]
}

export default function Menu() {
    const { menu, updateQuantity } = useMenu()

    const byCategories: ByCategory[] = []
    menu.forEach(item => {
        if (!item.quantity) return

        const by = byCategories.find(
            _by => _by.categoryName === item.categoryName
        )
        if (by) {
            by.menuItems.push(item)
            return
        }

        byCategories.push({
            categoryName: item.categoryName,
            menuItems: [item]
        })
    })

    return (
        <>
            {byCategories.map((by, i) => (
                <Box color="alo-gray" key={i}>
                    <Heading size="sm" my="3" px={{ base: 3, lg: 6 }}>
                        {by.categoryName}
                    </Heading>
                    {by.menuItems.map(menuItem => (
                        <MenuItem
                            key={menuItem.hash}
                            menuItem={menuItem}
                            onDelete={() => updateQuantity(menuItem.hash, 0)}
                            onClick={() => null}
                        />
                    ))}
                </Box>
            ))}
            {byCategories.length === 0 && (
                <Text color="gray-3" fontSize="sm" textAlign="center" mt={2}>
                    Selecciona platos o bebidas
                </Text>
            )}
        </>
    )
}
