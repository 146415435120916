import { Box, Flex, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { render } from 'redity'
import Keys from '../keys'
import { storagePage } from '../storage'
import { MatrixItem } from '../types'

export default function Item({ index, matrix_item }: PropsItem) {
    const [item, setItems] = useState([])

    const handleOnChange = (e: any) => {
        if (e.target.checked) {
            setItems([...item])
            storagePage.items.push(matrix_item)
            storagePage.items_to_divide.push({
                id: matrix_item.id,
                quantity_to_divide: matrix_item.quantity
            })
        } else {
            storagePage.items.splice(storagePage.items.indexOf(matrix_item), 1)
            storagePage.items_to_divide.splice(
                storagePage.items.indexOf(matrix_item),
                1
            )
        }
        render(Keys.ORDER_ITEMS)
    }

    return (
        <>
            <label
                style={{
                    cursor: 'pointer',
                    display: 'grid',
                    gridTemplateColumns: '1em auto',
                    gap: '0.5em',
                    paddingLeft: '1.5em',
                    paddingRight: '1.5em'
                }}
            >
                <input
                    type="checkbox"
                    checked={item[index]}
                    onClick={handleOnChange}
                    defaultChecked={false}
                    value={index}
                    style={{
                        border: '2px solid red',
                        cursor: 'pointer',
                        height: '18px',
                        width: '18px',
                        alignSelf: 'center'
                    }}
                />
                <Flex width="full" justifyContent="space-between" py={1}>
                    <Box display="flex" mr={8} alignItems="center">
                        <Text w="7" pl={3}>
                            {matrix_item.quantity}
                        </Text>
                        <Text fontSize="sm">{matrix_item.item.name}</Text>
                    </Box>
                    <Text fontSize="sm" w="24" textAlign="right">
                        S/ {matrix_item.final_price_item.toFixed(2)}
                    </Text>
                </Flex>
            </label>
        </>
    )
}

interface PropsItem {
    index: number
    matrix_item: MatrixItem
}
