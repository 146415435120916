export default function Camera(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.937 2.845c.07-.098.15-.219.25-.381l.21-.347h.001l.084-.139C7.31.622 7.913 0 9 0h4c1.087 0 1.69.622 2.518 1.978l.084.139.211.347c.1.162.18.283.25.381.047.065.088.117.12.155H19a3 3 0 013 3v9a3 3 0 01-3 3H3a3 3 0 01-3-3V6a3 3 0 013-3h2.816c.033-.038.074-.09.121-.155zM3 5a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1V6a1 1 0 00-1-1h-3c-.664 0-1.112-.364-1.56-.987a8.42 8.42 0 01-.329-.499 82.367 82.367 0 01-.226-.372l-.073-.12C13.36 2.282 13.087 2 13 2H9c-.087 0-.36.282-.812 1.022l-.073.12-.226.372c-.123.2-.225.356-.328.5C7.112 4.635 6.664 5 6 5H3zm16 2a1 1 0 11-2 0 1 1 0 012 0zM6 10a5 5 0 1010 0 5 5 0 00-10 0zm8 0a3 3 0 11-6 0 3 3 0 016 0z"
            />
        </svg>
    )
}
