import { Box, Flex, Input } from '@chakra-ui/react'
import Clean from '../../../components/Clean'
import { controlList } from '../storage'
import Dates from './Dates'
import Download from './Download'
import TypeProofFilter from './TypeProofFilter'

export default function Actions() {
    const { clear, ...props } = controlList.useSearch()

    return (
        <Box mb="5">
            <Flex
                justifyContent={{ base: 'flex-end', xl: 'space-between' }}
                mb="5"
                gridGap={5}
                direction={{ base: 'column', md: 'row' }}
            >
                <Flex gridGap={4} direction={{ base: 'column', md: 'row' }}>
                    <TypeProofFilter />
                    <Dates />
                </Flex>
                <Box display={{ base: 'none', xl: 'block' }}>
                    <Download />
                </Box>
            </Flex>
            <Flex justify="space-between">
                <Clean value={props.value} onClick={clear}>
                    <Input
                        w={{ base: 'full', md: '550px' }}
                        placeholder="🏸 Buscar por comprobante, nombre o documento de cliente"
                        {...props}
                    />
                </Clean>
                <Box display={{ base: 'block', xl: 'none' }}>
                    <Download />
                </Box>
            </Flex>
        </Box>
    )
}
