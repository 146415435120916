import { Box, SimpleGrid } from '@chakra-ui/react'
import { useRender } from 'redity'
import Keys from '../../keys'
import { nextColors, storagePage } from '../../storage'
import BoxItem from './BoxItem'
import useStorage from '../../../../hooks/useStorage'

export default function ListItems() {
    const { business_variables } = useStorage()
    useRender(Keys.LIST_ITEM)

    const var_enabled_validation_stock = business_variables.find(
        v => v.code === '025'
    )?.value
    const enabled_validation_stock =
        var_enabled_validation_stock === '1' ? true : false

    let _i = 0

    function getColor() {
        if (_i > 5) {
            _i = 0
        }

        return nextColors[_i++]
    }

    return (
        <Box
            w="full"
            p={{
                base: '4',
                lg: '5'
            }}
            bg="white"
            maxH={{
                base: 'calc(100vh - 260px)',
                lg: 'calc(100vh - 60px)'
            }}
            overflow="auto"
        >
            {storagePage.items.length > 0 && (
                <SimpleGrid
                    spacing={3}
                    minChildWidth={{
                        sm: '150px',
                        xl: '160px'
                    }}
                >
                    {storagePage.items.map((item, i) => (
                        <BoxItem
                            index={i}
                            item={item}
                            bg={getColor()}
                            key={i}
                            enabled_validation_stock={enabled_validation_stock}
                        />
                    ))}
                </SimpleGrid>
            )}
            {storagePage.items.length === 0 && (
                <Box textAlign="center" color="gray-3">
                    No existen resultados
                </Box>
            )}
        </Box>
    )
}
