import useTab from '../../hooks/useTab'
import useCategory from './Category/useCategory'
import useItem from './Item/useItem'

export default function useControl() {
    const openModalItem = useItem()
    const openModalCategory = useCategory()
    const props = useTab('production_items')

    function openModal() {
        if (props.value === 'production_items') {
            openModalItem()
        }
        if (props.value === 'prod_item_categories') {
            openModalCategory()
        }
    }

    function getTextButton() {
        switch (props.value) {
            case 'production_items':
                return 'Nuevo artículo'
            case 'prod_item_categories':
                return 'Nueva categoría'
            default:
                return 'Nuevo'
        }
    }

    return {
        openModal,
        getTextButton,
        tabProps: props
    }
}
