import { Button, Flex, Input } from '@chakra-ui/react'
import { ChangeEvent } from 'react'
import Clean from '../../../../components/Clean'
import SelectWithLabel from '../../../../components/SelectWithLabel'
import Warehouse from '../../../../models/warehouse'
import { listProducts } from '../../storage'
import ProductForm from './ProductForm'

export default function Actions({ warehouses }: { warehouses: Warehouse[] }) {
    const { clear, ...props } = listProducts.useSearch()

    function selectHandler(ev: ChangeEvent<HTMLSelectElement>) {
        const value = ev.target.value
        if (value === '') {
            listProducts.deleteQuery('warehouse_id')
        } else {
            listProducts.setQuery('warehouse_id', value)
        }
        listProducts.load()
    }

    return (
        <Flex
            mt={4}
            mb={4}
            w={{
                xl: 'auto',
                lg: 'full'
            }}
            justifyContent={{
                md: 'space-between',
                base: 'flex-end'
            }}
        >
            <Flex>
                <Clean value={props.value} onClick={clear}>
                    <Input {...props} placeholder="🏸 Buscar por producto" />
                </Clean>
                <SelectWithLabel
                    ml={4}
                    label="Seleccionar almacén:"
                    placeholder="Todos"
                    onChange={selectHandler}
                    defaultValue={
                        (listProducts.getQuery('warehouse_id') as string) || ''
                    }
                >
                    {warehouses.map(warehouse => (
                        <option key={warehouse.id} value={warehouse.id}>
                            {warehouse.name}
                        </option>
                    ))}
                </SelectWithLabel>
            </Flex>
            <ProductForm
                element={click => (
                    <Button
                        onClick={click}
                        variant="alo-blue:md-xl"
                        display={{ base: 'none', md: 'inline' }}
                        ml="2"
                    >
                        Nuevo producto
                    </Button>
                )}
                onDone={() => listProducts.load()}
            />
        </Flex>
    )
}
