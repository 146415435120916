import { Box, Image } from '@chakra-ui/react'
import logo from '../../assets/logo-alt.svg'

export default function LogoBox() {
    return (
        <Box>
            <Image
                w="97px"
                h="68px"
                src={logo}
                alt="logo"
                marginX="auto"
                marginBottom={20}
            />
        </Box>
    )
}
