import { Icon, Td, Tr } from '@chakra-ui/react'
import { useEffect } from 'react'
import Pencil from '../../../../components/Icon/Pencil'
import Trash from '../../../../components/Icon/Trash'
import colors from '../../../../config/theme/colors'
import Delete from '../../../../lib/delete'
import List from '../../../../lib/list'
import { listWarehouses } from '../../storage'
import Actions from './Actions'
import WarehouseForm from './WarehouseForm'

export default function WarehousesSection({ reload }: { reload: () => void }) {
    useEffect(() => {
        listWarehouses.load()
    }, [])

    return (
        <>
            <Actions reload={reload} />
            <List
                control={listWarehouses}
                tbody={(warehouse, e) => (
                    <Tr key={e}>
                        <Td textAlign="center">{warehouse.name}</Td>
                        <Td textAlign="center">
                            <WarehouseForm
                                element={click => (
                                    <Icon
                                        fill={colors['alo-blue']}
                                        as={Pencil}
                                        marginRight={2}
                                        cursor="pointer"
                                        onClick={click}
                                    />
                                )}
                                onDone={() => {
                                    listWarehouses.load()
                                    reload()
                                }}
                                edition={warehouse}
                            />
                            <Delete
                                title="Eliminar almacén"
                                path={`/api/warehouses/${warehouse.id}`}
                                element={click => (
                                    <Icon
                                        onClick={click}
                                        fill={colors.error}
                                        as={Trash}
                                        cursor="pointer"
                                    />
                                )}
                                done={() => {
                                    listWarehouses.setQuery('page', 1)
                                    listWarehouses.load()
                                    reload()
                                }}
                            >
                                ¿Esta seguro de eliminar el almacén{' '}
                                <b>{warehouse.name}</b>?
                            </Delete>
                        </Td>
                    </Tr>
                )}
            />
        </>
    )
}
