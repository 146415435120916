import { Box, Heading } from '@chakra-ui/react'
import { Fragment } from 'react'
import Item from './Item'
import { SelectedItem } from '../../types/runway_state'

export default function ListItems({ items }: { items: SelectedItem[] }) {
    function getPrice(item: SelectedItem['items'][number]) {
        let restCost = 0
        if (item.feature_settings) {
            restCost = item.feature_settings.reduce(
                (c, d) =>
                    c +
                    d.feature_setting_sub_features.reduce(
                        (e, f) => e + f.price * f.quantity,
                        0
                    ),
                0
            )
        }
        return (item.price + restCost) * item.quantity
    }

    return (
        <Box maxH="calc(100vh - 450px)" overflowY="auto">
            {items.map(selected => (
                <Fragment key={selected.category_item_id}>
                    <Heading size="sm" textTransform="uppercase" mb="3" px="8">
                        {selected.category_item_name}
                    </Heading>
                    <Box maxH="500px" overflowY="auto" px="8" mb={2}>
                        {selected.items.map((item, i) => (
                            <Item
                                quantity={item.quantity}
                                key={i}
                                description={item.name}
                                cost={`S/ ${getPrice(item).toFixed(2)}`}
                                featureSettings={item?.feature_settings || []}
                            />
                        ))}
                    </Box>
                </Fragment>
            ))}
        </Box>
    )
}
