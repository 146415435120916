import { Button, Flex, Text } from '@chakra-ui/react'
import colors from '../../../../../config/theme/colors'
import { CounterProps } from '../types'

export default function Counter({ counter, setCounter }: CounterProps) {
    return (
        <Flex alignItems="center" color="white" justifyContent="center" mb={4}>
            <Button
                bg={colors.dark}
                w={14}
                h={14}
                borderRadius="lg"
                fontSize="3xl"
                onClick={() => setCounter(counter - 1)}
                cursor="pointer"
                {...(counter === 1 && {
                    opacity: '.6',
                    pointerEvents: 'none'
                })}
                _hover={{}}
                _active={{}}
            >
                -
            </Button>
            <Text
                fontSize="xl"
                color={colors.dark}
                mx={2}
                minW={14}
                textAlign="center"
            >
                {counter}
            </Text>
            <Button
                bg={colors.dark}
                w={14}
                h={14}
                borderRadius="lg"
                fontSize="3xl"
                onClick={() => setCounter(counter + 1)}
                cursor="pointer"
                _hover={{}}
                _active={{}}
            >
                +
            </Button>
        </Flex>
    )
}
