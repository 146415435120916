import { useEffect, useState } from 'react'
import PageState from '../../types/pageState'
import { useParams } from 'react-router-dom'
import { get } from '../../lib/http'

import { storagePage } from './storage'
import orderItemsToSelectedItems from '../QuickSale/utilities/orderItemsToSelectedItems'
import { PaymentTypeResponse, Response } from './types'
import { Result } from '../../lib/http/type'
import {
    TYPES_DISCOUNT,
    TYPES_ORDER_PAYMENT,
    TYPES_PAYMENTS
} from './constants'
import clearStates from './utilities/clearStates'

export default function useInitial() {
    const [pageState, setPageState] = useState<PageState>(PageState.LOADING)

    const { order_payment_id } = useParams<{ order_payment_id?: string }>()
    async function loadApis() {
        const request: Array<Promise<any>> = [
            get<Response>(`/api/v2/order_payments/${order_payment_id}/detail`),
            get<PaymentTypeResponse>('/api/type_payments/all')
        ]

        const [result1, result2] = (await Promise.all(request)) as [
            Result<Response>,
            Result<PaymentTypeResponse>
        ]

        if (result1.error || result2.error) {
            setPageState(PageState.ERROR)
            return
        }

        const responseData = result1.data

        storagePage.selectedItems = orderItemsToSelectedItems(
            responseData.result.order_items as any
        )
        storagePage.client = responseData.result?.client
        storagePage.typeConsumption = responseData.result?.type_consumption
        storagePage.totalFinal = responseData.result.total_final
        storagePage.totalOriginal = responseData.result.total_original
        storagePage.discount = responseData.result.discount
        storagePage.subTotal = responseData.result.subtotal
        storagePage.alias = responseData.result.alias
        storagePage.code = responseData.result.code

        let typeDiscount: string = TYPES_DISCOUNT.AMOUNT
        if (responseData.result.percentage_discount) {
            typeDiscount = TYPES_DISCOUNT.PERCENTAGE
            storagePage.percentageDiscount =
                responseData.result.percentage_discount
        }
        storagePage.typeDiscount = typeDiscount

        if (responseData.result.courtesy) {
            storagePage.type = TYPES_ORDER_PAYMENT.FREE as any
        }

        const card: any[] = []
        const cash: any[] = []
        const transfer: any[] = []
        for (const order_payment_detail of responseData.result
            .order_payment_details) {
            switch (order_payment_detail.type_payment.name) {
                case TYPES_PAYMENTS.CARD:
                    card.push(order_payment_detail)
                    break
                case TYPES_PAYMENTS.TRANSFER:
                    transfer.push({
                        ...order_payment_detail,
                        sub_payment_name:
                            order_payment_detail.type_payment.sub_payment_type
                                ?.name ?? ''
                    })
                    break
                case TYPES_PAYMENTS.CASH:
                    cash.push(order_payment_detail)
                    break
            }
        }
        storagePage.cash = cash
        storagePage.transfer = transfer
        storagePage.card = card

        storagePage.paymentTypes = result2.data.result.result

        setPageState(PageState.SUCCESS)
    }

    useEffect(() => {
        loadApis()
        return () => {
            clearStates()
        }
    }, [])

    return {
        pageState
    }
}
