import { Box } from '@chakra-ui/react'
import { ItemWaiterProps } from './types'

export default function ItemWaiter({
    isActive,
    children,
    onClick
}: ItemWaiterProps) {
    return (
        <Box
            // key={waiter.id}
            h="100px"
            borderRadius="lg"
            px="4"
            border="1px solid #D1D5DB"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontSize="md"
            cursor="pointer"
            textAlign="center"
            bg={isActive ? 'alo-green' : ''}
            color={isActive ? 'white' : 'gray-3'}
            onClick={onClick}
        >
            {children}
        </Box>
    )
}
