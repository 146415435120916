import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { memo } from 'react'
import TableRow from './TableRow'
import { TableBanknotesProps } from './types'

function TableBanknotes({
    banknotesValues,
    setBanknotesValues,
    total
}: TableBanknotesProps) {
    return (
        <Table size="sm" w="full">
            <Thead>
                <Tr>
                    <Th textAlign="center" colSpan={3}>
                        Billetes
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                <Tr bg="#d0d9df">
                    <Th whiteSpace="nowrap">Denominación</Th>
                    <Th whiteSpace="nowrap" textAlign="center">
                        Cantidad
                    </Th>
                    <Th whiteSpace="nowrap" textAlign="center">
                        Total
                    </Th>
                </Tr>
                {Object.entries(banknotesValues).map(
                    ([banknote, { quantity, denominationMoneyId }], i) => (
                        <TableRow
                            autoFocus={i === 0}
                            key={banknote}
                            banknote={parseInt(banknote)}
                            quantity={quantity}
                            onChange={nextQuantity =>
                                setBanknotesValues({
                                    ...banknotesValues,
                                    [banknote]: {
                                        denominationMoneyId,
                                        quantity: nextQuantity
                                    }
                                })
                            }
                        />
                    )
                )}
                <Tr bg="gray.100">
                    <Th />
                    <Th textAlign="right">Total:</Th>
                    <Th>
                        S/
                        {total.toFixed(2)}
                    </Th>
                </Tr>
            </Tbody>
        </Table>
    )
}

export default memo(TableBanknotes)
