import { Select, Spinner } from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
import { render } from 'redity'
import colors from '../../../config/theme/colors'
import useStorage from '../../../hooks/useStorage'
import CashBox from '../../../models/cashbox'
import { Keys } from '../constant'
import { storagePage } from '../storage'
import loadMain from '../utilities/loadMain'

export default function CashboxField() {
    const { cashboxes } = useStorage()
    const [isLoading, setLoading] = useState(false)
    const id = storagePage.currentCashbox?.id || 0

    async function changeHandler(ev: ChangeEvent<HTMLSelectElement>) {
        storagePage.currentCashbox = cashboxes.find(
            cashbox => cashbox.id === parseInt(ev.target.value)
        ) as CashBox
        setLoading(true)
        await loadMain(storagePage.currentCashbox?.id || 1)
        render(Keys.MAIN)
        setLoading(false)
    }

    return (
        <>
            <Select
                w="auto"
                size="md"
                bg={colors['base-4']}
                borderRadius="full"
                fontFamily="Poppins-Semibold"
                defaultValue={id}
                onChange={changeHandler}
            >
                {cashboxes.map(cashbox => (
                    <option value={cashbox.id} key={cashbox.id}>
                        {cashbox.name}
                    </option>
                ))}
            </Select>
            {isLoading && <Spinner ml={4} color="gray-3" />}
        </>
    )
}
