import FormEvent from '../../lib/form_event'
import ControlList from '../../lib/list/ControlList'
import { CreditOrderData, StoragePage } from './types'

export const controlList = new ControlList<CreditOrderData>({
    path: '/api/outstandings/installments',
    theads: [
        '#',
        'Emisión',
        'Vencimiento',
        'Usuario',
        'Comprobante',
        'Cliente',
        'Monto',
        'Saldo',
        'Estado pago',
        'Acciones'
    ]
})

export const storagePage: StoragePage = {
    payment_methods: []
}

export const formPayment = new FormEvent<any>({
    payment_date: '',
    destiny: '',
    payment_type: '',
    sub_payment_type: '',
    amount: '',
    schedule: ''
})
