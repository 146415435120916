import { Box, Stack, VStack } from '@chakra-ui/react'
import PhonesList from './PhonesList'
import AddressesList from './AddressesList'
import DeliveryDetails from './DeliveryDetails'
import PaymentDetails from './PaymentDetails'

export default function ClientForm() {
    return (
        <Box w="full">
            <VStack spacing={3}>
                <Stack
                    w="full"
                    alignItems="stretch"
                    spacing={3}
                    direction={{
                        base: 'column',
                        md: 'row'
                    }}
                >
                    <PhonesList />
                    <AddressesList />
                </Stack>
                <Stack
                    w="full"
                    alignItems="stretch"
                    spacing={3}
                    direction={{
                        base: 'column',
                        md: 'row'
                    }}
                >
                    <DeliveryDetails />
                    <PaymentDetails />
                </Stack>
            </VStack>
        </Box>
    )
}
