import { SVGProps } from 'react'

const Alarm = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_2_40682)">
            <path d="M12 21.9999C10.8181 21.9999 9.64778 21.7672 8.55585 21.3149C7.46392 20.8626 6.47177 20.1996 5.63604 19.3639C4.80031 18.5282 4.13738 17.536 3.68509 16.4441C3.23279 15.3522 3 14.1818 3 12.9999C3 11.8181 3.23279 10.6477 3.68509 9.5558C4.13738 8.46387 4.80031 7.47171 5.63604 6.63599C6.47177 5.80026 7.46392 5.13732 8.55585 4.68503C9.64778 4.23274 10.8181 3.99995 12 3.99995C14.387 3.99995 16.6761 4.94816 18.364 6.63599C20.0518 8.32381 21 10.613 21 12.9999C21 15.3869 20.0518 17.6761 18.364 19.3639C16.6761 21.0517 14.387 21.9999 12 21.9999ZM12 19.9999C12.9193 19.9999 13.8295 19.8189 14.6788 19.4671C15.5281 19.1153 16.2997 18.5997 16.9497 17.9497C17.5998 17.2997 18.1154 16.528 18.4672 15.6787C18.8189 14.8295 19 13.9192 19 12.9999C19 12.0807 18.8189 11.1704 18.4672 10.3212C18.1154 9.47189 17.5998 8.70021 16.9497 8.0502C16.2997 7.40019 15.5281 6.88457 14.6788 6.53279C13.8295 6.18101 12.9193 5.99995 12 5.99995C10.1435 5.99995 8.36301 6.73745 7.05025 8.0502C5.7375 9.36296 5 11.1434 5 12.9999C5 14.8565 5.7375 16.6369 7.05025 17.9497C8.36301 19.2625 10.1435 19.9999 12 19.9999ZM13 12.9999H16V14.9999H11V7.99995H13V12.9999ZM1.747 6.28195L5.282 2.74695L6.697 4.16095L3.16 7.69695L1.747 6.28195ZM18.717 2.74695L22.253 6.28195L20.839 7.69695L17.303 4.16095L18.718 2.74695H18.717Z" />
        </g>
        <defs>
            <clipPath id="clip0_2_40682">
                <rect width={24} height={24} fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export default Alarm
