import { get } from '../../../lib/http'
import PageState from '../../../types/pageState'
import { storagePage } from '../storage'
import { CashboxUserData, DenominationMoneyData } from '../types'

export default async function loadMain(cashboxId: number) {
    const [result1, result2, result3] = await Promise.all([
        get<CashboxUserData | null>(`/api/cashbox_users/cash_box_user`),
        get<CashboxUserData>(`/api/cashbox_users/cash_box_user/${cashboxId}`),
        get<DenominationMoneyData[]>('/api/cash_counts/denomination_money')
    ])

    if (result1.error || result2.error || result3.error) {
        return PageState.ERROR
    }

    storagePage.cashboxUser = storagePage.isAdmin
        ? result2.data.result
        : result1.data.result
    storagePage.userSessionHaveOpenBox = !!result1.data.result
    storagePage.isYourBox = storagePage.isAdmin
        ? result1.data?.result?.cashbox_id === result2.data?.result?.cashbox_id
        : true
    storagePage.denominationMoneys = result3.data.result

    return PageState.SUCCESS
}
