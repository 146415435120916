import dayjs from 'dayjs'
import { render } from 'redity'
import { Keys } from '../constants'
import { storagePage } from '../storage'

export default function clearStates() {
    storagePage.issueDate = dayjs().format('YYYY-MM-DD')
    storagePage.dueDate = dayjs().format('YYYY-MM-DD')
    storagePage.type = 'ticket'
    storagePage.selectedItems = []
    storagePage.typeTax = 'levied'
    storagePage.condition = 'cash'
    storagePage.typeDiscount = 'amount'
    storagePage.discount = 0
    storagePage.card = []
    storagePage.cash = []
    storagePage.transfer = []
    storagePage.creditFees = []
    render(Keys.MAIN)
}
