import createKeys from '../../utilities/createKeys'

const Keys = createKeys('QUICK_SALE', [
    'LIST_CATEGORIES',
    'LIST_ITEM',
    'DETAILS_LIST_ITEMS',
    'CONTENT_BOTTOM',
    'CONTENT_LEFT',
    'CONTENT_RIGHT',
    'BUTTON_SEE_ORDER',
    'CONTENT_TOP'
])

export default Keys
