import { Flex, Select, Button } from '@chakra-ui/react'
import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import Tabs, { Tab } from '../../components/Tabs'
import Tables from './Tables'
import useInitial from './useInitial'
import Zones from './Zones'
import useFormTable from './Tables/useForm'
import useFormZone from './Zones/useForm'

export default function TableManagement() {
    const { tabProps } = useInitial()
    const openModalTable = useFormTable()
    const openModalZone = useFormZone()

    function openModal() {
        if (tabProps.value === 'table') {
            openModalTable()
        }
        if (tabProps.value === 'zone') {
            openModalZone()
        }
    }

    return (
        <Sidebar>
            <SimpleNav title="Gestión de mesas" />
            <Tabs {...tabProps}>
                <Tab value="table">Mesas</Tab>
                <Tab value="zone">Ambientes</Tab>
            </Tabs>
            <Flex
                justifyContent="space-between"
                display={{ base: 'flex', md: 'none' }}
            >
                <Select
                    w="200px"
                    value={tabProps.value}
                    onChange={e => tabProps.onTab(e.target.value)}
                >
                    <option value="table">Mesas</option>
                    <option value="zone">Ambientes</option>
                </Select>
                <Button variant="alo-blue:md-xl" onClick={() => openModal()}>
                    + Nuevo
                </Button>
            </Flex>
            {tabProps.value === 'table' && <Tables />}
            {tabProps.value === 'zone' && <Zones />}
        </Sidebar>
    )
}
