import {
    Box,
    Flex,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { get } from '../../../../lib/http'
import CategoryItem from '../../../../models/category_item'
import Item from '../../../../models/item'
import OrderItem from '../../../../models/order_item'
import TableInfo from './TableInfo'
import { FindOneBooking, ModalBookingProps } from './types'

export default function ModalBooking({
    isOpen,
    onClose,
    bookingId
}: ModalBookingProps) {
    const [booking, setBooking] = useState<FindOneBooking | null>(null)

    async function load() {
        const { data } = await get<FindOneBooking>(`/api/bookings/${bookingId}`)
        if (data) {
            setBooking(data.result)
        }
    }

    useEffect(() => {
        if (!isOpen) return
        load()
    }, [isOpen])

    const name = booking?.agency?.name || booking?.driver?.name || ''
    const guideName = booking?.agency_guide?.name || ''
    const categories: Array<
        CategoryItem & {
            orderItems: FindOneBooking['orders'][number]['order_items']
        }
    > = []
    let totalCommission = 0

    if (booking) {
        booking.orders
            .filter(order => order.order_state !== 2)
            .forEach(order => {
                order.order_items.forEach(orderItem => {
                    totalCommission =
                        totalCommission +
                        orderItem.commission * orderItem.quantity
                    const category = categories.find(
                        _category =>
                            _category.id === orderItem.item.category_item_id
                    )
                    if (category) {
                        category.orderItems.push(orderItem)
                        return
                    }
                    categories.push({
                        ...orderItem.item.category_item,
                        orderItems: [orderItem]
                    })
                })
            })
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick
            size="md"
            isCentered
        >
            <ModalOverlay />
            <ModalContent px={6} pt={4} pb={10}>
                <ModalHeader textAlign="center" pb={6}>
                    <Text fontSize="2xl">Reserva</Text>
                    <Text fontSize="sm">
                        {name}
                        {guideName ? ` - ${guideName} ` : ''}
                    </Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack w="full" mb={4}>
                        {categories.map((category, i) => (
                            <Box w="full" key={category.id}>
                                <HStack
                                    w="full"
                                    justify="space-between"
                                    fontWeight="bold"
                                >
                                    <Text>{category.name}</Text>
                                    {i === 0 && <Text>COMISIÓN</Text>}
                                </HStack>
                                {compactItem(category.orderItems).map(
                                    orderItem => (
                                        <HStack
                                            key={orderItem.id}
                                            w="full"
                                            justify="space-between"
                                            fontSize={{
                                                xl: '16px',
                                                lg: '14px'
                                            }}
                                        >
                                            <Flex>
                                                <Text mr={1} minW="6" as="span">
                                                    {orderItem.quantity}
                                                </Text>
                                                <Text>
                                                    {orderItem.item.name}
                                                </Text>
                                            </Flex>
                                            <Text>
                                                S/
                                                {(
                                                    orderItem.commission *
                                                    orderItem.quantity
                                                ).toFixed(2)}
                                            </Text>
                                        </HStack>
                                    )
                                )}
                            </Box>
                        ))}
                    </VStack>
                    {booking && (
                        <TableInfo
                            isAttented={booking.is_attented}
                            totalCommission={totalCommission}
                            date={booking.hour}
                        />
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

type OrderItemsParams = Array<OrderItem & { item: Item }>

function compactItem(orderItems: OrderItemsParams) {
    const newOrderItems: OrderItemsParams = []

    for (const orderItem of orderItems) {
        const savedOrderItem = newOrderItems.find(
            oi => oi.item_id === orderItem.item_id
        )
        if (savedOrderItem) {
            const quantity = orderItem.quantity + savedOrderItem.quantity
            savedOrderItem.quantity = quantity
        } else {
            newOrderItems.push({ ...orderItem })
        }
    }

    return newOrderItems
}
