import { Icon, Td, Tr, useDisclosure } from '@chakra-ui/react'
import Description from '../../../../components/Icon/Description'
import colors from '../../../../config/theme/colors'
import ModalHistories from '../../modals/ModalHistories'
import { TableRowProps } from './types'
import TdStock from './TdStock'

export default function TableRow({
    item,
    startDate,
    endDate,
    reload
}: TableRowProps) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Tr>
            <Td>{item.production_item.name}</Td>
            <Td>{item.warehouse.name}</Td>
            <TdStock reload={reload} item={item} stock={item.stock} />
            <Td>{item.production_item?.magnitude?.name}</Td>
            <Td>{item.cost ?? '-'}</Td>
            <Td>
                {item.cost
                    ? Math.round(item.cost * item.stock * 100) / 100
                    : '-'}
            </Td>
            <Td>
                <ModalHistories
                    isOpen={isOpen}
                    onClose={onClose}
                    initialStartDate={startDate}
                    inititalEndDate={endDate}
                    item={item}
                />
                <Icon
                    onClick={onOpen}
                    fill={colors['alo-blue']}
                    as={Description}
                    marginRight={2}
                    cursor="pointer"
                />
            </Td>
        </Tr>
    )
}
