import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text
} from '@chakra-ui/react'
import Download from '../../../../components/Icon/Download'
import useModalDownload from '../../hooks/useModalDownload'
import { ModalDownloadProps } from '../../types'
import TypeField from './TypeField'

export default function ModalDownload({
    element,
    onDone,
    filename
}: ModalDownloadProps) {
    const {
        submitHandler,
        setOpen,
        isOpen,
        isLoading,
        openModal,
        handleOnChange,
        documents
    } = useModalDownload({
        onDone,
        filename
    })

    return (
        <>
            {element(openModal)}
            <Modal
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                size="sm"
                isCentered={false}
            >
                <ModalOverlay />
                <ModalContent px={6} py={4}>
                    <ModalCloseButton mt={1} />
                    <ModalHeader>Descargar comprobante</ModalHeader>
                    <ModalBody>
                        <form onSubmit={submitHandler} id="form-print">
                            <Text mb={2}>
                                Seleccione el tipo de formato a descargar
                            </Text>
                            <TypeField
                                handleOnChange={handleOnChange}
                                documents={documents}
                            />
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="alo-green:lg-md"
                            w="40"
                            ml={3}
                            type="submit"
                            form="form-print"
                            isLoading={isLoading}
                            leftIcon={<Download fill="white" />}
                            disabled={documents.length > 0 ? false : true}
                        >
                            Descargar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
