import { Center, Spinner } from '@chakra-ui/react'
import {
    createContext,
    ReactNode,
    useCallback,
    useEffect,
    useState
} from 'react'
import colors from '../config/theme/colors'
import Logged from '../types/logged'
import http, { post, removeAuth, setAuth } from '../lib/http'
import { useHistory, useLocation } from 'react-router-dom'
import User from '../models/user'
import Role from '../models/role'
import dayjs from 'dayjs'
import { INACTIVITY_TOLERANCE } from '../config/constants'

export interface Auth {
    setAuth(logged: Logged): void
    signOut(): void
    verifyAuth(): Promise<void>
    session: Logged | null
    updateUser: (user: Partial<User>, roles: Role[]) => void
}

const AuthContext = createContext<Auth>({
    setAuth: () => null,
    signOut: () => null,
    verifyAuth: () => null as any,
    updateUser: () => null,
    session: null
})

export function AuthProvider({ children }: { children: ReactNode }) {
    const { push } = useHistory()
    const { pathname } = useLocation()
    const [isLoading, setIsLoading] = useState(true)
    const [session, setSession] = useState<Logged | null>(null)
    const [timeout, _setTimeout] = useState<null | NodeJS.Timeout>(null)

    useEffect(() => {
        verifyAuth().then(() => setIsLoading(false))
    }, [])

    const cbTimeout = useCallback(() => {
        if (timeout) {
            clearTimeout(timeout)
        }

        const lastActivityWithTolerance = http.lastActivity.add(
            INACTIVITY_TOLERANCE,
            'minute'
        )

        if (lastActivityWithTolerance.isSameOrAfter(dayjs())) {
            verifyAuth()
        } else {
            signOut()
        }
    }, [timeout])

    const _setAuth = (logged: Logged) => {
        setAuth(logged.token)
        setSession(logged)
        if (logged.roles[0].id === 3) {
            push('/by_tables')
        }
        _setTimeout(setTimeout(cbTimeout, Number(logged.expire_in)))
    }

    const signOut = () => {
        localStorage.removeItem('table_id')
        localStorage.removeItem('cashbox')
        localStorage.removeItem('order_id')
        removeAuth()
        setSession(null)
        if (pathname !== '/') {
            push('/')
        }
    }

    const verifyAuth = async () => {
        const { data } = await post<Logged>('/api/refresh', {})
        if (data) {
            _setAuth(data.result)
            setAuth(data.result.token)
        } else {
            signOut()
        }
    }

    function updateUser(user: Partial<User>, roles: Role[]) {
        if (!session) return

        const _session: Logged = {
            ...session,
            user: {
                ...session.user,
                ...user
            },
            roles
        }
        setSession(_session)
    }

    if (isLoading) {
        return (
            <Center w="full" h="100vh">
                <Spinner color={colors.primary} size="xl" />
            </Center>
        )
    }

    return (
        <AuthContext.Provider
            value={{
                setAuth: _setAuth,
                signOut,
                session,
                verifyAuth,
                updateUser
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
