import { FormControl, FormLabel, Input, InputProps } from '@chakra-ui/react'

import ErrorHelper from '../../../components/ErrorHelper'
import { formZone } from '../storage'

export default function FormZone() {
    const { props, helper, error } = formZone.useField<InputProps>('name')
    return (
        <FormControl mb={4}>
            <FormLabel>Nombre</FormLabel>
            <Input {...props} placeholder="Ingresar nombre" autoFocus />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}
