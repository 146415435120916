import { useToast } from '@chakra-ui/react'
import useOverlapLoader from '../../../hooks/useOverlapLoader'
import { post, put } from '../../../lib/http'
import useOrderByTable from './useOrderByTable'
import { useHistory } from 'react-router-dom'
import useCashbox from './useCashbox'
import useSession from '../../../hooks/useSession'
import { MenuItem } from '../../../context/MenuContext'
import { CreateOrderByTable, ResponseStock, UpdateOrderByTable } from '../types'
import usePreOrder from './usePreOrder'
import useInfoDialog from '../../../hooks/useInfoDialog'
import useMenu from '../../../hooks/useMenu'

/**
 * Registra o actualiza la orden por mesa.
 */
export default function useSaveOrderByTable() {
    const toast = useToast()
    const { push } = useHistory()
    const { start, end } = useOverlapLoader()
    const { cashboxUser } = useCashbox()
    const { table, orderId, pin } = useOrderByTable()
    const { booking, preOrders, driver } = usePreOrder()
    const { menu, initialMenu } = useMenu()
    const { isLogged, session } = useSession()
    const dialog = useInfoDialog()

    /**
     * Registra el pedido por mesa
     * @param waiterIdOrPin Es un tipo de usuario que tomo el pedido del cliente (Un mozo)
     * En caso de no ser así se identificará por el pin
     */
    async function register(waiterIdOrPin?: number | string) {
        if (!table) return
        let _pin = ''
        let waiter_id
        if (typeof waiterIdOrPin === 'string') {
            _pin = waiterIdOrPin
        } else {
            waiter_id = waiterIdOrPin
        }

        const items = menu
            .filter(menuItem => menuItem.quantity > 0)
            .map<CreateOrderByTable['items'][number]>(menuItem => ({
                id: menuItem.itemId,
                price: menuItem.price,
                quantity: menuItem.quantity,
                description: menuItem.description,
                commission:
                    booking?.type === 1 || booking?.type === 2 || driver?.id
                        ? menuItem.commission
                        : 0
            }))

        const body: CreateOrderByTable = {
            table_id: table.id,
            pin: _pin,
            user_id: waiter_id,
            items,
            booking_id: booking?.id,
            pre_order_ids: preOrders.map(preOrder => preOrder.id),
            driverId: driver?.id
        }

        start('Registrando pedido')

        const { error } = await post('/api/orders/by_table', body)

        end()

        if (error) {
            if (error.type === 'customer') {
                const responseStock: ResponseStock = error.response
                const filterItems = responseStock.missingAbilityItem.filter(
                    item => item.stockIsMissing
                )
                dialog.addContent(
                    <div>
                        {filterItems.map(data => (
                            <p key={data.itemId}>
                                {data.abilityProduction} {data.itemName}
                            </p>
                        ))}
                    </div>
                )

                dialog.show(error.title, 'Solo queda disponible:')
                return
            }

            toast({
                title: error.message,
                position: 'top',
                status: 'error',
                isClosable: true
            })
        } else {
            push('/by_tables')
        }
    }

    /**
     * Actualiza la orden por mesa.
     */
    async function update(reasonForAnnul = '') {
        const user_id =
            isLogged && session !== null ? session.user.id : undefined
        const cashbox_id = cashboxUser?.cashbox_id
        if (!table) return

        const items = menu.filter(menuItem => {
            /**
             * Verificamos si es un nuevo item a agregar para la orden existente.
             */
            const initialItem = initialMenu.find(
                //_initMenuItem => _initMenuItem.itemId === menuItem.itemId
                _initMenuItem => _initMenuItem.hash === menuItem.hash
            )

            /**
             * Es un nuevo item
             */
            if (!initialItem) {
                return true
            }

            /**
             * Cualquier variación que exista en uno de esos campos es razón
             * para enviar a que se actualice en la orden existente.
             */
            if (
                initialItem.price !== menuItem.price ||
                initialItem.quantity !== menuItem.quantity ||
                initialItem.description !== menuItem.description
            ) {
                return true
            }

            /**
             * Sin ninguna variación o uno nuevo, no es necesidad
             * de enviar el item para actualizar en la orden.
             */
            return false
        })

        /**
         * Retorna la diferencia de la cantidad inicial con la actual
         */
        function getDiffQuantity(menuItem: MenuItem) {
            const initMenu = initialMenu.find(
                //_initMenu => _initMenu.itemId === menuItem.itemId
                _initMenu => _initMenu.hash === menuItem.hash
            )
            if (initMenu) {
                return menuItem.quantity - initMenu.quantity
            }
            return menuItem.quantity
        }

        start('Actualizando pedido')

        const body: UpdateOrderByTable = {
            pin,
            items: items.map(menuItem => ({
                id: menuItem.itemId,
                pre_order_item_id: menuItem.preOrderItemId,
                price: menuItem.price,
                quantity: getDiffQuantity(menuItem),
                description: menuItem.description,
                commission:
                    booking?.type === 1 || booking?.type === 2
                        ? menuItem.commission
                        : 0
            })),
            cashbox_id: pin ? null : cashbox_id,
            // user_id_cancel: user_id, se entiende que user_id es el que está editando
            user_id,
            reason: reasonForAnnul,
            driverId: driver?.id
        }

        const { error } = await put(`/api/orders/${orderId}/by_table`, body)

        end()

        if (error) {
            if (error.type === 'customer') {
                const responseStock: ResponseStock = error.response
                const filterItems = responseStock.missingAbilityItem.filter(
                    item => item.stockIsMissing
                )
                dialog.addContent(
                    <div>
                        {filterItems.map(data => (
                            <p key={data.itemId}>
                                {data.abilityProduction} {data.itemName}
                            </p>
                        ))}
                    </div>
                )

                dialog.show(error.title, 'Solo queda disponible:')
                return
                return
            }

            toast({
                title: error.message,
                position: 'top',
                isClosable: true,
                status: 'error'
            })
        } else {
            push('/by_tables')
        }
    }

    return {
        register,
        update
    }
}
