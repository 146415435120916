import {
    FormControl,
    FormLabel,
    Input,
    InputProps,
    SelectProps,
    Select,
    Switch
} from '@chakra-ui/react'
import ErrorHelper from '../../../components/ErrorHelper'
import NumberField from '../../../components/NumberField'
import { useGet } from '../../../hooks/useHttp'
import TypeMeasure from '../../../models/type_measure'
import { formSupply } from '../storage'

export default function FormSupply() {
    return (
        <>
            <Name />
            <Unity />
            <Stock />
        </>
    )
}

function Name() {
    const { props, helper, error } = formSupply.useField<InputProps>('name')
    return (
        <FormControl mb={4}>
            <FormLabel>Nombre</FormLabel>
            <Input
                {...props}
                placeholder="Ingresar el nombre del insumo"
                autoFocus
                maxLength={180}
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function Stock() {
    const { props, helper, error } = formSupply.useField<InputProps>('stock')
    const { value, setValue } = formSupply.useValue('has_inventory_control')

    const id = formSupply.store().id

    return (
        <FormControl mb={4}>
            <FormControl display="flex" alignItems="center">
                <FormLabel>¿Control de Stock?</FormLabel>
                <Switch
                    isChecked={value}
                    onChange={e => setValue(e.target.checked)}
                />
            </FormControl>

            {value && !id && (
                <>
                    <NumberField {...props} placeholder="Ingresar stock" />
                    <ErrorHelper helper={helper || error} />
                </>
            )}
        </FormControl>
    )
}

function Unity() {
    const { data } = useGet<TypeMeasure[]>('/api/type_measures?sort=name', [])
    const { props, helper } =
        formSupply.useField<SelectProps>('type_measure_id')

    return (
        <FormControl mb={4}>
            <FormLabel>Unidad</FormLabel>
            <Select {...props} placeholder="Seleccionar unidad">
                {data.result.map(typeMeasure => (
                    <option key={typeMeasure.id} value={typeMeasure.id}>
                        {typeMeasure.name}
                    </option>
                ))}
            </Select>
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}
