import { Button, Flex } from '@chakra-ui/react'
import { FooterButtonsProps } from './types'

export default function FooterButtons({ onAdd, onClose }: FooterButtonsProps) {
    return (
        <Flex textAlign="center" mt="12" justifyContent="space-between">
            <Button
                h="40px"
                borderRadius="xl"
                flexGrow={1}
                border="1px solid #434343"
                color="#434343"
                bg="white"
                onClick={onClose}
                margin={{ base: '0 5px', md: '0 10px' }}
            >
                Cancelar
            </Button>
            <Button
                h="40px"
                variant="accent:lg-lg"
                fontSize="md"
                flexGrow={1}
                onClick={onAdd}
                margin="0 5px"
            >
                Agregar
            </Button>
        </Flex>
    )
}
