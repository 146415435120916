import { Box, Button, Icon } from '@chakra-ui/react'
import ArrowLeft from '../../../components/Icon/ArrowLeft'
import { useRender } from 'redity'
import Keys from '../keys'
import { storagePage } from '../storage'
import ContentBotton from './ContentBotton'
import ContentTop from './ContentTop'
import colors from '../../../config/theme/colors'

export default function Details({
    showDetailsOnMobile,
    onClickBack
}: {
    showDetailsOnMobile: boolean
    onClickBack: any
}) {
    useRender(Keys.CONTENT_RIGHT)
    const { isOpenCashbox } = storagePage

    return (
        <Box
            minW={{
                xl: '450px',
                lg: '355px'
            }}
            py="6"
            position={{ base: 'fixed', lg: 'relative' }}
            left={0}
            right={0}
            top={0}
            bottom={0}
            d={{ base: showDetailsOnMobile ? 'flex' : 'none', lg: 'flex' }}
            flexDirection="column"
            justifyContent="space-between"
            minHeight={{
                base: 'auto',
                lg: `calc(100vh - ${!isOpenCashbox ? '86' : '60'}px)`
            }}
            bg="base-3"
            overflow="hidden"
            zIndex={11}
        >
            <Button
                position={['fixed']}
                display={{ base: 'block', lg: 'none' }}
                onClick={onClickBack}
            >
                <Icon as={ArrowLeft} fill={colors.dark} />
            </Button>
            <ContentTop />
            <ContentBotton />
        </Box>
    )
}
