import BoxTotal from '../Components/BoxTotal'
import { storagePage } from '../storage'
import { CashboxUserData } from '../types'

export default function TotalCash() {
    const cashboxUser = storagePage.cashboxUser as CashboxUserData

    const targets = [
        {
            label: 'Monto de apertura',
            value: cashboxUser.started_amount
        },
        {
            label: 'Efectivo cobrado',
            value:
                cashboxUser.amount_collection.cash +
                cashboxUser.amount_sale.cash
        },
        {
            label: 'Ingresos',
            value: cashboxUser.income
        },
        {
            label: 'Egresos',
            value: cashboxUser.expenses
        }
    ]

    return (
        <BoxTotal
            w={{ base: '100%', md: '50%' }}
            title="Total de efectivo"
            value={
                cashboxUser.started_amount +
                cashboxUser.amount_collection.cash +
                cashboxUser.amount_sale.cash +
                cashboxUser.income -
                cashboxUser.expenses
            }
            targets={targets}
            wTarget={{ base: '118px', md: '144px', lg: '190px', xl: '118px' }}
        />
    )
}
