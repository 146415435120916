import { Icon } from '@chakra-ui/react'
import Transfer from '../../../components/Icon/Transfer'
import Colors from '../../../config/colors'
import Item from '../../../models/item'
import useEditionStock from './useEditionStock'

export default function EditionStock({ item }: { item: Item }) {
    const openModalStock = useEditionStock(item)

    return (
        <Icon
            fill={item.has_inventory_control ? Colors.INFO : 'gray'}
            as={Transfer}
            marginRight={2}
            cursor={item.has_inventory_control ? 'pointer' : 'normal'}
            pointerEvents={item.has_inventory_control ? 'auto' : 'none'}
            onClick={openModalStock}
        />
    )
}
