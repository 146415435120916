import { Button, Text, VStack } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import Check from '../../../../components/Icon/Check'
import OrderIcon from '../../../../components/Icon/OrderIcon'
import useDelivery from '../../../../hooks/useDelivery'
import { DeliveryStatus } from '../../../../types/deliveryMode'
import useSaveDelivery from '../../hooks/useSaveDelivery'
import DetailsContainer from '../../shared/DetailsContainer'
import AnnulDelivery from './AnnulDelivery'
import OrderDetails from './OrderDetails'
import Info from './Info'

export default function Summary({ isMobile }: { isMobile: boolean }) {
    const { push } = useHistory()
    const { hasOrder, delivery, orderEdition } = useDelivery()
    const { create, update } = useSaveDelivery()

    function goToOrderEdition() {
        orderEdition(true)
        push('/delivery/order')
    }

    return (
        <DetailsContainer isMobile={isMobile}>
            <Info />
            <Text fontWeight="bold" mb={2} mt={4} color="blue.600">
                Lista del Pedido
            </Text>
            {!hasOrder && (
                <Text fontSize="sm" textAlign="center" color="blackAlpha.600">
                    No se han agregado productos al Pedido
                </Text>
            )}
            <OrderDetails />

            <VStack>
                {!(
                    delivery?.status === DeliveryStatus.CLOSED ||
                    delivery?.status === DeliveryStatus.CANCELED
                ) && (
                    <Button
                        onClick={goToOrderEdition}
                        colorScheme="orange"
                        w="full"
                        isDisabled={
                            delivery?.is_paid ||
                            (!!delivery && delivery.status > 2)
                        }
                        fill="white"
                        fontWeight="normal"
                        leftIcon={<OrderIcon />}
                    >
                        {hasOrder ? 'Editar pedido' : 'Agregar productos'}
                    </Button>
                )}

                {!(
                    delivery?.status === DeliveryStatus.CLOSED ||
                    delivery?.status === DeliveryStatus.CANCELED
                ) && (
                    <Button
                        fontWeight="medium"
                        colorScheme="green"
                        w="full"
                        fill="white"
                        isDisabled={!hasOrder}
                        leftIcon={<Check />}
                        onClick={delivery ? update : create}
                    >
                        {delivery ? 'Guardar delivery' : 'Confirmar delivery'}
                    </Button>
                )}
                {delivery && delivery?.status !== DeliveryStatus.CANCELED && (
                    <AnnulDelivery />
                )}
            </VStack>
        </DetailsContainer>
    )
}
