import { Icon } from '@chakra-ui/react'
import Trash from '../../components/Icon/Trash'
import { controlList } from './storage'
import Colors from '../../config/colors'
import Delete from '../../lib/delete'
import PrinterMachine from '../../models/printer_machine'

export default function DeletePrinterMachine({
    printerMachine
}: {
    printerMachine: PrinterMachine
}) {
    return (
        <Delete
            title="Eliminar impresora"
            path={`/api/printer_machines/${printerMachine.id}`}
            element={click => (
                <Icon
                    onClick={click}
                    fill={Colors.ERROR}
                    as={Trash}
                    cursor="pointer"
                />
            )}
            done={() => {
                controlList.setQuery('page', 1)
                controlList.load()
            }}
        >
            ¿Esta seguro de eliminar la impresora <b>{printerMachine.name}</b>?
        </Delete>
    )
}
