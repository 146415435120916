import { Box, Flex, Text } from '@chakra-ui/layout'
import { DataItemProps } from './types'

export default function DataItem({
    title,
    value,
    color = 'gray.700'
}: DataItemProps) {
    return (
        <Flex
            w="full"
            whiteSpace="nowrap"
            alignItems="center"
            gap={2}
            color={color}
            fontSize="sm"
            fontFamily="RedHatDisplay"
        >
            <Text color={color}>{title}</Text>
            <Box
                w="full"
                h="1px"
                borderTopWidth={2}
                borderStyle="dotted"
                borderColor={color}
            />
            <Text color={color} fontWeight="bold">
                {value}
            </Text>
        </Flex>
    )
}
