import { storagePage } from '../storage'

export default function getItemsForPostPreOrder() {
    const items = storagePage.selectedItems.map(item => item.items)
    const nextItems: DataForPostPreOrder[] = []

    for (const item of items.flat()) {
        const restDataItem: DataForPostPreOrder = {
            id: item.id,
            quantity: item.quantity,
            aditional_information:
                item.description.length === 0 ? null : item.description
        }

        nextItems.push(restDataItem)
    }

    return nextItems
}

interface DataForPostPreOrder {
    id: number
    quantity: number
    aditional_information: string | null
}

export interface DataFeatureSetting {
    quantity: number
    feature_setting_sub_features: Array<{
        sub_feature_id: number
        quantity: number
        aditional_information: string
    }>
}
