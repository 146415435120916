import { Box } from '@chakra-ui/react'
import { Fragment } from 'react'
import { storagePage } from '../storage'
import Item from './Item'

export default function ListItems() {
    return (
        <Box maxHeight="calc(100vh - 298px)" overflowY="auto">
            {storagePage.matrix_items.map(matrix_item => (
                <Fragment key={matrix_item.id}>
                    <Box maxH="500px" overflowY="auto" mb={2} px="2">
                        <Item
                            matrix_item={matrix_item}
                            index={matrix_item.id}
                        />
                    </Box>
                </Fragment>
            ))}
        </Box>
    )
}
