import {
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Spinner
} from '@chakra-ui/react'
import { useState } from 'react'
import { get } from '../../../../lib/http'
import { OrderPaymentForView } from '../../types'
import Content from './ModalContent'

export default function ModalView({
    element,
    orderPaymentId
}: {
    element: (click: () => void) => JSX.Element
    orderPaymentId: number
}) {
    const [isOpen, setOpen] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [orderPayment, setOrderPayment] =
        useState<OrderPaymentForView | null>(null)

    async function openHandler() {
        setOpen(true)
        setLoading(true)
        const resp = await get<OrderPaymentForView>(
            `/api/v2/order_payments/${orderPaymentId}`
        )
        if (resp.data) {
            setOrderPayment(resp.data.result)
        }
        setLoading(false)
    }

    return (
        <>
            {element(openHandler)}
            <Modal
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                size="md"
                isCentered={false}
            >
                <ModalOverlay />
                <ModalContent px={6} py={4}>
                    <ModalBody pb={6}>
                        {isLoading ? (
                            <Flex justifyContent="center" py="12">
                                <Spinner size="xl" />
                            </Flex>
                        ) : (
                            <Content
                                orderPayment={
                                    orderPayment as OrderPaymentForView
                                }
                            />
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
