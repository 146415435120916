export default function RoomService(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 16.654c-.27-4.07-3.25-7.4-7.16-8.21A2.006 2.006 0 0012 5.654a2.006 2.006 0 00-1.84 2.79c-3.91.81-6.89 4.14-7.16 8.21h18zm-2.02 1H2v2h20v-2h-3.02zm-.48-3.01a7.002 7.002 0 00-6.5-4.41c-2.95 0-5.47 1.83-6.5 4.41h13z"
            />
        </svg>
    )
}
