import {
    Box,
    Text,
    Button,
    Table,
    Thead,
    Th,
    Tbody,
    Tr,
    Td,
    Spacer,
    Flex,
    Heading,
    Icon,
    SimpleGrid
} from '@chakra-ui/react'
import { useContext } from 'react'
import CardIcon from '../../../components/Icon/CardIcon'
import CashIcon from '../../../components/Icon/CashIcon'
import Close from '../../../components/Icon/Close'
import colors from '../../../config/theme/colors'
import getNumberWithTwoDecimals from '../../../utilities/getNumberWithTwoDecimals'
import CheckInContext from '../context/CheckInContext'
import ModalDiscount from './ModalDiscount'
import ModalPay from './ModalPay'

export default function LeftContent() {
    const {
        openModalPay,
        setOpenModalPay,
        typePay,
        setTypePay,
        payments,
        order,
        getTotal,
        getTotalWithDiscount,
        removePayment,
        openModalDiscount,
        setOpenModalDiscount
    } = useContext(CheckInContext)

    const handleOpenModalPay = (type: string) => {
        setTypePay(type)
        setOpenModalPay(true)
    }
    const paymentTotal = getTotal()
    const { paymentTotalWithDiscount, discount } = getTotalWithDiscount()

    function lack() {
        const rest =
            paymentTotalWithDiscount === '-'
                ? 0
                : (order?.amount || 0.0) - paymentTotalWithDiscount

        return paymentTotal > order.amount
            ? getNumberWithTwoDecimals(0)
            : getNumberWithTwoDecimals(
                  (order?.amount || 0.0) - paymentTotal - rest
              )
    }

    return (
        <>
            <ModalPay
                isOpen={openModalPay}
                typePay={typePay || '-'}
                onClose={() => setOpenModalPay(false)}
            />
            <ModalDiscount
                isOpen={openModalDiscount}
                onClose={() => setOpenModalDiscount(false)}
            />
            <Box
                minW={{
                    base: 'auto'
                }}
                w="67%"
                bg="white"
                py="6"
                px="12"
            >
                <Flex mt={10}>
                    <Text fontSize="lg">Total de pedido</Text>
                    <Spacer />
                    <Text fontSize="lg">Total de descuento {discount}</Text>
                </Flex>
                <Flex>
                    <Heading>
                        S/ {getNumberWithTwoDecimals(order?.amount || 0.0)}
                    </Heading>
                    <Spacer />
                    <Box>
                        <Heading mb={2}>
                            S/{' '}
                            {paymentTotalWithDiscount !== '-'
                                ? paymentTotalWithDiscount
                                : getNumberWithTwoDecimals(
                                      order?.amount || 0.0
                                  )}
                        </Heading>
                        <Text
                            color="alo-blue"
                            as="a"
                            textAlign="right"
                            cursor="pointer"
                            _hover={{ textDecor: 'underline' }}
                            onClick={() => setOpenModalDiscount(true)}
                        >
                            Agregar descuento
                        </Text>
                    </Box>
                </Flex>
                <Box mt="10" display="flex" gridGap="10px">
                    <Button
                        variant="dark:xl-sm"
                        onClick={() => handleOpenModalPay('Efectivo')}
                        leftIcon={
                            <CashIcon fontSize="40" fill={colors.white} />
                        }
                    >
                        Efectivo
                    </Button>
                    <Button
                        variant="dark:xl-sm"
                        bg="transparent"
                        color="dark"
                        onClick={() => handleOpenModalPay('Tarjeta')}
                        leftIcon={<CardIcon fontSize="40" fill={colors.dark} />}
                    >
                        Tarjeta
                    </Button>
                    <Button
                        variant="dark:xl-sm"
                        bg="transparent"
                        color="dark"
                        onClick={() => handleOpenModalPay('Transferencia')}
                    >
                        Transferencia
                    </Button>
                </Box>
                <Table
                    mt="10"
                    size="md"
                    variant="unstyled"
                    colorScheme="blackAlpha"
                    border={`2px solid ${colors.dark}`}
                    color="dark"
                >
                    <Thead>
                        <Tr>
                            <Th
                                textTransform="none"
                                fontWeight="normal"
                                fontSize="lg"
                            >
                                Tipo de pago
                            </Th>
                            <Th
                                textTransform="none"
                                fontWeight="normal"
                                fontSize="lg"
                            >
                                Monto
                            </Th>
                            <Th />
                        </Tr>
                    </Thead>
                    <Tbody>
                        {payments.length === 0 && (
                            <Tr borderTop={`2px solid ${colors['dark']}`}>
                                <Td colSpan={3} textAlign="center">
                                    No hay registros de pagos
                                </Td>
                            </Tr>
                        )}
                        {payments.length !== 0 &&
                            payments.map((payment: any) => (
                                <Tr
                                    key={payment.type}
                                    borderTop={`2px solid ${colors.dark}`}
                                >
                                    <Td>{payment.type}</Td>
                                    <Td>{payment.amount}</Td>
                                    <Td>
                                        <Icon
                                            cursor="pointer"
                                            onClick={() =>
                                                removePayment(payment.type)
                                            }
                                            as={Close}
                                            fill="primary"
                                        />
                                    </Td>
                                </Tr>
                            ))}
                    </Tbody>
                </Table>
                <SimpleGrid mt="24" color="dark" columns={3} spacingX="40px">
                    <Box>
                        <Text fontSize="2xl">Pago acumulado (S/)</Text>
                        <Text fontSize="4xl" fontWeight="bold">
                            {getNumberWithTwoDecimals(paymentTotal)}
                        </Text>
                    </Box>
                    <Box>
                        <Text fontSize="2xl">Falta</Text>
                        <Text fontSize="4xl" fontWeight="bold">
                            S/ {lack()}
                        </Text>
                    </Box>
                    <Box>
                        <Text fontSize="2xl">Vuelto Total</Text>
                        {paymentTotalWithDiscount !== '-' && (
                            <Text fontSize="4xl" fontWeight="bold">
                                {getNumberWithTwoDecimals(
                                    paymentTotal -
                                        Number(paymentTotalWithDiscount) <
                                        0
                                        ? 0
                                        : paymentTotal -
                                              Number(paymentTotalWithDiscount)
                                )}
                            </Text>
                        )}

                        {paymentTotalWithDiscount === '-' && (
                            <Text fontSize="4xl" fontWeight="bold">
                                {getNumberWithTwoDecimals(
                                    paymentTotal - order?.amount < 0
                                        ? 0
                                        : paymentTotal - order?.amount
                                )}
                            </Text>
                        )}
                    </Box>
                </SimpleGrid>
            </Box>
        </>
    )
}
