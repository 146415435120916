import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button
} from '@chakra-ui/react'
import { ReactNode, createContext, useCallback, useState, useRef } from 'react'

export interface InfoDialogContextInterface {
    show: (title: string, description: string) => void
    hide: () => void
    addContent: (content: ReactNode) => void
}

const InfoDialogContext = createContext<InfoDialogContextInterface>({
    show: () => null,
    hide: () => null,
    addContent: () => null
})

export interface OverlapLoaderProviderProps {
    children: ReactNode
}

/**
 * Muestra un diálogo de informe
 */
export function InfoDialogProvider({ children }: OverlapLoaderProviderProps) {
    const [isOpen, setIsOpen] = useState(false)
    const cancelRef = useRef<HTMLButtonElement>(null)
    const [description, setDescription] = useState('')
    const [title, setTitle] = useState('')
    const [content, setContent] = useState<ReactNode>(null)

    const show = useCallback((_title: string, _description: string) => {
        setIsOpen(true)
        setDescription(_description)
        setTitle(_title)
    }, [])

    const hide = useCallback(() => setIsOpen(false), [])

    const addContent = useCallback((_content: ReactNode) => {
        setContent(_content)
    }, [])

    return (
        <>
            <AlertDialog
                leastDestructiveRef={cancelRef}
                onClose={hide}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>{title}</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        {description}
                        {content}
                    </AlertDialogBody>

                    <AlertDialogFooter textAlign="center">
                        <Button
                            ref={cancelRef}
                            onClick={hide}
                            variant="outline"
                        >
                            Aceptar
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            <InfoDialogContext.Provider value={{ show, hide, addContent }}>
                {children}
            </InfoDialogContext.Provider>
        </>
    )
}

export default InfoDialogContext
