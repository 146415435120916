import { Box, Container, HStack } from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import Initial from '../../components/Initial'
import MenuUser from '../../components/MenuUser'
import NTabs from '../../components/NTabs'
import Content from './Main'
import useInitial from './useInitial'
import TabButton from './Components/TabButton'
import { useState } from 'react'
import ClosingHistory from './ClosingHistory'
import Arching from './Arching'
import { storagePage } from './storage'
import useResolution from '../../hooks/useResolution'
import useSession from '../../hooks/useSession'
import OpeningHistory from './OpeningHistory'

export default function CashRegister() {
    const { pageState } = useInitial()
    const [tab, setTabValue] = useState('1')
    const { isMobile } = useResolution()
    const { isAdmin } = useSession()

    return (
        <>
            <Navbar component={<NTabs />}>
                <Box px="5" py="1">
                    <MenuUser />
                </Box>
            </Navbar>
            <Initial pageState={pageState} h="calc(100vh - 60px)">
                <Box minH="calc(100vh - 60px)" w="full" bg="base-2">
                    <Container
                        w="full"
                        maxW="container.xl"
                        pt={{ base: '4', md: '8' }}
                    >
                        <HStack
                            spacing={isMobile ? 1 : 3}
                            mb={4}
                            overflowY="auto"
                        >
                            <TabButton
                                onTab={setTabValue}
                                isActive={tab === '1'}
                                value="1"
                            >
                                Apertura
                            </TabButton>
                            {storagePage.cashboxUser && (
                                <TabButton
                                    onTab={setTabValue}
                                    isActive={tab === '2'}
                                    value="2"
                                >
                                    Arqueo
                                </TabButton>
                            )}
                            <TabButton
                                onTab={setTabValue}
                                isActive={tab === '3'}
                                value="3"
                            >
                                Historial de cierres
                            </TabButton>
                            {isAdmin() && (
                                <TabButton
                                    onTab={setTabValue}
                                    isActive={tab === '4'}
                                    value="4"
                                >
                                    Historial de aperturas
                                </TabButton>
                            )}
                        </HStack>
                        {tab === '1' && <Content />}
                        {storagePage.cashboxUser && tab === '2' && (
                            <Arching onDone={() => setTabValue('1')} />
                        )}
                        {tab === '3' && <ClosingHistory />}
                        {tab === '4' && <OpeningHistory />}
                    </Container>
                </Box>
            </Initial>
        </>
    )
}
