import {
    Box,
    Table,
    TableCaption,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useGet } from '../../../../../hooks/useHttp'
import { OperationAll, TableMovementsProps } from './types'

export default function TableMovements({
    inventoryItemId,
    warehouseId,
    date
}: TableMovementsProps) {
    const { data } = useGet<OperationAll[]>(
        `/api/inventory_items/${inventoryItemId}/warehouses/${warehouseId}/operations/all?date=${date}`,
        []
    )

    return (
        <Box mt={1} overflowY="auto">
            <Table size="sm" className="table-a-la-orden">
                <Thead>
                    <Tr>
                        <Th>Hora</Th>
                        <Th>Tipo</Th>
                        <Th>Cantidad</Th>
                        <Th>Comentario</Th>
                        <Th>Usuario</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {data.result.map(operation => (
                        <Tr key={operation.id}>
                            <Td>
                                {dayjs(operation.created_at).format('HH:mm a')}
                            </Td>
                            <Td>{operation.type_inventory_operation.name}</Td>
                            <Td>{operation.operation_quantity}</Td>
                            <Td>{operation.comment}</Td>
                            <Td>
                                {operation.user.firstname}{' '}
                                {operation.user.lastname}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
                {data.result.length === 0 && (
                    <TableCaption>No existen movimientos</TableCaption>
                )}
            </Table>
        </Box>
    )
}
