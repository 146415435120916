import { FeatureSetting } from '../../../types/runway_state'
import { ADDITIONAL_INFORMATION_DIVIDER } from '../config'
import { FeatureSelected } from '../ContentModals/IceCream/types'

export default function getFeatureSettingSubFeatures(
    subs: FeatureSelected['subs']
) {
    const countingSubs: FeatureSetting['feature_setting_sub_features'] = []
    subs.forEach(sub => {
        const countingSub = countingSubs.find(
            cs => cs.sub_feature_id === sub.sub_feature_id
        )
        if (countingSub) {
            countingSub.quantity = ++countingSub.quantity
            if (sub.aditional_information) {
                countingSub.aditional_information = `${countingSub.aditional_information}${ADDITIONAL_INFORMATION_DIVIDER}${sub.aditional_information}`
            }
        } else {
            countingSubs.push({
                sub_feature_id: sub.sub_feature_id,
                quantity: 1,
                name: sub.name,
                price: sub.price,
                feature_setting_sub_feature_id:
                    sub.feature_setting_sub_feature_id,
                aditional_information: sub.aditional_information
            })
        }
    })

    return countingSubs
}
