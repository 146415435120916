import {
    Text,
    Modal,
    ModalOverlay,
    ModalBody,
    ModalFooter,
    Button,
    ModalContent,
    Box,
    Icon
} from '@chakra-ui/react'
import CircleCheck from '../Icon/CircleCheck'

export default function DialogConfirm({
    text,
    isOpen,
    onClose,
    onConfirm,
    isLoading = false
}: {
    text: string
    isOpen: boolean
    data?: any
    onClose: () => void
    onConfirm: () => void
    isLoading: boolean
}) {
    return (
        <Modal size="lg" isCentered onClose={onClose} isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent p="5">
                <ModalBody>
                    <Box display="flex" justifyContent="center">
                        <Icon as={CircleCheck} fill="success" fontSize="5xl" />
                    </Box>
                    <Text
                        textAlign="center"
                        mb="5"
                        mt="5"
                        color="#434343"
                        fontSize="2xl"
                        fontWeight="bold"
                    >
                        {text}
                    </Text>
                    <Text
                        textAlign="center"
                        fontWeight="semibold"
                        mb="5"
                        color="#434343"
                        fontSize="xl"
                    >
                        ¿Esta seguro de cambiar su contraseña?
                    </Text>
                </ModalBody>
                <ModalFooter display="flex" justifyContent="space-between">
                    <Button
                        borderRadius="5px"
                        color="#434343"
                        border="1px solid #434343"
                        fontWeight="semibold"
                        bg="transparent"
                        fontSize="xl"
                        h="60px"
                        w="180px"
                        isDisabled={isLoading}
                        _hover={{}}
                        onClick={() => onClose()}
                    >
                        {' '}
                        Cancelar
                    </Button>
                    <Button
                        borderRadius="5px"
                        color="white"
                        fontWeight="semibold"
                        bg="#13CE66"
                        fontSize="xl"
                        h="60px"
                        w="180px"
                        isLoading={isLoading}
                        _hover={{}}
                        onClick={() => onConfirm()}
                    >
                        {' '}
                        Si, cambiar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
