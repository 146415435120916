import {
    FormControl,
    FormLabel,
    Input,
    InputProps,
    NumberInput,
    Textarea,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Select,
    SelectProps,
    TextareaProps,
    Box
} from '@chakra-ui/react'
import { useEffect } from 'react'
import ErrorHelper from '../../../components/ErrorHelper'
import { useGet } from '../../../hooks/useHttp'
import Agency from '../../../models/agency'
import Driver from '../../../models/driver'
import { formBooking, TYPE_BOOKINGS_VALUE } from '../storage'
import useSelectAgency from './useSelectAgency'

export default function FormBooking() {
    return (
        <>
            <SelectAgency />
            <SelectDriver />
            <ClientName />
            <Box
                display={{
                    base: 'block',
                    md: 'flex'
                }}
                gridGap={5}
            >
                <Date />
                <Hour />
            </Box>
            <PersonQuantity />
            <Comment />
        </>
    )
}

function ClientName() {
    const { props, helper, error } =
        formBooking.useField<InputProps>('client_name')
    const { value } = formBooking.useValue('type')

    if (value !== TYPE_BOOKINGS_VALUE.CLIENT) return null

    return (
        <FormControl mb={4}>
            <FormLabel>Nombre del cliente</FormLabel>
            <Input
                {...props}
                placeholder="Ingresar el nombre del cliente"
                autoFocus
                maxLength={180}
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function SelectAgency() {
    const { value: typeValue } = formBooking.useValue('type')
    if (typeValue !== TYPE_BOOKINGS_VALUE.AGENCY) return null

    const { agencyGuides, getAgencyGuide, setAgencyGuides } = useSelectAgency()
    const { data } = useGet<Agency[]>('/api/agencies?sort=name', [])
    const {
        value,
        setValue,
        error: errorAgency,
        helper: helperAgency
    } = formBooking.useValue('agency_id')
    const { props, helper, error } =
        formBooking.useField<SelectProps>('agency_guide_id')

    async function getGuideByAgency() {
        if (value) {
            const response = await getAgencyGuide(value)
            if (response) {
                setValue(value)
            }
        } else {
            setValue(value)
            setAgencyGuides([])
        }
    }

    useEffect(() => {
        getGuideByAgency()
    }, [value])

    return (
        <>
            <FormControl mb={4}>
                <FormLabel>Agencia</FormLabel>
                <Select
                    value={value}
                    onChange={event => setValue(event.target.value)}
                    placeholder="Seleccionar Agencia"
                >
                    {data.result.map(agency => (
                        <option key={agency.id} value={agency.id}>
                            {agency.name}
                        </option>
                    ))}
                </Select>
                <ErrorHelper helper={helperAgency || errorAgency} />
            </FormControl>
            <FormControl mb={4}>
                <FormLabel>Guía turístico</FormLabel>
                <Select {...props} placeholder="Seleccionar Guía turistico">
                    {agencyGuides.map(agencyGuide => (
                        <option key={agencyGuide.id} value={agencyGuide.id}>
                            {agencyGuide.name}
                        </option>
                    ))}
                </Select>
                <ErrorHelper helper={helper || error} />
            </FormControl>
        </>
    )
}

function SelectDriver() {
    const { value: typeValue } = formBooking.useValue('type')
    if (typeValue !== TYPE_BOOKINGS_VALUE.DRIVER) return null
    const { data } = useGet<Driver[]>('/api/drivers?sort=name', [])
    const { props, helper, error } =
        formBooking.useField<SelectProps>('driver_id')
    return (
        <FormControl mb={4}>
            <FormLabel>Guía local</FormLabel>
            <Select {...props} placeholder="Seleccionar guía local">
                {data.result.map(driver => (
                    <option key={driver.id} value={driver.id}>
                        {driver.name}
                    </option>
                ))}
            </Select>
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function Comment() {
    const { props } = formBooking.useField<TextareaProps>('comment')
    return (
        <FormControl mb={4}>
            <FormLabel>Comentario (Opcional)</FormLabel>
            <Textarea
                {...props}
                placeholder="Ingresar un comentario"
                maxLength={180}
            />
        </FormControl>
    )
}

function PersonQuantity() {
    const { value, setValue, helper, error } =
        formBooking.useValue('person_quantity')

    return (
        <FormControl mb={4}>
            <FormLabel>Cantidad de personas</FormLabel>
            <NumberInput
                value={value}
                onChange={valueInput => setValue(valueInput)}
                min={0}
                step={1}
            >
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function Date() {
    const { props, helper, error } = formBooking.useField<InputProps>('date')

    return (
        <FormControl mb={4}>
            <FormLabel>Fecha</FormLabel>
            <Input {...props} type="date" />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function Hour() {
    const { props, helper, error } = formBooking.useField<InputProps>('hour')

    return (
        <FormControl mb={4}>
            <FormLabel>Hora</FormLabel>
            <Input {...props} type="time" />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}
