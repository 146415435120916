import { render } from 'redity'
import CategoryItem from '../../../models/category_item'
import Item from '../../../models/item'
import { Keys } from '../constants'
import { storagePage } from '../storage'
import getItemsSelected from '../utilities/getItemsSelected'

export default function useAddItems(
    item: Item & {
        category_item?: CategoryItem
    }
) {
    const { selectedItems } = storagePage
    const getItemForAdd = (quantity: number, price: number) => ({
        id: item.id,
        item: { ...item, price: price },
        quantity,
        sub_total: price * quantity
    })

    function addItem(quantity: number, price: number) {
        const { items: currentItems } = getItemsSelected(item.id)
        if (currentItems) {
            currentItems.quantity = quantity
            currentItems.item.price = price
            currentItems.sub_total = quantity * price
        } else {
            contitionalPushItem(quantity || 1, price)
        }
        render(Keys.MAIN)
        render(Keys.PRODUCTS_SECTION)
    }

    function contitionalPushItem(quantity = 1, price: number) {
        selectedItems.push(getItemForAdd(quantity, price))
    }

    return {
        addItem
    }
}
