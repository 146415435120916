import { Flex, IconButton, Text } from '@chakra-ui/react'
import Close from '../../../../../../components/Icon/Close'
import colors from '../../../../../../config/theme/colors'
import { ItemSelectedProps } from './types'

export default function ItemSelected({
    onClick,
    onRemove: onClose,
    children,
    enableClose = true
}: ItemSelectedProps) {
    return (
        <Flex w="full" gap={2} justifyContent="space-between" align="center">
            <Text
                as="b"
                color="dark-2"
                cursor="pointer"
                decoration={enableClose ? 'underline' : 'none'}
                pointerEvents={enableClose ? 'auto' : 'none'}
                onClick={onClick}
            >
                {children}
            </Text>
            {enableClose && (
                <IconButton
                    icon={<Close />}
                    size="sm"
                    aria-label="close"
                    variant="ghost"
                    zIndex="1"
                    fill={colors.secondary}
                    onClick={onClose}
                />
            )}
        </Flex>
    )
}
