import { useEffect, useState } from 'react'
import { useRender } from 'redity'
import useStorage from '../../hooks/useStorage'
import PageState from '../../types/pageState'
import { Keys } from './constant'
import loadMain from './utilities/loadMain'
import { storagePage } from './storage'
import useSession from '../../hooks/useSession'

export default function useInitial() {
    const { isAdmin } = useSession()
    useRender(Keys.MAIN)
    const [pageState, setPageState] = useState(PageState.LOADING)
    const { cashboxes } = useStorage()

    useEffect(() => {
        storagePage.currentCashbox = cashboxes[0]
        storagePage.isAdmin = isAdmin()
        if (storagePage?.currentCashbox?.id) {
            loadMain(storagePage.currentCashbox.id).then(setPageState)
        }
    }, [])

    return {
        pageState
    }
}
