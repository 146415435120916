import { Input, InputGroup, InputLeftElement } from '@chakra-ui/input'
import { Box, Text, VStack } from '@chakra-ui/layout'
import { Icon, Spinner } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import Search from '../../../../components/Icon/Search'
import useDelivery from '../../../../hooks/useDelivery'
import { get } from '../../../../lib/http'
import Client from '../../../../models/client'
import ModalClient from '../../modals/ClientModal'
import { useDeliveryForm } from '../../store/delivery-form.store'
import ClientItem from './ClientItem'
import { ClientListProps } from './types'

export default function ClientsList({ client, onClient }: ClientListProps) {
    const { setClient } = useDelivery()
    const [search, setSearch] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [clients, setClients] = useState<Client[]>([])
    const setForm = useDeliveryForm(state => state.set)

    async function load() {
        setIsLoading(true)
        const { data, error } = await get<Client[]>(
            `/api/clients?limit=20&search=${search}`
        )
        setIsLoading(false)
        if (!error) {
            setClients(data.result)
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (search.length > 2) {
                load()
            } else {
                setIsLoading(false)
            }
        }, 600)

        return () => {
            clearTimeout(timer)
        }
    }, [search])

    function doneHandler(_client: Client) {
        load()
        setClient(_client)
        setForm({
            address: [_client.addresses[0][0], _client.addresses[0][1]],
            phone: _client.phones[0]
        })
    }

    function clickItemHandñer(_client: Client) {
        onClient(_client)
        if (_client.addresses.length === 1) {
            setForm({
                address: [_client.addresses[0][0], _client.addresses[0][1]]
            })
        }
        if (_client.phones.length === 1) {
            setForm({
                phone: _client.phones[0]
            })
        }
    }

    return (
        <>
            <Box
                minW={{
                    md: client ? '0' : '72',
                    base: 'full'
                }}
                maxW={{
                    md: client ? '0' : '72',
                    base: 'full'
                }}
                minH="calc(100vh - 60px)"
                display={{
                    base: 'none',
                    md: 'block'
                }}
                transition=".2s ease"
            />
            <Box
                top={0}
                left={client ? -72 : 0}
                transition=".2s ease"
                minW={{
                    md: '72',
                    base: 'full'
                }}
                maxW={{
                    md: '72',
                    base: 'full'
                }}
                bg="white"
                borderRightWidth={1}
                minH="calc(100vh - 60px)"
                position={{
                    base: 'initial',
                    md: 'absolute'
                }}
            >
                <Box px={3} py={4}>
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <Icon as={Search} />
                        </InputLeftElement>
                        {!client && (
                            <Input
                                placeholder="Buscar cliente"
                                bg="white"
                                type="search"
                                value={search}
                                autoFocus
                                onChange={ev => {
                                    const val = ev.target.value
                                    setSearch(val)
                                    if (val === '') {
                                        setClients([])
                                    }
                                }}
                            />
                        )}
                    </InputGroup>
                    <ModalClient onDone={doneHandler} />
                </Box>
                <VStack w="full" spacing={0}>
                    {clients.map(_client => (
                        <ClientItem
                            key={_client.id}
                            title={
                                _client.type_identification_document_id === 2
                                    ? _client.business_name
                                    : `${_client.firstname} ${_client.lastname}`
                            }
                            onClick={() => clickItemHandñer(_client)}
                            isActive={client?.id === _client.id}
                            subTitle={_client.phones.join(', ')}
                        />
                    ))}
                    {clients.length === 0 &&
                        search.length > 2 &&
                        !isLoading && (
                            <Text color="gray.600">Búsqueda sin resultado</Text>
                        )}
                    {isLoading && <Spinner size="sm" colorScheme="gray" />}
                </VStack>
            </Box>
        </>
    )
}
